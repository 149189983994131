/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output, Renderer2,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { DomSanitizer, SafeHtml, SafeUrl } from "@angular/platform-browser";
import { Broadcaster, Events } from "../../providers";
import format from "date-fns/format";
import { formatDistanceToNow, isBefore } from "date-fns";
import enUS from "date-fns/locale/en-US";
import de from "date-fns/locale/de";
import fr from "date-fns/locale/de";
import { VNCTalkNotificationsService } from "../../../notifications";
import { ConfigService } from "../../../../config.service";
import { Subject, combineLatest, distinctUntilChanged } from "rxjs";
import { CommonService } from "../../../../shared/providers/common.service";
import { TranslateService } from "@ngx-translate/core";
import { CommonUtil } from "../../../utils/common.util";
import { getUserJID, getActiveTab, getAppSettings, getBaseApiUrl, getActiveFilter, getIsAppOnline } from "../../../../reducers/index";
import { getFileInProgressByMessageId, getMessageById, TalkRootState } from "../../../reducers/index";
import { Store } from "@ngrx/store";
import { JID } from "../../../models/jid.model";
import { Message, MessageStatus, OriginalMessage } from "../../../models/message.model";
import { ContactRepository } from "../../../repositories/contact.repository";
import { ConversationRepository } from "../../../repositories/conversation.repository";
import * as textile from "textile-js";
import { Observable } from "rxjs";
import * as filesize from "filesize";

import { MessageService } from "../../../../shared/providers/message.service";
import { environment } from "../../../../environments/environment";
import { AppService } from "../../../../shared/services/app.service";
import { ToastService } from "../../../../shared/services/toast.service";
import { ConferenceRepository } from "../../../repositories/conference.repository";
import { NavigationEnd, Router } from "../../../../../../node_modules/@angular/router";
import { Conversation } from "../../../models/conversation.model";
import { SearchRepository } from "../../../../repositories/search.repository";
import { isNullOrUndefined } from "util";
import { MessageUtil } from "../../../utils/message.util";
import { MatMenuTrigger } from "@angular/material/menu";
import { BroadcastKeys } from "../../../utils/constants.util";
import { DatabaseService } from "../../../services/db/database.service";
import { ElectronService } from "app/shared/providers/electron.service";
import { SelectProfile, SetActiveFilter, SetActiveTab } from "app/actions/app";
import { Contact } from "app/talk/models/contact.model";
import { MessageUpdateAction, MultiConversationUpdateLastMessage } from "app/talk/actions/message";
import { FilesStorageService } from "app/talk/services/files-storage.service";
import { DatetimeService } from "app/talk/services/datetime.service";
import { DateUtil } from "app/talk/utils/date.util";
import { MatDialog } from "@angular/material/dialog";
import { MatDatepicker } from "@angular/material/datepicker";
import { AvatarRepository } from "app/talk/repositories/avatar.repository";
import { DocumentPreviewComponent, VncLibraryService } from "vnc-library";
import {
  TOPIC_MENTION_MESSAGE_RECEIVER,
} from "../../../../channels/comments/comments.component";
import {GetFullNamePipe} from "../../pipes";
import { distinctUntilKeyChanged, filter, map, take, takeUntil } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
import { ChannelRepository } from "../../../../channels/repository/channel.repository";
import { SmartLinkData, SmartLinkService } from "../../../../channels/smart-link.service";
import { SmartLinkSearchResultType } from "../../../../channels/smart-link-popup/smart-link-popup.component";
import { Channel } from "../../../../channels/models/channel.model";
import { ChannelService } from "../../../../channels/channel.service";
import { FileActionsService } from "../../../services/file-actions.service";
import { File } from "../../../../channels/models/file.model";
import {SocialRepository} from "../../../repositories/social.repository";
import { AppSettings } from "app/talk/models/app-settings.model";
import { ConversationUpdate, DownloadFileInProgress, SetMessageToCopyAndDelete } from "app/talk/actions/conversation";
import { NotificationService } from "app/talk/services/notification.service";
import { LoggerService } from "app/shared/services/logger.service";
import { FilePreviewDialogComponent } from "../dialogs/file-preview-dialog/file-preview-dialog.component";
import { EmojiReactionService } from "app/talk/services/emoji-reaction.service";
import { HttpEventType } from "@angular/common/http";
import { saveAs } from "file-saver";

const TOTAL_VIDEO_DETECT_TIME = 10;
const ATTACHMENT_IMAGE_MAX_HEIGHT_PORTRAIT = 200;
const ATTACHMENT_VIDEO_MAX_HEIGHT_PORTRAIT = 268;

const SHOW_MESSAGE_HEIGHT_DEBUG_INFO = false;

export const TICKET_MENTION = "ticket_mention";
export const META_TASK_MENTION = "meta_task_mention";

interface IReactionEmoji{
  code:string,
  symbol:string
}

@Component({
  selector: "vp-message",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./message.html"
})

export class MessageComponent implements OnDestroy, OnInit, AfterViewInit, OnChanges {
  @Input() message: Message | any;
  @Input() messageHeight: number;
  @Input() mIndex: Message | any;
  @Input() messageStatus: MessageStatus | any;
  @Input() messageTimestamp: number;
  @Input() messageStarred: boolean;
  @Input() messageUrlPreviewData: any;
  @Input() messageRedminePreview: any;
  @Input() uploadFileInProgress: any;
  @Input() isAdvancedSearch: boolean;
  @Input() hideKebab: boolean;
  @Input() isMiniChat: boolean;
  @Input() messageAttachment: any;
  @Input() isDifferentSenderThanLastMessage: any;

  callHistory = "";

  @Input() background: any;
  @Input() chatType: any;
  @Input() keyword: any;
  @Input() showEditMenu = false;
  @Input() isLastMessage = false;
  @Input() inSelectionMode = false;
  @Input() isSearchResult = false;
  @Input() isStarred: boolean;
  @Output() onEdit = new EventEmitter();
  @Output() onStarChange = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @ViewChild("prioritySelect") prioritySelect;
  @ViewChild("statusSelect") statusSelect;
  @ViewChild("reactionMainMenu") reactionMainMenu;
  lang = "en";
  locale: any;
  messageBody: SafeHtml = "";
  destroy$ = new Subject();
  private static SVG_TYPE = "svg";
  private static GIF_TYPE = "gif";
  isAudio = false;
  isSupportedVideo = false;
  isImage = false;
  showMenu = false;
  mapUrl: SafeUrl = "";
  attachmentUrl: SafeUrl = "";
  imagePreviewUrl: SafeUrl = "";
  isUrlPreview = false;
  isOTAFormDisplayed = false;
  oat = 0;
  fromJid: string;
  displayTime: string = "";
  originalMessage: any = "";
  repliedMessage: SafeHtml = "";
  repliedAudioUrl: string = "";
  showPlayer = "none";
  isVideoLoading = false;
  amIAudience = false;
  localFilePath = "";
  private onlyText: boolean = true;
  private userJID: JID;
  private isAlive$ = new Subject<boolean>();
  @ViewChild("mainMenu", {static: false}) mainMenu: MatMenuTrigger;
  @ViewChild("reactMenu", {static: false}) reactMenu: MatMenuTrigger;
  @ViewChild("videoPlayer", {static: false}) videoPlayer: ElementRef;
  @ViewChild(MatDatepicker) dueDatePicker: MatDatepicker<Date>;
  minDate = new Date();
  isMainMenuOpen = false;
  isMyMessage: boolean;
  percentUploaded: string = "0%";
  uploadedPercent = 0;
  recodedPercent = 0;
  localUrl;
  isLoading = false;
  isIOS = false;
  isMeeting: boolean = false;
  companyName: string;
  isExternalUser: boolean;
  activeTab = "chat";
  isOnElectron = environment.isElectron;
  errorMessage: string;
  checkPlayable: any;
  displayPlayer: boolean;
  videoPreviewUrl = "";
  isRecoding = false;
  disableEmailToFriend: boolean = environment.disableEmailToFriend;
  disableAddTask: boolean = environment.disableAddTask;
  EMAIL_TO_A_FRIEND_KEY = environment.theme === "hin" ? "EMAIL_TO_A_FRIEND_HIN" : "EMAIL_TO_A_FRIEND";
  FILE_SENDING_FAILED_KEY = environment.theme === "hin" ? "FILE_SENDING_FAILED_HIN" : "FILE_SENDING_FAILED";
  translatedUrl: string = "";
  isGroup: boolean;
  videoHeight: number;
  videoWidth: number;
  hasMention: boolean;
  forwardTime: string;

  // redmine
  isRedmineUrl = false;
  issueId: any;
  issue: any;
  dueDate: any;
  isETAFormDisplayed: boolean;
  isCommentFormDisplayed: boolean;
  commentText: string;
  mentionableUsers = [];
  isGettingRemineTicketEditableRights = false;
  isUpdatingTicket = false;

  noAnswer: any;
  showDefaultAvatar: boolean;
  totalParticipants: number;
  translationKey: string;
  translationParam: { groupName: any; };
  fullUrl: any;
  hideInsecureContent: boolean;
  isInsecureContent: boolean;
  missCallJids: any;
  isMobileSize = CommonUtil.isMobileSize();
  isIPadSize = CommonUtil.isIPadSize();
  addTimestamp: boolean;
  fullActorName: string;
  actorName: string;
  replyToText: string = "Replying to";

  isHin = environment.theme === "hin";
  isDocument: boolean;
  triggerGallary = new Subject<any>();
  fontSize: string;
  enabledOwnCloud = !!localStorage.getItem("enabledOwnCloud") && localStorage.getItem("enabledOwnCloud") === "true";

  channelEntityPreviewType$ = new BehaviorSubject<string>("");
  channelEntityPreviewData$ = new BehaviorSubject<any>(null);

  commentActionType = CommentAction;
  currentFilter: string;
  activeFilter: any;
  emojiOnly: boolean;
  urlsCount: any;
  settings: AppSettings = {};
  messageRedmineVersionPreview: any;
  isVersionUrl: boolean;
  previewDialog: any;
  isDifferentSender$ = new BehaviorSubject<boolean>(false);
  showAvatar: boolean = false;
  isAppOnline: boolean;
  previewNotAvailableOffline: boolean;
  callEnded: string;

  outgoingActionEvent = new Subject<string>();
  favoriteIsStarred: any;
  headerActions: any;
  translations: any = {};

  emojiCollection: IReactionEmoji[] = [
    {code:":+1:", symbol:"👍"},
    {code:":-1:", symbol:"👎"},
    {code:":heart:", symbol:"❤️"},
    {code:":grinning:", symbol:"😀"},
    {code:":hushed:", symbol:"😯"},
    {code:":slightly_frowning_face:", symbol:"🙁"},
    {code:":angry:", symbol:"😠"},
    {code:":heavy_check_mark:", symbol:"✔️"},
  ];
  isPreviewLoading: boolean;
  fileUploaded: boolean;
  viewGridMode: boolean = false;
  formatToday: any;
  messageDate: any;
  formatTimestamp: boolean;

  unlistenScrollEvents: any;
  unlistenArrowEvents: any;


  get isMobileDevice():boolean{
    return environment.isCordova || CommonUtil.isOnNativeMobileDevice();
  }

  constructor(private sanitizer: DomSanitizer,
              private el: ElementRef,
              private vncLibraryService: VncLibraryService,
              private avatarRepo: AvatarRepository,
              private searchRepo: SearchRepository,
              private notificationsService: VNCTalkNotificationsService,
              private changeDetectorRef: ChangeDetectorRef,
              private dialog: MatDialog,
              private commonService: CommonService,
              private appService: AppService,
              private translate: TranslateService,
              private configService: ConfigService,
              public conferenceRepo: ConferenceRepository,
              private toastService: ToastService,
              private contactRepo: ContactRepository,
              private convRepo: ConversationRepository,
              private messageService: MessageService,
              private broadcaster: Broadcaster,
              private router: Router,
              private databaseService: DatabaseService,
              private electronService: ElectronService,
              private conversationRepo: ConversationRepository,
              private filesStorageService: FilesStorageService,
              private datetimeService: DatetimeService,
              private notificationService: NotificationService,
              private getFullName: GetFullNamePipe,
              private store: Store<TalkRootState>,
              private channelRepository: ChannelRepository,
              private socialRepository: SocialRepository,
              private matDialog: MatDialog,
              private channelService: ChannelService,
              private _smartLinkService: SmartLinkService,
              private logger: LoggerService,
              private emojiReactionService: EmojiReactionService,
              private fileActionsService: FileActionsService,
              private renderer: Renderer2) {
              this.enabledOwnCloud = this.configService.get("enabledOwnCloud");

    // this.logger.info("[MessageComponent][constructor]", textile);
    this.store.select(getAppSettings)
    .pipe(distinctUntilKeyChanged("selectedFontSize")
      , takeUntil(this.isAlive$))
      .subscribe(options => {
        this.fontSize = !!options.selectedFontSize ? `font-${options.selectedFontSize}` : "font-16";
      });

    this.store.select(getUserJID).pipe(filter(jid => !!jid), take(1)).subscribe(jid => {
      this.userJID = jid;

      if (this.message && this.fromJid) {
        this.isMyMessage = this._isMyMessage();
        this.changeDetectorRef.markForCheck();
      }
    });

    this.appService.getAppSettings().pipe(distinctUntilKeyChanged("hideInsecureContent"), takeUntil(this.isAlive$)).subscribe(options => {
      // this.logger.info("[hideInsecureContent]", options);
      if (typeof options.hideInsecureContent === "undefined") {
        this.hideInsecureContent = true;
      } else {
        this.hideInsecureContent = !!options.hideInsecureContent;
      }
      if (this.message) {
        this.processImageVideoAudioAttachment();
        this.processMessageUrl();
      }

      this.changeDetectorRef.markForCheck();
    });

    this.appService.getAppSettings().pipe(distinctUntilKeyChanged("addTimestamp"), takeUntil(this.isAlive$)).subscribe(options => {
      // this.logger.info("[addTimestamp]", options);
      let addTimestamp = !!options.addTimestamp;
      if (addTimestamp !== this.addTimestamp) {
        this.addTimestamp = addTimestamp;
        this.setDisplayTime();
        this.changeDetectorRef.markForCheck();
      }

    });

    this.lang = CommonUtil.getDefaultLang();
    this.commonService.currentLanguage.pipe(takeUntil(this.isAlive$)).subscribe((lang: string) => {
      this.lang = lang;
      this.setDisplayTime();
      this.setCallHistory();
      this.changeDetectorRef.markForCheck();
      if (this.lang === "de") {
        this.locale = de;
      }
      if (this.lang === "fr") {
        this.locale = fr;
      }
      if (this.lang === "en") {
        this.locale = enUS;
      }
    });

    this.store.select(getActiveTab).pipe(takeUntil(this.isAlive$)).subscribe(tabName => {
      this.activeTab = tabName;
    });
    this.store.select(getActiveFilter).pipe(takeUntil(this.isAlive$)).subscribe(name => {
      this.activeFilter = name;
    });
    this.store.select(getIsAppOnline).pipe(takeUntil(this.isAlive$)).subscribe(v => {
      if (!this.isAppOnline && !!v) {
        this.isAppOnline = v;
        this.previewNotAvailableOffline = false;
        this.processMessageUrl();
      } else {
        this.isAppOnline = v;
      }
    });

    this.broadcaster
      .on(BroadcastKeys.DETECT_CHANGES_IN_CHAT_WINDOW)
      .pipe(takeUntil(this.isAlive$))
      .subscribe(() => {
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnInit() {
    this.store.select(getAppSettings).pipe(takeUntil(this.isAlive$)).subscribe(options => {
      const isTimeSettingsUpdated = this.settings.timeFormat !== options.timeFormat
      || this.settings.timezone !== options.timezone || this.settings.dateFormat !== options.dateFormat;
      this.settings = options;
      if (isTimeSettingsUpdated) {
        this.setDisplayTime();
      }
    });
    this.processMessage();
    this.broadcaster.on<any>("fileSent").pipe(takeUntil(this.isAlive$)).subscribe((msg) => {
      if (this.message.id === msg.id) {
        this.fileUploaded = true;
        this.message.attachment = msg.attachment;
        this.logger.info("[messageComponent] fileUploaded ", this.message, msg);
        const tempUrl = (this.message.attachment.url.indexOf("?") > -1) ? this.message.attachment.url.split("?")[0] : this.message.attachment.url;
        this.isSupportedVideo = CommonUtil.isSupportedVideo(this.message.attachment.fileType);
        if (this.isSupportedVideo && this.message.attachment.url.includes("/share.php/")) {
          this.videoPreviewUrl = this.messageService.getAttachmentPreviewUrl(tempUrl);
          this.isRecoding = true;
        }
        this.changeDetectorRef.markForCheck();
      }
    });
    this.broadcaster.on<any>("fileRecoded").pipe(takeUntil(this.isAlive$)).subscribe((msg) => {
      if (this.message.id === msg.id) {
        this.fileUploaded = true;
        this.message.attachment = msg.attachment;
        this.logger.info("[messageComponent] fileRecoded ", this.message, msg);
        const tempUrl = (this.message.attachment.url.indexOf("?") > -1) ? this.message.attachment.url.split("?")[0] : this.message.attachment.url;
        this.isSupportedVideo = CommonUtil.isSupportedVideo(this.message.attachment.fileType);
        if (this.isSupportedVideo && this.message.attachment.url.includes("/share.php/")) {
          this.videoPreviewUrl = this.messageService.getAttachmentPreviewUrl(tempUrl);
          this.isRecoding = false;
        }
        this.changeDetectorRef.markForCheck();
      }
    });
    this.broadcaster.on<string[]>("updateDeletedStatus").pipe(takeUntil(this.isAlive$)).subscribe((ids) => {
      // this.logger.info("updateDeletedStatus", ids);
      if (ids.includes(this.message.id)) {
        this.message.body = null;
        this.message.isDeleted = true;
        this.changeDetectorRef.markForCheck();
      }
    });
    this.broadcaster.on(Events.DOCUMENT_CLICKED).pipe(takeUntil(this.isAlive$)).subscribe(() => {
      if (this.isMainMenuOpen) {
        this.mainMenu && this.mainMenu.closeMenu();
      }
    });
    if (this.mainMenu) {
      this.mainMenu.menuOpened.pipe(takeUntil(this.isAlive$)).subscribe(() => {
        setTimeout(() => {
          this.isMainMenuOpen = true;
        }, 100);
      });

      this.mainMenu.menuClosed.pipe(takeUntil(this.isAlive$)).subscribe(() => {
        this.isMainMenuOpen = false;
      });
    }
    this.broadcaster.on("selectMessage").pipe(takeUntil(this.isAlive$)).subscribe(msgId => {
      if (msgId === this.message.id) {
        this.copyMessage(true);
      }
    });
    this.store.select(state => getMessageById(state, this.message.id))
    .pipe(filter(v => !!v),takeUntil(this.isAlive$))
    .subscribe(message => {
      this.message = message;
      if (!!message.attachment && !!message.attachment.recodingRequired && !!message.attachment.recodingProgress) {
        this.recodedPercent = message.attachment.recodingProgress;
        if (this.recodedPercent === 100) {
          this.isRecoding = false;
        }
        // this.logger.info("[MessageComponentUpdate] recodingstat ", this.isRecoding, this.recodedPercent);
      }
      this.changeDetectorRef.markForCheck();
    });
    this.conversationRepo.getConversationById(this.message.convTarget).pipe(distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(conv => {
      if (!!conv) {
        this.amIAudience = conv.audience_only;
        this.changeDetectorRef.markForCheck();
      }
    });
    this.broadcaster.on<any>("UPDATE_ROLE_IN_CURRENTCHAT").pipe(takeUntil(this.isAlive$)).subscribe((v: any) => {
      if (this.message.convTarget === v) {
        setTimeout(() => {
          this.conversationRepo.getConversationById(v).pipe(take(1)).subscribe(conv => {
              this.amIAudience = conv?.audience_only;
              this.changeDetectorRef.markForCheck();
            });
        }, 200);
      }
    });
  }

  ngAfterViewInit() {
    if(CommonUtil.isMobileSize() || CommonUtil.isIPadSize()) {
      this.closeReactionMenuByScroll();
    }
  }

  closeReactionMenuByScroll(): void {
      this.reactionMainMenu?.menuOpened.subscribe(() => {
        this.unlistenScrollEvents = this.renderer.listen("document", "mousewheel", () => {
          this.reactionMainMenu && this.reactionMainMenu.closeMenu();
        });
        this.unlistenArrowEvents = this.renderer.listen("document", "keydown", (event) => {
          if (event.key === "ArrowUp" || event.key === "ArrowDown") {
            this.reactionMainMenu && this.reactionMainMenu.closeMenu();
          }
        });
      });

      this.reactionMainMenu?.menuClosed.subscribe(() => {
        if (this.unlistenArrowEvents) {this.unlistenArrowEvents();}
        if (this.unlistenScrollEvents) {this.unlistenScrollEvents();}
      });
  }

  private processMessage() {
    // this.logger.info("[MessageComponent][processMessage] id " + this.message?.id + ": ", this.message);
    this.isGroup = this.message.type === "groupchat";

    this.fromJid = this.convRepo.getBareFromMessage(this.message);

    // for a case if passed a SearchMessage class object
    if (!this.fromJid) {
      if (this.message.type === "groupchat") {
        if (typeof this.message.from === "string") {
          this.fromJid = this.message.from.includes("/") ? this.message.from.split("/")[1] : this.message.from;
        }
      } else {
        this.fromJid = this.message.from;
      }
    }

    this.setCallHistory();

    this.isExternalUser = this.contactRepo.isExternalUser(this.fromJid);
    if (this.isExternalUser) {
      this.contactRepo.getContactCompany(this.fromJid).pipe(take(1)).subscribe(companyName => {
        this.companyName = companyName;
      });
    }

    this.isMyMessage = this._isMyMessage();

    if (this.isStarred) {
      this.message.isStarred = true;
    } else {
      if (this.isSearchResult && isNullOrUndefined(this.message.isStarred)) {
        this.message.isStarred = MessageUtil.isFavouriteMessage(this.message);
      }
    }

    if (this.uploadFileInProgress) {
      this.store.select(state => getFileInProgressByMessageId(state, this.message.id))
      .pipe(filter(res => !!res), takeUntil(this.isAlive$)).subscribe((progress: any) => {
        // this.logger.info("[MesasgeComponent][getFileInProgressByMessageId]", this.message.id, progress);
        if (isNaN(progress.loaded) || isNaN(progress.total)) {
          this.percentUploaded = "100%";
          this.uploadedPercent = 100;
        } else {
          this.uploadedPercent = Math.round(progress.loaded * 100 / progress.total);
          this.percentUploaded = Math.round(this.uploadedPercent) + "%";
        }
        this.changeDetectorRef.markForCheck();
      });
    }

    this.setDisplayTime();
    this.processMessageBody();
    this.processOriginalMessage();
    this.processImageVideoAudioAttachment();
    this.processMessageUrl();
    this.processLocationAttachment();

    this.broadcaster.on(Events.DOCUMENT_CLICKED).pipe(takeUntil(this.isAlive$)).subscribe(() => {
      if (this.isMainMenuOpen) {
        this.mainMenu && this.mainMenu.closeMenu();
      }
    });

    if (this.mainMenu) {
      this.mainMenu.menuOpened.pipe(takeUntil(this.isAlive$)).subscribe(() => {
        setTimeout(() => {
          this.isMainMenuOpen = true;
        }, 100);
      });

      this.mainMenu.menuClosed.pipe(takeUntil(this.isAlive$)).subscribe(() => {
        this.isMainMenuOpen = false;
      });
    }
  }

  private setCallHistory() {
    if (this.message) {
      const callHistory = this.conferenceRepo.callHistory[this.message.id];
      this.renderCallHistory(callHistory);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.message.expiry && this.message.expiry * 1000 < new Date().getTime()) {
      this.conversationRepo.deleteMessageFromStoreAndDB(this.message);
    }

    this.conversationRepo.getConversationById(this.message.convTarget).pipe(distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(conv => {
      if (!!conv && this.amIAudience !== conv.audience_only) {
        this.amIAudience = conv.audience_only;
        this.changeDetectorRef.markForCheck();
      }
    });

    if (this.message.originalMessage) {
      delete this.message.attachment;
    }
    // attachment preview updated
    if (changes.messageAttachment && changes.messageAttachment.previousValue
      && changes.messageAttachment.previousValue.url !== changes.messageAttachment.currentValue?.url) {
      this.message.checkedVideo = false;
      this.processImageVideoAudioAttachment();
    }

    // timestamp updated
    if (changes.messageTimestamp && changes.messageTimestamp.previousValue
      && changes.messageTimestamp.previousValue !== changes.messageTimestamp.currentValue) {
        this.setDisplayTime();
        this.setCallHistory();
      }

    // message updated
    if (changes.message && changes.message.previousValue &&
        (changes.message.previousValue.id !== changes.message.currentValue.id || changes.message.previousValue.body?.length !== changes.message.currentValue.body?.length || (changes.message.currentValue.cannotDecrypt === false))) {
        // this.logger.info("[MessageComponent][ngOnChanges] [DataSource] message updated " + this.message?.id + ": ", changes.message.previousValue, changes.message.currentValue);


        // this.messageHeightReal = document.getElementById(`message-${this.message.id}`)?.clientHeight;

        // reset state if message change
        this.resetState();

        this.processMessage();

        if (SHOW_MESSAGE_HEIGHT_DEBUG_INFO) {
          setTimeout(() => {
            const clientHeight = document.getElementById("message-" + changes.message.currentValue.id)?.clientHeight;
            const el = document.getElementById("display-time-" + changes.message.currentValue.id);
            if (clientHeight && el) {
              if (!el.innerHTML.endsWith(";")) {
                el.innerHTML = el.innerHTML + " -> (" + clientHeight + ");";
              }
            }
          }, 1);
        }
      }

    if (changes.isDifferentSenderThanLastMessage) {
      this.isDifferentSender$.next(changes.isDifferentSenderThanLastMessage.currentValue);
    }
  }

  private resetState() {
    // this.logger.info("[MessageComponent][resetState]", this.message.id);

    this.isUrlPreview = false;
    //
    this.hasMention = false;
    //
    this.messageUrlPreviewData = null;
    this.isRedmineUrl = false;
    this.issueId = null;
    this.issue = null;
    this.dueDate = null;
    this.isETAFormDisplayed = false;
    this.isCommentFormDisplayed = false;
    this.commentText = "";
    this.isGettingRemineTicketEditableRights = false;
    this.isUpdatingTicket = false;
    this.mentionableUsers = [];

    this.missCallJids = null;
    this.fullActorName = null;

    this.channelEntityPreviewType$.next("");
    this.channelEntityPreviewData$.next(null);

    this.emojiOnly = false;

    this.attachmentUrl = "";
    this.translatedUrl = "";
    this.isAudio = false;
    this.isImage = false;
    this.isDocument = false;
    this.isSupportedVideo = false;
    this.imagePreviewUrl = "";
    this.videoPreviewUrl = "";

    this.fullUrl = null;
    this.urlsCount = undefined;
  }

  processLocationAttachment() {
    // build map url
    if (this.message.location) { // from the local or other vncTalk client
      this.logger.info("[MessageComponent][processLocationAttachment]");
      let center = this.message.location.lat + "," + this.message.location.lng;
      this.mapUrl = this.trustURL(
        "https://maps.googleapis.com/maps/api/staticmap?center=" + center
        + "&zoom=13&size=600x300&maptype=roadmap&markers=color:red%7Clabel:"
        + this.message.location.label + "%7C" + center
        + "&key=" + this.configService.GOOGLE_STATIC_MAP_API_KEY);
        this.changeDetectorRef.markForCheck();
    }
  }

  async emailTo(document) {
    const attachments = [{
        iconName: this.getIconName(document),
        filename: document.fileName,
        path: document.url
    }];
    let dialogStyles: any = {
      "width": "517px",
      "maxWidth": "80vw",
      "visibility": "visible"
    };
    if (CommonUtil.isMobileSize()) {
      dialogStyles = {
        "width": "100%",
        "height": "100%",
        "maxWidth": "100%",
        "visibility": "visible"
      };
    }
    const { EmailSelectedMessageComponent } = await import(
      "../email-selected-message/email-selected-message.component");
    this.matDialog.open(EmailSelectedMessageComponent, Object.assign({
      data: {attachments: attachments},
      backdropClass: "vnctalk-form-backdrop",
      panelClass: ["vnctalk-form-panel", "vnctalk-send-mail-to-friend"],
      disableClose: true,
      autoFocus: true
    }, dialogStyles));
  }

  get iconName() {
    return this.getIconName(this.message.attachment);
  }

  getIconName(attachment) {
    const fileName = attachment?.fileName || "";
    const fileType = attachment?.fileType || "";
    let iconName = "mdi-file-document-box";
    if (CommonUtil.isAudio(fileType)) {
      iconName = "mdi-file-music";
    } else if (CommonUtil.isVideo(fileType)) {
        iconName = "mdi-video";
    } else if (CommonUtil.isImage(fileType)) {
        iconName = "mdi-image";
    } else if (fileName.toLowerCase().indexOf(".pdf") !== -1) {
        iconName = "mdi-file-pdf";
    } else if (fileName.toLowerCase().indexOf(".ppt") !== -1 || fileName.toLowerCase().indexOf(".pptx") !== -1) {
        iconName = "mdi-file-pdf-box";
    } else if (fileName.toLowerCase().indexOf(".doc") !== -1 || fileName.toLowerCase().indexOf(".docx") !== -1) {
        iconName = "mdi-file-word-box";
    } else if (fileName.toLowerCase().indexOf(".xls") !== -1 || fileName.toLowerCase().indexOf(".xlsx") !== -1) {
        iconName = "mdi-file-excel-box";
    }
    return iconName;
  }

  async sendTo(document) {
    let message = this.message;
    this.store.select(state => getMessageById(state, document.id)).pipe(take(1)).subscribe(v => message = v);
    const messages = [{
      message: message, row: {
        fileName: document.fileName,
        iconName: this.getIconName(document)
      }
    }];
    let dialogStyles = {
      "width": "517px",
      "maxWidth": "80vw",
      "height": "604px",
      "maxHeight": "604px",
      "visibility": "visible"
    };
    if (CommonUtil.isMobileSize()) {
      dialogStyles = {
        "width": "100%",
        "maxWidth": "100%",
        "height": "100%",
        "maxHeight": "100%",
        "visibility": "visible"
      };
    }
    const { ForwardDialogComponent } = await import(
      "app/talk/shared/components/dialogs/forward/forward.component");
    this.dialog.open(ForwardDialogComponent, {
      backdropClass: "forward-dialog-backdrop",
      panelClass: "forward-dialog-panel",
      disableClose: true,
      autoFocus: false,
      data: { messages: messages, sendTo: true },
      width: dialogStyles.width,
      maxHeight: dialogStyles.maxHeight,
      maxWidth: dialogStyles.maxWidth,
      height: dialogStyles.height
    });
  }

  downloadMessage(url?: string) {
    url = url || this.message.attachment.url;;

    if (!url || url === "") {
        return;
    }

    // if download image on mobile
    if (environment.isCordova &&
        (url.includes(".jpeg") || url.includes(".png") || url.includes(".jpg") || url.includes(".gif"))) {
        this.toastService.showSnackbar("FILE_STARTED_DOWNLOADING", 2000);

        this.convRepo.downloadFileToDownloadsOrGallery(url).subscribe(() => {
            this.toastService.showSnackbar("FILE_DOWNLOADED", 2000);
        }, err => {
            this.logger.error("[PreviewImageDialogComponent][downloadFile] error", err);
        });
    } else if (environment.isElectron) {
        // ElectronService.downloadFile(this.message.attachment.url, "");
        this.electronService.fileDownloader(url).subscribe(() => {
            this.toastService.showSnackbar("FILE_DOWNLOADED", 2000);
        }, (error) => {
            this.logger.info("[PreviewImageDialogComponent][downloadFile] error: ", error);
        });
    } else {
        let messageDetails = {
          messageId: this.message.id,
          fileSize: this.message.attachment.fileSize,
          fileName: this.message.attachment.fileName,
          url: url,
          loaded: 0,
          total: parseInt(this.message.attachment.fileSize)
        };
        MessageUtil.downloadFileFromUrl(url, this.convRepo, this.filesStorageService, this.store, messageDetails);
    }
  }

  private _getViewerMode(mediaType: string):string{
    if(mediaType === "pdf"){
      return "pdf";
    } else {
      return "office";
    }
  }

  goToSource(file) {
    this.store.dispatch(new SetActiveTab("chat"));
    this.conversationRepo.navigateToConversation(null);
    setTimeout(() => {
      const highlightCompleteRow: boolean = true;
      this.conversationRepo.navigateToConversationAndJumpToMessage(this.message.convTarget, {id: file.id, timestamp: file.timestamp}, highlightCompleteRow);
      this.previewDialog.close();
    });
  }

  previewDocument(data?: any, allowImage?: boolean) {
    this.logger.info("[previewDocument] data", data);
    let message = this.message;
    if (!!data) {
      message = data;
    }
    const mediaType = message?.attachment?.fileType || "";
    this.logger.info("[previewDocument]", this.message?.attachment?.fileType);
    if (CommonUtil.isDocument(this.message?.attachment?.fileType)) {
      if (this.configService.get("disableDocumentPreview") || !CommonUtil.isPreviewAvailableForType(mediaType)) {
        this.toastService.show("UNSUPPORTED_FILE");
        return;
      }
    } else if ([...CommonUtil.unsupportVideoExtensions, ...CommonUtil.zipExtensions].indexOf(mediaType.trim().toLowerCase()) !== -1)  {
      this.downloadMessage();
      return;
    }

    if (CommonUtil.isOnMobileDevice()) {
      if (this.isImage && !allowImage) {
        this.previewImage(message);
        return;
      }
    }

    const triggerEvent = new Subject<any>();
    triggerEvent.pipe(takeUntil(this.isAlive$)).subscribe(v => {
      this.logger.info("[triggerEvent]", v);
      switch (v?.action) {
        case "email": {
            this.emailTo(v.document);
          break;
        }
        case "addToFavorites": {
          if (!this.message.isStarred) {
            this.starMessage();
            this.notificationService.openSnackBarWithTranslation("ADDED_TO_FAVORITES");
          } else {
            this.unstarMessage();
            this.notificationService.openSnackBarWithTranslation("REMOVED_FROM_FAVORITES");
          }
          break;
        }
        case "forward": {
            this.sendTo(v.document);
          break;
        }
        case "download": {
            this.downloadMessage(v.document.url);
          break;
        }
        case "copy": {
            CommonUtil.copyToClipboard([v.document.url]);
            this.notificationService.openSnackBarWithTranslation("IMAGE_COPY_SUCCESS");
          break;
        }
        case "ownCloud": {
          this.saveToOwncloud(v.document);
        break;
        }
        case "doubletap": {
          if (v.document.type === "image") {
            this.store.select(state => getMessageById(state, v.document.id)).pipe(take(1)).subscribe(v =>{
              if (!!v) {
                this.previewImage(v);
                this.conversationRepo.triggerGallary.next("close");
              }
            });

          }

        break;
        }
        case "view":{
          this.isGridMode();
          break;
        }
        case "copy-image":{
          this.outgoingActionEvent.next("copy-image");
          break;
        }
        case "image-copy-success-keyboard-shortcut":
        case "image-copy-success-trigger-event":{
          this.notificationService.openSnackBarWithTranslation("IMAGE_COPY_SUCCESS");
          break;
        }
        case "image-copy-error":{
          this.notificationService.openSnackBarWithTranslation("UNABLE_TO_COPY_FILE");
          break;
        }
        case "gotoSource":{
          this.goToSource(v?.document);
          break;
        }
        case "fullScreen":{
            break;
        }
        default: this.underDevelopment(); break;
      }
    });
    const translationsKey = ["CLOSE", "MORE_TEXT", "GALLERY_VIEW", "GRID_VIEW", "DELETE", "CREATE_VNCTASK", "EMAIL_TO_A_FRIEND", "ADD_TO_FAVORITES", "REMOVE_FROM_FAVORITES" , "GOTO_SOURCE", "COPY_TO_CLIPBOARD", "IMAGE_COPY", "SHARE", "FORWARD", "DOWNLOAD", "COPY_TO_CLIPBOARD", "SEND_TO_OWNCLOUD"];
    let translations: any = {};
    this.translate.get(translationsKey).pipe(take(1)).subscribe(v => {
      translations = v;
      this.translations = v;
    });

    // console.log("DEBUG translations",translations)
    // console.log("DEBUG translations CLOSE",translations.CLOSE)
    // console.log("DEBUG translations MORE",translations.MORE)

    const favoriteAddRemoveText = this.message.isStarred ? translations.REMOVE_FROM_FAVORITES : translations.ADD_TO_FAVORITES;
    this.favoriteIsStarred = this.message.isStarred ? "favorites-filled-new" : "favorites-new";

    this.headerActions = [
      {
        label: favoriteAddRemoveText,
        vncIconName: this.favoriteIsStarred,
        action: "addToFavorites"
      },
      // {
      //   label: translations.SHARE,
      //   vncIconName: 'share-new',
      //   action: 'share'
      // },
      {
        label: translations.FORWARD,
        vncIconName: "forward-new",
        action: "forward"
      },
      {
        label: translations.DOWNLOAD,
        vncIconName: "download-new",
        action: "download"
      },
      {
        label: translations.GOTO_SOURCE,
        vncIconName: "arrow-subdirectory-right-new",
        action: "gotoSource"
      },
      {
        label: translations.COPY_TO_CLIPBOARD,
        vncIconName: "content-copy",
        action: "copy"
      },
      {
        label: translations.GRID_VIEW,
        vncIconName: "view-tile-grid",
        action: "view"
      }
    ];
    let menuActions = [
      {
        label: translations.EMAIL_TO_A_FRIEND,
        vncIconName: "send-mail-new",
        action: "email"
      },
      {
        label: translations.CREATE_VNCTASK,
        iconName: "mdi-content-copy",
        vncIconName: "task-new",
        action: "task"
      },
      {
        label: translations.DELETE,
        iconName: "mdi-content-copy",
        vncIconName: "delete-new",
        action: "delete"
      }
    ];
    let contextMenuActions = [
      {
        label: translations.DOWNLOAD,
        vncIconName: "download-new",
        action: "download"
      },
      {
       label: this.isImage && !this.isFailed() ? translations.IMAGE_COPY : translations.COPY_TO_CLIPBOARD,
       vncIconName: "content-copy",
       action: "copy-image"
      },
      {
        label: translations.FORWARD,
        vncIconName: "arrow-forward",
        action: "forward"
      }
  ];
    if (CommonUtil.isMobileSize()) {
      menuActions.push({
          label: translations.SEND_TO_OWNCLOUD,
          iconName: "owncloud",
          vncIconName: "own-cloud",
          action: "ownCloud"
      });
    }
    const jid = this.fromJid || message.fromJid;
    let avatarName = this.avatarRepo.buildTargetHash(jid);
    const avatarFallback = {email: jid, fullName: this.contactRepo.getFullName(jid)};
    const avatarUrl = CommonUtil.translateHINFileURL(this.configService.avatarServiceUrl + "/" + avatarName + ".jpg?v=" + new Date().getTime());
    const document: any = {
      id: message.id,
      fileName: CommonUtil.isChannelFileURL(message.attachment.fileName) ? decodeURI(message.attachment.fileName.substr(message.attachment.fileName.lastIndexOf("/") + 1))  : message.attachment.fileName,
      fileType: this.isImage ? `image/${message.attachment.fileType}` : message.attachment.fileType,
      fullName: this.contactRepo.getFullName(jid),
      url: CommonUtil.isChannelFileURL(message.attachment.url) ? CommonUtil.addTokenToURL(CommonUtil.getAttachmentLocalAPIURL(message.attachment.url)) :  message.attachment.url,
      thumbnail_url: CommonUtil.isChannelFileURL(message.attachment.url) ? CommonUtil.addTokenToURL(CommonUtil.getAttachmentLocalAPIURL(message.attachment.url)) : message.attachment.url.replace("share.php", "preview.php"),
      avatarUrl,
      avatarFallback,
      timestamp: message.timestamp};
    if (this.isSupportedVideo) {
      if (environment.isCordova && !CommonUtil.isOnIpad()){
        screen.orientation.unlock();
      }
      document.fileType = `video/${message.attachment.fileType}`;
    }
    let allMessages = [];
    let fileExtension = (document.fileName.split(".").pop() || "").toLowerCase();

    if ((fileExtension !== "pdf") && this.isAppOnline) {
      this.convRepo.getSelectedConversationMessagesWithContent().pipe(take(1))
      .subscribe(messages => {
          messages = messages.map(msg => {
            if (!msg.attachment && msg.body
              && ( ((msg.body.startsWith("http://") || msg.body.startsWith("https://")) && CommonUtil.isKnownAttachmentMediaType(msg.body) )
                || CommonUtil.isChannelFileURL(msg.body))) {
              const splitPath = msg.body.split("/").filter((val) => { return val.length > 0; });
              try {
                if (splitPath.length > 2) {
                  const fileName = msg.body.split("/").pop().split("?")[0] || "";
                  const extension = fileName.toLowerCase().split(".").pop();
                  msg.attachment = {
                    url: msg.body && CommonUtil.isChannelFileURL(msg.body) ? CommonUtil.addTokenToURL(CommonUtil.getAttachmentLocalAPIURL(msg.body.substr(msg.body.indexOf(":") + 2, msg.body.length))).replace("api/api/", "api/") : msg.body,
                    fileName: fileName,
                    fileSize: 0,
                    fileType: extension
                  };
                }
              } catch (error) {
                // eslint-disable-next-line no-console
                console.warn("[previewDocument]", msg, error);
              }
            }
            if (!msg.attachment && document.id === msg.id) {
              try {
                const fileName = document.url.split("/").pop().split("?")[0];
                msg.attachment = {...document, fileName};
              } catch (error) {
                // eslint-disable-next-line no-console
                console.warn("[previewDocument]", msg, error);
              }
            }
            return msg;
          });
          allMessages = this.mapDocuments(messages.filter(m => !m.isDeleted && m.attachment && m.attachment.fileType
            && (CommonUtil.isImage(m.attachment.fileType) || CommonUtil.isSupportedVideo(m.attachment.fileType) || CommonUtil.isAudio(m.attachment.fileType)))
            .map(m => this.mapMessageFromStore(m)));
      });
    }
    let isCommonMediaFile = fileExtension === "pdf"
      || CommonUtil.isImage(fileExtension)
      || CommonUtil.isAudio(fileExtension)
      || CommonUtil.isSupportedVideo(fileExtension);

    if(isCommonMediaFile) {
      const style: any = {
        width: "100vw",
        maxWidth: "100vw",
        height: "100vh"
      };

      let previewDialogFiles = [];
      if (fileExtension !== "pdf") {
        this.isImage = CommonUtil.isImage(message.attachment.fileType);
        this.isSupportedVideo = CommonUtil.isSupportedVideo(message.attachment.fileType);
        previewDialogFiles = this.isImage || this.isSupportedVideo || this.isAudio ? allMessages.map(v => this.mapPreviewItem(v)) : [];
        if(!previewDialogFiles.length && message.attachment)
        {
          const filetype = CommonUtil.isImage(fileExtension) ? "image" :
            CommonUtil.isAudio(fileExtension) ? "audio" :
            CommonUtil.isSupportedVideo(fileExtension) ? "video" : "octet-stream";
          message.attachment = { ...message.attachment, type: filetype };
          previewDialogFiles = [this.mapPreviewItem(message.attachment)];
        }
      }
      if (CommonUtil.isOnMobileDevice()) {
        previewDialogFiles = [];
        let copyPreviewFiles = [];
        if (fileExtension !== "pdf") {
          this.isSupportedVideo = CommonUtil.isSupportedVideo(message.attachment.fileType);
          copyPreviewFiles = this.isSupportedVideo || this.isAudio ? allMessages.map(v => this.mapPreviewItem(v)) : [];
          if (!copyPreviewFiles.length && message.attachment) {
            const filetype = CommonUtil.isAudio(fileExtension) ? "audio" :
              CommonUtil.isSupportedVideo(fileExtension) ? "video" : "octet-stream";
            message.attachment = { ...message.attachment, type: filetype };
            copyPreviewFiles = [this.mapPreviewItem(message.attachment)];
          }
          copyPreviewFiles.map(prv => {
            if (CommonUtil.isAudio(prv.fileType.split("/")[1]) || CommonUtil.isSupportedVideo(prv.fileType.split("/")[1])) {
              previewDialogFiles.push(prv);
            }
          });
        }
      }

      if (document.url.startsWith("blob:")) {
        this.databaseService.fetchPendingAttachmentById(message.id).subscribe(attMessage => {
          document.url = attMessage.b64attachment;
          document.thumbnail_url = attMessage.b64attachment;
          message.attachment.url = attMessage.b64attachment;
          previewDialogFiles = [this.mapPreviewItem(message.attachment)];
          setTimeout(() => {
            this.previewDialog = this.dialog.open(DocumentPreviewComponent, Object.assign({
              backdropClass: "vnc-dialog-backdrop",
              panelClass: "vnc-preview-dialog-panel",
              disableClose: true,
              hasBackdrop: false,
              data: {
                document: document,
                selectedId: document.id,
                showOwnCloudIcon: this.enabledOwnCloud,
                showOwnCloudText: translations.SEND_TO_OWNCLOUD,
                viewerMode: this._getViewerMode(mediaType),
                forMobile: CommonUtil.isMobileSize(),
                files: fileExtension === "pdf" ? [] : previewDialogFiles.reverse(),
                menuActions: menuActions,
                contextMenuActions: contextMenuActions,
                incomingActionEvent: this.outgoingActionEvent,
                headerActions: this.headerActions,
                triggerEvent: triggerEvent,
                triggerGallary: this.conversationRepo.triggerGallary,
                imageZoomSlider: true,
              },
              autoFocus: false
            }, style));
            setTimeout(() => {
              this.changeDetectorRef.markForCheck();
            }, 200);
          }, 200);
        });

      } else {

        this.previewDialog = this.dialog.open(DocumentPreviewComponent, Object.assign({
          backdropClass: "vnc-dialog-backdrop",
          panelClass: "vnc-preview-dialog-panel",
          disableClose: true,
          hasBackdrop: false,
          data: {
            document: document,
            selectedId: document.id,
            showOwnCloudIcon: this.enabledOwnCloud,
            showOwnCloudText: translations.SEND_TO_OWNCLOUD,
            viewerMode: this._getViewerMode(mediaType),
            forMobile: CommonUtil.isMobileSize(),
            files: fileExtension === "pdf" ? [] : previewDialogFiles.reverse(),
            menuActions: menuActions,
            contextMenuActions: contextMenuActions,
            incomingActionEvent: this.outgoingActionEvent,
            headerActions: this.headerActions,
            triggerEvent: triggerEvent,
            triggerGallary: this.conversationRepo.triggerGallary,
            imageZoomSlider: true,
          },
          autoFocus: false
        }, style));
      }
    } else if (this.configService.get("onlyOfficeApiUrl")?.length){
      const style: any = CommonUtil.isOnMobileDevice()
      ? {
        maxHeight: "100vh",
        maxWidth: "100vw",
        height: "100vh",
        width: "100vw",
      }
      : {
        maxHeight: "90vh",
        maxWidth: "90vw",
        height: "90vh",
        width: "90vw",
      };
      this.logger.info("[FilePreviewDialogComponent]", document, allMessages);
      this.previewDialog = this.matDialog.open(FilePreviewDialogComponent, Object.assign({
        autoFocus: true,
        panelClass: "file-preview-dialog",
        data: {
            attachment: document,
            triggerEvent: triggerEvent,
            headerActions: this.headerActions,
        }}, style));
    } else {
      this.toastService.show("UNSUPPORTED_FILE");
    }

    if(!this.previewDialog) return;
    this.previewDialog.afterClosed().pipe(take(1)).subscribe(() => {
      if (environment.isCordova && !CommonUtil.isOnIpad()){
        screen.orientation.lock("portrait");
      }
    });

  }


  isGridMode() {
    const galeryBigImage = document.querySelector(".g-box");
    const gallerySliderContainer = document.querySelector(".preview-body");
    const zoomControl = document.querySelector(".zoom-control-wrapper");

    this.viewGridMode = !this.viewGridMode;
    if (this.viewGridMode) {
      gallerySliderContainer.classList.add("grid-mode-container");
      galeryBigImage.classList.add("grid-big-image");
      zoomControl.classList.add("hide-zoom-control");
      this.headerActions[5].label = this.translations.GALLERY_VIEW;
      this.headerActions[5].vncIconName = "grid-mobile-stripe-new";
    } else {
      gallerySliderContainer.classList.remove("grid-mode-container");
      galeryBigImage.classList.remove("grid-big-image");
      zoomControl.classList.remove("hide-zoom-control");
      this.headerActions[5].label = this.translations.GRID_VIEW;
      this.headerActions[5].vncIconName = "view-tile-grid";
    }
  }

  private mapDocuments(docs) {
    this.logger.info("[mapDocuments]", docs);
    return docs.filter(v => !!v).map(doc => {
        const body = doc.body ? doc.body.split("\n")[0] : "";
        let fileName = body.slice(body.lastIndexOf("/") + 1, body.length);
        let fileSize = "";
        let fileSizeNumber = "";
        let fileType = fileName ? fileName.slice(fileName.lastIndexOf(".") + 1, fileName.length) : "";
        let iconName = "mdi-file-document-box";
        let timestamp = doc.timestamp;
        let type = "document";
        let url = body;
        if (doc.x_attachment) {
            const attachment = JSON.parse(doc.x_attachment);
            if (attachment.fileName) {
                fileName = attachment.fileName;
            }

            if (attachment.url) {
                url = attachment.url;
            }
            if (attachment.fileSize) {
                try {
                    fileSize = filesize(attachment.fileSize, {locale: this.lang});
                    fileSizeNumber = attachment.fileSize;
                } catch (ex) {

                }
            }
            if (attachment.fileType) {
                fileType = attachment.fileType;
            }
        } else if (doc.attachment) {
            fileName = doc.attachment.fileName;
            url = doc.attachment.url;
            if (doc.attachment.fileSize) {
                fileSizeNumber = doc.attachment.fileSize;
                try {
                    fileSize = filesize(doc.attachments.fileSize, {locale: this.lang});
                } catch (ex) {
                  this.logger.sentryErrorLog("convert attachment file size ex", ex);
                }
            }
            fileType = fileName.slice(fileName.lastIndexOf(".") + 1, fileName.length);
        }
        if (!fileType) {
            fileType = "";
        }
        if (!fileName) {
            fileName = "";
        }
        if (CommonUtil.isAudio(fileType)) {
            iconName = "mdi-file-music";
            type = "audio";
        } else if (CommonUtil.isVideo(fileType)) {
            iconName = "mdi-video";
            type = "video";
        } else if (CommonUtil.isImage(fileType)) {
            iconName = "mdi-image";
            type = "image";
        } else if (fileName.toLowerCase().indexOf(".pdf") !== -1) {
            iconName = "mdi-file-pdf";
        } else if (fileName.toLowerCase().indexOf(".ppt") !== -1 || fileName.toLowerCase().indexOf(".pptx") !== -1) {
            iconName = "mdi-file-pdf-box";
        } else if (fileName.toLowerCase().indexOf(".doc") !== -1 || fileName.toLowerCase().indexOf(".docx") !== -1) {
            iconName = "mdi-file-word-box";
        } else if (fileName.toLowerCase().indexOf(".xls") !== -1 || fileName.toLowerCase().indexOf(".xlsx") !== -1) {
            iconName = "mdi-file-excel-box";
        }
        return {
            id: doc.id,
            doc: doc,
            type: type,
            url: url,
            thumbnail: url.replace("share.php", "preview.php"),
            fileName: fileName,
            fullName: this.contactRepo.getFullName(doc.from),
            jid: doc.from,
            fileSize: fileSize,
            fileSizeNumber: fileSizeNumber,
            timestamp: timestamp,
            fileType: fileType,
            iconName: iconName
        };
    });
  }

  private mapPreviewItem(data) {
    // let avatarName = this.avatarRepo.buildTargetHash(data.jid);
    this.logger.info("mapPreviewItem: ", data);
    const avatarFallback = {email: data.jid, fullName: this.contactRepo.getFullName(data.jid)};
    const avatarUrl = this.avatarRepo.getAvatarUrlWithCache(data.jid);
    const document: any = {
    fileName: data.fileName,
    type: data.type,
    fileType: data.type + "/" + (data.fileType === "mov" ? "mp4" : data.fileType),
    fullName: this.contactRepo.getFullName(data.jid),
    url: data.url,
    thumbnail_url: data.url ? data.url.replace("share.php", "preview.php") : "",
    avatarUrl,
    avatarFallback,
    id: data.id,
    timestamp: data.timestamp};
    return document;
  }

  private mapMessageFromStore(m) {
    const sm: any = {
        id: m.id,
        from: m.fromJid,
        to: m.to.bare,
        body: m.body,
        timestamp: m.timestamp,
        x_attachment: JSON.stringify(m.attachment),
        x_origMessage: JSON.stringify(m.originalMessage),
        x_forwardMessage: JSON.stringify(m.forwardMessage)
    };
    return sm;
}

  processImageVideoAudioAttachment() {
    if (!this.message || !this.message.attachment) {
      return;
    }

    this.isAudio = CommonUtil.isAudio(this.message.attachment.fileType);
    this.isImage = CommonUtil.isImage(this.message.attachment.fileType);
    this.isDocument = CommonUtil.isDocument(this.message.attachment.fileType);
    this.isSupportedVideo = CommonUtil.isSupportedVideo(this.message.attachment.fileType);

    // URL sanitazing
    let translatedUrl = CommonUtil.translateHINFileURL(this.message.attachment.url);
    this.message.attachment.url = translatedUrl;
    this.translatedUrl = translatedUrl;

    const isLimitSizeImage = (this.isImage || this.isSupportedVideo) && +this.message.attachment.fileSize > (1000 * 1024 * 5);

    const url = this.message.attachment.url || "";
    this.attachmentUrl = this.trustURL(`${url}`);
    if (!CommonUtil.isOnNativeMobileDevice() && CommonUtil.isVideo(this.message.attachment.fileType) && url.indexOf("blob:") === -1) {
      this.attachmentUrl = this.trustURL(`${url}?d=true`);
    }

    // Image
    if (this.isImage) {
      if (this.message.attachment.url.includes("/share.php/") && url.toLowerCase().lastIndexOf(".png") === -1 && url.lastIndexOf(".svg") === -1 && url.toLowerCase().lastIndexOf(".gif") === -1 ) {
        this.imagePreviewUrl = this.trustURL(this.messageService.getAttachmentPreviewUrl(this.message.attachment.url));
      } else if (this.message.attachment.fileType === MessageComponent.SVG_TYPE || this.message.attachment.fileType === MessageComponent.GIF_TYPE) {
        this.imagePreviewUrl = this.attachmentUrl;
      }
      // form localUrl for mobile
      if (CommonUtil.isOnNativeMobileDevice() && !this.isUrlLocalBlob(this.message.attachment.url) && !this.isLoading) {
        const imagePreviewUrl = this.messageService.getAttachmentPreviewUrl(this.message.attachment.url);
        const attachmentUrl = isLimitSizeImage
            ? (imagePreviewUrl ? imagePreviewUrl : this.attachmentUrl)
            : this.attachmentUrl;

        this.formLocalUrl(attachmentUrl);
      }
    }

    // Video
    if (this.isSupportedVideo && this.message.attachment.url.includes("/share.php/")) {
      const tempUrl = (this.message.attachment.url.indexOf("?") > -1) ? this.message.attachment.url.split("?")[0] : this.message.attachment.url;
      this.videoPreviewUrl = this.messageService.getAttachmentPreviewUrl(tempUrl);
    }

    // Audio
    if (this.isAudio && CommonUtil.isOnNativeMobileDevice() && !this.isUrlLocalBlob(this.message.attachment.url) && !this.isLoading) {
      this.formLocalUrl(url);
    }
    if (this.hideInsecureContent && this.message.attachment.url && this.isInsecureUrl(this.message.attachment.url)) {
      this.isInsecureContent = true;
    } else {
      this.isInsecureContent = false;
    }
    // this.logger.info("[MessageComponent][processImageVideoAudioAttachment]", this.message.id, this.message.attachment, this.isInsecureContent, this.hideInsecureContent);
  }

  isInsecureUrl(url) {
    return !url.startsWith("https") && !(url.startsWith("file://") || url.startsWith("blob:"));
  }

  private onImagePreviewLoaded() {
    const imgEl = (<HTMLElement>document.querySelector(`.image-attachment-preview-${this.message.id}`));
    if (!imgEl) {
      // eslint-disable-next-line no-console
      console.warn("[MessageComponent][onImagePreviewLoaded]", `.image-attachment-preview-${this.message.id}`, "not found", this.message);
      return;
    }

    const imgHeight = imgEl.clientHeight;
    const imgWidth = imgEl.clientWidth;

    // landscape?
    if (imgWidth > imgHeight) {
      // if (imgHeight > ATTACHMENT_IMAGE_MAX_HEIGHT_PORTRAIT) {
      // }
      imgEl.style.height = imgHeight + "px";
      // this.logger.info("[MessageComponent][onImagePreviewLoaded]: landscape", imgHeight, imgWidth, this.message.id);

      // also tune preview loader size if exists
      const imgPreviewEl = (<HTMLElement>document.querySelector(`.image-wrapper-${this.message.id}`));
      if (imgPreviewEl) {
        imgPreviewEl.style.height = imgHeight + "px";
      }
    } else {
      this.logger.info("[MessageComponent][onImagePreviewLoaded]: portrair", imgHeight, imgWidth, this.message.id);
    }
  }

  private onVideoPreviewLoaded() {
    const videoPreviewEl = (<HTMLElement>document.querySelector(`.video-attachment-preview-${this.message.id}`));
    if (!videoPreviewEl) {
      // eslint-disable-next-line no-console
      console.warn("[MessageComponent][onVideoPreviewLoaded]", `.video-attachment-preview-${this.message.id}`, "not found", this.message);
      return;
    }

    const videoHeight = videoPreviewEl.clientHeight;
    const videoWidth = videoPreviewEl.clientWidth;

    if (videoWidth > videoHeight && videoHeight < ATTACHMENT_IMAGE_MAX_HEIGHT_PORTRAIT) {
      const videoEl = (<HTMLElement>document.querySelector(`.video-player-${this.message.id}`));
      videoEl.style.height = videoHeight + "px";
      this.videoHeight = videoHeight;
      this.videoWidth = videoWidth;
      this.logger.info("[MessageComponent][onVideoPreviewLoad]: landscape", videoHeight, videoWidth, this.message.id);
    } else {
      this.videoHeight = ATTACHMENT_VIDEO_MAX_HEIGHT_PORTRAIT;
      const ratio = (videoHeight / videoWidth);
      this.videoWidth = ATTACHMENT_VIDEO_MAX_HEIGHT_PORTRAIT / ratio;
      this.logger.info("[MessageComponent][onVideoPreviewLoad]: portrait", videoHeight, videoWidth, this.message.id);
    }
  }

  private onVideoReplyPreviewLoaded(){
    const videoPreviewEl = (<HTMLElement>document.querySelector(`.video-attachment-reply-preview-${this.message.id}`));
    const videoHeight = videoPreviewEl.clientHeight;
    const videoWidth = videoPreviewEl.clientWidth;

    if (videoWidth > videoHeight && videoHeight < ATTACHMENT_IMAGE_MAX_HEIGHT_PORTRAIT) {
      const videoEl = (<HTMLElement>document.querySelector(`.video-player-reply-${this.message.id}`));
      videoEl.style.height = videoHeight + "px";
      this.videoHeight = videoHeight;
      this.videoWidth = videoWidth;
      this.logger.info("[MessageComponent][onVideoReplyPreviewLoaded]: landscape", videoHeight, videoWidth, this.message.id);
    } else {
      this.videoHeight = ATTACHMENT_VIDEO_MAX_HEIGHT_PORTRAIT;
      const ratio = (videoHeight / videoWidth);
      this.videoWidth = ATTACHMENT_VIDEO_MAX_HEIGHT_PORTRAIT / ratio;
      this.logger.info("[MessageComponent][onVideoReplyPreviewLoaded]: portrait", videoHeight, videoWidth, this.message.id);
    }
  }

  private formLocalUrl(remoteUrl: any){
    this.logger.info("[formLocalUrl]", remoteUrl);
    this.isLoading = true;

    this.databaseService
      .getAttachmentUrl(remoteUrl)
      .pipe(takeUntil(this.isAlive$))
      .subscribe(url => {
        remoteUrl = CommonUtil.translateHINFileURL(remoteUrl);
        if (!!url) {
          if (CommonUtil.isOnIOS()) {
            window.resolveLocalFileSystemURL(url, (fileEntry: any) => {
               fileEntry.file((file) => {
                 let reader = new FileReader();
                 let self = this;
                 reader.onloadend = function () {
                   self.localUrl = this.result;
                   // this.logger.info("[MessageComponent] localURl IOS", self.localUrl);
                   self.isLoading = false;
                   self.changeDetectorRef.markForCheck();
                 };
                 reader.readAsDataURL(file);
               }, (e) => {
                 this.logger.error(e);
                 this.isLoading = false;
                  this.localUrl = remoteUrl;
                  this.changeDetectorRef.markForCheck();
               });
             }, (e) => {
            this.logger.error(e);
            this.isLoading = false;
            this.localUrl = remoteUrl;
            this.changeDetectorRef.markForCheck();
           });
          }
        } else {
          this.isLoading = false;
          this.localUrl = remoteUrl;
          this.changeDetectorRef.markForCheck();
        }

      });
  }

  async openVideoPlayer() {
      let dialogStyles = {
          "width": "auto",
          "height": (window.innerHeight - 50) + "px",
          "maxHeight": (window.innerHeight - 50) + "px",
          "maxWidth": window.innerWidth + "px",
          "visibility": "visible"
      };
      if (CommonUtil.isOnMobileDevice()) {
        dialogStyles = {
          "width": "100vw",
          "height": "100vh",
          "maxHeight": "100vh",
          "maxWidth": "100vw",
          "visibility": "visible"
      };
      }
      const { MediaPreviewDialogComponent } = await import(
        "../dialogs/media-preview/media-preview.component");
      this.dialog.open(MediaPreviewDialogComponent, Object.assign({
        data: {message: {...this.message, isOriginalMessage: true}, mediaType: "videos"},
        backdropClass: "vnctalk-form-backdrop",
        panelClass: "vnctalk-form-panel",
        disableClose: true,
        autoFocus: true
      }, dialogStyles));

  }

  playVideo(): void {
    this.logger.info("[MessageComponent][playVideo]", this.message.checkedVideo, this.attachmentUrl);

    this.displayPlayer = true;

    if (this.message.checkedVideo) {
      this.showPlayer = "block";
      this.isVideoLoading = false;
      this.changeDetectorRef.markForCheck();

    } else if (this.message.attachment && this.message.attachment.fileType && !this.isUrlLocalBlob(this.message.attachment.url)) {
      if (this.attachmentUrl) {
        let totalTried = 0;
        this.isVideoLoading = true;

        if (this.checkPlayable) {
          clearInterval(this.checkPlayable);
        }
        this.checkPlayable = setInterval(() => {
          this.logger.info("[MessageComponent][playVideo]", this.videoPlayer, this.localUrl, this.videoPlayer ? this.videoPlayer.nativeElement.readyState : null);

          if (this.videoPlayer) {
            if (this.videoPlayer.nativeElement.readyState > 2 || (CommonUtil.isOnIOS() && this.videoPlayer.nativeElement.readyState === 1)) {
              this.showPlayer = "block";
              this.isVideoLoading = false;
              this.message.checkedVideo = true;
              this.store.dispatch(new MessageUpdateAction({id: this.message.id, changes: {checkedVideo: true}}));
              this.changeDetectorRef.markForCheck();
              clearInterval(this.checkPlayable);
            }
          }
          totalTried++;
          if (totalTried === TOTAL_VIDEO_DETECT_TIME) {
            this.errorMessage = "UNSUPPORTED_FORMAT";
            this.isVideoLoading = false;
            this.changeDetectorRef.markForCheck();
            clearInterval(this.checkPlayable);
          }
        }, 3000);
      }
    }
  }

  private processMessageUrl(): void {
    // it's already an attachment
    const isAttachment = this.message && this.message.attachment && this.message.attachment.url && this.message.attachment.fileType;
    if (isAttachment || !this.message) {
      return;
    }

    const body = this.message.originalMessage ? this.message.originalMessage.replyMessage : this.message.body;
    const [fullUrl, urlsCount] = MessageUtil.extractUrlFromMessageBodyAndCountUrls(body, this.configService.get("jitsiURL"));
    this.fullUrl = fullUrl;
    this.urlsCount = urlsCount;

    // not an url preview
    if (!fullUrl) {
      return;
    }

    // it's already url preview
    if (this.messageUrlPreviewData || this.message.urlPreviewData) {
      this.messageUrlPreviewData = this.message.urlPreviewData;

      this.isUrlPreview = true;

      // hide insecure content
      this.setIsInsecureContentForUrlPreview();
      return;
    }

    this.logger.info("[MessageComponent][processMessageUrl] this.messageRedminePreview", this.messageRedminePreview, this.message.redminePreview, fullUrl, fullUrl?.endsWith("/edit"));

    let shouldInvalidateUrlCache = false;

    // it's already redmine preview
    if (this.messageRedminePreview || this.message.redminePreview) {
      if (this.message.redminePreview) {
        this.messageRedminePreview = this.message.redminePreview;
      }

      if (!this.messageRedminePreview.notAvailable && !fullUrl?.endsWith("/edit")) {
        const unfurledAt = this.messageRedminePreview.unfurledAt || (Date.now() - 10000000); // just set in past if not provided
        const unfurledMore10MinsAgo = (Date.now() - (+unfurledAt) > 600000);
        this.logger.info("[MessageComponent][processMessageUrl] unfurledAt", unfurledAt, Date.now(), Date.now() - (+unfurledAt), unfurledMore10MinsAgo, this.messageRedminePreview);
        if (unfurledAt) {
          if (!unfurledMore10MinsAgo) {
            this.isRedmineUrl = true;
            this.issueId = this.messageRedminePreview.id;
            this.dueDate = this.messageRedminePreview.due_date;

            return;
          } else {
            shouldInvalidateUrlCache = true;
          }
        }
      } else {
        this.isRedmineUrl = false;
        return;
      }
    }


    // this.logger.info("[MessageComponent][extractUrlFromMessageBody]",  body, fullUrl);

    const { isImage, isAudio, isSupportedVideo, fileExtension, isRedmineUrl, isVersionUrl } = MessageUtil.getLinkType(fullUrl, urlsCount, this.configService.REDMINE_URL);

    // if this is attachment link
    // TODO: maybe move it outside of MessageComponent and do not parse every time
    if (isImage || isAudio || isSupportedVideo) {
      this.isImage = isImage;
      this.isAudio = isAudio;
      this.isSupportedVideo = isSupportedVideo;
      this.message.attachment = { fileType: fileExtension, url: fullUrl, fileName: fullUrl, fileSize: null };
      this.processImageVideoAudioAttachment();
      return;
    }


    // if a message does not contain the preview metadata, then let's get it and parse the old way

    this.isRedmineUrl = isRedmineUrl;
    this.isVersionUrl = isVersionUrl;

    let newUrl = fullUrl;
    if (this.isRedmineUrl) {
      newUrl = fullUrl.split("\n")[0];
    } else if (this.isVersionUrl) {
      newUrl = fullUrl.split("\n")[0];
    }

    // this.logger.info("[MessageComponent] unfurlURL", newUrl, this.appService.isAppOnline, MessageUtil.shouldUnfurlURL(newUrl), this.message);


    if (MessageUtil.shouldUnfurlURL(newUrl)) {
      if (!this.isRedmineUrl && !this.isVersionUrl) {
        this.isUrlPreview = true;
      }
      if (!this.isAppOnline) {
        this.previewNotAvailableOffline = true;
      } else {
        this.previewNotAvailableOffline = false;
        this.isPreviewLoading = true;
        this.convRepo.unfurlURLForMessage(this.message.id, newUrl, shouldInvalidateUrlCache).subscribe(data => {
          const {res, messageId} = data;
          this.logger.info("[MessageComponent] unfurlURL RES", res, this.message.id, messageId);
          if (!!res && this.message.id === messageId) {
            if (res.unfurledData) {
              this.setUnfurledData(res.unfurledData);
            } else if (res.redmineData) {
              this.setRedmineData(res.redmineData, false);
            } else if (res.versionData) {
              this.setRedmineVersionData(res.versionData, false);
            }
            if (isRedmineUrl && !res.redmineData) {
              this.setRedmineData({notAvailable: true}, true);
            } else if (isVersionUrl && !res.versionData) {
              this.setRedmineVersionData({notAvailable: true}, true);
            }
          } else {
            // eslint-disable-next-line no-console
            console.warn("[MessageComponent] ignore unfurlURL response - not for this message");
          }
        }, err => {
          this.isPreviewLoading = false;
          this.logger.error("[MessageComponent] unfurlURL ERR", err);
          if (isRedmineUrl) {
            this.setRedmineData({notAvailable: true}, true);
          } else if (isVersionUrl) {
            this.setRedmineVersionData({notAvailable: true}, true);
          }
        });
      }

    }
  }

  priorityClass() {
    const prioName = this.messageRedminePreview?.priority?.name || "";
    return prioName ? `${prioName.toLowerCase()}-priority` : "";
  }

  private isUrlLocalBlob(url: string) {
    return url && url.indexOf("blob:") !== -1;
  }

  imgLoadOnError() {
    this.showDefaultAvatar = true;
    this.changeDetectorRef.markForCheck();
  }

  private processOriginalMessage() {
    if (!this.message.originalMessage) {
      return;
    }

    let highlightedBare = "";
    this.store.select(getActiveTab).pipe(take(1)).subscribe(tabName => {
      if (tabName === "mention" && this.userJID) {
        highlightedBare = this.userJID?.bare;
      }
    });
    this.store.select(getActiveFilter).pipe(take(1)).subscribe(tabName => {
      if (tabName === "mention" && this.userJID) {
        highlightedBare = this.userJID?.bare;
      }
    });
    let body: string;
    if (this.message.originalMessage?.body && !this.message.originalMessage?.htmlBody) {
      body = CommonUtil.unEscapeHTMLString(this.message.originalMessage.body.replace(/&/g, "&amp;"));
    } else if (this.message.originalMessage?.htmlBody) {
      body = CommonUtil.unEscapeHTMLString(this.message.originalMessage.htmlBody.replace(/&/g, "&amp;"));
    }
    let origMessageObject: OriginalMessage = {
      ...this.message.originalMessage,
      replyMessage: this.message.originalMessage?.replyMessage ? this.message.originalMessage.replyMessage.replace(/&/g, "&amp;") : "",
      body: body
    };

    let { repliedMessage, originalMessageHTML, onlyText, repliedAudioUrl } = this.messageService.renderRepliedMessage(origMessageObject, this.message.references);
    if (CommonUtil.parseMentions(repliedMessage).length > 0 || CommonUtil.parseMentions(originalMessageHTML).length > 0
    || (this.message.type === "groupchat") && (repliedMessage.match(/@all\b/ig) || originalMessageHTML.match(/@all\b/ig))) {
      repliedMessage = this.contactRepo.renderMentionUsers(repliedMessage, "html", this.message.references, highlightedBare);
      originalMessageHTML = this.contactRepo.renderMentionUsers(originalMessageHTML, "html", this.message.references, highlightedBare);
    }
    let translations: any = {};
    this.translate.get(["TICKET_MENTION_RECEIVER_MESSAGE", "TICKET_MENTION_SENDER_MESSAGE", "META_TASK_MENTION_RECEIVER_MESSAGE", "META_TASK_MENTION_SENDER_MESSAGE"]).pipe(take(1)).subscribe(v => {
      translations = v;
    });
    if (!originalMessageHTML) {
      originalMessageHTML = "";
    }
    this.repliedMessage = this.sanitizer.bypassSecurityTrustHtml(repliedMessage);
    this.repliedAudioUrl = repliedAudioUrl;
    this.onlyText = onlyText;
    try {
      if (CommonUtil.parseRedmineMentions(TICKET_MENTION, originalMessageHTML).length > 0) {
        const textToReplace = CommonUtil.parseRedmineMentions(TICKET_MENTION, originalMessageHTML)[0];
        let userjid = textToReplace.split("#")[1];
        let mentionText = translations.TICKET_MENTION_RECEIVER_MESSAGE.replace("{{ sender_name }}", this.contactRepo.getFullName(this.message.originalMessage.from));
        if (this.userJID?.bare !== userjid) {
          mentionText = translations.TICKET_MENTION_SENDER_MESSAGE.replace("{{ receiver_name }}", this.contactRepo.getFullName(userjid));
        }
        originalMessageHTML = originalMessageHTML.replace(textToReplace, mentionText);
      } else if (CommonUtil.parseRedmineMentions(META_TASK_MENTION, originalMessageHTML).length > 0) {
        const textToReplace = CommonUtil.parseRedmineMentions(META_TASK_MENTION, originalMessageHTML)[0] || "";
        let userjid = textToReplace.split("#")[1];
        let mentionText = translations.META_TASK_MENTION_RECEIVER_MESSAGE.replace("{{ sender_name }}", this.contactRepo.getFullName(this.message.originalMessage.from));
        if (this.userJID?.bare !== userjid) {
          mentionText = translations.META_TASK_MENTION_SENDER_MESSAGE.replace("{{ receiver_name }}", this.contactRepo.getFullName(userjid));
        }
        originalMessageHTML = originalMessageHTML.replace(textToReplace, mentionText);
      }
    } catch (error) {

    }
    this.originalMessage = this.sanitizer.bypassSecurityTrustHtml(originalMessageHTML.replace(/&amp;amp;/g, "&").replace(/&amp;/g, "&"));
  }

  private processMessageBody(skipEmoji?: boolean, processOnly?: boolean) {
    // this.logger.info("[MessageComponent][processMessageBody] message", this.isSearchResult, !!this.message.cachedContent, this.message)
    let messageBody = this.message?.body || "";
    try {
      if (this.message.htmlBody) {
        messageBody = this.message.htmlBody;
      } else if (this.message.html && this.message.html.body) {
        messageBody = this.message.html.body;
      }
      if (!!messageBody) {
        messageBody = messageBody.trim();
        if (/^:[a-zA-Z0-9-_+]+:$/g.test(messageBody)) {
          this.emojiOnly = true;
        }
      }
      // this.logger.info("[processMessageBody]", body, this.emojiOnly);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn("[processMessageBody]", error);
    }

    return new Promise(async (resolve) => {
      const references = this.message.references;
      let isTicketMention = false;

      if (messageBody.startsWith("#quickreply_")) {
        const additionalActionsTranslationKeys = ["QUICK_REPLY_CANT_TALK_WHATS_UP", "QUICK_REPLY_CALL_YOU_RIGHT_BACK", "QUICK_REPLY_CALL_YOU_LATER", "QUICK_REPLY_CANT_TALK_CALL_ME_LATER"];
        let additionActionTranslations = {};
        this.translate.get(additionalActionsTranslationKeys).pipe(take(1)).subscribe(v => {
          additionActionTranslations = v;
        });
        // this.logger.info("[processMessageBody_quickReply] ", messageBody, additionActionTranslations);
        try {
          const translateKey = messageBody.split("quickreply_")[1].trim();
          if (additionalActionsTranslationKeys.indexOf(translateKey) > -1) {
            messageBody = additionActionTranslations[translateKey];
            this.message.cachedContent = additionActionTranslations[translateKey];
          }
        } catch (error) {

        }
      }

      if (!this.message.forwardMessage) {
        let translations: any = {};
        this.translate.get(["TICKET_MENTION_RECEIVER_MESSAGE", "TICKET_MENTION_SENDER_MESSAGE", "META_TASK_MENTION_RECEIVER_MESSAGE", "META_TASK_MENTION_SENDER_MESSAGE"]).pipe(take(1)).subscribe(v => {
          translations = v;
        });
        isTicketMention = CommonUtil.parseRedmineMentions(TICKET_MENTION, messageBody).length > 0 || CommonUtil.parseRedmineMentions(META_TASK_MENTION, messageBody).length > 0;
        try {
          if (CommonUtil.parseRedmineMentions(TICKET_MENTION, messageBody).length > 0) {
            const textToReplace = CommonUtil.parseRedmineMentions(TICKET_MENTION, messageBody)[0];
            let userjid = textToReplace.split("#")[1];
            let mentionText = translations.TICKET_MENTION_RECEIVER_MESSAGE.replace("{{ sender_name }}", this.contactRepo.getFullName(this.message.fromJid));
            if (this.userJID?.bare !== userjid) {
              mentionText = translations.TICKET_MENTION_SENDER_MESSAGE.replace("{{ receiver_name }}", this.contactRepo.getFullName(userjid));
            }
            isTicketMention = true;
            messageBody = messageBody.replace(textToReplace, mentionText).replace(/&lt;/g, "<").replace(/&gt;/g, ">");
          } else if (CommonUtil.parseRedmineMentions(META_TASK_MENTION, messageBody).length > 0) {
            const textToReplace = CommonUtil.parseRedmineMentions(META_TASK_MENTION, messageBody)[0];
            let userjid = textToReplace.split("#")[1];
            let mentionText = translations.META_TASK_MENTION_RECEIVER_MESSAGE.replace("{{ sender_name }}", this.contactRepo.getFullName(this.message.fromJid));
            if (this.userJID?.bare !== userjid) {
              mentionText = translations.META_TASK_MENTION_SENDER_MESSAGE.replace("{{ receiver_name }}", this.contactRepo.getFullName(userjid));
            }
            isTicketMention = true;
            messageBody = messageBody.replace(textToReplace, mentionText).replace(/&lt;/g, "<").replace(/&gt;/g, ">");
          }
        } catch (error) {

        }
      }

      if (!!this.message.cachedContent && !this.isSearchResult && !skipEmoji && !isTicketMention) {
        messageBody = this.message.cachedContent;
      } else {
        messageBody = CommonUtil.generateCachedBody(messageBody, this.isSearchResult ? this.keyword : null, skipEmoji);
        if (!this.isSearchResult && !skipEmoji) {
          const newMsg = {...this.message, cachedContent: messageBody};

          this.conversationRepo.getSelectedConversation().pipe(filter(res => !!res), take(1)).subscribe(selectedConversation => {
            const convTarget = !!this.message.convTarget ? this.message.convTarget : selectedConversation.Target;
            this.conversationRepo.updateMessage(newMsg, convTarget);
          });

        }
      }

      // mentions
      if (!!messageBody) {
        if (CommonUtil.parseMentions(messageBody).length > 0 || this.message.type === "groupchat" && messageBody.match(/@all\b/ig)) {
          messageBody = this.contactRepo.renderMentionUsers(messageBody, "html", references);
          this.hasMention = true;
          this.changeDetectorRef.markForCheck();
        }
      } else {
        messageBody = "";
      }

      if (this.message.group_action && (this.message.group_action === "ADD_PARTICIPANTS"
      || this.message.group_action.type === "ADD_PARTICIPANTS")) {
        if (this.message.group_action.data) {
          const participants = this.message.group_action.data.split(",");
          messageBody = messageBody.split("<br>")[1];
          if (!messageBody) { // e.g. when add a new user to omemo chat
            messageBody = "";
            participants.forEach((pJid, index) => {
              const pDisplayName = pJid.split("@")[0].replace(".", " ");
              messageBody += pDisplayName;
              if (index !== participants.length - 1) {
                messageBody += ", ";
              }
            });
          }
          this.totalParticipants = participants.length;
        } else {
           const firstLine = messageBody.split("<br>")[0];
           messageBody = messageBody.split("<br>")[1];
           if (!messageBody) { // e.g. when add a new user to omemo chat
             this.totalParticipants = 1;
             messageBody = "";
           } else {
             this.totalParticipants = parseInt(firstLine.replace(/\D+/g, ""));
           }
        }
        this.logger.info("[MessageComponent][ADDN] message: ", this.message);
        this.actorName = this.contactRepo.getFullName(this.fromJid);
        this.translationKey = this.totalParticipants > 1 ? "USER_ADD_N_PARTICIPANTS" : "USER_ADD_N_PARTICIPANT";
      } else if (this.message.group_action && (this.message.group_action === "UPDATE_AVATAR"
      || this.message.group_action.type === "UPDATE_AVATAR")) {
        this.fullActorName = messageBody.replace("has updated the group photo", "").replace("hat das Foto der Gruppe aktualisiert", "");
        this.translationKey = "HAS_UPDATED_GROUP_PHOTO";
      } else if (this.message.group_action && (this.message.group_action === "UPDATE_ENCRYPTION"
        || this.message.group_action.type === "UPDATE_ENCRYPTION" || (!!this.message.group_action.type.type && this.message.group_action.type.type === "UPDATE_ENCRYPTION"))) {
        this.translationKey = "ENABLED_E2E_ENCRYPTION";
        if (messageBody.indexOf("disabled E2E encryption for the chat") !== -1) {
          this.translationKey = "DISABLED_E2E_ENCRYPTION";
        }
        this.fullActorName = messageBody.replace("enabled E2E encryption for the chat", "").replace("disabled E2E encryption for the chat", "");
      }
       else if (this.message.group_action && (this.message.group_action === "created"
      || this.message.group_action.type === "created")) {
        this.logger.info("groupCreatedCheck: ", this.message);
        if (!!this.message.convTarget){
          if (this.message.convTarget.indexOf("talk_meeting@conference") > -1) {
            this.isMeeting = true;
          }
        }
        this.translationParam = { groupName: messageBody.replace(/Group\s"/, "").replace(/"\sCreated/, "")};
      } else if (!!this.message.group_action && typeof this.message.group_action?.type  === "string" && this.message.group_action?.type?.indexOf("MISSED_CALL") !== -1) {
        try {
          const data = JSON.parse(this.message.group_action?.type);
          this.missCallJids = data.jids;
        } catch (ex) {
          this.logger.sentryErrorLog("parse group_action error", ex);
        }
      } else if (this.message.group_action && this.message.group_action.type === "JOINED_PARTICIPANT") {
        const joinee = this.message.body.split(" ")[0];
        this.fullActorName = this.contactRepo.getFullName(joinee);
        if (joinee === this.userJID?.bare) {
          this.translationKey = "YOU_JOINED_THE_GROUPCHAT";
        } else {
          this.translationKey = "JOINED_THE_GROUPCHAT";
        }
      } else if (this.message.group_action && this.message.group_action.type === "LEAVE_GROUP") {
        this.actorName = this.contactRepo.getFullName(this.fromJid);
        if (this.fromJid === this.userJID?.bare) {
          this.translationKey = "YOU_LEFT_THE_GROUPCHAT";
        } else {
          this.translationKey = "LEFT_THE_GROUPCHAT";
        }
      }

      const generateSecretKey = "_._" + this.message.to.bare;
      const isMentionedMessageFromTopic = this.message?.body?.startsWith(generateSecretKey);
      if (messageBody && messageBody.includes(TOPIC_MENTION_MESSAGE_RECEIVER) && isMentionedMessageFromTopic) {
        if (this.isMyMessage) {
          const receiverName = this.getFullName.transform(this.message.to.bare);
          const translatedMessage = await this.translate.get("TOPIC_MENTION_MESSAGE_SENDER").pipe(take(1)).toPromise();
          const senderSideMessage = translatedMessage.replace("{{ USERNAME }}", receiverName);
          messageBody = messageBody.replace(TOPIC_MENTION_MESSAGE_RECEIVER, senderSideMessage);
        } else {
          const translatedMessage = await this.translate.get("TOPIC_MENTION_MESSAGE_RECEIVER").pipe(take(1)).toPromise();
          messageBody = messageBody.replace(TOPIC_MENTION_MESSAGE_RECEIVER, translatedMessage);
        }
        messageBody = messageBody.replace(generateSecretKey, "");
      }

      if (this.message.forwardMessage) {
        let translations: any = {};
        this.translate.get(["TICKET_MENTION_RECEIVER_MESSAGE", "TICKET_MENTION_SENDER_MESSAGE", "META_TASK_MENTION_RECEIVER_MESSAGE", "META_TASK_MENTION_SENDER_MESSAGE"]).pipe(take(1)).subscribe(v => {
          translations = v;
        });
        try {
          if (CommonUtil.parseRedmineMentions(TICKET_MENTION, messageBody).length > 0) {
            const textToReplace = CommonUtil.parseRedmineMentions(TICKET_MENTION, messageBody)[0];
            let userjid = textToReplace.split("#")[1];
            let mentionText = translations.TICKET_MENTION_RECEIVER_MESSAGE.replace("{{ sender_name }}", this.contactRepo.getFullName(this.message.forwardMessage.from));
            if (this.userJID?.bare !== userjid) {
              mentionText = translations.TICKET_MENTION_SENDER_MESSAGE.replace("{{ receiver_name }}", this.contactRepo.getFullName(userjid));
            }
            messageBody = messageBody.replace(textToReplace, mentionText);
          } else if (CommonUtil.parseRedmineMentions(META_TASK_MENTION, messageBody).length > 0) {
            const textToReplace = CommonUtil.parseRedmineMentions(META_TASK_MENTION, messageBody)[0];
            let userjid = textToReplace.split("#")[1];
            let mentionText = translations.META_TASK_MENTION_RECEIVER_MESSAGE.replace("{{ sender_name }}", this.contactRepo.getFullName(this.message.forwardMessage.from));
            if (this.userJID?.bare !== userjid) {
              mentionText = translations.META_TASK_MENTION_SENDER_MESSAGE.replace("{{ receiver_name }}", this.contactRepo.getFullName(userjid));
            }
            messageBody = messageBody.replace(textToReplace, mentionText);
            this.logger.info("[processMessageBody][forwardMessage] META_TASK_MENTION", messageBody);
          }
        } catch (error) {

        }
        if (!processOnly) {
          this.messageBody = this.sanitizer.bypassSecurityTrustHtml(messageBody.replace(/<a href=/g, "<a target=\"_blank\" class=\"open-new-window\" href=")
          .replace(/<a class="([a-z\s0-9]*)"\shref=/g, "<a target=\"_blank\" class=\"open-new-window\" href="));
        }
      }

      if (messageBody.includes("http://") || messageBody.includes("https://")) {
        this.checkForChannelEntities(messageBody);
      }
      if (!skipEmoji) {
        this.messageBody = this.sanitizer.bypassSecurityTrustHtml(messageBody.replace(/<a href=/g, "<a target=\"_blank\" class=\"open-new-window\" href=")
        .replace(/<a class="([a-z\s0-9]*)"\shref=/g, "<a target=\"_blank\" class=\"open-new-window\" href="));
        this.changeDetectorRef.markForCheck();
      }
      resolve(messageBody);
    });

  }

  checkForChannelEntities(msgBody: string) {
    const urls = msgBody.split("http");
    const firstUrl = urls[1] || "";
    const urlTokens = firstUrl.split("/");
    if (firstUrl.includes(ChannelEntityType.COMMENT) && firstUrl.includes(ChannelEntityType.TOPIC) && firstUrl.includes(ChannelEntityType.CHANNEL)) {
      const commentId = this.getCommentId(urlTokens, true);
      if (commentId) {
        this.fetchEntity(ChannelEntityType.COMMENT, commentId);
      }
    } else if (firstUrl.includes(ChannelEntityType.TOPIC) && firstUrl.includes(ChannelEntityType.CHANNEL)) {
      const topicId = this.getTopicId(urlTokens, true);
      if (topicId) {
        this.fetchEntity(ChannelEntityType.TOPIC, topicId);
      }
    } else if (firstUrl.includes(ChannelEntityType.SOCIAL)) {
      const socialPostId = this.getSocialId(urlTokens, true);
      if (socialPostId) {
        this.fetchEntity(ChannelEntityType.SOCIAL, socialPostId);
      }
    } else if (firstUrl.includes(ChannelEntityType.CHANNEL)) {
      const channelId = this.getChannelId(urlTokens, true);
      if (channelId) {
        this.fetchEntity(ChannelEntityType.CHANNEL, channelId);
      }
    }
  }

  private getChannelId(urlTokens, checkForEnd = false) {
    const indexOfChannelKey = urlTokens.indexOf(ChannelEntityType.CHANNEL);
    const id = urlTokens?.[indexOfChannelKey + 1];
    return checkForEnd ? id?.split("\"")?.[0] : id;
  }

  private getTopicId(urlTokens, checkForEnd = false) {
    const indexOfTopicKey = urlTokens.indexOf(ChannelEntityType.TOPIC);
    const id = urlTokens?.[indexOfTopicKey + 1].split("?")?.[0];
    return checkForEnd ? id?.split("\"")?.[0] : id;
  }

  private getSocialId(urlTokens, checkForEnd = false) {
    const indexOfTopicKey = urlTokens.indexOf("status");
    const id = urlTokens?.[indexOfTopicKey + 1].split("?")?.[0];
    return checkForEnd ? id?.split("\"")?.[0] : id;
  }

  private getCommentId(urlTokens, checkForEnd = false) {
    const indexOfTopicKey = urlTokens.indexOf(ChannelEntityType.TOPIC);
    const id = urlTokens?.[indexOfTopicKey + 1].split("?")?.[1]?.split("=")?.[1];
    return checkForEnd ? id?.split("\"")?.[0] : id;
  }

  private fetchEntity(entityType, entityId) {
    if (entityType === ChannelEntityType.COMMENT) {
      this.channelRepository.selectCommentById(entityId).pipe(takeUntil(this.isAlive$))
        .subscribe(async (res: any) => {
          if (res?.notFound || res?.noAccess) { return; }
          const preview = (await this.getMappedData({previewType: ChatPreviewType.COMMENT, resultType: SmartLinkSearchResultType.COMMENT, resultData: {...res, resultType: SmartLinkSearchResultType.COMMENT}})).getValue();
          this.channelEntityPreviewType$.next(preview.previewType);
          this.channelEntityPreviewData$.next(preview.previewData);
          let temp = this.channelEntityPreviewData$.getValue();
          temp.channelFullData.description = temp.channelFullData.description.slice(0,40) + "...";
          this.channelEntityPreviewData$.next(temp);
        });
    }
    if (entityType === ChannelEntityType.TOPIC) {
      this.channelRepository.selectTopicDetail(entityId).pipe(takeUntil(this.isAlive$))
        .subscribe(async (res: any) => {
          if (res?.notFound || res?.noAccess) { return; }
          let heroAttachments = this.channelRepository.generateHeaderAttachments(res);
          heroAttachments = this.channelRepository.generateLocalAttachmentsURLs(heroAttachments, res?.is_iom);
          const preview = (await this.getMappedData({previewType: ChatPreviewType.TOPIC, resultType: SmartLinkSearchResultType.TOPIC, resultData: {...res, resultType: SmartLinkSearchResultType.TOPIC, heroAttachments: heroAttachments }})).getValue();
          this.channelEntityPreviewType$.next(preview.previewType);
          this.channelEntityPreviewData$.next({topicData: {...res, resultType: SmartLinkSearchResultType.TOPIC, heroAttachments: heroAttachments }});
        });
    }
    if (entityType === ChannelEntityType.SOCIAL) {
      this.socialRepository.getPostById(entityId).pipe(takeUntil(this.isAlive$))
        .subscribe(async res => {
          if (!!res) {
            let heroAttachments = res.attachments;
            heroAttachments = this.socialRepository.generateLocalAttachmentsURLForSocial(heroAttachments);
            let prefixContent = "";
            if (!!res.parent_post) {
              this.translate.get("REPLYING_TO").pipe(take(1)).subscribe((text: string) => {
                this.replyToText = text;
                this.changeDetectorRef.markForCheck();
              });
              prefixContent = `<p><span class="reply-lable-text">${this.replyToText} </span><span class="reply-lable-id-name">${res?.parent_post?.author?.jid}</span></p>`;
            }
            const preview = (await this.getMappedData({previewType: ChatPreviewType.SOCIAL, resultType: "SOCIAL", resultData: {...res, resultType: "SOCIAL", prefixContent: prefixContent, heroAttachments: heroAttachments }})).getValue();
            this.channelEntityPreviewType$.next(preview.previewType);
            this.channelEntityPreviewData$.next({socialPostData: {...res, resultType: "SOCIAL", prefixContent: prefixContent, heroAttachments: heroAttachments }});
          } else {
            this.logger.error("No Post Found");
          }
         });
    }
    if (entityType === ChannelEntityType.CHANNEL) {
      this.channelRepository.selectChannelDetail(entityId).pipe(takeUntil(this.isAlive$))
        .subscribe(async (res: any) => {
          if (res?.notFound || res?.noAccess) { return; }
          const preview = (await this.getMappedData({previewType: ChatPreviewType.CHANNEL, resultType: SmartLinkSearchResultType.CHANNEL, resultData: {...res, resultType: SmartLinkSearchResultType.CHANNEL}})).getValue();
          this.channelEntityPreviewType$.next(preview.previewType);
          this.channelEntityPreviewData$.next(preview.previewData);
          let temp = this.channelEntityPreviewData$.getValue();
          temp.channelFullData.description = temp.channelFullData.description.slice(0,40) + "...";
          this.channelEntityPreviewData$.next(temp);
        });
    }
  }

  private async getMappedData(smartLinkData: ChatPreviewData | any) {
    const data = new BehaviorSubject<{ previewType: SmartLinkData["previewType"] | any, previewData: any, resultData: any }>({
      previewType: smartLinkData.previewType,
      previewData: smartLinkData?.resultType === "SOCIAL" ? this.socialRepository.getMappedPreviewData(smartLinkData.resultData) : this._smartLinkService.getMappedSmartLinkPreviewData(smartLinkData.resultData),
      resultData: smartLinkData.resultData
    });

    if (smartLinkData?.resultType === SmartLinkSearchResultType.CHANNEL) {
      const { channelFullData } = this._smartLinkService.getMappedSmartLinkPreviewData(smartLinkData.resultData);
      const channelMembersAndTopics = await this.getChannelTopicAndMembers(smartLinkData.resultData.id).pipe(take(1)).toPromise();
      data.next({
        previewType: smartLinkData.previewType,
        previewData: { channelFullData: { ...channelFullData, ...channelMembersAndTopics } },
        resultData: smartLinkData.resultData
      });
    }
    return data;
  }

  private getChannelTopicAndMembers(channelId: Channel["id"]) {
    const $channelMembersOb = this.channelRepository.getMembersOfChannel(channelId);
    return combineLatest([$channelMembersOb])
      .pipe(takeUntil(this.isAlive$))
      .pipe(map((res: any) => {
        const members = res[0];
        return {
          members
        };
      }));
  }

  openEntity() {
    const previewType = this.channelEntityPreviewType$.getValue();
    const previewData = this.channelEntityPreviewData$.getValue();
    if (previewType === ChatPreviewType.COMMENT) {
      const { commentFullData } = previewData;
      const { channel_id, topic_id, id: comment_id } = commentFullData;
      if (commentFullData?.topic_is_social) {
        return;
      } else {
        this.router.navigateByUrl(`talk/channels/${channel_id}/topics/${topic_id}?commentId=${comment_id}`);
      }
    }
    if (previewType === ChatPreviewType.TOPIC) {
      const { topicData } = previewData;
      const { channel_id, id: topic_id } = topicData;
      if (topicData?.is_social) {
        return;
      } else {
        this.router.navigateByUrl(`talk/channels/${channel_id}/topics/${topic_id}`);
      }
    }
    if (previewType === ChatPreviewType.CHANNEL) {
      const { channelFullData } = previewData;
      const { id: channel_id } = channelFullData;
      if (channelFullData?.is_social) {
       return;
      } else  {
        this.router.navigateByUrl(`talk/channels/${channel_id}`);
      }
    }
  }
  commentAction(action: CommentAction) {
    const {commentFullData} = this.channelEntityPreviewData$.getValue();
    if (action === CommentAction.LIKE) {
      this.likeComment(commentFullData?.id, commentFullData?.liked);
      return;
    }
    this.openEntity();
  }

  topicActionClicked(event: { action: string, data: any }) {
    if (event.action === "OPEN_COMMENT_FORM") {
      const { topicData } = this.channelEntityPreviewData$.getValue();
      const { channel_id, id: topic_id, last_comment: comment } = topicData;
      let url = `talk/channels/${channel_id}/topics/${topic_id}`;
      if( comment.id ) {
        url += `?commentId=${comment.id}`;
      }
      this.router.navigateByUrl(url);
    }
  }

  likeTopic(topicId, liked: boolean) {
    if(!liked) {
      this.channelService.likesTopic(topicId).subscribe((v: any) => {
        this.updateTopicLikeStatusLocally(v);
      });
    }
    else {
      this.channelService.unlikesTopic(topicId).subscribe((v: any) => {
        this.updateTopicLikeStatusLocally(v);
      });
    }
  }

  updateTopicLikeStatusLocally(topic) {
    const {topicData} = this.channelEntityPreviewData$.getValue();
    this.channelEntityPreviewData$.next({topicData: {...topicData, liked: topic?.liked, likes_count: topic?.likes_count}});
  }

  likeComment(commentId, liked: boolean) {
    if(!liked) {
      this.channelService.likeComment(commentId).subscribe((v: any) => {
        if (v.comment) {
          this.updateCommentLikeStatusLocally(v.comment);
        }
      });
    } else {
      this.channelService.unlikeComment(commentId).subscribe((v: any) => {
        if (v.comment) {
          this.updateCommentLikeStatusLocally(v.comment);
        }
      });
    }
  }

  updateCommentLikeStatusLocally(comment) {
    const {commentFullData} = this.channelEntityPreviewData$.getValue();
    this.channelEntityPreviewData$.next({commentFullData: {...commentFullData, liked: comment?.liked, likes_count: comment?.likes_count}});
  }

  fileAction(event: {action: any, data: any}) {
    if (event.action === FileActionType.DOWNLOAD_ALL_FILES) {
      this.downloadFiles(event.data, true);
    }
    if (event.action === FileActionType.DOWNLOAD_FILE) {
      this.downloadFiles([event.data]);
    }
    if (event.action === FileActionType.COPY_FILE_LINK) {
      this.copyFileLink(event.data);
    }
    if (event.action === FileActionType.COPY_FILE_TO_CLIPBOARD || event.action === FileActionType.COPY_ALL_FILES_TO_CLIPBOARD) {
      this.copyFilesToClipboard();
    }
  }

  downloadFiles(files: File[], isBulk = false) {
    if (!isBulk) {
      this.fileActionsService.downloadFile(files[0]);
    } else {
      this.fileActionsService.downloadFilesAsZIP(files, `files.zip`);
    }
  }

  copyFileLink(file: File) {
    this.fileActionsService.copyFileLink(file);
  }

  copyFilesToClipboard() {
    this.toastService.show("UNDER_DEVELOPMENT");
  }


  displayPreview(time: number) {
    try {
      const formatStr = this.lang === "de" ? "dd.MM.yyyy" : "dd.MM.yyyy";
      return format(new Date(time), formatStr, {locale: this.locale});
    } catch (e) {
      return "";
    }
  }

  setDisplayTime(): void {
    const timestamp = this.messageTimestamp || this.message?.timestamp;
    if (!!timestamp) {
      const dateFormat = this.settings.dateFormat || this.lang === "de" ? "dd.MM.yyyy" : "dd/MM/yyyy";
      const today = new Date();
      let dd:any = today.getDate();
      let mm:any = today.getMonth() + 1;
      let yyyy:any = today.getFullYear();
      if(dd < 10) {
        dd = "0" + dd;
      }
      if(mm < 10) {
        mm = "0" + mm;
      }
      let isToDay = [yyyy, mm, dd].join("-");
      this.formatToday = format(new Date(isToDay), dateFormat);
      this.messageDate = format(new Date(timestamp), dateFormat);
      this.formatTimestamp = this.addTimestamp;
      const displayTimestamp = +(this.datetimeService.getDisplayTime(timestamp) + "").padEnd(13, "0");
      const formatStr = this.datetimeService.getFormatString(
        this.lang, this.isMiniChat ? true :
          this.formatToday === this.messageDate ? this.formatTimestamp : !this.formatTimestamp
      );

      this.displayTime = format(new Date(displayTimestamp), formatStr, {locale: this.locale});
      if (this.lang === "de") {
        this.displayTime = this.displayTime  + " Uhr";
      }
      if (this.message && this.message.forwardMessage && this.message.forwardMessage.timestamp) {
        this.setForwardTime(this.message.forwardMessage.timestamp);
      }
    }

    // for debug purpose
    if (SHOW_MESSAGE_HEIGHT_DEBUG_INFO) {
      this.displayTime =  `${this.displayTime} (${this.messageHeight})`;
    }
  }

  private setForwardTime(timestamp: number) {
    this.forwardTime = this.getDisplayTimeText(timestamp);
  }

  getDisplayTimeText(timestamp: number) {
    const displayTimestamp = +(timestamp + "").padEnd(13, "0");

    const timeFormat = this.settings.timeFormat || "p";
    const dateFormat = this.settings.dateFormat || this.lang === "de" ? "dd.MM.yyyy" : "dd/MM/yyyy";

    const formatStr = `${dateFormat} ${timeFormat}`;
    let time = format(new Date(displayTimestamp), formatStr, {locale: this.locale});
    if (this.lang === "de") {
      time += " Uhr";
    }
    return time;
  }

  getCallTime(): string {
    const displayTimestamp = this.datetimeService.getDisplayTime(this.messageTimestamp);

    let time = format(displayTimestamp, "HH:mm", {locale: this.locale});
    if (this.lang === "de") {
      time = time  + " Uhr";
    }

    return time;
  }

  getDisplayTime(timestamp): string {
    return DateUtil.getDisplayTime(+timestamp, this.lang, this.datetimeService, this.translate);
  }

  ngOnDestroy(): void {
    if (this.checkPlayable) {
      clearInterval(this.checkPlayable);
    }
    this.mainMenu = null;
    this.videoPlayer = null;
    this.changeDetectorRef.detach();
    this.isAlive$.next(false);
    this.isAlive$.complete();
    this.destroy$.next(false);
    this.destroy$.unsubscribe();
  }

  trustURL(url) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  getNickName(jid) {
    if (jid && jid.resource) {
      return jid.resource;
    }
    return jid;
  }

  getJID(jid) {
    if (jid && jid.resource && jid.domain) {
      return jid.resource.replace(/\s+/g, "") + "@" + jid.domain.replace("conference.", "");
    }
    return jid;
  }

  cancelFailedAttachment() {
    this.convRepo.removeFailedMessage(this.message);

    this.convRepo.resetMessages();
    this.notificationService.openSnackBarWithTranslation("MESSAGE_DELETED", {});
  }

  resendFailedAttachment() {
    // now open a file picker to choose a file again
    // (we can't resend the same file cause we do not have a real link to it)
    document.getElementById("attachFile").click();

    // w/o this the above click event is not working on iOS
    setTimeout(() => {
      this.convRepo.removeFailedMessage(this.message);
    }, 100);

    this.convRepo.resetMessages();
  }

  copyURL(id) {
    let input = <HTMLInputElement>document.getElementById("attachment" + id);
    if (environment.isCordova) {
      cordova.plugins.clipboard.copy(input.value);
      this.copiedToClipboard();
      return;
    }
    let isiOSDevice = navigator.userAgent.match(/ipad|iphone/i);
    if (isiOSDevice) {
      let editable = input.contentEditable;
      let readOnly = input.readOnly;

      input.contentEditable = "true";
      input.readOnly = false;

      let range = document.createRange();
      range.selectNodeContents(input);

      let selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      range = null;
      input.setSelectionRange(0, 999999);
      input.contentEditable = editable;
      input.readOnly = readOnly;
    } else {
      input.select();
    }
    input = null;
    document.execCommand("copy");
    this.copiedToClipboard();
  }

  copiedToClipboard() {
    this.translate.get(["COPIED_TO_CLIPBOARD"])
      .pipe(take(1))
      .subscribe(res => {
        if(res?.COPIED_TO_CLIPBOARD) {
          this.vncLibraryService.openSnackBar(res?.COPIED_TO_CLIPBOARD, "checkmark", "", "", 2000, "left", "left").subscribe();
        }
      });
  }

  async showProfile(event, jid) {
    this.logger.info("showProfile", event, this.message, this.el.nativeElement.querySelector(".avatar").getBoundingClientRect());
    if (CommonUtil.isOnMobileDevice()) {
      const options = {
        maxWidth: "100vw",
        width: "100vw",
        height: "100vh"
      };
      const { ProfileInfoDialogComponent } = await import(
        "app/shared/components/profile-info-dialog/profile-info-dialog.component");
      this.dialog.open(ProfileInfoDialogComponent, Object.assign({
        backdropClass: "vnctalk-form-backdrop",
        panelClass: "vnctalk-form-panel",
        disableClose: true,
        data: {
            jid: jid
        },
        autoFocus: true
    }, options));
    } else {
      this.store.dispatch(new SelectProfile(jid));
      CommonUtil.showCircularMenu(this.el.nativeElement);
    }

  }

  openMap(location) {
    let latLon = location.lat + "," + location.lng;
    if (typeof device !== "undefined" && device.platform.toUpperCase() === "ANDROID") {
      window.open("geo:" + latLon, "_system");
    } else if (typeof device !== "undefined" && device.platform.toUpperCase() === "IOS") {
      cordova.InAppBrowser.open("http://maps.apple.com/?sll=" + latLon + "&z=100&t=k", "_system", "location=yes");
    } else if (this.electronService.isElectron) {
      this.electronService.openExternalUrl("http://maps.google.com/?q=" + latLon);
    } else {
      window.open("http://maps.google.com/?q=" + latLon);
    }
  }

  private setUnfurledData(data: any) {
    this.messageUrlPreviewData = {background: data.background, title: data.title, description: data.description};

    this.setIsInsecureContentForUrlPreview();

    // store to db & redux

    this.conversationRepo.getSelectedConversation().pipe(filter(res => !!res), take(1)).subscribe(selectedConversation => {
      const convTarget = !!this.message.convTarget ? this.message.convTarget : selectedConversation.Target;
      this.conversationRepo.storeUrlUnfurledData(this.message, convTarget);
      this.changeDetectorRef.markForCheck();
    });
  }

  private setRedmineData(data: any, isUrlNotAvailable?: boolean) {
    this.messageRedminePreview = {...this.messageRedminePreview, ...data};
    this.logger.info("[MessageComponent][setRedmineData]", data, this.messageRedminePreview, this.message);

    if (!isUrlNotAvailable) {
      this.issueId = this.messageRedminePreview.id;
      this.dueDate = this.messageRedminePreview.due_date;
    } else {
      this.isRedmineUrl = false;
    }

    // store to db & redux
    this.message.redminePreview = this.messageRedminePreview;

    this.conversationRepo.getSelectedConversation().pipe(filter(res => !!res), take(1)).subscribe(selectedConversation => {
      const convTarget = !!this.message.convTarget ? this.message.convTarget : selectedConversation.Target;
      // for debugging / testing #https://vncproject.vnc.biz/issues/30003052-25435 - uncomment this line and filter console for "setRedmineData"
      // this.logger.info("[MessageComponent][setRedmineData]", data, this.messageRedminePreview, this.message, selectedConversation, convTarget);
      this.conversationRepo.storeRedmineData(this.message, convTarget);
      this.changeDetectorRef.markForCheck();
    });
  }

  private setRedmineVersionData(data: any, isUrlNotAvailable?: boolean) {
    if (data.status) {
      data.statusKey = data.status.toUpperCase();
    }
    this.messageRedmineVersionPreview = {...this.messageRedmineVersionPreview, ...data};
    this.logger.info("[MessageComponent][setRedmineVersionData]", data, this.messageRedmineVersionPreview);

    if (!isUrlNotAvailable) {
      this.dueDate = this.messageRedmineVersionPreview.due_date;
    } else {
      this.isRedmineUrl = false;
    }

    // store to db & redux
    this.message.versionPreview = this.messageRedmineVersionPreview;

    this.conversationRepo.getSelectedConversation().pipe(filter(res => !!res), take(1)).subscribe(selectedConversation => {
      const convTarget = !!this.message.convTarget ? this.message.convTarget : selectedConversation.Target;
      this.conversationRepo.storeRedmineVersionData(this.message, convTarget);
      this.changeDetectorRef.markForCheck();
    });
  }

  private setIsInsecureContentForUrlPreview() {
    if (this.hideInsecureContent && !!this.messageUrlPreviewData && this.messageUrlPreviewData.background
      && (!this.messageUrlPreviewData.background.startsWith("url(https")
      && !this.messageUrlPreviewData.background.startsWith("url('https"))) {
      this.isInsecureContent = true;
    } else {
      this.isInsecureContent = false;
    }
  }


  //
  /// Redmine preview
  //

  private getRemineTicketEditableRights(issueId: string): Observable<any> {
    const response = new Subject<any>();

    this.logger.info("[MessageComponent][checkPreviewEditable]", issueId);

    this.isGettingRemineTicketEditableRights = true;
    this.changeDetectorRef.markForCheck();

    this.conversationRepo.checkPreviewEditable(issueId).subscribe(res => {
      this.isGettingRemineTicketEditableRights = false;
      this.logger.info("[MessageComponent][checkPreviewEditable]", res);

      if (res && res.issue) {
        this.issue = res.issue;
        this.mentionableUsers = this.issue.mention.mentionable_users.map(v => {
          return {
            label: v.name,
            value: v.login
          };
        });
      } else {
        this.issue = {};
      }

      response.next(res);
    });

    return response.asObservable().pipe(take(1));
  }


  //
  // change priority

  canEditPriority() {
    if (this.issue) {
      return this.issue.priority?.can_change === "true";
    } else {
      return true;
    }
  }

  openChangePriorityForm() {
    if (!this.issue) {
      if (!this.isGettingRemineTicketEditableRights) {
        this.getRemineTicketEditableRights(this.issueId).subscribe(() => {
          if (this.canEditPriority()) {
            setTimeout(() => {
              this.openChangePriorityForm();
            });
          } else {
            this.showNoRightsToast();
          }

          this.changeDetectorRef.markForCheck();
        });
      }
    } else {
      this.prioritySelect.toggle();
    }
  }

  changePriority(priority) {
    this.updateIssues({priority_id: priority.id, lock_version: this.issue.lock_version });
  }


  //
  // change status

  canEditStatus() {
    if (this.issue) {
      return this.issue.status?.can_change === "true";
    } else {
      return true;
    }
  }

  openStatusChangeForm() {
    this.logger.info("[MessageComponent][openStatusChangeForm]");
    if (!this.issue) {
      if (!this.isGettingRemineTicketEditableRights) {
        this.getRemineTicketEditableRights(this.issueId).subscribe(() => {
          if (this.canEditStatus()) {
            setTimeout(() => {
              this.openStatusChangeForm();
            });
          } else {
            this.showNoRightsToast();
          }

          this.changeDetectorRef.markForCheck();
        });
      }
    } else {
      this.logger.info("[MessageComponent][openStatusChangeForm] statusSelect.toggle");
      this.statusSelect.toggle();
    }
  }

  changeStatus(status) {
    this.updateIssues({status_id: status.id, lock_version: this.issue.lock_version });
  }


  //
  // change Due date

  canEditDueDate() {
    if (this.issue) {
      return this.issue.due_date?.can_change === "true";
    } else {
      return true;
    }
  }

  openCalendar() {
    if (this.canEditDueDate()) {
      this.dueDatePicker.open();
    }
  }

  onDateChange(event: any) {
    const newDate = format(new Date(event.value), "yyyy-MM-dd");

    if (!this.issue) {
      if (!this.isGettingRemineTicketEditableRights) {
        this.getRemineTicketEditableRights(this.issueId).subscribe(() => {
          if (this.canEditDueDate()) {
            this.updateIssues({due_date: newDate, lock_version: this.issue.lock_version });
          } else {
            this.showNoRightsToast();
          }

          this.changeDetectorRef.markForCheck();
        });
      }
    } else {
      this.updateIssues({due_date: newDate, lock_version: this.issue.lock_version });
    }
  }


  //
  // change OTA

  canEditOTA() {
    if (this.issue) {
      return this.issue?.ota?.can_change === "true";
    } else {
      return true;
    }
  }

  showOTAForm() {
    this.logger.info("[MessageComponent][showOTAForm]");

    if (!this.issue) {
      if (!this.isGettingRemineTicketEditableRights) {
        this.getRemineTicketEditableRights(this.issueId).subscribe(() => {
          if (this.canEditOTA()) {
            this.showOTAForm();
          } else {
            this.showNoRightsToast();
          }

          this.changeDetectorRef.markForCheck();
        });
      }
    } else {
      this.isOTAFormDisplayed = this.issue.ota?.can_change === "true";
    }
  }

  closeOTAForm() {
    this.isOTAFormDisplayed = false;
    this.changeDetectorRef.markForCheck();
  }

  updateOta() {
    this.isOTAFormDisplayed = false;
    this.updateIssues({ota: this.messageRedminePreview.ota, lock_version: this.issue.lock_version });
  }


  //
  // change ETA

  canEditEta() {
    if (this.issue) {
      return this.issue.estimated_hours?.can_change === "true";
    } else {
      return true;
    }
  }

  showETAForm() {
    this.logger.info("[MessageComponent][showETAForm]");
    if (!this.issue) {
      if (!this.isGettingRemineTicketEditableRights) {
        this.getRemineTicketEditableRights(this.issueId).subscribe(() => {
          if (this.canEditEta()) {
            this.showETAForm();
          } else {
            this.showNoRightsToast();
          }

          this.changeDetectorRef.markForCheck();
        });
      }
    } else {
      this.isETAFormDisplayed = this.issue.estimated_hours?.can_change === "true";
    }
  }

  updateETA() {
    this.isETAFormDisplayed = false;
    this.updateIssues({estimated_hours: this.messageRedminePreview.estimated_hours, lock_version: this.issue.lock_version });
  }

  closeETAForm() {
    this.isETAFormDisplayed = false;
    this.changeDetectorRef.markForCheck();
  }

  //
  // Comments

  canAddComment() {
    if (this.issue) {
      return this.issue.mention?.can_mention === "true";
    } else {
      return true;
    }
  }

  addComment() {
    this.updateIssues({notes: this.commentText, lock_version: this.issue ? this.issue.lock_version : 1 });
    this.isCommentFormDisplayed = false;
    this.commentText = "";
    this.changeDetectorRef.markForCheck();
  }

  openCommentForm() {
    this.logger.info("[MessageComponent][openCommentForm]");

    if (!this.issue) {
      if (!this.isGettingRemineTicketEditableRights) {
        this.getRemineTicketEditableRights(this.issueId).subscribe(() => {
          if (this.canAddComment()) {
            this.openCommentForm();
          } else {
            this.showNoRightsToast();
          }

          this.changeDetectorRef.markForCheck();
        });
      }
    } else {
      this.isCommentFormDisplayed = true;
      this.commentText = "";
      this.changeDetectorRef.markForCheck();

      this.broadcaster.broadcast("messageOpenCommentForm", this.message.id);

      setTimeout(() => {
        if (this.el.nativeElement.querySelector(".commentText")) {
          this.el.nativeElement.querySelector(".commentText").focus();
        }
      }, 500);
    }
  }

  //

  updateIssues(data) {
    this.logger.info("[MessageComponent][updateIssues]", data);

    this.isUpdatingTicket = true;

    this.conversationRepo.updateIssues(this.issueId, data).subscribe(res => {
      this.isUpdatingTicket = false;

      if (res.error) {
        // if (isArray(res.error)) {
        //  this.toastService.showSnackbar(res.error[0]);
        //} else {
          this.toastService.showSnackbar(res.error);
        // }
      } else {
        this.setRedmineData(res.issue);

        this.issue.lock_version = res.issue.lock_version;
      }
    });
  }

  private showNoRightsToast() {
    this.notificationService.openSnackBarWithTranslation("NOT_ALLOWED", {});
  }


  //
  //

  private _isMyMessage() {
    const isM = this.userJID && this.fromJid === this.userJID?.bare;
    // this.logger.info("[MessageComponent][_isMyMessage]", isM, this.fromJid, this.userJID?.bare, this.message.body, this.message);
    return isM;
  }

// returns true if this message is a placeholder message for file upload
  isPlaceholderMessage() {
    return this.message.attachment && this.isPending();
  }

  downloadFileAttachment() {
    if (!this.message.attachment || !this.message.attachment.url) {
      return;
    }
    let fileUrl = this.translatedUrl;
    let mimeType = CommonUtil.getMimeType(fileUrl);
    this.logger.info("[MessageComponent][downloadFileAttachment] fileUrl", mimeType, fileUrl);
    // iOS/Android
    if (environment.isCordova) {

      let headers = [];
      // we need this for HIN only ('if (environment.theme === "hin")'),
      // but it also works with a standard deployment, so we decided to keep it universal.
      headers.push(
        {
            Key: "Authorization",
            Value: localStorage.getItem("token")
        }
      );

      this.filesStorageService.downloadAndSaveFileInBackground(fileUrl, headers, CommonUtil.isOnIOS()).subscribe((localFileUrl) => {
        this.logger.info("[MessageComponent][downloadInBackgroundFileAsBlob] success, localFileUrl: ", localFileUrl);

        if (CommonUtil.isOnAndroid()) {
          this.translate.get("FILE_DOWNLOADED").pipe(take(1)).subscribe(text => {
            alert(text);
          });
        } else {
          this.openLocalFile(localFileUrl);
        }
      }, err => {
        this.logger.error("[MessageComponent][downloadInBackgroundFileAsBlob] err", err);
        this.toastService.show("SOME_UNKNOWN_ERROR");
      });

      this.toastService.show("FILE_STARTED_DOWNLOADING");

    // Electron
    } else if (environment.isElectron) {
      if (CommonUtil.isVideo(this.message.attachment.fileType)) {
        fileUrl = `${fileUrl}?d=true`;
      }
    // ElectronService.downloadFile(this.message.attachment.url, "");
      this.logger.info("[MessageComponent][downloadFileAttachment] electron", fileUrl);
      this.electronService.fileDownloader(fileUrl).pipe(take(1)).subscribe(() => {
        this.translate.get("FILE_DOWNLOADED").pipe(take(1))
          .subscribe(text => {
            this.notificationsService.html("", text, "notify", {
              timeOut: 2000
            });
            this.changeDetectorRef.markForCheck();
          });
      }, (error) => {
        this.logger.info("[PreviewImageDialogComponent][downloadFile] error: ", error);
      });
    } else {
      // window.open(fileUrl + "?d=true", "_blank");
      let messageDetails = {
        messageId: this.message.id,
        fileSize: this.message.attachment.fileSize,
        fileName: this.message.attachment.fileName,
        loaded: 0,
        total: parseInt(this.message.attachment.fileSize),
        url: fileUrl,
      };
      this.convRepo.downloadFile(messageDetails);
    }
  }

  private openLocalFile(localFileUrl: string) {
    let mimeType = CommonUtil.getMediaType(localFileUrl);
    this.logger.info("[MessageComponent][openLocalFile] localFileUrl: " + localFileUrl + ", mimeType: " + mimeType);

    if (CommonUtil.isOnAndroid()) {
      // this plugin is only for Android
      cordova.plugins.FileOpener.canOpenFile(localFileUrl, () => {
        cordova.plugins.FileOpener.openFile(localFileUrl, () => {
          this.logger.info("[MessageComponent][openLocalFile] success");
        }, (error) => {
          this.logger.error("[MessageComponent][openLocalFile] openFile error", error);
          this.toastService.show("SOME_UNKNOWN_ERROR");
        });
      }, (error) => {
        this.logger.error("[MessageComponent][downloadFileAttachment] canOpenFile error", error);
        this.toastService.show("FILE_DOWNLOADED");
      });

    // iOS
    } else {
      this.logger.info("[MessageComponent][downloadFileAttachment] showOpenWithDialog", localFileUrl, mimeType);
      if (CommonUtil.isOnIpad()) {
        mimeType = CommonUtil.getMimeType(localFileUrl);
        this.logger.info("[MessageComponent][downloadFileAttachment] showOpenWithDialog", localFileUrl, mimeType);
        cordova.plugins.fileOpener2.open(
          localFileUrl,
          mimeType,
          {
              error : (e)  => {
                  this.logger.error("[MessageComponent][downloadFileAttachment] Error status: " + e.status + " - Error message: " + e.message);
              },
              success : () => {
                  this.logger.info("[MessageComponent][downloadFileAttachment] file opened successfully");
              }
          }
      );
      } else {
        cordova.plugins.fileOpener2.showOpenWithDialog(
          localFileUrl,
          mimeType,
          {
              error : (e)  => {
                  this.logger.error("[MessageComponent][downloadFileAttachment] Error status: " + e.status + " - Error message: " + e.message);
              },
              success : () => {
                  this.logger.info("[MessageComponent][downloadFileAttachment] file opened successfully");
              }
          }
        );
      }

    }
  }

  DownloaderError(err) {
    this.logger.info("download error: " + err);
    alert("download error: " + err);
  }

  DownloaderSuccess() {
    this.logger.info("download succes");
    let filePath2 = localStorage.getItem("dfp");

    let target = "_system";
    /* if (typeof device !== "undefined"
      && device.platform
      && device.platform.toUpperCase() === "ANDROID"
      && (filePath2.endsWith(".jpeg") || this.localFilePath.endsWith(".png") || this.localFilePath.endsWith(".jpg") || this.localFilePath.endsWith(".gif"))) {
      target = "_blank";
    } */

    if (CommonUtil.isOnAndroid() && cordova.plugins && cordova.plugins.FileOpener) {
      let url = cordova.file.externalCacheDirectory + "downloads/" + localStorage.getItem("dfp");
      this.logger.info("[message.component][DownloadSuccess] testing file: ", url);
      cordova.plugins.FileOpener.canOpenFile(url, () => {
        this.logger.info("[message.component][DownloadSuccess] canOpenFile true: ", url);
        cordova.plugins.FileOpener.openFile(url, () => {
          this.logger.info("[message.component][DownloadSuccess] opened file succes: ", url);
          localStorage.removeItem("dfp");
        }, (error) => {
          this.logger.info("[message.component][DownloadSuccess] opened file error: ", error);
          this.toastService.show("SOME_UNKNOWN_ERROR");
          localStorage.removeItem("dfp");
        });
      }, () => {
        this.toastService.show("SOME_UNKNOWN_ERROR");
        localStorage.removeItem("dfp");
        this.logger.info("[message.component][DownloadSuccess] canOpenFile true", url);
      });
    } else {
      cordova.InAppBrowser.open(cordova.file.cacheDirectory + "downloads/" + filePath2, target, "location=no");
    }
  }

  isPending(): boolean {
    const isP = this.messageStatus === MessageStatus.PENDING || this.messageStatus === MessageStatus.SENDING;
    // this.logger.info("[message.component] isPending", isP, this.message.body);
    return isP;
  }

  isSent(): boolean {
    const isS = this.messageStatus === MessageStatus.SENT;
    // this.logger.info("[message.component] isSent", isS, this.message.body);
    return isS;
  }

  isDelivered(): boolean {
    return this.messageStatus === MessageStatus.DELIVERED;
  }

  isFailed(): boolean {
    return this.messageStatus === MessageStatus.FAILED;
  }

  containTextInBody(): boolean {
    return (this.message?.body || "").split("\n").filter(v => !!v).length > 1 || (this.message?.body || "").split(/\s+/g).filter(v => !!v).length > 1;
  }

  shouldShowBody() {
    return !this.message.originalMessage && this.message.body
      && (((!this.message.attachment || (this.message.attachment && this.containTextInBody() && !this.message.body.startsWith("blob:"))) && !this.message.location)
      || this.isSearchResult);
  }

  selectMessage() {
    this.convRepo.selectMessage(this.message.id);
    this.copyMessage(true);
  }

  replyMessage(): void {
    this.logger.info("[MessageComponent][replyMessage]", this.message);
    this.selectMessage();
    if (this.activeTab === "mention") {
      this.store.dispatch(new SetActiveTab("chat"));

      setTimeout(() => {
        this.convRepo.navigateToConversation(this.message.room);
      }, 100);
      setTimeout(() => {
        this.broadcaster.broadcast("replyMessage", this.message);
        this.logger.info("[MessageComponent][replyMessage] setTimeout", this.message);
      }, 500);
    } else {
      this.broadcaster.broadcast("replyMessage", this.message);
    }
  }

  deleteMessage(): void {
    this.logger.info("[MessageComponent][deleteMessage]", this.message);
    this.broadcaster.broadcast("deleteMessage", this.message);
  }

  async forwardMessage() {
    this.selectMessage();
    let dialogStyles = {
      "width": "517px",
      "maxWidth": "80vw",
      "height": "none",
      "maxHeight": "600px",
      "visibility": "visible"
    };
    if (CommonUtil.isMobileSize()) {
      dialogStyles = {
        "width": "100%",
        "maxWidth": "100%",
        "height": "100%",
        "maxHeight": "100%",
        "visibility": "visible"
      };
    }
    const { ForwardDialogComponent } = await import(
      "app/talk/shared/components/dialogs/forward/forward.component");
    this.dialog.open(ForwardDialogComponent, {
      backdropClass: "forward-dialog-backdrop",
      panelClass: "forward-dialog-panel",
      disableClose: false,
      autoFocus: false,
      width: dialogStyles.width,
      maxHeight: dialogStyles.maxHeight,
      maxWidth: dialogStyles.maxWidth,
      height: dialogStyles.height
    });
  }

  handleClickOnOriginalMessage() {
    this.logger.info("[handleClickOnOriginalMessage]", this.message?.originalMessage);
    if (this.message?.originalMessage && this.message?.originalMessage.attachment) {
      try {
        const attachment = JSON.parse(this.message?.originalMessage.attachment);
        if (CommonUtil.isImage(attachment.fileType) || CommonUtil.isSupportedVideo(attachment.fileType)) {
          const originalMsg = {...this.message?.originalMessage, attachment: attachment};
          this.previewDocument(originalMsg);
        }
      } catch (error) {

      }

    }
  }

  async copyMessage(skipNotification?: boolean) {
    let element = document.createElement("div");
    let body = "";
    if (this.message.originalMessage) {
      if (this.message.originalMessage.body && !this.message.originalMessage.htmlBody) {
        body = CommonUtil.unEscapeHTMLString(this.message.originalMessage.body.replace(/&/g, "&amp;"));
      } else if (this.message.originalMessage.htmlBody) {
        body = CommonUtil.unEscapeHTMLString(this.message.originalMessage.htmlBody.replace(/&/g, "&amp;"));
      }
      let origMessageObject: OriginalMessage = {
        ...this.message.originalMessage,
        replyMessage: this.message.originalMessage.replyMessage ? this.message.originalMessage.replyMessage.replace(/&/g, "&amp;") : "",
        body: body
      };

      let { repliedMessage, originalMessageHTML } = this.messageService.renderRepliedMessage(origMessageObject, this.message.references, true);
      if (CommonUtil.parseMentions(repliedMessage).length > 0 || CommonUtil.parseMentions(originalMessageHTML).length > 0
      || (this.message.type === "groupchat") && (repliedMessage.match(/@all\b/ig) || originalMessageHTML.match(/@all\b/ig))) {
        repliedMessage = this.contactRepo.renderMentionUsers(repliedMessage, "html", this.message.references, "");
      }

      element.innerHTML = repliedMessage ? repliedMessage.replace("<br />", "\n") : "";
      this.convRepo.contentForClipBoard[this.message.id] = element.innerText;
      if (!skipNotification) {
        CommonUtil.copyToClipboard([element.innerText]);
      }
    } else {
      (async() => {
        const msg = await this.processMessageBody(true, true);
        this.logger.info("[copyMessage]", msg.toString());
        element.innerHTML = msg.toString().replace(/<br(\s\/)?>/ig, "\n");
        this.convRepo.contentForClipBoard[this.message.id] = element.innerText;
        if (!skipNotification) {
          CommonUtil.copyToClipboard([element.innerText]);
        }
      })();

    }
    if (!skipNotification) {
      this.notificationService.openSnackBarWithTranslation("MESSAGE_COPIED", { total: 1 });
    }
  }

  async previewImage(data?: any) {
    let message = this.message;
    if (data) {
      message = data;
    }
    let dialogStyles: any = {
      "width": "auto",
      "height": "100%",
      "maxHeight": (window.outerHeight - 80) + "px",
      "maxWidth": window.outerWidth + "px",
      "visibility": "visible"
    };
    if (CommonUtil.isMobileSize()) {
      dialogStyles = {
        "width": "100%",
        "height": "100%",
        "maxHeight": "100%",
        "maxWidth": "100%",
        "visibility": "visible"
      };
    }
    if (CommonUtil.isOnIpad()) {
      dialogStyles = {
        "width": "auto",
        "maxWidth": "100%",
        "height": "100%",
        "visibility": "visible",
        "background-color": "transparent",
        "box-shadow": "none"
      };
    }
    const { PreviewImageDialogComponent } = await import(
      "../dialogs/preview-image/preview-image.component");
    this.dialog.open(PreviewImageDialogComponent, Object.assign({
      data: {
        imageUrl: message.attachment.url,
        message: message
      },
      backdropClass: "vnctalk-form-backdrop",
      panelClass: ["vnctalk-form-panel", "preview-image-host-component"],
      disableClose: true,
      autoFocus: true
    }, dialogStyles)).afterClosed().subscribe((data) => {
      if (data?.isDoubleTapped) {
        this.previewDocument(message, true);
      }
    });
  }

  startChat(): void {
    this.store.dispatch(new SetActiveTab("chat"));
    this.convRepo.navigateToConversation(this.message.fromJid);
  }

  startConference(conferenceType: string) {
    if (!this.appService.isAppOnline) {
      this.toastService.show("OFFLINE_MODE");
      return;
    }

    if (!this.configService.get("jitsiAvailable")) {
      this.toastService.show("CALLS_NOT_AVAILABLE");
      return;
    }

    this.store.dispatch(new SetActiveTab("chat"));
    this.conferenceRepo.leaveOldConference();

    let currentConversation: Conversation;
    this.convRepo.getSelectedConversation().pipe(filter(res => !!res), take(1)).subscribe(conv => {
      currentConversation = conv;
    });
    if (currentConversation && currentConversation.Target === this.message.fromJid) {
      this.conferenceRepo.startConference(this.message.fromJid, conferenceType);
    } else {
      this.convRepo.navigateToConversation(this.message.fromJid);
      this.router.events
        .pipe(filter(e => e instanceof NavigationEnd), take(1))
        .subscribe(() => {
          this.convRepo.getSelectedConversation().pipe(filter(res => !!res), take(1)).subscribe(conv => {
            if (conv && conv.Target === this.message.fromJid) {
              this.conferenceRepo.startConference(this.message.fromJid, conferenceType);
            }
          });
        });
    }
  }

  async emailToAFriend() {
    this.logger.info("[emailToAFriend]");
    let dialogStyles: any = {
      "width": "517px",
      "maxWidth": "80vw",
      "visibility": "visible"
    };
    if (CommonUtil.isMobileSize()) {
      dialogStyles = {
        "width": "100%",
        "height": "100%",
        "maxWidth": "100%",
        "visibility": "visible"
      };
    }
    const { EmailSelectedMessageComponent } = await import(
      "../email-selected-message/email-selected-message.component");
    this.matDialog.open(EmailSelectedMessageComponent, Object.assign({
      data: {message: this.message.body},
      backdropClass: "vnctalk-form-backdrop",
      panelClass: ["vnctalk-form-panel", "vnctalk-send-mail-to-friend"],
      disableClose: true,
      autoFocus: true
    }, dialogStyles));
  }

  addTask() {
    this.logger.info("[addTask]");
  }

  starMessage() {
    if (!this.message?.id) {
      return;
    }
    const messageId = this.message.id.replace(/.*_/ig, "");
    this.convRepo.addMessageFavorite(messageId).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.logger.info("[addMessageFavorite]", res);
      this.message.isStarred = true;
      this.onStarChange.emit(this.message);
      if (this.isSearchResult) {
        this.searchRepo.updateMessages([this.message]);
      }
      this.convRepo.updateMessageFavorite(messageId, true);

      this.conversationRepo.getSelectedConversation().pipe(filter(v => !!v), take(1)).subscribe(selectedConversation => {
        const convTarget = !!this.message.convTarget ? this.message.convTarget : selectedConversation.Target;
        this.databaseService.createOrUpdateMessages([{...this.message, isStarred: true}], convTarget);
        this.changeDetectorRef.markForCheck();
      });
      if (!!this.headerActions) {
        this.headerActions[0].label = this.translations.REMOVE_FROM_FAVORITES;
        this.headerActions[0].vncIconName = "favorites-filled-new";
      }
    });
  }

  unstarMessage() {
    if (!this.message?.id) {
      return;
    }
    const messageId = this.message.id.replace(/.*_/ig, "");
    this.convRepo.removeMessageFavorite(messageId).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.logger.info("[removeMessageFavorite]", res);
      this.message.isStarred = false;
      this.onStarChange.emit(this.message);
      if (this.isSearchResult) {
        this.searchRepo.updateMessages([this.message]);
      }
      this.convRepo.updateMessageFavorite(messageId, false);

      this.conversationRepo.getSelectedConversation().pipe(filter(v => !!v), take(1)).subscribe(selectedConversation => {
        const convTarget = !!this.message.convTarget ? this.message.convTarget : selectedConversation.Target;
        this.databaseService.createOrUpdateMessages([{...this.message, isStarred: false}], convTarget);
        this.changeDetectorRef.markForCheck();
      });
      if (!!this.headerActions) {
        this.headerActions[0].label = this.translations.ADD_TO_FAVORITES;
        this.headerActions[0].vncIconName = "favorites-new";
      }
    });
  }

  checkForChanges() {
    setTimeout(() => {
      this.changeDetectorRef.markForCheck();
    }, 0);
  }

  async underDevelopment() {
    this.toastService.show("UNDER_DEVELOPMENT");

  }

  getFullUrl(url: string) {
    return CommonUtil.getFullUrl(url);
  }

  setPlaceHolder() {
    if (this.isImage) {
      this.isImage = false;
      this.logger.info("[IMG] replace with URL!");
      setTimeout(() => {

        this.changeDetectorRef.markForCheck();
      }, 1000);
    }
    if (CommonUtil.isOnNativeMobileDevice()) {
      this.localUrl = CommonUtil.getFullUrl("/assets/loading-image.gif");
      this.changeDetectorRef.markForCheck();
    }
  }

  openUrl(event: any, url: string): void {
    if (environment.isCordova || this.electronService.isElectron) {
      event.preventDefault();
      event.stopPropagation();
      let serverURL = localStorage.getItem("serverURL");
      this.store.select(getBaseApiUrl).pipe(take(1)).subscribe(v => serverURL = v);
      if ((url.indexOf(`${serverURL}/talk/channels/`) !== -1) || (url.indexOf(`${serverURL}/talk/social/`) !== -1)){
        const channelUrl = url.split("/talk/")[1];
        this.router.navigateByUrl(`/talk/${channelUrl}`);
        if (CommonUtil.isOnMobileDevice()) {
          setTimeout(() => {
            this.broadcaster.broadcast("showRightPanel");
          }, 100);
        }
        return;
      }
      if (this.electronService.isElectron) {
        this.electronService.openExternalUrl(url);
      } else if (device.platform === "iOS") {
        window.open(url, "_system");
      } else if (device.platform === "Android") {
        navigator.app.loadUrl(url, {
          openExternal: true
        });
      }
    } else {
      const locationURL = window.location.origin;
      if ((url.indexOf(locationURL + "/talk/channels/") !== -1) || (url.indexOf(locationURL + "/talk/social/") !== -1)){
        event.preventDefault();
        event.stopPropagation();
        const channelUrl = url.split("/talk/")[1];
        this.router.navigateByUrl(`/talk/${channelUrl}`);
        if (CommonUtil.isOnMobileDevice()) {
          setTimeout(() => {
            this.broadcaster.broadcast("showRightPanel");
          }, 100);
        }
        return;
      }
    }
  }

  isBroadcastMessage(): boolean {

    let selectedConversation: Conversation;
    this.conversationRepo.getSelectedConversation().pipe(take(1)).subscribe(conv => selectedConversation = conv);
    if (!selectedConversation) {
      return false;
    }
    const isB = this.message.broadcast_owner
    || (this.message.from && this.message.from.local && this.message.from.local.startsWith("broadcast-"))
    || (this.message.to && this.message.to.local && this.message.to.local.startsWith("broadcast-"))
    || (this.message.forwardMessage && this.message.forwardMessage.from && this.message.forwardMessage.from.startsWith("broadcast-"))
    || selectedConversation && selectedConversation.Target.startsWith("broadcast-");

    return isB;
  }

  get isBroadcastOwner(): boolean {

    let selectedConversation: Conversation;
    this.conversationRepo.getSelectedConversation().pipe(take(1)).subscribe(conv => selectedConversation = conv);

    return selectedConversation && this.userJID && selectedConversation.broadcast_owner === this.userJID?.bare;
  }

  jumpToOriginal($event: any) {
    if ($event.target.classList.contains("open-new-window")) {
      this.openUrl($event, $event.target.href);
    } else if ( (this.activeTab === "mention" || this.activeFilter === "mention") && document.querySelector(".vnc-chat-window-message-action-header") === null) {
      let selectedConversation: Conversation;
      this.conversationRepo.getSelectedConversation().pipe(filter(res => !!res), take(1)).subscribe(conv => selectedConversation = conv);

      const currentTarget = selectedConversation?.Target;
      const messageId = this.message.id;

      this.logger.info("[MessageComponent][jumpToOriginal] messageId", messageId);
      this.store.dispatch(new SetActiveFilter("all"));
      this.store.dispatch(new SetActiveTab("chat"));
      this.conversationRepo.navigateToConversation(null);

      setTimeout(() => {
        this.conversationRepo.navigateToConversationAndJumpToMessage(currentTarget, this.message);
      });
    }
  }

  jumpToMessage() {
    let selectedConversation: Conversation;
    this.conversationRepo.getSelectedConversation().pipe(filter(res => !!res), take(1)).subscribe(conv => selectedConversation = conv);

    const currentTarget = selectedConversation.Target;
    this.logger.info("[jumpToMessage]", this.message);
    this.store.dispatch(new SetActiveTab("chat"));
    this.store.dispatch(new SetActiveFilter("all"));
    this.conversationRepo.navigateToConversation(null);
    setTimeout(() => {
      this.conversationRepo.navigateToConversationAndJumpToMessage(currentTarget, {id: this.message.id, timestamp: this.message.timestamp});

    });
  }

  showDuration(time: string): string {
    if (time.startsWith("00:")) {
      time = time.slice(3, time.length);
    }
    return time;
  }

  renderCallHistory(callHistoryMessages): string {
    if (!callHistoryMessages) {
      return;
    }

    // this.logger.info("[callHistoryMessages]", callHistoryMessages);

    let content = [];
    let callDuration;
    let isAtLeastOneJoinSignal = false;
    let joinCountPerUser = {};
    this.translate.get(["YOU", "USER_HAS_NOT_ANSWERED", "YOU_CALL", "YOU_JOINED", "YOU_REJOINED", "VERB", "STARTED", "JOINED", "REJOINED", "REJECTED", "ENDED", "CALL_ENDED"]).pipe(take(1)).subscribe(text => {

      callHistoryMessages.forEach(m => {

        let joinText = text.VERB;

        // name
        let name;
        if (this.userJID && m.fromJid === this.userJID?.bare) {
          name = text.YOU_CALL;
          joinText = "";
        } else {
          let contact: Contact;
          this.contactRepo.getContactById(m.fromJid).pipe(take(1)).subscribe(c => {
            contact = c;
          });
          //
          if (!!contact) {
            name = contact.name;
          } else {
            name = !!m.fromJid ? m.fromJid.split("@")[0] : "";
          }
        }

        // action
        const eventType = m.vncTalkConference.eventType;
        let action = "";
        switch (eventType) {
          case "join": {

            // track re-joins?
            if (!joinCountPerUser[m.fromJid]) {
              joinCountPerUser[m.fromJid] = 0;
            }
            ++joinCountPerUser[m.fromJid];

            if (this.userJID && m.fromJid === this.userJID?.bare) {
              name = text.YOU;

              if (joinCountPerUser[m.fromJid] === 1) {
                action = text.YOU_JOINED;
              } else {
                action = text.YOU_REJOINED;
              }
            } else {
              if (joinCountPerUser[m.fromJid] === 1) {
                action = text.JOINED;
              } else {
                action = text.REJOINED;
              }
            }

            isAtLeastOneJoinSignal = true;

            break;
          }
          case "leave": action = joinText + " " + text.ENDED; break;
          case "cancel": action = joinText + " " + text.ENDED; break;
          case "reject": action = joinText + " " + text.REJECTED; break;
        }

        // call duration in a call is ended for you
        if (eventType === "leave" || eventType === "cancel") {
          if (this.userJID && m.fromJid === this.userJID?.bare) {
            if (m.vncTalkConference.duration) {
              callDuration = `. ${text.CALL_ENDED} - ${this.showDuration(m.vncTalkConference.duration)}`;
            }
          }
        } else if (eventType === "no-answer") {
          if (this.userJID && m.vncTalkConference.from === this.userJID?.bare) {
            this.noAnswer = text.USER_HAS_NOT_ANSWERED;
            this.callEnded = text.CALL_ENDED;
          }
        }

        // final content
        content.push(`${name} ${action.trim()}`);
      });
      let res = content.join(", ");
      if (callDuration && isAtLeastOneJoinSignal) {
        res += callDuration;
      }

      this.callHistory = res;
      // this.logger.info("[MessageComponent][renderCallHistory]", callHistoryMessages, content, res);

      this.changeDetectorRef.markForCheck();
    });
  }

  onRightClick(event) {
    if (environment.isElectron && environment.enableOauthLogin) {
      event.preventDefault();
    }
  }

  processText(text) {
    if (!text) {
      return "";
    }
    // text = text.replace("<p>", "").replace("</p>", "")
    // if (text.length > 200) {
    //   text = this.smartSubstr(text, 200);
    // }
    const content = document.createElement("div");
    content.innerHTML = textile(text) || "";
    return content.innerHTML.replace(/<a\s+/g, "<a class=\"open-new-window\" target=\"_blank\" ");
  }

  processDescription(text: string) {
    if (!text) {
      return "";
    }
    if (text.length > 300) {
      text = CommonUtil.smartSubstr(text, 300);
    }
    return text.replace(/<a\s+/g, "<a class=\"open-new-window\" target=\"_blank\" ");
  }

  onMentionSelect(item: any) {
    if (item) {
      return `@${item.value} `;
    }
    return "";
  }

  likeItem(socialData: any) {
    if (socialData?.post && socialData.post.liked) {
      this.socialRepository.unlikePost(socialData?.post?.id);
    } else {
      this.socialRepository.likePost(socialData?.post?.id);
    }
  }

  replyItem(post: any): void {
    this.createSocialPost(post);
  }

  async createSocialPost(post?: any, isEdit?: boolean) {

  }

  shareItem(): void {
    this.socialRepository.underDevelopmemnt();
  }

  repostItem(post: any): void {
    this.socialRepository.rePost(post);
  }

  navigateToProfile(userJid) {
    this.router.navigateByUrl("/talk/social/" + userJid);
  }

  getTimeAgo(date:any) {
    if (typeof date === "string") {
      return formatDistanceToNow(Date.parse(date), {addSuffix: true, locale: this.locale});
    }
    return "";
  }

  isUpdated(createdOn:any, updatedOn:any){
    return isBefore(Date.parse(createdOn), Date.parse(updatedOn));
  }

  async deleteAndCopyMessage() {
    await this.copyMessage(true);
    if (this.convRepo.contentForClipBoard[this.message.id]) {
      this.conversationRepo.getSelectedConversation(this.isMiniChat).pipe(take(1)).subscribe(conv => {
    this.logger.info("[deleteAndCopyMessage]", this.message.id, this.convRepo.contentForClipBoard[this.message.id]);

        if (!!conv) {
          this.store.dispatch(new SetMessageToCopyAndDelete({
            conversationTarget: conv.Target,
            text: this.convRepo.contentForClipBoard[this.message.id],
            messageId: this.message.id
          }));
        }
      });
    }
  }

  async saveToOwncloud(file) {
  const { OwncloudUploadDialogComponent } = await import(
    "../dialogs/owncloud-upload/owncloud-upload.component");
    this.dialog.open(OwncloudUploadDialogComponent, {
      width: "480px",
      maxWidth: "95%",
      height: "610px",
      backdropClass: "common-dialog-backdrop",
      panelClass: "common-dialog-panel",
      disableClose: false,
      data: {
        file: file
      },
      autoFocus: true
    }).afterClosed().pipe(take(1)).subscribe((data) => {
      this.logger.info("[saveToOwncloud]", data);
      if (data && data.shared) {

      }
  });
}

  private getSocialTopicId(urlTokens, checkForEnd = false) {
    const indexOfTopicKey = urlTokens ? urlTokens.indexOf(ChannelEntityType.SOCIAL_TOPIC) : -1;
    const id = urlTokens?.[indexOfTopicKey + 1].split("?")?.[0];
    return checkForEnd ? id?.split("\"")?.[0] : id;
  }

  get totalSelectedMessages() {
    let selectedMessages = [];
    if (this.isSearchResult) {
      this.searchRepo.getSelectedMessages().pipe(take(1)).subscribe(messages => {
        selectedMessages = messages;
      });
    } else {
      this.conversationRepo.getSelectedMessages().pipe(take(1)).subscribe(messages => {
        selectedMessages = messages;
      });
    }
    return selectedMessages.length;
  }

  get cannotDecryptMessage() {
    return this.message?.body?.startsWith("Can't decrypt the message") && this.message?.$body?.en === "Encrypted message";
  }

  updateReaction(reactionCode) {
    this.logger.info("[MessageComponent] updateReaction ", this.message, reactionCode);
    const payload = {msgid: this.message.id, target: this.message.convTarget, reaction: reactionCode};
    this.emojiReactionService.addPendingReaction(payload);
  }

  removeReaction(){
    this.updateReaction("");
  }

  onReactionClick(data){
    if(data.action === "add"){
      this.updateReaction(data.emoji);
    } else if (data.action === "remove") {
      this.removeReaction();
    } else {
      /// TODO: add logger here
    }
    // console.log(data)
  }

  cancelUpload() {
    this.conversationRepo.cancelSendFileRequest(this.message.id);
    this.fileUploaded = true;
    this.changeDetectorRef.markForCheck();
    this.conversationRepo.getSelectedConversation(this.isMiniChat).pipe(take(1)).subscribe(conv => {
      this.store.dispatch(new ConversationUpdate({target: conv?.Target, changes: {
        content: ""
      }}));
    });

  }

}

enum ChannelEntityType {
  CHANNEL = "channels",
  TOPIC = "topics",
  COMMENT = "commentId",
  SOCIAL_TOPIC = "socialTopic",
  SOCIAL = "social"
}

enum ChatPreviewType {
  CHANNEL = "CHANNEL",
  TOPIC = "TOPIC",
  COMMENT = "COMMENT",
  SOCIAL = "SOCIAL"
}

enum CommentAction {
  LIKE = "LIKE",
  REPLY = "REPLY",
  VIEW_REPLIES = "VIEW_REPLIES"
}

enum FileActionType {
  COPY_ALL_FILES_TO_CLIPBOARD = "COPY_ALL_FILES_TO_CLIPBOARD",
  DOWNLOAD_ALL_FILES = "DOWNLOAD_ALL_FILES",
  DOWNLOAD_FILE = "DOWNLOAD_FILE",
  COPY_FILE_LINK = "COPY_FILE_LINK",
  COPY_FILE_TO_CLIPBOARD = "COPY_FILE_TO_CLIPBOARD",
  FILE_ITEM_CLICKED = "FILE_ITEM_CLICKED"
}

interface ChatPreviewData {
  previewType: ChatPreviewType;
  resultType: SmartLinkSearchResultType;
  linkText?: string;
  description?: string;
  searchQuery?: string;
  resultData: any;
}
