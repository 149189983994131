import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from "@angular/core";
import {BehaviorSubject, Subject} from "rxjs";
import {ConversationRepository} from "../../talk/repositories/conversation.repository";
import {map, take, takeUntil, debounceTime} from "rxjs/operators";
import {Conversation} from "../../talk/models/conversation.model";
import {Member} from "../models/member.model";
import {ContactRepository} from "../../talk/repositories/contact.repository";
import {UntypedFormControl} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ChannelRepository} from "../repository/channel.repository";
import {Topic} from "../models/topic.model";
import { CommonUtil } from "app/talk/utils/common.util";
import { Broadcaster } from "app/talk/shared/providers";
import { ChannelService } from "../channel.service";

@Component({
  selector: "vp-send-topic-link",
  templateUrl: "./send-topic-link.component.html",
  styleUrls: ["./send-topic-link.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendTopicLinkComponent implements OnInit, OnDestroy {

  $filteredRecipients = new BehaviorSubject<Member[]>([]);
  $selectedUsers = new BehaviorSubject<Member[]>([]);
  recipientSearchControl = new UntypedFormControl("");
  commentControl = new UntypedFormControl("");
  topic: Topic = null;
  channelMembers: Member[] = [];
  conversations: Conversation[] = [];
  tagsForMention: { id: string; value: string }[] = [];
  defaultTagsForMention: { id: string; value: string }[] = [];
  invitedUsers: Member[];


  editor: any;

  quillEditorModules: any = {
    toolbar: [],
    mention: {
      allowedChars: /^(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|[a-zA-Z0-9!@#\$%\^\&*)(+=._-]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])*$/,
      mentionDenotationChars: ["#"],
      defaultMenuOrientation: "top",
      source: async (searchTerm, renderList) => {
        const matchedOptions = await this.suggestMentionOptions(searchTerm);
        renderList(matchedOptions);
      },
      renderItem: (item: { id: string; value: string }) => {
        return `<span> #${item.value} </span>`;
      }
    },
  };

  private isAlive$ = new Subject<boolean>();

  constructor(private channelsRepo: ChannelRepository,
              private _contactRepository: ContactRepository,
              private changeDetectionRef: ChangeDetectorRef,
              private matDialog: MatDialog,
              private _conversationRepository: ConversationRepository,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private _dialogRef: MatDialogRef<SendTopicLinkComponent>,
    private broadcaster: Broadcaster,
    private channelService: ChannelService) {
    this.topic = this.data.topic;
  }

  ngOnInit() {
    this.fetchUsersOfSelectedChannel();
    this.fetchRecipients();
    this.broadcaster.on<any>("CLOSE_TOPIC_SEND_TO_DIALOG").pipe(takeUntil(this.isAlive$)).subscribe(() => {
      this.closePopup();
    });
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  fetchRecipients() {
    this.channelService.getUsersForInvitation().subscribe(async (data:any)=>{
      this.conversations = data;
      // const filtered_conversation = data.filter((conversation: Conversation) =>
      //   (conversation.type === "chat" || conversation.type === "groupchat") && !conversation.Target.includes("@external.") &&
      //   !conversation.Target.includes("broadcast-"));
      // this.conversations = [...filtered_conversation];

      const recipients = await this.getFilteredRecipients(data, this.recipientSearchControl.value);
      this.$filteredRecipients.next([...recipients]);
      this.recipientSearchControl.valueChanges.pipe(takeUntil(this.isAlive$), debounceTime(500)).subscribe(async () => {
        const recipients = await this.getFilteredRecipients(data, this.recipientSearchControl.value);
        this.$filteredRecipients.next([...recipients]);
      });
    });
    // this._conversationRepository.getConversations().pipe(take(1)).subscribe(async (conversations: Conversation[]) => {
    // });
    // this.recipientSearchControl.valueChanges.pipe(takeUntil(this.isAlive$), debounceTime(500)).subscribe(async () => {
    //   const recipients = await this.getFilteredRecipients(this.conversations, this.recipientSearchControl.value);
    //   this.$filteredRecipients.next([...recipients]);
    // });
  }

  async getFilteredRecipients(conversations: Conversation[], recipientSearchValue: string): Promise<Member[]> {
    const mappedMembers: Member[] = conversations.map((conversation:any) => ({
      jid: conversation.jid ,
      fullName: conversation.fullName
    }));

    let searchedUsers: Member[] = recipientSearchValue.length > 1 ?
      (await this._contactRepository.searchUsersOnServer(recipientSearchValue)
        .pipe(
          take(1),
          map(users => (users || []).map(user => ({ jid: user.email, fullName: user.name })))
        ).toPromise()) : [];

    let filtered_recipients: Member[] = mappedMembers;

    const trimmedRecipientSearchValue = recipientSearchValue.trim();

    if (trimmedRecipientSearchValue.length > 2) {
      let filteredGroupChatMembers: Member[] = mappedMembers.filter(member =>
        member.jid.toLowerCase().includes(trimmedRecipientSearchValue.toLowerCase()) ||
        member.fullName.toLowerCase().includes(trimmedRecipientSearchValue.toLowerCase())
      );
      filtered_recipients = filteredGroupChatMembers.concat(searchedUsers);
    }

    const target = this.$selectedUsers.getValue().map(user => user.jid);
    filtered_recipients = CommonUtil.uniqBy(
      filtered_recipients.filter(recipient => !recipient.jid.startsWith("broadcast-") && !target.includes(recipient.jid)),
      "jid"
    );

    return filtered_recipients;
  }

  getContainerStyles = () => ({
    width: CommonUtil.isMobileSize() ? "100%" : "480px",
    height: "572px"
  });

  async removeUser(user: Member) {
    const selectedUsers = this.$selectedUsers.getValue() || [];
    const filteredUsers = selectedUsers.filter(selectedUser => selectedUser.jid !== user.jid);
    this.$selectedUsers.next(filteredUsers);
    const recipients = await this.getFilteredRecipients(this.conversations, this.recipientSearchControl.value);
    this.$filteredRecipients.next([...recipients]);
    this.externalUsers = this.externalUsers.filter(e=> e?.jid !== user?.jid);
  }

  async selectUser(user: Member) {
    const selectedUsers = this.$selectedUsers.getValue() || [];
    const index = selectedUsers.findIndex(selectedUser => selectedUser.jid === user.jid);
    if (index !== -1) return;
    selectedUsers.push(user);
    const currentRecipients = this.$filteredRecipients.getValue();
    const updatedRecipients = currentRecipients.filter(r => r.jid !== user.jid);
    this.$filteredRecipients.next(updatedRecipients);
    const updatedUser = this.channelMembers.find((channelUser) => {
      return channelUser.jid === user.jid;
    });
    if (updatedUser) {
      return false;
    } else {
      this.externalUsers.push(user);
    }
    this.$selectedUsers.next(selectedUsers);
    const recipients = await this.getFilteredRecipients(this.conversations, this.recipientSearchControl.value);
    this.$filteredRecipients.next([...recipients]);
  }

  closePopup() {
    this._dialogRef.close();
  }

  onEditorCreated(editor) {
    this.editor = editor;
  }

  sendTopicLink() {
    const selectedUsers = this.$selectedUsers.getValue();
    const comment = !!this.editor?.root?.innerHTML?.replace(/<[^>]+>/g, "") ? this.editor?.root?.innerHTML : "";
    const selectedUserIds = selectedUsers.map(selectedUser => selectedUser.jid);
    this._dialogRef.close({ selectedUserIds, comment });
  }

  suggestMentionOptions = async (searchTerm) => {
    if (searchTerm.length === 0) {
      return [...this.defaultTagsForMention];
    } else {
      let params: any = [];
      params["name"] = "~" + searchTerm;
      const tags = await this._conversationRepository.getTags(params).toPromise();
      this.tagsForMention = tags.map(v => ({id: v.id, value: v.name, type: "tag"}));
      this.changeDetectionRef.markForCheck();
      return  [...this.tagsForMention];
    }
  };

  isExternalUser(user) {
    const updatedUser = this.channelMembers?.find((channelUser) => {
      return channelUser.jid === user.jid;
    });
    if (updatedUser) {
      return false;
    } else {
      return this.isInvitedUser(user);
    }
  }

  isInvitedUser(user) {
    let updatedInvited = this.invitedUsers?.find((channelUser) => {
      return channelUser.jid === user.jid;
    });
    if (updatedInvited) {
      return false;
    } else {
      return true;
    }
  }

  externalUsers: any[] = [];

  private fetchUsersOfSelectedChannel(force = true) {  // adding force = true as per https://vncproject.vnc.biz/issues/30003052-14252#note-7
    this.channelsRepo.getSelectedChannelId()
      .pipe(takeUntil(this.isAlive$)).subscribe((id) => {
        this.channelsRepo.getMembersOfChannel(id, force).subscribe((res: any) => {
          this.channelMembers = res;
          this.channelMembers = this.channelMembers.map((eachChannel: any) => {
            return { ...eachChannel, fullName: eachChannel.name };
          });
          // this.$filteredRecipients.next([... this.channelMembers]);
        });
      });
  }


  validateSendingLink() {
    if (this.externalUsers.length >= 1) {
       ; (async () => {
         let options: any = {
           width: "480px",
           height: "280px"
         };
         if (CommonUtil.isMobileSize()) {
           options = {
             maxWidth: "100vw",
             maxHeight: "100vh",
             minHeight: "300px"
           };
         }
         const { ConfirmationChannelComponent } = await import(
           "../confirmation-channel/confirmation-channel.component");
         this.matDialog.open(ConfirmationChannelComponent, Object.assign({
           backdropClass: "vnctalk-form-backdrop",
           panelClass: ["vnctalk-form-panel", "confirmation-dialog"],
           disableClose: true,
           data: {
             headerText: "ADD_EXTERNAL_USER",
             bodyText: "INVITE_TEXT",
             okLabel: "INVITE",
             cancelLabel: "CANCEL",
             externalUsers$: this.externalUsers
           },
           autoFocus: true
         }, options)).afterClosed().pipe(take(1)).subscribe((res: any) => {
           if (!!res && res.confirmation) {
             if (res.confirmation === "yes") {
               this.invitedUsers = res.invitedUsers;
               this.externalUsers = [];
               this.changeDetectionRef.markForCheck();
             }
           }
         });
       })();
     }
     else {
      this.sendTopicLink();
     }
   }

   isMobile() {
    return CommonUtil.isMobileSize();
  }

}
