import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {HeaderMode, SmartLinkHeaderTabType} from "vnc-library";
import {BehaviorSubject} from "rxjs";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {SmartLinkPopupComponentService} from "./smart-link-popup.component.service";
import {noop, Subject} from "rxjs";
import {debounceTime, map, skip, take, takeUntil} from "rxjs/operators";
import {SmartLinkPopupFiltersService} from "./smart-link-popup-filters.service";
import {TranslateService} from "@ngx-translate/core";
import {ChannelSnackbarService} from "../channel-snackbar.service";
import {DEFAULT_CHANNEL_THUMBNAIL_URL, FilterType, SmartLinkService} from "../smart-link.service";
import {ChannelRepository} from "../repository/channel.repository";
import {SnackbarType} from "../models/snackbar.model";
import {CommonUtil} from "../../talk/utils/common.util";
import {CategoryFilters, SmartLinkFilterComponent} from "./smart-link-filter/smart-link-filter.component";
import {MobileSmartLinkFilterService} from "./smart-link-filter/mobile-smart-link-filter.service";
import {Router} from "@angular/router";
import {CloseSmartObjectDialog} from "../../root.component";
import {Broadcaster} from "../../talk/shared/providers";
import { environment } from "app/environments/environment";

@Component({
  selector: "vp-smart-link-popup",
  templateUrl: "./smart-link-popup.component.html",
  styleUrls: ["./smart-link-popup.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class SmartLinkPopupComponent implements OnInit, OnDestroy {
  @HostListener("window:resize") onResize() {
    this.decideShowExpand();
  }
  currentURL: string = "";
  channelsRoute: boolean = false;
  rangesLabel: any;
  defaultLang: any;

  constructor(public smartLinkPopupComponentService: SmartLinkPopupComponentService,
    public smartLinkFiltersService: SmartLinkPopupFiltersService,
    @Inject(MAT_DIALOG_DATA) public data: { selectedText: string, getOnlyData: boolean, skipFirstStep: boolean, selectedSearchResult: any, selectedSmartLinkData: { title: string, description: string }, alreadyLinkedObjects: { object_type: string, id: any }, isMobileView: boolean },
    private _translate: TranslateService,
    private _smartLinkService: SmartLinkService,
    private _channelSnackBarService: ChannelSnackbarService,
    private _channelRepository: ChannelRepository,
    private _dialogRef: MatDialogRef<SmartLinkPopupComponent>,
    private smartLinkFilterDialogRef: MatDialog,
    private _router: Router,
    private _broadcaster: Broadcaster,
    private _changeDetectionRef: ChangeDetectorRef,
    public mobileFilterService: MobileSmartLinkFilterService) {

    this.currentURL = this._router.url;
    if (this.currentURL.includes("/channels")) {
      this.channelsRoute = true;
      this._changeDetectionRef.markForCheck();
    }
    this.smartlinkForm.patchValue({ title: this.data.selectedText });
    this.smartLinkPopupComponentService.setInitialFilters();
    this.smartLinkFiltersService.getAllTopics();
    this.smartSearchLinkName = this.data.selectedText;
    data?.skipFirstStep ? this.$currentStage.next(2) : noop();
    !!data?.selectedSearchResult ? this.selectedSearchResult = data?.selectedSearchResult : noop();
    !!data?.selectedSmartLinkData ? this.smartlinkForm.patchValue(data?.selectedSmartLinkData) : noop();
    this._broadcaster.on<any>(CloseSmartObjectDialog.CLOSE_SMART_LINK_POPUP_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(() => {
      this._dialogRef.close();
    });
    (async () => {
      this.rangesLabel = {
        today: await this._translate.get("TODAY").pipe(take(1)).toPromise(),
        yesterday: await this._translate.get("YESTERDAY").pipe(take(1)).toPromise(),
        thisWeek: await this._translate.get("THIS_WEEK").pipe(take(1)).toPromise(),
        thisMonth: await this._translate.get("THIS_MONTH").pipe(take(1)).toPromise(),
        lastMonth: await this._translate.get("LAST_MONTH").pipe(take(1)).toPromise(),
        last3Month: await this._translate.get("LAST_THREE_MONTH").pipe(take(1)).toPromise()
      };
    })();
  }

  headerMode = HeaderMode;
  smartLinkHeaderTabType = SmartLinkHeaderTabType;
  resultType = SmartLinkSearchResultType;
  filterType = FilterType;
  defaultChannelAvatar = DEFAULT_CHANNEL_THUMBNAIL_URL;
  categoryFilters = CategoryFilters;
   options = [
    {value: "everywhere", label: this.translateText("EVERYWHERE"), showIcon: false, showCheckmark: false },
    {value: "channels", label: this.translateText("CHANNELS"), showIcon: true, showCheckmark: true},
    {value: "topics", label: this.translateText("TOPICS"), showIcon: true, showCheckmark: true},
    {value: "comments", label: this.translateText("COMMENTS"), showIcon: true, showCheckmark: true},
    {value: "chats", label: this.translateText("CHATS"), showIcon: true, showCheckmark: true},
    {value: "files", label: this.translateText("FILES"), showIcon: true, showCheckmark: true},
    {value: "broadcast", label: this.translateText("BROADCAST"), showIcon: true, showCheckmark: true},
  ];
  filterCategories = {
    CHATS: false,
    CHANNELS: false,
    BROADCASTS: false,
    TOPIC: false,
    FILES: false,
    COMMENTS: false
  };
  filterCategorySelected = false;

  $activeHeaderTab = new BehaviorSubject("");
  $headerTitle = new BehaviorSubject(HeaderTabTitle.SMARTLINK);
  $showAllFilters = new BehaviorSubject<boolean>(false);
  $showCollapseToggle = new BehaviorSubject<boolean>(true);
  $currentStage = new BehaviorSubject<number>(1);
  $fullScreen = new BehaviorSubject<boolean>(false);
  $visibleCategories = new BehaviorSubject<string[]>(["everywhere"]);
  $searchResultHeight = new BehaviorSubject<string>("calc(100% - 110px)");
  selectedCat: string = "";
  $searchResults = new BehaviorSubject<any[]>([]);

  @ViewChild("smartLinkFilters") smartLinkFilters: ElementRef<HTMLDivElement>;

  globalFilters = { [FilterType.GLOBAL_UNREAD]: false, [FilterType.GLOBAL_STARRED]: false,  [FilterType.GLOBAL_ARCHIVED]: false };
  smartSearchLinkName = "";

  smartlinkControl = {
    search: new UntypedFormControl(""),
    title: new UntypedFormControl(""),
    description: new UntypedFormControl(""),
  };
  smartlinkForm = new UntypedFormGroup(this.smartlinkControl);

  selectedSearchResult: any;
  selectedSmartlinks = [];

  private isAlive$ = new Subject<boolean>();

  selectedSmartObjects = {
    CHANNEL: [],
    TOPIC: [],
    COMMENT: []
  };

  ngOnInit(): void {
    this.defaultLang = CommonUtil.getDefaultLang();
    this.handleSearchResultHeight();
    this.$visibleCategories.pipe(skip(1), takeUntil(this.isAlive$), debounceTime(500))
      .subscribe(value => this.smartLinkPopupComponentService.onCategoryChange(value));
    this.smartLinkPopupComponentService.$searchResults
      .pipe(
        takeUntil(this.isAlive$),
        map((results: any) => {
          return this.data?.alreadyLinkedObjects !== null ?
            results?.filter(result => !Object.keys(this.data?.alreadyLinkedObjects)?.includes(result?.id) && this.data?.alreadyLinkedObjects?.[result?.id] !== result?.object_type) :
            results;
        })
      )
      .subscribe(value => {
        this.$searchResults.next(this._smartLinkService.getMappedSmartLinkSearchResults(value));
      });
    this.smartlinkControl.search.valueChanges
      .pipe(takeUntil(this.isAlive$), debounceTime(100))
      .subscribe(value => this.smartLinkPopupComponentService.onSmartLinkSearchQueryChange(value));
    this.smartLinkFiltersService.fetchAllFilters();
    this.listenToMobileFilterChanges();
    if(environment.isCordova){
      StatusBar.backgroundColorByHexString("#000000");
      StatusBar.styleBlackTranslucent();
    }
  }

  private listenToMobileFilterChanges() {
    this.mobileFilterService.selectedFilters.pipe(takeUntil(this.isAlive$)).subscribe(data => {
      if (!!data) {
        const filters = Object.entries(data);
        filters.forEach(([key, value], index) => {
          if (value !== "" || value.length !== 0) {
            this.smartLinkPopupComponentService.onFilterChange(value, key, index + 1 === filters.length);
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
    this.smartLinkPopupComponentService.isAlive$.next(false);
    this.smartLinkPopupComponentService.isAlive$.complete();
    this.smartLinkFiltersService.isAlive$.next(false);
    this.smartLinkFiltersService.isAlive$.complete();
    if (environment.isCordova) {
      StatusBar.backgroundColorByHexString("#317bbc");
    }
  }

  changeFilterCategory(currentCategory: string) {
    Object.keys(this.filterCategories).forEach((key) => {
      this.filterCategories[key] = false;
    });
    this.filterCategories[currentCategory] = true;
    this.filterCategorySelected = true;
    this.selectedCat = currentCategory.toLocaleLowerCase();
  }

  resetCategorySelection() {
    this.filterCategorySelected = false;
    Object.keys(this.filterCategories).forEach((key) => {
      this.filterCategories[key] = false;
    });
  }

  toggleHeaderTab(event = "") {
    if (this.$headerTitle.getValue() === HeaderTabTitle.BACK) {
      this.$currentStage.next(1);
      this.$headerTitle.next(HeaderTabTitle.SMARTLINK);
      this.$activeHeaderTab.next("");
      return;
    }
    if (event === SmartLinkHeaderTabType.SETTINGS) {
      this.underDevelopment();
      return;
      this.$headerTitle.next(HeaderTabTitle.SETTINGS);
    }
    if (event === SmartLinkHeaderTabType.SHORTCUTS) {
      this.underDevelopment();
      return;
      this.$headerTitle.next(HeaderTabTitle.HELP);
    }
    if (event === SmartLinkHeaderTabType.CLOSE) {
      this._dialogRef.close();
    }
    if (event === "") {
      this.$headerTitle.next(HeaderTabTitle.SMARTLINK);
    }
    this.$activeHeaderTab.next(event);
  }

  underDevelopment() {
    this._translate.get("UNDER_DEVELOPMENT").pipe(take(1)).subscribe(text => {
      this._channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE);
    });
  }

  selectSearchResult(searchResult) {
    this.selectedSearchResult = searchResult;
    this.$currentStage.next(2);
    this.$headerTitle.next(HeaderTabTitle.BACK);
    this.$activeHeaderTab.next("BACK");
  }

  updateGlobalFilters(filterName: FilterType.GLOBAL_UNREAD | FilterType.GLOBAL_STARRED | FilterType.GLOBAL_ARCHIVED) {
    this.globalFilters[filterName] = !this.globalFilters[filterName];
    this.smartLinkPopupComponentService.onFilterChange(this.globalFilters[filterName], filterName);
  }

  toggleShowAllFilters() {
    const previousValue = this.$showAllFilters.getValue();
    this.$showAllFilters.next(!previousValue);
  }

  onToggleFullScreen() {
    const dialogContainer = document.querySelector(".smartlink-popup-container");
    const isCurrentlyFullScreen = this.$fullScreen.getValue();
    isCurrentlyFullScreen ? dialogContainer.classList.remove(FULL_SCREEN_DIALOG) : dialogContainer.classList.add(FULL_SCREEN_DIALOG);
    this.$fullScreen.next(!isCurrentlyFullScreen);
    this.decideShowExpand();
  }

  decideShowExpand() {
    const collapsedWrapperWidth = document.querySelector(".smartlink-chips-collapsed")?.clientWidth;
    const expandedWrapperWidth = document.querySelector(".smartlink-chips-expanded")?.clientWidth;
    if (collapsedWrapperWidth > 1742 || expandedWrapperWidth > 1742) {
      this.$showCollapseToggle.next(false);
      return;
    }
    this.$showCollapseToggle.next(true);
  }

  handleSearchResultHeight() {
    this.$showAllFilters.pipe(takeUntil(this.isAlive$), debounceTime(1000)).subscribe(() => {
      const filtersHeight = this.smartLinkFilters?.nativeElement?.offsetHeight || 0;
      // 74 consists of { smartlinkInputHeight -> 36 + Spacing -> 38 ( 12 + 16 + 10 ) }
      this.$searchResultHeight.next(`calc(100% - ${filtersHeight + 74}px)`);
    });
  }

  translateText(textString: string) {
    let transFormedText  = textString;
    this._translate.get(textString).pipe(take(1)).subscribe( t => transFormedText = t);
    return transFormedText;
  }

  onCategoryChange(event: any) {
    setTimeout(() => {
      if (!event.length) return;
      this.$visibleCategories.next(event);
      const collapsedWrapperWidth = document.querySelector(".smartlink-chips-collapsed")?.clientWidth;
      const expandedWrapperWidth = document.querySelector(".smartlink-chips-expanded")?.clientWidth;
      if (event?.length <= 2 && (collapsedWrapperWidth < 739 || expandedWrapperWidth < 739)) {
        this.smartLinkPopupComponentService.showExpandCollapsedButton = false;
      } else {
        this.smartLinkPopupComponentService.showExpandCollapsedButton = true;
      }
    }, 500);
  }

  getMobileSmartLinkFilterRef(appliedFilters: any): MatDialogRef<SmartLinkFilterComponent> {
    return this.smartLinkFilterDialogRef.open(SmartLinkFilterComponent, {
      width: "100%",
      height: "100%",
      backdropClass: "transparent-backdrop",
      panelClass: "smartlink-popup-container-mobile",
      data: appliedFilters,
      position: {
        left: "0px",
        top: "0px"
      }
    });
  }

  openSmartLinkFilter(appliedFilters: any = null) {
    this.getMobileSmartLinkFilterRef(appliedFilters);
  }


  addSelectedSmartLinks() {
    this._dialogRef.close({data: this._smartLinkService.constructMultiSmartLinkData(this.selectedSmartlinks, this.smartSearchLinkName, this.data.getOnlyData)});
  }

  pushSmartLink(data: any) {
    if (data?.isChecked) {
      this.selectedSmartlinks.push(data?.event);
      this.selectedSmartObjects?.[data.event?.resultType].push(data.event?.id);
    } else {
      this.selectedSmartlinks = this.selectedSmartlinks.filter(searchResult => searchResult.id !== data?.event.id);
      this.selectedSmartObjects[data.event.resultType] = this.selectedSmartObjects?.[data.event?.resultType]?.filter(id => id !== data.event?.id);
    }
  }

  addSmartLink(form: UntypedFormGroup, smartLinkName: string, selectedResult, getOnlyData = false, skipFirstStep = false) {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }
    this._dialogRef.close({ data: skipFirstStep ? form.value : this._smartLinkService.constructSmartLinkData(form, smartLinkName, selectedResult, getOnlyData) });
  }

}

export enum SmartLinkSearchResultType {
  BROADCAST = "BROADCAST",
  CHAT = "CHAT",
  GROUP_CHAT = "GROUP_CHAT",
  CHANNEL = "CHANNEL",
  TOPIC = "TOPIC",
  FILE = "FILE",
  COMMENT = "COMMENT",
}

enum HeaderTabTitle {
  SMARTLINK = "Smartlink",
  SETTINGS = "Settings",
  HELP = "Help",
  BACK = "Back"
}

export const FULL_SCREEN_DIALOG = "full-screen-dialog";
