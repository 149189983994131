
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { Observable, Subject, BehaviorSubject, take } from "rxjs";
import { CommonUtil } from "app/talk/utils/common.util";
import { Broadcaster } from "../shared/providers";
import { LoggerService } from "app/shared/services/logger.service";

@Injectable()
export class AudioOutputService {

  constructor(private broadcaster: Broadcaster, private logger: LoggerService) {
    // AudioToggle.switchMicrophone = (micDeviceId: string) => {
    //   broadcaster.broadcast("switchMicrophone", micDeviceId);
    // };

    this.broadcaster.on<boolean>("onSpeakerEnabled").subscribe(isEnabled => {
      if (isEnabled) {
        this._setAudioMode(AudioToggle.SPEAKER);
      } else {
        this.isBluetoothHeadsetOrHeadphoneConnected().subscribe(available => {
          if (available) {
            this._setAudioMode(AudioToggle.BLUETOOTH);
          } else {
            this._setAudioMode(AudioToggle.EARPIECE);
          }
        });
      }
    });
  }

  private _audioMode$ = new BehaviorSubject<string>("earpiece");

  // SPEAKER
  useSpeakerAudioOutput() {
    this.logger.info("[AudioOutputService][useSpeakerAudioOutput]");

    if (CommonUtil.isOnIOS()) {
      this._changeAudioOutputiOS(AudioToggle.SPEAKER);
    } else if (CommonUtil.isOnAndroid()) {
      this._changeAudioOutputAndroid(AudioToggle.SPEAKER);
    }

    this.broadcaster.broadcast("notifySpeakerEnabled", true);
  }

  // EARPIECE
  useDefaultAudioOutput() {
    this.logger.info("[AudioOutputService][useDefaultAudioOutput]");

    if (CommonUtil.isOnIOS()) {
      this._changeAudioOutputiOS(AudioToggle.EARPIECE);
    } else if (CommonUtil.isOnAndroid()) {
      this._changeAudioOutputAndroid(AudioToggle.EARPIECE);
    }
  }

  // BLUETOOTH
  useBluetoothAudioOutput() {
    this.logger.info("[AudioOutputService][useBluetoothAudioOutput]");

    if (CommonUtil.isOnIOS()) {
      this._changeAudioOutputiOS(AudioToggle.BLUETOOTH);
    } else if (CommonUtil.isOnAndroid()) {
      this._changeAudioOutputAndroid(AudioToggle.BLUETOOTH);
    }
  }

  correctAudioOutput() {
    if (!CommonUtil.isOnNativeMobileDevice()) {
      return;
    }
    this.logger.info("[AudioOutputService][correctAudioOutput]", this._audioMode$.value);

    // set output
    if (this._audioMode$.value === AudioToggle.SPEAKER) {
      this.useSpeakerAudioOutput();
    } else if (this._audioMode$.value === AudioToggle.BLUETOOTH) {
      this.useBluetoothAudioOutput();
    } else {
      this.useDefaultAudioOutput();
    }
    if (CommonUtil.isOnAndroid()) {
      AudioToggle.setAudioMode(this._audioMode$.value);
    }
  }

  setInitialAudioOutput(mode = "speaker") {
    if (!CommonUtil.isOnNativeMobileDevice()) {
      return;
    }

    this._setAudioMode(mode);

    this.logger.info("[AudioOutputService][setInitialAudioOutput]", mode);

    this.correctAudioOutput();
  }

  private _changeAudioOutputAndroid(mode) {
    this.logger.info("[AudioOutputService][_changeAudioOutputAndroid]", mode);

    AudioToggle.setAudioMode(mode);
    this._setAudioMode(mode);
  }

  private _changeAudioOutputiOS(mode = "speaker") {
    const output = mode === "speaker" ? "speaker" : "earpiece";

    this.logger.info("[AudioOutputService][changeAudioOutputiOS]", mode, output);

    const audioRouteValue = mode === "speaker" ? "speaker" : "default";
    cordova.plugins.audioroute.overrideOutput(audioRouteValue, success => {
      this._setAudioMode(mode);
      this.logger.info("[AudioOutputService][changeAudioOutputiOS] success", success);
    }, error => {
      this.logger.error("[AudioOutputService][changeAudioOutputiOS] error", error);
    });
  }

  getAudioMode(): Observable<string> {
    return this._audioMode$;
  }

  private _setAudioMode(mode: string) {
    this.logger.info("[AudioOutputService][_setAudioMode]", mode);
    this._audioMode$.next(mode);
  }

  //

  isWiredHeadsetOrHeadphoneConnected(){
    this.logger.info("[AudioOutputService][isWiredHeadsetOrHeadphoneConnected]");
    if (CommonUtil.isOnIOS()) {
      return this.isWiredHeadsetOrHeadphoneConnectediOS();
    } else if (CommonUtil.isOnAndroid()) {
      return this.isWiredHeadsetOrHeadphoneConnectedAndroid();
    }
  }

  enableDefaultOutput(): void {
    this.logger.info("[AudioOutputService][enableDefaultOutput]");
    this.isBluetoothHeadsetOrHeadphoneConnected().subscribe(available => {
      if (available) {
        this.useBluetoothAudioOutput();
      } else {
        this.useDefaultAudioOutput();
      }
    });

    this.broadcaster.broadcast("notifySpeakerEnabled", false);
  }

  enableDefaultSpeakerOutput(): void {
    this.logger.info("[AudioOutputService][enableDefaultSpeakerOutput]");
    this.isBluetoothHeadsetOrHeadphoneConnected().subscribe(available => {
      if (available) {
        this.useBluetoothAudioOutput();
      } else {
        this.useSpeakerAudioOutput();
      }
    });
  }

  public isBluetoothHeadsetOrHeadphoneConnected(){
    this.logger.info("[AudioOutputService][isBluetoothHeadsetOrHeadphoneConnected]");
    if (CommonUtil.isOnIOS()) {
      return this.isBluetoothHeadsetOrHeadphoneConnectediOS();
    } else if (CommonUtil.isOnAndroid()) {
      return this.isBluetoothHeadsetOrHeadphoneConnectedAndroid();
    }
  }

  //

  private isWiredHeadsetOrHeadphoneConnectedAndroid(){
    // https://developer.android.com/reference/android/media/AudioDeviceInfo
    // 4, 3, 22 -> WIRED
    const validDeviceTypes = [4, 3, 22];
    return this.isHeadsetOrHeadphoneConnectedAndroid(validDeviceTypes);
  }

  private isBluetoothHeadsetOrHeadphoneConnectedAndroid(){
    // https://developer.android.com/reference/android/media/AudioDeviceInfo
    // 7, 8 -> BLUETOOTH
    const validDeviceTypes = [7, 8];
    return this.isHeadsetOrHeadphoneConnectedAndroid(validDeviceTypes);
  }

  private isHeadsetOrHeadphoneConnectedAndroid(validDeviceTypes){
    const response = new Subject<boolean>();

    this.logger.info("[AudioOutputService][isHeadsetOrHeadphoneConnectedAndroid] validDeviceTypes", validDeviceTypes);

    AudioToggle.getOutputDevices(res => {
      this.logger.info("[AudioOutputService][isHeadsetOrHeadphoneConnectedAndroid] devices", res);
      for (let i = 0; i < res.devices.length; ++i) {
        const type = res.devices[i].type;
        this.logger.info("[AudioOutputService][isHeadsetOrHeadphoneConnectedAndroid] type", type, validDeviceTypes.includes(type));
        if (validDeviceTypes.includes(type)) {
          response.next(true);
          return;
        }
      }

      response.next(false);
    });

    return response.asObservable().pipe(take(1));
  }

  //

  private isWiredHeadsetOrHeadphoneConnectediOS(){
    this.logger.info("[AudioOutputService][isWiredHeadsetOrHeadphoneConnectediOS]");
    // https://github.com/saghul/cordova-plugin-audioroute#currentoutputssuccesscallback-errorcallback
    const validDeviceTypes = ["headphones"];
    return this.isHeadsetOrHeadphoneConnectediOS(validDeviceTypes);
  }

  private isBluetoothHeadsetOrHeadphoneConnectediOS(){
    this.logger.info("[AudioOutputService][isBluetoothHeadsetOrHeadphoneConnectediOS]");
    // https://github.com/saghul/cordova-plugin-audioroute#currentoutputssuccesscallback-errorcallback
    const validDeviceTypes = ["bluetooth-a2dp", "bluetooth-le", "bluetooth-hfp", "airplay"];
    // "bluetooth-a2dp" - Output on a Bluetooth A2DP device (Advanced Audio Distribution Profile)
    // "bluetooth-le" - Output on a Bluetooth Low Energy device
    // "bluetooth-hfp" - Hands-Free Profile (HFP)
    // "airplay" - Output on a remote Air Play device
    return this.isHeadsetOrHeadphoneConnectediOS(validDeviceTypes);
  }

  private isHeadsetOrHeadphoneConnectediOS(validDeviceTypes){
    this.logger.info("[AudioOutputService][isHeadsetOrHeadphoneConnectediOS]", validDeviceTypes);
    const response = new Subject<boolean>();

    cordova.plugins.audioroute.currentOutputs(outputs => {
      this.logger.info("[AudioOutputService][isHeadsetOrHeadphoneConnectediOS] success", outputs);
      const found = outputs.some(r => validDeviceTypes.includes(r));
      this.logger.info("[AudioOutputService][isHeadsetOrHeadphoneConnectediOS] found", found);
      response.next(found);
    }, error => {
      this.logger.error("[AudioOutputService][isHeadsetOrHeadphoneConnectediOS] error", error);
    });

    return response.asObservable().pipe(take(1));
  }
}
