/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { SetActiveTab } from "app/actions/app";
import { SnackbarType } from "app/channels/models/snackbar.model";
import { getUserConfig, getUserJID } from "app/reducers";
import { UserConfig } from "app/shared/models/user-config.model";
import { TalkRootState } from "app/talk/reducers";
import { Broadcaster } from "app/talk/shared/providers";
import { CommonUtil } from "app/talk/utils/common.util";
import { Observable, Subject, combineLatest} from "rxjs";
import {filter, take, tap, map, switchMap} from "rxjs/operators";
import { SocialService } from "../services/social.service";
import {
  AllPostsInfoUpdate,
  BookmarkedPostsInfoUpdate,
  MediaPostInfoUpdate,
  PostAdd,
  PostBulkAdd,
  PostBulkDelete,
  PostBulkUpdate,
  PostDelete, PostRemoveAllScrollToItem, PostRemoveScrollToItem,
  PostReplysAdd,
  PostReplysLoadSuccess,
  PostReplysRemove, PostRepostRequest, PostRepostSuccess,
  PostScrollToItemSuccess,
  PostUserJidUpdate, SetPostTimer,
  SetSelectedPostId
} from "../actions/post";
import {
  getAllPosts,
  getAllPostsInfo,
  getBookmarkedPostsInfo,
  getFollowers, getFollowersByIds,
  getFollowings,
  getMediaPostsInfo,
  getPostById,
  getPostByIds,
  getPostReplys, getPostReposting, getPostTimer,
  getPostUserJid,
  getProfileAllPosts,
  getProfileById,
  getProfileFilteredAllPosts,
  getProfileFilteredMediaPosts,
  getProfileFilteredReplyPosts,
  getProfileLikedPosts,
  getProfileMediaPosts,
  getProfileReplyPosts, getRepostsByParentId, getScrollToItem,
  getSelectedProfileId,
  PostRootState
} from "../reducers";
import {
  ProfileFollowersAddOne,
  ProfileFollowersLoadRequest,
  ProfileFollowersLoadSuccess, ProfileFollowersRemoveOne, ProfileFollowingAddOne, ProfileFollowingLoadRequest,
  ProfileFollowingLoadSuccess, ProfileFollowingRemoveOne,
  FilteredPostsLoadRequest,
  FilteredPostsLoadSuccess,
  ProfileLoadRequest,
  ProfileLoadSuccess,
  ProfilePostAdd,
  ProfilePostRemove,
  ProfilePostsLoadRequest,
  ProfilePostsLoadSuccess,
  ProfileUpdate,
  SetSelectedProfileId,
} from "../actions/profile";
import {SocialProfile, USER_POST_TYPE} from "../../shared/models/social-profile.model";
import { MatDialog } from "@angular/material/dialog";
import { Location } from "@angular/common";
import {Attachment} from "../../channels/models/attachment.model";
import {BehaviorSubject} from "rxjs";
import {DomSanitizer} from "@angular/platform-browser";
import {FollowersLoadSuccess} from "../actions/follow";
import { Post } from "../models/post.model";
import { SocialSnackbarService } from "../services/social-snackbar.service";
import { Follow } from "../models/follow.model";
import { LoggerService } from "app/shared/services/logger.service";

@Injectable()
export class SocialRepository {

  userConfig: UserConfig;
  userJID: any;
  LIMIT: number = 25;

  constructor(
    private translate: TranslateService,
    private socialSnackbarService: SocialSnackbarService,
    private store: Store<TalkRootState | PostRootState>,
    private socialService: SocialService,
    private broadcaster: Broadcaster,
    private matDialog: MatDialog,
    private locationService: Location,
    private logger: LoggerService,
    private _sanitizer: DomSanitizer,
  ) {
    this.store.select(getUserConfig).pipe(filter(res => !!res)).subscribe(userConfig => {
      this.userConfig = userConfig;
    });

    this.store.select(getUserJID).pipe(filter(res => !!res)).subscribe(jid => {
      this.userJID = jid;
    });
  }

  underDevelopmemnt(): void {
    this.translate.get("UNDER_DEVELOPMENT").pipe(take(1)).subscribe(text => {
      this.socialSnackbarService.openSnackBar(text, SnackbarType.CLOSE);
    });
  }

  setActiveTabLive(): void {
    const tab = "social";
    this.store.dispatch(new SetActiveTab(tab));
  }

  setActiveTabChat() {
    const tab = "chat";
    this.store.dispatch(new SetActiveTab(tab));
  }

  getAllPosts(): Observable<Post[]> {
    return this.store.select(getAllPosts);
  }

  getBookmarkedPosts() {
    return this.store.select(getBookmarkedPostsInfo);
  }

  addPostToStore(post: Post): void {
    this.store.dispatch(new PostAdd(post));
    if (!!post) {
      const userJID = post.author.jid;
      const postId = post.id;
      if(!!post.attachments?.length) {
        this.addUserPostIdInStore(userJID, postId, USER_POST_TYPE.MEDIA);
      }
      if (!!post.parent_post) {
        this.addUserPostIdInStore(userJID, postId, USER_POST_TYPE.REPLIES);
      }
      this.addUserPostIdInStore(userJID, postId, USER_POST_TYPE.ALL);
    }
  }

  addPostsToStore(posts: Post[]): void {
    this.store.dispatch(new PostBulkAdd(posts));
  }

  loadBookmarkedPosts(offset?: number, limit?: number): void {
    const data = this.buildDataObjectForPosts(0, 0, [], true, false);
    this.store.dispatch(new BookmarkedPostsInfoUpdate(data));
    this.socialService.getPosts(offset, limit, "bookmarked=1&posttype=post|repost|reply").pipe(take(1)).subscribe(res => {
      const posts: Post[] = res.posts;
      const data = {
        totalCount: res.total_count,
        offset: res.posts.length,
        ids: this.extractIdsFromPosts(posts),
        isLoading: false,
        isLoaded: true
      };
      this.addPostsToStore(posts);
      this.store.dispatch(new BookmarkedPostsInfoUpdate(data));
    });
  }

  loadMoreBookmarkedPosts(offset: number, limit?: number) {
    let totalCount = 0;
    let previousIds = [];
    let previousOffset = 0;
    this.getBookmarkedPosts().pipe(take(1), filter(v => !!v))
      .subscribe(info => {
        totalCount = info.totalCount;
        previousIds = info.ids;
        previousOffset = info.offset;
      });

    if (totalCount > offset) {
      const data = this.buildDataObjectForPosts(totalCount, previousOffset, previousIds, true, false);
      this.store.dispatch(new BookmarkedPostsInfoUpdate(data));
      this.socialService.getPosts(offset, limit, "bookmarked=1&posttype=post|repost|reply").pipe(take(1)).subscribe(res => {
        const posts: Post[] = res.posts;
        const data = {
          totalCount: res.total_count,
          offset: offset + res.posts.length,
          ids: [...previousIds, ...this.extractIdsFromPosts(posts)],
          isLoading: false,
          isLoaded: true
        };
        this.addPostsToStore(posts);
        this.store.dispatch(new BookmarkedPostsInfoUpdate(data));
      });
    }
  }

  selectBookmarkedPosts() {
    let bookmarkedPosts = [];
    const $bookmarkedPostsInfo = this.store.select(getBookmarkedPostsInfo);
    const $allPosts = this.store.select(getAllPosts);
    return combineLatest([$bookmarkedPostsInfo, $allPosts])
      .pipe(map((value: [{totalCount: number, offset: number, ids: number[], isLoading: boolean, isLoaded: boolean}, Post[]]) => {
        const ids = value?.[0]?.ids.map(id => id.toString());
        const allAvailablePosts = value?.[1];
        bookmarkedPosts = allAvailablePosts.filter(v => ids.includes(v.id.toString()));
        return bookmarkedPosts;
      }));
  }

  getPosts(offset?: number, limit?: number, query?: string, isMedia?: boolean): void {
    this.store.dispatch(new SetPostTimer(new Date()));
    if (isMedia) {
      const data = this.buildDataObject(0, 0, [], true, false);
      this.store.dispatch(new MediaPostInfoUpdate(data));
    } else {
      const data = this.buildDataObject(0, 0, [], true, false);
      this.store.dispatch(new AllPostsInfoUpdate(data));
    }

    if (!!query) {
      query = query + `&skip_reposts=1`;
    } else {
      query = `skip_reposts=1`;
    }

    this.socialService.getPosts(offset, limit, query).pipe(take(1)).subscribe(res => {
      if (!!res && res.posts) {
        const posts: Post[] = res.posts;
        if (isMedia) {
          const data = {
            totalCount: res.total_count,
            offset: res.posts.length,
            ids: this.extractIdsFromPosts(posts),
            isLoading: false,
            isLoaded: true
          };
          this.store.dispatch(new MediaPostInfoUpdate(data));
        } else {
          const data = {
            totalCount: res.total_count,
            offset: res.posts.length,
            ids: this.extractIdsFromPosts(posts),
            isLoading: false,
            isLoaded: true
          };
          this.store.dispatch(new AllPostsInfoUpdate(data));
        }
        this.addPostsToStore(posts);
      }
    }, error => {
      this.socialSnackbarService.openSnackBar(error);
    });

    /* Get Unpublish lilst */
    const unpublishQuery = "unpublished=1";
    this.socialService.getPosts(null, null, unpublishQuery).pipe(take(1)).subscribe(res => {
      if (!!res && res.posts) {
        const posts: Post[] = res.posts;
        this.addPostsToStore(posts);
      }
    });
  }

  isFileSizeExceedLimit(file) {
    if (this.userConfig && this.userConfig.attachment_max_size) {
      let size = CommonUtil.bytesToKiloBytes(file?.size);
      if ( size > this.userConfig.attachment_max_size ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  showFileExceedNotification(file) {
    if (this.userConfig && this.userConfig.attachment_max_size) {
      const limitSize = CommonUtil.bytesToSize(this.userConfig.attachment_max_size * 1024);
      this.translate.get("FILE_SIZE_EXCEED", { filesize: limitSize, filename: file?.name}).pipe(take(1)).subscribe(text => {
        this.socialSnackbarService.openSnackBar(text, SnackbarType.CLOSE);
      });
    }
  }

  createPost(body: any): void {
    this.socialService.createPost(body).pipe(take(1)).subscribe((res: any) => {
      if (!!res && res.post) {
        const post: Post = res.post;
        this.addPostToStore(post);
        this.updatePostsInfo(1);
      }
    }, error => {
      this.socialSnackbarService.openSnackBar(error);
    });
  }

  removePostIntoStore(postId):void {
    this.store.dispatch(new PostDelete(postId));
  }

  removeRepostsOfParentPost(postId): void {
    this.getRepostIdsByParentPostId(postId).pipe(take(1)).subscribe(ids => {
      ids.forEach(id => this.store.dispatch(new PostDelete(id)));
    });
  }

  deletePost(postId, isReplyPost?: boolean): void {
    this.socialService.deletePost(postId).pipe(take(1)).subscribe(() => {
      this.removePostIntoStore(postId);
      if (isReplyPost) {
        this.store.dispatch(new PostReplysRemove({
          postId: postId,
          ids: [postId]
        }));
      } else {
        this.getPostReplys().pipe(take(1)).subscribe(res => {
          this.logger.info("[getPostReplys]: ", res);
          if (res !== null) {
              const object = res[parseInt(postId)];
              this.logger.info("[getPostReplys][object]", object);
              if (object?.children) {
                  const ids = object?.children.map(c => c.id);
                  if (!!ids && ids.length > 0) {
                    this.store.dispatch(new PostReplysRemove({
                      postId: postId,
                      ids: ids
                    }));
                  }
              }
          }
        });
        this.updatePostsInfo(-1);
        this.broadcaster.broadcast("BACK_TO_SOCIAL_STREAM");
      }
      let repostedPostsToDelete = [];
      this.getAllPosts().pipe(take(1)).subscribe(posts => {
        repostedPostsToDelete =  [...posts].filter(p => (p?.original_post?.id === postId))
          .map(p => p.id);
      });
      this.store.dispatch(new PostBulkDelete(repostedPostsToDelete));
      const DELETE_KEY = "SOCIAL_POST_DELETED";
      this.translate.get(DELETE_KEY).pipe(take(1)).subscribe(text => {
        this.socialSnackbarService.openSnackBar(text);
      });
    }, err => {
      this.logger.info("[deletePost] err", err);
      if (err.error) {
        this.socialSnackbarService.openSnackBar(err.error.error, SnackbarType.CLOSE);
      }
    });
  }

  buildDataObject(totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean) {
    return {
      totalCount,
      offset,
      ids,
      isLoading,
      isLoaded
    };
  }

  buildDataObjectForPosts(totalCount: number, offset: number, ids: number[], isLoading: boolean, isLoaded: boolean) {
    return {
      totalCount,
      offset,
      ids,
      isLoading,
      isLoaded
    };
  }

  extractIdsFromPosts(posts: Post[], previousIds?: string[]) {
    let ids = [];
    if (previousIds && previousIds.length > 0) {
      posts.forEach(post => !previousIds.includes(post.id) && ids.push(post.id));
    } else {
      posts.forEach(post => ids.push(post.id));
    }
    return ids;
  }

  getAllPostInfo(): Observable<any> {
    return this.store.select(getAllPostsInfo);
  }

  updatePostsInfo(changeInTotalCount = 0): void {
    this.getAllPostInfo().pipe(take(1)).subscribe(info => {
      if (info  !== null ) {
        let posts_count = info.totalCount;
        let offset = info.offset;
        if (changeInTotalCount > 0) {
          posts_count = !!posts_count ? posts_count + changeInTotalCount : 1;
          offset = !!offset ? offset + changeInTotalCount : 1;
        }  else if (changeInTotalCount < 0) {
          posts_count = !!posts_count ? posts_count - 1 : 0;
          offset = !!offset ? offset - 1 : 0;
        }
        const data = {
          totalCount: posts_count,
          offset: offset,
          ids: info?.ids,
          isLoading: false,
          isLoaded: posts_count === 0 ? false : true
        };
        this.store.dispatch(new AllPostsInfoUpdate(data));
      }
    });
  }

  loadMoreSocialPosts(offset?: number, limit?: number, isMedia?: boolean): void {
    let totalCount = 0;
    let previousIds = [];
    if (!isMedia) {
      this.getAllPostInfo().pipe(take(1)).subscribe(info => {
        if (info) {
          totalCount = info.totalCount;
          previousIds = info.ids;
          if (offset > info.offset) {
            offset = info.offset;
          }
        }
      });
      if (totalCount > offset) {
        this.socialService.getPosts(offset, limit).pipe(take(1)).subscribe(res => {
          if (!!res && res.posts) {
            const posts: Post[] = res.posts;
            const data = {
              totalCount: res.total_count,
              offset: offset + res.posts.length,
              ids: [...previousIds, ...this.extractIdsFromPosts(posts)],
              isLoading: false,
              isLoaded: true
            };
            this.store.dispatch(new AllPostsInfoUpdate(data));
            this.addPostsToStore(posts);
          }
        }, error => {
          this.socialSnackbarService.openSnackBar(error);
        });
      }
    } else {
      this.getMediaPostInfo().pipe(take(1)).subscribe(info => {
        if (info) {
          totalCount = info.totalCount;
          previousIds = info.ids;
          if (offset > info.offset) {
            offset = info.offset;
          }
        }
      });
      if (totalCount > offset) {
        this.socialService.getPosts(offset, limit, "attachment=*").pipe(take(1)).subscribe(res => {
          if (!!res && res.posts) {
            const posts: Post[] = res.posts;
            const data = {
              totalCount: res.total_count,
              offset: offset + res.posts.length,
              ids: [...previousIds, ...this.extractIdsFromPosts(posts)],
              isLoading: false,
              isLoaded: true
            };
            this.store.dispatch(new MediaPostInfoUpdate(data));
            this.addPostsToStore(posts);
          }
        }, error => {
          this.socialSnackbarService.openSnackBar(error);
        });
      }
    }
  }

  setSelectedPost(postId: string) {
    this.store.dispatch(new SetSelectedPostId(postId));
    if (!!postId && postId !== null) {
      localStorage.setItem("socialPostId", postId);
    }
  }

  getAllPostReply(postId): void {
    this.socialService.getAllPostReply(postId).pipe(take(1)).subscribe((res: any) => {
      if (!!res && res.posts) {
          const posts: Post[] = res.posts;
          this.addPostsToStore(posts);
          this.replyOperation(res, postId);
      }
    });
  }

  getMediaPostInfo(): Observable<any> {
    return this.store.select(getMediaPostsInfo);
  }

  setSocialUserJid(userJid: string) {
    return this.store.dispatch(new PostUserJidUpdate(userJid));
  }

  getSocialUserJid(): Observable<string> {
    return this.store.select(getPostUserJid);
  }

  bookMarkPost(postId) {
    this.socialService.bookMarkPost(postId).pipe(take(1)).subscribe((res: any) => {
      this.getPostById(postId).pipe(take(1)).subscribe( () => {
        let newPost = { ...res.post };
        this.updatePostIntoStore(newPost);
        this.getBookmarkedPosts().pipe(take(1), filter(v => !!v))
          .subscribe(info => {
            const data = {
              ...info,
              totalCount: info.totalCount + 1,
              offset: info.offset + 1,
              ids: [postId, ...info.ids]
            };
            this.store.dispatch(new BookmarkedPostsInfoUpdate(data));
            this.translate.get("ADDED_TO_BOOKMARKS").pipe(take(1)).subscribe(text => {
              this.socialSnackbarService.openSnackBar(text, SnackbarType.CHECKMARK);
            });
          });
      });
    }, err => {
      if (err.error) {
        this.socialSnackbarService.openSnackBar(err.error.error, SnackbarType.CLOSE);
      }
    });
  }

  unBookMarkPost(postId) {
    this.socialService.unBookMarkPost(postId).pipe(take(1)).subscribe((res: any) => {
      this.getPostById(postId).pipe(take(1)).subscribe( () => {
        let newPost = { ...res.post };
        this.updatePostIntoStore(newPost);
        this.getBookmarkedPosts().pipe(take(1), filter(v => !!v))
          .subscribe(info => {
            const data = {
              ...info,
              totalCount: info.totalCount - 1,
              offset: info.offset - 1,
              ids: [...(info.ids.filter(id => id !== postId))]
            };
            this.store.dispatch(new BookmarkedPostsInfoUpdate(data));
            this.translate.get("REMOVED_FROM_BOOKMARKS").pipe(take(1)).subscribe(text => {
              this.socialSnackbarService.openSnackBar(text, SnackbarType.CHECKMARK);
            });
          });
      });
    }, err => {
      if (err.error) {
        this.socialSnackbarService.openSnackBar(err.error.error, SnackbarType.CLOSE);
      }
    });
  }

  likePost(postId) {
    this.socialService.likePost(postId).pipe(take(1)).subscribe((res: any) => {
        this.getPostById(postId).pipe(take(1)).subscribe( () => {
          let newPost = { ...res.post };
          this.updatePostIntoStore(newPost);
          let loggedInUserId = "";
          this.getSocialUserJid().pipe(take(1)).subscribe(i => loggedInUserId = i);
          if (!!loggedInUserId) {
            this.addUserPostIdInStore(loggedInUserId, postId, USER_POST_TYPE.LIKE);
          }
        });
      }, err => {
        this.logger.info("[likePost] err", err);
        if (err.error) {
          this.socialSnackbarService.openSnackBar(err.error.error, SnackbarType.CLOSE);
        }
      });
  }


  unlikePost(postId) {
    this.socialService.unlikePost(postId).pipe(take(1)).subscribe((res: any) => {
        this.getPostById(postId).pipe(take(1)).subscribe( () => {
          let newPost = { ...res.post };
          this.updatePostIntoStore(newPost);
          let loggedInUserId = "";
          this.getSocialUserJid().pipe(take(1)).subscribe(i => loggedInUserId = i);
          if (!!loggedInUserId) {
            this.removeUserPostIdInStore(loggedInUserId, postId, USER_POST_TYPE.LIKE);
          }
        });
      }, err => {
        this.logger.info("[unlikePost] err", err);
        if (err.error) {
          this.socialSnackbarService.openSnackBar(err.error.error, SnackbarType.CLOSE);
        }
      });
  }

  getPostById(postId): Observable<Post> {
    return this.store.select((state: PostRootState) => getPostById(state, postId)).pipe(switchMap(post => {
      const $postDetail = new BehaviorSubject<any>(post);
      if(!post) {
        this.socialService.getSocialPostById(postId)
          .pipe(map((v: any) => v.post as Post))
          .subscribe(value => {
            this.addPostsToStore([value]);
          }, value => {
            const errorMessage = value?.error?.error;
            this.logger.info(errorMessage);
          });
      }
      return $postDetail.asObservable().pipe(filter(i => !!i));
    }));
  }

  getRepostIdsByParentPostId(postId): Observable<string[]> {
    return this.store.select((state: PostRootState) => getRepostsByParentId(state, postId));
  }

  getMappedPreviewData(social: any) {
    if(social?.resultType === "SOCIAL") {
      return  {
        socialPostData: {
          ...social,
          description: this._sanitizer.bypassSecurityTrustHtml(social?.description),
        },
      };
    }
  }

  getPostByIds(postIds): Observable<Post[]> {
    return this.store.select((state: PostRootState) => getPostByIds(state, postIds));
  }

  updatePostIntoStore(post: Post) {
    this.bulkUpdateAllReposts(post);
    // this.store.dispatch(new PostUpdate(post));
  }

  getPostReplys(): Observable<any> {
    return this.store.select(getPostReplys);
  }

  replyOperation(res: any, postId): void {
    const total_count = res.total_count;
    const ids = res.posts.map((p: any) => ({id: p?.id}));
    this.store.dispatch(new PostReplysLoadSuccess({postId, children: ids, total_count, isLoaded: true, isLoading: false}));
  }

  setScrollToItem(route, postId) {
    this.store.dispatch(new PostScrollToItemSuccess({route, postId}));
  }

  removeScrollToItem(route) {
    this.store.dispatch(new PostRemoveScrollToItem({route}));
  }

  removeAllScrollToItem() {
    this.store.dispatch(new PostRemoveAllScrollToItem());
  }

  getScrollToItem() {
    return this.store.select(getScrollToItem);
  }

  addReplyToStore(parentPostId, postId): void {
    this.store.dispatch(new PostReplysAdd({
      postId: parentPostId,
      children: [{
        id: postId
      }]
    }));
  }

  getSelectedUserFollowers(jid: string) {
    return this.store.select(getFollowers).pipe(
      tap(followers => {
        const userFollowers = followers?.[jid];
        if (!userFollowers) {
          this.loadUserFollowers(jid);
        }
      })
    );
  }

  getSelectedUserFollowings(jid: string) {
    return this.store.select(getFollowings).pipe(
      tap(followers => {
        const userFollowers = followers?.[jid];
        if (!userFollowers) {
          this.loadUserFollowingList(jid);
        }
      })
    );
  }

  loadUserFollowers(jid: string): void {
    this.store.dispatch(new ProfileFollowersLoadRequest(jid));
    this.socialService.getFollowersList(jid).pipe(take(1)).subscribe(res => {
      if (!!res && res.users) {
        let newOffset = (res?.offset || 0) + 25;
        const total_count = res.total_count;
        newOffset = newOffset > total_count ? total_count : newOffset;
        const users = res.users;
       this.store.dispatch(new FollowersLoadSuccess(users));
        this.store.dispatch(new ProfileFollowersLoadSuccess({jid, users, offset: newOffset, total_count}));
      }
    });
  }

  loadUserFollowingList(jid: string): void {
    this.store.dispatch(new ProfileFollowingLoadRequest(jid));
    this.socialService.getFollowingList(jid).pipe(take(1)).subscribe(res => {
      if (!!res && res.users) {
        let newOffset = (res?.offset || 0) + 25;
        const total_count = res.total_count;
        newOffset = newOffset > total_count ? total_count : newOffset;
        const users = res.users;
       this.store.dispatch(new FollowersLoadSuccess(users));
        this.store.dispatch(new ProfileFollowingLoadSuccess({jid, users, offset: newOffset, total_count}));
      }
    });
  }

  getFollowersByIds(followeIds): Observable<Follow[]> {
    return this.store.select(getFollowersByIds, followeIds);
  }

  followUser(userjid) {
    const response = new Subject();
    this.socialService.followUser(userjid).pipe(take(1)).subscribe(() => {
      response.next(true);
    }, (err) => {
      this.logger.error("[Error] -Follow user $#%$#-", err);
    });
    return response;
  }

  addFollowerInStore(data: {source, target}) {
    const source = data.source;
    const target = data.target;
    const source_profile: Follow = {
      jid: source.user.jid,
      avatar_url: source.user.avatar_url,
      followed_by: source.followed_by,
      following: source.following,
      id: source.user.id,
      name: source.user.name,
    };
    const target_profile: Follow = {
      jid: target.user.jid,
      avatar_url: target.user.avatar_url,
      followed_by: target.followed_by,
      following: target.following,
      id: target.user.id,
      name: target.user.name,
    };
    this.store.dispatch(new FollowersLoadSuccess([source_profile, target_profile]));
    this.store.dispatch(new ProfileFollowingAddOne({
      jid: source.user.jid,
      id: target.user.id
    }));
    this.store.dispatch(new ProfileFollowersAddOne({
      jid: target.user.jid,
      id: source.user.id,
    }));
    const sourceUser: SocialProfile = {...data.source.user, followed_by: data.source.followed_by, following: data.source.following};
    const targetUser: SocialProfile = {...data.target.user, followed_by: data.target.followed_by, following: data.target.following};
    this.store.dispatch(new ProfileUpdate(sourceUser));
    this.store.dispatch(new ProfileUpdate(targetUser));
  }

  unFollowUser(userjid) {
    const response = new Subject();
    this.socialService.unFollowUser(userjid).pipe(take(1)).subscribe(() => {
      response.next(true);
    });
    return response;
  }

  removeFollowerInStore(data: {source: Follow, target: Follow}) {
    this.store.dispatch(new FollowersLoadSuccess([data.source, data.target]));
    this.store.dispatch(new ProfileFollowingRemoveOne({jid: data.source.jid, id: data.target.id}));
    this.store.dispatch(new ProfileFollowersRemoveOne({jid: data.target.jid, id: data.source.id}));
    this.store.dispatch(new ProfileUpdate(data.source));
    this.store.dispatch(new ProfileUpdate(data.target));
  }

  copyPostLink(jid: string, postId: string) {
    let currentUrl = CommonUtil.getBaseOriginUrl();
    currentUrl = `${currentUrl}/talk/social/${jid}/status/${postId}`;
    CommonUtil.copyToClipboard([currentUrl]);
    this.translate.get("LINK_COPIED_TO_THE_CLIPBOARD").pipe(take(1)).subscribe(text => {
      this.socialSnackbarService.openSnackBar(text, SnackbarType.CHECKMARK);
    });
  }

  getSocialPostById(postId): void {
    this.socialService.getSocialPostById(postId).pipe(take(1)).subscribe(res => {
      if (!!res && res.post) {
        const posts = res.post;
        this.addPostsToStore([posts]);
      }
    });
  }

  loadMoreSearchFollowUnfollowUser(offset: number, searchKeyword: string): Observable<any> {
    const response = new Subject<boolean>();
    this.socialService.searchFollowUnfollow(searchKeyword, offset, this.LIMIT).pipe(take(1)).subscribe((resp: any) => {
      if (!!resp && resp.users) {
          response.next(resp);
      }
    });
    return response.asObservable();
  }

  loadMoreFollowingList(jid: string, offset?: number) {
    this.socialService.getFollowingList(jid, offset, this.LIMIT).pipe(take(1)).subscribe(res => {
      if (!!res && res.users) {
        let newOffset = (res?.offset || 0) + 25;
        const total_count = res.total_count;
        newOffset = newOffset > total_count ? total_count : newOffset;
        const users = res.users;
        this.store.dispatch(new FollowersLoadSuccess(users));
        this.store.dispatch(new ProfileFollowingLoadSuccess({jid, users, offset: newOffset, total_count}));
      }
    });
  }

  loadMoreFollowerList(jid: string, offset?: number) {
    this.socialService.getFollowersList(jid, offset, this.LIMIT).pipe(take(1)).subscribe(res => {
      if (!!res && res.users) {
        let newOffset = (res?.offset || 0) + 25;
        const total_count = res.total_count;
        newOffset = newOffset > total_count ? total_count : newOffset;
        const users = res.users;
        this.store.dispatch(new FollowersLoadSuccess(users));
        this.store.dispatch(new ProfileFollowersLoadSuccess({jid, users, offset: newOffset, total_count}));
      }
    });
  }

  public updateUser(userData): Observable<any> {
    return this.socialService.updateSocialProfile(userData)
      .pipe(tap((data: any) => {
        let jid = "";
        this.getSocialUserJid().pipe(take(1)).subscribe(j => jid = j);
        if (!!jid) {
          let user = {
            jid: jid
          };
          Object.keys(userData).forEach(key => {
            if(data[key]) {
              user[key] = data[key];
            } else {
              user[key] = userData[key];
            }
          });
          this.store.dispatch(new ProfileUpdate({...user}));
        }
        this.logger.info("[User data]", data);
      }));
  }

  rePost(post): void {
    const postId = !!post?.original_post ? post?.original_post?.id : post?.id;
    let reposting = [];
    this.store.select(getPostReposting).pipe(take(1)).subscribe(r => reposting = r);
    if (!reposting.includes(postId)) {
      this.store.dispatch(new PostRepostRequest(postId));
      const body : any = {
        "post": {
          "related_post_id": postId
        }
      };
      this.socialService.createPost(body).pipe(take(1)).subscribe((res: any) => {
        if (!!res && res.post) {
          if (res.post.original_post) {
            this.bulkUpdateAllReposts(res.post.original_post);
          }
          this.addPostToStore(res.post);
          this.updatePostsInfo(1);
          this.store.dispatch(new PostRepostSuccess(postId));
        }
      });
    }
  }

  undoRepost(post): void {
    const postId = !!post?.original_post ? post?.original_post?.id : post?.id;
    let reposting = [];
    this.store.select(getPostReposting).pipe(take(1)).subscribe(r => reposting = r);
    if (!reposting.includes(postId)) {
      this.store.dispatch(new PostRepostRequest(postId));
      let postsToDelete = [];
      this.getAllPosts().pipe(take(1)).subscribe(posts => {
        postsToDelete =  [...posts].filter(p => (p?.original_post?.id === postId) && (p?.author?.jid === this.userJID?.bare))
          .map(p => p.id);
      });
      this.socialService.undoRepost(postId).pipe(take(1)).subscribe((res: any) => {
        if (!!res && res.post) {
          this.store.dispatch(new PostBulkDelete(postsToDelete));
          this.bulkUpdateAllReposts(res.post);
          this.store.dispatch(new PostRepostSuccess(postId));
        }
      });
    }
  }

  bulkUpdateAllReposts(originalPost: Post) {
    let postsToUpdate = [];
    this.getAllPosts().pipe(take(1)).subscribe(posts => {
      postsToUpdate =  [...posts].filter(p => p?.original_post?.id === originalPost.id)
        .map(p => ({...p, original_post: originalPost}));
    });
    this.store.dispatch(new PostBulkUpdate([originalPost, ...postsToUpdate]));
  }

  addReplyCountToPost(postId): void {
    this.getPostById(postId).pipe(take(1)).subscribe(post => {
      if (!!post) {
        post.replies_count = post.replies_count + 1;
        this.updatePostIntoStore(post);
      }
    });
  }

  publishSocialPost(post): void {
    const body = {
      "post": {
        "is_draft": "0",
        "is_scheduled": "0",
        "publish_at": ""
      }
     };
    this.socialService.updatePost(post.id, body).pipe(take(1)).subscribe((res: any) => {
      if (!!res && res.post) {
        const post: Post = res.post;
        this.addPostToStore(post);
        let isMedia = false;
        if (post?.attachments?.length) {
          isMedia = true;
        }
        this.getAllPosts().pipe(take(1)).subscribe(res => {
          const data = {
            totalCount: res.length,
            offset: res.length,
            ids: this.extractIdsFromPosts(res),
            isLoading: false,
            isLoaded: true
          };
          this.store.dispatch(new AllPostsInfoUpdate(data));
          if (isMedia) {
            this.store.dispatch(new MediaPostInfoUpdate(data));
          }
        });
      }
    }, error => {
      this.logger.info("[Error] :: ", error);
    });
  }

  getCurrentUserId(): Observable<any> {
    return this.store.select(getUserJID);
  }

  setSelectedUserJid(userJid: string) {
    return this.store.dispatch(new SetSelectedProfileId(userJid));
  }

  getSelectedUserJid(): Observable<string> {
    return this.store.select(getSelectedProfileId);
  }

  getPostTimer() {
    return this.store.select(getPostTimer);
  }

  getProfileById(jid: string) {
    return this.store.select((state: PostRootState) => getProfileById(state, jid)).pipe(
      tap(profile => {
        if (!profile) {
          this.store.dispatch(new ProfileLoadRequest());
          this.socialService.getFollowerDetails(jid).subscribe(
            res => {
              this.store.dispatch(new ProfileLoadSuccess(res.user));
            }
          );
        }
      })
    );
  }

  updateProfileIntoStore(profile: SocialProfile) {
    this.store.dispatch(new ProfileUpdate(profile));
  }

  getUserPosts(jid: string, type: USER_POST_TYPE) {
    let postsSelector = this.store.select(getProfileAllPosts);
    if (type === USER_POST_TYPE.LIKE) {
      postsSelector = this.store.select(getProfileLikedPosts);
    } else if (type === USER_POST_TYPE.MEDIA) {
      postsSelector = this.store.select(getProfileMediaPosts);
    } else if (type === USER_POST_TYPE.REPLIES) {
      postsSelector = this.store.select(getProfileReplyPosts);
    } else if(type === USER_POST_TYPE.ALL){
      postsSelector = this.store.select(getProfileAllPosts);

    }
    return postsSelector.pipe(
      tap(posts => {
        const profilePosts = posts?.[jid];
        if (!profilePosts) {
          this.store.dispatch(new ProfilePostsLoadRequest({userJID: jid, type}));
          this.loadUserPosts({jid, type});
        }
      })
    );
  }

  getFilteredUserPosts(jid: string, type: USER_POST_TYPE, publishAt: string) {
    let postsSelector = this.store.select(getProfileFilteredAllPosts);
    if (type === USER_POST_TYPE.MEDIA) {
      postsSelector = this.store.select(getProfileFilteredMediaPosts);
    } else if (type === USER_POST_TYPE.REPLIES) {
      postsSelector = this.store.select(getProfileFilteredReplyPosts);
    }
    return postsSelector.pipe(
      tap(posts => {
        const profilePosts = posts?.[publishAt];
        if (!profilePosts) {
          this.store.dispatch(new FilteredPostsLoadRequest({type, filter: publishAt}));
          this.loadUserFilteredPosts({jid, type}, publishAt);
        }
      })
    );
  }

  loadMoreUserPosts(jid: string, type: USER_POST_TYPE) {
    let postsSelector = this.store.select(getProfileAllPosts);
    if (type === USER_POST_TYPE.LIKE) {
      postsSelector = this.store.select(getProfileLikedPosts);
    } else if (type === USER_POST_TYPE.MEDIA) {
      postsSelector = this.store.select(getProfileMediaPosts);
    } else if (type === USER_POST_TYPE.REPLIES) {
      postsSelector = this.store.select(getProfileReplyPosts);
    }
    postsSelector.subscribe(
      posts => {
        const profilePosts = posts?.[jid];
        if (!profilePosts) {
          this.store.dispatch(new ProfilePostsLoadRequest({userJID: jid, type}));
          this.loadUserPosts({jid, type});
        } else {
          const loading = profilePosts?.isLoading;
          const offset = profilePosts?.offset;
          const total_count = profilePosts?.total_count;
          const loadedPostsCount = profilePosts?.ids?.length;
          if (!loading && (total_count > loadedPostsCount)) {
            this.store.dispatch(new ProfilePostsLoadRequest({userJID: jid, type}));
            this.loadUserPosts({jid, type, offset});
          }
        }
      }
    );
  }

  loadUserPosts(data: {jid: string, type: USER_POST_TYPE, offset?: number, limit?: number, }): void {
    this.getProfileById(data.jid).pipe(filter(v => !!v), take(1)).subscribe(
      res => {
        const userId = res.id;
        this.socialService.getUserPosts(userId, data.type, data.offset, data.limit).pipe(take(1)).subscribe(res => {
          if (!!res && res.posts) {
            const posts: Post[] = res.posts;
            this.store.dispatch(new ProfilePostsLoadSuccess({
              userJID: data.jid,
              type: data.type,
              offset: res.posts.length + (data.offset || 0),
              posts: posts,
              total_count: res.total_count
            }));
            this.addPostsToStore(res.posts);
          }
        }, error => {
          this.socialSnackbarService.openSnackBar(error?.messages);
        });
      }
    );
  }

  loadUserFilteredPosts(data: {jid: string, type: USER_POST_TYPE, offset?: number, limit?: number }, publishAt: string): void {
    this.getProfileById(data.jid).pipe(filter(v => !!v), take(1)).subscribe(
      res => {
        const userId = res.id;
        this.socialService.getUserPosts(userId, data.type, data.offset, data.limit, publishAt).pipe(take(1)).subscribe(res => {
          if (!!res && res.posts) {
            const posts: Post[] = res.posts;
            this.store.dispatch(new FilteredPostsLoadSuccess({
              filter: publishAt,
              type: data.type,
              offset: res.posts.length + (data.offset || 0),
              posts: posts,
              total_count: res.total_count
            }));
            this.addPostsToStore(res.posts);
          }
        }, error => {
          this.socialSnackbarService.openSnackBar(error?.messages);
        });
      }
    );
  }

  addRepostToStore(post): void {
    this.addPostToStore(post);
    if (post?.original_post) {
      this.getPostById(post?.original_post?.id).pipe(take(1)).subscribe(po => {
        po.reposts_count = po?.reposts_count + 1;
        this.updatePostIntoStore(po);
      });
    }
  }

  generateLocalAttachmentsURLForSocial(attachments: Attachment[]): Attachment[] {
    const updatedAttachments = attachments.map(attachment => {
      let content_url = CommonUtil.getAttachmentLocalAPIURL(attachment.content_url);
      let thumbnail_url = CommonUtil.getAttachmentLocalAPIURL(attachment.thumbnail_url);
      return {...attachment, content_url, thumbnail_url};
    });
    return updatedAttachments;
  }

  async showFilePreview(fileAttachments: any[], file: any) {

  }

  addUserPostIdInStore(userJID: SocialProfile["jid"], postId: Post["id"], type: USER_POST_TYPE) {
    this.store.dispatch(new ProfilePostAdd({userJID, postId, type}));
  }

  removeUserPostIdInStore(userJID: SocialProfile["jid"], postId: Post["id"], type: USER_POST_TYPE) {
    this.store.dispatch(new ProfilePostRemove({userJID, postId, type}));
  }

  async createPostForSocial() {

  }

  back(): void {
    this.locationService.back();
  }
}
