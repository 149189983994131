
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "../../actions";
import { Conversation, ConversationConfig } from "../models/conversation.model";
import { JitsiOption } from "../models/jitsi-participant.model";

export class ConversationActionTypes {
  static CONVERSATION_LOAD_REQUEST = "[CONVERSATION] Load Request";
  static CONVERSATION_LOAD_SUCCESS = "[CONVERSATION] Load Success";

  static CONVERSATION_NEXT_LOAD_REQUEST = "[CONVERSATION] Next Load Request";
  static CONVERSATION_NEXT_LOAD_SUCCESS = "[CONVERSATION] Next Load Success";

  static CONVERSATION_CREATE = "[CONVERSATION] Create";
  static CONVERSATION_UPDATE_SUBJECT = "[CONVERSATION] Update Subject";
  static INCOMING_CONFERENCE = "[CONVERSATION] Call Invitation";

  static CONVERSATION_SET_CONFIG = "[CONVERSATION] Set Config";

  static CONVERSATION_INITIAL_ACTIVE = "[CONVERSATION] Initial Ative";
  static CONVERSATION_INITIAL_DEACTIVATE = "[CONVERSATION] Initial Deactivate";
  static CONVERSATION_RESET_ALL_ACTIVATED = "[CONVERSATION] Reset All Ativated";
  static CONVERSATION_SELECT = "[CONVERSATION] Select";
  static CONVERSATION_DE_SELECT = "[CONVERSATION] De Select";

  static CONVERSATION_DELETE = "[CONVERSATION] Delete";
  static CONVERSATION_MULTIPLE_DELETE = "[CONVERSATION] Multiple Delete";
  static CONVERSATION_INACTIVE = "[CONVERSATION] InActive";

  static CONVERSATION_UPDATE_NOTIFICATION_SETTING = "[CONVERSATION] Update notification settings";

  static CONVERSATION_UPDATE_SOUND_SETTING = "[CONVERSATION] Update Sound settings";

  static CONVERSATION_BLOCK_LIST_INDEX = "[CONVERSATION] Block List Index";
  static CONVERSATION_BLOCK_LIST_ADD = "[CONVERSATION] Block List Add";
  static CONVERSATIONS_BLOCK_LIST_ADD = "[CONVERSATION] Blocks List Add";
  static CONVERSATIONS_BLOCK_LIST_REMOVE = "[CONVERSATION] Block List Remove";
  static CONVERSATION_BLOCK_LIST_REMOVE = "[CONVERSATION] Block List Remove";

  static CONVERSATION_SHOW_DETAILS = "[CONVERSATION] Show Details";
  static CONVERSATION_HIDE_DETAILS = "[CONVERSATION] Hide Details";

  static CONVERSATION_SET_TEXT = "[CONVERSATION] Set Text";

  static CONVERSATION_UPDATE_MEMBERS = "[CONVERSATION] Update Members";
  static CONVERSATION_UPDATE_OWNER = "[CONVERSATION] Update Owner";
  static CONVERSATION_UPDATE_ADMINS = "[CONVERSION] Update Admins";
  static MULTI_CONVERSATION_UPDATE_MEMBERS = "[CONVERSATION] Multi Conversation Update Members";
  static MULTI_CONVERSATION_UPDATE_OWNER = "[CONVERSATION] Multi Conversation Update Owner";
  static MULTI_CONVERSATION_UPDATE_ADMINS = "[CONVERSATION] Multi Conversation Update Admins";
  static MULTI_CONVERSATION_UPDATE_AUDIENCE = "[CONVERSATION] Multi Conversation Update Audience";
  static CONVERSATION_REMOVE_MEMBER = "[CONVERSATION] Remove Member";
  static CONVERSATION_REMOVE_MEMBERS = "[CONVERSATION] Remove Members";
  static CONVERSATION_ADD_MEMBERS = "[CONVERSATION] Add Members";
  static START_CONFERENCE = "[CONVERSATION] Start Conference";
  static LEAVE_CONFERENCE = "[CONVERSATION] Leave Conference";
  static UPDATE_ACTIVE_CONFERENCE = "[CONVERSATION] Update Conference";
  static SET_JITSI_ROOM = "[CONVERSATION] Set Jitsiroom";
  static START_WHITEBOARD = "[CONVERSATION] Start Whiteboard";
  static CONVERSATION_RESET_UNREAD = "[CONVERSATION] Reset Unread";
  static CONVERSATION_SET_UNREAD = "[CONVERSATION] Set Unread";
  static RESET_LAST_PAGE_LOADED = "[CONVERSATION] Reset Last Page Loaded";

  static ARCHIVE_CONVERSATION = "[CONVERSATION] Archive Conversation";
  static UPDATE_CONVERSATION = "[CONVERSATION] Update Conversation";
  static UNARCHIVE_CONVERSATION = "[CONVERSATION] Unarchive Conversation";
  static CONVERSATION_UPDATE_UPLOADING_FILE = "[CONVERSATION] Update uploading file";
  static CONVERSATION_FILE_UPLOADED = "[CONVERSATION] Remove uploaded file";
  static CONVERSATION_UPDATE_LAST_ACTIVITY = "[CONVERSATION] Update last activity";

  static CONVERSATION_LAST_ACTIVITY = "[CONVERSATION] last activity";
  static CONVERSATION_LAST_TEXT = "[CONVERSATION] last text";
  static SET_ACTIVE_CONVERSATION = "[CONVERSATION] Set Active Conversation";
  static CONVERSATION_UPDATE_JITSI_ROOM = "[CONVERSATION] Update jitsi room";
  static CONVERSATION_ROOM_IDS = "[CONVERSATION] Update jitsi room";
  static CONVERSATION_UPDATE_BROADCAST_AUDIENCE = "[CONVERSATION] Update broadcast audience";
  static CONVERSATION_UPDATE_BROADCAST_TITLE = "[CONVERSATION] Update broadcast title";
  static CONVERSATION_UPDATE_BROADCAST_DATA = "[CONVERSATION] Update broadcast data";
  static CONVERSATION_ADD_PAD_NOTIFICATIONS = "[CONVERSATION] add pad notifications";
  static CONVERSATION_REMOVE_PAD_NOTIFICATIONS = "[CONVERSATION] remove pad notifications";
  static CONVERSATION_ADD_WB_NOTIFICATIONS = "[CONVERSATION] add WB notifications";
  static CONVERSATION_REMOVE_WB_NOTIFICATIONS = "[CONVERSATION] remove WB notifications";
  static CONVERSATION_DATA_UPDATE = "[CONVERSATION] Update conversation data";
  static CONVERSATION_UPDATE_MCB = "[CONVERSATION] Update conversation mcb";
  static CONVERSATION_UPDATE_AUDIENCES = "[CONVERSATION] Update conversation audiences";
  static MINI_CONVERSATION_SELECT = "[CONVERSATION] Select mini conversation";
  static CONVERSATION_SET_NOTIFICATION_CONFIG = "[CONVERSATION] Select notification config";
  static CONVERSATION_SET_ALL_NOTIFICATION_CONFIG = "[CONVERSATION] Select all notification config";

  static CONVERSATION_UPDATE_CALL_FLAG = "[CONVERSATION] Update has_active_call flag";
  static CONVERSATION_UPDATE_FAV_FLAG = "[CONVERSATION] Update isfav flag";
  static CONVERSATION_UPDATE_PIN_FLAG = "[CONVERSATION] Update is_pinned flag";
  static CONVERSATION_SET_MESSAGE_TO_DELETE = "[CONVERSATION] Set message to delete";
  static CONVERSATION_UPDATE_RETENTION_TIME = "[CONVERSATION] Update retention time";
  static CONVERSATION_UPDATE_DOWNLOADING_FILE = "[CONVERSATION] Update downloading file";
  static CONVERSATION_RESET_DOWNLOAD = "[CONVERSATION] Remove downloaded file";
}

export class ConversationLoadRequest implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_LOAD_REQUEST;
}

export class ConversationLoadSuccess implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_LOAD_SUCCESS;

  constructor(public payload: Conversation[]) {
  }
}

export class ConversationNextLoadRequest implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_NEXT_LOAD_REQUEST;
}

export class ConversationNextLoadSuccess implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_NEXT_LOAD_SUCCESS;

  constructor(public payload: { conversations: Conversation[] }) {
  }
}

export class ConversationCreate implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_CREATE;

  constructor(public payload: Conversation) {
  }
}

export class ConversationAddPadNotification implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_ADD_PAD_NOTIFICATIONS;

  constructor(public payload: string) {
  }
}

export class ConversationRemovePadNotification implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_REMOVE_PAD_NOTIFICATIONS;

  constructor(public payload: string) {
  }
}

export class ConversationAddWBNotification implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_ADD_WB_NOTIFICATIONS;

  constructor(public payload: string) {
  }
}

export class ConversationRemoveWBNotification implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_REMOVE_WB_NOTIFICATIONS;

  constructor(public payload: string) {
  }
}

export class ConversationInActive implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_INACTIVE;

  constructor(public payload: { target: string }) {
  }
}

export class ConversationSubjectUpdate implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_SUBJECT;

  constructor(public payload: { target: string, subject: string }) {
  }
}

export class ConversationUpdate implements Action {
  readonly type = ConversationActionTypes.UPDATE_CONVERSATION;

  constructor(public payload: { target: string, changes: any }) {
  }
}

export class IncomingConference implements Action {
  readonly type = ConversationActionTypes.INCOMING_CONFERENCE;

  constructor(public payload: any) {
  }
}

export class ConversationSetConfig implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_SET_CONFIG;

  constructor(public payload: { conversationTarget: string, config: ConversationConfig }) {
  }
}

export class UpdateConversationNotificationConfig implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_SET_NOTIFICATION_CONFIG;

  constructor(public payload: { conversationTarget: string, config: any }) {
  }
}

export class UpdateAllNotificationConfig implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_SET_ALL_NOTIFICATION_CONFIG;

  constructor(public payload: any) {
  }
}

export class ConversationActivate implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_INITIAL_ACTIVE;

  constructor(public payload: string) {
  }
}

export class ConversationDeactivate implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_INITIAL_DEACTIVATE;

  constructor(public payload: string) {
  }
}

export class ConversationResetAllActivated implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_RESET_ALL_ACTIVATED;
}

export class ConversationSelect implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_SELECT;

  constructor(public payload: string) {
  }
}

export class MiniConversationSelect implements Action {
  readonly type = ConversationActionTypes.MINI_CONVERSATION_SELECT;

  constructor(public payload: string) {
  }
}

export class ConversationDeSelect implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_DE_SELECT;
}

export class ConversationDelete implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_DELETE;

  constructor(public payload: string) {
  }
}

export class ConversationDataUpdate implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_DATA_UPDATE;
  constructor(public payload: {target: string, data: any}) {
  }
}

export class UpdateNotificationSetting implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_NOTIFICATION_SETTING;

  constructor(public payload: {conversationTarget: string, type: number}) {
  }
}

export class UpdateRetentionTime implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_RETENTION_TIME;

  constructor(public payload: {conversationTarget: string, retention_time: any}) {
  }
}

export class UpdateConversationCallFlag implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_CALL_FLAG;
  constructor(public payload: {target: string, flag: boolean}) {
  }
}

export class UpdateConversationFavFlag implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_FAV_FLAG;
  constructor(public payload: {target: string, flag: boolean}) {
  }
}

export class UpdateConversationPinFlag implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_PIN_FLAG;
  constructor(public payload: {target: string, flag: boolean}) {
  }
}

export class UpdateSoundSetting implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_SOUND_SETTING;

  constructor(public payload: {conversationTarget: string, type: number}) {
  }
}


export class ConversationBlockListIndex implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_BLOCK_LIST_INDEX;

  constructor(public payload: string[]) {
  }
}


export class ConversationBlockListAdd implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_BLOCK_LIST_ADD;

  constructor(public payload: string) {
  }
}

export class ConversationsBlockListAdd implements Action {
  readonly type = ConversationActionTypes.CONVERSATIONS_BLOCK_LIST_ADD;

  constructor(public payload: string[]) {
  }
}

export class ConversationsBlockListRemove implements Action {
  readonly type = ConversationActionTypes.CONVERSATIONS_BLOCK_LIST_REMOVE;

  constructor(public payload: string[]) {
  }
}


export class ConversationBlockListRemove implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_BLOCK_LIST_REMOVE;

  constructor(public payload: string) {
  }
}

export class ConversationShowDetails implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_SHOW_DETAILS;

  constructor(public payload: string) {
  }
}

export class ConversationHideDetails implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_HIDE_DETAILS;

  constructor(public payload: string) {
  }
}

export class ConversationUpdateBroadcastAudience implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_BROADCAST_AUDIENCE;
  constructor(public payload: { conversationTarget: string, audience: string[] }) {
  }
}

export class ConversationUpdateAudiences implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_AUDIENCES;
  constructor(public payload: { target: string, audiences: string[] }) {
  }
}

export class ConversationUpdateMCB implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_MCB;
  constructor(public payload: { target: string, meta_conference_boards: any[] }) {
  }
}

export class ConversationUpdateBroadcastTitle implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_BROADCAST_TITLE;
  constructor(public payload: { conversationTarget: string, title: string }) {
  }
}

export class ConversationUpdateBroadcastData implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_BROADCAST_DATA;
  constructor(public payload: { conversationTarget: string, changes: any }) {
  }
}

export class ConversationUpdateMembers implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_MEMBERS;


  constructor(public payload: { conversationTarget: string, members: string[] }) {
  }
}

export class ConversationUpdateOwner implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_OWNER;


  constructor(public payload: { conversationTarget: string, owner: string }) {
  }
}

export class ConversationUpdateAdmins implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_ADMINS;


  constructor(public payload: { conversationTarget: string, admins: string[] }) {
  }
}

export class ConversationResetUnread implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_RESET_UNREAD;


  constructor(public payload: string) {
  }

}

export class ConversationSetUnread implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_SET_UNREAD;


  constructor(public payload: { conversationTarget: string, unreadIds: string[], last_mention_stamp?: number, unread_mentions?: string[], total_mentions?: number }) {
  }

}

export class MultiConversationUpdateMembers implements Action {
  readonly type = ConversationActionTypes.MULTI_CONVERSATION_UPDATE_MEMBERS;


  constructor(public payload: { [conversationTarget: string]: string[] }) {
  }

}

export class MultiConversationUpdateOwner implements Action {
  readonly type = ConversationActionTypes.MULTI_CONVERSATION_UPDATE_OWNER;


  constructor(public payload: { [conversationTarget: string]: string[] }) {
  }

}

export class MultiConversationUpdateAdmins implements Action {
  readonly type = ConversationActionTypes.MULTI_CONVERSATION_UPDATE_ADMINS;


  constructor(public payload: { [conversationTarget: string]: string[] }) {
  }

}

export class MultiConversationUpdateAudience implements Action {
  readonly type = ConversationActionTypes.MULTI_CONVERSATION_UPDATE_AUDIENCE;


  constructor(public payload: { [conversationTarget: string]: string[] }) {
  }

}

export class ConversationRemoveMember implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_REMOVE_MEMBER;


  constructor(public payload: { conversationTarget: string, member: string }) {
  }

}

export class ConversationRemoveMembers implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_REMOVE_MEMBERS;


  constructor(public payload: { conversationTarget: string, members: string[] }) {
  }

}

export class ConversationAddMembers implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_ADD_MEMBERS;


  constructor(public payload: { conversationTarget: string, members: string[] }) {
  }

}

export class ConversationUpdateLastActivity implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_LAST_ACTIVITY;


  constructor(public payload: { target: string, seconds: number }) {
  }
}

export class ConversationMultipleDelete implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_MULTIPLE_DELETE;


  constructor(public payload: string[]) {
  }
}

export class StartConference implements Action {
  readonly type = ConversationActionTypes.START_CONFERENCE;

  constructor(public payload: { conversationTarget: string, conferenceType: string, startFromInvitation?: boolean }) {
  }
}

export class SetJitsiRoom implements Action {
  readonly type = ConversationActionTypes.SET_JITSI_ROOM;

  constructor(public payload: { conversationTarget: string, jitsiRoom: string }) {
  }
}

export class StartWhiteboard implements Action {
  readonly type = ConversationActionTypes.START_WHITEBOARD;

  constructor(public payload: { conversationTarget: string, conferenceType: string, startFromInvitation?: boolean }) {
  }
}

export class LeaveConference implements Action {
  readonly type = ConversationActionTypes.LEAVE_CONFERENCE;

  constructor(public payload: { conversationTarget: string }) {
  }
}

export class UpdateActiveConference implements Action {
  readonly type = ConversationActionTypes.UPDATE_ACTIVE_CONFERENCE;

  constructor(public payload: { active: any }) {
  }
}

export class SetConversationText implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_SET_TEXT;

  constructor(public payload: { conversationTarget: string, text: string, originalMessage?: any }) {
  }
}
export class SetMessageToCopyAndDelete implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_SET_MESSAGE_TO_DELETE;

  constructor(public payload: { conversationTarget: string, messageId: string, text: string }) {
  }
}

export class ArchiveConversation implements Action {
  readonly type = ConversationActionTypes.ARCHIVE_CONVERSATION;

  constructor(public payload: { target: string, timestamp: number }) {

  }
}

export class UnArchiveConversation implements Action {
  readonly type = ConversationActionTypes.UNARCHIVE_CONVERSATION;

  constructor(public payload: { target: string, timestamp: number }) {
  }
}

export class UpdateFileInProgress implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_UPLOADING_FILE;

  constructor(public payload: { messageId: string, progress: {
    loaded: number, total: number
  } }) {
  }
}

export class RemoveUploadedFile implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_FILE_UPLOADED;

  constructor(public payload: { messageId: string }) {
  }
}

export class ResetLastPageLoaded implements Action {
  readonly type = ConversationActionTypes.RESET_LAST_PAGE_LOADED;

  constructor(public payload: string) {
  }
}

export class RestoreLastActivity implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_LAST_ACTIVITY;

  constructor(public payload) {
  }
}

export class RestoreRoomIds implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_ROOM_IDS;

  constructor(public payload) {
  }
}

export class RestoreLastText implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_LAST_TEXT;

  constructor(public payload) {
  }
}

export class SetActiveConversation implements Action {
  readonly type = ConversationActionTypes.SET_ACTIVE_CONVERSATION;

  constructor(public payload: Conversation) {
  }
}

export class UpdateConversationRoomId implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_JITSI_ROOM;

  constructor(public payload: { conversationTarget: string, option: JitsiOption }) {
  }
}

export class DownloadFileInProgress implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_UPDATE_DOWNLOADING_FILE;

  constructor(public payload: { messageId: string, progress: {
    loaded?: number, total?: number, status?: string, convoTarget?: string, convoType?: string, url?: string, fileName?: string
  } }) {
  }
}

export class ResetDownloadInProgress implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_RESET_DOWNLOAD;
}
