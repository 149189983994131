
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */


import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, take } from "rxjs";
import { Subject } from "rxjs";
import { TalkRootState, getTotalUnreadCount } from "app/talk/reducers";
import { getGlobalMute, getUserNotifyOption } from "../../reducers/index";
import { ElectronScreenSharingComponent } from "../components/electron-screen-sharing-dialog/electron-screen-sharing-dialog.component";
import { environment } from "app/environments/environment";
import { Broadcaster } from "app/talk/shared/providers";
import { ConferenceShareScreen, SetScreenSharingRequestStatus, SetScreenSharingStarted } from "app/talk/actions/conference";
import { MatDialog } from "@angular/material/dialog";
import { PromptScreensharePermissionsComponent } from "app/talk/shared/components/dialogs/prompt-screenshare-permissions/prompt-screenshare-permissions.component";
import { LoggerService } from "../services/logger.service";
import { TranslateService } from "@ngx-translate/core";
import { ChannelSnackbarService } from "app/channels/channel-snackbar.service";

@Injectable()
export class ElectronService {
  app: any;
  remote: any;
  shell: any;
  win: any;
  ipcRenderer: any;
  desktopCapturer: any;
  downloadManager: any;
  blueimpMd5: any;
  electronStore: any;

  isGlobalMuteEnabled = false;
  notifyOption: string;
  isDownloading: any = {};

  constructor(
    private store: Store<TalkRootState>,
    private broadcaster: Broadcaster,
    private logger: LoggerService,
    public dialog: MatDialog,
    private _translate: TranslateService,
    private channelSnackbarService: ChannelSnackbarService
  ) {
      if (this.isElectron) {
      this.remote = window.electron.remote;
      this.ipcRenderer = window.electron.ipcRenderer;
      this.desktopCapturer = {
        getSources: (opts) => this.ipcRenderer.invoke("DESKTOP_CAPTURER_GET_SOURCES", opts)
      };
      this.shell = window.electron.shell;
      this.blueimpMd5 = window.electron.blueimpMD5;
      this.downloadManager = this.remote.require("electron-download-manager");
      const Store = window.electron.electronStore;
      this.electronStore = new Store();

      this.logger.info("[ElectronService] constructor", !!this.remote, !!this.ipcRenderer, !!this.desktopCapturer, !!this.shell, !!this.blueimpMd5, !!this.downloadManager, !!Store);
      this.app = this.remote.app;
      this.win = this.remote.getCurrentWindow();

      this.store.select(getGlobalMute).subscribe(globalMute => {
        this.isGlobalMuteEnabled = globalMute;
      });

      this.store.select(getUserNotifyOption).subscribe(notifyOption => {
        this.notifyOption = notifyOption;
      });
      // handle message from cmdline open

      this.ipcRenderer.on("openUri", (event, messageData) => {
        this.logger.info("[electronService][openUri] message: ", event, messageData);
        this.broadcaster.broadcast("electronOpenUri", messageData);
      });

      this.broadcaster.on("setupScreenSharingPicker").subscribe(() => {
        if (this.isElectron) {
          this.setupScreenSharingPicker(() => {
            this.logger.info("setupScreenSharingPicker done");
          });
          if (this.isMacOS) {
            const res = this.hasScreenCapturePermission();
            this.logger.info("hasScreenCapturePermission", res);

            if (!res) {
              // need to prompt users to enable the permissions in the System Preferences.
              this.dialog.open(PromptScreensharePermissionsComponent, {
                width: "380px",
                backdropClass: "invalid-browser-backdrop",
                panelClass: "invalid-browser-panel",
                disableClose: true
              });
              return false;
            }
          }


        }
      });
    }
  }

  setToStorage(key: string, value: any): void {
    this.electronStore.set(key, value);
  }

  getFromStorage(key: string): any {
    return this.electronStore.get(key);
  }

  deleteFromStorage(key: string): void {
    this.electronStore.delete(key);
  }

  clearStorage(): void {
    this.electronStore.clear();
  }

  get isElectron(): boolean {
    return environment.isElectron;
  }

  get isWindows(): boolean {
    return window && window.process && window.process.platform === "win32";
  }

  get isMacOS(): boolean {
    return window && window.process && window.process.platform === "darwin";
  }

  get isLinux(): boolean {
    return window && window.process && window.process.platform === "linux";
  }

  get appVersion(): string {
    return this.app.getVersion();
  }

  setBadgeCountListener(): void {
    if (this.app.isReady()) {
      this.badgeCountListener();
    } else {
      this.app.on("ready", this.badgeCountListener.bind(this));
    }
  }

  badgeCountListener(): void {
    this.store
      .select(getTotalUnreadCount)
      .subscribe(count => this.setBadge(count));
  }

  setBadge(count: number): void {
    if (this.isWindows) {
      const badgeNumber = count ? count : null;
      this.ipcRenderer.sendSync("update-badge", badgeNumber);
    } else {
      this.app.setBadgeCount(count);
    }

    if (this.isMacOS && count) {
      this.logger.info("[ElectronService][setBadge]", count, this.isGlobalMuteEnabled, this.notifyOption);
    }
  }

  openExternalUrl(url: string): void {
    this.shell.openExternal(url);
  }

  showApp(): void {
    this.win.show();
  }

  md5(str: string): string {
    return this.blueimpMd5(str);
  }

  hasScreenCapturePermission() {
    const meiaAccessStatus = window.electron.systemPreferences.getMediaAccessStatus("screen");
    this.logger.info("[ElectronService][hasScreenCapturePermission]", meiaAccessStatus);

    return meiaAccessStatus === "granted";
  }

  setupScreenSharingPicker(callback: any) {
    this.logger.info("[ElectronService][setupScreenSharingPicker] window.JitsiMeetScreenObtainer", window.JitsiMeetScreenObtainer);

    window.JitsiMeetScreenObtainer = {
      openDesktopPicker: (options, onSuccess, onFailure) => {
        this.logger.info("[ElectronService][setupScreenSharingPicker] getSources");
        this.desktopCapturer.getSources({
          types: ["screen", "window"],
          thumbnailSize: {
            height: 1280,
            width: 1920
          }
        }).then((sources) => {
          this.logger.info("[ElectronService][setupScreenSharingPicker] sources", sources);

          const pDialog = this._openSelectScreensDialog(sources);
          pDialog.afterClosed().subscribe((sourceId) => {
              this.logger.info("[ElectronService][setupScreenSharingPicker] sourceId", sourceId);
              if (sourceId) {
                // only set ConferenceShareScreen ins redux when we actually are streaming a source - otherwise we just keep the current state
                this.store.dispatch(new ConferenceShareScreen());
                onSuccess(sourceId, "desktop");
              } else {
                this.logger.info("[ElectronService][setupScreenSharingPicker] no sourceId - nothing to do");
              }
            });
          if (typeof callback === "function") {
            callback();
          }
        }).catch((error) => {
          // toDo: review with macOS re permissions on fresh install
          this.logger.error("[ElectronService][setupScreenSharingPicker] error", error);
          this.logger.sentryErrorLog("[ElectronService][setupScreenSharingPicker] error", error);
          this.store.dispatch(new SetScreenSharingRequestStatus(false));
          this.store.dispatch(new SetScreenSharingStarted());
          onFailure(error);
        });
      }
    };
  }

  private _openSelectScreensDialog(sources) {
    return this.dialog.open(ElectronScreenSharingComponent, {
      data: sources,
      backdropClass: "vnctalk-form-backdrop",
      panelClass: "vnctalk-form-panel",
      disableClose: true,
      autoFocus: true,
      width: "480px",
      height: "320px"
    });
  }

  fileDownloader(url: string): Observable<any> {
    const response = new Subject();
    if (!!this.isDownloading[url]) {
      response.error("this file is already being downloaded");
      return response.asObservable().pipe(take(1));
    } else {
      this.isDownloading[url] = true;
      setTimeout(() => {
        delete this.isDownloading[url];
      }, 1000);
    }
    this.downloadManager.download({
      url: url, override: true, headers: [{
        name: "Authorization",
        value: localStorage.getItem("token")
      }, {
        name: "User-Agent",
        value: "Mozilla/5.0 (Electron) AppleWebKit/537.36 (KHTML, like Gecko) vnc-uxf-portal/0.0.1-54 Electron/5.0.0 Safari/537.3"
      }]
    }, (error, info) => {
      this._translate.get("DOWNLOADING_FILES").pipe(take(1)).subscribe((text: string) => {
        this.channelSnackbarService.openSnackBar(text, "loader");
      });
      if (error) {
        this.logger.info("electrondownload error: ", error);
        response.error(error);
        return;
      }
      this.logger.info("electrondownload info: ", info);
      response.next(info);
    }, (newFileName, options) => {
      this.logger.info("======electrondownload======", newFileName, options);
      this._translate.get("FILES_DOWNLOAD_SUCCESSFULLY").pipe(take(1)).subscribe((text: string) => {
        this.channelSnackbarService.openSnackBar(text, "checkmark");
      });
    });
    return response.asObservable().pipe(take(1));
  }

  restartApp() {
    this.app.relaunch();
    this.app.exit(0);
  }

  static downloadFile(url: string, fileName?: string) {
    let element = document.createElement("a");

    element.setAttribute("href", url);
    element.setAttribute("download", fileName);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    element = null;
  }
}
