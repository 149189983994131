
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { CommonUtil } from "app/talk/utils/common.util";
import { BehaviorSubject, filter, Observable, Subject, take } from "rxjs";
import { normalizeCommonJSImport } from "app/talk/utils/normalize-common-js-import";
import { LoggerService } from "../services/logger.service";

export interface FileInfo {
  id?: string;
  name: string;
  path: string;
  sharedName?: string;
  type: string;
  fileType?: string;
  fileId?: any;
  action?: any;
  resourceType: any;
  quotaAvailableBytes?: string;
  quotaUsedBytes?: string;
  lastModified?: number;
  sharedTime?: number;
  contentType: string;
  contentLength?: string;
  expiration?: string;
  etag?: string;
  size?: string;
  sharedUrl?: string;
  shareTypes?: string;
  permissions?: string;
  ownerDisplayName?: string;
  shareWithDisplayName?: string;
  favorite?: string;
  starred?: boolean;
  commentsUnread?: string;
  lockdiscovery?: string;
  tusSupport?: boolean;
  tags?: any;
  url?: string;
  urlV2?: string;
}

@Injectable()
export class OwnCloudService {
  OwnCloudLib: any;

  oc: any;

  worker: Worker;

  owncloudURL: any;
  username: string;
  password: string;

  ownCloudProxyURL: any;
  currentUser: any;

  static fileExtensionMap: any = {
    Image: ["png", "jpg", "jpeg", "gif", "bmp"],
    Pdf: ["pdf"],
    Excel: ["xls", "xlsx", "xlsm", "xltx", "xltm"],
    Word: ["doc", "docx", "dot", "dotm", "dot", "dotx", "docm", "docb", "wbk"],
    Powerpoint: ["ppt", "pptx", "pptm"],
    Sound: ["mp3", "aac", "wma"],
    Video: ["mp4", "webm", "vob", "ogg", "gif", "avi", "mkv", "flv", "mov", "wmv", "m4v", "3gp"],
    Text: ["txt", "json"],
    TrueType: ["ttf"],
    PostScript: ["eps", "ps"],
    Autocad: ["cad"],
    Vector: ["svg"],
    TaggedImage: ["tiff"],
    Photoshop: ["psd"],
    Illustrator: ["ai"],
    XML: ["xml", "xps"],
    ApplePages: ["pages"],
    Markup: ["css", "html", "php", "c", "cpp", "h", "hpp", "js", "py", "java", "ts"],
    Archive: ["iso", "zip", "7z", "rar", "tar", "gz"]
  };
  properties = ["{DAV:}getlastmodified",
    "{DAV:}getetag",
    "{DAV:}getcontenttype",
    "{DAV:}resourcetype",
    "{http://owncloud.org/ns}fileid",
    "{http://owncloud.org/ns}permissions",
    "{http://owncloud.org/ns}size",
    "{DAV:}getcontentlength",
    "{http://owncloud.org/ns}tags",
    "{http://owncloud.org/ns}favorite",
    "{DAV:}lockdiscovery",
    "{http://owncloud.org/ns}comments-unread",
    "{http://owncloud.org/ns}owner-display-name",
    "{http://owncloud.org/ns}share-types"];

  private isLoggedIn$ = new BehaviorSubject<boolean>(false);
  capabilitie: any;
  capabilities: any;
  config: any;
  reload$ = new Subject<boolean>();
  tags$ = new BehaviorSubject<any[]>([]);
  filesize: any;
  constructor(private logger: LoggerService) {
    this.logger.info("OwnCloudService");
    this.isLoggedIn$.asObservable().pipe(filter(v => !!v)).subscribe(() => {
      this.getCurrentUser();
      this.getCapabilities();
      this.getTags();
      this.sharedByLink();
      this.sharedWithOthers();
      this.sharedWithMe();
    });
  }

  getAllTags() {
    return this.tags$.asObservable();
  }

  isLoggedIn() {
    return this.isLoggedIn$.asObservable();
  }

  async getCurrentUser() {
    const currentUser = await this.oc.getCurrentUser();
    this.currentUser = currentUser;
  }

  async getCapabilities() {
    const capabilities = await this.oc.getCapabilities();
    this.config = capabilities;
    this.logger.info("[OwnCloudService] getCapabilities", capabilities);
  }

  async getFileSize(size, lang) {
    try {
      if (!this.filesize) {
        this.filesize = await normalizeCommonJSImport(import("filesize"));
      }
      return this.filesize(size, { locale: lang });
    } catch (error) {
      return "";
    }
  }

  getFileType(filename: string) {
    const fileExt = this.getExtensionType(filename);
    for (const type in OwnCloudService.fileExtensionMap) {
      if (OwnCloudService.fileExtensionMap[type].filter((ext: any) => ext === fileExt.trim()).length) {
        return type;
      }
    }
    return "File";
  }

  getExtensionType(name: string): string {
    let ext: any = name.split(".");
    ext = ext[ext.length - 1];
    return ext;
  }

  initCredentials(owncloudURL: string, username: string, password: string) {
    this.logger.info("[OwnCloudService][initCredentials]");

    if (owncloudURL.lastIndexOf("/") < owncloudURL.length - 1) {
      owncloudURL = owncloudURL + "/";
    }
    this.owncloudURL = owncloudURL;
    this.username = username;
    this.password = password;
  }

  private loadLib(): Observable<any> {
    const subject = new BehaviorSubject<any>(undefined);

    normalizeCommonJSImport(import("owncloud-sdk")).then(owncloud => {
      this.logger.info("[OwnCloudService] owncloudLib", owncloud);
      this.OwnCloudLib = owncloud;
      subject.next(owncloud);
    });

    return subject.asObservable().pipe(filter(v => v !== undefined), take(1));
  }

  public loginOC(): Observable<any> {
    this.logger.info("[OwnCloudService][loginOC] isLoggedIn", this.isLoggedIn$.value);

    const subject = new BehaviorSubject<any>(undefined);

    if (this.isLoggedIn$.value) {
      subject.next(true);
    } else {
      this._loginOC().then(() => {
        subject.next(true);
      }).catch(error => {
        subject.error(error);
      });
    }

    return subject.asObservable().pipe(filter(v => v !== undefined), take(1));
  }

  private loginOCPromise: any;
  private _loginOC(): Promise<any> {
    if (this.loginOCPromise) {
      return this.loginOCPromise;
    }

    this.loginOCPromise = new Promise((resolve, reject) => {
      this.loadLib().subscribe(() => {
        this.oc = new this.OwnCloudLib({
          baseUrl: this.owncloudURL,
          auth: {
            basic: {
              username: this.username,
              password: this.password
            }
          }
        });

        this.oc.login().then((v: any) => {
          this.logger.info("[OwnCloudService][login] Ok", v, this.oc);
          this.isLoggedIn$.next(true);
          this.currentUser = v;

          resolve(true);

          this.loginOCPromise = null;
        }).catch((error: any) => {
          this.logger.sentryErrorLog("[OwnCloudService][login] Error", error);
          this.logger.error("[OwnCloudService][login] Error", error);
          reject(error);
        });
      });
    });

    return this.loginOCPromise;
  }

/**
 * Moves a remote file or directory
 * @param   {string} source     initial path of file/folder
 * @param   {string} target     path where to move file/folder finally
 * @returns {Promise.<status>}  boolean: whether the operation was successful
 * @returns {Promise.<error>}   string: error message, if any.
 */
  move(source: string, target: string) {
    if (!this.oc.helpers.getAuthorization()) {
      return Promise.reject("Please specify an authorization first.");
    }
    // const davClient = new Dav(this.getWebdavUrl(), this.getDavPath());
    const headers = this.oc.helpers.buildHeaders();
    headers.Destination = this._buildFullWebDAVURL(target);
    return this.oc.files.davClient.request("MOVE", this._buildFullWebDAVPath(source), headers).then(result => {
      if ([200, 201, 204, 207].indexOf(result.status) > -1) {
        return Promise.resolve(true);
      }
      return Promise.reject(this.oc.helpers.buildHttpErrorFromDavResponse(result.status, result.body));
    });
  }

/**
 * Deletes a remote file or directory
 * @param   {string}  path        path of the file/folder at OC instance
 * @returns {Promise.<status>}    boolean: whether the operation was successful
 * @returns {Promise.<error>}     string: error message, if any.
 */
  delete(path: string) {
    if (!this.oc.helpers.getAuthorization()) {
      return Promise.reject("Please specify an authorization first.");
    }
    return this.oc.files.davClient.request("DELETE", this._buildFullWebDAVPath(path), this.oc.helpers.buildHeaders()).then(result => {
      if ([200, 201, 204, 207].indexOf(result.status) > -1) {
        return Promise.resolve(true);
      } else {
        return Promise.reject(this.oc.helpers.buildHttpErrorFromDavResponse(result.status, result.body));
      }
    });
  }

  getTags() {
    this.loginOC().subscribe(() => {
      const xmlReq = `<?xml version="1.0"?>
      <d:propfind  xmlns:d="DAV:" xmlns:oc="http://owncloud.org/ns">
        <d:prop>
          <oc:id />
          <oc:display-name />
          <oc:user-visible />
          <oc:user-editable />
          <oc:user-assignable />
          <oc:editable-in-group />
          <oc:can-assign />
        </d:prop>
      </d:propfind>`;
      fetch( this.getDavPath() + "/systemtags", {method: "PROPFIND", body: xmlReq, headers: this.oc.helpers.buildHeaders()}).then(result => {
        if ([200, 201, 204, 207].indexOf(result.status) > -1) {
          result.text().then(v => {
            const NS = "{http://owncloud.org/ns}";
            const data = this.oc.files.davClient.parseMultiStatus(v).map(v => {
              const properties = v.propStat[0].properties;
              return {
                canAssign: properties[`${NS}can-assign`] === "true",
                editableInGroup: properties[`${NS}editable-in-group`] === "true",
                userAssignable: properties[`${NS}user-assignable`] === "true",
                userEditable: properties[`${NS}user-editable`] === "true",
                userVisible: properties[`${NS}user-visible`] === "true",
                displayName: properties[`${NS}display-name`],
                label: properties[`${NS}display-name`],
                value: properties[`${NS}id`],
                id: properties[`${NS}id`]
              };
            });
            this.tags$.next(data.filter(v => !!v.displayName));
          });
        }
      });
    });
  }

  sharedWithMe() {
    const subject = new Subject<FileInfo[]>();

    this.loginOC().subscribe(() => {
      fetch( `${this.owncloudURL}ocs/v1.php/apps/files_sharing/api/v1/shares?format=json&shared_with_me=true&state=all&share_types=0%2C1%2C6&include_tags=true`, {method: "GET", headers: this.oc.helpers.buildHeaders()}).then(result => {
        if ([200, 201, 204, 207].indexOf(result.status) > -1) {
          result.json().then(v => {
            const files = v.ocs.data.map(v => this.mapSharedFiles(v));
            subject.next(files);
            this.logger.info("[sharedWithMe]", files);
          });
        } else {
          subject.next([]);
        }
      });
    });

    return subject.asObservable();
  }

  sharedByLink() {
    const subject = new Subject<FileInfo[]>();

    this.loginOC().subscribe(() => {
      fetch( `${this.owncloudURL}ocs/v1.php/apps/files_sharing/api/v1/shares?format=json&share_types=3&include_tags=true`, {method: "GET", headers: this.oc.helpers.buildHeaders()}).then(result => {
        if ([200, 201, 204, 207].indexOf(result.status) > -1) {
          result.json().then(v => {
            const files = v.ocs.data.map(v => this.mapSharedFiles(v));
            subject.next(files);
            this.logger.info("[sharedByLink]", files);
          });
        } else {
          subject.next([]);
        }
      });
    });

    return subject.asObservable();
  }

  getFilesSharing(path) {
    const subject = new Subject<FileInfo[]>();

    this.loginOC().subscribe(() => {
      fetch( `${this.owncloudURL}ocs/v1.php/apps/files_sharing/api/v1/shares?format=json&path=${path}&reshares=true`, {method: "GET", headers: this.oc.helpers.buildHeaders()}).then(result => {
        if ([200, 201, 204, 207].indexOf(result.status) > -1) {
          result.json().then(v => {
            const files = v.ocs.data.filter(v => !!v.name).map(v => this.mapSharedFiles(v));
            subject.next(files);
            this.logger.info("[getFilesSharing]", files);
          });
        } else {
          subject.next([]);
        }
      });
    });

    return subject.asObservable();
  }

  deleteSharedFile(shareId) {
    const subject = new Subject<boolean>();

    this.loginOC().subscribe(() => {
      fetch( `${this.owncloudURL}ocs/v1.php/apps/files_sharing/api/v1/shares/${shareId}?format=json`, {method: "DELETE", headers: this.oc.helpers.buildHeaders()}).then(result => {
        if ([200, 201, 204, 207].indexOf(result.status) > -1) {
          result.json().then(() => {
            subject.next(true);
          });
        } else {
          subject.next(false);
        }
      });
    });

    return subject.asObservable();
  }

  updateSharedFile(shareId, body) {
    const subject = new Subject<any>();

    this.loginOC().subscribe(() => {
      fetch( `${this.owncloudURL}ocs/v1.php/apps/files_sharing/api/v1/shares/${shareId}?format=json`,
      {method: "PUT", headers: {...this.oc.helpers.buildHeaders(), "Content-type": "application/json; charset=UTF-8"}, body: JSON.stringify(body)}).then(result => {
        if ([200, 201, 204, 207].indexOf(result.status) > -1) {
          result.json().then(v => {
            subject.next(this.mapSharedFiles(v.ocs.data));
          });
        } else {
          subject.next(null);
        }
      });
    });

    return subject.asObservable();
  }

  sharedWithOthers() {
    const subject = new Subject<FileInfo[]>();

    this.loginOC().subscribe(() => {
      fetch( `${this.owncloudURL}ocs/v1.php/apps/files_sharing/api/v1/shares?format=json&include_tags=true`, {method: "GET", headers: this.oc.helpers.buildHeaders()}).then(result => {
        if ([200, 201, 204, 207].indexOf(result.status) > -1) {
          result.json().then(v => {
            const files = v.ocs.data.map(v => this.mapSharedFiles(v));
            subject.next(files);
            this.logger.info("[sharedWithOthers]", files);
          });
        } else {
          subject.next([]);
        }
      });
    });

    return subject.asObservable();
  }

  getOfficeUrl(fileId: string) {
    const subject = new Subject<string>();

    this.loginOC().subscribe(() => {
      fetch( `${this.owncloudURL}ocs/v2.php/apps/onlyoffice/api/v1/config/${fileId}`, {method: "GET", headers: this.oc.helpers.buildHeaders()}).then(result => {
        if ([200, 201, 204, 207].indexOf(result.status) > -1) {
          result.json().then(v => {
            const url = (!!v && !!v.document && !!v.document.url) ? v.document.url : "";
            subject.next(url);
            this.logger.info("[getOfficeUrl]", url);
          });
        } else {
          subject.next("");
        }
      });
    });

    return subject.asObservable();
  }

  private _normalizePath(path) {
    if (!path) {
      path = "";
    }

    if (path.length === 0) {
      return "/";
    }

    if (path[0] !== "/") {
      path = "/" + path;
    }

    return path;
  }

  private _encodeUri(path) {
    path = this._normalizePath(path);
    path = encodeURIComponent(path);
    return path.split("%2F").join("/");
  }

  private _buildFullWebDAVPath(path) {
    return this._encodeUri(path);
  }

  _buildFullWebDAVPathV2(path) {
    return this._encodeUri(path);
  }

  _buildFullWebDAVURL(path) {
    return this.getWebdavUrl() + this._encodeUri(path);
  }

  private getWebdavUrl() {
    return this.owncloudURL + "remote.php/webdav";
  }

  private getDavPath() {
    return this.owncloudURL + "remote.php/dav";
  }

  _buildFullWebDAVURLV2(path) {
    return this.getDavPath() + this._encodeUri(path);
  }

  listAllFiles(path, depth: string = "1", properties = this.properties): Observable<FileInfo[]> {
    const subject = new Subject<FileInfo[]>();

    this.loginOC().subscribe(() => {
      this.oc.files.list(path, depth, properties).then((files: any[]) => {
        this.logger.info("[listAllFiles]", files);
        try {
          subject.next(files.map(v => this.mapFiles(v)));
        } catch (error) {
          this.logger.info("[listAllFiles] error", error);
          subject.next([]);
        }

      }).catch(error => {
        subject.error(error);
        this.logger.info(error);
      });
    });

    return subject.asObservable();
  }

  getFilesByTags(tags, properties = this.properties): Observable<FileInfo[]> {
    const subject = new Subject<FileInfo[]>();

    this.loginOC().subscribe(() => {
      this.oc.files.getFilesByTags(tags, properties).then((files: any[]) => {
        this.logger.info("[getFilesByTags]", files);
        try {
          subject.next(files.map(v => this.mapFiles(v)));
        } catch (error) {
          this.logger.info("[getFilesByTags] error", error);
          subject.next([]);
        }

      }).catch(error => {
        subject.error(error);
        this.logger.info(error);
      });
    });

    return subject.asObservable();
  }

  getFavoriteFiles(properties = this.properties): Observable<FileInfo[]> {
    const subject = new Subject<FileInfo[]>();

    this.loginOC().subscribe(() => {
      this.oc.files.getFavoriteFiles(properties).then((files: any[]) => {
        this.logger.info("[getFavoriteFiles]", files);
        try {
          subject.next(files.map(v => this.mapFiles(v)));
        } catch (error) {
          this.logger.info("[getFavoriteFiles] error", error);
          subject.next([]);
        }

      }).catch(error => {
        subject.error(error);
        this.logger.info(error);
      });
    });

    return subject.asObservable();
  }

  search(pattern, limit, properties = this.properties): Observable<FileInfo[]> {
    const subject = new Subject<FileInfo[]>();

    this.loginOC().subscribe(() => {
      this.oc.files.search(pattern, limit, properties).then((files: any[]) => {
        this.logger.info("[search]", files);
        try {
          subject.next(files.map(v => this.mapFiles(v)));
        } catch (error) {
          this.logger.info("[search] error", error);
          subject.next([]);
        }

      }).catch(error => {
        subject.error(error);
        this.logger.info(error);
      });
    });

    return subject.asObservable();
  }

  fileInfo(path, properties = this.properties): Observable<FileInfo> {
    const subject = new Subject<FileInfo>();

    this.loginOC().subscribe(() => {
      this.oc.files.fileInfo(path, "0", properties).then((v: any) => {
        this.logger.info("[fileInfo]", v);
        try {
          subject.next(this.mapFiles(v));
        } catch (error) {
          this.logger.info("[fileInfo] error", error);
          subject.next(null);
        }
      }).catch(error => {
        subject.error(error);
        this.logger.info(error);
      });
    });

    return subject.asObservable();
  }


  getThumbnail(file) {
    this.logger.info("[OwncloudService][getThumbnail]", file);
    return this.oc.files.getFileUrlV2(file.path) + "?x=32&y=32&forceIcon=0&preview=1";
  }

  mapFiles(f: any) {
    this.logger.info("[OwncloudService][mapFiles]");

    let name = f.name;
    if (name && name.lastIndexOf("/") !== -1) {
      name = name.slice(name.lastIndexOf("/") + 1, name.length);
    }
    return {
      name: name,
      path: f.name,
      urlV2: this.oc.files.getFileUrlV2(f.name),
      url: this.oc.files.getFileUrl(f.name),
      type: f.type,
      pathHash: CommonUtil.md5(f.name),
      tusSupport: f.tusSupport,
      fileId: f.getFileId(),
      fileType: this.getFileType(name),
      resourceType: f.fileInfo["{DAV:}resourcetype"],
      quotaAvailableBytes: f.fileInfo["{DAV:}quota-available-bytes"],
      quotaUsedBytes: f.fileInfo["{DAV:}quota-used-bytes"],
      lastModified: new Date(f.fileInfo["{DAV:}getlastmodified"]).getTime(),
      contentType: f.fileInfo["{DAV:}getcontenttype"],
      contentLength: f.fileInfo["{DAV:}getcontentlength"],
      etag: f.fileInfo["{DAV:}getetag"],
      size: f.fileInfo["{http://owncloud.org/ns}size"],
      shareTypes: f.fileInfo["{http://owncloud.org/ns}share-types"],
      permissions: f.fileInfo["{http://owncloud.org/ns}permissions"],
      ownerDisplayName: f.fileInfo["{http://owncloud.org/ns}owner-display-name"],
      favorite: f.fileInfo["{http://owncloud.org/ns}favorite"],
      starred: f.fileInfo["{http://owncloud.org/ns}favorite"] !== "0",
      commentsUnread: f.fileInfo["{http://owncloud.org/ns}comments-unread"],
      lockdiscovery: f.fileInfo["{DAV:}lockdiscovery"],
      tags: f.fileInfo["{http://owncloud.org/ns}tags"]
    } as FileInfo;
  }

  mapSharedFiles(f: any) {
    this.logger.info("[OwncloudService][mapSharedFiles]");

    let name = f.path;
    if (name && name.lastIndexOf("/") !== -1) {
      name = name.slice(name.lastIndexOf("/") + 1, name.length);
    }
    let name2 = f.file_target;
    if (name2 && name2.lastIndexOf("/") !== -1) {
      name2 = name2.slice(name2.lastIndexOf("/") + 1, name2.length);
    }
    const isStarred = f.tags && f.tags.indexOf("_$!<Favorite>!$_") !== -1;
    return {
      name: name,
      sharedName: f.name || name2,
      path: f.path,
      urlV2: this.oc.files.getFileUrlV2(f.path),
      url: this.oc.files.getFileUrl(f.path),
      sharedUrl: f.url,
      type: f.item_type === "folder" ? "dir" : "file",
      pathHash: CommonUtil.md5(f.path),
      tusSupport: f.tusSupport,
      fileId: f.item_source,
      id: f.id,
      fileType: this.getFileType(name),
      resourceType: f.mimetype,
      sharedTime: f.stime * 1000,
      contentType: f.mimetype,
      shareTypes: f.share_type,
      permissions: f.permissions,
      ownerDisplayName: f.displayname_owner,
      expiration: f.expiration,
      shareWithDisplayName: f.share_with_displayname,
      favorite: isStarred ? "1" : "0",
      starred: isStarred,
      commentsUnread: "",
      lockdiscovery: "",
      tags: f.tags
    } as FileInfo;
  }

  toggleFavorite(path: string, starred: boolean) {
    return this.oc.files.favorite(path, starred);
  }
}
