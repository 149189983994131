
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { AfterViewInit, Component , ElementRef , EventEmitter, HostBinding , Input, Output } from "@angular/core";


@Component({
  selector: "vp-emoji" ,
  templateUrl: "./emoji.html"
})
export class VNCEmojiComponent implements AfterViewInit {
  @Input() chatWindow: ElementRef;
  @Input() inputSelector: string;
  searchInput = "";
  @HostBinding("class") emojiPopup = "wdt-emoji-popup";
  @Output() closeEmojiPopup = new EventEmitter<string>();
  showCloseButton: boolean = false;
  constructor() {
  }

  clearSearch() {
    wdtEmojiBundle.searchInput.value = "";
    wdtEmojiBundle.search("");
    this.searchInput = "";
  }

  async ngAfterViewInit() {
      setTimeout(() => {
        if (!!this.inputSelector && !!this.chatWindow && this.chatWindow.nativeElement) {
          wdtEmojiBundle.init(this.inputSelector , this.chatWindow);
          if (wdtEmojiBundle && wdtEmojiBundle.emoji) {
            wdtEmojiBundle.emoji.replace_mode = "unified";
            wdtEmojiBundle.emoji.allow_native = true;
          }
        }
        setTimeout(() => {
          if (document.querySelector(".wdt-emoji-picker") !== null) {
            document.querySelector(".wdt-emoji-picker").setAttribute("title" , "Emoji");
            document.querySelector(".wdt-emoji-picker").innerHTML = "mood";
            document.querySelector(".wdt-emoji-picker").classList.add("material-icons");
          }
        } , 2000);
        this.showCloseButton = true;
      } , 2000);
  }

  closeEmojiDialog() {
    this.clearSearch();
    if (!!document.querySelector(".wdt-emoji-picker")) {
      (<HTMLElement>document.querySelector(".wdt-emoji-picker")).click();
    }
  }

  close() {
    this.showCloseButton = false;
    this.closeEmojiPopup.emit("close");
  }

}
