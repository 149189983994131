/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import {
  LastActivityComponent,
  VNCEmojiComponent,
  VNCProfileComponent
} from "./components/index";
import { GetFullNamePipe } from "./pipes/get-fullname.pipe";
import { ScrollListDirective } from "./directives/scroll-list.directive";
import { MessageMinichatComponent } from "../../shared/components/message-minichat/message-minichat.component";
import { VNCAuthenticationComponent } from "../../vnc-authentication/vnc-authentication.component";
import { VNCShareLocationComponent } from "./components/share-location/share-location.component";
import { ProcessTextPipe } from "./pipes/process-text.pipe";
import { SafeUrlPipe } from "./pipes/safe-url.pipe";
import { GetGroupNamePipe } from "./pipes/get-room-name.pipe";
import { VNCTalkNotificationsComponent } from "../notifications/notifications.component";
import { VNCTalkNotificationComponent } from "../notifications/notification.component";
import { TalkAddContactsComponent } from "../contacts/index";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { AgmCoreModule } from "@agm/core";
import { VNCLongPressDirective } from "./directives/long-press.directive";
import {
  BlockUserDialogComponent,
  DeleteConversationDialogComponent,
  ExitGroupDialogComponent
} from "./components/dialogs/index";
import { VNCBlockedContactsComponent } from "../contacts/blocked-contacts/blocked-contacts.component";
import { VNCTalkContactListComponent } from "./components/contact-list/contact-list.component";
import { PreviewImageDialogComponent } from "./components/dialogs/preview-image/preview-image.component";
import { MediaOptionsDialogComponent } from "./components/dialogs/media-options/media-options.component";
import { VNCNewConversationComponent } from "../conversation/new-conversation-mobile/new-conversation-mobile.component";
import { UnderDevelopmentDialogComponent } from "./components/dialogs/under-development/under-development.component";
import { OMEMOHitMaxLimitDialogComponent } from "./components/dialogs/omemo-hit-max-limit/omemo-hit-max-limit.component";
import { TFAReminderDialogComponent } from "./components/dialogs/tfa-reminder/tfa-reminder.component";
import { InstallAppDialogComponent } from "./components/dialogs/install-app/install-app.component";
import { VNCTalkContactListAutoCompleteComponent } from "./components/contact-list-autocomplete/contact-list-autocomplete.component";
import { EmailSelectedMessageComponent } from "./components/email-selected-message/email-selected-message.component";
import { MediaPreviewDialogComponent } from "./components/dialogs/media-preview/media-preview.component";
import { DeleteMediaConfirmationDialogComponent } from "./components/dialogs/delete-media-confirmation/delete-media-confirmation.component";
import { SendEmailConfirmationComponent } from "./components/dialogs/send-email-confirmation/send-email-confirmation.component";
import { LogoutConfirmationComponent } from "./components/dialogs/logout-confirmation/logout-confirmation.component";
import { VNCNewBroadcastComponent } from "../conversation/new-broadcast";
import { VNCOAuthLoginComponent } from "app/oauth-login/oauth-login.component";
import { MentionModule } from "./directives/mention";
import { UserInfoDialogComponent } from "./components/dialogs/user-info/user-info.component";
import { PinchZoomModule } from "ngx-pinch-zoom";
import { VNCTalkGroupChatsComponent } from "./components/group-chats/group-chats.component";
import { CommonNotificationComponent } from "./components/dialogs/common-notification/common-notification";
import { VNCTalkGroupInformationComponent } from "./components/groupchat-information/groupchat-information.component";
import { RescanNotificationComponent } from "./components/rescan-notification/rescan-notification";
import { WelcomeNotificationComponent } from "./components/welcome-notification/welcome-notification";
import { MatListModule } from "@angular/material/list";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { InvalidBrowserDialogComponent } from "./components/dialogs/invalid-browser/invalid-browser.component";
import { PromptScreensharePermissionsComponent } from "./components/dialogs/prompt-screenshare-permissions/prompt-screenshare-permissions.component";
import { PromptMicPermissionsComponent } from "./components/dialogs/prompt-mic-permissions/prompt-mic-permissions.component";
import { PromptCamPermissionsComponent } from "./components/dialogs/prompt-cam-permissions/prompt-cam-permissions.component";
import { TFASettingsComponent } from "app/shared/components/tfa-settings/tfa-settings.component";
import { DeleteConferenceDialogComponent } from "./components/dialogs/delete-conference/delete-conference.component";
import { MatGridListModule } from "@angular/material/grid-list";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatMenuModule } from "@angular/material/menu";
import { RFCDailogComponent } from "app/shared/components/rfc-dialog/rfc-dialog.component";
import { InviteUserDailogComponent } from "app/shared/components/invite-user-dialog/invite-user-dialog.component";

import { ConferenceDialogComponent } from "./components/dialogs/conference-confirmation/conference-confirmation.component";
import { VNCVideoMeetingSettings } from "./components/dialogs/video-metting-setting/video-meeting-settings.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { UpgradeAccountDailogComponent } from "app/shared/components/upgrade-account-dialog/upgrade-account-dialog.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { StartConferenceComponent } from "./components/start-conference/start-conference.component";
import { SelectParticipantsComponent } from "./components/select-participants/select-participants.component";
import { UpdateAvatarComponent } from "./components/update-avatar/update-avatar.component";
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from "@angular-material-components/datetime-picker";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { NewWhiteboardDialogComponent } from "./components/dialogs/new-whiteboard/new-whiteboard.component";
import { MatTabsModule } from "@angular/material/tabs";
import { MatNativeDateModule } from "@angular/material/core";
import { MatTableModule } from "@angular/material/table";
import { CallDropComponent } from "../call-drop";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { SendMailAttachmentsDialogComponent } from "./components/dialogs/send-mail-attachments/send-mail-attachments.component";
import { StartChatComponent } from "./components/dialogs/start-chat/start-chat.component";
import { ScheduledConferencesComponent } from "./components/dialogs/scheduled-conferences/scheduled-conferences.component";
import { ProfileInfoDialogComponent } from "app/shared/components/profile-info-dialog/profile-info-dialog.component";
import { SafeHtmlPipe, TimeAgoPipe } from "app/shared/pipes";
import { GroupChatInfoComponent } from "./components/groupchat-info/groupchat-info.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { ImageCropperModule } from "ngx-image-cropper";
import { CircularChatComponent } from "./components/circular-chat/circular-chat.component";
import { SelectChatComponent } from "./components/select-chat/select-chat.component";
import { GlobalSharedModule } from "app/global-shared.module";
import { InfiniteScrollDirective } from "./directives/infinite-scroll.directive";
import { VncLibraryModule } from "vnc-library";
import { VNCLobbyScreenComponent } from "./components/dialogs/lobby-screen";
import { NewParticipantsDialogComponent } from "./components/new-participants-dialog/new-participants-dialog.component";
import { FloatingVideoComponent } from "./components/floating-video/floating-video.component";
import { InputDialogComponent } from "./components/dialogs/input-dialog/input-dialog.component";
import { CommonDialogComponent } from "./components/dialogs/common-dialog/common-dialog.component";
import { OwncloudPublicDialogComponent } from "./components/dialogs/owncloud-public/owncloud-public.component";
import { OwncloudShareDialogComponent } from "./components/dialogs/owncloud-share/owncloud-share.component";
import { OwncloudUploadDialogComponent } from "./components/dialogs/owncloud-upload/owncloud-upload.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";

import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime-ex";
import { OwncloudDetailDialogComponent } from "./components/dialogs/owncloud-detail/owncloud-detail.component";
import { MCBDialogComponent } from "./components/dialogs/mcb-list-mobile/mcb-list-mobile.component";
import { MeetingSettingsComponent } from "app/shared/components/meeting-settings/meeting-settings.component";
import { VNCBroadcastAudienceComponent } from "../conversation/broadcast-audience";
import { FilePreviewDialogComponent } from "./components/dialogs/file-preview-dialog/file-preview-dialog.component";
import { NgxOnlyOfficeModule } from "ngx-onlyoffice";
import { AddParticipantChatHistoryOptionDialogComponent } from "./components/dialogs/add-participant-chat-history-options-dialog/add-participant-chat-history-options-dialog.component";
import { SvgIconModule } from "app/shared/svg-icon/svg-icon.module";
import { VideoCallComponent } from "./components/video-call/video-call.component";
import { CallPreviewComponent } from "./components/video-call/call-preview/call-preview.component";
import {AddContactComponent} from "./components/dialogs/add-contact/add-contact.component";
import {ChangeContactInfoComponent} from "../../shared/components/change-contact-info/change-contact-info.component";
import {ContactExistsDialogComponent} from "../../shared/components/contact-exists/contact-exists.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { RedirectInterceptor } from "app/channels/redirect.interceptor";


/*
 * Shared module for components, services, directives and pipes
 * that are used only within vncmail (mail, contacts, tasks
 * and calendar)
 */
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatAutocompleteModule,
    MatInputModule,
    MatDialogModule,
    MentionModule,
    PinchZoomModule,
    MatInputModule,
    MatDialogModule,
    MatSelectModule,
    MatDialogModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatGridListModule,
    MatFormFieldModule,
    DragDropModule,
    MatMenuModule,
    ScrollingModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatTabsModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatNativeDateModule,
    MatTableModule,
    MatSlideToggleModule,
    ImageCropperModule,
    GlobalSharedModule,
    VncLibraryModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxOnlyOfficeModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBQEKwtB4BVCgGqwA8uH_Zr9IIeP13cjfg"
    }),
    SvgIconModule
  ],
  declarations: [
    DeleteConversationDialogComponent,
    LogoutConfirmationComponent,
    BlockUserDialogComponent,
    PreviewImageDialogComponent,
    VNCVideoMeetingSettings,
    GetFullNamePipe,
    ScrollListDirective,
    VNCLongPressDirective,
    MessageMinichatComponent,
    VNCAuthenticationComponent,
    VNCOAuthLoginComponent,
    VNCEmojiComponent,
    VNCNewConversationComponent,
    VNCBroadcastAudienceComponent,
    VNCNewBroadcastComponent,
    TalkAddContactsComponent,
    VNCTalkNotificationComponent,
    VNCTalkNotificationsComponent,
    VNCTalkContactListComponent,
    GetFullNamePipe,
    GetGroupNamePipe,
    SafeUrlPipe,
    TimeAgoPipe,
    ProcessTextPipe,
    VNCShareLocationComponent,
    VNCBlockedContactsComponent,
    ExitGroupDialogComponent,
    MediaOptionsDialogComponent,
    MediaPreviewDialogComponent,
    DeleteMediaConfirmationDialogComponent,
    UnderDevelopmentDialogComponent,
    InstallAppDialogComponent,
    VNCTalkContactListAutoCompleteComponent,
    EmailSelectedMessageComponent,
    SendEmailConfirmationComponent,
    LastActivityComponent,
    UserInfoDialogComponent,
    VNCTalkGroupChatsComponent,
    VNCTalkGroupInformationComponent,
    VNCProfileComponent,
    CommonNotificationComponent,
    WelcomeNotificationComponent,
    OMEMOHitMaxLimitDialogComponent,
    TFAReminderDialogComponent,
    RescanNotificationComponent,
    InvalidBrowserDialogComponent,
    PromptScreensharePermissionsComponent,
    PromptMicPermissionsComponent,
    PromptCamPermissionsComponent,
    DeleteConferenceDialogComponent,
    ConferenceDialogComponent,
    TFASettingsComponent,
    DeleteConferenceDialogComponent,
    RFCDailogComponent,
    InviteUserDailogComponent,
    UpgradeAccountDailogComponent,
    StartConferenceComponent,
    SelectParticipantsComponent,
    StartChatComponent,
    AddContactComponent,
    ChangeContactInfoComponent,
    ContactExistsDialogComponent,
    UpdateAvatarComponent,
    NewWhiteboardDialogComponent,
    CallDropComponent,
    SendMailAttachmentsDialogComponent,
    ScheduledConferencesComponent,
    GroupChatInfoComponent,
    ProfileInfoDialogComponent,
    SafeHtmlPipe,
    CircularChatComponent,
    SelectChatComponent,
    InfiniteScrollDirective,
    VNCLobbyScreenComponent,
    NewParticipantsDialogComponent,
    FloatingVideoComponent,
    InputDialogComponent,
    CommonDialogComponent,
    OwncloudShareDialogComponent,
    OwncloudPublicDialogComponent,
    OwncloudUploadDialogComponent,
    OwncloudDetailDialogComponent,
    MeetingSettingsComponent,
    MCBDialogComponent,
    FilePreviewDialogComponent,
    AddParticipantChatHistoryOptionDialogComponent,
    VideoCallComponent,
    CallPreviewComponent
  ],
  exports: [
    MatButtonModule,
    MatTabsModule,
    MatAutocompleteModule,
    DeleteConversationDialogComponent,
    LogoutConfirmationComponent,
    BlockUserDialogComponent,
    PreviewImageDialogComponent,
    GetFullNamePipe,
    ScrollListDirective,
    VNCLongPressDirective,
    MessageMinichatComponent,
    VNCVideoMeetingSettings,
    VNCAuthenticationComponent,
    VNCOAuthLoginComponent,
    VNCEmojiComponent,
    VNCNewConversationComponent,
    VNCNewBroadcastComponent,
    VNCBroadcastAudienceComponent,
    TalkAddContactsComponent,
    VNCTalkNotificationComponent,
    VNCTalkNotificationsComponent,
    VNCTalkContactListComponent,
    GetFullNamePipe,
    TimeAgoPipe,
    GetGroupNamePipe,
    SafeUrlPipe,
    ProcessTextPipe,
    VNCShareLocationComponent,
    VNCBlockedContactsComponent,
    ExitGroupDialogComponent,
    MediaOptionsDialogComponent,
    MediaPreviewDialogComponent,
    DeleteMediaConfirmationDialogComponent,
    UnderDevelopmentDialogComponent,
    InstallAppDialogComponent,
    VNCTalkContactListAutoCompleteComponent,
    EmailSelectedMessageComponent,
    SendEmailConfirmationComponent,
    LastActivityComponent,
    VNCTalkGroupChatsComponent,
    VNCProfileComponent,
    UserInfoDialogComponent,
    CommonNotificationComponent,
    VNCTalkGroupInformationComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    ScrollingModule,
    MatNativeDateModule,
    WelcomeNotificationComponent,
    RescanNotificationComponent,
    InvalidBrowserDialogComponent,
    PromptScreensharePermissionsComponent,
    PromptMicPermissionsComponent,
    PromptCamPermissionsComponent,
    DeleteConferenceDialogComponent,
    ConferenceDialogComponent,
    TFASettingsComponent,
    DeleteConferenceDialogComponent,
    RFCDailogComponent,
    InviteUserDailogComponent,
    UpgradeAccountDailogComponent,
    MatDatepickerModule,
    MatTableModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    MatProgressSpinnerModule,
    CallDropComponent,
    SendMailAttachmentsDialogComponent,
    ScheduledConferencesComponent,
    GroupChatInfoComponent,
    ProfileInfoDialogComponent,
    SafeHtmlPipe,
    CircularChatComponent,
    SelectChatComponent,
    InfiniteScrollDirective,
    VNCLobbyScreenComponent,
    NewParticipantsDialogComponent,
    FloatingVideoComponent,
    InputDialogComponent,
    CommonDialogComponent,
    OwncloudShareDialogComponent,
    OwncloudPublicDialogComponent,
    OwncloudUploadDialogComponent,
    OwncloudDetailDialogComponent,
    OwlDateTimeModule,
    FilePreviewDialogComponent,
    AddParticipantChatHistoryOptionDialogComponent,
    VideoCallComponent,
    CallPreviewComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: RedirectInterceptor, multi: true },
  ]
})
export class TalkSharedModule {

}
