import { Injectable } from "@angular/core";
import { ChannelRepository } from "app/channels/repository/channel.repository";
import { TalkRootState } from "../reducers";
import { Store } from "@ngrx/store";
import { getUserJID, getAppSettings } from "app/reducers";
import { NotificationStatus } from "app/channels/models/notification-status.model";
import { VNCTalkNotificationsService } from "../notifications";
import { ConstantsUtil } from "../utils/constants.util";
import { Broadcaster } from "../shared/providers";
import { ConfigService } from "app/config.service";
import { distinctUntilChanged, filter, take } from "rxjs/operators";
import { SocialRepository } from "../repositories/social.repository";
import { AllPostsInfoUpdate, MediaPostInfoUpdate } from "../actions/post";
import { LoggerService } from "app/shared/services/logger.service";
import { ChannelRootState } from "app/channels/store/reducers";
import { TopicInfoUpdate } from "app/channels/store/actions/topic";
import { ChannelService } from "app/channels/channel.service";

@Injectable()
export class MessageCableService {
  userJID: any;
  channelInvite: boolean;
  channelNewComment: boolean;
  channelNewTopic: boolean;
  channelReplyComment: boolean;
  constructor(
    private channelRepository: ChannelRepository,
    private store: Store<TalkRootState>,
    private channelRootStore: Store<ChannelRootState>,
    private broadcaster: Broadcaster,
    private notificationService: VNCTalkNotificationsService,
    private socialRepository: SocialRepository,
    private logger: LoggerService,
    private configService: ConfigService,
    private _channelService: ChannelService,
  ) {
    this.store.select(getUserJID).pipe(filter(jid => !!jid)).subscribe(jid => {
      this.userJID = jid;
    });
    this.store.select(getAppSettings)
      .pipe(distinctUntilChanged())
      .subscribe(options => {
        this.channelInvite = options.channelInvite;
        this.channelNewComment = options.channelNewComment;
        this.channelNewTopic = options.channelNewTopic;
        this.channelReplyComment = options.channelReplyComment;
        this.logger.info("[MessageCableService][getAppSettings] ::", this.channelInvite, this.channelNewComment, this.channelNewTopic, this.channelReplyComment);
      });
  }

  notify(message: any) {
    this.logger.info("[MessageCableService][notify] recived message::", message);
    this.handleNotification(message);
    /*this.showChromeNotification(message?.status, content);*/
  }

  handleNotification(notification: any) {
    this.logger.info("[MessageCableService][handleNotification]:::::::", notification);
    if (notification?.notification?.is_iom) {
      if (notification?.notification.notification_type != NotificationStatus.TOPIC_ARCHIVED && notification?.notification.notification_type != NotificationStatus.COMMENT_DELETED && notification?.notification.notification_type != NotificationStatus.TOPIC_DELETED) {
        notification = notification?.notification?.notification;
      }
      else
        notification = notification?.notification;
      if (notification?.data?.topic) {
        notification.data.topic.id = "iom-" + notification.data.topic.id;
        notification.data.topic.is_iom = true;
      }
      if (notification?.data?.topic_id)
        notification.data.topic_id = "iom-" + notification.data.topic_id;
      if (notification?.data?.comment) {
        notification.data.comment.topic_id = "iom-" + notification.data.comment.topic_id;
      }
    }
    switch (notification?.notification_type) {
      case NotificationStatus.CHANNEL_CREATED:
      case NotificationStatus.PRIVATE_CHANNEL_INVITE:
      case NotificationStatus.CHANNEL_ACCESS_GRANTED:
        if (notification?.data?.channel) {
          this.channelRepository.addChannelIntoStore(notification?.data?.channel);
          this.channelRepository.setChannelCategory(notification?.data?.channel);
          if ((this.userJID && this.userJID?.bare !== notification?.actor?.jid && notification?.data?.subscribed)) {
            this.addNotificationIntoCenter(notification);
            if (notification?.notification_type === "channel_created" && this.channelInvite && notification?.data?.subscribed) {
              this.pushNotification(notification);
            }
          }
        }
        break;
      case NotificationStatus.CHANNEL_UPDATED:
        if (notification?.data?.channel) {
          this.channelRepository.updateChannelIntoStore(notification?.data?.channel);
          this.channelRepository.channelAvatarFailedToLoadRemove(notification?.data?.channel?.id);
        }
        break;
      case NotificationStatus.CHANNEL_RESTORED:
        if (notification) {
          if (notification?.data?.channel_subscribed) {
            this._channelService.getChannelById(notification?.data?.channel_id).pipe(take(1)).subscribe((channel: any) => {
              this.channelRepository.updateChannelIntoStore(channel?.channel, true, true);
              this.channelRepository.channelAvatarFailedToLoadRemove(channel?.channel);
            });
          }
        }
        break;
      case NotificationStatus.CHANNEL_ARCHIVED:
      case NotificationStatus.CHANNEL_DELETED:
        if (notification?.data?.channel_id) {
          this.channelRepository.removeChannelIntoStore(notification?.data?.channel_id);
          this.channelRepository.redirectToTalkIfChannelRemoved(notification?.data?.channel_id);
          this.channelRepository.deleteAllChannelNotifications(notification?.data?.channel_id);
          if (notification?.notification_type === NotificationStatus.CHANNEL_DELETED) {
            this.channelRepository.removeAllTopicsIntoStore(notification?.data?.channel_id);
          }
          this.channelRepository.getUnreadNotificationCount();
        }
        break;
      case NotificationStatus.CHANNEL_RESTORED:
        if (notification) {
          if (notification?.data?.channel_subscribed) {
            this._channelService.getChannelById(notification?.data?.channel_id).pipe(take(1)).subscribe((channel: any) => {
              this.channelRepository.updateChannelIntoStore(channel?.channel);
              this.channelRepository.channelAvatarFailedToLoadRemove(channel?.channel);
            });
          }
        }
        break;
      case NotificationStatus.CHANNEL_UNARCHIVED:
        if (notification?.data?.channel) {
          this.channelRepository.addChannelIntoStore(notification?.data?.channel);
          this.channelRepository.setChannelCategory(notification?.data?.channel);
        }
        break;
      case NotificationStatus.TOPIC_CREATED:
      case NotificationStatus.PRIVATE_TOPIC_INVITE:
      case NotificationStatus.TOPIC_ACCESS_GRANTED:
        if (notification?.data?.topic) {
          if (notification?.data?.topic?.is_social) {
            return;
          }
          if ((this.userJID && this.userJID?.bare !== notification?.actor?.jid)) {
            this.channelRepository.updateChannelTopicCount(notification?.data?.topic?.channel_id, 1, 1);
            if (notification?.data?.topic?.channel_subscribed && notification?.data?.subscribed) {
              this.addNotificationIntoCenter(notification);
              if (notification?.notification_type === "topic_created" && this.channelNewTopic && notification?.data?.subscribed) {
                this.pushNotification(notification);
              }
            }
          } else {
            this.channelRepository.updateChannelCountForCreateTopic(notification?.data?.topic?.id);
          }
          this.channelRepository.addTopicIntoStore(notification?.data?.topic);
          const channel_id = notification?.data?.topic?.channel_id;
          this.channelRepository.getTopicsInfoByChannelId(channel_id).pipe(take(1)).subscribe((info) => {
            this.channelRootStore.dispatch(new TopicInfoUpdate({ channelId: channel_id, info: { ...info, totalCount: info?.totalCount + 1 } }));
          });
          if (notification?.data?.topic?.channel) {
            this.channelRepository.updateChannelIntoStore(notification?.data?.topic?.channel);
          }
        }
        break;
      case NotificationStatus.TOPIC_UPDATED:
        if (notification?.data?.topic) {
          this.channelRepository.updateTopicIntoStore(notification?.data?.topic);
        }
        if (notification?.data?.topic?.channel) {
          this.channelRepository.updateChannelIntoStore(notification?.data?.topic?.channel);
        }
        break;
      case NotificationStatus.TOPIC_ARCHIVED:
        if (notification?.data?.topic_id) {
          this.channelRepository.removeTopicIntoStoreAndRedirect(notification.data.topic_id);
          if ((this.userJID && this.userJID?.bare === notification?.data?.actor_jid)) {
            this.channelRepository.updateChannelCountForArchiveTopic(notification?.data?.topic_id, notification?.data?.channel_id, -1);
          } else {
            const changeInUnreadCount = notification?.data?.topic?.read ? 0 : -1;
            this.channelRepository.updateChannelTopicCount(notification?.data?.topic?.channel_id, -1, changeInUnreadCount);
          }
        }
        break;
      case NotificationStatus.TOPIC_READ:
      case NotificationStatus.TOPIC_UNREAD:
        const data = notification?.data;
        if (data) {
          const topicId = data?.topic_id;
          const read = data?.read;
          if (topicId) {
            this.channelRepository.updateTopicReadStatus(topicId, read);
          }

          const channelId = data?.channel_id;
          const unread_topics_count = data?.channel_unread_topics_count;

          if (channelId) {
            this.channelRepository.updateChannelUnreadTopicCount(channelId, unread_topics_count);
          }
        }
        break;
      case NotificationStatus.TOPIC_DELETED:
        if (notification?.data?.topic_id) {
          this.channelRepository.updateChannelCountForArchiveTopic(notification?.data?.topic_id, notification?.data?.channel_id, -1);
          this.channelRepository.removeTopicIntoStoreAndRedirect(notification?.data?.topic_id);
          this.channelRepository.deleteAllTopicNotifications(notification?.data?.topic_id);
          this.channelRepository.getUnreadNotificationCount();
        }
        break;
      case NotificationStatus.TOPIC_UNARCHIVED:
        if (notification?.data?.topic) {
          if ((this.userJID && this.userJID?.bare === notification?.actor?.jid)) {
            this.channelRepository.updateChannelCountForUnarchiveTopic(notification?.data?.topic_id, notification?.data?.channel_id, -1);
          } else {
            this.channelRepository.updateChannelTopicCount(notification?.data?.topic?.channel_id, 1, 1);
          }
          this.channelRepository.addTopicIntoStore(notification?.data?.topic);
        }
        break;
      case NotificationStatus.TOPIC_FILE_ADDED:
        if (notification?.data) {
          this.channelRepository.filesAddedInTopic([notification?.data]);
        }
        break;
      case NotificationStatus.TOPIC_FILE_REMOVED:
        if (notification?.data) {
          const fileId = notification?.data?.id;
          const topicId = notification?.data?.container?.id;
          if (!!fileId && topicId) {
            this.channelRepository.fileRemovedFromTopic(fileId, topicId);
          }
        }
        break;
      case NotificationStatus.TOPIC_MEMBER_ADDED:
        if (notification?.data) {
          const topicId = notification?.data?.topic_id;
          this.channelRepository.memberAddedInTopic(notification?.data, topicId);
        }
        break;
      case NotificationStatus.TOPIC_MEMBER_REMOVED:
        if (notification?.data) {
          const userId = notification?.data?.jid;
          const topicId = notification?.data?.topic_id;
          if (!!userId && topicId) {
            this.channelRepository.memberRemovedFromTopic(userId, topicId);
          }
        }
        break;
      case NotificationStatus.CHANNEL_MEMBER_ADDED:
        if (notification?.data) {
          const channelId = notification?.data?.channel_id;
          this.channelRepository.memberAddedInChannel(notification?.data, channelId);
          if ((this.userJID && this.userJID?.bare === notification?.data?.jid)) {
            setTimeout(() => {
              this.channelRepository.updatePermissions(channelId);
            }, 1000);
          }
        }
        break;
      case NotificationStatus.CHANNEL_MEMBER_REMOVED:
        if (notification?.data) {
          const userId = notification?.data?.jid;
          const channelId = notification?.data?.channel_id;
          if (!!userId && channelId) {
            this.channelRepository.memberRemovedFromChannel(userId, channelId);
          }
        }
        break;
      case NotificationStatus.CHANNEL_MEMBER_UPDATED:
        if (notification?.data) {
          const channelId = notification?.data?.channel_id;
          this.channelRepository.memberUpdatedInChannel(notification?.data, channelId);
          if ((this.userJID && this.userJID?.bare === notification?.data?.jid)) {
            setTimeout(() => {
              this.channelRepository.updatePermissions(channelId);
            }, 1000);
          }
        }
        break;
      case NotificationStatus.BULK_READ_NOTIFICATION:
        notification?.data?.map((noti: any) => {
          if (!!noti?.channel) {
            this.channelRepository.updateChannelUnreadTopicCount(noti?.channel?.id, 0);
          }
        });
        break;
      case NotificationStatus.COMMENT_CREATED:
      case NotificationStatus.COMMENT_CREATED_REPLY:
      case NotificationStatus.COMMENT_CREATED_MENTION:
        if (notification?.data?.comment) {
          if (notification?.data?.comment?.topic_is_social) {
            return;
          }
          if ((this.userJID && this.userJID?.bare !== notification?.actor?.jid)) {
            this.channelRepository.updateTopicCommentCount(notification?.data?.comment?.topic_id, 1, 1);
            if (notification?.data?.comment?.topic_subscribed) {
              this.addNotificationIntoCenter(notification);
              // this.pushNotification(notification);
              if (((notification?.notification_type === "comment_created_reply") && this.channelReplyComment) || ((notification?.notification_type === "comment_created") && this.channelNewComment)) {
                this.pushNotification(notification);
              }
            }
          } else {
            // this.channelRepository.updateTopicCommentCountForCreateComment(notification?.data?.comment?.id, notification?.data?.comment?.topic_id, notification?.data?.comment?.parent_comment_id);
          }
          this.channelRepository.addCommentIntoStore(notification?.data?.comment);
          this.broadcaster.broadcast(ConstantsUtil.ADD_COMMENT_IN_MENTIONED_COMMENT, notification?.data?.comment);
        }
        break;
      case NotificationStatus.COMMENT_UPDATED:
        if (notification?.data?.comment) {
          this.channelRepository.updateCommentIntoStore(notification?.data?.comment);
          this.broadcaster.broadcast(ConstantsUtil.UPDATE_COMMENT_IN_MENTIONED_COMMENT, notification?.data?.comment);
        }
        break;
      case NotificationStatus.CHANNEL_ACCESS_REQUEST:
      case NotificationStatus.TOPIC_ACCESS_REQUEST:
        if (notification?.data?.channel || notification?.data?.topic) {
          if ((this.userJID && this.userJID?.bare !== notification?.actor?.jid && notification?.data?.subscribed)) {
            this.addNotificationIntoCenter(notification);
            this.pushNotification(notification);
          }
        }
        break;
      case NotificationStatus.COMMENT_DELETED:
        this.logger.info("COmment deleted action performed");
        if (notification?.data?.comment_id) {
          const commentId = notification?.data?.comment_id;
          const parentId = notification?.data?.parent_comment_id;
          const topic_id = notification?.data?.topic_id;
          this.channelRepository.removeCommentIntoStore(commentId, parentId, topic_id);
          this.channelRepository.updateTopicCommentCountForDeleteComment(notification?.data?.topic_id);
          this.channelRepository.deleteAllCommentNotifications(commentId, parentId);
          this.channelRepository.getUnreadNotificationCount();
          this.broadcaster.broadcast(ConstantsUtil.DELETE_COMMENT_IN_MENTIONED_COMMENT, { commentId, parentId, topic_id });
        }
        break;
      case NotificationStatus.NOTIFICATION_READ:
        if (notification?.data?.first_notification_id && notification?.data?.last_notification_id) {
          this.channelRepository.getUnreadNotificationCount();
          this.channelRepository.markAsreadNotificationRangeInStore(notification?.data?.first_notification_id, notification?.data?.last_notification_id);
        }
        break;
    }
  }

  addNotificationIntoCenter(notification) {
    if (notification) {
      this.channelRepository.addNotificationIntoStore(notification);
      this.channelRepository.getUnreadNotificationCount();
    }
  }

  pushNotification(notification) {
    if (notification && !this.isNotificationCenterOpen()) {
      this.channelRepository.getNotificationCenterItem(notification).then(notificationItem => {
        if (notificationItem) {
          this.notificationService.channelNotification(notificationItem);
        }
      });
    }
  }

  showChromeNotification(title: string, content: string) {
    if (typeof ChromeNotification !== "undefined") {
      if (ChromeNotification.permission !== "granted") {
        ChromeNotification.requestPermission();
      } else {
        const sticky = false;
        const notification = new ChromeNotification(title, {
          body: content,
          requireInteraction: sticky
        });
        notification.onclick = () => {
          notification.close();
        };
        if (!sticky) {
          setTimeout(() => notification.close(), 5000);
        }
      }
    }
  }

  isNotificationCenterOpen() {
    let notificationCenter = document.querySelector(".notification-center-view");
    if (notificationCenter) {
      return true;
    }
    return false;
  }
}
