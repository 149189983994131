/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "../actions";
import { AppActionTypes } from "../actions/app";
import { ProfileResponse } from "../responses/profile.response";
import { UserStatus } from "../shared/models";
import { AppSettings } from "../talk/models/app-settings.model";
import { NetworkInformation } from "app/shared/models/network-information.model";
import { UserConfig } from "app/shared/models/user-config.model";
import { InvitationConfig } from "app/shared/models/invitation-config";
import { ScreenViews } from "app/talk/utils/constants.util";



export interface AppState {
  selectedLayout: ScreenViews;
  profile: ProfileResponse;
  loggedInUserLoading: boolean;
  loggedInUserLoaded: boolean;
  isLoggedIn: boolean;
  isXmppConnected: boolean;
  isXmppSessionStarted: boolean;
  xmppOmemoInitState: number;

  // PRASHANT_COMMENT: same as xmppSecret
  sessionId: string | null;
  jid: any | null;
  bare: string;
  status: UserStatus;
  notifyOption: string;

  landingUrl: string;
  baseApiUrl: string;
  firebaseToken?: string;
  isBootstrapped: boolean;
  isError: boolean;

  isOnline: boolean;
  isWindowFocused: boolean;
  appSettings: AppSettings;
  fontSize: number;
  globalMute: boolean;
  isDatabaseReady: boolean;
  networkInformation: NetworkInformation;
  domain: string;
  federatedApps: any[];
  selectedTab: string;
  activeFilter: string;
  disableLogout: boolean;
  userConfig: UserConfig;
  invitationConfig: InvitationConfig;
  behaviorTopBarNew: string;
  behaviorBottomBarNew: string;
  selectedProfile: string;
  selectedChannel: string;
  mcbList: any[];
  layout: ScreenViews;
  selectedKeyword: string;
  chatBackgrounds: any[];
  selectedChatBackgroundPreview: string;
  chatBackgroundColorPreview: string;
}



export const initialState: AppState = {
  profile: null,
  loggedInUserLoading: false,
  loggedInUserLoaded: false,
  isXmppConnected: false,
  isXmppSessionStarted: false,
  xmppOmemoInitState: 0,
  isLoggedIn: false,

  sessionId: null,
  jid: null,
  status: UserStatus.ONLINE,
  bare: "",
  notifyOption: "2",
  activeFilter: "all",
  landingUrl: "/talk",
  baseApiUrl: "",
  firebaseToken: null,
  isBootstrapped: false,
  isError: false,
  isDatabaseReady: false,

  isOnline: true,
  isWindowFocused: true,
  appSettings: {
    lang: "en",
    receipts: true,
    informTyping: true,
    publicLastActivity: true,
    enableOperators: true,
    useEncryption: false,
    encryptionType: 0,
    vibration: true,
    ledNotification: true,
    enableSound: true,
    enabledSound: true,
    notificationSound: "0",
    silentHours: true,
    daysOptions: "EVERY_DAY",
    startTime: "00:00",
    endTime: "23:30",
    sendKey: "Enter",
    sendMessageWithEnter: true,
    enableAutofocus: false,
    behaviorTopBarNew: "always",
    behaviorBottomBarNew: "always",
    badgeColor: "red",
    broadcastEnableNotification: true,
    broadcastShowBadge: true,
    mentionEnableNotification: true,
    mentionShowBadge: true,
    channelNewTopic: true,
    channelInvite: true,
    channelNewComment: true,
    channelReplyComment: true,
    chatMention: true,
    chatConferenceReminder: true,
    chatNotifyMissedCall: true,
    enableWhiteboard: true,
    enableBroadcasts: true,
    enableChannels: true,
    enableMCB: true,
    enableOmemoEncryption: true,
    selectedChatBackground: ""
  },
  fontSize: null,
  globalMute: false,
  networkInformation: null,
  domain: null,
  disableLogout: false,
  federatedApps: [],
  selectedTab: "chat",
  userConfig: null,
  invitationConfig: null,
  behaviorTopBarNew: "always",
  behaviorBottomBarNew: "always",
  mcbList: [],
  layout: ScreenViews.TILES,
  selectedProfile: null,
  selectedChannel: null,
  selectedLayout: ScreenViews.TILES,
  selectedKeyword: null,
  chatBackgrounds: [],
  selectedChatBackgroundPreview: "",
  chatBackgroundColorPreview: "",
};


export function appReducer(state: AppState = initialState, action: Action): AppState {
  switch (action.type) {
    case AppActionTypes.SET_LANDING_PAGE_URL: {
      return {
        ...state,
        landingUrl: action.payload
      };
    }
    case AppActionTypes.SET_AVAILABLE_APPS: {
      return {
        ...state,
        federatedApps: action.payload
      };
    }

    case AppActionTypes.SELECT_PROFILE: {
      return {
        ...state,
        selectedProfile: action.payload
      };
    }

    case AppActionTypes.SET_ACTIVE_TAB: {
      return {
        ...state,
        selectedTab: action.payload
      };
    }

    case AppActionTypes.SET_ACTIVE_FILTER: {
      return {
        ...state,
        activeFilter: action.payload
      };
    }

    case AppActionTypes.SET_DOMAIN: {
      return {
        ...state,
        domain: action.payload
      };
    }

    case AppActionTypes.UPDATE_LOGOUT_OPTION: {
      return {
        ...state,
        disableLogout: action.payload
      };
    }

    case AppActionTypes.SET_BASE_API_URL: {
      return {
        ...state,
        baseApiUrl: action.payload
      };
    }

    case AppActionTypes.APP_BOOTSTRAPPED: {
      return {
        ...state,
        isBootstrapped: true
      };
    }

    case AppActionTypes.LOGIN_REQUEST: {
      return {
        ...state,
        loggedInUserLoading: true
      };
    }

    case AppActionTypes.NOTIFY_OPTION_CHANGE: {
      return {
        ...state,
        notifyOption: action.payload
      };
    }

    case AppActionTypes.SET_FIREBASE_TOKEN: {
      return {
        ...state,
        firebaseToken: action.payload
      };
    }


    case AppActionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        isLoggedIn: true
      };
    }

    case AppActionTypes.LOGOUT_SUCCESS: {
      return {
        ...state,
        isLoggedIn: false
      };
    }

    case AppActionTypes.XMPP_SESSION: {
      return {
        ...state,
        jid: action.payload,
        isXmppSessionStarted: true
      };
    }

    case AppActionTypes.XMPP_OMEMO_INIT: {
      return {
        ...state,
        xmppOmemoInitState: 2
      };
    }

    case AppActionTypes.XMPP_OMEMO_MAX_DEVICES_REACHED: {
      return {
        ...state,
        xmppOmemoInitState: 1
      };
    }

    case AppActionTypes.SET_BARE: {
      return {
        ...state,
        bare: action.payload
      };
    }

    case AppActionTypes.STATUS_CHANGE: {
      return {
        ...state,
        status: action.payload
      };
    }

    case AppActionTypes.CHANGE_LAYOUT: {
      return {
        ...state,
        layout: action.payload
      };
    }

    case AppActionTypes.UPDATE_SELECTED_LAYOUT: {
      return {
        ...state,
        selectedLayout: action.payload
      };
    }

    case AppActionTypes.FETCH_ME_SUCCESS: {
      const response = action.payload as ProfileResponse;
      return {
        ...state,
        profile: response,
        isLoggedIn: true,
        loggedInUserLoading: false,
        loggedInUserLoaded: true,
      };
    }

    case AppActionTypes.XMPP_CONNECTED: {
      return {
        ...state,
        isXmppConnected: action.payload
      };
    }

    case AppActionTypes.APP_ONLINE: {
      return {
        ...state,
        isOnline: action.payload
      };
    }

    case AppActionTypes.RESTORE_SAVED_STATE: {
      const savedState = action.payload.appState;
      return savedState ? {...state, ...savedState} : state;
    }


    case AppActionTypes.WINDOW_FOCUS_STATE: {

      if (state.isWindowFocused === action.payload) {
        return state;
      }

      return {
        ...state,
        isWindowFocused: action.payload
      };
    }
    case AppActionTypes.SET_APP_SETTINGS: {
      return {
        ...state,
        appSettings: action.payload
      };
    }

    case AppActionTypes.SET_FONT_SIZE: {

      if (state.fontSize === action.payload) {
        return state;
      }

      return {
        ...state,
        fontSize: action.payload
      };
    }

    case AppActionTypes.RESTORE_PROFILE_JID: {
      return {
        ...state,
        profile: action.payload.profile,
        jid: action.payload.jid,
      };
    }

    case AppActionTypes.DATABASE_READY: {
      return {
        ...state,
        isDatabaseReady: true
      };
    }

    case AppActionTypes.ENABLE_GLOBAL_MUTE: {
      return {
        ...state,
        globalMute: true
      };
    }

    case AppActionTypes.DISABLE_GLOBAL_MUTE: {
      return {
        ...state,
        globalMute: false
      };
    }

    case AppActionTypes.UPDATE_CONNECTION_INFORMATION: {
      return {
        ...state,
        networkInformation: {
          ...state.networkInformation,
          ...action.payload
        }
      };
    }

    case AppActionTypes.UPDATE_USER_CONFIG: {
      return {
        ...state,
        userConfig: action.payload
      };
    }

    case AppActionTypes.UPDATE_TOPIC_VIEW: {
      return {
        ...state,
        userConfig: {...state.userConfig, topics_view: action.payload}
      };
    }

    case AppActionTypes.UPDATE_TRASH_CONFIG: {
      return {
        ...state,
        userConfig: {...state.userConfig, auto_clean_trash: action?.payload?.auto_clean_trash, auto_clean_trash_period: action?.payload?.auto_clean_trash_period}
      };
    }

    case AppActionTypes.SET_INVITATION_CONFIG: {
      return {
        ...state,
        invitationConfig: action.payload
      };
    }

    case AppActionTypes.SET_MCB_LIST: {
      return {
        ...state,
        mcbList: action.payload
      };
    }

    case AppActionTypes.SET_LOCAL_SEARCH_LAYOUT: {
      return {
        ...state,
        selectedKeyword: action.payload
      };
    }

    case AppActionTypes.SET_CHAT_BACKGROUND_IMAGES: {
      const newBackground = action.payload;

      // Check if the new background is not already in the array
      const isBackgroundExists = state.chatBackgrounds.some(
        background => background.id === newBackground.id
      );

      if (!isBackgroundExists) {
        const chatBackgrounds = [...state.chatBackgrounds, newBackground];
        return {
          ...state,
          chatBackgrounds: chatBackgrounds
        };
      }
      // If it's already in the array, return the current state unchanged
      return state;
    }

    case AppActionTypes.SET_SELECTED_CHAT_BACKGROUND_PREVIEW: {
      return {
        ...state,
        selectedChatBackgroundPreview: action.payload
      };
    }

    case AppActionTypes.DELETE_CHAT_BACKGROUND_IMAGE: {
      const chatBackgrounds = state.chatBackgrounds;
      const deleteBackground = chatBackgrounds.find(b=> b.id === action.payload);
      if (deleteBackground) {
        const index = chatBackgrounds.indexOf(deleteBackground);
        chatBackgrounds.splice(index, 1);
      }
      return {
        ...state,
        chatBackgrounds: chatBackgrounds
      };
    }

    case AppActionTypes.SET_CHAT_BACKGROUND_COLOR_PREVIEW: {
      return {
        ...state,
        chatBackgroundColorPreview: action.payload
      };
    }

    default: {
      return state;
    }
  }
}


export const _getSessionId = (state: AppState) => state.sessionId;
export const _getJID = (state: AppState) => state.jid;
export const _getBare = (state: AppState) => state.bare;

export const _getStatus = (state: AppState) => state.status;
export const _getNotifyOption = (state: AppState) => state.notifyOption;

export const _getUserProfile = (state: AppState) => {
  const profile = state.profile;
  if (!!profile && !!profile.user && !profile.user.fullName) {
    profile.user.fullName = profile.user.firstName + " " + profile.user.lastName;
  }
  return profile;
};
export const _getIsLoggedInUserLoading = (state: AppState) => state.loggedInUserLoading;
export const _getIsLoggedInUserLoaded = (state: AppState) => state.loggedInUserLoaded;
export const _getIsLoggedIn = (state: AppState) => state.isLoggedIn;
export const _getIsConnectedXMPP = (state: AppState) => state.isXmppConnected;
export const _getIsXmppSessionStarted = (state: AppState) => state.isXmppSessionStarted;
export const _getXmppOmemoInitState = (state: AppState) => state.xmppOmemoInitState;

export const _getLandingPageUrl = (state: AppState) => state.landingUrl;
export const _getBaseApiUrl = (state: AppState) => state.baseApiUrl;
export const _getFirebaseToken = (state: AppState) => state.firebaseToken;

export const _getIsAppBootstrapped = (state: AppState) => state.isBootstrapped;
export const _getIsAppOnline = (state: AppState) => state.isOnline;

export const _getIsWindowFocused = (state: AppState) => state.isWindowFocused;

export const _getAppSettings = (state: AppState) => state.appSettings;

export const _getFontSize = (state: AppState) => state.fontSize;
export const _getIsDatabaseReady = (state: AppState) => state.isDatabaseReady;
export const _getGlobalMute = (state: AppState) => state.globalMute;
export const _getNetworkInformation = (state: AppState) => state.networkInformation;
export const _getDomain = (state: AppState) => state.domain;
export const _getFederatedApps = (state: AppState) => state.federatedApps;
export const _getActiveTab = (state: AppState) => state.selectedTab;
export const _getActiveFilter = (state: AppState) => state.activeFilter;
export const _getLogoutOption = (state: AppState) => state.disableLogout;
export const _getUserConfig = (state: AppState) => state.userConfig;
export const _getInvitationConfig = (state: AppState) => state.invitationConfig;
export const _getMCBList = (state: AppState) => state.mcbList;
export const _getCallLayout = (state: AppState) => state.layout;
export const _getSelectedLayout = (state: AppState) => state.selectedLayout;
export const _getSelectedProfile = (state: AppState) => state.selectedProfile;
export const _getSelectedChannel = (state: AppState) => state.selectedChannel;
export const _getLocalSearchLayout = (state: AppState) => state.selectedKeyword;
export const _getChatBackgrounds = (state: AppState) => state.chatBackgrounds;
export const _selectedChatBackgroundPreview = (state: AppState) => state.selectedChatBackgroundPreview;
export const _chatBackgroundColorPreview = (state: AppState) => state.chatBackgroundColorPreview;
