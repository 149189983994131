<!--
  ~ VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->
<div class="notification-center-view-wrap" (click)="$event.stopPropagation();">
    <div class="notification-center-view" *ngIf="view === 'notifications'">
        <vnc-header onmouseover="this.setAttribute('data-title', this.title);this.title = ''"
            onmouseout="this.setAttribute('title', this.getAttribute('data-title'))" style="width:100%;"
            listToolTip="{{'SUBSCRIPTIONS' | translate}}" settingsToolTip="{{'SETTINGS' | translate}}"
            (onActionPanelClick)="notificationPanelClick($event)" [title]="'NOTIFICATIONS' | translate" size="l"
            type="title-2-actionpanel"></vnc-header>
        <div class="notification-tab-header">
            <div class="tabs-section">
                <vnc-chips *ngFor="let tab of tabs" text="{{tab.label | translate | lowercase}}" type="filter" size="s"
                    (clickEvent)="activateTab(tab.value)" [active]="tab.active"></vnc-chips>
            </div>
            <div class="mark-all-read-icon">
                <vnc-icon-container [isClickable]="notificationCount > 0" (click)="markAsReadAllNotificaitons()">
                    <ng-container *ngIf="notificationCount > 0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18">
                            <g fill="none" fill-rule="evenodd">
                                <g fill="#337CBD">
                                    <g>
                                        <g>
                                            <g>
                                                <g>
                                                    <path
                                                        d="M4.5 8.377L1.373 5.25 0.307 6.308 4.5 10.5 13.5 1.5 12.443 0.443z"
                                                        transform="translate(-447 -105) translate(14 96) translate(430 6) translate(3 3) translate(2.25 3.75)" />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </ng-container>
                    <ng-container *ngIf="notificationCount == 0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18">
                            <g fill="none" fill-rule="evenodd">
                                <g fill="#C5CBD1">
                                    <g>
                                        <g>
                                            <g>
                                                <g>
                                                    <g>
                                                        <path
                                                            d="M4.5 8.377L1.373 5.25 0.307 6.308 4.5 10.5 13.5 1.5 12.443 0.443z"
                                                            transform="translate(-841 -144) translate(394 39) translate(0 96) translate(444 6) translate(3 3) translate(2.25 3.75)" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </ng-container>
                </vnc-icon-container>
            </div>
        </div>
        <div class="notifications-list vnc-scroll-bar--small--vertical" vpInfiniteScroll scrollPercentage="0.8"
            (onScroll)="onUserScroll($event, getActiveTab())">
            <div class="notification-strip" *ngIf="(getActiveTab() === notificationCategory.ALL && allNotifications?.length) ||
            (getActiveTab() === notificationCategory.UNREAD && unreadNotifications?.length)"></div>
            <div class="notification-content-wrapper" *ngIf="getActiveTab() === notificationCategory.ALL">
                <div class="header-group" *ngFor="let periodGroup of allPeriodGroup">
                    <div class="notification-header" (click)="showHideNotifications(periodGroup)"
                        [class.collapse]="!this.headerInfo[periodGroup]?.isExapanded"
                        *ngIf="allNotificationsGroupedByDate[periodGroup]?.notifications?.length > 0">
                        <vp-dropdown-item [itemType]="'subheader-icon'"
                            label="{{ getHeaderLabel(periodGroup) | translate | uppercase }}" [sufixImageType]="'check'"
                            [size]="'m'" (suffixIconClick)="markAsReadNotificaitons(periodGroup)"
                            [isDisabled]="headerInfo[periodGroup]?.isDisabled">
                        </vp-dropdown-item>
                    </div>
                    <div class="notifications-items"
                        *ngIf="allNotificationsGroupedByDate[periodGroup]?.notifications?.length > 0 && this.headerInfo[periodGroup]?.isExapanded"
                        [class.show-more]="this.headerInfo[periodGroup]?.isShowMore">
                        <div *ngFor="let item of allNotificationsGroupedByDate[periodGroup]?.notifications">
                            <div *ngIf="!item?.isSocial"    class="notification-item">
                                <div class="unread-mark" [class.read]="item?.unread"></div>
                                <vnc-notification-center [type]="item?.type" [desctiption]="item?.description"
                                    dateLabel="{{ item?.timing | date: 'd MMM yyyy'}}"
                                    timeLabel="{{ item?.timing | date: 'HH:mm' }}" [imgSrc]="item?.imgSrc"
                                    [authorAvatar]="item?.authorAvatar" [title]="item?.title"
                                    [isPrivate]="item?.isSocial ? false : item.isPrivate" [object]="item.object"
                                    [defaultImageIndex]="item.defaultImageIndex" [isSocial]="item?.isSocial"
                                    (onNotificationClick)="clickOnNotificationItem($event)">
                                </vnc-notification-center>
                            </div>
                        </div>
                    </div>
                    <div class="notifications-more"
                        *ngIf="allNotificationsGroupedByDate[periodGroup]?.notifications?.length > 5 && this.headerInfo[periodGroup]?.isExapanded && !this.headerInfo[periodGroup]?.isShowMore"
                        (click)="showMoreNotifications(periodGroup)">
                        <span>+{{ allNotificationsGroupedByDate[periodGroup]?.notifications?.length - 5 }} {{
                            'MORE_TEXT' | translate | lowercase }}</span>
                    </div>
                </div>
                <div class="no-notifications" *ngIf="allNotifications?.length === 0">
                    <div *ngIf="gotNotification" class="no-notificaiton-text">
                        {{ 'NO_NOTIFICATIONS' | translate }}
                    </div>
                    <vnc-loader-circular *ngIf="!gotNotification"></vnc-loader-circular>

                </div>
            </div>
            <div class="notification-content-wrapper" *ngIf="getActiveTab() === notificationCategory.UNREAD">
                <div class="header-group" *ngFor="let periodGroup of unreadPeriodGroup">
                    <div class="notification-header" (click)="showHideNotifications(periodGroup)"
                        [class.collapse]="!this.headerInfo[periodGroup]?.isExapanded"
                        *ngIf="unreadNotificationsGroupedByDate[periodGroup]?.notifications?.length > 0">
                        <vp-dropdown-item [itemType]="'subheader-icon'"
                            label="{{ getHeaderLabel(periodGroup) | translate | uppercase }}" [sufixImageType]="'check'"
                            [size]="'m'" (suffixIconClick)="markAsReadNotificaitons(periodGroup)"
                            [isDisabled]="headerInfo[periodGroup]?.isDisabled">
                        </vp-dropdown-item>
                    </div>
                    <div class="notifications-items"
                        *ngIf="unreadNotificationsGroupedByDate[periodGroup]?.notifications?.length > 0 && this.headerInfo[periodGroup]?.isExapanded"
                        [class.show-more]="this.headerInfo[periodGroup]?.isShowMore">
                        <div *ngFor="let item of unreadNotificationsGroupedByDate[periodGroup]?.notifications">
                            <div class="notification-item">
                                <div class="unread-mark" [class.read]="item.unread"></div>
                                <vnc-notification-center [type]="item.type" [desctiption]="item.description"
                                    dateLabel="{{ item.timing | date: 'd MMM yyyy'}}"
                                    timeLabel="{{ item.timing | date: 'HH:mm' }}" [imgSrc]="item?.imgSrc"
                                    [authorAvatar]="item?.authorAvatar" [title]="item?.title"
                                    [isPrivate]="item?.isSocial ? false : item.isPrivate" [object]="item.object"
                                    [defaultImageIndex]="item.defaultImageIndex" [isSocial]="item?.isSocial"
                                    (onNotificationClick)="clickOnNotificationItem($event)">
                                </vnc-notification-center>
                            </div>
                        </div>
                    </div>
                    <div class="notifications-more"
                        *ngIf="unreadNotificationsGroupedByDate[periodGroup]?.notifications?.length > 5 && this.headerInfo[periodGroup]?.isExapanded && !this.headerInfo[periodGroup]?.isShowMore"
                        (click)="showMoreNotifications(periodGroup)">
                        <span>+{{ unreadNotificationsGroupedByDate[periodGroup]?.notifications?.length - 5 }} {{
                            'MORE_TEXT' | translate | lowercase }}</span>
                    </div>
                </div>
                <div class="no-notifications" *ngIf="unreadNotifications?.length === 0">
                    <div *ngIf="gotNotifications" class="no-notificaiton-text">
                        {{ 'NO_NOTIFICATIONS' | translate }}
                    </div>
                    <vnc-loader-circular *ngIf="!gotNotifications"></vnc-loader-circular>
                </div>
            </div>
        </div>
    </div>
    <div class="subscription-center-wrap" *ngIf="view === 'subscriptions'">
        <vp-subscription-center (onBackClick)="backToNotification($event)"></vp-subscription-center>
    </div>
</div>
