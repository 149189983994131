
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { JID } from "../models/jid.model";
import { getUserJID, getGlobalMute, getAppSettings } from "../../reducers/index";
import {
  getActiveConference,
  getBackCameraId,
  getConferenceId,
  getConferenceParticipants,
  getConferenceType,
  getFrontCameraId,
  getFullScreenParticipantId,
  getSelectedParticipantId,
  getHasActiveCall,
  getHasMicrophone,
  getHasSpeaker,
  getHasWebcam,
  getInvitationStatus,
  getIsConferenceAudioMuted,
  getIsConferenceScreenSharingOn,
  getIsConferenceVideoMuted,
  getJitsiRoom,
  getStreamId,
  TalkRootState,
  getAvailableMediaDevices,
  getInvitedParticipants,
  getConversationById, getConversationOwner, getActiveWhiteboard,
  getConversationAdmins, getConversationMembers, getCurrentView, getScreenSharingRequestStatus, getLobbyState, getKnockingParticipants, getUploadedBackground, getVirtualBackground, isFloatingExpanded, isNoiseSuppressionEnabled
} from "../reducers/index";
import { Store } from "@ngrx/store";
import { bufferTime, debounceTime, filter, Observable } from "rxjs";
import { JitsiParticipant, JitsiOption } from "../models/jitsi-participant.model";
import {
  ConferenceAddParticipant,
  // sct
  JitsiConferenceAddParticipant,
  ConferenceLeaveSuccess,
  ConferenceMuteAudio,
  ConferenceMuteVideo,
  ConferenceRemoveParticipant,
  // sct
  JitsiConferenceRemoveParticipant,
  ConferenceSetFullScreenParticipant,
  ConferenceSelectParticipant,
  ConferenceShareScreen,
  ConferenceStartRequest,
  ConferenceUnMuteAudio,
  ConferenceUnMuteVideo,
  ConferenceUnShareScreen,
  HideActiveCall,
  SetConferenceId,
  SetConversationTarget,
  SetJitsiRoomId,
  SetMicrophoneStatus,
  SetSpeakerStatus,
  SetStreamId,
  SetWebcamStatus,
  ShowActiveCall,
  UpdateBackCameraId,
  UpdateFrontCameraId,
  ConferenceSetActiveWhiteboard,
  ResetConferenceStartRequest,
  AnonymousConference,
  SetCurrentView,
  SetAvailableMediaDevices, ResetScreenSharingData, UpdateJitsiConfig,
  StartLoadJitsiConfig, StopLoadJitsiConfig, ToggleFloatingVideo, SetNoiseSuppressionEnabled, SetScreenSharingRequest, SetScreenSharingRequestStatus, SetScreenSharingStarted
} from "../actions/conference";
import { ConversationService } from "../services/conversation.service";
import { XmppService } from "../services/xmpp.service";
import { Broadcaster } from "../shared/providers/broadcaster.service";
import { NotificationService } from "../services/notification.service";
import { VNCTalkConference, Message } from "../models/message.model";
import { ConversationRepository } from "./conversation.repository";
import { Conversation } from "../models/conversation.model";
import { CommonUtil } from "../utils/common.util";
import { TranslateService } from "@ngx-translate/core";
import { ConfigService } from "../../config.service";
import { BroadcastKeys, ConstantsUtil, ScreenViews } from "../utils/constants.util";
import { Subject, BehaviorSubject, of, take } from "rxjs";
import { environment } from "../../environments/environment";
import { ChangeLayout, SetActiveTab, SetAppSettings, UpdateSelectedLayout, XmppSession } from "app/actions/app";
import { DatetimeService } from "../services/datetime.service";
import { VNCTalkNotificationsService } from "../notifications";
import { ContactRepository } from "./contact.repository";
import { ConversationUtil } from "../utils/conversation.util";
import { ConversationUpdateAdmins, ConversationUpdateMembers } from "../actions/conversation";
import { MeetingsService } from "../services/meetings.service";
import { GroupChatsService } from "../services/groupchat.service";
import { SetSelectedWhiteboardId } from "../actions/whiteboard";
import { NavigationEnd, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { ConferenceDialogComponent } from "../shared/components/dialogs/conference-confirmation/conference-confirmation.component";
import { ToastService } from "app/shared/services/toast.service";
import { LoggerService } from "app/shared/services/logger.service";
import { NoiseSuppressionEffect } from "../services/noise-suppression/NoiseSuppressionEffect";

@Injectable()
export class ConferenceRepository {
  invitee: string;
  private microphoneStatus: boolean;
  private webcamStatus: boolean;
  private speakerStatus: boolean;
  private frontCameraId: string;
  private backCameraId: string;

  userJID: JID;
  selectedConversation: Conversation;
  activeConferenceTarget: string;
  joinedParticipants = {};
  callHistory = {};
  roomId: string;
  isQuickCall = false;
  lastIncomingConversationTarget: string;

  participantsInExistingCall = [];
  totalRejectedParticipantsInExistingCall = 0;
  private callRequestTimeoutTimer: any;
  invitedParticipants = new BehaviorSubject<any[]>([]);
  fullPreviewParticipant$ = new BehaviorSubject<any>(null);
  presenterParticipant$ = new BehaviorSubject<any>(null);
  hideTextChatStatus$ = new BehaviorSubject<boolean>(false);
  kickedParticipants = [];
  hideVideoTracks$ = new BehaviorSubject<boolean>(false);
  showMiniChat$ = new BehaviorSubject<boolean>(false);
  showSelectChat$ = new BehaviorSubject<boolean>(false);
  miniWindowSize$ = new BehaviorSubject<string>("full");
  scheduledConference$ = new BehaviorSubject<any[]>([]);
  hideBottomBar$ = new BehaviorSubject<boolean>(false);
  invitedParticipants$ = new BehaviorSubject<any[]>([]);
  password: any;
  isAppPaused = false;
  availableMediaDevicesSet = false;
  hideAllVideo$ = new BehaviorSubject<boolean>(false);
  isDisplayed$ = new BehaviorSubject<boolean>(false);
  localUserInfo: any = {};
  anonymousConferenceJitsiRoom: string;
  callView$ = new BehaviorSubject<string>("floating");
  soundDevice$ = new BehaviorSubject<any>(null);
  constructor(private store: Store<TalkRootState>,
              private middlewareService: ConversationService,
              private groupChatsService: GroupChatsService,
              private xmppService: XmppService,
              private broadcaster: Broadcaster,
              private notificationService: NotificationService,
              private notificationsService: VNCTalkNotificationsService,
              private contactRepo: ContactRepository,
              private translate: TranslateService,
              private logger: LoggerService,
              private dialog: MatDialog,
              public configService: ConfigService,
              private datetimeService: DatetimeService,
              private meetingsService: MeetingsService,
              private conversationRepo: ConversationRepository,
              private toastService: ToastService,
              private router: Router) {

    document.addEventListener("deviceready", this.deviceReady.bind(this), false);
//    if (environment.theme !== "hin") {
//      this.handleScheduledConference();
//    }
    this.anonymousConferenceJitsiRoom = "";
    this.store.select(getHasMicrophone).subscribe(v => this.microphoneStatus = v);
    this.store.select(getHasWebcam).subscribe(v => this.webcamStatus = v);
    this.store.select(getHasSpeaker).subscribe(v => this.speakerStatus = v);
    this.store.select(getFrontCameraId).subscribe(v => {
      this.logger.info("[ConferenceRepository][getFrontCameraId]", v);
      this.frontCameraId = v;
    });
    this.store.select(getBackCameraId).subscribe(v => {
      this.logger.info("[ConferenceRepository][getBackCameraId]", v);
      this.backCameraId = v;
    });

    this.store.select(getUserJID).pipe(filter(v => !!v)).subscribe(jid => {
      this.userJID = jid;
    });

    this.invitedParticipants$.asObservable().pipe(debounceTime(2000)).subscribe(v => {
      this.logger.info("[invitedParticipants] who does not respone yet: ", v);
    });

    this.store.select(getActiveConference).subscribe(v => {
      this.activeConferenceTarget = v;
      this.logger.info("[ConferenceRepository][getActiveConference]", v);
    });

    this.broadcaster.on<any>("onScreenStarted")
    .subscribe(() => {
      this.store.dispatch(new ChangeLayout(ScreenViews.FILMSTRIP));
      this.callView$.next("stripe");

    });
    this.broadcaster.on<boolean>("toggleHideVideoIOS")
    .subscribe(v => {
      this.toggleHideVideoIOS(v);
    });
    this.broadcaster.on<any>("onUserLeft")
    .subscribe(participantId => {
      if (this.fullPreviewParticipant$.value && this.fullPreviewParticipant$.value.id === participantId) {
        this.fullPreviewParticipant$.next(null);
      }
    });
    this.broadcaster.on<any>("endingCall")
    .subscribe(() => {
      this.notificationService.playEndingCall();
    });

    this.broadcaster.on<any>("onScreenStopped")
    .subscribe(participantId => {
      this.logger.info("participantId", participantId);
      if (this.fullPreviewParticipant$.value && this.fullPreviewParticipant$.value.id === participantId ) {
        this.fullPreviewParticipant$.next(null);
        this.store.dispatch(new ChangeLayout(ScreenViews.TILES));
      }
    });

    this.broadcaster.on<any>("resetFullPreviewParticipant")
    .subscribe(() => {
      this.fullPreviewParticipant$.next(null);
      this.store.dispatch(new ChangeLayout(ScreenViews.TILES));
    });

    this.broadcaster.on<any>("forceGetMediaDevices")
      .subscribe(() => {
      this.updateDevicesList();
    });

    document.addEventListener("offline", (event: any) => {
      this.logger.info("[conferenceRepository][getIsAppOnlineX]1 offline event: ", event);
      if (CommonUtil.isOnAndroid()) {
        if (!!this.lastIncomingConversationTarget && (this.lastIncomingConversationTarget.length > 0)) {
          this.logger.info("[conferenceRepository][getIsAppOnlineX]1 cancel invite -> hideIncomingCallNotification: ", this.lastIncomingConversationTarget);
          this.notificationsService.hideIncomingCallNotification(this.lastIncomingConversationTarget);
          this.lastIncomingConversationTarget = "";
        }
      }
    });

    this.broadcaster.on<any>("onCallRejected")
      .subscribe(callData => {
        const jitsiOption: JitsiOption = {
          value: callData.jitsiRoom,
          jitsiurl: callData.jitsiURL
        };
        this.rejectCallAction(callData.from_jid, callData.conferenceId, callData.call_type, jitsiOption);
        this.sendSelfRejectCallSignal(callData.conferenceId, callData.call_type);
      });

    if (!this.configService.isAnonymous) {
      this.conversationRepo.getSelectedConversation().subscribe(conv => this.selectedConversation = conv);

      this.broadcaster.on<any>("joinConferenceViaNotification").subscribe(data => {
        this.logger.info("[ConferenceRepository][joinConferenceViaNotification]", data);
        if (data.extraCallAction && data.extraCallAction === "TalkCallAccept") {
          this.sendSelfAcceptCallSignal(data.conversationTarget, data.conferenceType);
          this.joinConferenceViaNotification(data.conversationTarget, data.conferenceType, data.initiatorJid,
            data.jitsiRoom, data.jitsiURL);
        }
      });
      this.broadcaster.on<any>("stopPlayCalling").subscribe(() => {
        this.stopPlayCalling();
      });
      this.broadcaster.on<any>("setInvitedParticipants").subscribe(data => {
        this.setInvitedParticipants(data);
      });

      this.broadcaster.on<any>("onUserJoined").subscribe(data => {
        this.invitedParticipants$.next(this.invitedParticipants$.value.filter(v => v.jid !== data));
        this.store.select(state => getConversationOwner(state, this.activeConferenceTarget)).pipe(take(1)).subscribe(owner => {
          if (owner && owner === this.userJID?.bare) {
            this.sendInvitedParticipants();
          }
        });
      });

      if (!this.configService.isAnonymous) {

        document.addEventListener("pause", () => {
          this.logger.info("[ConferenceRepository] pause");
          this.isAppPaused = true;

          // this.clearscheduleConfTimer();
        });

        document.addEventListener("resume", () => {
          this.logger.info("[ConferenceRepository] resume");
          this.isAppPaused = false;

          // this.handleScheduledConference();
        });
        this.broadcaster.on<any>("scheduledConferences").subscribe(v => {
          this.scheduledConference$.next(v.filter(v =>  v.startTime && (new Date(v.startTime).getTime() > new Date().getTime())));
        });
        this.xmppService.getOnMessage().pipe(bufferTime(400)).subscribe((messages) => {
          messages.filter(m => m.vncTalkConference).forEach(message => {

            const incomingCallSignal = message.vncTalkConference;
            const conferenceId = incomingCallSignal && incomingCallSignal.conferenceId;
            const conferenceType = incomingCallSignal && incomingCallSignal.conferenceType;
            const eventType = incomingCallSignal && incomingCallSignal.eventType;
            const jitsiRoom = incomingCallSignal && incomingCallSignal.jitsiRoom;
            const jitsiURL = incomingCallSignal && incomingCallSignal.jitsiURL;
            const senderBare = incomingCallSignal.from;
            let conversationTarget = conferenceId.indexOf("@") !== -1 ? conferenceId : senderBare;

            const isGroup = CommonUtil.isGroupTarged(conferenceId);
            const isSentMessage = this.userJID && senderBare === this.userJID?.bare;

            const msgid = message.id;

            this.logger.info("[ConferenceRepository][getOnMessage] window.appInBackground", senderBare, message, window.appInBackground);

            // incoming signals
            if (!isSentMessage) {
              if (["join", "leave", "reject"].indexOf(eventType) !== -1) {
                this.invitedParticipants$.next(this.invitedParticipants$.value.filter(v => v.jid !== senderBare));
              }
              // ingore delayed call signals (e.g. that were sent while offline)
              if (message.delay) {
                this.logger.info("[ConferenceRepository][getOnMessage] ignore deplayed call signal", message);
                return;
              }

              // if (window.appInBackground && ["join", "leave", "reject", "invite"].indexOf(eventType) !== -1) {
              if (CommonUtil.isOnAndroid() && ["join", "leave", "reject", "invite"].indexOf(eventType) !== -1) {
                this.handleCallSignalInBackground(msgid, eventType, senderBare, conversationTarget, conferenceType, jitsiRoom, jitsiURL);
                return;
              }


              // INVITE
              if (eventType === "invite" && conferenceType !== "whiteboard") {
                this.logger.info("[ConferenceRepository][getOnMessage] INVITE", "incoming", message, Date.now() );

                // When a user got a push re call, then rejected it via FCM action, then opened an app - there is again an in-app calling notification.
                // We have a logic to ignore all call signals if an app is in BG, but cause iOS app pauses the JS execution, all these events come when an app becomes active again and now we have an app in foreground.
                // Solution - store a 'processed as rejected' call id to shared preferences from FCM iOS code, and then check this info from JS when receive a call invite.
                // If this call invite is already processed - then simply ignore it. We can use a xmpp message id for this, cause 'jitsiRoom' is same for all calls in same room.
                if (CommonUtil.isOnIOS()) {
                  this.isCallAlreadyProcessedByiOSFCMPlugin(message.id).subscribe(isProcessed => {
                    this.logger.info("[ConferenceRepository][getOnMessage] INVITE isCallAlreadyProcessedByiOSFCMPlugin", isProcessed);

                    this.cleanupCallAlreadyProcessedByiOSFCMPluginData();

                    if (!isProcessed) {
                      this.showIncomingCallNotificationAndSetInitiator(incomingCallSignal, jitsiRoom, senderBare, conversationTarget);
                    }
                  });
                } else {
                  // do not show incoming call notification,
                  // if we opened an app by push and then later got an invite by xmpp,
                  // so simply ignore it
                  this.getActiveConference().pipe(take(1)).subscribe(activeConf => {
                    this.logger.info("[ConferenceRepository][getOnMessage] getActiveConference", activeConf, conversationTarget);
                    if (!activeConf || activeConf !== conversationTarget) {
                      this.showIncomingCallNotificationAndSetInitiator(incomingCallSignal, jitsiRoom, senderBare, conversationTarget);
                    } else {
                      this.logger.info("[ConferenceRepository][getOnMessage] ignore incoming call notificaction");
                    }
                  });
                }

              // UPGRADE
              } else if (eventType === "upgrade") {
                this.logger.info("[ConferenceRepository][getOnMessage] UPGRADE", "incoming", message);

                this.getActiveConference().pipe(take(1)).subscribe((target) => {
                  this.logger.info("[ConferenceRepository][getOnMessage] UPGRADE", "getActiveConference", target);
                  if (this.getConferenceKey() === incomingCallSignal.oldConferenceId) {
                    this.logger.info("[ConferenceRepository][getOnMessage] UPGRADE", "join new room and update acive conference", conferenceId);
                    // join text room (new conference id)
                    // replace 1:1 chat in the UI with the new text room
                    const room = this.conversationRepo.createLocalConversation(conferenceId);
                      this.conversationRepo.joinRoomIfNotJoined(room);
                    const jitsiURL = this.configService.get("jitsiURL") || "";
                    const jitsiOption = {
                      value: jitsiRoom,
                      jitsiurl: jitsiURL + jitsiRoom
                    };
                    this.conversationRepo.updateConversationRoomId(conferenceId, jitsiOption);
                    this.setJitsiRoom(jitsiOption);
                    this.setConversationTarget(conferenceId);
                    this.setConferenceKey(conferenceId);
                    this.store.dispatch(new SetActiveTab("chat"));
                    this.logger.info("[ConferenceRepository][getOnMessage] UPGRADE", "navigate to new conference", conferenceId);
                    this.conversationRepo.navigateToConversation(conferenceId);
                  }
                });

              // REJECT
              } else if (eventType === "reject") {
                this.logger.info("[ConferenceRepository][getOnMessage] REJECT", "incoming", message);

                // 1-1 rejected
                if (!isGroup) {
                  // There can be a case,
                  // when there are 2 active clients fo one user,
                  // and a user can acccept from one of them and reject from the 2nd.
                  // So, when we reecive a reject from 2nd client - we simply need to ignore it.
                  //
                  // TODO: probably it's better to check not by length,
                  // but to check if the sender is presented already in 'getConferenceParticipants'
                  const participantsNotME = this.getConferenceParticipantsNotME();
                    this.broadcaster.broadcast(BroadcastKeys.CALL_REQUEST_REJECTED);
                    this.broadcaster.broadcast(BroadcastKeys.HIDE_INVITATION, conversationTarget);
                    this.stopPlayCalling();
                  return;
                } else {
                  // here we count number of rejects and if rejects == participants then drop a call
                    ++this.totalRejectedParticipantsInExistingCall;
                    this.invitedParticipants.asObservable().pipe(take(1)).subscribe(members => {
                      let joinedParticipants = [];
                      this.store.select(getConferenceParticipants).pipe(take(1)).subscribe(participants => {
                        joinedParticipants = participants;
                    });
                    this.logger.info(`[ConferenceRepository][getOnMessage] REJECT, total rejected: ${this.totalRejectedParticipantsInExistingCall}, total members: ${members.length}, total joined: ${joinedParticipants.length}`);
                    if (this.totalRejectedParticipantsInExistingCall === members.length && joinedParticipants.length < 2) {
                      // drop a call, no one left here
                      this.logger.info(`[ConferenceRepository][getOnMessage] REJECT drop a call, no one left`, message);
                      this.broadcaster.broadcast(BroadcastKeys.ALL_PARTICIPANTS_LEFT, message);
                      this.stopPlayCalling();
                      this.broadcaster.broadcast(BroadcastKeys.CALL_REQUEST_REJECTED, message);
                    }
                  });
                }

              // JOIN
              } else if (eventType === "join") {
                this.logger.info("[ConferenceRepository][getOnMessage] JOIN", "join", message);
                this.notificationService.stopPlayCalling();

              // LEAVE
              } else if (eventType === "leave") {
                this.notificationService.stopPlayCalling();

                // initiator left
                const callInitiator = this.getCallInitiator(conversationTarget);
                const isInitiatorLeft = senderBare === callInitiator;

                if (message.vncTalkConference.conferenceType === "screen-receive" &&
                  message.vncTalkConference.eventType === "leave" && isInitiatorLeft) {
                    this.broadcaster.broadcast(BroadcastKeys.CALL_REQUEST_REJECTED);
                }

                this.logger.info("[ConferenceRepository][getOnMessage] LEAVE", "leave", senderBare, callInitiator, message);

                if (isInitiatorLeft || !isGroup) {
                  if (isInitiatorLeft) {
                    this.getActiveConference().pipe(take(1)).subscribe(activeConf => {
                      this.logger.info("[ConferenceRepository][getOnMessage] LEAVE", "getActiveConference", activeConf);
                      if (!activeConf) {
                        setTimeout(() => {
                          this.displayMissedCallNotification(incomingCallSignal);
                        }, 1500);
                      }
                    });
                  }
                  this.logger.info("[ConferenceRepository][getOnMessage] LEAVE2 -> notifyCallEnded", "leave", senderBare, callInitiator, message);
                  this.broadcaster.broadcast(BroadcastKeys.INITIATOR_LEFT, conversationTarget);
                  this.broadcaster.broadcast(BroadcastKeys.HIDE_INVITATION, conversationTarget);
                  this.broadcaster.broadcast("notifyCallEnded");
                  return;
                }
              }

            // outgoing signals
            } else {
              if (["join", "leave", "reject", "cancel"].indexOf(eventType) !== -1) {
                this.broadcaster.broadcast(BroadcastKeys.HIDE_INVITATION, conversationTarget);
                this.notificationService.stopPlayCalling();

              // joined from other active device
              } else if (["joined-self", "rejected-self"].indexOf(eventType) !== -1) {
                if (conversationTarget === this.userJID?.bare) {
                  conversationTarget = message.vncTalkConference.conferenceId.replace(/#/g, "@").split(",").find(v => v !== this.userJID?.bare);
                }
                  // this.broadcaster.broadcast(BroadcastKeys.HIDE_INVITATION, conversationTarget);
                // if (eventType === "joined-self") {
                //   this.hangup(conversationTarget);
                // }
                this.broadcaster.broadcast(BroadcastKeys.HIDE_INCOMING_INVITATION, conversationTarget);
                if (CommonUtil.isOnAndroid()) {
                  // ratinale: the incoming call notification of native call disappears automatically when call is joined,
                  // if we cancel here for our own join we exit the call immediately
                  if ((message.from.resource !== this.userJID.resource) || (eventType === "rejected-self")) {
                    this.logger.info("[ConferenceRepository][getOnMessage] REJECTED self -> hideIncomingCallNotification", message, message.from.resource, this.userJID.resource, conversationTarget);
                    this.notificationsService.hideIncomingCallNotification(conversationTarget);
                  }
                } else {
                  this.logger.info("[ConferenceRepository][getOnMessage] JOINED self -> hideIncomingCallNotification", message, message.from.resource, this.userJID.resource, conversationTarget);
                  this.notificationsService.hideIncomingCallNotification(conversationTarget);
                }
                this.logger.info("[ConferenceRepository][getOnMessage] JOINED/REJECTED self", message, message.from.resource, this.userJID.resource, conversationTarget);
              }
            }
          });
        });
      }
    }
    if (!environment.isCordova) {
      this.logger.info("[ConferenceRepository][navigator.userAgent]", navigator.userAgent);
      this.startListeningForDeviceChanges();
    }
  }

  public checkRouteChanged(conversationTarget: string): boolean {
    let routeChanged: boolean = false;
    this.conversationRepo.getSelectedConversation().pipe(take(1)).subscribe(conv => {
      if (!conv || (conv && conv.Target !== conversationTarget)) {
        routeChanged = true;
      }
    });
    return routeChanged;
  }

  public getOrCreateConversation(bareJID: string, skipAddUserToChatWhenAddToCall?: boolean) {
    this.broadcaster.broadcast("closeDialog");
    this.broadcaster.broadcast(ConstantsUtil.CLOSE_SIDEBAR);

    const activeTab = CommonUtil.isBroadcast(bareJID) ? "broadcast" : "chat";
    this.store.dispatch(new SetActiveTab(activeTab));

    if (skipAddUserToChatWhenAddToCall) {
      this.conversationRepo.navigateToTempConversation(bareJID);
    } else {
      this.conversationRepo.navigateToConversation(bareJID);
    }
  }

  public startCall(conversationTarget: string, type: string): void {
    this.startConference(conversationTarget, type, true);

    if (document.getElementById("mainLayout") !== null) {
      document.getElementById("mainLayout").classList.add("hide-header-mobile");
    }
  }

  getTextChatState() {
    return this.hideTextChatStatus$.asObservable();
  }

  hideTextChat(value) {
    this.hideTextChatStatus$.next(value);
  }

  hideVideoTracks(value) {
    this.hideVideoTracks$.next(value);
  }

  toggleHideVideoIOS(value) {
    if (CommonUtil.isOnIOS() || CommonUtil.isOnIpad()) {
      this.logger.info("[toggleHideVideoIOS]", value);
      this.hideAllVideo(value);
    }
  }

  hideAllVideo(value) {
    this.hideAllVideo$.next(value);
  }

  toggleVCDisplay(value) {
    this.isDisplayed$.next(value);
  }

  isVCDisplay() {
    return this.isDisplayed$.asObservable();
  }

  setWhiteboardStatus(value) {
    if (!value) {
      this.resetWhiteboard();
    }
  }

  setWhiteboardMode(value) {
  }

  getWhiteboardMode() {
  }

  getWhiteboardData() {
  }

  getWhiteboardStatus() {
  }

  loadIframe(iframeName, url) {
  }

  // TODO: replace to redux
  setConferenceView(view: string) {
    this.logger.info("[ConferenceRepository][setConferenceView]", view);

    this.store.dispatch(new SetCurrentView(view));
  }

  getConferenceView(): Observable<string>  {
    const response = new BehaviorSubject<string>(null);

    this.logger.info("[ConferenceRepository][getCurrentView]", CommonUtil.isOnIpad() || environment.isCordova || CommonUtil.isOnMobileDevice());

    if (CommonUtil.isOnIpad() || environment.isCordova || CommonUtil.isOnMobileDevice()) {
      const view = "speaker";

      response.next(view);

      this.store.select(getCurrentView).pipe(take(1)).subscribe(currentView => {
        this.logger.info("[ConferenceRepository][getCurrentView]1", currentView);
        if (currentView !== view) {
          this.setConferenceView(view);
        }
      });
    } else {
      this.store.select(getCurrentView).pipe(take(1)).subscribe(view => {
        this.logger.info("[ConferenceRepository][getCurrentView]2", view);
        if (!view) {
          // initial value
          if (CommonUtil.isOnIpad() || environment.isCordova || CommonUtil.isOnMobileDevice()) {
            view = "speaker";
          } else {
            view = "tile";
          }

          this.setConferenceView(view);
        }

        response.next(view);
      });
    }

    return response.asObservable();
  }

  private showIncomingCallNotificationAndSetInitiator(incomingCallSignal, jitsiRoom, senderBare, conversationTarget) {
    this.logger.info("[ConferenceRepository][showIncomingCallNotificationAndSetInitiator]", jitsiRoom, conversationTarget, this.lastIncomingConversationTarget);
    if (this.checkIfGloballyMuted()) {
      this.logger.info("[RootComponent][showIncomingCallNotificationAndSetInitiator][checkIfGloballyMuted] do not display notification");
      return;
    }
    this.lastIncomingConversationTarget = conversationTarget;
    this.handleIncomingCall(incomingCallSignal);

    this.setupCallRequestTimeoutTimer(incomingCallSignal);
    if (!this.getCallInitiator(conversationTarget)) {
      this.setCallInitiator(senderBare, conversationTarget);
    }
  }

  checkIfGloballyMuted(): boolean {
    let isGloballyMuted = false;
    this.store.select(getGlobalMute)
      .pipe(take(1))
      .subscribe(mute => {
        isGloballyMuted = mute;
      });
    return isGloballyMuted;
  }

  checkIfMuteEverything(target): boolean {
    let isMuted = false;
    this.conversationRepo.getNotificationConfig(target).pipe(take(1)).subscribe(v => {
      isMuted = v?.muteEverything;
    });
    return isMuted;
  }

  checkIfMuteIncomingCall(target): boolean {
    let isMuted = false;
    this.conversationRepo.getConversationById(target).pipe(take(1)).subscribe(v => {
      this.logger.info("conferenceRepo checkIfMuteIncomingCall: ", v);
      isMuted = (v?.mute_notification & 4) === 4;
    });
    return isMuted;
  }

  checkIfMuteSound(target): boolean {
    let isMuted = false;
    this.conversationRepo.getConversationById(target).pipe(take(1)).subscribe(v => {
      this.logger.info("conferenceRepo  checkIfMuteSound: ", v);
      isMuted = v?.mute_sound === 2;
    });
    return isMuted;
  }

  checkIfMuteNotification(target): boolean {
    let isMuted = false;
    this.conversationRepo.getConversationById(target).pipe(take(1)).subscribe(v => {
      isMuted = (v.mute_notification & 2) === 2;
    });
    return isMuted;
  }

  private handleIncomingCall(incoming) {
    this.logger.info("[ConferenceRepository][handleIncomingCall]");

    const conferenceId = incoming && incoming.conferenceId;
    const conferenceType = incoming && incoming.conferenceType;
    const jitsiRoom = incoming && incoming.jitsiRoom;
    const senderBare = incoming.from;
    const conversationTarget = conferenceId.indexOf("@") !== -1 ? conferenceId : senderBare;
    if (this.checkIfGloballyMuted() || this.checkIfMuteIncomingCall(conversationTarget)) {
      this.logger.info("[ConferenceRepository][handleIncomingCall] this.checkIfGloballyMuted ", this.checkIfGloballyMuted());
      this.logger.info("[ConferenceRepository][handleIncomingCall] this.checkIfMuteIncomingCall(conversationTarget) ", this.checkIfMuteIncomingCall(conversationTarget));
      return;
    }
    // play incoming call signal
    //
    this.store.select(getJitsiRoom).pipe(take(1)).subscribe(() => {
      this.store.select(state => getConversationById(state, conversationTarget))
        .pipe(take(1)).subscribe(() => {
          // todo: skipHereIfactive
          let lsStartConferenceTarget = localStorage.getItem("startConferenceTarget");

          this.logger.info("ssaCall this.activeConferenceTarget: ", this.activeConferenceTarget, conversationTarget, lsStartConferenceTarget, conferenceId, this.getConferenceKey());
          if ((!!this.activeConferenceTarget && (conversationTarget === this.activeConferenceTarget)) || (conversationTarget === lsStartConferenceTarget)) {
            this.logger.info("skip play incoming as already active");
          } else {
            if (!this.checkIfMuteSound(conversationTarget)) {
              this.playIncomingCall();
            }
            if (environment.isCordova && CommonUtil.isOnAndroid()) {
              window.plugins.ringerMode.getRingerMode(res => {
                this.logger.info("[ConferenceRepository][playIncomingCall] ringerMode result: " + res);
                if (res === "RINGER_MODE_VIBRATE" && navigator.vibrate) {
                  navigator.vibrate(3000);
                }
              }, error => {
                this.logger.error("[ConferenceRepository][playIncomingCall] ringerMode error: " + error);
              });
            }
          }
      });
    });

    // call
    if (conversationTarget !== "whiteboard") {
      let showInvitation = true;
      this.store.select(getJitsiRoom).pipe(take(1)).subscribe(room => {
        this.logger.info("[ConferenceRepository][handleIncomingCall] getJitsiRoom", room, incoming );
        if (room === incoming.jitsiRoom) {
          showInvitation = false;
          this.logger.info("[ConferenceRepository][handleIncomingCall] do not show invitation");
        }
      });
      if (!showInvitation) return;

      // incoming.id = senderBare + "call"; // To prevent duplicate incoming call
      incoming.id = conferenceId + "call" ; // To prevent duplicate incoming call
      this.logger.info("[ConferenceRepository][handleIncomingCall] show invitationIncoming", incoming);
      this.logger.info("[ConferenceRepository][handleIncomingCall] show invitation", conferenceType, conversationTarget, jitsiRoom,
      conversationTarget.split("/")[0], incoming );
      if (conferenceType === "audio") {
        this.notificationsService.audioCall(conversationTarget, incoming.jitsiRoom,
          conversationTarget.split("/")[0], incoming);
      } else if (conferenceType === "video") {
        this.notificationsService.videoCall(conversationTarget, incoming.jitsiRoom,
          conversationTarget.split("/")[0], incoming);
      } else if (conferenceType === "screen") {
        this.notificationsService.screenShare(conversationTarget, incoming.jitsiRoom,
          conversationTarget.split("/")[0], incoming);
      }
      this.broadcaster.broadcast("newInvitation", conversationTarget);
    // whiteboard
    } else {
      this.store.select(getActiveWhiteboard).pipe(take(1)).subscribe(whiteboard => {
        if (whiteboard && whiteboard.conversationTarget === conversationTarget) {
          return;
        }
        this.notificationsService.whiteBoard(conversationTarget, jitsiRoom, conversationTarget, incoming);
      });
    }
  }

  private handleCallSignalInBackground(msgid, eventType, senderBare, conversationTarget, conferenceType, jitsiRoom: string, jitsiURL: string){
    this.logger.info("[ConferenceRepository][handleCallSignalInBackground]", msgid, eventType, senderBare, conversationTarget, conferenceType, jitsiRoom, jitsiURL);
    if (conferenceType === "whiteboard" || this.checkIfGloballyMuted()) {
      return;
    }

    // if in background - forward the incoming call signal to FCM plugin
    let username;
    this.contactRepo.getContactVCard(senderBare).pipe(take(1)).subscribe(vCard => {
      username = vCard && vCard.fullName;
      if (username == null || username === ""){
        username = this.contactRepo.getPrettyUsername(senderBare);
      }
    });

    let groupName;
    this.store.select(state => getConversationById(state, conversationTarget)).pipe(take(1)).subscribe(conv => {
      groupName = conv.groupChatTitle || "";
    });

    if (eventType === "invite" && CommonUtil.isOnAndroid()) {
      // Need to call it to bypass lock screen when accepted a call.
      // We should back this property to 'false' once call is finished.
      if (window.FirebasePlugin && window.FirebasePlugin.enableLockScreenVisibility) {
        this.logger.info("[RootComponent][getActiveConference] enableLockScreenVisibility true");
        window.FirebasePlugin.enableLockScreenVisibility(true);

        localStorage.setItem("enabledLockScreenVisibility", "YES");
      }
      this.logger.info("[conferenceRepository][getIsAppOnlineX][handleCallSignalInBackground] conversationTarget: ", conversationTarget);
      this.lastIncomingConversationTarget = conversationTarget;
    }

    this.notificationsService.scheduleCallNotification(msgid, eventType, conversationTarget, username, groupName, conferenceType, this.userJID?.bare, senderBare, jitsiRoom, jitsiURL);
  }

  private isCallAlreadyProcessedByiOSFCMPlugin(mid: string): Observable<boolean> {
    const response = new Subject<boolean>();

    plugins.appPreferences.fetch((data) => {
      if (data) {
        const res = data.includes(mid);
        this.logger.info("[ConferenceRepository][isCallAlreadyProcessedByiOSFCMPlugin] success1", data, mid, res);

        response.next(res);
      } else {
        response.next(false);
      }
    }, () => {
      this.logger.error("[ConferenceRepository][isCallAlreadyProcessedByiOSFCMPlugin] failure");
      response.next(false);
    }, "processedCallsIds");

    return response.asObservable().pipe(take(1));
  }

  private cleanupCallAlreadyProcessedByiOSFCMPluginData() {
    // TODO:
    // as we store an array of processed messages ids,
    // then a better way will be not to cleanup everything,
    // but just to remove a particular message id
    plugins.appPreferences.remove(() => {
      this.logger.info("[ConferenceRepository][cleanupCallAlreadyProcessedByiOSFCMPluginData] cleanup success");
    }, () => {
      this.logger.error("[ConferenceRepository][cleanupCallAlreadyProcessedByiOSFCMPluginData] cleanup failure");
    }, "processedCallsIds");
  }

  deviceReady() {
    this.logger.info("[ConferenceRepository][deviceReady]");
    this.updateDevicesList();
  }

  startConference(conversationTarget: string, conferenceType: string, startFromInvitation?: boolean, invitedParticipants?: JID[], externalParticipants?: any[]) {
    // availableMediaDevicesSet
    if (!this.availableMediaDevicesSet) {
      this.logger.info("delayedstart - waiting for availableMediaDevicesSet");
      setTimeout(() => {
        this.startConference(conversationTarget, conferenceType, startFromInvitation, invitedParticipants, externalParticipants);
      }, 200);
    } else {
      this.logger.info("[ConferenceRepository][startConference]", conversationTarget, conferenceType, invitedParticipants, startFromInvitation);
      this._startConference(conversationTarget, conferenceType, startFromInvitation, invitedParticipants, externalParticipants, false);
    }
  }

  private joinConferenceViaNotification(conversationTarget: string, conferenceType: string, initiatorJid: string, jitsiRoom: string, jitsiURL: string) {

    this.logger.info("[ConferenceRepository][joinConferenceViaNotification1]", jitsiRoom, jitsiURL);

    if (jitsiRoom && jitsiURL) {
      const resOption: JitsiOption = {
        value: jitsiRoom,
        jitsiurl: jitsiURL
      };
      this.setJitsiRoom(resOption);
    }

    this.logger.info("[ConferenceRepository][startConferenceFromPush]", conversationTarget, conferenceType);
    setTimeout(() => {

      this._startConference(conversationTarget, conferenceType, true, null, null, true);
    }, 200);

    // if (initiatorJid) {
    //   this.setCallInitiator(initiatorJid, conversationTarget);
    // }
  }

  private _startConference(conversationTarget: string, conferenceType: string, startFromInvitation?: boolean, invitedParticipants?: JID[], externalParticipants?: any[], joinViaNotification?: boolean) {
    this.kickedParticipants = [];
    let hasWebcam = false;
    this.hasWebcam().pipe(take(1)).subscribe(res => {
      hasWebcam = res;
    });

    this.logger.info("[ConferenceRepository][_startConference]", hasWebcam, conversationTarget, conferenceType, startFromInvitation, invitedParticipants, externalParticipants, joinViaNotification);

    // send self-join to stop ringing if 2 clients started call with each other nearly simultanously
    setTimeout(() => {
      this.sendSelfAcceptCallSignal(this.getConferenceKey(), conferenceType);
    }, 5000);

    if (conferenceType === "video" && !hasWebcam) {
      conferenceType = "audio";
    }
    // TODO: do it better, not via local storage
    localStorage.setItem(`startingCallTime`, new Date().getTime().toString());
    this.store.dispatch(new ChangeLayout(ScreenViews.TILES));
    // save actve conf to redux. This will produce 'conferenceRepo.getActiveConference()' call
    this.store.dispatch(new ResetConferenceStartRequest());
    this.store.dispatch(new ConferenceStartRequest({
      conversationTarget,
      startFromInvitation,
      conferenceType,
      invitedParticipants,
      externalParticipants
    }));
    this.stopPlayIncomingCall();
    this.invalidateCallRequestTimeoutTimer();

    this.totalRejectedParticipantsInExistingCall = 0;
  }

  startAnonymousConference(participantEmail: string, jitsiRoom: string) {
    this.logger.info("[conferenceRepository][startAnonymousConference] env: ", environment);
    if (!environment.isElectron) {
      this.store.dispatch(new XmppSession({bare: participantEmail}));
    } else {
      this.anonymousConferenceJitsiRoom = jitsiRoom;
    }
    this.store.dispatch(new AnonymousConference({
      participantEmail, jitsiRoom: {value: jitsiRoom}
    }));

  }

  // TODO: move everything to 'hangupCallIfActive'
  hangup(conferenceTarget: string) {

    const callInititator = this.getCallInitiator(conferenceTarget);
    this.logger.info("[ConferenceRepository][hangup] -> notifyCallEnded", conferenceTarget, this.userJID?.bare, callInititator);

    this.broadcaster.broadcast("notifyCallEnded");

    if (!CommonUtil.isOnIpad() && !environment.isCordova) {
      this.setConferenceView("tile");
    }
    this.setPresenterParticipant(null);
    this.store.dispatch(new ResetScreenSharingData());
    this.store.dispatch(new UpdateSelectedLayout(ScreenViews.TILES));
    this.totalRejectedParticipantsInExistingCall = 0;

    this.stopPlayCalling();
    this.stopPlayIncomingCall();

    this.invalidateCallRequestTimeoutTimer();

    // if (this.userJID?.bare === callInititator) {
    //  this. deleteJitsiRoomIfRequired(conferenceTarget);
    // }
  }

  deleteJitsiRoomIfRequired(conferenceTarget) {
    if (CommonUtil.isGroupTarged(conferenceTarget)) {
      this.logger.info("[deleteJitsiRoomIfRequired] room: ", conferenceTarget);

      this.store.select(getConferenceParticipants).pipe(take(1)).subscribe(participants => {
        this.logger.info("[deleteJitsiRoomIfRequired]", participants);
        const requireJitsiAuth = this.configService.get("requireJitsiAuth");
        if (participants && participants.length > 0 && participants.length < 3 && !requireJitsiAuth) {
          this.deleteJitsiRoom(conferenceTarget).subscribe(res => {
            this.logger.info("[ConferenceRepository][hangup] deleteJitsiRoom res", res);
          });
        }
      });

    }

  }

  // TODO: to make all components to call this joint method to finish the call
  hangupCallIfActive() {
    let isActiveCall = false;
    this.getActiveConference().pipe(take(1)).subscribe(conversationTarget => {
      if (conversationTarget) {
        isActiveCall = true;
        const cInititator = this.getCallInitiator(conversationTarget);
        this.store.select(getInvitedParticipants).pipe(take(1)).subscribe((invitedParticipants: any) => {
          this.logger.info("[ConferenceRepository][hangupCallIfActive] conversationTarget " + conversationTarget + " initiator: ", cInititator, invitedParticipants);
          if ((this.userJID?.bare === cInititator) && (!!invitedParticipants && (invitedParticipants.length === 0))) {
            this.logger.info("[ConferenceRepository][hangupCallIfActive] skipp hangup - initiator and no invitees => keep call running");
            this.store.select(getConferenceParticipants).pipe(take(1)).subscribe((participants: any) => {
              this.logger.info("[ConferenceRepository][hangupCallIfActive] getConferenceParticipants", participants);
              if (!!participants && participants.length < 2) {
                this.hangupCall();
              }
            });
          } else {
            this.hangupCall();
          }
        });
      }
    });
    this.logger.info("[ConferenceRepository][hangupCallIfActive]", isActiveCall);

    return isActiveCall;
  }
  //
  hangupCall(fromReJoin?:boolean) {

    this.stopPlayCalling();

    this.notificationsService.remove(null, "active");

    this.resetStreamId();

    this.hideActiveCall();
    if (!fromReJoin) {
      this.logger.info("[ConferenceRepository][hangupCall] !fromRejoin ->  notifyCallEnded");
      this.broadcaster.broadcast("notifyCallEnded");
    } else {
      }
    this.leaveConference(fromReJoin);
    this.leaveJitsiConference();
  }

  loadJitsiConfig(jitsiUrl: string, jitsiRoomId: string): Observable<any> {
    this.store.dispatch(new StartLoadJitsiConfig());

    const response = new Subject<any>();

    let currentJitsiURL = this.configService.get("jitsiURL");
    // for support of multiple shards, the URL query param room= is used.
    // without this param we get a config for a random shard - not the config for the actual shard.
    // hence we reload config before starting a call
    // if no sharding is used, the query param is simply ignored.

    if (!!jitsiRoomId) {
      currentJitsiURL = "invalid";
    }

    this.logger.info("[ConferenceRepository][loadJitsiConfig]", jitsiUrl, currentJitsiURL);
    this.configService.getJitsiConfig(jitsiUrl, jitsiRoomId).subscribe(jitsiConfig => {
      this.logger.info("[ConferenceRepository][loadJitsiConfig] res", jitsiConfig);
      this.store.dispatch(new UpdateJitsiConfig(jitsiConfig));

      this.store.dispatch(new StopLoadJitsiConfig());

      response.next(jitsiConfig);
    }, () => {
      this.store.dispatch(new StopLoadJitsiConfig());
    });

    return response.asObservable().pipe(take(1));
  }


  showActiveCall() {
    this.store.dispatch(new ShowActiveCall());
  }

  hideActiveCall() {
    this.store.dispatch(new HideActiveCall());
  }

  startWhiteboard(conversationTarget: string) {
    this.store.dispatch(new ConferenceSetActiveWhiteboard({conversationTarget}));
  }

  resetWhiteboard() {
    this.store.dispatch(new ConferenceSetActiveWhiteboard(null));
  }

  setFullScreenParticipant(fullScreenParticipantId: string) {
    this.store.dispatch(new ConferenceSetFullScreenParticipant(fullScreenParticipantId));
  }

  setJitsiRoom(option: JitsiOption) {
    this.store.dispatch(new SetJitsiRoomId(option));
  }

  setConferenceKey(key: string) {
    this.store.dispatch(new SetConferenceId(key));
  }

  setConversationTarget(conversationTarget: string) {
    this.store.dispatch(new SetConversationTarget(conversationTarget));
  }

  setStreamId(streamId: string) {
    this.store.dispatch(new SetStreamId(streamId));
  }

  detectAndSetStreamIdForScreenShare() {
    const isFirefox = /firefox/i.test(navigator.userAgent.toLowerCase());
    const isChrome = /chrome/i.test(navigator.userAgent.toLowerCase());
    const isSafari = /safari/i.test(navigator.userAgent.toLowerCase());

    let streamIdScreenShare: string;
    if (isChrome) {
      streamIdScreenShare = "chrome";
    } else if (isFirefox) {
      streamIdScreenShare = "firefox";
    } else if (isSafari) {
      streamIdScreenShare = "safari";
    } else {
      throw "Unedfined streamIdScreenShare";
    }

    this.logger.info("[ChatWindowConference][detectAndSetStreamIdForScreenShare]", streamIdScreenShare);
    // skip - as setting a streamId leads to wrong state re screenshare and
    // cam buttons - especially when cancelling from select source dialog
    // if really required to set this then only do on success - but this
    // was called too  often causing wrong states of buttons displayed
    // this.setStreamId(streamIdScreenShare);

    return streamIdScreenShare;
  }

  leaveOldConference(): void {

  }

  leaveConference(fromReJoin?:boolean) {
    this.invitedParticipants$.next([]);
    this.getActiveConference().pipe(take(1)).subscribe(conferenceTarget => {
      this.logger.info("[conference.repository][joincallbutton] skip removing the flag");
      // this.store.dispatch(new UpdateConversationCallFlag({target: conferenceTarget, flag: false}));
      const isMeeting = CommonUtil.isVideoMeeting(conferenceTarget);
      this.logger.info("[ConferenceRepository][leaveConference] is meeting ?", isMeeting);
      const callInititator = this.getCallInitiator(conferenceTarget);
      this.logger.info("[ConferenceRepository][leaveConference]", conferenceTarget, callInititator);
      this.store.select(getConferenceParticipants).pipe(take(1)).subscribe(participants => {
        this.getConferenceType().pipe(take(1)).subscribe(type => {
          this.logger.info("[ConferenceRepository][leaveConference] ended?", type, conferenceTarget, callInititator, participants);
          if (!!type) {
            if (((participants.length < 3) && (!type.startsWith("screen"))) || ((participants.length < 1) && (type.startsWith("screen"))))  {
              if (!fromReJoin) {
                this.logger.info("[ConferenceRepository][leaveConference] participants sendEndedCallSignal", participants);
                this.sendEndedCallSignal(conferenceTarget);
              }
            }
          }
        });
      });
      const requireJitsiAuth = this.configService.get("requireJitsiAuth");
      if ((this.userJID?.bare === callInititator) && !fromReJoin) {
        if (!requireJitsiAuth) {
          this.deleteJitsiRoomIfRequired(conferenceTarget);
        }
      } else {
        if (CommonUtil.isVideoMeeting(conferenceTarget)) {
          this.store.select(state => getConversationOwner(state, conferenceTarget)).pipe(take(1)).subscribe(owner => {
            this.logger.info("[ConferenceRepository][leaveConference] 3 ", conferenceTarget, owner);
            // once we enforece jitsi auth there is no need to delete the room!
            if (!requireJitsiAuth) {
              if ((owner && owner === this.userJID?.bare) && !fromReJoin) {
                this.deleteJitsiRoomIfRequired(conferenceTarget);
              }
            }
          });
        }
      }
    });

    this.stopPlayCalling();

    this.store.dispatch(new ConferenceLeaveSuccess());
  }

  leaveJitsiConference() {
    this.logger.info("[ConferenceRepository][leaveJitsiConference]");
    this.setNoiseSuppressionEnabled(false);
  }

  resetStreamId() {
    this.logger.info("[ConferenceRepository][resetStreamId]");

    this.store.dispatch(new SetStreamId(""));
  }

  getParticipants(): Observable<JitsiParticipant[]> {
    return this.store.select(getConferenceParticipants);
  }

  getCallParticipants(target: string, isRunningCall?: boolean): Observable<any[]> {
    const subject = new Subject<any[]>();
    let callParticipants = [];
    if (isRunningCall) {
      this.getParticipants().pipe(take(1)).subscribe(participants => {
        callParticipants = participants.map(v => {
          let jid = v.name;
          if (jid === "ME") {
            jid = this.userJID.bare;
          }
          return {
            id: v.id,
            fullName: this.contactRepo.getFullName(jid),
            jid: jid
          };
        });
      });
      subject.next(callParticipants);
    } else {
      this.conversationRepo.getCallParticipants(target).pipe(take(1)).subscribe(res => {
        if (res && Array.isArray(res)) {
          const participants = res.map(v => {
            return {
              id: v.jid.split("/")[1],
              fullName: this.contactRepo.getFullName(v.display_name),
              jid: v.display_name,
              role: v.role
            };
          });
          callParticipants = participants;
        }
        subject.next(callParticipants);
      });
    }
    return subject.asObservable().pipe(take(1));
  }

  getConferenceParticipantsNotME() {
  }

  getActiveConference(): Observable<string> {
    return this.store.select(getActiveConference);
  }

  getKnockingParticipants(): Observable<any[]> {
    return this.store.select(getKnockingParticipants);
  }

  getLobbyState(): Observable<any> {
    return this.store.select(getLobbyState);
  }

  getUploadedBackground(): Observable<any> {
    return this.store.select(getUploadedBackground);
  }

  getVirtualBackground(): Observable<any> {
    return this.store.select(getVirtualBackground);
  }

  isAudioMuted(): Observable<boolean> {
    return this.store.select(getIsConferenceAudioMuted);
  }

  getHasActiveCall(): Observable<boolean> {
    return this.store.select(getHasActiveCall);
  }

  isVideoMuted(): Observable<boolean> {
    return this.store.select(getIsConferenceVideoMuted);
  }

  isScreenSharingOn(): Observable<boolean> {
    return this.store.select(getIsConferenceScreenSharingOn);
  }

  hasWebcam(): Observable<boolean> {
    return this.store.select(getHasWebcam);
  }

  hasMicrophone(): Observable<boolean> {
    return this.store.select(getHasMicrophone);
  }

  hasSpeaker(): Observable<boolean> {
    return this.store.select(getHasSpeaker);
  }

  getFrontCameraId(): Observable<string> {
    return this.store.select(getFrontCameraId);
  }

  getBackCameraId(): Observable<string> {
    return this.store.select(getBackCameraId);
  }

  getFullScreenParticipantId(): Observable<string> {
    return this.store.select(getFullScreenParticipantId);
  }

  getSelectedParticipantId(): Observable<string> {
    return this.store.select(getSelectedParticipantId);
  }

  getConferenceId(): Observable<string> {
    return this.store.select(getConferenceId);
  }

  getJitsiRoomId(): Observable<JitsiOption> {
    return this.store.select(getJitsiRoom);
  }

  getStreamId(): Observable<string> {
    return this.store.select(getStreamId);
  }

  getConferenceType(): Observable<string> {
    return this.store.select(getConferenceType);
  }

  getScreenSharingRequestStatus(): Observable<boolean> {
    return this.store.select(getScreenSharingRequestStatus);
  }

  getInvitationStatus(): Observable<boolean> {
    return this.store.select(getInvitationStatus);
  }

  private setCallInitiator(callInitiator, confTarget) {
    this.logger.info("[ConferenceRepository][setCallInitiator]", callInitiator, confTarget);
    localStorage.setItem(this.keyStorageCallInitiator(confTarget), callInitiator);
  }

  private getCallInitiator(confTarget) {
    const callInitiator = localStorage.getItem(this.keyStorageCallInitiator(confTarget));
    this.logger.info("[ConferenceRepository][getCallInitiator]", callInitiator, confTarget);
    return callInitiator;
  }

  clearCallInitiator(confTarget) {
    this.logger.info("[clearCallInitiator]", confTarget, this.getCallInitiator(confTarget));
    localStorage.removeItem(this.keyStorageCallInitiator(confTarget));
  }

  private keyStorageCallInitiator(confTarget) {
    return `callInitiator_${confTarget}`;
  }

  muteMicrophone(isMuted: boolean){
    if (isMuted) {
      this.store.dispatch(new ConferenceMuteAudio());
    } else {
      this.store.dispatch(new ConferenceUnMuteAudio());
    }
  }

  muteAudio() {
    this.store.dispatch(new ConferenceMuteAudio());
    this.broadcaster.broadcast("notifyMicMuted", true);
  }

  resetScreenSharingData() {
    this.store.dispatch(new ResetScreenSharingData());
  }

  unmuteAudio() {
    this.store.dispatch(new ConferenceUnMuteAudio());
    this.broadcaster.broadcast("notifyMicMuted", false);
  }

  turnOffVideo() {
    this.muteVideo();
  }

  muteVideo() {
    this.store.dispatch(new ConferenceMuteVideo());
  }

  turnONwhiteboard() {
    this.fullPreviewParticipant$.next(null);
  }

  onWhiteboardOpen() {
  }

  getRaisedHandList() {
  }

  raiseMyHand() {
  }

  turnOnVideo() {
    if (status) {
      this.unmuteVideo();
    }
    return status;
  }

  unmuteVideo() {
    this.store.dispatch(new ConferenceUnMuteVideo());
  }

  shareScreen(screenOnly?: boolean) {
    this.logger.info("[ConferenceRepository][shareScreen]", screenOnly);

    this.startScreenShare();
  }

  private startScreenShare() {
    this.store.dispatch(new ConferenceShareScreen());
  }

  unshareScreenAndStartNewStream() {
    this.logger.info("[unshareScreenAndStartNewStream]");
  }

  private stopScreenShare() {
    this.store.dispatch(new ConferenceUnShareScreen());
  }

  resaveAVSettings(audioOnly?: boolean) {
    this.logger.info("[ConferenceRepository] resaveAVSettings", audioOnly);
  }

  saveAVPreferencesAndChangeMediaDevices(
                currentVideoInputDeviceLabel: string,
                currentAudioInputDeviceLabel: string,
                currentAudioOutputDeviceLabel: string,
                availableMediaDevices: any,
                audioOnly?: boolean) {

    this.logger.info("[ConferenceRepository][saveAVPreferencesAndChangeMediaDevices]", audioOnly, { currentVideoInputDeviceLabel, currentAudioInputDeviceLabel, currentAudioOutputDeviceLabel , availableMediaDevices});

    // save prefs
    this.saveMediaDevicesPreferences(CommonUtil.getDeviceId(), currentVideoInputDeviceLabel, currentAudioInputDeviceLabel, currentAudioOutputDeviceLabel);

  }

  addParticipant(participant: JitsiParticipant) {
    this.store.dispatch(new ConferenceAddParticipant(participant));
    // sct
    this.store.dispatch(new JitsiConferenceAddParticipant(participant));
  }

  removeParticipant(participantId: string) {
    this.store.dispatch(new ConferenceRemoveParticipant(participantId));
    // sct
    this.store.dispatch(new JitsiConferenceRemoveParticipant(participantId));
  }

  selectParticipant(participantId: string) {
    this.store.dispatch(new ConferenceSelectParticipant(participantId));
  }

  public deleteJitsiRoom(confKey: string): Observable<any> {
    return this.middlewareService.deleteJitsiRoom(confKey);
  }

  public checkActiveConference(roomId: string): Observable<any> {
    return this.middlewareService.checkActiveConference(roomId);
  }

  public checkJoinableConference(roomId: string, iomDomain?: string, sentBy?: string): Observable<any> {
    return this.middlewareService.checkJoinableConference(roomId, iomDomain, sentBy);
  }

  public getJitsiRoom(confKey: string): Observable<any> {
    this.logger.info("[ConferenceRepository][getJitsiRoom]", confKey);
    return this.middlewareService.getJitsiRoom(confKey);
  }

  public joinMCBCallFlowFromNotification(roomId, iomDomain, sentBy) {
    const subject = new Subject<boolean>();
    this.checkJoinableConference(roomId, iomDomain, sentBy).subscribe(res => {
      if (!!res && res.joinable) {
        subject.next(true);
      } else {
        subject.next(false);
      }
    }, () => {
      subject.next(false);
    });
    return subject.asObservable().pipe(take(1));
  }

  public createJitsiRoom(confKey: string, jitsiRoomId: string, jitsiURL?: string): Observable<any> {
    this.logger.info("[ConferenceRepository][createJitsiRoom]", confKey, jitsiRoomId, jitsiURL);
    return this.middlewareService.createJitsiRoom(confKey, jitsiRoomId, jitsiURL);
  }

  public sendLeaveCallSignal(conferenceType?: string, target?: string) {
    if (!this.selectedConversation) {
      this.logger.warn("[ConferenceRepository][sendCancelSignal] skip, no selectedConversation");
      return;
    }

    this.logger.info("[ConferenceRepository][sendCancelSignal]");

    if (!conferenceType) {
      conferenceType = "audio";
      this.getConferenceType().pipe(take(1)).subscribe(type => {
        if (type) {
          conferenceType = type;
        }
      });
    }

    let conferenceKey: string;
    this.getConferenceId().pipe(take(1)).subscribe(key => {
      conferenceKey = key;
    });

    let jitsiOption: JitsiOption;
    this.getJitsiRoomId().pipe(take(1)).subscribe(res => {
      jitsiOption = res;
    });

    const leaveCallMessage = this.buildCallSignalMessage(target || this.selectedConversation?.Target, jitsiOption, conferenceType, "leave", conferenceKey);
    const signalMessage = this.sendCallSignal(target || this.selectedConversation?.Target, leaveCallMessage);

    this.conversationRepo.processAndStoreCallMessage(signalMessage);
    if (conferenceType === "screen") {
      this.logger.info("going to hangup for conversation: ", this.selectedConversation);
      this.sendEndedCallSignal(target || this.selectedConversation?.Target);
    }
  }

  public sendCallSignal(to: string, message: any, isAddParticipant?: boolean): Message {
    if (!this.shouldSendCallSignal(to)) {
      this.logger.warn("[ConferenceRepository][sendCallSignal] skip because of external user (not known IOM domain or email)", to);
      if (message.vncTalkConference.eventType === "invite") {
        return;
      }
    }

    this.logger.info("[ConferenceRepository][sendCallSignal]", message.vncTalkConference && message.vncTalkConference.eventType, to, message, isAddParticipant);
    const signalMessage = this.xmppService.sendMessage(to, message);
    // save to chat history
    this.conversationRepo.processAndStoreCallMessage(signalMessage);
    if (message.vncTalkConference) {
      if (message.vncTalkConference.eventType === "invite") {
        this.invitee = to;
        if (!isAddParticipant) {
          this.setupCallRequestTimeoutTimer();
        }
      } else if (message.vncTalkConference.eventType === "reject") {
        this.reject(to);
        this.invalidateCallRequestTimeoutTimer();
      } else if (message.vncTalkConference.eventType === "join") {
        this.invalidateCallRequestTimeoutTimer();
      }
    }

    return signalMessage;
  }

  public rejectCallAction(to: string, conferenceId: string, conferenceType: string, jitsiOption: JitsiOption) {
    let callSignal: Message = this.buildCallSignalMessage(to, jitsiOption, conferenceType, "reject", conferenceId);

    // send reject signal
    const signalMessage = this.sendCallSignal(to, callSignal);

    // and save to chat history
    this.conversationRepo.processAndStoreCallMessage(signalMessage);

    // send self-reject call signal
    this.sendSelfRejectCallSignal(conferenceId, conferenceType);
  }

  public acceptCallAction(to: string, conferenceId: string, conferenceType: string, jitsiOption: JitsiOption, timestamp: any) {
    let callSignal: Message = this.buildCallSignalMessage(to, jitsiOption, conferenceType, "join", conferenceId);

    if (timestamp) {
      localStorage.setItem("startingCallTime", callSignal.timestamp + "");
    }

    // send join signal
    const signalMessage = this.sendCallSignal(to, callSignal);

    // and save to chat history
    this.conversationRepo.processAndStoreCallMessage(signalMessage);

    // send self-join call signal
    this.sendSelfAcceptCallSignal(conferenceId, conferenceType);
  }

  sendMissCallSignal(target, jids, conferenceId?: any) {
    let message = {
      body: "Missed call",
      type: CommonUtil.isGroupTarged(target) ? "groupchat" : "chat",
      group_action: {
        type: JSON.stringify({reason: "MISSED_CALL", jids: jids})
      }
    };
    if (!!conferenceId) {
      message["vncTalkConference"] = {
        conferenceId: conferenceId,
        conferenceType: "audio",
        eventType: "missed",
        from: this.userJID.bare,
        timestamp: Math.floor(Date.now() / 1000),
        to: target,
        xmlns: "xmpp:vnctalk"
      };
    }
    this.xmppService.sendMessage(target, message);
    this.logger.info("[sendMissCallSignal]", message);
    this.invitedParticipants$.next(this.invitedParticipants$.value.filter(v => !jids.includes(v.jid)));
  }

  sendInitiatorLeftSignal(target) {
    this.logger.info("[sendInitiatorLeftSignal]", target);
    const message = {
      body: "",
      type: CommonUtil.isGroupTarged(target) ? "groupchat" : "normal",
      group_action: {
        type: "INITIATOR_LEFT"
      }
    };
    this.xmppService.sendMessage(target, message);
  }

  sendEndedCallSignal(target) {
    this.logger.info("[sendEndedCallSignal]", target);
    const message = {
      body: "",
      group_action: {
        type: "ENDED_CALL"
      },
      type: CommonUtil.isGroupTarged(target) ? "groupchat" : "normal"
    };
    this.xmppService.sendMessage(target, message);
  }

  public sendSelfAcceptCallSignal(conferenceId: string, conferenceType: string): void  {
    this.sendSelfCallSignal("joined-self", conferenceId, conferenceType);
  }

  public sendSelfRejectCallSignal(conferenceId: string, conferenceType: string): void  {
    this.sendSelfCallSignal("rejected-self", conferenceId, conferenceType);
  }

  public sendSelfCallSignal(eventType: string, conferenceId: string, conferenceType: string): void  {
    const to = this.userJID?.bare;

    let message: any = { type: "normal", body: "" };

    let vncTalkConference: VNCTalkConference = {
      from: this.xmppService.xmpp.jid.bare,
      to: this.xmppService.xmpp.jid.bare,
      conferenceId: conferenceId || this.getConferenceKey(),
      eventType,
      conferenceType
    };

    message["vncTalkConference"] = vncTalkConference;

    message.timestamp = this.datetimeService.getCorrectedLocalTime();

    // message.body = "JOINED_CALL";
    // message.body = "REJECTED_CALL";

    this.logger.info("[ConferenceRepository][sendSelfCallSignal] message", message);

    this.xmppService.sendMessage(to, message);
  }

  private reject(target) {
    this.logger.info("[ConferenceRepository][reject]", target);

    this.broadcaster.broadcast(BroadcastKeys.CALL_REQUEST_REJECTED, target);
    this.broadcaster.broadcast(BroadcastKeys.HIDE_INVITATION, target);
  }

  public stopPlayCalling() {
    this.notificationService.stopPlayCalling();
  }

  public playIncomingCall() {
    this.getActiveConference().pipe(take(1)).subscribe(conferenceTarget => {
      if (!conferenceTarget && !this.checkIfGloballyMuted()) {
        this.notificationService.playIncomingCall();
      }
    });
  }

  public stopPlayIncomingCall() {
    this.notificationService.stopPlayIncomingCall();
  }

  public playWakeUp() {
    this.notificationService.playWakeUp();
  }

  public stopWakeUp() {
    this.notificationService.stopWakeUp();
  }

  public getDiscoItems(item: string): Observable<JID[]> {
    return this.xmppService.getDiscoItems(item);
  }

  public getDiscoInfo(jid: string): Observable<JID[]> {
    return this.xmppService.getDiscoInfo(jid);
  }

  public  setupCallRequestTimeoutTimer(incoming = null) {
    this.logger.info("[ConferenceRepository][setupCallRequestTimeoutTimer]", incoming, environment.callRequestTimeout * 1000);

    this._clearCallRequestTimeoutInterval();
    let conferenceKey;
    let callRequestTimeout = environment.callRequestTimeout * 1000;
    this.getConferenceId().pipe(take(1)).subscribe(key => {
      this.logger.info("[ConferenceRepository] getConferenceId", key);
      conferenceKey = key;
      if (key && CommonUtil.isVideoMeeting(key)) {
        callRequestTimeout = environment.meetingWaitingTime * 60 * 1000;
      }
    });

    this.callRequestTimeoutTimer = setTimeout(() => {
      this.logger.info("[ConferenceRepository] callRequestTimeoutTimer fired");
      // send miss call notification
      this.logger.info("[ConferenceRepository] callRequestTimeoutTimer: invitedParticipants", this.invitedParticipants$.value);

      this.invitedParticipants$.asObservable().pipe(take(1)).subscribe(participants => {
        if (participants.length > 0) {
          setTimeout(() => {
            this.sendMissCallSignal(participants[0].conversationTarget, participants.map(v => v.jid), conferenceKey);
          }, 1000);
        }
      });

      let conversationTarget = incoming && incoming.from;
      // hide incoming call invitation (callee side)
      if (conversationTarget) {
        // hide calling screen (caller side)
        // show 'Missed call' notification
        if (incoming.conferenceId.indexOf("@") !== -1) {
          conversationTarget = incoming.conferenceId;
        }
        this.broadcaster.broadcast(BroadcastKeys.HIDE_INVITATION, conversationTarget);
          this.displayMissedCallNotification(incoming);
      } else {
        this.broadcaster.broadcast(BroadcastKeys.CALL_REQUEST_TIMED_OUT);
        this.logger.info("[ConferenceRepository] CALL_REQUEST_TIMED_OUT");
      }
      this.callRequestTimeoutTimer = null;

    }, callRequestTimeout);
  }

  public invalidateCallRequestTimeoutTimer() {
    this.logger.info("[ConferenceRepository][invalidateCallRequestTimeoutTimer]");
    this._clearCallRequestTimeoutInterval();
  }

  private _clearCallRequestTimeoutInterval() {
    this.logger.info("[ConferenceRepository][_clearCallRequestTimeoutInterval]");
    if (this.callRequestTimeoutTimer) {
      clearTimeout(this.callRequestTimeoutTimer);
      this.callRequestTimeoutTimer = null;
    }
  }

  private displayMissedCallNotification(incoming) {
    this.logger.info("[ConferenceRepository][displayMissedCallNotification]");

    if (CommonUtil.isOnAndroid()) {
      // TODO: duplication (in 3 places)
      const conferenceId = incoming && incoming.conferenceId;
      const conferenceType = incoming && incoming.conferenceType;
      const senderBare = incoming && incoming.from;
      const conversationTarget = conferenceId.indexOf("@") !== -1 ? conferenceId : senderBare;

      const senderName = this.contactRepo.getPrettyUsername(senderBare);

      let roomName = "";
      const isGroup = CommonUtil.isGroupTarged(conferenceId);
      if (isGroup) {
        this.store.select(state => getConversationById(state, conferenceId)).pipe(take(1)).subscribe(conv => {
          roomName = conv ? conv.groupChatTitle : ConversationUtil.getGroupChatTitle(conferenceId);
        });
      }

      // show notification
      if (window.FirebasePlugin && window.FirebasePlugin.displayMissedCallNotification) {
        const options = {
          "callType": conferenceType,
          "target": conversationTarget,
          "name": senderName,
          "groupName": roomName
        };
        this.logger.info("[ConferenceRepository][displayMissedCallNotification] options", options);
        window.FirebasePlugin.displayMissedCallNotification(options, () => {
          this.logger.info("[ConferenceRepository][displayMissedCallNotification] success");
        }, error => {
          this.logger.error("[ConferenceRepository][displayMissedCallNotification] error", error);
          CommonUtil.sentryLog("[ConferenceRepository][displayMissedCallNotification] error" + error);
        });
      }

      // set unreads +1
      this.conversationRepo.setConversationAsUnreadWhenMissedCall(conversationTarget);
    }
  }

  public get jitsiMeetURL(): string {
    return this.configService.get("jitsiMeetURL");
  }

  public shouldSendCallSignal(target: string): boolean {
    if (!target || target.split("@").length < 2) {
      return false;
    }
    const domain = this.conversationRepo.getXmppDomain();
    const allowedDomain = [...this.configService.get("knownIOMDomains"), domain];
    let conferenceDomain = `conference.${domain}`;
    if (!!localStorage.getItem("conferenceDomain")) {
      conferenceDomain = localStorage.getItem("conferenceDomain");
    }
    allowedDomain.push(conferenceDomain);
    const targetDomain = target.split("@")[1];
    // this.logger.info("[ConferenceRepository][shouldSendCallSignal]", allowedDomain, targetDomain);
    return allowedDomain.indexOf(targetDomain) !== -1;
  }

  public get jitsiURL(): string {
    return this.configService.get("jitsiURL");
  }

  public buildCallSignalMessage(to: string, option: JitsiOption, conferenceType: string, eventType: string, conferenceId: string, oldConferenceId?: string) {
    this.logger.info("[ConferenceRepository][buildCallSignalMessage]", to, conferenceType, eventType, option, conferenceId, oldConferenceId);
    let message: any = { type: "normal", body: "" };
    let jitsiRoom = "";
    let jitsiURL = "";
    if (!!option) {
      jitsiRoom = option.value;
      jitsiURL = option.jitsiurl || "";
    }
    let jitsiURLOnly = jitsiURL.replace(jitsiRoom, "");
    if (!jitsiURLOnly.endsWith("/")) {
      jitsiURLOnly += "/";
    }
    const externalURL = `${jitsiURLOnly}vnctalk-jitsi-meet/external.html?r=${jitsiRoom}`;
    if (eventType === "invite") {
      const invitedParticipants = this.invitedParticipants$.value;
      invitedParticipants.push({jid: to, timestamp: new Date().getTime(), conversationTarget: this.activeConferenceTarget});
      this.invitedParticipants$.next(invitedParticipants);
      if (conferenceType === "whiteboard") {
        this.translate.get("WHITEBOARD_SESSION", { link: externalURL }).subscribe((body: string) => {
          message.body = body;
        });
      } else {
        // this.translate.get("INVITATION_MESSAGE", { link: externalURL }).subscribe((body: string) => {
        //   message.body = body;
        // })
        // message.body = `Hey there,\n\nI'd like to invite you to a VNCtalk video conference I've just set up.\nPlease click on the following link in order to join the conference:\n\n${externalURL} \n\nNote that VNCtalk video is currently only supported by Google Chrome and Firefox, so you have to use one of these browsers.\n\nTalk to you in a sec!`;
        message.body = `STARTED_CALL`;
      }
    }
    const conferenceKey =  conferenceId || this.getConferenceKey();
    if (["cancel", "leave", "join", "reject", "no-answer"].indexOf(eventType) !== -1 && conferenceKey.indexOf("@") !== -1) {
      message.type = "groupchat";
    }

    let vncTalkConference: VNCTalkConference = {
      from: this.xmppService.xmpp.jid.bare,
      to: to,
      conferenceId: conferenceId || this.getConferenceKey(),
      oldConferenceId: oldConferenceId,
      jitsiRoom: jitsiRoom,
      jitsiURL: jitsiURL,
      jitsiXmppUrl: "",
      jitsiXmppPort: "",
      reason: "",
      conferenceType: conferenceType,
      eventType: eventType
    };
    if (localStorage.getItem("startingCallTime") !== null) {
      vncTalkConference.timestamp = localStorage.getItem("startingCallTime");
    }
    if (eventType === "cancel") {
      message.body = "MISSED_CALL";
    } else if (eventType === "reject") {
      message.body = "REJECTED_CALL";
    } else if (eventType === "join") {
      message.body = "JOINED_CALL";
    } else if (eventType === "leave") {
      if (localStorage.getItem("duration") !== null) {
        vncTalkConference.duration = localStorage.getItem("duration");
        localStorage.removeItem("duration");
      }
      message.body = "ENDED_CALL";
    } else if (eventType === "no-answer") {
      message.body = "USER_HAS_NOT_ANSWERED";
    }
    message["vncTalkConference"] = vncTalkConference;

    message.timestamp = this.datetimeService.getCorrectedLocalTime();

    this.logger.info("[ConferenceRepository][buildCallSignalMessage]", message.timestamp, message);

    return message;
  }

  getMediaDevices(): Observable<any> {
    return CommonUtil.getMediaDevices();
  }

  getUserId() {
  }

  public getConferenceKey(): string {
    if (!this.selectedConversation || !this.userJID) return "";
    if (this.selectedConversation && this.selectedConversation.type === "groupchat") {
      return this.getConferenceKeyFromGroupTarget(this.selectedConversation?.Target);
    }
    const participants: string[] = [this.selectedConversation?.Target, this.userJID?.bare];
    participants.sort((a, b) => {
      if (a > b) return 1;
      return -1;
    });
    return participants.join(",").replace(/@/g, "#").toLowerCase();
  }

  public getConferenceKeyFromGroupTarget(target): string {
    return target.toLowerCase();
  }

  private startListeningForDeviceChanges() {
    this.logger.info("[ConferenceRepository][startListeningForDeviceChanges]");

    if (navigator.mediaDevices) {
      navigator.mediaDevices.ondevicechange = (event) => {
        this.logger.info("[ConferenceRepository] ondevicechange: ", event);
        this.store.select(getAvailableMediaDevices).pipe(take(1)).subscribe(availableMediaDevices => {
          this.logger.info("[ConferenceRepository] ondevicechange, old availableMediaDevices: ", availableMediaDevices);
          const oldAudioInputs = availableMediaDevices.audioInput;
          const oldAudioOutputs = availableMediaDevices.audioOutput;
          const oldVideoInputs = availableMediaDevices.videoInput;
          this.updateDevicesList(true, oldAudioInputs.length, oldAudioOutputs.length, oldVideoInputs.length);
        });
      };
    }

    this.updateDevicesList();
  }

  public updateDevicesList(ondevicechanged: boolean = false, oldAudioInputs: number = 0, oldAudioOutputs: number = 0, oldVideoInputs: number = 0): void {

    this.getMediaDevices().pipe(take(1)).subscribe(devices => {
      const availableMediaDevices = {
        audioInput: [],
        videoInput: [],
        audioOutput: []
      };

      let cameraCount = 0;
      this.logger.info("[ConferenceRepository][updateDevicesList1]", devices);

      devices.forEach(device => {

        const deviceId = device.deviceId || device.id;

        // // on FF, if no getUserMedia permissions are granted - then no labels will be shared
        // const deviceLabel = device.label || deviceId;

        const deviceLabel = device.label;

        const deviceKind = device.kind;
        // on iOS it's:
        //
        // "Back Camera"
        // "Front Camera"
        // "Back Dual Camera"

        // this.logger.info("[ConferenceRepository][updateDevicesList] getMediaDevices, device:", deviceKind, deviceId, deviceLabel);

        // Audio
        if (deviceKind === "audio" || deviceKind === "audioinput") {
          availableMediaDevices.audioInput.push({ deviceId, deviceLabel });
          this.logger.info("[ConferenceRepository][updateDevicesList] microphone: ", device);
          if (!this.microphoneStatus) {
            // this.logger.info("[ConferenceRepository][updateDevicesList] SetMicrophoneStatus");
            this.store.dispatch(new SetMicrophoneStatus(true));
          }
        }

        // Video
        if (deviceKind === "video" || deviceKind === "videoinput") {
          availableMediaDevices.videoInput.push({ deviceId, deviceLabel });

          if (!this.webcamStatus) {
            // this.logger.info("[ConferenceRepository][updateDevicesList] SetWebcamStatus");
            this.store.dispatch(new SetWebcamStatus(true));
          }

          cameraCount++;

          if (CommonUtil.isOnIOS()) {
            if (deviceLabel.includes("front") || deviceLabel.includes("Front")) {
              if (!this.frontCameraId || this.frontCameraId !== deviceId) {
                this.logger.info("[ConferenceRepository][updateDevicesList] UpdateFrontCameraId");
                this.store.dispatch(new UpdateFrontCameraId(deviceId));
              }
            } else if (deviceLabel.includes("back") || deviceLabel.includes("Back")) {
              if (!this.backCameraId || this.backCameraId !== deviceId) {
                this.logger.info("[ConferenceRepository][updateDevicesList] UpdateBackCameraId");
                this.store.dispatch(new UpdateBackCameraId(deviceId));
              }
            }
          } else if (CommonUtil.isOnAndroid()) {
            if (cameraCount === 1) {
              if (!this.frontCameraId || this.frontCameraId !== deviceId) {
                this.logger.info("[ConferenceRepository][updateDevicesList] UpdateFrontCameraId", deviceId);
                this.store.dispatch(new UpdateFrontCameraId(deviceId));
              }
            } else if (cameraCount >= 2) {
              if (!this.backCameraId || this.backCameraId !== deviceId) {
                this.logger.info("[ConferenceRepository][updateDevicesList] UpdateBackCameraId", deviceId);
                this.store.dispatch(new UpdateBackCameraId(deviceId));
              }
            }
          }
        }

        // Audio output
        if (deviceKind === "audiooutput") {
          availableMediaDevices.audioOutput.push({ deviceId, deviceLabel });

          if (!this.speakerStatus) {
            this.store.dispatch(new SetSpeakerStatus(true));
          }
        }
      });


      // set front/back cams for Web/Electron
      let prefCamId: string;
      if (!environment.isCordova) {
        // when pref is available
        if (prefCamId) {
          this.store.dispatch(new UpdateFrontCameraId(prefCamId));
          this.logger.info("[ConferenceRepository][updateDevicesList] UpdateFrontCameraId", prefCamId);
          //
          if (availableMediaDevices.videoInput.length > 1) {
            const otherCam = availableMediaDevices.videoInput.find(d => d.deviceId !== prefCamId);
            this.logger.info("[ConferenceRepository][updateDevicesList] UpdateBackCameraId", otherCam.deviceId);
            this.store.dispatch(new UpdateBackCameraId(otherCam.deviceId));
          }
        // when not
        } else {
          if (availableMediaDevices.videoInput.length > 0) {
            const firstCam = availableMediaDevices.videoInput[0];
            this.store.dispatch(new UpdateFrontCameraId(firstCam.deviceId));
            this.logger.info("[ConferenceRepository][updateDevicesList] UpdateFrontCameraId", firstCam.deviceId);
            //
            if (availableMediaDevices.videoInput.length > 1) {
              const otherCam = availableMediaDevices.videoInput.find(d => d.deviceId !== firstCam.deviceId);
              this.logger.info("[ConferenceRepository][updateDevicesList] UpdateBackCameraId", otherCam.deviceId);
              this.store.dispatch(new UpdateBackCameraId(otherCam.deviceId));
            }
          } else {
            // no cam available
            this.logger.warn("[ConferenceRepository][updateDevicesList] no cam available");
          }
        }
      }

      // this.logger.info("[ConferenceRepository] ondevicechange: ", availableMediaDevices, Array.isArray(availableMediaDevices.audioOutput), availableMediaDevices.audioOutput.length, availableMediaDevices.audioOutput[0]);
      this.store.dispatch(new SetAvailableMediaDevices(availableMediaDevices));
      this.availableMediaDevicesSet = true;
    });
  }

  getCurrentMediaDevices() {
  }

  changeMediaDevices(cameraId: string, micId: string, outputDeviceId?: string): Observable<any> {
      return;
  }

  saveMediaDevicesPreferences(currentDeviceUdid: string, cameraLabel?: string, micLabel?: string, audioOutputLabel?: string, doNotSave?: boolean) {
    this.logger.info("[ConferenceRepository][saveMediaDevicesPreferences]", currentDeviceUdid, cameraLabel, micLabel, audioOutputLabel);
    localStorage.setItem("preferableMicLabel", micLabel);
    localStorage.setItem("preferableAudioOutputLabel", audioOutputLabel);
    localStorage.setItem("preferableCameraLabel", cameraLabel);
    let existingAppSettings;
    this.store.select(getAppSettings).pipe(take(1)).subscribe(appSettings => {
      this.logger.info("[ConferenceRepository][saveMediaDevicesPreferences] existingAppSettings", appSettings);
      existingAppSettings = appSettings;
    });

    if (!existingAppSettings.preferableMediaDevices) {
      existingAppSettings.preferableMediaDevices = {};
    }
    let mediaDevicesSettingsForCurrentDevice = existingAppSettings.preferableMediaDevices[currentDeviceUdid];
    if (!mediaDevicesSettingsForCurrentDevice) {
      mediaDevicesSettingsForCurrentDevice = {};
      existingAppSettings.preferableMediaDevices[currentDeviceUdid] = mediaDevicesSettingsForCurrentDevice;
    }

    if (cameraLabel) {
      mediaDevicesSettingsForCurrentDevice.preferableCameraLabel = cameraLabel;
    }
    if (micLabel) {
      mediaDevicesSettingsForCurrentDevice.preferableMicLabel = micLabel;
    }
    if (audioOutputLabel) {
      mediaDevicesSettingsForCurrentDevice.preferableAudioOutputLabel = audioOutputLabel;
    }

    const changes = {preferableMediaDevices: existingAppSettings.preferableMediaDevices};
    this.logger.info("[ConferenceRepository][saveMediaDevicesPreferences] changes", changes);
    if (!doNotSave) {
      return this.xmppService.updatePrivateDocuments(changes).subscribe(newAppSettings => {
        const settings = CommonUtil.getAppSettings(newAppSettings);
        this.store.dispatch(new SetAppSettings(settings));

        setTimeout(() => {
          this.updateFrontBackCamerasIds();
        }, 100);

        this.logger.info("[ConferenceRepository][saveMediaDevicesPreferences] done", settings);

        // regenerate prefs
        this.updateDevicesList();
      });
    } else {
      setTimeout(() => {
        this.updateFrontBackCamerasIds();
      }, 100);

      this.logger.info("[ConferenceRepository][saveMediaDevicesPreferences] done");

      // regenerate prefs
      this.updateDevicesList();
      return of(changes);
    }
  }

  updateFrontBackCamerasIds() {
    this.logger.info("[ConferenceRepository][updateFrontBackCamerasIds]");

    if (environment.isCordova) {
      return;
    }
  }

  deleteConference(target: string) {
    this.logger.info("[ConferenceRepository][deleteConference]");

    this.hangupCallIfActive();
    //
    this.conversationRepo.getSelectedConversationMembers().pipe(filter(v => !!v), take(1)).subscribe(members => {
        members.forEach(v => {
            this.conversationRepo.kick(target, v);
        });
    });

    this.conversationRepo.configureRoom(target, {
        persistent: 0,
        isPublic: 0,
        memberOnly: 0
      }).pipe(take(1)).subscribe(() => {
        this.logger.info("CONFIG MEETING ROOM");
      });

    this.conversationRepo.deleteConversationByTarget(target).pipe(take(1)).subscribe(() => {
        this.logger.info("DELETE MEETING ROOM");
    });

    this.conversationRepo.leaveSelectedConversation();
  }

  public removeLocalTempGroupConversation(target: string) {
    this.conversationRepo.removeLocalTempGroupConversation(target);
  }

  setInvitedParticipants(data: string[], sendCommand?: boolean) {
    this.logger.info("[ConferenceRepository][setInvitedParticipants]", data);
    this.invitedParticipants.next(CommonUtil.uniq(data));

    if (sendCommand) {
      data.push(this.userJID?.bare);
    }
  }

  kickUsersFromCallIfActiveConference(target, bareList){
    if (this.activeConferenceTarget && this.activeConferenceTarget === target) {
      bareList.forEach(participantJid =>  {
        this.kickParticipantByJid(participantJid);
      });
    }
  }

  kickParticipant(participantId, name?: string) {
    this.logger.info("[ConferenceRepository][kickParticipant]", participantId);

    if (name) {
      this.kickedParticipants.push(name);
    }

  }

  kickParticipantByJid(participantJid) {
    this.logger.info("[ConferenceRepository][kickParticipantByJid]", participantJid);
    const participants = this.getConferenceParticipantsNotME();
  }

  sendInvitedParticipants() {
    const data = this.invitedParticipants.value;
    data.push(this.userJID?.bare);
  }

  resetInvitedParticipants() {
    this.logger.info("[resetInvitedParticipants]");
    this.invitedParticipants.next([]);
  }

  getParticipantsList() {
  }

  removeFromMeeting(target: string) {
    this.conversationRepo.kickMultiples(this.selectedConversation?.Target, [target]);
  }

  getInvitedParticipantsList() {
  }

  getJoinedParticipantsList() {
  }

  getLeftParticipantsList() {
  }

  getJoinedParticipants() {
  }

  getMutedForMe() {
  }

  muteForMe(participantId: string) {
    this.logger.info("[muteForMe]", participantId);
  }

  unMuteForMe(participantId: string) {
    this.logger.info("[unMuteForMe]", participantId);
  }

  setFullPreviewParticipant(participant) {
    this.logger.info("[setFullPreviewParticipant]", participant);
    if (participant) {
      this.setFullScreenParticipant(participant.id);
    }
    this.broadcaster.broadcast("setFullPreviewParticipant");
    this.fullPreviewParticipant$.next(participant);
  }

  setPresenterParticipant(participantId) {
    this.presenterParticipant$.next(participantId);
  }

  setAudioOutput(value) {
    this.soundDevice$.next(value);
  }

  togglePinParticipant(isPinned, participantId, fromView) {
  }

  changeRole(email, role) {
    let target, owner, admins, members;
    this.store.select(getActiveConference).pipe(take(1)).subscribe(v => {
      target = v;
    });
    let audiences = [];
    this.logger.info("[changeRole]", target, email, role);
    if (target) {


      this.store.select(state => getConversationOwner(state, target)).pipe(take(1)).subscribe(v => {
        owner = v;
      });
      this.store.select(state => getConversationMembers(state, target)).pipe(take(1)).subscribe(v => {
        members = v || [];
      });
      this.store.select(state => getConversationAdmins(state, target)).pipe(take(1)).subscribe(v => {
        admins = v || [];
      });
      if (role === "moderator" && admins.indexOf(email) === -1) {
        admins.push(email);
        members = members.filter(v => v !== email);
      } else if (role === "participant") {
        admins = admins.filter(v => v !== email);
        if (members.indexOf(email) === -1) {
          members.push(email);
        }
      }
      this.conversationRepo.getGroupInfo(this.selectedConversation?.Target).subscribe((res: any) => {
        this.logger.info("[getGroupInfo]", res);
        if (res && res.group_chat && res.group_chat.affiliations_audience) {
          audiences = res.group_chat.affiliations_audience.map(v => v.jid);
        }
        if (role === "audience") {
          audiences.push(email);
        } else {
          audiences = audiences.filter(v => v !== email);
        }
        members = members.filter(v => v !== owner && !admins.includes(v) && !audiences.includes(v));
        this.updateRoles(target, admins, owner, members, audiences);
      });

    }
  }

  updateRoles(target, admins, owner, members, audiences) {
    this.logger.info("[updateRoles]", target, owner, admins, members, audiences);
    if (admins.length > 0) {
      this.conversationRepo.setRoomAffiliations(target,CommonUtil.uniq(admins), "admin").pipe(take(1)).subscribe(res => {
        this.logger.info("[VNCStartVideoMeetingComponent][setRoomAffiliations] admin", admins, res);
      });
    }
    if (members.length > 0) {
      this.conversationRepo.setRoomAffiliations(target, CommonUtil.uniq(members), "member").pipe(take(1)).subscribe(res => {
        this.logger.info("[VNCStartVideoMeetingComponent][setRoomAffiliations] members", members, res);
      });
    }
    if (audiences.length > 0) {
      this.conversationRepo.setRoomAffiliations(target, CommonUtil.uniq(audiences), "audience").pipe(take(1)).subscribe(res => {
        this.logger.info("[VNCStartVideoMeetingComponent][setRoomAffiliations] audiences", audiences, res);
      });
    }


    this.store.dispatch(new ConversationUpdateAdmins({
      conversationTarget: target,
      admins: admins.filter(v => !audiences.includes(v))
    }));

    this.store.dispatch(new ConversationUpdateMembers({
      conversationTarget: target,
      members: members.filter(v => !audiences.includes(v))
    }));

    this.groupChatsService.audienceList$.next(audiences);
  }

  sendBroadcast(message: string) {
  }

  wakeUp(participantId: string) {
  }

  getConversationOwner(target) {
    return this.store.select(state => getConversationOwner(state, target));
  }

  getConversationAdmins(target) {
    return this.store.select(state => getConversationAdmins(state, target));
  }

  scheduleMeeting(attributes: any, attendees: any): Observable<any> {
    return this.meetingsService.scheduleMeeting(attributes, attendees);
  }

  cancelScheduledMeeting(target: string): Observable<any> {
    const subject = new Subject();
    this.groupChatsService.getGroupInfo(target).pipe(take(1)).subscribe(groupinfo => {
      if (!!groupinfo && !!groupinfo.group_chat && !!groupinfo.group_chat.status && (groupinfo.group_chat.status === "cancelled")) {
        this.toastService.show("MEETING_IS_ALREADY_CANCELLED");
        subject.next(true);
      } else {
        this.dialog.open(ConferenceDialogComponent, {
          width: "390px",
          maxWidth: "95%",
          height: "251px",
          backdropClass: "delete-conference-backdrop",
          panelClass: "delete-conference-panel",
          disableClose: true,
          data: {
            action: "cancel_meeting"
          },
          autoFocus: true
        }).afterClosed().pipe(take(1)).subscribe(data => {
          if (data && data.cancelMeeting) {
            // Note1: Cancellation email will be sent to all the participants with the proper ICS file having meeting cancellation attributes.
            this.meetingsService.cancelScheduledMeeting(target, data.message).subscribe(v => {
              this.logger.info("[cancelScheduledMeeting]", target, v);
              subject.next(true);
              if (v && typeof v === "string") {
                this.notificationService.openSnackBarWithTranslation("MEETING_CANCELLED");
                this.conversationRepo.getAllMembersOfSelectedConversation().pipe(take(1)).subscribe(members => {
                  members.filter(v => v !== this.userJID?.bare).map(m => {
                    // Note2: Users from the related GroupChat should be removed via a separate call to affiliation update API(
                      this.groupChatsService.setRoomAffiliation(target, [m], "none");
                    });
                    this.meetingsService.deleteScheduledMeeting(target).subscribe();
                  });
                } else {
                  this.notificationService.openSnackBarWithTranslation("MEETING_IS_ALREADY_CANCELLED");
                }
                this.groupChatsService.updateGroupInfo(target, {status: "cancelled"}).subscribe();
              });
            }
          });
      }
    });
    return subject.asObservable();
  }

  updateScheduledMeeting(body: any) {
    this.meetingsService.updateScheduledMeeting(body).subscribe(() => {
      this.notificationService.openSnackBarWithTranslation("UPDATED");
    });
  }

  async modifyVideoMeeting(target: string) {
    let options: any = {
      width: "540px",
      height: "680px",
    };
    if (CommonUtil.isMobileSize()) {
      options = {
        maxWidth: "100vw",
        width: "100vw",
        height: "100vh"
      };
    }
    const { StartConferenceComponent } = await import(
      /* webpackPrefetch: true */
      "../shared/components/start-conference/start-conference.component"
      );
      this.groupChatsService.getGroupInfo(target).pipe(take(1)).subscribe(groupinfo => {
        if (!!groupinfo && !!groupinfo.group_chat && !!groupinfo.group_chat.status && (groupinfo.group_chat.status === "cancelled")) {
          // toast "already cancelled"
          this.toastService.show("MEETING_IS_ALREADY_CANCELLED");
        } else {
          this.dialog.open(StartConferenceComponent, Object.assign({
            backdropClass: "vnctalk-form-backdrop",
            panelClass: "vnctalk-form-panel",
            disableClose: true,
            data: {
              action: "modify_conference",
              target: target
            },
            autoFocus: true
          }, options));
        }
      });
  }

  setSelectedWhiteboardId(id) {
    this.store.dispatch(new SetSelectedWhiteboardId(id));
  }

  canEnableNoiseSuppression(localAudio) {
    if (!localAudio) {
      return false;
    }
    const { channelCount } = localAudio.track.getSettings();

    // Sharing screen audio implies an effect being applied to the local track, because currently we don't support
    // more then one effect at a time the user has to choose between sharing audio or having noise suppression active.
    // Stereo audio tracks aren't currently supported, make sure the current local track is mono
    if (channelCount > 1) {
      return false;
    }
    return true;
  }

  async setNoiseSuppressionEnabled(enabled: boolean) {

  }

  collapseFloating() {
    this.store.dispatch(new ToggleFloatingVideo(false));
  }

  expandFloating() {
    this.store.dispatch(new ToggleFloatingVideo(true));
  }

  isFloatingExpanded() {
    return this.store.select(isFloatingExpanded);
  }

  setPassword(password) {
  }

  setConferencePassword(target, password) {
  }

  public sendSchedulerInformation(target, conferenceType, subject, ownerJid, ownerName, invitees, startTime, endTime, password): void  {
    const to = target;
    let message: any = { type: "groupchat", body: " " };
    let vncTalkConferenceScheduler = {
        subject: subject,
        owner: ownerJid,
        ownerName: ownerName,
        invitees: invitees,
        startTime: startTime,
        endTime: endTime,
        password: password,
        serverURL: "",
        conferenceType: conferenceType,
        timestamp: this.datetimeService.getCorrectedLocalTime()
    };

    message["vncTalkConferenceScheduler"] = vncTalkConferenceScheduler;

    message.timestamp = this.datetimeService.getCorrectedLocalTime();

    this.logger.info("[ConferenceRepository][sendSelfCallSignal] message", message);

    this.xmppService.sendMessage(to, message);
  }

  startMeeting() {
    let jitsiRoomId = CommonUtil.randomId(10);
    const meetingName = `Meeting ${jitsiRoomId}`;
    this.createMeetingRoom(meetingName).subscribe(roomBare => {
      this.logger.info("[startMeeting][createMeetingRoom]", roomBare);
      this.xmppService.joinRoom(roomBare);
      this.xmppService.setSubject(roomBare, meetingName);
      this.xmppService.configureRoom(roomBare, {
        persistent: 1,
        isPublic: 0,
        memberOnly: 0,
        isE2E: 0
      }).subscribe(() => {
        this.xmppService.setRoomAffiliation(roomBare, this.userJID.bare, "admin").subscribe();
        this.groupChatsService.setRoomAffiliation(roomBare, [this.userJID.bare], "admin");
        this.conversationRepo.sendNewGroupSignal(meetingName, roomBare);
        const data: any = {
          subject: meetingName
        };
        this.groupChatsService.updateGroupInfo(roomBare, data)
          .subscribe();
        this.conversationRepo.inviteToRoomViaGroupManage(roomBare, [this.userJID.bare]);
      });

      this.conversationRepo.createLocalConversation(roomBare, "groupchat", "", [], meetingName);
      this.createJitsiRoom(roomBare, jitsiRoomId, this.configService.get("jitsiURL")).subscribe(() => {
        this.logger.info("[startMeeting][createJitsiRoom]");
        this.conversationRepo.navigateToConversation(roomBare);
        this.router.events
        .pipe(filter(e => e instanceof NavigationEnd), take(1))
        .subscribe(() => {
          this.conversationRepo.getSelectedConversation().pipe(filter(res => !!res), take(1)).subscribe(conv => {
            if (conv && conv.Target === roomBare) {
              this.setConversationTarget(roomBare);
              this.setConferenceKey(roomBare);
              this.isQuickCall = true;
              this.startConference(roomBare, "video", false, []);
            }
          });
        });
      });
    });
  }

  createMeetingRoom(name?: string): Observable<string> {
    const response = new Subject<string>();
    this.xmppService.createMeetingRoom(name, true).subscribe(bare => {
      response.next(bare);
    }, error => {
      response.error(error);
    });

    return response.asObservable().pipe(take(1));
  }
}
