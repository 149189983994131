
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { MiddlewareService } from "./middleware.service";

@Injectable()
export class TFAService {

  constructor(private middlewareService: MiddlewareService) {
  }

  enable2FA(secret: string) {
    let data = {"user": {"totp_secret": secret, "tfa_enabled": "true" }};
    return this.middlewareService.postTest(`api/channel-users/2fa/config`, true, data);
  }

  disable2FA() {
    let data = {"user": {"totp_secret": null, "tfa_enabled": "false"}};
    return this.middlewareService.postTest(`api/channel-users/2fa/config`, true, data);
  }

  verifyToken(otpCode) {
    let data = {};
    return this.middlewareService.post(`/api/2fa/verify/` + otpCode, true, data);
  }

  get2FAStatus() {
    return this.middlewareService.get(`/api/2fa/status`, true);
  }
}
