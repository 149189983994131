export enum NotificationStatus {
    BULK_READ_NOTIFICATION = "notification_bulk_read",
    CHANNEL_CREATED = "channel_created",
    CHANNEL_UPDATED = "channel_updated",
    CHANNEL_ARCHIVED= "channel_archived",
    CHANNEL_UNARCHIVED = "channel_unarchived",
    CHANNEL_MEMBER_ADDED = "channel_member_added",
    CHANNEL_MEMBER_REMOVED = "channel_member_removed",
    CHANNEL_MEMBER_UPDATED = "channel_member_updated",
    CHANNEL_RESTORED = "channel_restored",
    TOPIC_CREATED = "topic_created",
    TOPIC_UPDATED = "topic_updated",
    TOPIC_ARCHIVED= "topic_archived",
    TOPIC_UNARCHIVED = "topic_unarchived",
    TOPIC_READ= "topic_read",
    TOPIC_UNREAD = "topic_unread",
    TOPIC_FILE_ADDED = "topic_file_added",
    TOPIC_FILE_REMOVED = "topic_file_removed",
    TOPIC_MEMBER_ADDED = "topic_member_added",
    TOPIC_MEMBER_REMOVED = "topic_member_removed",
    COMMENT_CREATED = "comment_created",
    COMMENT_UPDATED = "comment_updated",
    COMMENT_DELETED = "comment_deleted",
    PRIVATE_CHANNEL_INVITE = "private_channel_invite",
    PRIVATE_TOPIC_INVITE = "private_topic_invite",
    CHANNEL_ACCESS_REQUEST = "channel_access_request",
    TOPIC_ACCESS_REQUEST = "topic_access_request",
    CHANNEL_ACCESS_GRANTED = "channel_access_granted",
    TOPIC_ACCESS_GRANTED = "topic_access_granted",
    CHANNEL_DELETED = "channel_deleted",
    TOPIC_DELETED = "topic_deleted",
    COMMENT_CREATED_REPLY = "comment_created_reply",
    COMMENT_CREATED_MENTION = "comment_created_mention",
    NOTIFICATION_READ = "notification_read",
    SOCIAL_POST_CREATED = "live_post_created",
    SOCIAL_POST_DELETED = "live_post_deleted",
    SOCIAL_POST_REPLY_CREATED = "live_reply_created",
    SOCIAL_POST_REPOST_CREATED = "live_repost_created",
    SOCIAL_POST_QUOTE_CREATED = "live_quote_created",
    SOCIAL_POST_UPDATED = "live_post_updated",
    USER_PROFILE_UPDATE = "user_profile_update",
    FOLLOW = "follow",
    UNFOLLOW = "unfollow"
}
