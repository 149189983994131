
<!--
    ~ VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
    ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
    ~
    ~ This program is free software: you can redistribute it and/or modify
    ~ it under the terms of the GNU Affero General Public License as published by
    ~ the Free Software Foundation, version 3 of the License.
    ~
    ~ This program is distributed in the hope that it will be useful,
    ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
    ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
    ~ GNU Affero General Public License for more details.
    ~
    ~ You should have received a copy of the GNU Affero General Public License
    ~ along with this program. Look for COPYING file in the top folder.
    ~ If not, see http://www.gnu.org/licenses/.
    -->
    <div class="avatar avatar-for-{{ className }}" [ngClass]="{'is-external-user': isExternalUser}">
      <div *ngIf="totalUnread > 0" class="unread-count">
        {{ totalUnread }}
      </div>
      <div class="encrypt-icon" *ngIf="isEncrypted">
        <svg width="10" height="10" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.857 5a.429.429 0 0 1 0 .857H9.43v.857A.429.429 0 0 1 9 7.143h-.429V5.857H5.528a2.144 2.144 0 1 1 0-.857h4.33zM3.43 4.143a1.286 1.286 0 1 0 0 2.571 1.286 1.286 0 0 0 0-2.571z" fill="#FFF" fill-rule="evenodd"/>
        </svg>
      </div>
      <div class="moderator-icon" *ngIf="isModerator">
        <mat-icon fontSet="mdi" fontIcon="mdi-star"></mat-icon>
      </div>
      <div class="moderator-icon" *ngIf="isAudience">
        <mat-icon fontSet="mdi" fontIcon="mdi-account-multiple"></mat-icon>
      </div>
      <!-- 1-1 chat -->
      <div *ngIf="!isGroupChat && !isBroadcast" class="vnc-avatar {{ size || 'small' }}"
        [ngStyle]="{'background': background + ' ' + (!!tempAvatarUrl ? 'url(' + tempAvatarUrl + ')' : ''), 'background-size': '100%'}"
        [ngClass]="{'is-external-user': isExternalUser, 'invited-email': isInvited}">
        <ng-container *ngIf="isInvited && isExternalUser">
          <mat-icon fontSet="mdi" fontIcon="mdi-at"></mat-icon>
        </ng-container>
        <ng-container *ngIf="!(isInvited && isExternalUser)">

          <img class="avatar-photo temp-avatar" draggable="false" *ngIf="isImageLoading && tempAvatarUrl" [src]="tempAvatarUrl"
          validate="never">
          <!-- img itself -->
          <img class="avatar-photo hightResolution" *ngIf="highResolutionUrl"
          [src]="highResolutionUrl" draggable="false" #imgElement
          [ngStyle]="{'display': !isImageLoading && isAvatarExists ? 'block' : 'none'}"
          validate="never"
          (load)="onDefaultImageLoad()"
          (error)="highResolutionError($event)">

          <img class="avatar-photo" *ngIf="avatarUrl && !highResolutionUrl" [src]="avatarUrl" draggable="false" #imgElement
          [ngStyle]="{'display': !isImageLoading && isAvatarExists ? 'block' : 'none'}"
          validate="never"
          (load)="imgOnLoad()"
          (error)="imgLoadOnError($event)">

          <!-- user's status -->
          <span *ngIf="!hidestatus && !isGroupChat && !isBlocked" class="status {{ status || 'offline' }}"></span>
                    <!-- <mat-icon *ngIf="!hidestatus && isBlocked && !hideBlockedIcon" class="status-blocked">badge-block-icon</mat-icon> -->
                    <vnc-icon-container *ngIf="!hidestatus && isBlocked && !hideBlockedIcon" class="status blocked">
                      <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                          <title>Blocked</title>
                          <g id="Chats" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <g id="0110.-VNCtalk--Chats-#63" transform="translate(-48.000000, -394.000000)">
                                  <g id="00-Talk/menu/chats/hover" transform="translate(0.000000, 48.000000)">
                                      <g id="Group" transform="translate(0.000000, 158.000000)">
                                          <g id="00-Talk/menu/item/favorites" transform="translate(0.000000, 144.000000)">
                                              <g id="Avatar/state/online" transform="translate(16.000000, 12.000000)">
                                                  <g id="Badge/status/blocked" transform="translate(32.571429, 32.571429)">
                                                      <circle id="Oval" fill="#EF417B" cx="6.85714286" cy="6.85714286" r="6.85714286"></circle>
                                                      <g id="Icon/product/slash-large-new" transform="translate(1.714286, 1.484771)" fill="#FFFFFF">
                                                          <path d="M8.12132034,2.16439394 C8.2886879,2.3317615 8.2886879,2.60311792 8.12132034,2.77048547 L2.77048547,8.12132034 C2.60311792,8.2886879 2.3317615,8.2886879 2.16439394,8.12132034 C1.99702639,7.95395279 1.99702639,7.68259637 2.16439394,7.51522882 L7.51522882,2.16439394 C7.68259637,1.99702639 7.95395279,1.99702639 8.12132034,2.16439394 Z" id="Path"></path>
                                                      </g>
                                                  </g>
                                              </g>
                                          </g>
                                      </g>
                                  </g>
                              </g>
                          </g>
                      </svg>
                    </vnc-icon-container>
          <!-- initials -->
          <span *ngIf="size !== 'video-call'" [ngClass]="{'hide': isAvatarExists || !!tempAvatarUrl}" class="avatar-text">{{ text }}</span>
          <svg *ngIf="size === 'video-call' && !isAvatarExists" width="240" height="240" viewBox="0 0 240 240" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M120 0c66.274 0 120 53.726 120 120 0 28.21-9.734 54.145-26.026 74.633-6.453-1.905-13.514-3.332-21.181-4.289l-3.323-.38c-21.326-2.236-30.39-7.997-35.05-14.24l-.877-1.253c-.136-.21-.27-.42-.398-.63l-.726-1.264-.643-1.262-.575-1.257-.764-1.861-.689-1.817c-2.222-5.964-2.627-11.353-1.214-16.167.49-.29 1.078-1.172 1.766-2.643l.721-1.668c.126-.31.255-.638.386-.981l.82-2.258.435-1.276c1.576-4.714 2.49-8.374 2.743-10.98l.063-.929 4.334-2.592c7.28-5.414 10.937-24.525 9.534-28.51-.841-2.392-2.096-3.33-3.763-2.817l-.57.225-2.6.864 3.466-13.824c1.85-15.09.116-26.61-5.2-34.558-11.11-13.977-24.23-21.177-39.361-21.6-21.179 0-37.606 8.813-40.38 14.688.884 3.812-.38 5.47-2.258 6.89l-2.448 1.714c-2.122 1.538-4.243 3.607-4.827 8.125-2.207 17.043-2.044 26.716 1.996 37.079l.604 1.486-2.6-.864c-2.581-.83-4.026.035-4.334 2.591-.462 3.836 1.02 17.033 4.334 21.757l1.255 1.79c2.592 3.652 4.473 5.763 8.279 4.964 1.03 4.912 2.045 8.918 3.049 12.018l.169.491.378.954 1.33 2.961.46 1.144.443 1.283c1.078 3.414 1.871 8.483 1.36 17.796-.877 16.011-8.777 21.053-27.81 21.75l-3.372.078c-.58.006-1.17.01-1.77.01-9.334 0-19.883 1.993-31.645 5.981C8.738 171.414 0 146.727 0 120 0 53.726 53.726 0 120 0z"
              fill="#434B53" fill-rule="evenodd" />
          </svg>
          <span *ngIf="!noAvatarTemplate" [ngClass]="{'hide': isAvatarExists || !!tempAvatarUrl}" class="avatar-text">{{ text }}</span>
          <ng-container [ngTemplateOutlet]="noAvatarTemplate" *ngIf="noAvatarTemplate && !isAvatarExits && !tempAvatarUrl">
          </ng-container>
        </ng-container>
      </div>

      <!-- group chat -->
      <div *ngIf="isGroupChat && !isBroadcast" class="vnc-avatar group-chat-icon {{ size || 'small' }}"
      [ngStyle]="{'background': bgcolor + ' ' + (!!tempAvatarUrl ? 'url(' + tempAvatarUrl + ')' : ''), 'background-size': '100%'}"
      [ngClass]="{'is-external-user': isExternalUser || hasExternalUsers, 'is-video-meeting': isVideoMeeting}">
      <ng-container *ngIf="!isVideoMeeting">
        <img class="avatar-photo temp-avatar" draggable="false" *ngIf="isImageLoading && tempAvatarUrl" [src]="tempAvatarUrl"
        validate="never">
        <img class="avatar-photo" draggable="false" *ngIf="avatarUrl" [src]="avatarUrl" #imgElement
        [ngStyle]="{'display': !isImageLoading && isAvatarExists ? 'block' : 'none'}"
        validate="never"
        (load)="imgOnLoad()"
        (error)="imgLoadOnError($event)">
        <ng-container *ngIf="!isHinTheme">
          <div class="groupchat-avatar-icon avatar-empty-state-image" *ngIf="!tempAvatarUrl && !isAvatarExists">
            <svg width="96" height="96" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg">
              <g fill="none" fill-rule="evenodd">
                  <circle fill="#E3E7EB" cx="48" cy="48" r="48"/>
                  <path d="M58.72 48c3.33 0 6.948.72 10.862 2.139 4.258 1.544 6.936 4.02 7.633 7.428l.093.545.025.367v2.854a2.667 2.667 0 1 1-5.333 0v-2.618l-.03-.156c-.298-1.23-1.42-2.297-3.667-3.2l-.539-.206c-3.363-1.22-6.38-1.82-9.044-1.82-1.442 0-2.994.176-4.652.531a8.177 8.177 0 0 1 1.813 3.703l.094.545.025.367V64a2.667 2.667 0 0 1-5.333 0v-5.285l-.03-.156c-.276-1.136-1.252-2.132-3.168-2.99l-.5-.21-.538-.206c-3.364-1.22-6.38-1.82-9.044-1.82-2.667 0-5.704.601-9.106 1.823-2.64.948-3.929 2.085-4.252 3.404l-.029.141V64a2.667 2.667 0 0 1-5.333 0v-5.52l.025-.368c.513-3.697 3.267-6.353 7.787-7.976C30.426 48.72 34.059 48 37.387 48c3.266 0 6.81.693 10.64 2.059l-.215.077C51.76 48.72 55.393 48 58.72 48zM36 26.667c2.613 0 4.884.891 6.655 2.648 1.775 1.762 2.678 4.026 2.678 6.633 0 2.606-.903 4.871-2.678 6.632-1.744 1.73-3.957 2.658-6.697 2.752-2.613-.093-4.863-1.015-6.613-2.752-1.775-1.76-2.678-4.026-2.678-6.632 0-2.607.903-4.871 2.678-6.633 1.771-1.757 4.042-2.648 6.655-2.648zm24 0c2.613 0 4.884.891 6.655 2.648 1.775 1.762 2.678 4.026 2.678 6.633 0 2.606-.903 4.871-2.678 6.632-1.75 1.737-4 2.66-6.805 2.752-2.548-.094-4.761-1.021-6.505-2.752-1.775-1.76-2.678-4.026-2.678-6.632 0-2.607.903-4.871 2.678-6.633 1.771-1.757 4.042-2.648 6.655-2.648zM36 32c-1.236 0-2.15.359-2.899 1.101-.744.739-1.101 1.634-1.101 2.847s.357 2.108 1.101 2.847c.77.763 1.74 1.16 2.854 1.207 1.231-.045 2.168-.438 2.944-1.207.744-.74 1.101-1.634 1.101-2.847s-.357-2.108-1.101-2.847C38.15 32.36 37.236 32 36 32zm24 0c-1.236 0-2.15.359-2.899 1.101-.744.739-1.101 1.634-1.101 2.847s.357 2.108 1.101 2.847a4.055 4.055 0 0 0 2.752 1.207c1.307-.047 2.276-.444 3.046-1.207.744-.74 1.101-1.634 1.101-2.847s-.357-2.108-1.101-2.847C62.15 32.36 61.236 32 60 32z" fill="#8B96A0"/>
              </g>
            </svg>
          </div>
          <div *ngIf="showGroupIcon" class="group-avataricon small-icon">
            <svg width="11" height="11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.151 5.429c.535 0 1.117.115 1.746.343.685.248 1.115.646 1.227 1.194l.015.088.004.059v.458a.429.429 0 0 1-.857 0v-.423l-.005-.022c-.048-.198-.228-.37-.59-.515l-.086-.033c-.54-.196-1.025-.292-1.454-.292-.231 0-.48.028-.747.085.147.174.246.372.291.595l.015.088.004.059V8a.429.429 0 0 1-.857 0v-.85l-.005-.024c-.044-.183-.2-.343-.509-.48l-.08-.035-.087-.033c-.54-.196-1.025-.292-1.453-.292-.429 0-.917.096-1.463.293-.425.152-.632.335-.684.547l-.005.022V8a.429.429 0 0 1-.857 0v-.887l.004-.06c.083-.593.525-1.02 1.252-1.281.634-.228 1.218-.343 1.753-.343.525 0 1.094.11 1.71.33l-.035.013c.635-.228 1.219-.343 1.753-.343zM3.5 2c.42 0 .785.143 1.07.426.285.283.43.647.43 1.066 0 .419-.145.783-.43 1.066-.28.278-.636.427-1.077.442a1.542 1.542 0 0 1-1.063-.442A1.456 1.456 0 0 1 2 3.492c0-.42.145-.783.43-1.066C2.715 2.143 3.08 2 3.5 2zm3.857 0c.42 0 .785.143 1.07.426.285.283.43.647.43 1.066 0 .419-.145.783-.43 1.066-.282.279-.643.427-1.094.442a1.522 1.522 0 0 1-1.045-.442 1.456 1.456 0 0 1-.43-1.066c0-.42.144-.783.43-1.066.284-.283.65-.426 1.07-.426zM3.5 2.857a.619.619 0 0 0-.466.177.602.602 0 0 0-.177.458c0 .195.057.338.177.457a.67.67 0 0 0 .459.194.668.668 0 0 0 .473-.194.602.602 0 0 0 .177-.457.602.602 0 0 0-.177-.458.619.619 0 0 0-.466-.177zm3.857 0a.619.619 0 0 0-.466.177.602.602 0 0 0-.177.458c0 .195.058.338.177.457a.652.652 0 0 0 .443.194.69.69 0 0 0 .489-.194A.602.602 0 0 0 8 3.492a.602.602 0 0 0-.177-.458.619.619 0 0 0-.466-.177z" fill="#FFF" fill-rule="evenodd"/>
            </svg>
          </div>
          <div *ngIf="showVideoIcon" class="video-icon small-icon">
              <svg width="20px" height="16px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="Icon/product/notification-video" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="Icon/custom/video" transform="translate(3.750000, 6.750000)" fill="#ffffff">
                          <path d="M8.25,0 C9.85664654,0 11.1683092,1.26297635 11.2463285,2.85026974 L11.25,3 L11.25,7.5 C11.25,9.10664654 9.98702365,10.4183092 8.39973026,10.4963285 L8.25,10.5 L3,10.5 C1.39335346,10.5 0.081690843,9.23702365 0.00367148043,7.64973026 L0,7.5 L0,3 C0,1.39335346 1.26297635,0.081690843 2.85026974,0.00367148043 L3,0 L8.25,0 Z M8.25,1.5 L3,1.5 C2.20922865,1.5 1.56137616,2.11190834 1.5041143,2.88805324 L1.5,3 L1.5,7.5 C1.5,8.29077135 2.11190834,8.93862384 2.88805324,8.9958857 L3,9 L8.25,9 C9.04077135,9 9.68862384,8.38809166 9.7458857,7.61194676 L9.75,7.5 L9.75,3 C9.75,2.20922865 9.13809166,1.56137616 8.36194676,1.5041143 L8.25,1.5 Z M14.6569377,2.02656107 C15.3108012,1.58856487 16.1959283,1.76355985 16.6339245,2.41742329 C16.7342489,2.56719278 16.8049413,2.73417248 16.8448318,2.93198112 L16.8689561,3.08633083 L16.875,3.21048557 L16.875,7.28898084 C16.875,8.07598661 16.2370058,8.71398084 15.45,8.71398084 C15.269769,8.71398084 15.0917293,8.67979708 14.9052147,8.60288056 L14.7635731,8.5370472 L14.6570727,8.47299572 L12.6320727,7.11686595 C12.322532,6.90956848 12.1084081,6.59076132 12.0283131,6.19693761 L12.0066318,6.06293471 L12,5.93285106 L12,4.5669497 C12,4.19445217 12.145711,3.8391805 12.4283073,3.55349912 L12.5275638,3.46092124 L12.6319377,3.3830252 L14.6569377,2.02656107 Z M15.375,7.1505 L15.37575,3.3495 L13.5,4.605 L13.4985,5.892 L15.375,7.1505 Z" id="Combined-Shape"></path>
                      </g>
                  </g>
              </svg>
          </div>
        </ng-container>

        <svg *ngIf="isHinTheme" width="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.92 49.22"><defs><style>.people-icon-color{fill:#dbdbdb;}.cls-2{fill:none;}</style></defs><title>hintalk-avatar-gruppen-cmyk-v2</title><path class="people-icon-color" d="M33.16,39l-1.09.22h0a17.27,17.27,0,0,1-2.07.23A9.6,9.6,0,1,1,39.66,30a8.94,8.94,0,0,1-.25,2l-.29,1.05A11.48,11.48,0,0,0,33.16,39Z" transform="translate(-17.3 -20.28)"></path><path class="people-icon-color" d="M36.85,53.05a17.22,17.22,0,0,0-2.69,2.15,37.32,37.32,0,0,1-4.31.46,20,20,0,0,1-12.55-4.4,14.8,14.8,0,0,1,7.22-10.55A12.16,12.16,0,0,0,30,42.06a11.07,11.07,0,0,0,2.32-.24A11.68,11.68,0,0,0,36.85,53.05Z" transform="translate(-17.3 -20.28)"></path><path class="people-icon-color" d="M54.35,39.1l1.09.21h0a16.58,16.58,0,0,0,2.07.24,9.6,9.6,0,1,0-9.67-9.45,9.55,9.55,0,0,0,.26,2l.29,1.05A11.43,11.43,0,0,1,54.35,39.1Z" transform="translate(-17.3 -20.28)"></path><path class="people-icon-color" d="M50.66,53.13a16.6,16.6,0,0,1,2.69,2.15,37.32,37.32,0,0,0,4.31.46,20,20,0,0,0,12.55-4.4A14.84,14.84,0,0,0,63,40.78a12.07,12.07,0,0,1-5.48,1.36,11.78,11.78,0,0,1-2.32-.24A11.68,11.68,0,0,1,50.66,53.13Z" transform="translate(-17.3 -20.28)"></path><path class="cls-2" d="M43.62,69.51A20.09,20.09,0,0,0,56.19,65.1a14.84,14.84,0,0,0-7.06-10.48,12.2,12.2,0,0,1-5.36,1.28,12.07,12.07,0,0,1-5.48-1.35,14.8,14.8,0,0,0-7.22,10.56A20,20,0,0,0,43.62,69.51Z" transform="translate(-17.3 -20.28)"></path><circle class="cls-2" cx="26.46" cy="23.44" r="9.59"></circle><path class="people-icon-color" d="M43.62,69.51A20.09,20.09,0,0,0,56.19,65.1a14.84,14.84,0,0,0-7.06-10.48,12.2,12.2,0,0,1-5.36,1.28,12.07,12.07,0,0,1-5.48-1.35,14.8,14.8,0,0,0-7.22,10.56A20,20,0,0,0,43.62,69.51Z" transform="translate(-17.3 -20.28)"></path><circle class="people-icon-color" cx="26.46" cy="23.44" r="9.59"></circle></svg>
      </ng-container>

      <!-- video meeting -->
      <ng-container *ngIf="isVideoMeeting">
        <img class="avatar-photo" draggable="false" *ngIf="avatarUrl" [src]="avatarUrl" #imgElement
        [ngStyle]="{'visibility': hideWhileImageLoading && isImageLoading ? 'hidden' : 'visible', 'display': !isImageLoading && isAvatarExists ? 'block' : 'none'}"
        validate="never"
        (load)="imgOnLoad()"
        (error)="imgLoadOnError($event)">
        <svg *ngIf="!isAvatarExists || isImageLoading" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="Icon/product/camera-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <path d="M12,5 C14.1421954,5 15.8910789,6.68396847 15.9951047,8.80035966 L16,9 L16,15 C16,17.1421954 14.3160315,18.8910789 12.1996403,18.9951047 L12,19 L5,19 C2.85780461,19 1.10892112,17.3160315 1.00489531,15.1996403 L1,15 L1,9 C1,6.85780461 2.68396847,5.10892112 4.80035966,5.00489531 L5,5 L12,5 Z M12,7 L5,7 C3.9456382,7 3.08183488,7.81587779 3.00548574,8.85073766 L3,9 L3,15 C3,16.0543618 3.81587779,16.9181651 4.85073766,16.9945143 L5,17 L12,17 C13.0543618,17 13.9181651,16.1841222 13.9945143,15.1492623 L14,15 L14,9 C14,7.9456382 13.1841222,7.08183488 12.1492623,7.00548574 L12,7 Z M20.5425836,7.70208143 C21.4144016,7.1180865 22.5945711,7.35141313 23.178566,8.22323106 C23.3123319,8.4229237 23.4065884,8.64556331 23.4597758,8.90930817 L23.4919415,9.11510777 L23.5,9.28064743 L23.5,14.7186411 C23.5,15.7679821 22.649341,16.6186411 21.6,16.6186411 C21.359692,16.6186411 21.1223057,16.5730628 20.8736196,16.4705074 L20.6847641,16.3827296 L20.5427636,16.2973276 L17.8427636,14.4891546 C17.4300426,14.212758 17.1445442,13.7876818 17.0377508,13.2625835 L17.0088423,13.083913 L17,12.9104681 L17,11.0892663 C17,10.5926029 17.1942814,10.1189073 17.5710764,9.73799882 L17.7034184,9.61456165 L17.8425836,9.51070027 L20.5425836,7.70208143 Z M21.5,14.534 L21.501,9.466 L19,11.14 L18.998,12.856 L21.5,14.534 Z" id="Combined-Shape" fill="#ffffff"></path>
          </g>
      </svg>
        <div *ngIf="showGroupIcon" class="group-avataricon small-icon">
          <svg width="11" height="11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.151 5.429c.535 0 1.117.115 1.746.343.685.248 1.115.646 1.227 1.194l.015.088.004.059v.458a.429.429 0 0 1-.857 0v-.423l-.005-.022c-.048-.198-.228-.37-.59-.515l-.086-.033c-.54-.196-1.025-.292-1.454-.292-.231 0-.48.028-.747.085.147.174.246.372.291.595l.015.088.004.059V8a.429.429 0 0 1-.857 0v-.85l-.005-.024c-.044-.183-.2-.343-.509-.48l-.08-.035-.087-.033c-.54-.196-1.025-.292-1.453-.292-.429 0-.917.096-1.463.293-.425.152-.632.335-.684.547l-.005.022V8a.429.429 0 0 1-.857 0v-.887l.004-.06c.083-.593.525-1.02 1.252-1.281.634-.228 1.218-.343 1.753-.343.525 0 1.094.11 1.71.33l-.035.013c.635-.228 1.219-.343 1.753-.343zM3.5 2c.42 0 .785.143 1.07.426.285.283.43.647.43 1.066 0 .419-.145.783-.43 1.066-.28.278-.636.427-1.077.442a1.542 1.542 0 0 1-1.063-.442A1.456 1.456 0 0 1 2 3.492c0-.42.145-.783.43-1.066C2.715 2.143 3.08 2 3.5 2zm3.857 0c.42 0 .785.143 1.07.426.285.283.43.647.43 1.066 0 .419-.145.783-.43 1.066-.282.279-.643.427-1.094.442a1.522 1.522 0 0 1-1.045-.442 1.456 1.456 0 0 1-.43-1.066c0-.42.144-.783.43-1.066.284-.283.65-.426 1.07-.426zM3.5 2.857a.619.619 0 0 0-.466.177.602.602 0 0 0-.177.458c0 .195.057.338.177.457a.67.67 0 0 0 .459.194.668.668 0 0 0 .473-.194.602.602 0 0 0 .177-.457.602.602 0 0 0-.177-.458.619.619 0 0 0-.466-.177zm3.857 0a.619.619 0 0 0-.466.177.602.602 0 0 0-.177.458c0 .195.058.338.177.457a.652.652 0 0 0 .443.194.69.69 0 0 0 .489-.194A.602.602 0 0 0 8 3.492a.602.602 0 0 0-.177-.458.619.619 0 0 0-.466-.177z" fill="#FFF" fill-rule="evenodd"/>
            </svg>

        </div>
        <div *ngIf="showVideoIcon" class="video-icon small-icon">
          <svg width="20px" height="16px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Icon/product/notification-video" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Icon/custom/video" transform="translate(3.750000, 6.750000)" fill="#ffffff">
                    <path d="M8.25,0 C9.85664654,0 11.1683092,1.26297635 11.2463285,2.85026974 L11.25,3 L11.25,7.5 C11.25,9.10664654 9.98702365,10.4183092 8.39973026,10.4963285 L8.25,10.5 L3,10.5 C1.39335346,10.5 0.081690843,9.23702365 0.00367148043,7.64973026 L0,7.5 L0,3 C0,1.39335346 1.26297635,0.081690843 2.85026974,0.00367148043 L3,0 L8.25,0 Z M8.25,1.5 L3,1.5 C2.20922865,1.5 1.56137616,2.11190834 1.5041143,2.88805324 L1.5,3 L1.5,7.5 C1.5,8.29077135 2.11190834,8.93862384 2.88805324,8.9958857 L3,9 L8.25,9 C9.04077135,9 9.68862384,8.38809166 9.7458857,7.61194676 L9.75,7.5 L9.75,3 C9.75,2.20922865 9.13809166,1.56137616 8.36194676,1.5041143 L8.25,1.5 Z M14.6569377,2.02656107 C15.3108012,1.58856487 16.1959283,1.76355985 16.6339245,2.41742329 C16.7342489,2.56719278 16.8049413,2.73417248 16.8448318,2.93198112 L16.8689561,3.08633083 L16.875,3.21048557 L16.875,7.28898084 C16.875,8.07598661 16.2370058,8.71398084 15.45,8.71398084 C15.269769,8.71398084 15.0917293,8.67979708 14.9052147,8.60288056 L14.7635731,8.5370472 L14.6570727,8.47299572 L12.6320727,7.11686595 C12.322532,6.90956848 12.1084081,6.59076132 12.0283131,6.19693761 L12.0066318,6.06293471 L12,5.93285106 L12,4.5669497 C12,4.19445217 12.145711,3.8391805 12.4283073,3.55349912 L12.5275638,3.46092124 L12.6319377,3.3830252 L14.6569377,2.02656107 Z M15.375,7.1505 L15.37575,3.3495 L13.5,4.605 L13.4985,5.892 L15.375,7.1505 Z" id="Combined-Shape"></path>
                </g>
            </g>
        </svg>
        </div>
      </ng-container>

      </div>

      <!-- broadcast -->
      <div *ngIf="isBroadcast" class="vnc-avatar {{ size || 'small' }} broadcast-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33">
          <g fill="none" fill-rule="evenodd">
              <g fill="#FFF">
                  <g>
                      <g>
                          <g>
                              <g>
                                  <path d="M13.333 18.667l-.962 5.771c-.214 1.286-1.326 2.229-2.63 2.229h-.408c-1.247 0-2.259-1.012-2.259-2.26 0-.124.01-.248.031-.37L8 18.666h5.333zm13.42-14.86l.197.015c.98.105 1.863.752 2.223 1.74.106.293.16.601.16.912v12.385c0 1.406-1.088 2.558-2.467 2.66l-.2.007c-.31 0-.619-.054-.91-.16l-11.09-4.033H7.334c-2.577 0-4.666-2.089-4.666-4.666C2.667 10.089 4.756 8 7.333 8h7.334l11.088-4.032c.264-.096.533-.148.799-.16h.199zm-.086 15.052V6.474l-11.53 4.193H7.332c-1.104 0-2 .895-2 2 0 1.04.794 1.894 1.808 1.99l.192.01h7.803l11.53 4.192z" transform="translate(-24 -181) translate(0 48) translate(0 114) translate(16 12) translate(8 8)"/>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
        </svg>
        </div>
      <div class="name" *ngIf="showName">
        {{name}}
      </div>
    </div>
