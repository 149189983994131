
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { forkJoin, Observable, timer } from "rxjs";
import { JID, SearchUser, VCard } from "../models/jid.model";
import {
  getIsAppBootstrapped,
  getContactById,
  getContactPhotoById,
  getContacts,
  getIsConnectedXMPP,
  getIsContactLoaded,
  getIsContactLoading,
  getMembersByGroupId,
  getUserJID,
  getUserProfile,
  RootState,
  getContactVCardById,
  getContactsByIds,
  getDomain,
  getGroups,
  getGroupsByName,
  getIsGroupLoading,
  getNetworkInformation
} from "../../reducers";
import { ContactsService } from "../services/contacts.service";
import { XmppService } from "../services/xmpp.service";
import {
  ContactBulkAdd,
  ContactStatusUpdate,
  ContactBulkStatusUpdate,
  ContactLoadRequest,
  ContactAddVCard,
  RemoveGroupFromContact,
  RemoveGroupFromContacts,
  AddGroupToContacts,
  ContactAdd,
  ContactBulkUpdate,
  ContactDelete
} from "../../actions/contact";
import { Photo } from "../models/photo.model";
import { Contact } from "../models/contact.model";
import { isNullOrUndefined } from "util";
import { ConstantsUtil } from "../utils/constants.util";
import { AppService } from "../../shared/services/app.service";
import { Conversation, Recipient } from "../models/conversation.model";
import { getConversationById } from "../reducers";
import { ConversationUtil } from "../utils/conversation.util";
import { ContactInformation } from "../models/vcard.model";
import { DatabaseService } from "../services/db/database.service";
import { Broadcaster } from "../shared/providers";
import { CommonUtil } from "../utils/common.util";
import { ContactRest } from "../models/contact-rest.model";
import {BehaviorSubject, Subject} from "rxjs";
import { Group } from "../models/group.model";
import { GroupLoadRequest, GroupLoadSuccess, GroupBulkAdd, GroupAdd, GroupUpdate, GroupDelete } from "app/actions/group";
import { ConversationUpdateLastActivity } from "app/talk/actions/conversation";
import { UserStatus } from "app/shared/models";
import { environment } from "app/environments/environment";
import { ConfigService } from "app/config.service";
import { SearchMessage } from "../models/search-message.model";
import { GroupChatsService } from "../services/groupchat.service";
import {bufferTime, concatMap, distinctUntilChanged, filter, map, reduce, switchMap, take, takeWhile} from "rxjs/operators";
import { LoggerService } from "app/shared/services/logger.service";

@Injectable()
export class ContactRepository {

  private contactsLoading = false;
  private contactsLoaded = false;

  private isXmppConnected = false;
  private isNetOnline = false;

  public userJID: JID;
  public fullName: string;
  public nickName: string;
  public contactSubscription$ = {};
  public photoSubscription$ = {};
  public statusSubscription$ = {};
  private updateContactsFromLdap$ = new Subject<any>();
  updateContactsStore$ = new Subject<any>();
  isGroupManageEnabled: boolean = false;

  needToSyncContactsAndGroups = false;

  checkedContactsMap: any = {};
  allContacts: Contact[] = [];
  allApiContactIds: any[] = [];
  contact: any;
  contactsList$ = new Subject<any>();
  constructor(private store: Store<RootState>,
              private contactService: ContactsService,
              private xmppService: XmppService,
              private appService: AppService,
              private databaseService: DatabaseService,
              private broadcaster: Broadcaster,
              private groupChatsService: GroupChatsService,
              private logger: LoggerService,
              private configService: ConfigService) {
        if (!this.configService.isAnonymous) {
          this.initData();
        }
  }

  initData() {
    this.store.select(getIsConnectedXMPP).subscribe(v => this.isXmppConnected = v);
    this.store.select(getContacts).subscribe(c => {
      this.allContacts = c;
    });
    this.broadcaster.on<boolean>("USER_LOGGED_OUT")
      .subscribe(() => {
        this.cleanLastContactsFetchTimeStamp();
      });

    this.broadcaster.on<any>("vncdirectorycontactsbulkupdate").subscribe( () => {
      this.logger.info("[getOnMessage][contactsupdate] on broadcast");
        this.loadGroups();
        this.loadContacts(true);
    });


    this.broadcaster.on<any>("onChatState").pipe(bufferTime(2000), filter(v => v.length > 0))
    .subscribe(msgs => {
      this.logger.info("[ContactRepository][onChatState] ", msgs);
      if (msgs.length > 0) {
        const ts = Date.now();
        msgs.forEach(msg => {
          if (msg.from.bare.indexOf("@conference.") > -1) {
            this.logger.info("[ContactRepository][onChatState] update contact", msg.from.resource);
            this.updateContactStatus(msg.from.resource, ts);
          } else {
            this.logger.info("[ContactRepository][onChatState] update contact", msg.from.bare);
            this.updateContactStatus(msg.from.bare, ts);
          }
        });
      }
    });


    this.updateContactsFromLdap$.pipe(bufferTime(1000), filter(v => v.length > 0)).subscribe(contacts => {
      const updatedContacts = CommonUtil.uniqBy(contacts, "bare");
      this.logger.info("[ContactRepository] updateContactsFromLdap$", updatedContacts);
      updatedContacts.forEach(c => {
        this.updateContactFromLDAP(c);
      });
    });

    this.updateContactsStore$.pipe(bufferTime(2000), filter(v => v.length > 0)).subscribe(contacts => {
      const updatedContacts = CommonUtil.uniqBy(contacts, "bare");
      this.logger.info("[ContactRepository][updateContactsStore]", updatedContacts);
      this.store.dispatch(new ContactBulkUpdate(updatedContacts));
      this.databaseService.createOrUpdateContacts(updatedContacts).subscribe();
    });

    this.configService.getLoadedConfig().subscribe(() => {
      if (this.isGroupManageEnabled !== this.configService.get("groupManagementViaDirectory")) {
        this.isGroupManageEnabled = this.configService.get("groupManagementViaDirectory");
      }
    });

    this.store.select(getNetworkInformation).pipe(distinctUntilChanged()).subscribe(information => {
      this.logger.info("[ContactRepository][getNetworkInformation]", this.isNetOnline);

      this.isNetOnline = information && information.onlineState;
    });

    this.store.select(getIsConnectedXMPP).pipe(filter(v => !!v)).subscribe(isConnected => {
      this.logger.info("[ContactRepository] getIsConnectedXMPP", isConnected);

      if (!window.appInBackground) {
        this.store.select(getIsAppBootstrapped).pipe(distinctUntilChanged()).subscribe(v => {
          if (!!v) {
            this.getContacts()[0].pipe(take(1)).subscribe(contacts => {
              this.syncLastActivityOfAllContacts(contacts);
            });
            setTimeout(() => {
              this.loadGroups();
              this.loadContacts();
              this.cleanupOmemomeDeviceSubscribers();
            }, 3000);
          }
        });
      } else {
        this.needToSyncContactsAndGroups = true;
      }
    });

    this.store.select(getIsContactLoading).subscribe(v => {
      this.contactsLoading = v;
      this.logger.info("[ContactRepository][getIsContactLoading] v");
    });

    this.store.select(getIsContactLoaded).subscribe(v => {
      this.contactsLoaded = v;
      this.logger.info("[ContactRepository][getIsContactLoaded] v");
    });

    this.store.select(getUserJID).pipe(filter(v => !!v)).subscribe(jid => {
      this.userJID = jid;
      this.store.select(getUserProfile).pipe(take(1)).subscribe(res => {
        this.fullName = res?.user?.firstName + " " + res?.user?.lastName;
        this.contact = {
            first_name: res?.user?.firstName,
            last_name: res?.user?.lastName,
            email: this.userJID?.bare
        };
        this.getContactVCard(this.userJID.bare).pipe(distinctUntilChanged()).subscribe(data => {
          let vCard = data || {};
          if (vCard.fullName) {
            this.fullName = vCard.fullName;
          }
          if (!!vCard.nicknames && !!vCard.nicknames[0]) {
            this.nickName = vCard.nicknames[0];
          }
        });
      });
    });

    document.addEventListener("resume", () => {
      this.logger.info("[ContactRepository][resume]");

      if (this.needToSyncContactsAndGroups) {
        setTimeout(() => {
          this.loadGroups();
          this.loadContacts();
        }, 5000);

        this.needToSyncContactsAndGroups = false;
      }

      // TODO: parsing batch contacts result locks the UI thread for seconds,
      // or even for one user from dev2 it locks it for 14 seconds (this userr has 1500 contacts).
      // Need to come up with a bette solution
      //
      // // retrieve last activity of all contacts on app resume
      //
      //   if (this.isNetOnline) {
      //     this.getContacts()[0].filter(contacts => contacts.length > 0).pipe(take(1)).subscribe(contacts => {
      //       this.syncLastActivityOfAllContacts(contacts);
      //     });
      //   }
    });

    // for web: retrieve last activity of all contacts every 5 mins
    if (!environment.isCordova){
      timer(0, this.configService.LAST_ACTIVITY_BATCH_ITERVAL).subscribe(() => {
        this.getContacts()[0].pipe(take(1)).subscribe(contacts => {
          this.logger.info("[ContactRepository][constructor] contacts", contacts.length);
          this.syncLastActivityOfAllContacts(contacts);
        });
      });
    }

    this.xmppService.getOnStampReceived().pipe(bufferTime(100), filter(res => res.length > 0)).subscribe((data) => {
      data.forEach(v => {
        // this.logger.info("[getOnStampReceived]", v);
        this.updateContactStatus(v.jid, v.timestamp);
      });
    });

    // add a contact on 1st message sent/received if not exist
    this.xmppService.getOnMessage().pipe(bufferTime(600), filter(res => res.length > 0)).subscribe((messages) => {
      messages.forEach(message => {
        if (this.userJID && message.type === "chat") {
          const msgFromJid = message.from.bare;
          const isSentMessage = this.userJID && msgFromJid === this.userJID?.bare;
          const contactTarget = isSentMessage ? message.to.bare : msgFromJid;
          // this.logger.info("[ContactRepository][getOnMessage]", message.to.bare, msgFromJid, message);
          this.createContactByTargetIfNotExists(contactTarget);
        }
      });
    });
  }

  createContactByTargetIfNotExists(contactTarget: string): void {
    if (!contactTarget) {
      return;
    }

    // alreaddy checked
    if (this.checkedContactsMap[contactTarget]) {
      return;
    }

    this.checkedContactsMap[contactTarget] = true;

    // search locally first
    this.searchUsersRedux(contactTarget).subscribe(contacts => {
      this.logger.info("[ContactRepository][createContactByTargetIfNotExists] searchUsersRedux: ", contacts, contactTarget);

      // search on server then
      if (!contacts || contacts.length === 0) {
        this.searchUsersOnServer(contactTarget).pipe(switchMap(fromLdapContacts => {
          return this.getOrCreateContactFromBareJidOnServer(contactTarget, fromLdapContacts && fromLdapContacts[0]);
        })).subscribe(() => {
          this.getLastActivity(contactTarget).subscribe(() => {

          });
        });
      }
    });
  }

  private updateContactFromLDAP(contact: ContactRest): void {
    // this.logger.info("[ContactRepository][updateContactFromLDAP]", contact);
    this.searchUsersOnLDAPServer(contact.bare).pipe(take(1)).subscribe(contacts => {
      // this.logger.info("[ContactRepository][updateContactFromLDAP] searchUsersOnLDAPServer res", contacts);
      if (contacts.length > 0) {
        const name = contacts[0].name;
        contact.name = name;
        if (name !== contact.bare.split("@")[0]) {
          contact.first_name = name.split(" ")[0];
          if (name.split(" ").length > 1) {
            contact.last_name = name.split(" ").splice(1).join(" ");
          }
          this.updateContactsStore$.next(contact);
          this.contactService.updateContact(contact).pipe(take(1)).subscribe(() => {

          });
        }
      }
    });
  }

  isAlreadyContact(bare:string) {
    return !!this.allContacts.find(c => c.jid === bare && !this.isInHiddenGroup(c));
  }

  getProfile(data) {
    return this.contactService.getProfile(data);
  }

  isExternalUser(target: string): boolean {
    if (!target) {
      return false;
    }

    let isExternal = false;
    this.store.select(getDomain).pipe(take(1)).subscribe(domain => {
      if (!!domain && !!target) {
        if (target.indexOf(`@conference`) !== -1) {
          isExternal = target && target.indexOf(`@conference.${domain}`) === -1;
        } else {
          isExternal = target && target.indexOf(`@${domain}`) === -1;
        }
      }
    });
    return isExternal;
  }

  isUpdateableContact(contact: any): boolean {
    // this.logger.info("[ContactRepository][isUpdateableContact] ", contact);
    if (!contact.bare) {
      return false;
    }
    if (contact.bare.indexOf("@") < 0) {
      return false;
    }
    if (this.isExternalUser(contact.bare)) {
      return false;
    }
    return true;
  }

  private loadContacts(forceUpdate?: boolean) {
    this.logger.info("[ContactRepository][loadContacts]");

    this.store.dispatch(new ContactLoadRequest());

    this.loadUpdatedContacts(forceUpdate).subscribe(contacts => {
      contacts = contacts.sort(CommonUtil.sortBy("name"));
      this.logger.info("[ContactRepository][loadAllContacts] contacts", contacts.length, contacts);
      this.contactsList$.next(contacts);
      this.store.dispatch(new ContactBulkAdd(contacts));

      if (contacts.length > 0) {
        this.databaseService.createOrUpdateContacts(contacts).subscribe();
      }

      contacts.forEach(contact => {
        if (contact.bare && contact.first_name.toLowerCase() === contact.bare.split("@")[0]
          && !contact.last_name && this.isUpdateableContact(contact) && !contact.bare.startsWith("broadcast-")) {
          this.updateContactsFromLdap$.next(contact);
        }
      });

      // Retrieve last activity of all contacts or diffs on app start.
      // For mobile we also call 'syncLastActivityOfAllContacts' from TalkComponent when restore contacts from DB
      this.syncLastActivityOfAllContacts(contacts);
      this.processResyncContacts();
    });
  }

  private loadGroups() {
    this.logger.info("[ContactRepository][loadGroups]");
    this.store.dispatch(new GroupLoadRequest());
    this.loadAllGroups().subscribe(groups => {
      groups = groups.sort(CommonUtil.sortBy("name"));
      if (!groups.find(v => v.name === ConstantsUtil.FAVOURITE_LIST_NAME)) {
        this.contactService.createGroup(ConstantsUtil.FAVOURITE_LIST_NAME).subscribe(res => {
          // this.logger.info("[ContactRepository][createGroup] res", res);
          this.store.dispatch(new GroupAdd(res.contact_group as Group));
        });
      }
      if (!groups.find(v => v.name === ConstantsUtil.HIDDEN_LIST_NAME)) {
        this.contactService.createGroup(ConstantsUtil.HIDDEN_LIST_NAME).subscribe(res => {
          // this.logger.info("[ContactRepository][createGroup] res", res);
          this.store.dispatch(new GroupAdd(res.contact_group as Group));
        });
      }
      this.logger.info("[ContactRepository][loadGroups] groups", groups);
      this.store.dispatch(new GroupLoadSuccess(groups));
      this.store.dispatch(new GroupBulkAdd(groups));
    });
  }

  private loadUpdatedContacts(force?: boolean): Observable<any[]> {
      // this.logger.info("[ContactRepository][loadUpdatedContacts]");

      // this.contactService.deleteContact(660).subscribe();
      // this.contactService.deleteContact(661).subscribe();

      const trigger = new BehaviorSubject<number>(0);
      return trigger.asObservable().pipe(concatMap(offset => {
        if (force && localStorage.getItem("lastContactsFetchTimeStamp")) {
          let timestamp = new Date(localStorage.getItem("lastContactsFetchTimeStamp")).getTime();
          timestamp = timestamp - 10 * 60 * 1000;
          localStorage.setItem("lastContactsFetchTimeStamp", new Date(timestamp).toISOString());
        }
        // updated_after logic (only for mobile)
        const lastContactsFetchTimeStamp = this.lastContactsFetchTimeStamp();
        let params = {offset};
        params["limit"] = this.configService.CONTACTS_REST_PER_PAGE;
        this.logger.info("[ContactRepository][loadUpdatedContacts] params", JSON.stringify(params));

        return this.getContactsFromAPI(params)
          .pipe(map(res => {
            this.logger.info("[loadCOntactsFromAPI] res: ", res);
            let contacts = res.contacts.map(v => this.processAPIContact(v));
            let contactIds = res.contacts.map(v => v.id);
            this.allApiContactIds = contactIds.concat(this.allApiContactIds);
            this.logger.info("[ContactRepository][loadUpdatedContacts] getContactsFromAPI res total_count", res.total_count, contacts.count, offset, this.allApiContactIds);

            offset = offset + this.configService.CONTACTS_REST_PER_PAGE;
            if (res.total_count > offset ) {
              trigger.next(offset);
            } else {
              // save updated_after
              this.setLastContactsFetchTimeStamp();

              trigger.complete();
            }
            return contacts;
          }));
        }
      ), takeWhile((contacts: any) => contacts.length > 0)
        , reduce((accumulator, contacts: any) => {
          return [...accumulator, ...contacts];
        }, []));
  }

  private lastContactsFetchTimeStamp() {
    let date = localStorage.getItem("lastContactsFetchTimeStamp");
    if (date) {
      date = date.split(".")[0];
      if (date[date.length - 1] !== "Z") {
        date = date + "Z"; // compatibility, previously we used with milliseconds
      }
    }
    // this.logger.info("[ContactRepository][lastContactsFetchTimeStamp]", date);
    return date;
  }

  private setLastContactsFetchTimeStamp() {
    const date = new Date().toISOString().split(".")[0] + "Z"; // remove milliseconds
    // this.logger.info("[ContactRepository][setLastContactsFetchTimeStamp]", date);
    localStorage.setItem("lastContactsFetchTimeStamp", date);
  }

  private cleanLastContactsFetchTimeStamp() {
    // this.logger.info("[ContactRepository][cleanLastContactsFetchTimeStamp]");
    localStorage.removeItem("lastContactsFetchTimeStamp");
  }

  private loadAllGroups(): Observable<Group[]> {
    const trigger = new BehaviorSubject<number>(0);
    return trigger.asObservable().pipe(concatMap(offset =>
      this.contactService.getGroups({offset}).pipe(map((res: any) => {
          let groups = res.contact_groups.map(v => v as Group);
          if (res.total_count > 25 ) {
            offset = offset + 25;
            trigger.next(offset);
          }
          else {
            trigger.complete();
          }
          return groups;
        })
    )), takeWhile(groups => groups.length > 0)
      , reduce((accumulator, groups) => {
        return [...accumulator, ...groups];
      }, []));
  }

  public getLastActivity(bare: string): Observable<any> {
    const response = new Subject<any>();

    this.xmppService.getLastActivity(bare).pipe(take(1)).subscribe(res => {
      this.logger.info("[ContactRepository][getLastActivity] " + bare, res.lastActivity);

      const lastActivitySeconds = parseInt(res.lastActivity);
      this.store.dispatch(new ConversationUpdateLastActivity({target: bare, seconds: lastActivitySeconds}));
      this.store.dispatch(new ContactStatusUpdate(this.contactStatusUpdateData(bare, lastActivitySeconds)));
      const thisDomain = this.userJID?.bare.split("@")[1];
      const bareDomain = bare.split("@")[1];
      if ((this.xmppService.rosterItemJids.indexOf(bare) < 0) && (thisDomain !== bareDomain))  {
        this.xmppService.addBareToRoster(bare);
      }
      response.next(res.lastActivity);
    }, err => {
      this.logger.error("[ContactRepository][getLastActivity] error", err);
      response.error(err);
    });
    return response.asObservable().pipe(take(1));
  }

  private getLastActivityBatch(bareJids: string[]): Observable<any> {
    this.logger.info("[ContactRepository][getLastActivityBatchCheck]", bareJids);
    if (bareJids.indexOf(ConstantsUtil.ALL_TARGET) > -1) {
      bareJids.splice(bareJids.indexOf(ConstantsUtil.ALL_TARGET), 1);
    }
    const response = new Subject<any>();

    this.xmppService.getLastActivityBatch(bareJids).subscribe(res => {
      let statusArray = [];
      if (res && res.lastActivityBatchResults) {
        // this.logger.info("[ContactRepository][getLastActivityBatch] result", res.lastActivityBatchResults);
        for (let jid of Object.keys(res.lastActivityBatchResults)) {
          // this.logger.info("[ContactRepository][getLastActivityBatch]", jid, res.lastActivityBatchResults[jid]);
          const lastActivitySeconds = parseInt(res.lastActivityBatchResults[jid].seconds);

          // TODO: make a batch update
          this.store.dispatch(new ConversationUpdateLastActivity({target: jid, seconds: lastActivitySeconds}));
          statusArray.push(this.contactStatusUpdateData(jid, lastActivitySeconds));
        }

        this.store.dispatch(new ContactBulkStatusUpdate(statusArray));

        response.next(res.lastActivityBatchResults);
      } else {
        response.next({});
      }

    }, err => {
      response.error(err);
    });
    return response.asObservable().pipe(take(1));
  }

  syncLastActivityOfAllContacts(contacts) {
    // this.logger.info("[ContactRepository][syncLastActivityOfAllContacts] " + contacts.length);

    if (contacts.length === 0) {
      return;
    }

    this.appService.onXmppConnect().pipe(take(1)).subscribe(() => {
      const contactsBareJids: string[] = contacts.filter(v => !!v && v !== ConstantsUtil.ALL_TARGET && !this.isInHiddenGroup(v)).map(c => c.bare);
      this.getLastActivityBatch(contactsBareJids);
    });
  }

  private isInHiddenGroup(contact) {
    const groups = contact.groups;
    return (groups.length > 0) ? !!groups.find(g => g.name === ConstantsUtil.HIDDEN_LIST_NAME) : false;
  }

  updateContactStatus(jid: string, timestamp: number) {
    const lastActivitySeconds = Math.abs(new Date().getTime() - timestamp) / 1000;
    // this.logger.info("[updateContactStatus]", jid, timestamp, lastActivitySeconds);
    this.store.dispatch(new ConversationUpdateLastActivity({target: jid, seconds: lastActivitySeconds}));
    this.store.dispatch(new ContactBulkStatusUpdate([this.contactStatusUpdateData(jid, lastActivitySeconds)]));
  }

  private contactStatusUpdateData(jid: string, lastActivitySeconds: number) {
    return {jid: jid,
         status: (lastActivitySeconds > -1 && lastActivitySeconds < this.configService.LAST_ACTIVITY_ONLINE_THRESHHOLD)
          ? UserStatus.ONLINE : UserStatus.OFFLINE};
  }

  public getContacts(): [Observable<Contact[]>, Observable<boolean>] {
    const loading$ = this.store.select(getIsContactLoading);
    return [
      this.store.select(getContacts),
      loading$
    ];
  }

  getAllContacts() {
    return this.store.select(getContacts);
  }

  public getGroups(): [Observable<Group[]>, Observable<boolean>] {
    const loading$ = this.store.select(getIsGroupLoading);
    return [
      this.store.select(getGroups),
      loading$
    ];
  }

  public getTargetPhoto(bareId: string): Observable<Photo> {
    return this.store.select(state => getContactPhotoById(state, bareId));
  }

  public getContactVCard(bareId: string): Observable<ContactInformation> {
    return this.store.select(state => getContactVCardById(state, bareId));
  }

  public updateContactFromVCard(bareId: string) {
    this.xmppService.updateContactFromVCard(bareId);
  }

  public getContactCompany(bareId: string): Observable<string> {
    return this.store.select(state => getContactVCardById(state, bareId)).pipe(map(res => {
      if (res && res.organization) {
        return res.organization.name;
      }
      return "";
    }));
  }

  private searchUsersRedux(keyword: string): Observable<SearchUser[]> {
    // search locally first
    const rosterContacts$ = this.store.select(getContacts)
      .pipe(map(contacts => {
        if (!keyword) {
          return [];
        }

        return contacts
          .filter(contact => (contact.name && contact.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1)
            || contact.bare?.toLowerCase().startsWith(keyword.toLowerCase()))
          .map(contact => {
            return {
              name: contact.name || contact.local,
              jid: contact.bare,
              email: (contact.emails && contact.emails[0] && contact.emails[0].email) || contact.bare,
              ldapData: {}};
          });
      }), take(1));

    return rosterContacts$;
  }

  public searchUsersOnServer(keyword: string): Observable<SearchUser[]> {
    // search locally first
    const rosterContacts$ = this.searchUsersRedux(keyword);

    if (this.isNetOnline) {
      return forkJoin(rosterContacts$, this.searchUsersOnLDAPServer(keyword)).pipe(map(contacts => {
        let rosterContacts = contacts[0];
        let ldapContacts = contacts[1];

        const ldapContactsJids = ldapContacts.map(c => c.jid);
        rosterContacts = rosterContacts.filter(c => ldapContactsJids.indexOf(c.jid) === -1);

        const allContacts = CommonUtil.uniqBy([...rosterContacts, ...ldapContacts], user => user.jid);
        return allContacts;
      }));
    } else {
      return rosterContacts$;
    }
  }

  public searchUsersOnLDAPServer(keyword: string): Observable<SearchUser[]> {
    return this.contactService.searchLDAPUsers(keyword).pipe(take(1));
  }

  public searchIOMUsers(keyword: string): Observable<SearchUser[]> {
    return this.contactService.searchIOMUsers(keyword).pipe(take(1));
  }

  public retrieveLDAPUsers(jids: string[]): Observable<SearchUser[]> {
    return this.contactService.retrieveLDAPUsers(jids).pipe(take(1));
  }

  public getContactById(bare: string): Observable<Contact> {
    return this.store.select(state => getContactById(state, bare));
  }

  public renderMentionUsers(content: string, type: string = "html", references?: any[], highlightedBare?: string, addHightLight?: boolean): string {
    if (!content) {
      return "";
    }
    let mentions = CommonUtil.parseMentions(content);
    if (mentions.length > 0) {
      mentions.forEach(mention => {
        let replaceLink = new RegExp(`(@<span href="javascript:void\\(0\\)" class="open-new-window">${mention}<\/span>)`, "igm");
        content = content.replace(replaceLink, `@${mention}`);
        let query = new RegExp(`(@${mention})\s*` , "gim");
        const isHighlighted = highlightedBare && highlightedBare === mention;
        if (this.userJID && this.userJID?.bare === mention) {
          this.store.select(getUserProfile).pipe(filter(p => !!p), take(1)).subscribe(profile => {
            const name = profile.user.fullName;
            content = CommonUtil.replaceName(type, content, query, name, isHighlighted, addHightLight ? highlightedBare : "");
          });

        } else {
          this.getContactById(mention).pipe(take(1)).subscribe(contact => {
            if (!!contact) {
              const name = contact.name || contact.local;
              content = CommonUtil.replaceName(type, content, query, name, isHighlighted, addHightLight ? highlightedBare : "");
            } else {
              const name = mention.split("@")[0];
              content = CommonUtil.replaceName(type, content, query, name, isHighlighted, addHightLight ? highlightedBare : "");
            }
          });
        }
      });
      if (content.match(/@all\b/ig) && type === "html") {
        content = content.replace(/@all\b/ig, `<span class="mentioned-user">@all</span>`);
      }
    } else if (content.match(/@all\b/ig) && type === "html") {
      content = content.replace(/@all\b/ig, `<span class="mentioned-user">@all</span>`);
    }
    return content;
  }

  public getContactsByIds(bare: string[]): Observable<Contact[]> {
    return this.store.select(state => getContactsByIds(state, bare));
  }

  public getPrettyUsername(bare: string) {
    if (!bare) {
      return null;
    }
    let username = bare.split("@")[0]; // get hid local part
    username = username.replace(".", " "); // replace dot with space
    username = username.replace(/\b\w/g, word => word.toUpperCase()); // capitalise each word

    return username;
  }

  public getFullName(bare: any, returnYouForLoggedInUser: boolean = false) {
    if (!bare) {
      return "";
    }

    let conversation: Conversation;
    this.store.select((state) => getConversationById(state, bare)).pipe(take(1)).subscribe(c => conversation = c);

    let loggedInJID: JID;
    this.store.select(getUserJID).pipe(take(1)).subscribe(j => loggedInJID = j);

    if (loggedInJID && (bare === loggedInJID.bare) && returnYouForLoggedInUser) {
      return "You";
    }

    if (loggedInJID && (bare === loggedInJID.bare) && !!this.fullName && (this.fullName !== "")) {
      return this.fullName;
    }

    if (conversation && conversation.groupChatTitle) {
      return conversation.groupChatTitle;
    }
    if (conversation && conversation.Target.startsWith("broadcast-")) {
      return conversation.broadcast_title;
    }

    let contact: any;
    this.getContactById(bare).pipe(take(1)).subscribe(c => {
      contact = c;
    });

    if (contact) {
      // this.logger.info("[contactRepo][getNickName] fullname " + bare + ": ", contact);
      if (!!contact.first_name && !!contact.middle_name && !!contact.last_name) {
        return contact.first_name + " " + contact.middle_name + " " + contact.last_name;
      }
      if (!!contact.first_name && !!contact.last_name) {
        return contact.first_name + " " + contact.last_name;
      }

      return contact.name || contact.local;
    }

    return CommonUtil.beautifyName(bare.split("@")[0]);
  }

  public getNickName(bare: any, returnYouForLoggedInUser: boolean = false) {
    if (!bare) {
      return "";
    }

    let conversation: Conversation;
    this.store.select((state) => getConversationById(state, bare)).pipe(take(1)).subscribe(c => conversation = c);

    let loggedInJID: JID;
    this.store.select(getUserJID).pipe(take(1)).subscribe(j => loggedInJID = j);

    if (loggedInJID && (bare === loggedInJID.bare) && returnYouForLoggedInUser) {
      return "You";
    }

    if (loggedInJID && (bare === loggedInJID.bare) && !!this.nickName && (this.nickName !== "")) {
      return this.nickName;
    }

    if (loggedInJID && (bare === loggedInJID.bare) && !!this.fullName && (this.fullName !== "")) {
      return this.fullName;
    }

    if (conversation && ConversationUtil.isGroupChat(conversation)) {
      return conversation.groupChatTitle;
    }
    if (conversation && conversation.Target.startsWith("broadcast-")) {
      return conversation.broadcast_title;
    }

    let contact: any;
    this.getContactById(bare).pipe(take(1)).subscribe(c => {
      contact = c;
    });

    if (contact) {
      // this.logger.info("[contactRepo][getNickName] " + bare + ": ", contact);
      return contact.name || contact.local;
    }

    return CommonUtil.beautifyName(bare.split("@")[0]);
  }


  public publishVCards(data: ContactInformation): Observable<any> {
    this.logger.info("[ContactRepository][publishVCards]", new Date(), data);

    return this.xmppService.publishVCards(data).pipe(map(res => {
      let vCard: VCard = {
        jid: this.userJID,
        vCard: data
      };
      this.store.dispatch(new ContactAddVCard(vCard));
      if (!!data.photo && !!data.photo.data) {
        const avatarId = CommonUtil.md5(data.photo.data);
        this.xmppService.sendAvatarPresence(avatarId);
      } else {
        this.xmppService.sendAvatarPresence();
      }
      return res;
    }));
  }

  public uploadGroupAvatar(target: string, photo: any): Observable<any> {
    this.logger.info("[ContactRepository][uploadGroupAvatar] target", target);
    if (this.isGroupManageEnabled) {
      return this.groupChatsService.updateGroupAvatar(target, photo).pipe(map( res => {
        return res;
      }));
    } else {
      return this.xmppService.uploadGroupAvatar(target, photo).pipe(map(res => {
        return res;
      }));
    }
  }

  // TODO: will not work anymore since we are mooving away from presences
  // so need to change somehow
  public publishNick(nick: string): Observable<any> {
    return this.xmppService.publishNick(nick);
  }

  public sendVcards(vCards: string) {
    this.xmppService.sendVcards(vCards);
  }

  public removeGroupFromContacts(groupId: number, contacts: Contact[]): void {
    contacts =  contacts.map((contact) => {
      contact.groups =  contact.groups.filter(group => group.id !== groupId);
      return contact;
    });
    this.store.dispatch(new RemoveGroupFromContacts({contacts, groupId}));
  }

  public addGroupToContact(group: Group, contact: Contact): void {
    this.store.select(state => getMembersByGroupId(state, group.id)).pipe(take(1)).subscribe(contacts => {
      let ids = contacts.map(c => c.id);
      ids.push(contact.id);
      this.contactService.updateGroupContacts(group.id, ids).subscribe(() => {
        // send update contact
        this.broadcaster.broadcast("sendUpdateContacts");
        this.store.dispatch(new AddGroupToContacts({contacts: [contact], group: group}));
      });
    });
  }

  public updateGroupContacts(groupId: number, ids: number[]): Observable<any> {
    return this.contactService.updateGroupContacts(groupId, ids);
  }

  public notifyOnAvatarUpdate(): Observable<null> {
    this.broadcaster.broadcast("userAvatarUpdated");
    return this.contactService.notifyOnAvatarUpdate();
  }

  private processXmppContact(rawContact: Contact): Contact {
    let oldGroups = rawContact.groups;
    let isGeneralAdded = false;

    if (isNullOrUndefined(oldGroups) || oldGroups.length === 0) {
      oldGroups = [{id: 0, name: ConstantsUtil.TITLE_GENERAL_GROUP}];
      isGeneralAdded = true;
    }

    return {
      ...rawContact,
      groups: oldGroups,
      isGeneralAdded: isGeneralAdded
    };
  }

  private processAPIContact(rawContact: any): any {
    let contact: any = {
      id: rawContact.id,
      created_at: new Date(rawContact.created_at),
      updated_at: new Date(rawContact.updated_at),
      is_company: rawContact.is_company,
      first_name: rawContact.first_name,
      middle_name: rawContact.middle_name,
      last_name: rawContact.last_name,
      company: rawContact.company,
      job_title: rawContact.job_title,
      bare: "",
      domain: "",
      full: "",
      avatar_url: rawContact?.avatar,
      dob: rawContact?.birthday,
      timezone: rawContact?.time_zone,
      products: rawContact?.products,
      language: rawContact?.language
    };

    let name = [];
    if (rawContact.first_name) {
      name.push(rawContact.first_name);
    }
    if (rawContact.deleted_at) {
      name.push(new Date(rawContact.deleted_at));
    }
    if (rawContact.middle_name) {
      name.push(rawContact.middle_name);
    }
    if (rawContact.last_name) {
      name.push(rawContact.last_name);
    }

    contact.name = name.join(" ");

    if (rawContact.phones) {
      contact.phones = rawContact.phones;
    }
    if (rawContact.jid) {
      contact.bare = rawContact.jid;
      contact.full = rawContact.jid;
      contact.domain = rawContact.jid.split("@")[1];
      if (rawContact.emails) {
        contact.emails = rawContact.emails;
      } else {
        contact.emails = [{email: rawContact.jid}];
      }
    } else {
      if (rawContact.emails) {
        contact.emails = rawContact.emails;
        contact.bare = rawContact.emails[0].email;
        contact.full = rawContact.emails[0].email;
        contact.domain = rawContact.emails[0].email.split("@")[1];
      }
    }
    if (rawContact.addresses) {
      contact.addresses = rawContact.addresses;
    }

    if (contact.bare && contact.name.trim() === contact.bare.split("@")[0]) {
      contact.name = CommonUtil.beautifyName(contact.name.trim());
    }

    let oldGroups = rawContact.groups || [];
    let isGeneralAdded = false;

    if (isNullOrUndefined(oldGroups) || oldGroups.length === 0) {
      oldGroups = [{id: 0}];
      isGeneralAdded = true;
    }

    if (rawContact?.is_global) {
      contact.user_id = rawContact?.user_id;
    }

    return {
      ...contact,
      ...rawContact,
      groups: oldGroups,
      isGeneralAdded: isGeneralAdded
    };
  }

  public getUserProfile() {
    return this.store.select(getUserProfile);
  }

  private getContactsFromAPI(params: any): Observable<any> {
    return this.contactService.getContacts(params);
  }

  getContactByJid(jid: string): Observable<any> {
    return this.contactService.getContacts({jid}).pipe(map(res => {
      const contacts = res.contacts.map(v => this.processAPIContact(v));
      this.store.dispatch(new ContactBulkUpdate(contacts));
      this.databaseService.createOrUpdateContacts(contacts).subscribe();
      return contacts[0];
    }));
  }

  removeContactFromGroup(contact: Contact, groupId: number) {
    this.store.select(state => getMembersByGroupId(state, groupId)).pipe(take(1)).subscribe(contacts => {
      const ids = contacts.filter(c => c.id !== contact.id).map(c => c.id);
      this.contactService.updateGroupContacts(groupId, ids).subscribe(res => {
        this.logger.info("[updateGroupContacts]", groupId, res);
        this.broadcaster.broadcast("sendUpdateContacts");
        this.store.dispatch(new RemoveGroupFromContact({contact, groupId}));
      });
    });
  }

  removeContactFromHidden(contact: Contact) {
    this.store.select(state => getGroupsByName(state, ConstantsUtil.HIDDEN_LIST_NAME)).pipe(take(1)).subscribe(g => {
      this.logger.info("removeFromHidden group: ", g);
      if (!!g[0] && !!g[0].id) {
        if (!!contact.id) {
          this.removeContactFromGroup(contact, g[0].id);
        } else {
          this.store.select(state => getContactById(state, contact.bare)).pipe(take(1)).subscribe(c => {
            this.removeContactFromGroup(c, g[0].id);
          });
        }
      }
    });
  }

  createGroup(groupName: string, contacts: Contact[]): Observable<any> {
    const ids = contacts.map(c => c.id);
    return this.contactService.createGroup(groupName, ids).pipe(map(res => {
      const group = res.contact_group as Group;
      this.store.dispatch(new GroupAdd(group));
      this.store.dispatch(new AddGroupToContacts({contacts, group}));
      this.logger.info("[createGroup]", res);
      return group;
    }));
  }

  deleteGroup(groupId: number): Observable<any> {
    return this.contactService.deleteGroup(groupId).pipe(map(res => {
      this.logger.info("[deleteGroup]", groupId, res);
      this.store.select(state => getMembersByGroupId(state, groupId)).pipe(take(1)).subscribe(contacts => {
        this.store.dispatch(new RemoveGroupFromContacts({contacts, groupId}));
        this.store.dispatch(new GroupDelete(groupId));
      });
      return res;
    }));

  }
  renameGroup(groupId: number, groupName: string): Observable<any> {
    return this.contactService.updateGroupName(groupId, groupName).pipe(map(res => {
      this.logger.info("[updateGroupName]", groupId, res);
      this.store.dispatch(new GroupUpdate(res.contact_group as Group));
      this.store.dispatch(new ContactLoadRequest());
      this.loadContacts(true);
      setTimeout(() => {
        this.loadGroups();
      }, 1200);
      return res;
    }));
  }

  getOrCreateContactOnServer(contact: ContactRest): Observable<any> {
    this.logger.info("[ContactRepository][getOrCreateContactOnServer]", contact);
    const subject = new Subject();
    this.contactService.getContacts({jid: contact.bare}).subscribe(v => {
      this.logger.info("[ContactRepository][getOrCreateContactOnServer] getContacts", v);

      const contacts = v && v.contacts || [];
      if (contacts && contacts.length > 0) {
        let contact = this.processAPIContact(contacts[0] as Contact);
        this.store.dispatch(new ContactAdd(contact));
        subject.next(contact);
      } else {
        this.contactService.createContact(contact).subscribe(res => {
          if (res?.contact) {
            let contact = this.processAPIContact(res.contact as Contact);
            this.store.dispatch(new ContactAdd(contact));
            subject.next(contact);
          } else {
            subject.error(null);
          }
        });
      }
    });
    return subject.asObservable();
  }

  getOrCreateContactFromBareJidOnServer(target: string, ldapContact?: SearchUser): Observable<ContactRest> {
    const response = new Subject<ContactRest>();

    let newContact: ContactRest = {
      first_name: target.split("@")[0],
      bare: target,
      full: target,
      domain: target.split("@")[1],
      emails: [{email: (ldapContact && ldapContact.email) || target}]
    };

    if (ldapContact) {
      const contactName = ldapContact.name.split(" ");
      newContact.first_name = contactName[0];
      if (contactName.length > 1) {
        newContact.last_name = contactName.slice(1).join(" ");
      }
    }

    this.logger.info("[ContactRepository][getOrCreateContactFromBareJidOnServer]", target, ldapContact, newContact);

    this.getOrCreateContactOnServer(newContact).subscribe(c => {
      this.logger.info("[ContactRepository][getOrCreateContactOnServer]", c);
      response.next(c);
    }, err => {
      this.logger.error("[ContactRepository][getOrCreateContactFromBareJidOnServer] error", err);
      response.next(null);
    });

    return response.asObservable().pipe(take(1));
  }


  addNewContactToContactsList(target: string, ldapContact?: SearchUser): Observable<ContactRest> {
    const response = new Subject<ContactRest>();

    let newContact: ContactRest = {
      first_name: target.split("@")[0],
      bare: target,
      full: target,
      domain: target.split("@")[1],
      emails: [{email: (ldapContact && ldapContact.email) || target}]
    };

    if (ldapContact) {
      const contactName = ldapContact.name.split(" ");
      newContact.first_name = contactName[0];
      if (contactName.length > 1) {
        newContact.last_name = contactName.slice(1).join(" ");
      }
      if (ldapContact.companyName) {
        newContact.company = ldapContact.companyName;
      }
      if (ldapContact.email) {
        if (!newContact.emails.filter(value => value.email == ldapContact.email).length) {
          newContact.emails.unshift({email: ldapContact.email});
        }
      }
      if (ldapContact.position) {
        newContact.job_title = ldapContact.position;
      }
    }

    this.logger.info("[ContactRepository][getOrCreateContactFromBareJidOnServer]", target, ldapContact, newContact);

    this.getOrCreateContactOnServer(newContact).subscribe(c => {
      this.logger.info("[ContactRepository][getOrCreateContactOnServer]", c);
      response.next(c);
    }, err => {
      this.logger.error("[ContactRepository][getOrCreateContactFromBareJidOnServer] error", err);
      response.next(null);
    });

    return response.asObservable().pipe(take(1));
  }

  highlightedSearchText(searchMessage: SearchMessage, keyword: string) {
    let prefix = "";
    let searchBody = searchMessage.body;
    if (searchMessage.room) {
      const fullName = this.getFullName(searchMessage.from, true);
      prefix = "<span class=\"bold-sender\">" + fullName + ":</span> ";
    }
    if (searchMessage.mention) {
      try {
        const references = JSON.parse(searchMessage.mention);
        searchBody = this.renderMentionUsers(searchBody, "html", references, keyword, false);
        let content = CommonUtil.linkify(searchBody.replace(/\\n/g, "<br />"));
        return prefix + CommonUtil.highlightWord(content, keyword);
      } catch (ex) {
      }

    }
    let content = CommonUtil.linkify(searchBody.replace(/\\n/g, "<br />"));
    return prefix + CommonUtil.highlightSearch(content, keyword);
  }

  public isContactFavorite(bareId: string): Observable<boolean> {
    return this.getContactById(bareId).pipe(take(1)).pipe(map(() => {
    //  return contact && contact.groups.filter(g => g.name === ConstantsUtil.FAVOURITE_LIST_NAME).length > 0;
      // this.logger.info("ContactRepository isContactFavorite processing: ", contact);
      return false;
    }));
  }

  public searchContacts(value: string): Observable<Recipient[]> {
    let userJID;
    this.store.select(getUserJID).pipe(filter(v => !!v)).subscribe(jid => {
      userJID = jid;
    });
    return this.searchUsersOnServer(value).pipe(take(1), map(users => {
      const contacts = users.filter(u => !userJID || userJID && u.email !== userJID.bare).map(u => {
        const recipient: Recipient = { target: u.email, title: u.name, type: "user", ldapData: u.ldapData, isExternalUser: this.isExternalUser(u.email) };
         if (environment.theme === "hin") {
          if (u.ldapData && Object.keys(u.ldapData).length > 0)  {
            let additionalInfo = [];
            let title = [];
            if (u.ldapData.company) {
              additionalInfo.push(u.ldapData.company[0]);
            }
            if (u.ldapData.l) {
              additionalInfo.push(u.ldapData.l[0]);
            }
            if (u.ldapData.title) {
              title.push(u.ldapData.title[0]);
            }
            if (u.ldapData.givenName) {
              title.push(u.ldapData.givenName[0]);
            }
            if (u.ldapData.sn) {
              title.push(u.ldapData.sn[0]);
            }
            if (additionalInfo.length > 0) {
              recipient.additionalInfo = additionalInfo.join(", ");
            }
            if (title.length > 0) {
              recipient.title = title.join(" ");
            }
            this.logger.info("[contact-list.component] searchUsers hin receipient", recipient);
          } else {
            this.getContactCompany(u.email).pipe(take(1)).subscribe(companyName => {
              this.logger.info("[contact-list.component] searchUsers hin", companyName);
              if (companyName) {
                recipient.additionalInfo = companyName;
              }
            });
          }
        } else if (recipient.isExternalUser) {
          if (u.ldapData && !!u.ldapData.company) {
            recipient.companyName = u.ldapData.company[0];
          } else {
            this.getContactCompany(u.email).pipe(take(1)).subscribe(companyName => {
              recipient.companyName = companyName;
            });
          }
        }
        recipient.title = CommonUtil.highlightSearch(recipient.title, value);
        return recipient;
      });
      return contacts;
    }));
  }

  getGroupsByName(name: string) {
    this.store.select(state => getGroupsByName(state, ConstantsUtil.HIDDEN_LIST_NAME)).pipe(take(1)).subscribe(groups => {
      return groups;
    });
  }

  deleteAvatar(target: string) {
    return this.contactService.deleteAvatar(target);
  }

  cleanupOmemomeDeviceSubscribers() {
    this.configService.getOMEMODeviceSubscribers().subscribe(res => {
      this.logger.info("[contact.repo][cleanupOmemomeDeviceSubscribers] subscribers: ", res, this.userJID);
      const subscribersToRemove = res.filter(s => ((s.indexOf("/") > -1) && (s !== this.userJID.full)));
      this.logger.info("[contact.repo][cleanupOmemomeDeviceSubscribers] subscribersToRemove: ", subscribersToRemove);
      this.xmppService.removeOmemoSubscribers(subscribersToRemove);
    });
  }

  broadcastAvatarUpdate() {
    this.broadcaster.broadcast("AVATARUPDATED");
  }

  processResyncContacts() {
    this.databaseService.fetchContacts().subscribe(dbContacts => {
      this.logger.info("processResyncContacts dbContacts: ", dbContacts);
      let contactsToDelete = [];
      for (let i = 0; i < dbContacts.length - 1; i++) {
        const id = dbContacts[i].id;
        if (this.allApiContactIds.indexOf(id) === -1) {
          contactsToDelete.push(id);
          // ContactDelete
          this.store.dispatch(new ContactDelete(id));
          // this.logger.info("processResyncContacts need to delete dbContact: ", dbContacts[i]);
        }
      }
      if (contactsToDelete.length > 0) {
        this.logger.info("processResyncContacts need to delete dbContact ids: ", contactsToDelete);

        this.databaseService.deleteContacts(contactsToDelete).subscribe(res => {

        });

      }
    });
  }
}
