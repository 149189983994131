<div *ngIf="!data?.isMobileView" class="smart-link-popup-container" [ngClass]="{'height-fit-content': ($currentStage | async) === 2, 'height-full-screen': ($fullScreen | async)}">
  <vnc-header [mode]="headerMode.SMART_LINK"
              [title]="($headerTitle | async)"
              (onBackClick)="toggleHeaderTab()"
              (onSmartLinkHeaderTabClick)="toggleHeaderTab($event)"
              [fullScreen]="$fullScreen | async"
              (toggleFullScreen)="onToggleFullScreen()"
              [activeSmartLinkHeaderTab]="($activeHeaderTab | async)"
              cdkDrag
              cdkDragRootElement=".cdk-overlay-pane"
              [cdkDragDisabled]="$fullScreen | async"
              [ngClass]="{'cursor-move': ($fullScreen | async) === false}"></vnc-header>

  <div class="stage-1" *ngIf="($currentStage | async) === 1">
    <vnc-smartlink-input [ngClass]="{'hide-cross': ($visibleCategories | async)?.includes('everywhere') || ($visibleCategories | async)?.length < 2 || ($visibleCategories | async)?.length > 5}"
                         class="smartlink-input flex" [searchControl]="smartlinkControl.search"
                         (onClear)="smartlinkForm.patchValue({ search: '' })"
                         (onCategoryChange)="onCategoryChange($event)"
                         [options]="options"
                         [showClear]="true"></vnc-smartlink-input>

    <div [ngClass]="{'expanded-filter': ($showAllFilters | async)}" class="filters-block flex flex-wrap items-end" #smartLinkFilters>

      <div [ngClass]="($showAllFilters | async) ? 'smartlink-chips-expanded' : 'smartlink-chips-collapsed'">

        <!-- GLOBAL CHIPS -->
        <ng-container>
          <vnc-chips class="smart-link-popup-chip" [text]="'UNREAD' | translate"
                     [active]="globalFilters[filterType.GLOBAL_UNREAD]"
                     (clickEvent)="updateGlobalFilters(filterType.GLOBAL_UNREAD)"
                     leftIcon="unread" type="filter"
                     size="s"></vnc-chips>
          <vnc-chips class="smart-link-popup-chip" [text]="'STARRED' | translate"
                     [active]="globalFilters[filterType.GLOBAL_STARRED]"
                     (clickEvent)="updateGlobalFilters(filterType.GLOBAL_STARRED)"
                     leftIcon="star"
                     type="filter"
                     size="s"></vnc-chips>
          <vnc-chips class="smart-link-popup-chip" [text]="'ARCHIVED' | translate"
                     [active]="globalFilters[filterType.GLOBAL_ARCHIVED]"
                     (clickEvent)="updateGlobalFilters(filterType.GLOBAL_ARCHIVED)"
                     leftIcon="archive"
                     type="filter"
                     size="s"></vnc-chips>
                     <vnc-smart-link-filter-chip [lang]="defaultLang"
                     (click)="toggleShowAllFilters()"
                     [applyLabel]="'APPLY' | translate"
                     [cancelLabel]="'CANCEL' | translate"
                     [clearLabel]="'ALL_TIMES' | translate " 
                     [customRangeLabel]="'CUSTOM_RANGE' | translate " 
                     [rangesLabel]="rangesLabel"
                     [showAllTimeDate]="true"
                     [showDropdown]="true"
                     [linkCalendar]="false"
                     [shortRange]="false"
                     [showCustomRangeLabel]="true"
                     [showAllAsDefault]="true"
                     [showCancel]="false"
                     (dateFilterChange)="smartLinkPopupComponentService.onFilterChange($event, filterType.GLOBAL_DATE_RANGE)"
                     type="DATE_RANGE"></vnc-smart-link-filter-chip>

          <div class="vertical-divider"></div>
        </ng-container>

        <!-- CHAT CHIPS -->
        <ng-container *ngIf="($visibleCategories | async)?.includes('chats')">
          <span class="chip-with-label">
        <span class="label">{{'CHATS' | translate}}</span>
        <vnc-chips class="smart-link-popup-chip" [text]="'TYPE' | translate"
                   rightIcon="mdi-chevron-down"
                   type="filter"
                   size="s"></vnc-chips>
      </span>
          <vnc-chips class="smart-link-popup-chip" [text]="'User' | translate"
                     rightIcon="mdi-chevron-down"
                     type="filter"
                     size="s"></vnc-chips>

          <div class="vertical-divider"></div>
        </ng-container>

        <!-- CHANNEL CHIPS -->
        <ng-container *ngIf="($visibleCategories | async)?.includes('everywhere') || ($visibleCategories | async)?.includes('channels')">
          <span class="chip-with-label">
            <span class="label">{{'CHANNELS' | translate}}</span>
            <vnc-smart-link-filter-chip (apply)="smartLinkPopupComponentService.onFilterChange($event, filterType.CHANNEL_TYPE)"
                                        [label]="'TYPE' | translate" [allTypeOptionValue]="'all'" type="SELECT"
                                        [options]="smartLinkFiltersService.channelTypeOptions"
                                        [useMenuItem]="true"
                                        [multiple]="false"></vnc-smart-link-filter-chip>
          </span>

          <vnc-smart-link-filter-chip [useMenuItem]="true"
                                      [label]="'User' | translate"
                                      [showResetIcon]="true"
                                      [multiple]="true" type="SELECT"
                                      [options]="smartLinkFiltersService.$userOptions | async"
                                      [searchFilterControl]="smartLinkFiltersService.userSearchControl"
                                      [disableSearchFilterDropdown]="true"
                                      [disableApplyOnChange]="true"
                                      (apply)="smartLinkPopupComponentService.onFilterChange($event, filterType.CHANNEL_USER)"
                                      [showSearchBox]="true"></vnc-smart-link-filter-chip>


          <vnc-smart-link-filter-chip [useMenuItem]="true"
                                      [label]="'Author' | translate"
                                      [showResetIcon]="true"
                                      [multiple]="true" type="SELECT"
                                      [options]="smartLinkFiltersService.$userOptions | async"
                                      [searchFilterControl]="smartLinkFiltersService.userSearchControl"
                                      [disableSearchFilterDropdown]="true"
                                      [disableApplyOnChange]="true"
                                      (apply)="smartLinkPopupComponentService.onFilterChange($event, filterType.CHANNEL_AUTHOR)"
                                      [showSearchBox]="true"></vnc-smart-link-filter-chip>

          <div class="vertical-divider"></div>
        </ng-container>

        <!-- TOPIC CHIPS -->
        <ng-container *ngIf="($visibleCategories | async)?.includes('everywhere') || ($visibleCategories | async)?.includes('topics')">
          <span class="chip-with-label">
            <span class="label">{{'TOPIC' | translate}}</span>
            <vnc-smart-link-filter-chip (apply)="smartLinkPopupComponentService.onFilterChange($event, filterType.TOPIC_TYPE)"
                                        [label]="'TYPE' | translate" [allTypeOptionValue]="'all'" type="SELECT"
                                        [options]="smartLinkFiltersService.topicTypeOptions"
                                        [useMenuItem]="true"
                                        [multiple]="false"></vnc-smart-link-filter-chip>
          </span>

          <vnc-smart-link-filter-chip [useMenuItem]="true"
                                      [label]="'User' | translate"
                                      [showResetIcon]="true"
                                      [multiple]="true" type="SELECT"
                                      [options]="smartLinkFiltersService.$userOptions | async"
                                      [searchFilterControl]="smartLinkFiltersService.userSearchControl"
                                      [disableSearchFilterDropdown]="true"
                                      [disableApplyOnChange]="true"
                                      (apply)="smartLinkPopupComponentService.onFilterChange($event, filterType.TOPIC_USER)"
                                      [showSearchBox]="true"></vnc-smart-link-filter-chip>

          <vnc-smart-link-filter-chip [useMenuItem]="true"
                                      [label]="'Author' | translate"
                                      [showResetIcon]="true"
                                      [multiple]="true" type="SELECT"
                                      [options]="smartLinkFiltersService.$userOptions | async"
                                      [searchFilterControl]="smartLinkFiltersService.userSearchControl"
                                      [disableSearchFilterDropdown]="true"
                                      [disableApplyOnChange]="true"
                                      (apply)="smartLinkPopupComponentService.onFilterChange($event, filterType.TOPIC_AUTHOR)"
                                      [showSearchBox]="true"></vnc-smart-link-filter-chip>

          <div class="vertical-divider"></div>
        </ng-container>

        <!-- BROADCAST CHIPS -->
        <ng-container *ngIf="($visibleCategories | async)?.includes('broadcast')">
          <span class="chip-with-label">
            <span class="label">{{'BROADCASTS' | translate}}</span>
            <vnc-chips [text]="'Author' | translate"
                   rightIcon="mdi-chevron-down"
                   type="filter"
                   size="s"></vnc-chips>
          </span>
          <vnc-chips [text]="'USER' | translate"
                     rightIcon="mdi-chevron-down"
                     type="filter"
                     size="s"></vnc-chips>

          <div class="vertical-divider"></div>
        </ng-container>

        <!-- FILES CHIPS -->
        <ng-container *ngIf="($visibleCategories | async)?.includes('files')">
          <span class="chip-with-label">
            <span class="label">{{'FILES' | translate}}</span>
            <vnc-chips [text]="'TYPE' | translate"
                   rightIcon="mdi-chevron-down"
                   type="filter"
                   size="s"></vnc-chips>
          </span>
          <vnc-chips [text]="'Author' | translate"
                     rightIcon="mdi-chevron-down"
                     type="filter"
                     size="s"></vnc-chips>
          <vnc-chips [text]="'SIZE' | translate"
                     rightIcon="mdi-chevron-down"
                     type="filter"
                     size="s"></vnc-chips>
          <vnc-chips [text]="'LOCATION' | translate"
                     rightIcon="mdi-chevron-down"
                     type="filter"
                     size="s"></vnc-chips>

          <div class="vertical-divider"></div>
        </ng-container>

        <!-- COMMENT CHIPS -->
        <ng-container *ngIf="($visibleCategories | async)?.includes('everywhere') || ($visibleCategories | async)?.includes('comments')">
          <span class="chip-with-label">
            <span class="label">{{'COMMENTS' | translate}}</span>
            <vnc-smart-link-filter-chip (apply)="smartLinkPopupComponentService.onFilterChange($event, filterType.COMMENT_CHANNEL)"
                                        [label]="'Channel' | translate" [multiple]="true" [allTypeOptionValue]="'all'" type="SELECT"
                                        [options]="(smartLinkFiltersService.$channelOptions | async)"></vnc-smart-link-filter-chip>
          </span>

          <vnc-smart-link-filter-chip [useMenuItem]="true"
                                      [label]="'Author' | translate"
                                      [showResetIcon]="true"
                                      [multiple]="true" type="SELECT"
                                      [options]="smartLinkFiltersService.$userOptions | async"
                                      [searchFilterControl]="smartLinkFiltersService.userSearchControl"
                                      [disableSearchFilterDropdown]="true"
                                      [disableApplyOnChange]="true"
                                      (apply)="smartLinkPopupComponentService.onFilterChange($event, filterType.COMMENT_AUTHOR)"
                                      [showSearchBox]="true"></vnc-smart-link-filter-chip>


          <vnc-smart-link-filter-chip [useMenuItem]="true"
                                      [label]="'TOPIC' | translate"
                                      [showResetIcon]="true"
                                      [multiple]="true" type="SELECT"
                                      [allTypeOptionValue]="'all'"
                                      [options]="smartLinkFiltersService.$topicOptions | async"
                                      [searchFilterControl]="smartLinkFiltersService.userSearchControl"
                                      [disableApplyOnChange]="true"
                                      (apply)="smartLinkPopupComponentService.onFilterChange($event, filterType.COMMENT_TOPIC)"></vnc-smart-link-filter-chip>

          <vnc-smart-link-filter-chip (apply)="smartLinkPopupComponentService.onFilterChange($event, filterType.COMMENT_TOPIC)"
                                      [label]="'TOPIC' | translate" [multiple]="true" [allTypeOptionValue]="'all'" type="SELECT"
                                      [options]="smartLinkFiltersService.$topicOptions | async"></vnc-smart-link-filter-chip>

        </ng-container>

        <!-- COLLAPSE CHIPS -->
        <vnc-chips class="expand-button"
                   *ngIf="($showAllFilters | async) && ($showCollapseToggle | async) &&
                   (smartLinkPopupComponentService.showExpandCollapsedButton === true)" [text]="'...'"
                   [active]="($showAllFilters | async)"
                   (clickEvent)="toggleShowAllFilters()"
                   type="filter"
                   size="s"></vnc-chips>
      </div>

      <!-- EXPAND CHIPS -->
      <vnc-chips class="expand-button"
                 *ngIf="($showAllFilters | async) === false  && ($showCollapseToggle | async) &&
                 (smartLinkPopupComponentService.showExpandCollapsedButton === true)" [text]="'...'"
                 [active]="($showAllFilters | async)"
                 (clickEvent)="toggleShowAllFilters()"
                 type="filter"
                 size="s"></vnc-chips>
    </div>

    <div class="smart-link-search-results"
         [ngStyle]="{'max-height': ($searchResultHeight | async)}"
         vpInfiniteScroll
         scrollPercentage="0.8"
         (onUserScroll)="smartLinkPopupComponentService.loadMoreSearchResults()">
      <ng-container *ngFor="let searchResult of ($searchResults | async)">
        <vnc-smart-link-search-result *ngIf="searchResult.object_type === selectedCat"
                                      [avatarTemplate]="avatarTemplate" [resultData]="searchResult"
                                      [showActionIconsOnHover]="true"
                                      class="smartlink-popup-search-result"
                                      [actionType]="'preview'"
                                      (actionClicked)="smartLinkPopupComponentService.showSearchResultPreview($event, searchResult, smartSearchLinkName, smartlinkForm)"
                                      [resultType]="searchResult?.resultType"
                                      [topicsLabel]="searchResult?.topics_count === 1 ? 'topic' : 'topics'"
                                      (click)="selectSearchResult(searchResult)"></vnc-smart-link-search-result>
        <ng-template #avatarTemplate>
          <vnc-avatar class="channel-avatar" *ngIf="searchResult?.resultType === 'CHANNEL'"
                      [imgSrc]="searchResult?.avatar_url"
                      [defaultAvatarTemplate]="defaultChannelAvatarTemplate"
                      [rightIcon]="searchResult?.author?.avatar_url"
                      [leftIcon]="searchResult?.subscribed ? 'subscribe' : ''"
                      [totalUnread]="searchResult?.unread_topics_count"></vnc-avatar>
          <vnc-avatar class="topic-avatar" *ngIf="searchResult?.resultType === 'TOPIC'"
                      [imgSrc]="searchResult?.heroAttachmentThumbnailUrl"
                      [defaultAvatar]="searchResult?.default_cover_url || searchResult?.default_cover"
                      [rightIcon]="searchResult?.author?.avatar_url"
                      [leftIcon]="searchResult?.subscribed ? 'subscribe' : ''"
                      [totalUnread]="searchResult?.unread_comments_count"></vnc-avatar>
          <vnc-avatar *ngIf="searchResult?.resultType === 'COMMENT'"
                      [imgSrc]="searchResult?.author?.avatar_url"></vnc-avatar>
        </ng-template>
      </ng-container>
      <div class="load-spinner" *ngIf="smartLinkPopupComponentService.$loading | async">
        <vnc-loader-circular [color]="'blue'" [size]="'m'"></vnc-loader-circular>
        <span class="loading-text">{{'LOADING' | translate}}...</span>
      </div>

      <div class="error-message" *ngIf="(smartLinkPopupComponentService.$loadingError | async) && !(smartLinkPopupComponentService.$searchResults | async)?.length">
        {{'LOAD_FAILED' | translate}}
      </div>

      <div class="error-message" *ngIf="!($searchResults | async).length && (smartLinkPopupComponentService.$loading | async) === false && (smartLinkPopupComponentService.$loadingError | async) === false">
        {{ 'NOTHING_TO_DISPLAY' | translate }}
      </div>
    </div>
  </div>

  <div class="stage-2" *ngIf="($currentStage | async) === 2">
    <vnc-smartlink-input class="smartlink-input flex" [smartSearchLinkName]="smartSearchLinkName"
                         [showClear]="true"></vnc-smartlink-input>

    <vnc-input label="Link text (optional)" type="questionmark" class="questionmark-input flex"
               placeholder="Link text (optional)" [showLabel]="true" [form]="smartlinkControl.title"></vnc-input>

    <vnc-textarea [control]="smartlinkControl.description" rows="6" class="smartlink-textarea flex"></vnc-textarea>

    <div class="action-buttons-row">
      <vnc-button [height]="'small'"
                  [shape]="'rectangle'"
                  [type]="'link'"
                  [label]="'CANCEL' | translate"
                  (onClick)="toggleHeaderTab(smartLinkHeaderTabType.CLOSE)"
                  class="vnc-default"></vnc-button>
      <vnc-button [height]="'small'"
                  [shape]="'rectangle'"
                  [type]="'primary'"
                  [label]="(data?.skipFirstStep ? 'UPDATE' : 'ADD') | translate"
                  (onClick)="addSmartLink(smartlinkForm, smartSearchLinkName, selectedSearchResult, data.getOnlyData, data?.skipFirstStep)"></vnc-button>
    </div>
  </div>
</div>

<div *ngIf="data?.isMobileView" class="smart-link-popup-container-mobile">
  <vnc-dialog [classes]="['mobile-editor-channel-dialog']" [headerTemplate]="headerTemplate"
              classes="fixed-size with-border with-border-radius" [footerTemplate]="footerTemplate"
              [bodyTemplate]="bodyTemplate">
  </vnc-dialog>

  <ng-template #headerTemplate>
    <vnc-mobile-header headerType="dark-back-more" darkBackMorePlaceHolder="{{ 'SMARTLINK' | translate }}"
                       (darkSuffixButtonPress)="addSelectedSmartLinks()"
                       [darkSuffixIcon]="'mdi-check'" (darkBackPress)="toggleHeaderTab(smartLinkHeaderTabType.CLOSE)">
    </vnc-mobile-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="input-wrapper">
      <vnc-mobile-input [placeholder]="'SEARCH' | translate"
                        [searchForm]="smartlinkControl.search"
                        (filterClick)="openSmartLinkFilter()"
      ></vnc-mobile-input>
      <div *ngIf="mobileFilterService.appliedFiltersCount" class="filter-count">{{mobileFilterService.appliedFiltersCount}}</div>
    </div>
    <div class="chip-bar">
      <div class="left-part">
        <div *ngIf="filterCategorySelected" class="back-button" (click)="resetCategorySelection()">
          <ng-container [ngTemplateOutlet]="filterBackIconTemplate"></ng-container>
        </div>
        <div class="filter-chips" [ngClass]="{'max-width-controlled': filterCategorySelected}">
          <vnc-chips class="smart-link-popup-chip" *ngIf="!filterCategorySelected || filterCategories.CHATS" [text]="'CHATS' | translate"
                     [active]="filterCategories.CHATS"
                     (clickEvent)="changeFilterCategory(categoryFilters.CHATS)"
                     type="filter"
                     size="m"></vnc-chips>
          <vnc-chips class="smart-link-popup-chip" *ngIf="!filterCategorySelected || filterCategories.CHANNELS" [text]="'CHANNELS' | translate"
                     [active]="filterCategories.CHANNELS"
                     (clickEvent)="changeFilterCategory(categoryFilters.CHANNELS)"
                     type="filter"
                     size="m"></vnc-chips>
          <vnc-chips class="smart-link-popup-chip" *ngIf="!filterCategorySelected || filterCategories.TOPIC" [text]="'TOPIC' | translate"
                     [active]="filterCategories.TOPIC"
                     (clickEvent)="changeFilterCategory(categoryFilters.TOPIC)"
                     type="filter"
                     size="m"></vnc-chips>
          <vnc-chips class="smart-link-popup-chip" *ngIf="!filterCategorySelected || filterCategories.BROADCASTS" [text]="'BROADCASTS' | translate"
                     [active]="filterCategories.BROADCASTS"
                     (clickEvent)="changeFilterCategory(categoryFilters.BROADCASTS)"
                     type="filter"
                     size="m"></vnc-chips>
          <vnc-chips class="smart-link-popup-chip" *ngIf="!filterCategorySelected || filterCategories.FILES" [text]="'FILES' | translate"
                     [active]="filterCategories.FILES"
                     (clickEvent)="changeFilterCategory(categoryFilters.FILES)"
                     type="filter"
                     size="m"></vnc-chips>
          <vnc-chips class="smart-link-popup-chip" *ngIf="!filterCategorySelected || filterCategories.COMMENTS" [text]="'COMMENTS' | translate"
                     [active]="filterCategories.COMMENTS"
                     (clickEvent)="changeFilterCategory(categoryFilters.COMMENTS)"
                     type="filter"
                     size="m"></vnc-chips>


          <!--        chat section-->

          <vnc-chips class="smart-link-popup-chip" *ngIf="filterCategories.CHATS" [text]="'TYPE' | translate"
                     rightIcon="mdi-chevron-down"
                     type="filter"
                     (clickEvent)="underDevelopment()"
                     size="m"></vnc-chips>

          <vnc-chips class="smart-link-popup-chip" *ngIf="filterCategories.CHATS" [text]="'User' | translate"
                     rightIcon="mdi-chevron-down"
                     type="filter"
                     (clickEvent)="underDevelopment()"
                     size="m"></vnc-chips>

          <!--        channel section-->

          <vnc-chips class="smart-link-popup-chip" *ngIf="filterCategories.CHANNELS" [text]="'TYPE' | translate"
                     rightIcon="mdi-chevron-down"
                     type="filter"
                     [active]="mobileFilterService.filterCategories.CHANNEL_TYPE !== ''"
                     (clickEvent)="mobileFilterService.openMobileMenu(filterType.CHANNEL_TYPE, true)"
                     size="m"></vnc-chips>

          <vnc-chips class="smart-link-popup-chip" *ngIf="filterCategories.CHANNELS" [text]="'User' | translate"
                     rightIcon="mdi-chevron-down"
                     type="filter"
                     [active]="mobileFilterService.filterCategories.CHANNEL_USER.length !== 0"
                     (clickEvent)="mobileFilterService.selectUsers(mobileFilterService.filterCategories.CHANNEL_USER, filterType.CHANNEL_USER, true)"
                     size="m"></vnc-chips>

          <vnc-chips class="smart-link-popup-chip" *ngIf="filterCategories.CHANNELS" [text]="'AUTHOR' | translate"
                     rightIcon="mdi-chevron-down"
                     type="filter"
                     [active]="mobileFilterService.filterCategories.CHANNEL_AUTHOR.length !== 0"
                     (clickEvent)="mobileFilterService.selectUsers(mobileFilterService.filterCategories.CHANNEL_AUTHOR, filterType.CHANNEL_AUTHOR, true)"
                     size="m"></vnc-chips>

          <!--        topic section-->
          <vnc-chips class="smart-link-popup-chip" *ngIf="filterCategories.TOPIC" [text]="'TYPE' | translate"
                     rightIcon="mdi-chevron-down"
                     type="filter"
                     [active]="mobileFilterService.filterCategories.TOPIC_TYPE !== ''"
                     (clickEvent)="mobileFilterService.openMobileMenu(filterType.TOPIC_TYPE, true)"
                     size="m"></vnc-chips>

          <vnc-chips class="smart-link-popup-chip" *ngIf="filterCategories.TOPIC" [text]="'User' | translate"
                     rightIcon="mdi-chevron-down"
                     type="filter"
                     [active]="mobileFilterService.filterCategories.TOPIC_USER.length !== 0"
                     (clickEvent)="mobileFilterService.selectUsers(mobileFilterService.filterCategories.TOPIC_USER, filterType.TOPIC_USER, true)"
                     size="m"></vnc-chips>

          <vnc-chips class="smart-link-popup-chip" *ngIf="filterCategories.TOPIC" [text]="'AUTHOR' | translate"
                     rightIcon="mdi-chevron-down"
                     type="filter"
                     [active]="mobileFilterService.filterCategories.TOPIC_AUTHOR.length !== 0"
                     (clickEvent)="mobileFilterService.selectUsers(mobileFilterService.filterCategories.TOPIC_AUTHOR, filterType.TOPIC_AUTHOR, true)"
                     size="m"></vnc-chips>

          <!--        broadcast section-->
          <vnc-chips class="smart-link-popup-chip" *ngIf="filterCategories.BROADCASTS" [text]="'AUTHOR' | translate"
                     rightIcon="mdi-chevron-down"
                     type="filter"
                     (clickEvent)="underDevelopment()"
                     size="m"></vnc-chips>

          <vnc-chips class="smart-link-popup-chip" *ngIf="filterCategories.BROADCASTS" [text]="'User' | translate"
                     rightIcon="mdi-chevron-down"
                     type="filter"
                     (clickEvent)="underDevelopment()"
                     size="m"></vnc-chips>


          <!--        files section-->
          <vnc-chips class="smart-link-popup-chip" *ngIf="filterCategories.FILES" [text]="'TYPE' | translate"
                     rightIcon="mdi-chevron-down"
                     type="filter"
                     (clickEvent)="underDevelopment()"
                     size="m"></vnc-chips>

          <vnc-chips class="smart-link-popup-chip" *ngIf="filterCategories.FILES" [text]="'AUTHOR' | translate"
                     rightIcon="mdi-chevron-down"
                     type="filter"
                     (clickEvent)="underDevelopment()"
                     size="m"></vnc-chips>
          <vnc-chips class="smart-link-popup-chip" *ngIf="filterCategories.FILES" [text]="'SIZE' | translate"
                     rightIcon="mdi-chevron-down"
                     type="filter"
                     (clickEvent)="underDevelopment()"
                     size="m"></vnc-chips>

          <vnc-chips class="smart-link-popup-chip" *ngIf="filterCategories.FILES" [text]="'LOCATION' | translate"
                     rightIcon="mdi-chevron-down"
                     type="filter"
                     (clickEvent)="underDevelopment()"
                     size="m"></vnc-chips>


          <!--        comment section-->

          <vnc-chips class="smart-link-popup-chip" *ngIf="filterCategories.COMMENTS" [text]="'Channel' | translate"
                     rightIcon="mdi-chevron-down"
                     type="filter"
                     [active]="mobileFilterService.filterCategories.COMMENT_CHANNEL.length !== 0"
                     (clickEvent)="mobileFilterService.selectUsers(mobileFilterService.filterCategories.COMMENT_CHANNEL, filterType.COMMENT_CHANNEL, true)"
                     size="m"></vnc-chips>
          <vnc-chips class="smart-link-popup-chip" *ngIf="filterCategories.COMMENTS" [text]="'Author' | translate"
                     rightIcon="mdi-chevron-down"
                     type="filter"
                     [active]="mobileFilterService.filterCategories.COMMENT_AUTHOR.length !== 0"
                     (clickEvent)="mobileFilterService.selectUsers(mobileFilterService.filterCategories.COMMENT_AUTHOR, filterType.COMMENT_AUTHOR, true)"
                     size="m"></vnc-chips>

          <vnc-chips class="smart-link-popup-chip" *ngIf="filterCategories.COMMENTS" [text]="'TOPIC' | translate"
                     rightIcon="mdi-chevron-down"
                     type="filter"
                     [active]="mobileFilterService.filterCategories.COMMENT_TOPIC.length !== 0"
                     (clickEvent)="mobileFilterService.selectUsers(mobileFilterService.filterCategories.COMMENT_TOPIC, filterType.COMMENT_TOPIC, true)"
                     size="m"></vnc-chips>

        </div>
      </div>
      <div *ngIf="filterCategorySelected" class="advance-filters" (click)="openSmartLinkFilter(filterCategories)">
        <ng-container [ngTemplateOutlet]="advancedFilterIcon"></ng-container>
      </div>
    </div>


    <div class="smart-link-search-results"
         [ngStyle]="{'max-height': ($searchResultHeight | async)}"
         vpInfiniteScroll
         scrollPercentage="0.8"
         (onUserScroll)="smartLinkPopupComponentService.loadMoreSearchResults()">
      <ng-container *ngFor="let searchResult of ($searchResults | async)">
        <div  [ngClass]="{'mobile-search-result': data?.isMobileView}" class="search-result-wrapper">
          <vnc-smart-link-search-result [avatarTemplate]="avatarTemplate" [resultData]="searchResult"
                                        [showActionIconsOnHover]="false"
                                        class="smartlink-popup-search-result"
                                        [actionType]="'preview'"
                                        (actionClicked)="smartLinkPopupComponentService.showSearchResultPreview($event, searchResult, smartSearchLinkName, smartlinkForm)"
                                        [resultType]="searchResult?.resultType"
                                        [topicsLabel]="searchResult?.topics_count === 1 ? 'topic' : 'topics'"
                                        [mobileMode]="true"
                                        [enableSelection]="true"
                                        [checked]="selectedSmartObjects[searchResult.resultType].includes(searchResult.id)"
                                        (checkboxClicked)="pushSmartLink($event)"
                                        (click)="selectSearchResult(searchResult)"></vnc-smart-link-search-result>
        </div>
        <ng-template #avatarTemplate>
          <vnc-avatar class="channel-avatar" *ngIf="searchResult?.resultType === 'CHANNEL'"
                      [imgSrc]="searchResult?.avatar_url"
                      [defaultAvatarTemplate]="defaultChannelAvatarTemplate"
                      [rightIcon]="searchResult?.author?.avatar_url"
                      [leftIcon]="searchResult?.subscribed ? 'subscribe' : ''"
                      [totalUnread]="searchResult?.unread_topics_count"></vnc-avatar>
          <vnc-avatar class="topic-avatar" *ngIf="searchResult?.resultType === 'TOPIC'"
                      [imgSrc]="searchResult?.heroAttachmentThumbnailUrl"
                      [defaultAvatar]="searchResult?.default_cover_url || searchResult?.default_cover"
                      [rightIcon]="searchResult?.author?.avatar_url"
                      [leftIcon]="searchResult?.subscribed ? 'subscribe' : ''"
                      [totalUnread]="searchResult?.unread_comments_count"></vnc-avatar>
          <vnc-avatar *ngIf="searchResult?.resultType === 'COMMENT'"
                      [imgSrc]="searchResult?.author?.avatar_url"></vnc-avatar>
        </ng-template>
      </ng-container>
      <div class="load-spinner" *ngIf="smartLinkPopupComponentService.$loading | async">
        <vnc-loader-circular [color]="'blue'" [size]="'m'"></vnc-loader-circular>
        <span class="loading-text">{{'LOADING' | translate}}...</span>
      </div>

      <div class="error-message" *ngIf="(smartLinkPopupComponentService.$loadingError | async) && !(smartLinkPopupComponentService.$searchResults | async)?.length">
        {{'LOAD_FAILED' | translate}}
      </div>

      <div class="error-message" *ngIf="!($searchResults | async).length && (smartLinkPopupComponentService.$loading | async) === false && (smartLinkPopupComponentService.$loadingError | async) === false">
        {{ 'NOTHING_TO_DISPLAY' | translate }}
      </div>
    </div>
  </ng-template>
  <ng-template #footerTemplate></ng-template>

  <ng-template #filterBackIconTemplate>
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20z" fill="#337CBD" fill-rule="evenodd"/>
    </svg>
  </ng-template>
  <ng-template #advancedFilterIcon>
    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.75 13.5h4.5V12h-4.5v1.5zm-1.5-3.75h7.5v-1.5h-7.5v1.5zm-3-5.25V6h13.5V4.5H2.25z" fill="#337CBD" fill-rule="evenodd"/>
    </svg>
  </ng-template>
</div>

<ng-template #defaultChannelAvatarTemplate>
  <img class="channel-default-avatar" [src]="defaultChannelAvatar" alt="">
</ng-template>
