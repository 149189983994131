
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { MiddlewareService } from "./middleware.service";
import { map } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
import { LoggerService } from "app/shared/services/logger.service";

@Injectable()
export class UserConfigService {
  openRightSidebar = false;
  constructor(private middlewareService: MiddlewareService, private logger: LoggerService) {
  }

  getUserConfigFromServer() {
    return this.middlewareService.getTest(`api/channel-users/my-config`, true).pipe(map( (res: any) => res.user ));
  }

  getUserConfig(refresh: boolean = false) {
    const response = new BehaviorSubject<any[]>([]);
    const cachedConfigString = localStorage.getItem("userConfigFromServer");
    let cachedConfig;
    try {
      if (!!cachedConfigString && (cachedConfigString !== "")) {
        cachedConfig = JSON.parse(cachedConfigString);
      }
    } catch (e) {
      this.logger.error("[userConfigService][getUserConfig] error restoring cachedConfig: ", e);
    }
    const hasCachedConfig = !!cachedConfig && (cachedConfigString !== "");

    if (!refresh && hasCachedConfig) {
      this.logger.info("[userConfigService][getUserConfig] cachedConfig: ", cachedConfig);
      response.next(cachedConfig);
    } else {
      this.getUserConfigFromServer().subscribe(data => {
        this.logger.info("[userConfigService][getUserConfig] dataFromServer: ", data);
        let jsonData;
        try {
          jsonData = JSON.stringify(data);
          localStorage.setItem("userConfigFromServer", jsonData);
        } catch (e) {
          this.logger.error("[userConfigService][getUserConfig] error serializing config data: ", e);
        }

        response.next(data);
      });
    }


    return response.asObservable();
  }


  updateUserConfig(data: any) {
    return this.middlewareService.postTest("api/channel-users/2fa/config", true, data);
  }


  sendInvitationUsers(email: string, departmentId: number, organizationId: number) {
    let data = {"user_invitation": { "email": email, "department_id": departmentId }};
    return this.middlewareService.post(`/api/invitations/send/` + organizationId, true, data);
  }

  getInvitationConfigFromServer() {
    return this.middlewareService.get(`/api/invitations/config`, true);
  }

  getInvitationConfig(refresh: boolean = false) {
    const response = new BehaviorSubject<any>({});
    const cachedConfigString = localStorage.getItem("invitationConfigFromServer");
    let cachedConfig;
    try {
      if (!!cachedConfigString && (cachedConfigString !== "")) {
        cachedConfig = JSON.parse(cachedConfigString);
      }
    } catch (e) {
      this.logger.error("[userConfigService][getInvitationConfig] error restoring cachedConfig: ", e);
    }
    const hasCachedConfig = !!cachedConfig && (cachedConfigString !== "");

    if (!refresh && hasCachedConfig) {
      this.logger.info("[userConfigService][getInvitationConfig] cachedConfig: ", cachedConfig);
      response.next(cachedConfig);
    } else {
      this.getInvitationConfigFromServer().subscribe(data => {
        this.logger.info("[userConfigService][getInvitationConfig] dataFromServer: ", data);
        let jsonData;
        try {
          jsonData = JSON.stringify(data);
          localStorage.setItem("invitationConfigFromServer", jsonData);
        } catch (e) {
          this.logger.error("[userConfigService][getInvitationConfig] error serializing config data: ", e);
        }

/*        let configEqual = CommonUtil.compareObjects(data, cachedConfig);
        this.logger.info("[configService][getMyProducts] configEqual: ", configEqual);

        if (CommonUtil.compareObjects(data, cachedConfig)) {
          data["unchanged"] = true;
        } */
        response.next(data);
      });
    }


    return response.asObservable();
  }

  upgradeAccount(name: string, websiteUrl: string, vatNumber: string ) {
    let data = {"organization": { "name": name, "website": websiteUrl, "vat_number": vatNumber }};
    return this.middlewareService.post(`/api/upgrade/account`, true, data);
  }

  getJitsiAuth() {
    return this.middlewareService.get("/api/getjitsiauth", true);
  }

  getOmemoSubscribers() {
    return this.middlewareService.get("/api/getOMEMODeviceSubscriptions", true);
  }
}
