``;/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Topic } from "app/channels/models/topic.model";
import { Action } from "../actions";
import { TopicActionTypes } from "../actions/topic";
import { TopicFiles } from "../../models/topic-files.model";
import { File } from "../../models/file.model";
import {DefaultCover} from "../../models/default-cover.model";



export interface TopicState extends EntityState<Topic> {
  isLoading: boolean;
  channelIdsWithLoadedTopics: string[];
  topicsInfo: { [channelId: string]: { totalCount: number, offset: number, isLoaded: boolean}};
  isLoaded: boolean;
  selectedTopicId: Topic["id"];
  archiveTopicsSortBy: string;
  members: { [topicId: string]: { members: { id: string, role: string }[], isLoading: boolean, isLoaded: boolean, offset?: number, total_count?: number } };
  comments: { [topicId: string]: { offset?: number, total_count?: number, isLoading: boolean, isLoaded: boolean } };
  files: TopicFiles;
  defaultCovers: DefaultCover[];
  topicSideBarTab: string;
  remarks: any[];
  allTopicsInfo: { totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean};
  archivedTopicsInfo: { totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean};
  mediaTopicsInfo: { [channelId: string]: { totalCount: number, offset: number, isLoaded: boolean}};
}

export const topicAdapter: EntityAdapter<Topic> = createEntityAdapter<Topic>({
  selectId: (topic: Topic) => topic.id,
  sortComparer: sortByTimestamp
});

export function sortByTimestamp(t1: Topic, t2: Topic): number {
  return new Date(t2.updated_on).getTime() - new Date(t1.updated_on).getTime();
}

export const initialState: TopicState = topicAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,
  channelIdsWithLoadedTopics: [],
  topicsInfo: null,
  selectedTopicId: null,
  archiveTopicsSortBy: null,
  members: null,
  comments: null,
  files: null,
  defaultCovers: [],
  topicSideBarTab: null,
  mediaTopicsInfo: null,
  remarks: [],
  allTopicsInfo: {offset: 0, totalCount: 0, ids: [], isLoading: false, isLoaded: false},
  archivedTopicsInfo: {offset: 0, totalCount: 0, ids: [], isLoading: false, isLoaded: false}});

export function topicReducer(state: TopicState = initialState, action: Action): TopicState {
  switch (action.type) {

    case TopicActionTypes.TOPIC_LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case TopicActionTypes.TOPIC_LOAD_SUCCESS: {
      const topics = action.payload.map(t => {
        let topic = state.entities[t.id];
        if (topic) {
          t = {...t, ...topic};
        }
        return t;
      });

      return topicAdapter.setAll(topics, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case TopicActionTypes.TOPIC_ADD: {
      const newState = topicAdapter.addOne(action.payload, state);
      return topicAdapter.updateOne({ id: action.payload.id, changes: action.payload }, newState);
    }

    case TopicActionTypes.TOPIC_UPDATE: {
      const updatedState = topicAdapter.updateOne({
        id: action.payload.id,
        changes: action.payload
      }, state);
      return updatedState;
    }

    case TopicActionTypes.TOPIC_BULK_ADD: {
      const newState = topicAdapter.addMany(action.payload, state);

      const changes = action.payload.map(topic => {
        return { id: topic.id, changes: topic };
      });

      return topicAdapter.updateMany(changes, newState);
    }

    case TopicActionTypes.TOPIC_DELETE: {
      return topicAdapter.removeOne(action.payload, state);
    }

    case TopicActionTypes.TOPICS_DELETE: {
      return topicAdapter.removeMany(action.payload, state);
    }

    case TopicActionTypes.TOPIC_CHANNEL_ADD: {
      const channelIds = [...state.channelIdsWithLoadedTopics];
      if (!channelIds.includes(action.payload)) {
        channelIds.push(action.payload);
        return {...state, channelIdsWithLoadedTopics: channelIds};
      } else {
        return state;
      }
    }

    case TopicActionTypes.TOPIC_INFO_UPDATE: {
      const channelId = action.payload.channelId;
      const info = action.payload.info;
      return {
        ...state ,
        topicsInfo: {
          ...state.topicsInfo,
          [channelId]: info
        }
      };
    }

    case TopicActionTypes.SELECTED_TOPIC: {
      return {
        ...state,
        selectedTopicId: action.payload
      };
    }

    case TopicActionTypes.ARCHIVE_TOPICS_SORT_BY: {
      return {
        ...state,
        archiveTopicsSortBy: action.payload
      };
    }

    case TopicActionTypes.TOPIC_SIDEBAR_TAB_CHANGE: {
      return {
        ...state,
        topicSideBarTab: action.payload
      };
    }

    case TopicActionTypes.TOPIC_MEMBERS_LOAD_REQUEST: {
      return {
        ...state,
        members: { ...state.members, [action.payload]: { memberIds: [], isLoading: true, isLoaded: false, offset: 0 }}
      };
    }

    case TopicActionTypes.TOPIC_MEMBERS_LOAD_SUCCESS: {
      const topicId = action.payload.topicId;
      const oldMembers = [ ...(state?.members?.[topicId]?.members || [])];
      const oldMemberIds = oldMembers.map(item => item.id);
      const newMembers = (action.payload.members || [])
        .filter(k => !oldMemberIds.includes(k?.jid))
        .map(k => ({id: k?.jid, role: k?.role}));
      return {
        ...state,
        members: {
          ...state.members,
          [topicId]: {
            ...(state?.members?.[topicId] || {}),
            members: [ ...oldMembers, ...newMembers ],
            isLoading: false,
            isLoaded: true,
            total_count: action.payload.total_count
          }
        }
      };
    }

    case TopicActionTypes.TOPIC_MEMBERS_ADD: {
      const topicId = action.payload.topicId;
      const oldMemberIds: any[] = state?.members?.[topicId]?.members?.map(x => x.id) || [];
      const newMembers: any[] = (action.payload.members?.map(k => ({ id: k.jid, role: k.role })) || [])
        .filter(m => !oldMemberIds.includes(m.id));
      const oldMembers = [...(state?.members?.[action.payload.topicId]?.members || [])];
      return {
        ...state,
        members: {
          ...state.members,
          [topicId]: {
            ...(state?.members?.[topicId] || {}),
            members: [...oldMembers, ...newMembers],
            isLoading: false,
            isLoaded: true
          }
        }
      };
    }

    case TopicActionTypes.TOPIC_MEMBERS_REMOVE: {
      const topicId = action.payload.topicId;
      const newMembers: any[] = [...(state?.members?.[topicId]?.members || [])]
        .filter(x => !action.payload.memberIds.includes(x.id));
      return {
        ...state,
        members: {
          ...state.members,
          [topicId]: {
            ...(state?.members?.[topicId] || {}),
            members: newMembers
          }
        }
      };
    }

    case TopicActionTypes.TOPIC_MEMBERS_OFFSET_UPDATE: {
      const topicId = action.payload.topicId;
      const offset = action.payload.offset;
      return {
        ...state,
        members: {
          ...state.members,
          [topicId]: {
            ...state.members[topicId],
            offset
          }
        }
      };
    }

    case TopicActionTypes.TOPIC_FILES_LOAD_REQUEST: {
      return {
        ...state,
        files: { ...(state.files || {}), [action.payload]: { fileIds: [], isLoading: true, isLoaded: false, offset: 0 } }
      };
    }

    case TopicActionTypes.TOPIC_FILES_LOAD_SUCCESS: {
      const topicId = action.payload.topicId;
      const oldFileIds = [...(state?.files?.[topicId]?.fileIds || [])];
      const newFiles = (action.payload.files || []).filter(m => !oldFileIds.includes(m.id));
      return {
        ...state,
        files: {
          ...state.files,
          [topicId]: {
            ...state.files[topicId],
            fileIds: [ ...oldFileIds, ...newFiles.map((file: File) => file.id) ],
            isLoading: false,
            isLoaded: true,
            total_count: action.payload.total_count
          }
        }
      };
    }

    case TopicActionTypes.TOPIC_FILES_ADD: {
      const topicId = action.payload.topicId;
      const allAttachments = action.payload.allAttachments || [];
      const extraFilesAdded = action.payload.extraFilesAdded;
      const fileIds = [...(state?.files?.[topicId]?.fileIds || [])];
      return {
        ...state,
        files: {
          ...state.files,
          [topicId]: {
            ...[state.files?.[topicId] || []],
            fileIds: [...fileIds, ...allAttachments.map((file: File) => file.id)],
            isLoading: false,
            isLoaded: true,
            total_count: state?.files?.[topicId]?.total_count + extraFilesAdded
          }
        }
      };}

    case TopicActionTypes.TOPIC_DEFAULT_COVERS_ADD: {
      return {
        ...state,
        defaultCovers: action.payload
      };
    }

    case TopicActionTypes.TOPIC_FILE_REMOVE: {
      const fileId = action.payload.fileId;
      const topicId = action.payload.topicId;
      const newFileIds = state?.files?.[topicId]?.fileIds.filter(x => x !== fileId);
      return {
        ...state,
        files: {
          ...state.files,
          [topicId]: {
            ...state.files[topicId],
            fileIds: [...newFileIds]
          }
        }
      };
    }

    case TopicActionTypes.TOPIC_FILES_OFFSET_UPDATE: {
      const topicId = action.payload.topicId;
      const offset = action.payload.offset;
      return {
        ...state,
        files: {
          ...state.files,
          [topicId]: {
            ...state.files[topicId],
            offset
          }
        }
      };
    }

    case TopicActionTypes.TOPIC_COMMENTS_LOAD_REQUEST: {
      return {
        ...state,
        comments: { ...state.comments, [action.payload]: {...(state?.comments?.[action.payload] || {}), isLoading: true}}
      };
    }

    case TopicActionTypes.TOPIC_COMMENTS_LOAD_SUCCESS: {
      const topicId = action.payload.topicId;
      return {
        ...state,
        comments: {
          ...state.comments,
          [topicId]: {
            ...(state?.comments?.[topicId] || {}),
            isLoading: false,
            isLoaded: true,
            total_count: action.payload.total_count,
            offset: action.payload.offset
          }
        }
      };
    }

    case TopicActionTypes.ARCHIVED_TOPICS_INFO_UPDATE: {
      return {
        ...state,
        archivedTopicsInfo: action.payload
      };
    }

    case TopicActionTypes.MEDIA_TOPIC_INFO_UPDATE: {
      const channelId = action.payload.channelId;
      const info = action.payload.info;
      return {
        ...state ,
        mediaTopicsInfo: {
          ...state.mediaTopicsInfo,
          [channelId]: info
        }
      };
    }

    default: {
      return state;
    }
  }
}

export const _getIsTopicLoading = (state: TopicState) => state.isLoading;
export const _getIsTopicLoaded = (state: TopicState) => state.isLoaded;
export const _getChannelIdsWithLoadedTopics = (state: TopicState) => state.channelIdsWithLoadedTopics;
export const _getTopicsInfo = (state: TopicState) => state.topicsInfo;
export const _getSelectedTopicId = (state: TopicState) => state.selectedTopicId;
export const _getArchiveTopicsSortBy = (state: TopicState) => state.archiveTopicsSortBy;
export const _getTopicMembers = (state: TopicState) => state.members;
export const _getTopicComments = (state: TopicState) => state.comments;
export const _getTopicFiles = (state: TopicState): TopicFiles => state.files;
export const _getTopicDefaultCovers = (state: TopicState) => state.defaultCovers;
export const _getTopicSideBarTab = (state: TopicState): string => state.topicSideBarTab;
export const _getAllTopicsInfo = (state: TopicState) => state.allTopicsInfo;
export const _getMediaTopicsInfo = (state: TopicState) => state.mediaTopicsInfo;
export const _getArchivedTopicsInfo = (state: TopicState) => state.archivedTopicsInfo;
