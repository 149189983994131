
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Router, NavigationEnd } from "@angular/router";
import { Notification } from "./notifications.model";
import { VNCTalkNotificationsService } from "./notifications.service";
import { Broadcaster } from "../shared/providers";
import { Store } from "@ngrx/store";
import { getConversationById, getSelectedConversation, TalkRootState } from "../reducers";
import { Conversation as NewConversation } from "../models/conversation.model";
import { JID } from "../models/jid.model";
import { getUserJID } from "../../reducers";
import { ConversationRepository } from "../repositories/conversation.repository";
import { ConversationUtil } from "../utils/conversation.util";
import { ContactRepository } from "../repositories/contact.repository";
import { ConferenceRepository } from "../repositories/conference.repository";
import { AppService } from "../../shared/services/app.service";
import { ConstantsUtil, BroadcastKeys } from "../utils/constants.util";
import { JitsiOption } from "../models/jitsi-participant.model";
import { SetActiveTab } from "app/actions/app";
import { Component, ViewEncapsulation, OnInit, OnDestroy, Input, ViewChild, NgZone, ChangeDetectorRef } from "@angular/core";
import { trigger, state, style, transition, animate } from "@angular/animations";
import { debounceTime, filter, Subject, take, takeUntil, takeWhile } from "rxjs";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CommonUtil } from "../utils/common.util";
import { ElectronService } from "app/shared/providers/electron.service";
import { environment } from "app/environments/environment";
import { ToastService } from "app/shared/services/toast.service";
import { IncomingCallComponent } from "vnc-library";
import { AvatarRepository } from "../repositories/avatar.repository";
import { ConfigService } from "app/config.service";
import { SharingService } from "../services/sharing.service";
import { TranslateService } from "@ngx-translate/core";
import { FilesStorageService } from "../services/files-storage.service";
import { ExpandChatFilesSidebar, ExpandChatRightSidebarWithProfile, ExpandChatRightSidebarWithProfileTab, ExpandSideRostersList, SetChatFileTabSidebar } from "../actions/layout";
import { ConversationUpdate } from "app/talk/actions/conversation";
import { LoggerService } from "app/shared/services/logger.service";
import { XmppService } from "../services/xmpp.service";



@Component({
  selector: "vp-notification",
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("enterLeave", [

      // Enter from right
      state("fromRight", style({ opacity: 1, transform: "translateX(0)" })),
      transition("* => fromRight", [
        style({ opacity: 0, transform: "translateX(5%)" }),
        animate("400ms ease-in-out")
      ]),
      state("fromRightOut", style({ opacity: 0, transform: "translateX(-5%)" })),
      transition("fromRight => fromRightOut", [
        style({ opacity: 1, transform: "translateX(0)" }),
        animate("300ms ease-in-out")
      ]),

      // Enter from left
      state("fromLeft", style({ opacity: 1, transform: "translateX(0)" })),
      transition("* => fromLeft", [
        style({ opacity: 0, transform: "translateX(-5%)" }),
        animate("400ms ease-in-out")
      ]),
      state("fromLeftOut", style({ opacity: 0, transform: "translateX(5%)" })),
      transition("fromLeft => fromLeftOut", [
        style({ opacity: 1, transform: "translateX(0)" }),
        animate("300ms ease-in-out")
      ]),

      // Rotate
      state("scale", style({ opacity: 1, transform: "scale(1)" })),
      transition("* => scale", [
        style({ opacity: 0, transform: "scale(0)" }),
        animate("400ms ease-in-out")
      ]),
      state("scaleOut", style({ opacity: 0, transform: "scale(0)" })),
      transition("scale => scaleOut", [
        style({ opacity: 1, transform: "scale(1)" }),
        animate("400ms ease-in-out")
      ]),

      // Scale
      state("rotate", style({ opacity: 1, transform: "rotate(0deg)" })),
      transition("* => rotate", [
        style({ opacity: 0, transform: "rotate(5deg)" }),
        animate("400ms ease-in-out")
      ]),
      state("rotateOut", style({ opacity: 0, transform: "rotate(-5deg)" })),
      transition("rotate => rotateOut", [
        style({ opacity: 1, transform: "rotate(0deg)" }),
        animate("400ms ease-in-out")
      ])
    ])
  ],
  templateUrl: "./notifications.html"
})

export class VNCTalkNotificationComponent implements OnInit, OnDestroy {
  selectedConversation: NewConversation;
  userJID: JID;

  @Input() timeOut: number;
  @Input() showProgressBar: boolean;
  @Input() pauseOnHover: boolean;
  @Input() clickToClose: boolean;
  @Input() maxLength: number;
  @Input() theClass: string;
  @Input() rtl: boolean;
  @Input() animate: string;
  @Input() position: number;
  @Input() item: Notification;
  @ViewChild("videoArea", { static: false }) videoArea;

  // Progress bar variables
  progressWidth = 0;
  stopTime = false;
  timer: any;
  steps: number;
  speed: number;
  count = 0;
  start: any;
  diff: any;
  defaultBackground = "rgba(25,125,194, 0.8)";
  activeCallInterval: any;
  isAlive = true;
  enableAudio: boolean;
  enableVideo: boolean;
  hasWebcam = false;
  participants = [];
  backCameraId = "";
  frontCameraId = "";
  private _roomName;
  private isAlive$ = new Subject<boolean>();
  conferenceType: string;
  showAudioMutedPopup: boolean = false;
  showVideoMutedPopup: boolean = false;
  showAudioUnmutedPopup: boolean = false;
  showVideoUnmutedPopup: boolean = false;
  audioMutedPopup: MatDialogRef<any>;
  videoMutedPopup: MatDialogRef<any>;
  audioUnmutedPopup: MatDialogRef<any>;
  videoUnmutedPopup: MatDialogRef<any>;
  isAudioPopupOpen: boolean;
  isVideoPopupOpen: boolean;
  isOnSafari = CommonUtil.isOnSafari() && !CommonUtil.isOnNativeMobileDevice();
  private callInvitationResult: string = "";
  meetingName: string;
  isScreenSharing: boolean;
  floatingParticipant: any;
  fullName: any;
  onHide$ = new Subject<boolean>();
  callDialog$: MatDialogRef<IncomingCallComponent, any>;

  constructor(private broadcaster: Broadcaster,
    private toastService: ToastService,
    private router: Router,
    private notificationService: VNCTalkNotificationsService,
    private zone: NgZone,
    private translate: TranslateService,
    private filesStorageService: FilesStorageService,
    private store: Store<TalkRootState>,
    private contactRepo: ContactRepository,
    private conferenceRepo: ConferenceRepository,
    private appService: AppService,
    private configService: ConfigService,
    private changeDetectorRef: ChangeDetectorRef,
    private conversationRepo: ConversationRepository,
    private electronService: ElectronService,
    private avatarRepo: AvatarRepository,
    private sharingService: SharingService,
    private xmppService: XmppService,
    private logger: LoggerService,
    public dialog: MatDialog) {

    this.store.select(getSelectedConversation).pipe(takeWhile(() => this.isAlive)).subscribe(conv => {
      this.selectedConversation = conv;
      this.conferenceRepo.getActiveConference().pipe(take(1)).subscribe(conversationTarget => {
        if (this.item && this.item.type === "active" && conv && conv.Target === conversationTarget) {
          this.remove();
          this.conferenceRepo.hideActiveCall();
        } else if (this.item && this.item.type === "meeting" && conv && conv.Target === conversationTarget) {
          this.remove();
        }
        this.conferenceRepo.getConferenceType().pipe(take(1)).subscribe(conferenceType => {
            if (conferenceType === "screen") {
              this.isScreenSharing = true;
            }
        });
      });
    });

    this.conferenceRepo.getParticipants().pipe(takeWhile(() => this.isAlive)).subscribe(res => {
      this.participants = res;
      this.changeDetectorRef.markForCheck();
    });

    this.store.select(getUserJID).pipe(takeWhile(() => this.isAlive)).subscribe(jid => this.userJID = jid);

    this.broadcaster.on<string>(BroadcastKeys.HIDE_INVITATION).pipe(takeUntil(this.isAlive$)).subscribe(conversationTarget => {
      this.handleHideNotification(conversationTarget);
    });

    this.broadcaster.on<string>(BroadcastKeys.HIDE_INCOMING_INVITATION).pipe(takeUntil(this.isAlive$)).subscribe(conversationTarget => {
      this.handleHideNotification(conversationTarget);
    });
  }

  handleHideNotification(conversationTarget) {
    let target = this.item.override ? this.item.override.conversationTarget || this.item.override.conferenceId || "" : "";
    if (target.indexOf("#") !== -1) {
      target = target.replace(/#/g, "@").split(",").filter(v => v !== this.userJID?.bare)[0];
    }
    this.logger.info("[notificationComponent][ConferenceRepository][handleHideNotification]", conversationTarget, target, this.item.override);
    if (target === conversationTarget) {
      this.hideNotification();
    }
  }

  get isActiveMeeting() {
    return this.item.type === "active" && this.item.override
    && CommonUtil.isVideoMeeting(this.item.override.conversationTarget);
  }

  setFloatingVideo(participantId?: any) {
    this.conferenceRepo.getParticipants().pipe(take(1)).subscribe(res => {
      const callParticipants = res;
      if (participantId) {
        const participant = callParticipants.find(v => v.id === participantId);
        this.floatingParticipant = participant;
      } else if (callParticipants.find(val => val.name === "ME")) {
        this.floatingParticipant = callParticipants.find(val => val.name === "ME");
      }
      this.logger.info("[setFloatingVideo]", this.floatingParticipant);
    });
  }

  ngOnInit() {
    this.conversationRepo.item = this.item;
    if (this.item.type === "audiocall" || this.item.type === "active" || this.item.type === "videocall") {
      this.callInvitationComponent();
      this.logger.info("[VNCTalkNotificationComponent][ngOnInit] audioStatus: videoStatus:", this.enableAudio, this.enableVideo);

      this.logger.info("[VNCTalkNotificationComponent][ngOnInit] confType: ", this.conferenceType);

      this.broadcaster.on<any>("onConferenceTypeChangeForParticipant").pipe(takeUntil(this.isAlive$))
        .subscribe(res => {
          this.logger.info("[VNCTalkNotificationComponent][onConferenceTypeChange] ", res);
        });
        this.broadcaster.on<any>("onTrackMuteUnmute").pipe(takeUntil(this.isAlive$))
      .subscribe(track => {

      });

      this.conferenceRepo.hasWebcam().pipe(takeWhile(() => this.isAlive)).subscribe(res => {
        this.hasWebcam = res;
        this.changeDetectorRef.markForCheck();
      });


      this.conferenceRepo.getBackCameraId().pipe(takeWhile(() => this.isAlive)).subscribe(res => {
        this.backCameraId = res;
      });

      this.conferenceRepo.getFrontCameraId().pipe(takeWhile(() => this.isAlive)).subscribe(res => {
        this.frontCameraId = res;
      });
    }
    if (this.item.type === "screen") {
      this.fullName = this.contactRepo.getFullName(this.item.bare);
    }
    if (this.animate) {
      this.item.state = this.animate;
    }
    if (this.item.override) {
      this.attachOverrides();
    }
    if (this.timeOut !== 0 && (this.item.type !== "audiocall" && this.item.type !== "screen" && this.item.type !== "active" && this.item.type !== "videocall"  && this.item.type !== "recording"
      && this.item.type !== "whiteboard" && this.item.type !== "rfc")) {
      this.startTimeOut();
    }

    if ((this.item.override && this.item.override.chatType === "groupchat") || this.item.type === "groupchat" || this.item.type === "mention") {
      let roomName = this.item.bare;
      if (this.item.override && this.item.override.conversationTarget) {
        roomName = this.item.override.conversationTarget;
      } else if (this.item.override && this.item.override.room) {
        roomName = this.item.override.room;
      }
      this.store.select(state => getConversationById(state, roomName))
        .pipe(take(1))
        .subscribe(conv => {
          if (!conv) {
            this._roomName = ConversationUtil.getGroupChatTitle(roomName);
          } else {
            this._roomName = conv.groupChatTitle;
          }
        });
    }


    if (this.item.type === "meeting") {
      this.conversationRepo.getConversationById(this.item.bare).pipe(take(1)).subscribe(conv => {
        if (!!conv) {
          this.meetingName = conv.groupChatTitle;
          if (this.meetingName && this.meetingName.length > 20) {
            this.meetingName = this.meetingName.slice(0, 20) + "...";
          }
          this.changeDetectorRef.markForCheck();
        }
      });
    }

    this.broadcaster.on<any>("closeNotification").pipe(takeUntil(this.isAlive$)).subscribe(signal => {
      const data = signal.data ? JSON.parse(signal.data) : {};
      if (this.item.override?.file === data?.override?.file) {
        this.remove();
      }
    });
  }

  padZeros(num) {
    if (num < 10) {
      return "0" + num;
    }
    return num;
  }

  getFullName(target: string) {
    return this.contactRepo.getFullName(target);
  }

  getSessions() {
    if (this.item.override && this.item.override.viewers) {
      return Object.keys(this.item.override.viewers);
    }
    return [];
  }

  startTimeOut() {
    if (this.item.type !== "audiocall" && this.item.type !== "videocall" && this.item.type !== "active") {
      this.steps = this.timeOut / 1000;
    }
    if ( this.item.type === "channels" ) {
      this.speed = 2500;
    } else {
      this.speed = this.timeOut / this.steps;
    }
    this.start = new Date().getTime();
    this.zone.runOutsideAngular(() => this.timer = setTimeout(this.instance, this.speed));
  }

  onEnter() {
    if (this.pauseOnHover) {
      this.stopTime = true;
    }
  }

  onLeave() {
    if (this.pauseOnHover) {
      this.stopTime = false;
      setTimeout(this.instance, (this.speed - this.diff));
    }
  }

  setPosition(): number {
    return this.position !== 0 ? this.position * 90 : 0;
  }

  onClick($e) {
    if (this.item.click) {
      this.item.click.emit($e);
    }
    if (this.clickToClose) {
      let target = this.item.bare;
      if (this.item.type === "active") {
        target = this.item.override.conversationTarget;
      }
      this.conversationRepo.markConversationAsRead(target);
      if (this.item.type === "chat") {
        this.conferenceRepo.getOrCreateConversation(this.item.bare);
        this.remove();
      } else if (this.item.type === "groupchat" || this.item.type === "mention") {
        this.conferenceRepo.getOrCreateConversation(this.item.bare);
        if (this.item.type === "mention") {
          // this.conversationRepo.setUnreadIdsForTarget(target, [this.item.override.messageId.toString()]);
        }
        this.remove();
      } else if (this.item.type === "active") {
        this.conferenceRepo.getOrCreateConversation(this.item.override.conversationTarget);
        if (window.location.href.indexOf("/talk") === -1) {
          this.router.navigate(["/talk"]);
        }
      }
    }
  }

  goToMeeting() {
    this.remove();
    this.conversationRepo.navigateToConversation(this.item.bare);
  }

  // Attach all the overrides
  attachOverrides() {
    Object.keys(this.item.override).forEach(a => {
      if (this.hasOwnProperty(a)) {
        (<any>this)[a] = this.item.override[a];
      }
    });
  }

  ngOnDestroy() {
    if (this.item.type === "wakeupme") {
      this.conferenceRepo.stopWakeUp();
    }
    clearTimeout(this.timer);
    this.isAlive = false;
    this.broadcaster.broadcast("toggleHideVideoIOS", false);
  }

  private instance = () => {
    this.zone.runOutsideAngular(() => {
      this.zone.run(() => this.diff = (new Date().getTime() - this.start) - (this.count * this.speed));
      if (this.count++ === this.steps) {
        this.zone.run(() => this.remove());
      } else if (!this.stopTime) {
        if (this.showProgressBar) {
          this.zone.run(() => this.progressWidth += 100 / this.steps);
        }
        this.timer = setTimeout(this.instance, (this.speed - this.diff));
      }
    });
  };

  remove() {
    if (this.animate) {
      this.item.state = this.animate + "Out";
      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          this.zone.run(() => this.notificationService.set(this.item, false));
        }, 310);
      });
      if (this.item.type === "active") {
        this.conferenceRepo.hideActiveCall();
      }
    } else {
      this.notificationService.set(this.item, false);
    }
    this.onHide$.next(true);
  }

  decline() {
    this.sendRejectSignal();
  }

  private hide() {
    this.hideNotification();
  }

  sendCancelSignal(): void {
    let conversationTarget: string = this.item.override.conversationTarget;
    this.logger.info("[sendCancelSignal]", this.item);
    let jitsiOption = this.item.override.jitsiOption;
    let callSignal = this.conferenceRepo.buildCallSignalMessage(conversationTarget, jitsiOption, this.item.override.conferenceType, "leave", this.item.override.conferenceKey);
    if (this.item.override.timestamp) {
      // callSignal.timestamp = this.item.override.timestamp;
      localStorage.setItem("startingCallTime", callSignal.timestamp + "");
    }
    this.conferenceRepo.sendCallSignal(conversationTarget, callSignal);
  }

  private sendJoinSignal(): void {
    this.incomingCallResponse("join");
  }

  private sendRejectSignal(): void {
    this.incomingCallResponse("reject");
  }

  private incomingCallResponse(eventType: string) {
    this.logger.info("[NotificationComponent][incomingCallResponse]", eventType, this.item.override);

    // extract call params
    let to = this.item.override.from;
    if (this.item.override.conferenceId.indexOf("@") !== -1) {
      to = this.item.override.conferenceId;
    }
    const conferenceId = this.item.override.conferenceId;
    const conferenceType = this.item.override.conferenceType;
    const jitsiOption = this.item.override.jitsiOption;

    this.broadcaster.broadcast(ConstantsUtil.CLOSE_SIDEBAR);

    if (eventType === "join") {
      // perform accept action
      const timestamp = this.item.override.timestamp;
      this.conferenceRepo.acceptCallAction(to, conferenceId, conferenceType, jitsiOption, timestamp);
    } else if (eventType === "reject") {
      // perform reject action
      this.conferenceRepo.rejectCallAction(to, conferenceId, conferenceType, jitsiOption);
    }

    this.hideNotification();
    setTimeout(() => {
      if (eventType === "join" && conferenceId.indexOf("@conference") > -1) {
        this.store.dispatch(new ConversationUpdate({ target: conferenceId, changes: { has_active_call: true, ended_call_time: null } }));
      }
      this.conversationRepo.reloadConversationHistory();
    }, 300);
  }

  private hideNotification() {
    this.logger.info("[notificationComponent][hideNotification]");

    this.conferenceRepo.stopPlayIncomingCall();
    this.conferenceRepo.invalidateCallRequestTimeoutTimer();
    if (this.callDialog$ && this.callDialog$.close) {
      this.callDialog$.close();
    }
    this.remove();
  }

  denyWhiteboardInvitation() {
    this.remove();
  }

  acceptWhiteboardInvitation() {
    this.store.dispatch(new SetActiveTab("chat"));
    this.broadcaster.broadcast("acceptWhiteboardInvitation", this.item);
    let conversationTarget: string = this.item.bare;
    if (this.item.override.conferenceId.indexOf("@") !== -1) {
      conversationTarget = this.item.override.conferenceId;
    }
    this.conversationRepo.updateConversationRoomId(conversationTarget, this.item.override.jitsiRoom);
    let routeChanged: boolean = this.conferenceRepo.checkRouteChanged(conversationTarget);
    this.conferenceRepo.getOrCreateConversation(conversationTarget);
    if (!routeChanged) {
      this.conferenceRepo.startWhiteboard(conversationTarget);
    } else {
      this.router.events
        .pipe(filter(e => e instanceof NavigationEnd), take(1))
        .subscribe(() => {
          this.conversationRepo.getSelectedConversation().pipe(filter(res => !!res), take(1)).subscribe(conv => {
            if (conv && conv.Target === conversationTarget) {
              this.conferenceRepo.startWhiteboard(conversationTarget);
            }
          });
        });
    }
    this.remove();
  }

  get roomName() {
    return this._roomName;
  }

  startAudio() {
    this.startCall("audio");
  }

  /**
   * Function to accept screen share only session at the receiver end
   */
  acceptScreenShareSession(){
    this.startCall("screen-receive");
  }

  startCall(type: string): void {
    this.logger.info("[NotificationComponent][startCall]", type, this.item);
    const vncTalkConference = this.item.override;
    let conversationTarget: string = this.item.bare;
    let sentBy: string = vncTalkConference.from;
    this.conferenceRepo.stopPlayCalling();
    this.conferenceRepo.stopPlayIncomingCall();
    this.conferenceRepo.invalidateCallRequestTimeoutTimer();
    if (vncTalkConference.conferenceId.indexOf("@") !== -1) {
      conversationTarget = vncTalkConference.conferenceId;
      this.conversationRepo.getConversationMCBsFromRedux(conversationTarget).pipe(take(1)).subscribe(mcbs => {
        this.logger.info("[NotificationComponent][getConversationMCBsFromRedux]", mcbs, sentBy);
        if (mcbs && mcbs.length > 0) {
          let iomDomain = "";
          const domain = this.conversationRepo.getXmppDomain();
          const conferenceDomain = `conference.${domain}`;
          const conferenceTargetDomain = conversationTarget.split("@")[1];
          if (conferenceDomain !== conferenceTargetDomain) {
            iomDomain = conferenceTargetDomain;
          }
          this.conferenceRepo.joinMCBCallFlowFromNotification(vncTalkConference.jitsiRoom, iomDomain, sentBy).subscribe(v => {
            if (!!v) {
              this.joinCall(type);
            } else {
              this.toastService.show("CANNOT_START_CALL");
            }
          });

        } else {
          this.joinCall(type);
        }
      });
    } else {
      this.joinCall(type);
    }
  }

  private joinCall(type) {
    this.store.dispatch(new SetActiveTab("chat"));
    this.conferenceRepo.getActiveConference().pipe(take(1)).subscribe(conversationTarget => {
      this.logger.info("[getActiveConference]", conversationTarget);
      if (conversationTarget) {
        this.broadcaster.broadcast("hangup");
        this.conferenceRepo.hangupCall();
        setTimeout(() => {
          this.prepareAndStartCall(type);
        }, 3000);
      } else {
        this.prepareAndStartCall(type);
      }
    });
  }

  private prepareAndStartCall(type: string): void {
    const vncTalkConference = this.item.override;

    const jitsiOption: JitsiOption = {
      value: vncTalkConference.jitsiRoom,
      jitsiurl: vncTalkConference.jitsiURL
    };

    let conversationTarget: string = this.item.bare;
    if (vncTalkConference.conferenceId.indexOf("@") !== -1) {
      conversationTarget = vncTalkConference.conferenceId;
    }
    this.conversationRepo.markConversationAsRead(conversationTarget);
    this.conversationRepo.updateConversationRoomId(conversationTarget, vncTalkConference.jitsiRoom);

    this.logger.info("[NotificationComponent][prepareAndStartCall]", jitsiOption, vncTalkConference);
    this.conferenceRepo.resetInvitedParticipants();
    this.conferenceRepo.setJitsiRoom(jitsiOption);

    this.logger.info("[NotificationComponent][prepareAndStartCall] createJitsiRoom");
    this.conferenceRepo.createJitsiRoom(vncTalkConference.conferenceId, jitsiOption.value, jitsiOption.jitsiurl).subscribe(res => {
      this.logger.info("[NotificationComponent][prepareAndStartCall] createJitsiRoom, res: ", res);

      this.conversationRepo.updateConversationRoomId(conversationTarget, jitsiOption);

      let routeChanged: boolean = this.conferenceRepo.checkRouteChanged(conversationTarget);
      this.conferenceRepo.getOrCreateConversation(conversationTarget, vncTalkConference.skipAddUserToChatWhenAddToCall);

      this.sendJoinSignal();

      if (!routeChanged) {
        this.conferenceRepo.startCall(conversationTarget, type);
      } else {
        this.router.events
          .pipe(filter(e => e instanceof NavigationEnd), take(1))
          .subscribe(() => {
            this.conversationRepo.getSelectedConversation().pipe(filter(res => !!res), take(1)).subscribe(conv => {
              if (conv && conv.Target === conversationTarget) {
                this.conferenceRepo.startCall(conversationTarget, type);
              }
            });
          });
      }
    });
  }

  startVideo() {
    this.startCall("video");
  }

  turnOffVideo() {
    this.logger.info("[turnOffVideo]");
    this.conferenceRepo.muteVideo();
  }

  muteAudio() {
    this.conferenceRepo.muteAudio();
  }


  private declineActiveCallIfExists() {
    this.hideAllPopups();
    this.remove();
    const isActiveCall = this.conferenceRepo.hangupCallIfActive();
    this.logger.info("[NotificationComponent][declineActiveCallIfExists]", isActiveCall);
    return isActiveCall;
  }

  private hideAllPopups() {
    if (this.showAudioMutedPopup) {
      this.audioMutedPopup.close();
    }
    if (this.showAudioUnmutedPopup) {
      this.audioUnmutedPopup.close();
    }
    if (this.showVideoMutedPopup) {
      this.videoMutedPopup.close();
    }
    if (this.showVideoUnmutedPopup) {
      this.videoUnmutedPopup.close();
    }
  }

  setBackground() {
    if (this.item.override && this.item.override.bgColor) {
      return this.item.override.bgColor;
    }
    return this.defaultBackground;
  }

  hasIncomingCall() {
    if (window.location.href.indexOf("/talk") !== -1) {
      this.broadcaster.broadcast("exchangePage", {});
    } else if (window.location.href.indexOf("/talk") === -1) {
      // this.xmppService.isHasIncomingCall = true;
      this.router.navigate(["/talk"]);
    }

  }

  processText(text) {
    if (text) {
      return this.appService.renderEmoji(text);
    }

    return "";
  }


  handleCallInvitation() {
    let style: any = {
      width: "440px",
      height: "632px"
    };
    if (CommonUtil.isOnMobileDevice()) {
      style = {
        width: "100vw",
        maxWidth: "100vw",
        height: "100vh"
      };
    }
    let conversationTarget: string = this.item.bare;
    let avatarName = this.avatarRepo.buildTargetHash(conversationTarget);
    avatarName = `${avatarName}-420`;
    const avatarUrl = this.configService.avatarServiceUrl + "/" + avatarName + ".jpg";
    this.logger.info("handleCallInvitation", this.item);
    const displayName = this.contactRepo.getFullName(conversationTarget);
    const triggerEvent = new Subject<any>();
    const closeEvent = new Subject<any>();
    triggerEvent.pipe(takeUntil(this.isAlive$)).subscribe(v => {
      this.logger.info("[IncomingCallComponent] triggerEvent", v);
      switch (v?.action) {
        case "callback": this.sharingService.underDevelopment(); break;
        case "muteSound": {
          this.conferenceRepo.stopPlayCalling();
          this.conferenceRepo.stopPlayIncomingCall();
          break;
        }
        case "blockUser": {
          this.conversationRepo.blockContact(conversationTarget);
          break;
        }
        case "reply": {
          const coversationType = v?.callData?.bare.includes("@conference") ? "groupchat" : "chat";
          this.decline();
          const actionTrans = v?.callData?.additionalActionTranslations;
          this.logger.info("[IncomingCallComponent] triggerEvent actionTrans", actionTrans);
          let actionMessage = "#quickreply_";
          if (!!actionTrans && (Object.keys(actionTrans).length > 0)) {
            Object.keys(actionTrans).forEach(k => {
              if (actionTrans[k] === v?.value) {
                actionMessage += k;
              }
            });
          }
          const actionBody =  (actionMessage !== "#quickreply_") ? actionMessage : v?.value;

          this.logger.info("[IncomingCallComponent] triggerEvent actionBody", actionMessage, actionBody);

          setTimeout(() => {
            this.conversationRepo.sendMessage({
              body: actionBody,
              type: coversationType
            }, v?.callData?.bare, coversationType);
          }, 1100);

          break;
        }
      }
    });
    const additionalActionsTranslationKeys = ["QUICK_REPLY_CANT_TALK_WHATS_UP", "QUICK_REPLY_CALL_YOU_RIGHT_BACK", "QUICK_REPLY_CALL_YOU_LATER", "QUICK_REPLY_CANT_TALK_CALL_ME_LATER"];
    let additionActionTranslations = {};
    this.translate.get(additionalActionsTranslationKeys).pipe(take(1)).subscribe(v => {
      additionActionTranslations = v;
    });
    const actions = additionalActionsTranslationKeys.map(k => {
      return {
        action: "reply",
        label: additionActionTranslations[k]
      };
    });

    const translationsKey = ["INCOMING_AUDIO_CALL", "INCOMING_VIDEO_CALL", "BLOCK_USER", "MUTE_SOUND", "CALL_BACK_MESSAGE"];
    let translations = {};
    this.translate.get(translationsKey).pipe(take(1)).subscribe(v => {
      translations = v;
    });
    this.broadcaster.broadcast("toggleHideVideoIOS", true);
    this.callDialog$ = this.dialog.open(IncomingCallComponent, Object.assign({
      backdropClass: "vnc-dialog-backdrop",
      panelClass: "vnc-dialog-panel",
      disableClose: true,
      hasBackdrop: false,
      data: {
        ...this.item,
        ...translations,
        avatarUrl: avatarUrl,
        additionalActionTranslations: additionActionTranslations,
        displayName: displayName,
        triggerEvent: triggerEvent,
        isOnMobile: CommonUtil.isOnMobileDevice(),
        closeEvent: closeEvent,
        type: this.conversationRepo.callInvitationType,
        additionalActions: actions
      },
      autoFocus: true
    }, style));
    let onHide = null;
    this.callDialog$.afterOpened().pipe(take(1)).subscribe(() => {
      onHide = this.broadcaster.on<string>(BroadcastKeys.HIDE_INCOMING_INVITATION).pipe(takeUntil(this.isAlive$)).subscribe(target => {
        this.logger.info("[IncomingCallComponent][HIDE_INCOMING_INVITATION]", conversationTarget, target);
        if (conversationTarget === target) {
          closeEvent.next(true);
          this.callDialog$.close();
        }
        if (onHide) {
          onHide.unsubscribe();
        }
        this.hideNotification();
      });
      this.onHide$.pipe(take(1)).subscribe(v => {
        if (!!v) {
          closeEvent.next(true);
          this.callDialog$.close();
        }
      });
    });
    this.callDialog$.afterClosed().pipe(take(1)).subscribe(data => {
      this.logger.info("[IncomingCallComponent]", data);
      this.broadcaster.broadcast("toggleHideVideoIOS", false);
      if (data && data.startCall) {
        if (data.startCall === "video") {
          this.startVideo();
        } else if (data.startCall === "audio") {
          this.startAudio();
        } else if (data.startCall === "screen") {
          this.startCall("screen-receive");
        } else {
          this.decline();
        }
      } else if (data && data.callData) {
        this.decline();
      }
      if (onHide) {
        onHide.unsubscribe();
      }
      this.hideNotification();
    });
  }

  callInvitationComponent() {
    let callerName: string = this.contactRepo.getFullName(this.item.bare);
    this.conversationRepo.callInvitationName = callerName;
    if (this.item.type === "videocall" || this.item.type === "audiocall" || this.item.type === "screen") {
      if (this.item.type === "videocall") {
        this.conversationRepo.callInvitationType = "video";
      } else if (this.item.type === "audiocall") {
        this.conversationRepo.callInvitationType = "audio";
      } else {
        this.conversationRepo.callInvitationType = "screen";
      }
      this.conversationRepo.item = this.item;
      this.handleCallInvitation();
    }
  }
  actionRaise(action: string, id: string) {
    this.remove();
  }

  denyRfcInvitation() {
    if ( this.item && this.item.override && this.item.override.token) {
      this.broadcaster.broadcast("denyrfcinvitation", this.item.override.token);
    }
    this.remove();
  }

  acceptRfcInvitation() {
    if ( this.item && this.item.override && this.item.override.token) {
      this.broadcaster.broadcast("acceptrfcinvitation", this.item.override.token);
    }
    this.remove();
  }

  getSingleTarget(convTarget) {
    let target = convTarget;
    if (target.indexOf("#") !== -1) {
      target = target.replace(/#/g, "@").split(",").filter(v => v !== this.userJID?.bare)[0];
    }
    return target;
  }
  openFiles() {
    const targetConversationId = this.getSingleTarget( this.item.override.conv);
    let targetConversation = null;

    this.conversationRepo.navigateToConversation(this.getSingleTarget( this.item.override.conv));
    this.conversationRepo.getConversationById(targetConversationId).pipe(take(1)).subscribe(conv => {
      if (!!conv) {
        targetConversation = conv;
      }
    });
    if (CommonUtil.isMobileSize()) {
      this.showMedia();
    } else {
      if (targetConversation.type == "chat") {
        this.broadcaster.broadcast(ConstantsUtil.CLOSE_SIDEBAR);
        this.store.dispatch(new ExpandChatRightSidebarWithProfile(targetConversation.Target));
        this.store.dispatch(new ExpandChatRightSidebarWithProfileTab("filesTab"));
        this.store.dispatch(new SetChatFileTabSidebar("recording"));
      } else {
        this.broadcaster.broadcast(ConstantsUtil.CLOSE_SIDEBAR);
        this.store.dispatch(new ExpandSideRostersList(false));
        this.store.dispatch(new ExpandChatFilesSidebar(true));
        this.store.dispatch(new SetChatFileTabSidebar("recording"));
      }
    }
    this.remove();
  }

  async showMedia() {
    let dialogStyles: any = {
      "width": "611px",
      "height": "513px",
      "visibility": "visible"
    };
    if (CommonUtil.isMobileSize()) {
      dialogStyles = {
        "width": "100%",
        "maxWidth": "100%",
        "height": "100%",
        "visibility": "visible"
      };
    }
    const { ChatMediaComponent } = await import(
      "../shared/components/chat-media/chat-media.component");
    this.dialog.open(ChatMediaComponent, Object.assign({
      data: this.getSingleTarget( this.item.override.conv),
      backdropClass: "vnctalk-form-backdrop",
      panelClass: "vnctalk-form-panel",
      disableClose: true,
      autoFocus: true
    }, dialogStyles));

  }

  downloadFile() {
    let serverURL = window.location.origin;
    if (environment.isCordova || environment.isElectron) {
        serverURL = localStorage.getItem("serverURL");
    }
    let url = serverURL + "/api/fetchRecording/" + btoa(this.item.override.conv) + "/" + this.item.override.callId + "/" + this.item.override.file;
    if (environment.isCordova) {
      const translatedUrl = CommonUtil.translateHINFileURL(url);
      let headers;
      if (CommonUtil.isOnIOS()) {
          headers = [{
              Key: "Authorization",
              Value: localStorage.getItem("token")
          }];
      } else {
          headers = {"Authorization": localStorage.getItem("token")};
      }
      this.toastService.showSnackbar("FILE_STARTED_DOWNLOADING", 5000);
      this.filesStorageService.downloadAndSaveFileInBackground(translatedUrl, headers, CommonUtil.isOnIOS()).subscribe((localFileUrl) => {
        this.translate.get("FILE_DOWNLOADED").pipe(take(1)).subscribe(text => {
          alert(text);
        });
        if (!CommonUtil.isOnAndroid()) {
          CommonUtil.openLocalFile(localFileUrl);
        }
      }, err => {
        this.logger.error("[download recording][downloadInBackgroundFileAsBlob] err", err);
        this.toastService.show("SOME_UNKNOWN_ERROR");
      });
    } else if (environment.isElectron) {
        this.electronService.fileDownloader(url).subscribe(() => {
            this.toastService.showSnackbar("FILE_DOWNLOADED", 2000);
        }, (error) => {
            this.logger.info("[PreviewImageDialogComponent][downloadFile] error: ", error);
        });
    } else {
      window.open(url, "_blank");
    }
    this.remove();
  }

  clickOnNotificationItem($event) {
    this.broadcaster.broadcast("CLICK_CHANNEL_NOTIFICATION", $event);
    this.remove();
  }

  closeNotification() {
    const signal = {
      type: "closeNotification",
      target: this.item.bare,
      data: JSON.stringify(this.item)
    };
    this.xmppService.sendSignalToMyself(signal);
    this.logger.info("closeNotification", signal);
    this.remove();
  }
}
