
<!--
  ~ VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

  <a  class="wdt-emoji-popup-mobile-closer" *ngIf="showCloseButton" (click)="close()"> × </a>
  <div class="wdt-emoji-menu-content">
    <div class="input-search-box">
      <mat-icon fontSet="mdi" fontIcon="mdi-magnify"></mat-icon>
      <input [(ngModel)]="searchInput" id="wdt-emoji-search" type="text" placeholder="{{ 'SEARCH' | translate }}">
      <mat-icon *ngIf="!!searchInput" (click)="clearSearch()" fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </div>
    <div class="wdt-emoji-scroll-wrapper">
      <div id="wdt-emoji-menu-items">
        <div class="wdt-emoji-sections"></div>
      </div>
    </div>
    <div id="wdt-emoji-menu-header">
      <a style="display: none" class="wdt-emoji-tab " data-group-name="Recent"></a>
      <a class="wdt-emoji-tab active" data-group-name="People"></a>
      <a class="wdt-emoji-tab" data-group-name="Nature"></a>
      <a class="wdt-emoji-tab" data-group-name="Foods"></a>
      <a class="wdt-emoji-tab" data-group-name="Activity"></a>
      <a class="wdt-emoji-tab" data-group-name="Places"></a>
      <a class="wdt-emoji-tab" data-group-name="Objects"></a>
      <a class="wdt-emoji-tab" data-group-name="Symbols"></a>
      <a class="wdt-emoji-tab" data-group-name="Flags"></a>
      <!-- <mat-icon fontSet="mdi" fontIcon="mdi-close-circle-outline close_Icon" (click) = "closeEmojiDialog()"></mat-icon> -->
    </div>
    <div id="wdt-emoji-footer">
      <div id="wdt-emoji-preview">
        <span id="wdt-emoji-preview-img"></span>
        <div id="wdt-emoji-preview-text">
          <span id="wdt-emoji-preview-name"></span><br>
          <span id="wdt-emoji-preview-aliases"></span>
        </div>
      </div>
    </div>
    <div id="wdt-emoji-search-result-title" style="display: none"></div>
    <div style="font-family: 'Source Sans Pro';
    position: relative;
    bottom: 230px;
    font-size: 14px;
    color: #606b76;" id="wdt-emoji-no-result">
      <p>
        {{ "NOTHING_TO_SHOW" | translate}}
      </p>
    </div>
  </div>
