import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from "@angular/core";
import { TopicCommentScroll } from "../../../channels/channel-detail/channel-detail.component";
import { Router } from "@angular/router";
import { ChannelRepository } from "../../../channels/repository/channel.repository";
import { Topic } from "../../../channels/models/topic.model";
import { BehaviorSubject } from "rxjs";
import { UntypedFormControl } from "@angular/forms";
import { ConstantsUtil } from "../../utils/constants.util";
import { combineLatest, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, take, takeUntil } from "rxjs/operators";
import { ChannelService } from "../../../channels/channel.service";
import { MatDialog } from "@angular/material/dialog";
import { Channel } from "../../../channels/models/channel.model";
import { Broadcaster } from "../../shared/providers";
import { ToastService } from "../../../shared/services/toast.service";
import { TOPIC_VIEW, UserConfig } from "../../../shared/models/user-config.model";
import { UserConfigRepository } from "../../repositories/userconfig.repository";
import { IconColorConfig } from "vnc-library";
import { getUserConfig } from "../../../reducers";
import { Store } from "@ngrx/store";
import { TalkRootState } from "../../reducers";
import { Attachment } from "../../../channels/models/attachment.model";
import { CommonUtil } from "../../utils/common.util";

import { DEFAULT_AVATAR, DEFAULT_IMG } from "../../../common";
import { ChannelDetailComponentService } from "../../../channels/channel-detail/channel-detail.component.service";
export const REMOVE_CHILD_ARCHIVED_TOPICS = "REMOVE_CHILD_ARCHIVED_TOPICS";
export const REMOVE_PARENT_ARCHIVED_CHANNEL = "REMOVE_PARENT_ARCHIVED_CHANNEL";
import { HostListener } from "@angular/core";

@Component({
  selector: "vp-archive-window-topic",
  templateUrl: "./archive-window-topic.component.html",
  styleUrls: ["./archive-window-topic.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArchiveWindowTopicComponent implements OnInit, OnDestroy {

  constructor(private _router: Router,
    private _dialog: MatDialog,
    private _channelRepository: ChannelRepository,
    private _channelService: ChannelService,
    private _broadcaster: Broadcaster,
    private _toastService: ToastService,
    private _talkRootStore: Store<TalkRootState>,
    private changeDetectorRef: ChangeDetectorRef,
    private _userConfigRepo: UserConfigRepository,
    public channelDetailService: ChannelDetailComponentService) {
    this.userConfig = JSON.parse(localStorage.getItem("userConfigFromServer"));
  }

  @Output() totalTopics = new BehaviorSubject<number>(0);
  topicCommentScroll = TopicCommentScroll;
  groupType = TopicGroupType;
  searchControl = new UntypedFormControl("");
  groupControl = new UntypedFormControl(this.groupType.DATE_ASC);
  topics = new BehaviorSubject<Topic[]>([]);
  data = { offset: 0, limit: ConstantsUtil.PAGINATION_LIMIT.ARCHIVED_TOPICS, q: "", sort: TopicGroupType.DATE_ASC };
  private isAlive$ = new Subject<boolean>();
  dateModeEnum = DateMode;
  defaultAvatar = DEFAULT_AVATAR;
  defaultChannelAvatar = DEFAULT_IMG;
  channelsGroup = [];
  isRightBarExpanded: boolean = false;
  isLeftBarExpanded: boolean = false;
  authorsGroup = [];
  uiTopicView = TOPIC_VIEW;
  currentView = "list";
  viewTileIconColor: IconColorConfig = {
    colorBase: "#8B96A0",
    colorActive: "#337CBD",
    colorDisabled: "#8B96A0"
  };
  heroImageHeight = "244px";
  userConfig: any;
  dismissLoader: boolean = false;

  ngOnInit(): void {
    this._channelRepository.getRightbarExpanded().pipe(takeUntil(this.isAlive$)).subscribe((res: boolean) => {
      this.isRightBarExpanded = res;
      this.changeDetectorRef.markForCheck();
    });

    this._channelRepository.getIsLeftSideBarExpanded().pipe(takeUntil(this.isAlive$)).subscribe((res: boolean) => {
      this.isLeftBarExpanded = res;
      this.changeDetectorRef.markForCheck();
    });
    this.fetchArchivedTopics();
    this.listenSearchChanges();
    this.listenBroadcasterChanges();
    this.getTopicView();
    this._channelRepository.getArchiveTopicsSortBy().pipe(takeUntil(this.isAlive$)).subscribe(sortBy => {
      if (!!sortBy) {
        this.groupControl.setValue(sortBy);
      } else {
        this.groupControl.setValue(this.groupType.DATE_ASC);
      }
    });
    this.groupControl.valueChanges.subscribe(sortBy => {
      this._channelRepository.setArchiveTopicsSortBy(sortBy);
    });
  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }


  @HostListener("document:wheel", ["$event.target"])
  onScroll(): void {
    this.loadMoreTopics();
  }

  fetchArchivedTopics() {
    this.data.offset = 0;
    this.totalTopics.next(0);
    this.topics.next([]);
    this.getArchivedTopics().pipe(takeUntil(this.isAlive$)).subscribe(topics => {
      this.setArchivedTopics(topics);
     });
  }

  openTopic(topic, scrollToCommentSection: TopicCommentScroll) {
    this._router.navigateByUrl(`/talk/channels/${topic.channel_id}/topics/${topic.id}?comment=${scrollToCommentSection}`);
  }

  likesTopic(item: Topic) {
    const updateTopics = (updatedTopic: Topic) => {
      const topics = this.topics.getValue();
      let index = topics.findIndex(item => item.id === updatedTopic.id);
      topics[index].liked = updatedTopic.liked;
      topics[index].likes_count = updatedTopic.likes_count;
      this.topics.next(topics);
    };
    item?.liked ?
      this._channelService.unlikesTopic(item.id).subscribe((value: { topic: Topic }) => updateTopics(value.topic)) :
      this._channelService.likesTopic(item.id).subscribe((value: { topic: Topic }) => updateTopics(value.topic));
  }

  loadMoreTopics() {
    const loadedTopics = this.topics.getValue().length;
    const totalTopics = this.totalTopics.getValue();
    if (loadedTopics < totalTopics) {
      this.getArchivedTopics().subscribe((topics: Topic[]) => this.setArchivedTopics(topics));
    }
  }

  async unArchiveTopic(item: Topic) {
    const topicChannel = await this._channelService.getChannelById(item.channel_id).pipe(map((v: any) => v.channel as Channel), take(1)).toPromise();
    if (topicChannel?.archived) {
      const { UnarchiveTopicComponent } = await import(
        "../unarchive-topic/unarchive-topic.component");
      const unArchiveTopicRef = this._dialog.open(UnarchiveTopicComponent, {
        backdropClass: "vnctalk-form-backdrop",
        panelClass: "vnctalk-form-panel",
        width: "480px",
        height: "336px",
        autoFocus: true,
        data: { channelName: topicChannel.name }
      });
      unArchiveTopicRef.afterClosed()
        .pipe(take(1), filter(val => val?.hasOwnProperty("unArchiveChannelTopics")))
        .subscribe(value => {
          const $ob = value.unArchiveChannelTopics ?
            this._channelService.unArchiveTopic(item.id, true) :
            this._channelService.unArchiveTopic(item.id, true, true);
          $ob.subscribe(() => {
            this.removeTopicLocally(item.id);
            this._broadcaster.broadcast(REMOVE_PARENT_ARCHIVED_CHANNEL, { channel: topicChannel });
          });
        });
    } else {
      this._channelService.unArchiveTopic(item.id).subscribe(() => this.removeTopicLocally(item.id));
    }
  }

  private updateCount(reduceBy = 1) {
    const count = this.totalTopics.value;
    this.totalTopics.next(count - reduceBy);
  }

  private removeTopicLocally(topicId) {
    let topics = this.topics.value;
    topics = topics.filter(t => t.id !== topicId);
    this.topics.next(topics);
    this.updateCount();
  }
  private removeTopicWhoseChannelIsUnarchived(channelId) {
    const oldTopics = this.topics.value;
    const newTopics = oldTopics.filter(t => t.channel_id !== channelId);
    this.topics.next(newTopics);
    this.updateCount(oldTopics.length - newTopics.length);
  }

  underDevelopment() {
    this._toastService.show("UNDER_DEVELOPMENT");
  }

  private listenBroadcasterChanges() {
    this._broadcaster.on(REMOVE_CHILD_ARCHIVED_TOPICS).pipe(takeUntil(this.isAlive$)).subscribe((res: any) => this.removeTopicWhoseChannelIsUnarchived(res?.channelId));
  }

  private getArchivedTopics() {
    return this._channelService.getArchivedTopics(this.data).pipe(takeUntil(this.isAlive$), map(value => {
      let newOffset = (value?.offset || 0) + ConstantsUtil.PAGINATION_LIMIT.ARCHIVED_TOPICS;
      const total_count = value?.total_count || 0;
      newOffset = newOffset > total_count ? total_count : newOffset;
      this.totalTopics.next(total_count);
      this.dismissLoader = true;
      this.data.offset = newOffset;
      return (value?.topics || []);
    }));
  }

  private listenSearchChanges() {
    const $searchControlOb = this.searchControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged(), startWith(""));
    const $groupControlOb = this.groupControl.valueChanges.pipe(startWith(this.groupType.DATE_ASC));
    combineLatest([$searchControlOb, $groupControlOb])
      .pipe(takeUntil(this.isAlive$), switchMap((value: [string, TopicGroupType]) => {
        this.data.q = value[0] || "";
        this.data.sort = value[1] || TopicGroupType.DATE_ASC;
        this.data.offset = 0;
        this.totalTopics.next(0);
        this.topics.next([]);
        return this.getArchivedTopics();
      })).subscribe((topics: Topic[]) => {
        this.setArchivedTopics(topics);
      });
    this.searchControl.patchValue("");
    this.groupControl.patchValue(this.groupType.DATE_ASC);
  }

  private setArchivedTopics(topics: Topic[]) {
    const filterAndConcat = (oldTopics: Topic[], newTopics: Topic[]) => {
      const resultantTopics = [...oldTopics];
      newTopics.forEach(newTopic => {
        const index = oldTopics.findIndex(oldTopic => oldTopic.id === newTopic.id);
        if (index === -1) {
          resultantTopics.push(newTopic);
        }
      });
      return resultantTopics;
    };
    let allTopics = filterAndConcat(this.topics.getValue(), topics).map(topic => {
      let headerAttachments = topic.attachments.filter(attachment => attachment.is_header === true);
      const heroAttachments = this.updateAttachmentsURLs(headerAttachments);
      return { ...topic, heroAttachments };
    });
    this.topics.next(allTopics);
    this.totalTopics.next(this.totalTopics.getValue());
  }


  async permanentlyDeleteTopic(topic: Topic) {
    let options: any = {
      width: "480px",
      height: "280px"
    };
    if (CommonUtil.isMobileSize()) {
      options = {
        maxWidth: "100vw",
        maxHeight: "100vh",
        minHeight: "300px"
      };
    }
    const { ConfirmationChannelComponent } = await import(
      "../../../channels/confirmation-channel/confirmation-channel.component");
    this._dialog.open(ConfirmationChannelComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: ["vnctalk-form-panel", "confirmation-dialog"],
      disableClose: true,
      data: {
        headerText: "DELETE_TOPIC",
        bodyText: "DELETE_TOPIC_MSG",
        okLabel: "DELETE"
      },
      autoFocus: true
    }, options)).afterClosed().pipe(take(1)).subscribe((res: any) => {
      if (!!res && res.confirmation) {
        if (res.confirmation === "yes") {
          this._channelRepository.deleteTopic(topic.id, topic.channel_id, topic.read);
          this.removeTopicLocally(topic.id);
        }
      }
    });
  }


  changeView(view: TOPIC_VIEW, bypassApi = false) {
    this.currentView = view;
    this.changeDetectorRef.markForCheck();
    if (!bypassApi) {
      this._userConfigRepo.updateUserTopicView(view)
        .subscribe();
    }
  }

  getTopicView() {
    this._talkRootStore.select(getUserConfig)
      .pipe(filter(userConfig => !!Object.keys(userConfig || {})?.length), map((userConfig: UserConfig) => userConfig.topics_view), take(1))
      .subscribe((view: TOPIC_VIEW) => {
        this.changeView(view, true);
      });
  }
  updateAttachmentsURLs(attachments: Attachment[]): Attachment[] {
    return attachments.map(attachment => {
      let content_url = CommonUtil.getAttachmentLocalAPIURL(attachment.content_url);
      let thumbnail_url = CommonUtil.getAttachmentLocalAPIURL(attachment.thumbnail_url);
      return { ...attachment, content_url, thumbnail_url };
    });
  }

  getDefaultCover(topic: Topic) {
    return [
      {
        default_thumbnail_url: topic?.default_cover_url || topic?.default_cover,
        thumbnail_url: topic?.default_cover_url || topic?.default_cover
      }
    ];
  }
}

export enum TopicGroupType {
  "DATE_ASC" = "created_on:asc",
  "DATE_DESC" = "created_on:desc",
  "AUTHOR_ASC" = "author:asc",
  "AUTHOR_DESC" = "author:desc",
  "CHANNEL_ASC" = "channel:asc",
  "CHANNEL_DESC" = "channel:desc"
}

export enum DateMode {
  CREATED_ON = "created_on",
  UPDATED_ON = "updated_on"
}
