<div *ngIf="!data.isMobileView" [ngClass]="smartObjectPopupClass()">

  <div class="smart-objects-preview-component__header">
    <span class="left">Smart Objects</span>

    <div class="right" *ngIf="data.isEditable">
<!--      <mat-icon class="icon-18 blue-hover" [ngClass]="{'active': menuOpened}" svgIcon="add_icon" (click)="menuOpened = true" [matMenuTriggerFor]="smartObjectsMenu"></mat-icon>-->
<!--      <mat-icon class="icon-18 blue-hover" svgIcon="delete" (click)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: smartObjectActions.DELETE })"></mat-icon>-->
      <vnc-action-panel [iconTemplate]="addIconTemplate"
                        [actionColor]="'white'"
                        [ngClass]="{'active': menuOpened}"
                        [matMenuTriggerFor]="smartObjectsMenu"
                        (actionClicked)="menuOpened = true"></vnc-action-panel>
      <vnc-action-panel [iconTemplate]="deleteIconTemplate"
                        [actionColor]="'white'"
                        (actionClicked)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: smartObjectActions.DELETE })"></vnc-action-panel>
      <vnc-action-panel [iconTemplate]="zoomIconTemplate"
                        [actionColor]="'white'"
                        (actionClicked)="onToggleFullScreen()"></vnc-action-panel>
    </div>
  </div>

  <div class="smart-objects-preview-component__body">
    <div class="category highlight-category" *ngIf="!!highlightControl.value">
      <span class="sub-header">HIGHLIGHT</span>
      <vnc-smart-link-search-result [resultType]="'HIGHLIGHT'"
                                    [showActionIconsOnHover]="data?.isEditable"
                                    [resultData]="{name: highlightControl.value}"
                                    (actionClicked)="onHighlightHoverActionClick($event)"></vnc-smart-link-search-result>
      <div class="edit-box" *ngIf="($editHighlightName | async)">
        <div class="input-wrap">
          <vnc-input [form]="highlightControl" size="l"></vnc-input>
        </div>
        <vnc-secondary-button [leadingIconTemplate]="checkIconTemplate" class="vnc-secondary-button-changes"
                            (onClick)="completeHighlightEdit();onHeaderAndFooterActionClick({ clickEvent: $event, actionType: smartObjectActions.UPDATE })"></vnc-secondary-button>
        <vnc-secondary-button [leadingIconTemplate]="closeIconTemplate"
                              (onClick)="completeHighlightEdit()"></vnc-secondary-button>
      </div>
    </div>
    <div class="category smart-link-category" *ngIf="($filteredSmartSearchResults | async).length > 0">
      <span class="sub-header">SMARTLINKS</span>
      <ng-container *ngFor="let searchResult of ($filteredSmartSearchResults | async); let i = index;">
        <vnc-smart-link-search-result [avatarTemplate]="avatarTemplate" [resultData]="searchResult"
                                      [showActionIconsOnHover]="data?.isEditable"
                                      [resultType]="searchResult?.resultType"
                                      (actionClicked)="onSmartLinkHoverActionClick($event, searchResult)"
                                      (click)="openSmartLinkPreview($event, searchResult)"
                                      [topicsLabel]="searchResult?.topics_count === 1 ? 'topic' : 'topics'">
        </vnc-smart-link-search-result>
        <ng-template #avatarTemplate>
          <vnc-avatar class="channel-avatar" *ngIf="searchResult?.resultType === 'CHANNEL'"
                      [imgSrc]="searchResult?.avatar_url"
                      [defaultAvatarTemplate]="defaultChannelAvatarTemplate"
                      [rightIcon]="searchResult?.author?.avatar_url"
                      [leftIcon]="searchResult?.subscribed ? 'subscribe' : ''"
                      [totalUnread]="searchResult?.unread_topics_count"></vnc-avatar>
          <vnc-avatar class="topic-avatar" *ngIf="searchResult?.resultType === 'TOPIC'"
                      [imgSrc]="searchResult?.heroAttachmentThumbnailUrl"
                      [defaultAvatar]="searchResult?.default_cover_url || searchResult?.default_cover"
                      [rightIcon]="searchResult?.author?.avatar_url"
                      [leftIcon]="searchResult?.subscribed ? 'subscribe' : ''"
                      [totalUnread]="searchResult?.unread_comments_count"></vnc-avatar>
          <vnc-avatar *ngIf="searchResult?.resultType === 'COMMENT'"
                      [imgSrc]="searchResult?.author?.avatar_url"></vnc-avatar>
        </ng-template>
      </ng-container>
    </div>
    <div class="category sticky-note-category" *ngIf="($allStickyNotesData | async)?.length > 0">
      <span class="sub-header">STICKY NOTE</span>
      <ng-container *ngFor="let note of ($allStickyNotesData | async)">
        <vnc-smart-link-search-result [resultType]="'STICKY_NOTE'"
                                      [showActionIconsOnHover]="data?.isEditable"
                                      [resultData]="note"
                                      (actionClicked)="onStickyNoteHoverActionClick($event, note)"
                                      (click)="onStickyNoteHoverActionClick({clickEvent: $event, actionType: 'preview'}, note)"></vnc-smart-link-search-result>
      </ng-container>
    </div>
    <div class="category reference-category" *ngIf="($allReferencesData | async).length > 0">
      <span class="sub-header">REFERENCES</span>
      <ng-container *ngFor="let reference of ($allReferencesData | async)">
        <vnc-smart-link-search-result [resultType]="'REFERENCE'"
                                      [showActionIconsOnHover]="data?.isEditable"
                                      [resultData]="reference"
                                      (actionClicked)="onReferenceHoverActionClick($event, reference)"
                                      (click)="onReferenceHoverActionClick({clickEvent: $event, actionType: 'preview'}, reference)"></vnc-smart-link-search-result>
      </ng-container>
    </div>
  </div>

  <div class="smart-objects-preview-component__footer" *ngIf="($showFooterButtons | async)">
    <vnc-button [height]="'small'"
                [shape]="'rectangle'"
                [type]="'link'"
                [label]="'CANCEL' | translate"
                (onClick)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: smartObjectActions.CANCEL })"
                class="vnc-default"></vnc-button>
    <vnc-button [height]="'small'"
                [shape]="'rectangle'"
                [type]="'primary'"
                [label]="'SAVE' | translate"
                (onClick)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: smartObjectActions.UPDATE })"></vnc-button>
  </div>

</div>

<div *ngIf="data.isMobileView">
  <vnc-dialog [classes]="['mobile-editor-channel-dialog']" [headerTemplate]="mobileHeaderTemplate"
              classes="fixed-size with-border with-border-radius" [footerTemplate]="footerTemplate"
              [bodyTemplate]="bodyTemplate">
  </vnc-dialog>

  <ng-template #mobileHeaderTemplate>
    <vnc-mobile-header headerType="dark-back-more" darkBackMorePlaceHolder="{{ 'SMART_OBJECT' | translate | uppercase }}"
                       [sufixIconTemplate2]="($showFooterButtons | async) ? checkIconTemplate : ''"
                       [sufixIconTemplate1]="kebabIconTemplate"
                       (darkBackPress)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: smartObjectActions.CANCEL })">
    </vnc-mobile-header>
  </ng-template>


  <ng-template #bodyTemplate>
    <div class="smart-object-mobile-body" (click)="removeSwipeActions()" (clickOutside)="removeSwipeActions()">
      <div class="category-mobile" *ngIf="!!highlightControl.value">
        <span class="sub-header-mobile">HIGHLIGHT</span>
        <div class="highlight-category-mobile">
          <div class="smart-entity-wrapper">
            <div class="smart-object-search-result-wrapper" id="highlight-object" (swipeleft)="handleSwipeForEntity('highlight-object', swipe.LEFT)"
                 (swiperight)="handleSwipeForEntity('highlight-object', swipe.RIGHT)">
              <vnc-smart-link-search-result [resultType]="'HIGHLIGHT'"
                                            [showActionIconsOnHover]="data?.isEditable && data?.isMobileView"
                                            [resultData]="{name: highlightControl.value}"
                                            (actionClicked)="onHighlightHoverActionClick($event)"></vnc-smart-link-search-result>
            </div>
            <div class="swipe-edit-button" (click)="onHighlightHoverActionClick({clickEvent: null, actionType: 'edit'})">
              <ng-container [ngTemplateOutlet]="editIconTemplate"></ng-container>
            </div>
            <div class="swipe-delete-button" (click)="onHighlightHoverActionClick({clickEvent: null, actionType: 'delete'})">
              <ng-container [ngTemplateOutlet]="deleteIconTemplate"></ng-container>
            </div>
          </div>
        </div>
        <div class="edit-box" *ngIf="($editHighlightName | async)">
          <div class="input-wrap">
            <vnc-input [form]="highlightControl" size="l"></vnc-input>
          </div>
          <vnc-primary-button [leadingIconTemplate]="checkIconTemplate"
                              (onClick)="completeHighlightEdit()"></vnc-primary-button>
          <vnc-secondary-button [leadingIconTemplate]="closeIconTemplate"
                                (onClick)="completeHighlightEdit()"></vnc-secondary-button>
        </div>
      </div>
      <div class="category-mobile smart-link-category-mobile" *ngIf="($filteredSmartSearchResults | async).length > 0">
        <span class="sub-header-mobile">SMARTLINKS</span>
        <ng-container *ngFor="let searchResult of ($filteredSmartSearchResults | async); let i = index;">

          <div class="smart-entity-wrapper">
            <div [ngClass]="{'mobile-search-result': data?.isMobileView}" class="smart-object-search-result-wrapper" id="{{searchResult?.id}}" (swiperight)="handleSwipeForEntity(searchResult?.id, swipe.RIGHT, true)" (swipeleft)="handleSwipeForEntity(searchResult?.id, swipe.LEFT)">
              <vnc-smart-link-search-result [avatarTemplate]="avatarTemplate" [resultData]="searchResult"
                                            [showActionIconsOnHover]="data?.isEditable && data?.isMobileView"
                                            [resultType]="searchResult?.resultType"
                                            (actionClicked)="onSmartLinkHoverActionClick($event, searchResult)"
                                            (click)="openSmartLinkPreview($event, searchResult)"
                                            [mobileMode]="true"
                                            [actionType]="preview"
                                            [topicsLabel]="searchResult?.topics_count === 1 ? 'topic' : 'topics'">
              </vnc-smart-link-search-result>
              <ng-template #avatarTemplate>
                <vnc-avatar class="channel-avatar" *ngIf="searchResult?.resultType === 'CHANNEL'"
                            [imgSrc]="searchResult?.avatar_url"
                            [defaultAvatarTemplate]="defaultChannelAvatarTemplate"
                            [rightIcon]="searchResult?.author?.avatar_url"
                            [leftIcon]="searchResult?.subscribed ? 'subscribe' : ''"
                            [totalUnread]="searchResult?.unread_topics_count"></vnc-avatar>
                <vnc-avatar class="topic-avatar" *ngIf="searchResult?.resultType === 'TOPIC'"
                            [imgSrc]="searchResult?.heroAttachmentThumbnailUrl"
                            [defaultAvatar]="searchResult?.default_cover_url || searchResult?.default_cover"
                            [rightIcon]="searchResult?.author?.avatar_url"
                            [leftIcon]="searchResult?.subscribed ? 'subscribe' : ''"
                            [totalUnread]="searchResult?.unread_comments_count"></vnc-avatar>
                <vnc-avatar *ngIf="searchResult?.resultType === 'COMMENT'"
                            [imgSrc]="searchResult?.author?.avatar_url"></vnc-avatar>
              </ng-template>
            </div>
          </div>

        </ng-container>
      </div>
      <div class="category-mobile sticky-note-category-mobile" *ngIf="($allStickyNotesData | async)?.length > 0">
        <span class="sub-header-mobile">STICKY NOTE</span>
        <ng-container *ngFor="let note of ($allStickyNotesData | async)">
          <div class="smart-entity-wrapper">
            <div class="smart-object-search-result-wrapper" id="{{note?.uid}}" (swipeleft)="handleSwipeForEntity(note?.uid, swipe.LEFT)"
                 (swiperight)="handleSwipeForEntity(note?.uid, swipe.RIGHT)">
              <vnc-smart-link-search-result [resultType]="'STICKY_NOTE'"
                                            [showActionIconsOnHover]="data?.isEditable && data?.isMobileView"
                                            [resultData]="note"
                                            (actionClicked)="onStickyNoteHoverActionClick($event, note)"
                                            (click)="onStickyNoteHoverActionClick({clickEvent: $event, actionType: 'preview'}, note)">
              </vnc-smart-link-search-result>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="category-mobile reference-category-mobile" *ngIf="($allReferencesData | async).length > 0">
        <span class="sub-header-mobile">REFERENCES</span>
        <ng-container *ngFor="let reference of ($allReferencesData | async)">
          <div class="smart-entity-wrapper">
            <div class="smart-object-search-result-wrapper" id="{{reference?.uid}}" (swipeleft)="handleSwipeForEntity(reference?.uid, swipe.LEFT)"
              (swiperight)="handleSwipeForEntity(reference?.uid, swipe.RIGHT)">
              <vnc-smart-link-search-result [resultType]="'REFERENCE'"
                                            [showActionIconsOnHover]="data?.isEditable && data?.isMobileView"
                                            [resultData]="reference"
                                            (actionClicked)="onReferenceHoverActionClick($event, reference)"
                                            (click)="onReferenceHoverActionClick({clickEvent: $event, actionType: 'preview'}, reference)">
              </vnc-smart-link-search-result>
            </div>
          </div>
        </ng-container>
      </div>
    </div>


    <vnc-floating-button [iconTemplate]="showActions ? closeIcon : plusIcon" *ngIf="data?.isEditable" class="new-object-button" id="newConversationMobile"
                         (onClick)="$event.stopPropagation();showActions = !showActions" size="m" backgroundColor="blue600"></vnc-floating-button>

    <div *ngIf="data?.isEditable && showActions" class="mobile-action-backdrop" (click)="showActions = false">
      <ul class="smart-object-ul">
        <li (click)="$event.stopPropagation()" *ngIf="!data?.previewData?.highlightData || !data?.previewData?.highlightData?.name">
          <span class="border-lable">{{ 'HIGHLIGHT' | translate }}</span>
          <vnc-floating-button class="mobile-floating-action-button" size="s" [iconTemplate]="highlightIconTemplate"
           (onClick)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: smartObjectActions.ADD_HIGHLIGHT });showActions=false">
          </vnc-floating-button>
        </li>
        <li (click)="$event.stopPropagation()">
          <span class="border-lable">{{ 'STICKY_NOTE' | translate }}</span>
          <vnc-floating-button class="mobile-floating-action-button" size="s" [iconTemplate]="stickyNoteMobileIcon"
          (onClick)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: smartObjectActions.ADD_STICKY_NOTE });showActions=false">
          </vnc-floating-button>
        </li>
        <li (click)="$event.stopPropagation()">
          <span class="border-lable">{{ 'SMARTLINK' | translate }}</span>
          <vnc-floating-button class="mobile-floating-action-button" size="s" [iconTemplate]="smartlinkMobileIcon"
          (onClick)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: smartObjectActions.ADD_SMART_LINK });showActions=false">
          </vnc-floating-button>
        </li>
        <li (click)="$event.stopPropagation()">
          <span class="border-lable">{{ 'REFERENCE' | translate }}</span>
          <vnc-floating-button class="mobile-floating-action-button" size="s" [iconTemplate]="referenceIconTemplate"
          (onClick)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: smartObjectActions.ADD_REFERENCE });showActions=false">
          </vnc-floating-button>
        </li>
      </ul>
    </div>

  </ng-template>

  <ng-template #footerTemplate>
    <span></span>
  </ng-template>
</div>

<ng-template #smartLinkIconTemplate>
  <mat-icon svgIcon="smartlink"></mat-icon>
</ng-template>

<ng-template #stickyNoteIconTemplate>
  <mat-icon svgIcon="sticky-note"></mat-icon>
</ng-template>

<ng-template #highlightIconTemplate>
  <mat-icon svgIcon="highlight"></mat-icon>
</ng-template>

<ng-template #referenceIconTemplate>
  <mat-icon svgIcon="reference"></mat-icon>
</ng-template>

<mat-menu #smartObjectsMenu class="mat-menu-container" (close)="menuOpened = false">
  <ng-container [ngTemplateOutlet]="smartObjectsMenuOptionsTemplate"></ng-container>
</mat-menu>

<ng-template #smartObjectsMenuOptionsTemplate>
  <div class="item-drop-down">
    <vp-dropdown-item [itemType]="'icon-drop-default'"
                      [prefixIconTemplate]="smartLinkIconTemplate"
                      label="{{'ADD_SMARTLINK' | translate}}"
                      (onClickEvent)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: smartObjectActions.ADD_SMART_LINK })">
    </vp-dropdown-item>
  </div>
  <div class="item-drop-down">
    <vp-dropdown-item [itemType]="'icon-drop-default'"
                      [prefixIconTemplate]="stickyNoteIconTemplate"
                      label="{{'ADD_STICKY_NOTE' | translate}}"
                      (onClickEvent)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: smartObjectActions.ADD_STICKY_NOTE })">
    </vp-dropdown-item>
  </div>
  <div class="item-drop-down" *ngIf="!data?.previewData?.highlightData || !data?.previewData?.highlightData?.name">
    <vp-dropdown-item [itemType]="'icon-drop-default'"
                      [prefixIconTemplate]="highlightIconTemplate"
                      label="{{'ADD_HIGHLIGHT' | translate}}"
                      (onClickEvent)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: smartObjectActions.ADD_HIGHLIGHT })">
    </vp-dropdown-item>
  </div>
  <div class="item-drop-down">
    <vp-dropdown-item [itemType]="'icon-drop-default'"
                      [prefixIconTemplate]="referenceIconTemplate"
                      label="{{'ADD_REFERENCE' | translate}}"
                      (onClickEvent)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: smartObjectActions.ADD_REFERENCE })">
    </vp-dropdown-item>
  </div>
</ng-template>

<ng-template #defaultChannelAvatarTemplate>
  <img class="channel-default-avatar" [src]="defaultChannelAvatar" alt="">
</ng-template>


<ng-template #checkIconTemplate>
  <span (click)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: smartObjectActions.UPDATE })">
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <filter x="-9.6%" y="-13.1%" width="119.2%" height="126.1%" filterUnits="objectBoundingBox" id="filter-1">
          <feOffset dx="0" dy="8" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.031372549   0 0 0 0 0.176470588   0 0 0 0 0.239215686  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
          <feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter2"></feOffset>
          <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter2" result="shadowBlurOuter2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0311856736   0 0 0 0 0.177658465   0 0 0 0 0.240432518  0 0 0 0.1 0" type="matrix" in="shadowBlurOuter2" result="shadowMatrixOuter2"></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="shadowMatrixOuter2"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
        <filter x="-16.5%" y="-86.2%" width="133.0%" height="272.5%" filterUnits="objectBoundingBox" id="filter-2">
          <feOffset dx="0" dy="8" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.031372549   0 0 0 0 0.176470588   0 0 0 0 0.239215686  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
          <feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter2"></feOffset>
          <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter2" result="shadowBlurOuter2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0311856736   0 0 0 0 0.177658465   0 0 0 0 0.240432518  0 0 0 0.1 0" type="matrix" in="shadowBlurOuter2" result="shadowMatrixOuter2"></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="shadowMatrixOuter2"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
      </defs>
      <g id="Channels" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="0231.-Topic-type--Analytics-#04-17" transform="translate(-1499.000000, -2051.000000)" fill="#FFFFFF">
          <g id="Group-14" transform="translate(1125.000000, 1907.000000)">
            <g id="Group-9" filter="url(#filter-1)" transform="translate(0.000000, 24.000000)">
              <g id="Group-6" transform="translate(0.000000, 84.000000)">
                <g id="Group" transform="translate(64.000000, 8.000000)" filter="url(#filter-2)">
                  <g id="Group-2" transform="translate(16.000000, 8.000000)">
                    <g id="Button/primary-icon/@l/hover" transform="translate(282.000000, 8.000000)">
                      <g id="Group-2" transform="translate(12.000000, 12.000000)">
                        <g id="Icon/product/check" transform="translate(0.000000, 0.000000)">
                          <g id="Icon-24px" transform="translate(3.410000, 5.590000)">
                            <polygon id="Shape" points="5.59 10.58 1.42 6.41 0 7.82 5.59 13.41 17.59 1.41 16.18 0"></polygon>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </span>
</ng-template>

<ng-template #closeIconTemplate>
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter x="-9.6%" y="-13.1%" width="119.2%" height="126.1%" filterUnits="objectBoundingBox" id="a">
        <feOffset dy="8" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
        <feColorMatrix values="0 0 0 0 0.031372549 0 0 0 0 0.176470588 0 0 0 0 0.239215686 0 0 0 0.2 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
        <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter2"/>
        <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter2" result="shadowBlurOuter2"/>
        <feColorMatrix values="0 0 0 0 0.0311856736 0 0 0 0 0.177658465 0 0 0 0 0.240432518 0 0 0 0.1 0" in="shadowBlurOuter2" result="shadowMatrixOuter2"/>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"/>
          <feMergeNode in="shadowMatrixOuter2"/>
          <feMergeNode in="SourceGraphic"/>
        </feMerge>
      </filter>
      <filter x="-16.5%" y="-86.2%" width="133%" height="272.5%" filterUnits="objectBoundingBox" id="b">
        <feOffset dy="8" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
        <feColorMatrix values="0 0 0 0 0.031372549 0 0 0 0 0.176470588 0 0 0 0 0.239215686 0 0 0 0.2 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
        <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter2"/>
        <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter2" result="shadowBlurOuter2"/>
        <feColorMatrix values="0 0 0 0 0.0311856736 0 0 0 0 0.177658465 0 0 0 0 0.240432518 0 0 0 0.1 0" in="shadowBlurOuter2" result="shadowMatrixOuter2"/>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"/>
          <feMergeNode in="shadowMatrixOuter2"/>
          <feMergeNode in="SourceGraphic"/>
        </feMerge>
      </filter>
    </defs>
    <g transform="translate(-430 -120)" fill="#606B76" fill-rule="evenodd">
      <g transform="translate(64 92)" filter="url(#b)">
        <path d="M384.95 33.05a1 1 0 0 1 0 1.414L379.414 40l5.536 5.536a1 1 0 0 1-1.414 1.414L378 41.414l-5.536 5.536a1 1 0 1 1-1.414-1.414L376.586 40l-5.536-5.536a1 1 0 0 1 1.414-1.414L378 38.586l5.536-5.536a1 1 0 0 1 1.414 0z"/>
      </g>
    </g>
  </svg>
</ng-template>

<ng-template #zoomIconTemplate>
  <mat-icon class="icon-18 blue-hover" svgIcon="zoom_out"></mat-icon>
</ng-template>

<ng-template #deleteIconTemplate>
  <mat-icon class="icon-18 blue-hover" svgIcon="delete"></mat-icon>
</ng-template>

<ng-template #addIconTemplate>
  <mat-icon class="icon-18 blue-hover" svgIcon="add_icon"></mat-icon>
</ng-template>

<ng-template #kebabIconTemplate>
  <span (click)="smartObjectMenuOpen=!smartObjectMenuOpen" [matMenuTriggerFor]="smartMenu">
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" fill="#FFF" fill-rule="evenodd"/>
    </svg>
  </span>
</ng-template>

<ng-template #smartlinkMobileIcon>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter x="-26%" y="-26%" width="152.1%" height="152.1%" filterUnits="objectBoundingBox" id="a">
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
        <feColorMatrix values="0 0 0 0 0.027713168 0 0 0 0 0.114145307 0 0 0 0 0.200577446 0 0 0 0.2 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
        <feOffset in="SourceAlpha" result="shadowOffsetOuter2"/>
        <feGaussianBlur stdDeviation=".5" in="shadowOffsetOuter2" result="shadowBlurOuter2"/>
        <feColorMatrix values="0 0 0 0 0.027713168 0 0 0 0 0.114145307 0 0 0 0 0.200577446 0 0 0 0.2 0" in="shadowBlurOuter2" result="shadowMatrixOuter2"/>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"/>
          <feMergeNode in="shadowMatrixOuter2"/>
          <feMergeNode in="SourceGraphic"/>
        </feMerge>
      </filter>
    </defs>
    <g transform="translate(-12 -12)" fill="#FFF" fill-rule="evenodd">
      <path d="m25.016 14-2.017 2.016V16L16 16v17h14v-5.188l2-2V33a2 2 0 0 1-2 2H16a2 2 0 0 1-2-2V16a2 2 0 0 1 2-2h9.016zm3.497 5.094c.266 0 .492.11.68.328 1 1 1.5 2.172 1.5 3.515 0 1.344-.5 2.516-1.5 3.516l-3.516 3.563c-1.031.968-2.21 1.453-3.539 1.453-1.328 0-2.508-.485-3.539-1.453-.969-1.032-1.453-2.211-1.453-3.54 0-1.328.484-2.507 1.453-3.538l1.5-1.5a6.427 6.427 0 0 0 .375 2.437l-.469.469c-.593.625-.89 1.336-.89 2.133 0 .796.304 1.5.914 2.109.61.61 1.312.914 2.11.914.796 0 1.507-.297 2.132-.89l3.516-3.563c.593-.594.89-1.29.89-2.086 0-.797-.297-1.508-.89-2.133a1.09 1.09 0 0 1-.282-.726c0-.266.094-.493.282-.68.218-.219.46-.328.726-.328zM30.623 13c1.328 0 2.507.484 3.539 1.453.968 1.031 1.453 2.211 1.453 3.54 0 1.327-.485 2.507-1.453 3.538l-1.5 1.5a6.427 6.427 0 0 0-.375-2.437l.468-.469c.594-.625.891-1.336.891-2.133 0-.797-.305-1.5-.914-2.11-.61-.609-1.313-.913-2.11-.913-.796 0-1.507.297-2.132.89l-3.516 3.563c-.594.594-.89 1.289-.89 2.086 0 .797.296 1.508.89 2.133.188.218.281.46.281.726 0 .266-.101.5-.304.703a.966.966 0 0 1-.703.305.865.865 0 0 1-.68-.328c-1-1-1.5-2.172-1.5-3.516 0-1.343.5-2.515 1.5-3.515l3.515-3.563c1.032-.969 2.211-1.453 3.54-1.453z"/>
    </g>
  </svg>
</ng-template>

<ng-template #stickyNoteMobileIcon>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter x="-26%" y="-26%" width="152.1%" height="152.1%" filterUnits="objectBoundingBox" id="a">
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
        <feColorMatrix values="0 0 0 0 0.027713168 0 0 0 0 0.114145307 0 0 0 0 0.200577446 0 0 0 0.2 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
        <feOffset in="SourceAlpha" result="shadowOffsetOuter2"/>
        <feGaussianBlur stdDeviation=".5" in="shadowOffsetOuter2" result="shadowBlurOuter2"/>
        <feColorMatrix values="0 0 0 0 0.027713168 0 0 0 0 0.114145307 0 0 0 0 0.200577446 0 0 0 0.2 0" in="shadowBlurOuter2" result="shadowMatrixOuter2"/>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"/>
          <feMergeNode in="shadowMatrixOuter2"/>
          <feMergeNode in="SourceGraphic"/>
        </feMerge>
      </filter>
    </defs>
    <g transform="translate(-12 -12)" fill="#FFF" fill-rule="evenodd">
      <path d="M29 15a3 3 0 0 1 2.995 2.824L32 18v12a3 3 0 0 1-2.824 2.995L29 33h-6.362l-5.559-4.632a3 3 0 0 1-1.072-2.1L16 26.063V18a3 3 0 0 1 2.824-2.995L19 15h10zm-5.005 12.824L24 28v3h5a1 1 0 0 0 .974-.77l.02-.113L30 30V18a1 1 0 0 0-1-1H19a1 1 0 0 0-.993.883L18 18v7h3a3 3 0 0 1 2.995 2.824zM22 29.864V28a1 1 0 0 0-.883-.993L21 27h-2.438L22 29.864z"/>
    </g>
  </svg>
</ng-template>

<ng-template #plusIcon>
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter x="-198.2%" y="-198.2%" width="496.4%" height="496.4%" filterUnits="objectBoundingBox" id="a">
        <feOffset dy="16" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur stdDeviation="12" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
        <feColorMatrix values="0 0 0 0 0.027713168 0 0 0 0 0.114145307 0 0 0 0 0.200577446 0 0 0 0.2 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
        <feOffset dy="12" in="SourceAlpha" result="shadowOffsetOuter2"/>
        <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter2" result="shadowBlurOuter2"/>
        <feColorMatrix values="0 0 0 0 0.027713168 0 0 0 0 0.114145307 0 0 0 0 0.200577446 0 0 0 0.1 0" in="shadowBlurOuter2" result="shadowMatrixOuter2"/>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"/>
          <feMergeNode in="shadowMatrixOuter2"/>
          <feMergeNode in="SourceGraphic"/>
        </feMerge>
      </filter>
    </defs>
    <g transform="translate(-16 -16)" fill="#FFF" fill-rule="evenodd">
      <path d="M35 29h-6v6h-2v-6h-6v-2h6v-6h2v6h6z"/>
    </g>
  </svg>
</ng-template>

<ng-template #closeIcon>
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter x="-198.2%" y="-198.2%" width="496.4%" height="496.4%" filterUnits="objectBoundingBox" id="a">
        <feOffset dy="16" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur stdDeviation="12" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
        <feColorMatrix values="0 0 0 0 0.027713168 0 0 0 0 0.114145307 0 0 0 0 0.200577446 0 0 0 0.2 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
        <feOffset dy="12" in="SourceAlpha" result="shadowOffsetOuter2"/>
        <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter2" result="shadowBlurOuter2"/>
        <feColorMatrix values="0 0 0 0 0.027713168 0 0 0 0 0.114145307 0 0 0 0 0.200577446 0 0 0 0.1 0" in="shadowBlurOuter2" result="shadowMatrixOuter2"/>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"/>
          <feMergeNode in="shadowMatrixOuter2"/>
          <feMergeNode in="SourceGraphic"/>
        </feMerge>
      </filter>
    </defs>
    <g transform="rotate(90 28 12)" fill="#FFF" fill-rule="evenodd">
      <path d="M35 22.41 33.59 21 28 26.59 22.41 21 21 22.41 26.59 28 21 33.59 22.41 35 28 29.41 33.59 35 35 33.59 29.41 28z"/>
    </g>
  </svg>
</ng-template>

<ng-template #editIconTemplate>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" fill="#FFF" fill-rule="evenodd"/>
  </svg>
</ng-template>


<ng-template #mobileSmartObjectMenuTemplate>

  <div class="item-drop-down">
    <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: smartObjectActions.DELETE })" [prefixIconTemplate]="deleteSmartObjectTemplate" label="{{'DELETE_ALL_SMART_OBJECT' | translate }}">
    </vp-dropdown-item>
  </div>

</ng-template>

<ng-template #deleteSmartObjectTemplate>

  <svg width="24" height="25" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter x="-19.2%" y="-98.9%" width="138.3%" height="297.7%" filterUnits="objectBoundingBox" id="a">
        <feOffset dy="8" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
        <feColorMatrix values="0 0 0 0 0.031372549 0 0 0 0 0.176470588 0 0 0 0 0.239215686 0 0 0 0.2 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
        <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter2"/>
        <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter2" result="shadowBlurOuter2"/>
        <feColorMatrix values="0 0 0 0 0.0311856736 0 0 0 0 0.177658465 0 0 0 0 0.240432518 0 0 0 0.1 0" in="shadowBlurOuter2" result="shadowMatrixOuter2"/>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"/>
          <feMergeNode in="shadowMatrixOuter2"/>
          <feMergeNode in="SourceGraphic"/>
        </feMerge>
      </filter>
    </defs>
    <g transform="translate(-16 -23)" fill="#8B96A0" fill-rule="evenodd">
      <path d="M34 30.798v11.286c0 1.489-1.191 2.714-2.667 2.714h-6.666c-1.476 0-2.667-1.225-2.667-2.714V30.798h12zm-2 2h-8v9.286c0 .363.26.66.578.707l.089.007h6.666c.358 0 .667-.318.667-.714v-9.286zm-3-6a2 2 0 0 1 1.733 1H34a1 1 0 0 1 0 2H22a1 1 0 1 1 0-2h3.268a2 2 0 0 1 1.732-1h2z"/>
    </g>
  </svg>

</ng-template>


<mat-menu (closed)="smartObjectMenuOpen=false" class="mobile-header-mat-menu" #smartMenu="matMenu" [backdropClass]="'vnc-component-backdrop-menu-overlay'">
  <ng-container
    *ngIf="mobileSmartObjectMenuTemplate"
    [ngTemplateOutlet]="mobileSmartObjectMenuTemplate">
  </ng-container>
</mat-menu>
