
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Message, MessageStatus } from "../models/message.model";
import { JID } from "../models/jid.model";
import { SearchMessage } from "../models/search-message.model";
import { environment } from "../../environments/environment";
import { ElectronService } from "app/shared/providers/electron.service";
import { CommonUtil } from "./common.util";
import { DateUtil } from "../utils/date.util";
import { ConversationRepository } from "../repositories/conversation.repository";
import { filter, take } from "rxjs";
import { Store } from "@ngrx/store";
import { TalkRootState } from "../reducers";
import { FilesStorageService } from "../services/files-storage.service";
import formatDistance from "date-fns/formatDistance";

const ALLOWED_PREVIEW_EXT = ["html", "htm", "php", "asp", "aspx", "jsp"];

//
const VS_ORIGINAL_MESSAGE_ADDITION = 12;
const VS_REACTION_HEIGHT = 27;
//
const VS_DELETED_MESSAGE_HEIGHT = 43;
//
const VS_LINK_PREVIEW_HEIGHT = 190 + 20; // 210 is height, 20 are paddings
const VS_REDMINE_PREVIEW_HEIGHT = 300;
const VS_REDMINE_VERSION_PREVIEW_HEIGHT = 245;

//
const VS_CHANNEL_TOPIC = 273;
const VS_CHANNEL_CHANNEL = 182;
const VS_CHANNEL_COMMENT = 243;
const VS_CHANNEL_SOCIAL = 400; // TODO
//

// TODO: calls system mesasge may have dinamic height
const VS_MESSAGE_GROUP_ACTION = 46 + 5 + 5 + 1 + 1; // 10 + 10 this is padding in .call-status. 1 + 1 is a border radius
const VS_MESSAGE_GROUP_ACTION_2_LINES = 70 + 5 + 5 + 1 + 1;
const VS_MESSAGE_CALL_SIGNAL = 105;
const VS_MESSAGE_CALL_SIGNAL_2_LINES = 89 + 5 + 5 + 1 + 1;
const VS_MESSAGE_CALL_SIGNAL_3_LINES = 113 + 5 + 5 + 1 + 1;


enum ChannelEntityType {
  CHANNEL = "channels",
  TOPIC = "topics",
  COMMENT = "commentId",
  SOCIAL_TOPIC = "socialTopic",
  SOCIAL = "social"
}

export class MessageUtil {

  static CATCH_MESSAGES_SIZE = {};

  // VS pieces
  //
  static measureTextHeights(
    clientWidth: number,
    messages: Message[], isGroupChat: boolean,
    jitsiURL: string, redmineUrl: string,
    callHistory: any, unreadids: string[],
    userJID: any,
    fontSize: string) {

    // const fontSize = Math.ceil(14 * 1.14);  // 14   .font-size-14 { font-size: 14px;}   // 1.14rem
    const fontSizeNumber = +fontSize.split("-")[1];
    const lineHeight = 1.25;
    // console.log("[measureTextHeights][fontSizeNumber]", fontSizeNumber);
    const isHinTheme = localStorage.getItem("theme") === "hin";

    const mapped = [];
    messages.forEach((m, idx) => {
      mapped.push(this.measureMessageTextHeight(m, idx, clientWidth, fontSizeNumber, lineHeight, jitsiURL, redmineUrl, messages, isGroupChat, callHistory, unreadids, isHinTheme, userJID));
    });

    // const mapped = messages.map(m => this.measureMessageTextHeight(m, idx, clientWidth, fontSizeNumber, lineHeight, jitsiURL, redmineUrl));

    const sum = mapped.reduce((partialSum, a) => partialSum + a, 0);

    return [mapped, sum];
  }

  static measureMessageTextHeight(
          message: Message, idx: number,
          clientWidth: number, fontSize: number, lineHeight: number,
          jitsiURL: string, redmineUrl: string,
          messages: Message[], isGroupChat: boolean, callHistory: any, unreadids: string[],
          isHinTheme: boolean,
          userJID: any): number {

    const cachedData = this.getMessageTextHeightFromCache(message.id);
    if (cachedData) {
      const {
        testHeight,
        isDifferentSenderThanLastMessage
      } = cachedData;
      message.isDifferentDayThanLastMessage = this.isDifferentDayThanLastMessage(idx, messages);
      message.isDifferentSenderThanLastMessage = !!isDifferentSenderThanLastMessage;
      message.highlighted = this.isHighlighted(idx, message, unreadids);
      return testHeight;
    }

    message.highlighted = this.isHighlighted(idx, message, unreadids);

    // [x] attachments
    // [x] texts
    // [x] reply
    // [x] forward
    // [x] delete
    // [x] link preview
    // [x] redmine preview
    // [x] system messages
    // [+-] call notifications

    let isUrlOrRedminePreview = false;

    let msgHeight = 0;

    message.isDifferentSenderThanLastMessage = this.isDifferentSenderThanLastMessage(idx, messages);

    if (message.isDifferentSenderThanLastMessage) {
      msgHeight = msgHeight + 72;  // vp-chat-window .chat-message.is-first-message .vnctalk-message:not(.private-chat) {margin-bottom: 10px;}
    }


    // deleted
    if (message.isDeleted) {
      msgHeight = VS_DELETED_MESSAGE_HEIGHT;

    // system messages
    } else if (message.group_action) {
      if (message.group_action.type === "ADD_PARTICIPANTS") {
        msgHeight = VS_MESSAGE_GROUP_ACTION_2_LINES;
      } else {
        msgHeight = VS_MESSAGE_GROUP_ACTION;
      }

    // call history
    } else if (message.vncTalkConference) {
      const ch = callHistory[message.id];
      if (ch?.length >= 2) {
        if (ch.find(c => c.body === "USER_HAS_NOT_ANSWERED")) {
          msgHeight = VS_MESSAGE_CALL_SIGNAL;
        } else {
          msgHeight = VS_MESSAGE_CALL_SIGNAL;
        }
      } else {
        msgHeight = VS_MESSAGE_CALL_SIGNAL;
      }

    } else {
      // attachments
      let testHeight = 0;

      if (message.attachment && message.attachment.fileName
        && message.attachment.url && message.attachment.url.startsWith("http")) {
        const isImage = CommonUtil.isImage(message.attachment.fileType);
        if (isImage) {
          msgHeight = 200;
        } else {
          const isAudio = CommonUtil.isAudio(message.attachment.fileType);
          if (isAudio) {
            msgHeight = 54;
          } else {
            const isSupportedVideo = CommonUtil.isSupportedVideo(message.attachment.fileType);
            if (isSupportedVideo) {
              msgHeight = 120;
            } else {
              msgHeight = 60; //file
            }
          }
        }
      // text
      } else {
        const isMyMessage = this._isMyMessage(userJID, message.fromJid);

        let maxWidthFinal = clientWidth;

        // MOBILE
        if (CommonUtil.isMobileSize()) {

          if (isMyMessage) {
            maxWidthFinal -= 10; // .vnctalk-message.me {padding-right: 10px;}
            if (isGroupChat) {
              maxWidthFinal -= 44; // .vnctalk-message .message-content-container .message-content.me {margin-left: 44px;}
              maxWidthFinal -= 6;  // @media screen and (max-width: 767px).vnctalk-message .message-content-container .message-content.me {margin-right: 6px;}
            } else {
              maxWidthFinal -= 36; // @media screen and (max-width: 768px).vnctalk-message .message-content-container .message-content.me.is-single-chat {margin-left: 36px;}
            }
            maxWidthFinal -= (1 * 2);  // .vnctalk-message .message-content-container .message-content.me { border: solid 1px #dadee2; }
            maxWidthFinal -= (7 * 2); //@media screen and (max-width: 767px).vnctalk-message .margin0 {padding: 7px;}
          } else {
            maxWidthFinal -= 10; // .vnctalk-message { padding-right: 10px; }
            if (isGroupChat) {
              maxWidthFinal -= 32; // avatar
              maxWidthFinal -= 10; // @media screen and (max-width: 767px).vnctalk-message .my-avatar.groupchat { margin-left: 10px; }
              maxWidthFinal -= 10; // .vnctalk-message .message-content-container .message-content { margin-left: 10px; }
              maxWidthFinal -= 24; // .vnctalk-message .message-content-container .message-content:not(.me) {margin-right: 24px;}
            } else {
              maxWidthFinal -= (26 + 10); // @media screen and (max-width: 767px).vnctalk-message .message-content-container .message-content.is-single-chat:not(.me) {margin-right: 26px;margin-left: 10px;}
            }
            maxWidthFinal -= (1 * 2);  // .vnctalk-message .message-content-container .message-content.me { border: solid 1px #dadee2; }
            maxWidthFinal -= (7 * 2); //@media screen and (max-width: 767px).vnctalk-message .margin0 {padding: 7px;}
          }

        // DESKTOP
        } else {
          maxWidthFinal = Math.ceil((maxWidthFinal * 80) / 100); // vp-chat-window .chat-window-wrapper .chat-window .chat-list > cdk-virtual-scroll-viewport > div .vnctalk-message { max-width: 80%; }

          if (isMyMessage) {
            maxWidthFinal -= 12; // .vnctalk-message.me { padding-right: 12px; }
            if (isGroupChat) {
              maxWidthFinal -= 44; // .vnctalk-message .message-content-container .message-content.me { margin-left: 44px; }
            } else {
              maxWidthFinal -= 24; // .vnctalk-message .message-content-container .message-content.me.is-single-chat { margin-left: 24px; }
            }
            maxWidthFinal -= 2;  // .vnctalk-message .message-content-container .message-content.me {border: solid 1px #dadee2;}
            maxWidthFinal -= (23 + 8); // .vnctalk-message .margin0 { padding-right: 23px; padding-left: 8px;}
          } else {
            maxWidthFinal -= 10; // .vnctalk-message {padding-right: 10px;}
            maxWidthFinal -= 24; // .vnctalk-message .message-content-container .message-content:not(.me) { margin-right: 24px;}
            if (isGroupChat) {
              if (message.isDifferentSenderThanLastMessage) {
                maxWidthFinal -= 42; // avatar
              } else {
                maxWidthFinal -= 44; // element.style {padding-left: 44px;}
              }
            }
            maxWidthFinal -= 10; // .vnctalk-message .message-content-container .message-content { margin-left: 10px;}
            maxWidthFinal -= 2;  // .vnctalk-message .message-content-container .message-content { border: solid 1px #dadee2; }
            maxWidthFinal -= (23 + 8); // .vnctalk-message .margin0 { padding-right: 23px; padding-left: 8px;}
          }
        }
        testHeight = this.measureText(message.cachedContent, maxWidthFinal, fontSize, lineHeight, isHinTheme, message);
        msgHeight = testHeight;
        if (message.reactions) {
          msgHeight += VS_REACTION_HEIGHT;
        }
        if (!message.originalMessage) {
          const [fullUrl, urlsCount] = this.extractUrlFromMessageBodyAndCountUrls(message.cachedContent, jitsiURL);
          if (fullUrl && !message.forwardMessage) {
            // console.log("measureMessageTextHeight forward fullUrl", testHeight, msgHeight);
            const channelEntityHeight = this.getChannelEntityHeight(fullUrl);
            if (channelEntityHeight > 0) {
              msgHeight += channelEntityHeight;
            } else {
              const { isRedmineUrl, isVersionUrl } = this.getLinkType(fullUrl, urlsCount, redmineUrl);
              const shouldUnfurlURL = this.shouldUnfurlURL(isRedmineUrl ? fullUrl.split("\n")[0] : fullUrl);
              if (shouldUnfurlURL) {
                isUrlOrRedminePreview = true;
                if (isRedmineUrl) {
                  msgHeight += (CommonUtil.isMobileSize() ? VS_REDMINE_PREVIEW_HEIGHT + 90 : VS_REDMINE_PREVIEW_HEIGHT);
                } else if (isVersionUrl) {
                  msgHeight += VS_REDMINE_VERSION_PREVIEW_HEIGHT;
                } else {
                  msgHeight += VS_LINK_PREVIEW_HEIGHT;
                }
              }
            }
          }
        } else if (!!message.originalMessage?.replyMessage) {
          const originalHeight = this.measureText( CommonUtil.linkify( CommonUtil.processSpecialChars( CommonUtil.decodeHTML(message.originalMessage.body))), maxWidthFinal + 5, fontSize, lineHeight, isHinTheme, message);
          const replyHeight = this.measureText(message.originalMessage.replyMessage, maxWidthFinal, fontSize, lineHeight, isHinTheme, message);
          msgHeight = originalHeight + replyHeight + 20;
          const [fullUrl, urlsCount] = this.extractUrlFromMessageBodyAndCountUrls(!!message.originalMessage?.replyMessage ? message.originalMessage.replyMessage : message.body, jitsiURL);
          if (fullUrl) {
            const channelEntityHeight = this.getChannelEntityHeight(fullUrl);
            if (channelEntityHeight > 0) {
              msgHeight += channelEntityHeight;
            } else {
              const { isRedmineUrl, isVersionUrl } = this.getLinkType(fullUrl, urlsCount, redmineUrl);
              const shouldUnfurlURL = this.shouldUnfurlURL(isRedmineUrl ? fullUrl.split("\n")[0] : fullUrl);
              if (shouldUnfurlURL) {
                isUrlOrRedminePreview = true;
                if (isRedmineUrl) {
                  msgHeight += (CommonUtil.isMobileSize() ? VS_REDMINE_PREVIEW_HEIGHT : VS_REDMINE_PREVIEW_HEIGHT - 60);
                } else if (isVersionUrl) {
                  msgHeight += VS_REDMINE_VERSION_PREVIEW_HEIGHT;
                } else {
                  msgHeight += VS_LINK_PREVIEW_HEIGHT;
                }
              }
            }
          }
        }

      }

      // reply
      if (message.originalMessage) {
        msgHeight += VS_ORIGINAL_MESSAGE_ADDITION;
      }

      if (this._isMyMessage(userJID, message.fromJid)) {
        msgHeight = msgHeight + 3;
        // if (message.id.indexOf("isz8forooj") > -1) {
        //  console.log("CustomVirtualScrollStrategy debug isz8forooj isMyMessage ", msgHeight); //355
        // }
      }

      // console.log("measureMessageTextHeight before and after", message.id, testHeight, msgHeight);
      // forward
      // <div class="forwared-message">
      if (message.forwardMessage) {
        // height +
        // .vnctalk-message .message-content-container .message-content .forwared-message {margin-bottom: 10px;}
        msgHeight += (17 + 10);
      }

      // <div class="vnctalk-message">
      // msgHeight = msgHeight + 2 + 2; // .vnctalk-message {padding-top: 2px; padding-bottom: 2px;}
      // if (CommonUtil.isMobileSize()) {
      //   msgHeight = msgHeight + 2; // @media screen and (max-width: 767px).vnctalk-message {margin-top: 2px;}
      // }

      // <div class="message-content">
      msgHeight = msgHeight + 1 + 1; // .vnctalk-message .message-content-container .message-content {border: solid 1px #dadee2; }
      msgHeight = msgHeight + 5; // .vnctalk-message .message-content-container .message-content {    margin-bottom: 5px;}

      // // <div class="margin0">
      // msgHeight = msgHeight + 7 + 7; // @media screen and (max-width: 767px).vnctalk-message .margin0 {padding: 7px;} OR  .vnctalk-message .margin0 { padding: 7px 23px 7px 8px; }

      // mesasge text
      // ...

      if (isUrlOrRedminePreview) {
        msgHeight = msgHeight + 2; //. vnctalk-message .message-content-container .message-content .url-preview-metadata { border-top: 1px solid #dadada; }
        msgHeight = msgHeight + 5 + 5; // .vnctalk-message .message-content-container .message-content .message-time-status.web-preview-metadata {padding: 5px 0;}
      }

      // <div class="message-time-status">
      msgHeight = msgHeight + 24 + 3; //  17 + .vnctalk-message .message-content-container .message-content .message-time-status {margin-top: 3px; }
    }
    msgHeight = msgHeight + 14; // padding top and bottom
    // <h6 class="conversation-date">
    message.isDifferentDayThanLastMessage = this.isDifferentDayThanLastMessage(idx, messages);
    if (message.isDifferentDayThanLastMessage) {
      msgHeight += (15 + 5 + 14);
    }
    this.cacheMessageTextHeight(message.id, msgHeight, message.isDifferentSenderThanLastMessage, message.isDifferentDayThanLastMessage);

    return msgHeight;
  }

  static _isMyMessage(userJID: any, fromJid: string) {
    const isM = userJID && fromJid === userJID.bare;
    return isM;
  }

  static measureText(text: string, maxWidth: number, fontSize: number, lineHeight: number, isHinTheme: boolean, message) {
    const fontFamily = isHinTheme ? "Lato" : "Source Sans Pro";
    if (!!text && /^:[a-zA-Z0-9-_+]+:$/g.test(text)) { // Big emoji
      fontSize = 32;
    }
    // text.replace(/<p/g, "<p style='margin: 0;font-family: 'Source Sans Pro';font-size: 16px;'");
    // text.replace(/<h1/g, "<h1 style='font-size: 24px;margin: 5px 0;font-weight: bold;'");
    // text.replace(/<h2/g, "<h2 style='font-size: 20px;margin: 5px 0;font-weight: bold;'");
    // text.replace(/<h3/g, "<h3 style='font-size: 18px;margin: 5px 0;font-weight: bold;'");
    // text.replace(/<h4/g, "<h4 style='font-size: 16px;margin: 5px 0;font-weight: bold;'");
    // text.replace(/<h5/g, "<h5 style='font-size: 16px;margin: 5px 0;font-weight: bold;'");
    // text.replace(/<h6/g, "<h6 style='font-size: 16px;margin: 5px 0;font-weight: bold;'");
    let mainStyle = `width:${maxWidth + 50}px; word-wrap: break-word; white-space: pre-line; font-size: ${fontSize}px; font-family: "${fontFamily}"; line-height: ${lineHeight}`;
     if (CommonUtil.isMobileSize()) {
       mainStyle = `width:auto; word-wrap: break-word; white-space: pre-line; font-size: ${fontSize}px; font-family: "${fontFamily}"; line-height: ${lineHeight}`;
    }
    const div = document.createElement("div");
    const messageContentContainer = document.createElement("div");
    const messageContent = document.createElement("div");
    const messageText = document.createElement("div");
    div.className = "vnctalk-message";
    messageContentContainer.className = "message-content-container";
    messageContent.className = "message-content";
    if (message.type !== "groupchat") {
      messageContent.className = messageContent.className + " is-single-chat";
      div.className = div.className + " private-chat";
    }
    if (localStorage.getItem("userJID") === message.fromJid) {
      div.className = div.className + " me";
      messageContent.className = messageContent.className + " me";
    }
    // eslint-disable-next-line no-console
    console.log("[measureText]", message);
    messageText.className = "text font-" + fontSize;
    messageText.innerHTML = text;
    messageContent.style.marginBottom = "0";
    messageContent.appendChild(messageText);
    messageContentContainer.appendChild(messageContent);
    div.appendChild(messageContentContainer);
    div.style.cssText = mainStyle;
    document.body.appendChild(div);
    let testHeight = div.offsetHeight;
    // if (CommonUtil.isMobileSize()) {
    //   testHeight += 10;
    // }
    // const totalLineBreaks = text.match(/<br>/g) !== null ? text.match(/<br>/g).length : 0;
    // testHeight = testHeight + totalLineBreaks * 6;
    div.remove();
    // eslint-disable-next-line no-console
    // console.log("measureText testHeight", text, testHeight);
    return testHeight;
  }

  //
  // cache

  static cacheMessageTextHeight(mid: string, testHeight: number, isDifferentSenderThanLastMessage: boolean, isDifferentDayThanLastMessage: boolean) {
    this.CATCH_MESSAGES_SIZE[mid] = {
      testHeight,
      isDifferentSenderThanLastMessage,
      isDifferentDayThanLastMessage
    };
  }

  static getMessageTextHeightFromCache(mid: string): any {
    const cacheMsg = this.CATCH_MESSAGES_SIZE[mid];
    return cacheMsg || null;
  }

  static deleteMessageTextHeightFromCache(mid: string) {
    delete this.CATCH_MESSAGES_SIZE[mid];
  }

  static timerChangeWindowSize: any;
  static resetCacheOnWindowSizeChange() {
    if (this.timerChangeWindowSize) {
      clearInterval(this.timerChangeWindowSize);
    }
    this.timerChangeWindowSize = setTimeout(() => {
      this.CATCH_MESSAGES_SIZE = {};


      this.timerChangeWindowSize = null;
    }, 1000);
  }

  //
  //

  static getChannelEntityHeight(firstUrl: string) {
    let res = 0;

    if (firstUrl.includes(ChannelEntityType.COMMENT) && firstUrl.includes(ChannelEntityType.TOPIC) && firstUrl.includes(ChannelEntityType.CHANNEL)) {
      // comment
      res = VS_CHANNEL_COMMENT;
    } else if (firstUrl.includes(ChannelEntityType.TOPIC) && firstUrl.includes(ChannelEntityType.CHANNEL)) {
      // topic
      res = VS_CHANNEL_TOPIC;
    } else if (firstUrl.includes(ChannelEntityType.SOCIAL)) {
      // social post
      res = VS_CHANNEL_SOCIAL;
    } else if (firstUrl.includes(ChannelEntityType.CHANNEL)) {
      if (firstUrl.includes(ChannelEntityType.SOCIAL_TOPIC)) {
        if (firstUrl.includes("/socialTopic/")) {
          // topic
          res = VS_CHANNEL_TOPIC;
        } else {
          // channel
          res = VS_CHANNEL_CHANNEL;
        }
      } else {
        //channel
        res = VS_CHANNEL_CHANNEL;
      }
    }
    return res;
  }

  static isHighlighted(msgIndex: number, msg: Message, unreadids: string[]) {
    const res = typeof msgIndex !== "undefined" ? (unreadids.length === 1 ? unreadids.includes(msg.id) : msgIndex < unreadids.length) : false;
    return res;
  }

  static isDifferentSenderThanLastMessage(currentMessageIndex: number, messages: Message[]) {

    const currentMessage = messages[currentMessageIndex];
    const messageAbove = messages[currentMessageIndex + 1];

    // TODO:
    // but, a 'messageAbove' can be from a diff user after got more, so what to do?
    // how to remove this flag form a prev message?
    if (currentMessageIndex === messages.length - 1 || !messageAbove) {
      return true;
    }

    if (!currentMessage) {
      return false;
    }

    const messageAboveIsCallInfo = messageAbove && (messageAbove.vncTalkConference || messageAbove.group_action);
    const currentMessageIsCallInfo = currentMessage && (currentMessage.vncTalkConference || currentMessage.group_action);

    const currentJid = currentMessage && currentMessage.fromJid;
    const jidAbove = messageAbove && messageAbove.fromJid;

    const res = (messageAboveIsCallInfo && !currentMessageIsCallInfo)
            || currentJid !== jidAbove
            || (!currentMessage.isDeleted && messageAbove.isDeleted);


    return res;
  }

  static isDifferentDayThanLastMessage(currentMessageIndex: number, messages: Message[]) {
    let res: boolean;

    // last
    if (currentMessageIndex === messages.length - 1) {
      res = false;
    } else {
      if (!messages[currentMessageIndex] || !messages[currentMessageIndex + 1]) {
        res = false;
      } else {
        res = !DateUtil.isSameDay(messages[currentMessageIndex + 1].timestamp, messages[currentMessageIndex].timestamp);
      }
    }


    return res;
  }

  static extractUrlFromMessageBodyAndCountUrls(body: string, jitsiURL: string) {
    let matches: any;
    let fullUrl: string;
    try {
      matches = body.match(/\bhttps?:\/\/\S+/gi); // complete body is url, followed by spaces maybe
      if (matches) {
        fullUrl = matches[0];
        if (!fullUrl.startsWith("http")) {
          fullUrl = `https://${fullUrl}`;
        }

        // skip call invites
        if (fullUrl?.includes("vnctalk-jitsi-meet") || fullUrl?.includes(jitsiURL)) {
          fullUrl = null;
        }
      }
    } catch (ex) {

    }


    return [fullUrl, matches?.length || 0];
  }

  static shouldUnfurlURL(url: string): boolean {
    let should = true;
    if (!environment.enablePreviewURL) {
      should = false;
    } else {
      const splitPath = url.split("/").filter((val) => { return val.length > 0; });
      if (splitPath.length > 2) {
        const fileName = url.split("/").pop().split("?")[0];
        const extension = fileName.toLowerCase().split(".").pop();
        if (fileName && fileName.split(".").length > 1 && !ALLOWED_PREVIEW_EXT.includes(extension)) {
          should = false;
        }
      }
    }


    return should;
  }

  static getLinkType(fullUrl: string, urlsCount: number, redmineUrl: string) {
    const res = {
      isImage: false,
      isAudio: false,
      isSupportedVideo: false,
      fileExtension: null,
      isRedmineUrl: false,
      isUrlPreview: false,
      isVersionUrl: false,
    };

    // if send multiple links in a single message - e.g. one with video and another one just a link, then we need to reset it all and set isUrlPreview=true only
    if (urlsCount === 1) {
      let fullUrlLowercase = fullUrl.toLowerCase();
      if (res.fileExtension = CommonUtil.imagesExtensions.find(ext => fullUrlLowercase.endsWith("." + ext))) {
        res.isImage = true;
      } else if (res.fileExtension = CommonUtil.audioExtensions.find(ext => fullUrlLowercase.endsWith("." + ext))) {
        res.isAudio = true;
      } else if (res.fileExtension = CommonUtil.supportedVideoExtensions.find(ext => fullUrlLowercase.endsWith("." + ext))) {
        res.isSupportedVideo = true;
      }
    }

    res.isRedmineUrl = this.checkIsRedmineUrl(fullUrl, redmineUrl);
    res.isVersionUrl = this.checkIsRedmineVersionUrl(fullUrl, redmineUrl);

    res.isUrlPreview = !res.isImage && !res.isAudio && !res.isSupportedVideo && !res.isRedmineUrl;


    return res;
  }

  static checkIsRedmineUrl(url: string, redmineUrl: string): boolean {
    return url.startsWith(redmineUrl + "/issues/") && !url.endsWith("/edit");
  }

  static checkIsRedmineVersionUrl(url: string, redmineUrl: string): boolean {
    return url.startsWith(redmineUrl + "/versions/") && !url.endsWith("/edit");
  }

  static isReceived(message: Message, loggedInJid: JID) {
    return message.from.bare === loggedInJid.bare;
  }

  static hasBody(message: Message) {
    return (message.body && message.body.trim()) || message.attachment;
  }

  static isValidMessage(message: Message): boolean {
    if (message.attachment) {
      return true;
    }

    if (message.type && message.type === "normal" && !message.vncTalkBroadcast) {
      return false;
    }

    if (message.vncTalkConference || message.notification) {
      return false;
    }

    if (message.vncTalkMuc) {
      return false;
    }

    if (message.startFile) {
      return false;
    }

    if (message.body && message.body.trim()) {
      return true;
    }

    return false;
  }

  static isInviteToCall(message: Message): boolean {
    if (message.vncTalkConference && message.vncTalkConference.eventType === "invite") {
      return true;
    }

    return false;
  }

  static isValidMessageFromServerHistory(message: Message): boolean {
    if (message.type && message.type === "normal") {
      return false;
    }


    if (message.vncTalkMuc || message.notification) {
      return false;
    }

    if (message.startFile) {
      return false;
    }

    if (message.body && message.body.trim()) {
      return true;
    }

    if (message.attachment) {
      return true;
    }
    return false;
  }

  static isFavouriteMessage(message: SearchMessage): boolean {
    if (!message.tags) {
      return false;
    }

    return message.tags.indexOf("*") !== -1;
  }

  static getTimeAgo(message: Message | SearchMessage) {
    return formatDistance(new Date(message.timestamp), new Date(), { addSuffix: true });
  }


  static convertMessageToFromStringToObject(to, resource?: string) {
    if (typeof to === "object") {
      return to;
    }

    to = to || "";
    return {
      local: to.split("@")[0],
      domain: to.split("@")[1],
      resource: resource ? resource : "some_random_res",
      bare: to,
      full: to,
      unescapedBare: to,
      unescapedFull: to,
      unescapedLocal: to.split("@")[0]
    };
  }

  static convertFromObject(from, room = undefined) {
    const randomResource = "some_random_res";

    if (room) {
      return {
        local: room.split("@")[0],
        domain: room.split("@")[1],
        full: room + "/" + from,
        resource: from,
        bare: room,
      };
    } else {
      return {
        local: from.split("@")[0],
        domain: from.split("@")[1],
        full: from + "/" + randomResource,
        resource: randomResource,
        bare: from,
      };
    }
  }

  static convertToXmppMessage(message: SearchMessage): Message {

    let htmlBody = message.htmlbody;
    const text = /<body xmlns=\"http:\/\/www.w3.org\/1999\/xhtml\">(.*)<\/body>/ig.exec(htmlBody);
    if (!!text && text[1]) {
      htmlBody = text[1];
    }
    let mStatus = MessageStatus.SENT;
    if (!!message.receipts) {
      mStatus = MessageStatus.DELIVERED;
    }

    message.id = message.id.replace(/.*_/ig, "");

    const response = {
      fromJid: message.from,
      body: message.body,
      htmlBody: htmlBody,
      timestamp: message.timestamp,
      file: null,
      isForwarded: false,
      isStarred: message.isStarred,
      status: mStatus,
      requestReceipt: true,
      id: message.id,
      tags: message.tags,
      to: this.convertMessageToFromStringToObject(message.to),
    };


    if (!!message.isStarred) {
      let tags = ["*"];
      if (!!response.tags && (response.tags.length > 0)) {
        response.tags.push("*");
      } else {
        response.tags = tags;
      }
      response["isStarred"] = true;
    }


    if (message.x_attachment) {
      response["attachment"] = JSON.parse(message.x_attachment);

      // check if this is an attach reply
      if (response["attachment"]["$t"]) {
        response["attachment"] = JSON.parse(response["attachment"]["$t"]);
      }
    }

    if (message.broadcast) {
      response["broadcast"] = message.broadcast;
    }
    if (message.expiry) {
      response["expiry"] = +message.expiry;
    }

    if (message.mention && message.mention !== "[]") {
      const mention = JSON.parse(message.mention);
      response["references"] = mention.map(m => {
        m = m.replace(/xmpp:+/ig, "");
        const data = {
          type: "mention",
          uri: `xmpp:${m}`
        };
        return data;
      });
    }

    if (message.sort_id) {
      response["sort_id"] = message.sort_id;
    }

    if (message.x_origMessage) {
      response["originalMessage"] = typeof message.x_origMessage === "string" ? JSON.parse(message.x_origMessage) : message.x_origMessage;
    }

    if (message.x_forwardMessage) {
      response["forwardMessage"] = typeof message.x_forwardMessage === "string" ? JSON.parse(message.x_forwardMessage) : message.x_forwardMessage;
      response["isForwarded"] = true;
    }

    if (message.x_vncConference) {
      response["vncTalkConference"] = JSON.parse(message.x_vncConference);
    }

    if (message.x_location) {
      response["location"] = JSON.parse(message.x_location);
    }

    if (message.x_replaceMsgId) {
      response["replace"] = { id: message.x_replaceMsgId };
      // response["id"] =  message.x_replaceMsgId;
      // response["isDeleted"] =  true;
      // response["body"] =  null;
    }

    if (message.room) {
      response["from"] = this.convertFromObject(message.from, message.room);
      response["type"] = "groupchat";
    } else {
      response["from"] = this.convertFromObject(message.from);
      response["type"] = "chat";
    }

    if (message.group_action){
      response["group_action"] = {type: message.group_action};
    }

    // omemo
    if (message.encrypted) {
      response["encrypted"] = message.encrypted;
    }
    if (message.encryption) {
      response["encryption"] = message.encryption;
    }

    if (!!message.reactions) {
      try {
        response["reactions"] = JSON.parse(message.reactions);
      } catch (error) {
         // eslint-disable-next-line no-console
        console.error("unable to parse message ", message);
      }
    }

    if (!!message.conversationTarget) {
      response["conversationTarget"] = message.conversationTarget;
      response["convTarget"] = message.conversationTarget;
    }

    return response;
  }

  static downloadFileFromUrl(url: string, conversationRepository?: ConversationRepository, filesStorageService?: FilesStorageService, store?: Store<TalkRootState>, messageDetails?: any) {
    if (environment.isCordova) {
      let target = "_system";
      if (typeof device !== "undefined"
        && device.platform
        && device.platform.toUpperCase() === "ANDROID"
        && (url.includes(".jpeg") || url.includes(".png") || url.includes(".jpg") || url.includes(".gif"))) {
        target = "_blank";
      }
      cordova.InAppBrowser.open(url, target, "location=no");
    } else if (environment.isElectron) {
      ElectronService.downloadFile(url);
    } else {
      if(!conversationRepository){
        if (url.indexOf("?") === -1) {
          window.open(url + "?d=true", "_blank");
        } else {
          window.open(url, "_blank");
        }
      }else{
        conversationRepository.downloadFile(messageDetails);
      }
    }
  }

  static isSystemMessage(message: Message) {
    return (!message.body || !message.body.trim()
      || message.chatState || (message.type !== "chat" && message.type !== "broadcast" && message.type !== "groupchat"));
  }

  static isActionMessage(message: Message) {
    return !!message.group_action || !!message.vncTalkConference;
  }
}
