
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { map, Observable, take } from "rxjs";
import { MiddlewareService } from "../../talk/services/middleware.service";
import { HttpHeaders } from "@angular/common/http";
import { ProfileResponse } from "../../responses/profile.response";
import { environment } from "../../environments/environment";
import { Subject, timeout } from "rxjs";
@Injectable()
export class AuthService {
  private isCordovaOrElectron = environment.isCordova || environment.isElectron;

  constructor(private middlewareService: MiddlewareService) {
  }

  getProfile(): Observable<ProfileResponse> {
    let headers = new HttpHeaders({"Content-Type": "application/json"});
    if (this.isCordovaOrElectron && localStorage.getItem("token") !== null) {
      headers.append("Authorization", localStorage.getItem("token"));
    }
    return this.middlewareService.get<ProfileResponse>("/api/profile", false, null, headers).pipe(timeout(5000));
  }

  getPrivateDocuments(): Observable<any> {
    const response = new Subject<any>();
    this.middlewareService.getWithError<any>("/api/privatexmldoc", true).pipe(map(res => {
      let documents = {};
      if (!!res.__array && Array.isArray(res.__array)) {
        res.__array.forEach(item => {
          if (!!item.__array) {
            try {
              documents[item.attr.key] = JSON.parse(item.__array[0]);
              if (item.attr.key === "preferableMediaDevices") {
                documents[item.attr.key] = JSON.parse(documents[item.attr.key]);
              }

              if (item.attr.key === "timeFormat") {
                // moment.js -> date-fns migration of AM/PM format
                // https://date-fns.org/v2.11.0/docs/format
                documents[item.attr.key] = documents[item.attr.key].replace("A", "a");
              } else if (item.attr.key === "dateFormat") {
                // https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
                documents[item.attr.key] = documents[item.attr.key].replace("DD", "dd").replace("YYYY", "yyyy");
              }
            } catch (error) {
              // eslint-disable-next-line no-console
              console.error("getPrivateDocuments err", item.attr.key, error);
            }
          }
        });
      }

      return documents;
    })).subscribe((data) => {
      response.next(data);
    }, (err) => {
      // eslint-disable-next-line no-console
      console.error("[AuthService][getPrivateDocuments] err", err);
      response.next(true);
    });
    return response.asObservable().pipe(take(1));
  }

  changeServerUrl(serverURL: string): Observable<any> {
    let headers = new HttpHeaders({"Content-Type": "application/json"});
    if (serverURL.endsWith("/")) {
      serverURL = serverURL.substring(0, serverURL.lastIndexOf("/")).trim();
    }
    return this.middlewareService.getTest<any>(serverURL + "/config", false, null, headers, false);
  }
}
