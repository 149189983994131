/* eslint no-console: 0 */

/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { isNullOrUndefined } from "util";
import { Store } from "@ngrx/store";
import { getBaseApiUrl, getIsLoggedIn } from "../../reducers";
import { TalkRootState } from "../reducers";
import { Broadcaster, Events } from "../shared/providers/broadcaster.service";
import { ConfigService } from "../../config.service";
import { environment } from "../../environments/environment";
import { CommonUtil } from "../utils/common.util";
import { Subject, catchError, map, take } from "rxjs";
import { LoggerService } from "app/shared/services/logger.service";

@Injectable()
export class MiddlewareService {
  private isCordovaOrElectron = environment.isCordova || environment.isElectron;
  private isCordova = environment.isCordova;
  private isElectron =  environment.isElectron;
  private _baseUrl = "";
  private handleError(error) {
    throw new Error(error);

  }
  private _isLoggedIn: boolean;

  constructor(private http: HttpClient,
              private configService: ConfigService,
              private store: Store<TalkRootState>,
              private logger: LoggerService,
              private broadcaster: Broadcaster) {
    this.store.select(getBaseApiUrl).subscribe(base => {
      if (!!base && base !== "null") {
        this._baseUrl = base;

      }
      this.logger.info("[MiddlewareService] baseApiUrl", this._baseUrl, base);
    });
    this.store.select(getIsLoggedIn).subscribe(isLoggedIn => {
      this._isLoggedIn = isLoggedIn;
    });
    this.handleError = this._handleError.bind(this);
  }

  isLoggedIn(): boolean {
    return localStorage.getItem("token") !== null;
  }

  get<T>(url: string, useAuthHeaders: boolean, data?: any, headers?: HttpHeaders, useBaseUrl: boolean = true, responseType?: string): Observable<T> {
    let options = {
      params: this.buildParams(data),
      headers: this.buildHeaders(useAuthHeaders, headers)
    };
    if (responseType) {
      options["responseType"] = responseType;
    }


    let baseUrl = this._baseUrl + url;
    if (!useBaseUrl) {
      baseUrl = url;
    }
    return this.http.get<T>(baseUrl, options);
  }

  getTest<T>(
    url: string,
    useAuthHeaders: boolean,
    data?: any,
    headers?: HttpHeaders,
    useBaseUrl: boolean = false,
    responseType?: string
  ): Observable<T> {
    let options = {
      params: this.buildParams(data),
      headers: this.addHeaders(useAuthHeaders, headers) // Use useAuthHeaders flag
    };
    if (responseType) {
      options["responseType"] = responseType;
    }
    let baseUrl = this._baseUrl + url;
    if (!useBaseUrl) {
      baseUrl = url;
    }
    return this.http.get<T>(baseUrl, options);
  }

  private addHeaders(useAuthHeaders: boolean, additionalHeaders?: HttpHeaders): HttpHeaders {
    let headers = additionalHeaders || new HttpHeaders();
    if (useAuthHeaders) {
      const token = localStorage.getItem("jwtTemp");
      if (token) {
        headers = headers.set("Authorization", `${token}`);
      }
    }
    return headers;
  }

  getWithStatusInterception(url: string, useAuthHeaders: boolean, data: any, headers?: HttpHeaders): Observable<any> {
    let baseUrl = this._baseUrl + url;
    // console.log("[ssaDebugTest1] ", CommonUtil.isOnAndroid(), this.isCordova);

    if (CommonUtil.isOnAndroid() && (!!window.appInBackground)) {
      const result = new Subject<any>();
      this.logger.info("[MiddlewareService] getWithStatusInterception ", url);
      try {
        this.logger.info("buildParams: ", data, Object.keys(data));
        const paramKeys = Object.keys(data);
        let params = [];
        for (let i = 0; i < paramKeys.length; i++) {
          params.push(paramKeys[i] + "=" + data[paramKeys[i]]);
        }
        if (params.length > 0) {
          baseUrl += "?" + encodeURI(params.join("&"));
        }
        this.logger.info("buildParams2: ", baseUrl);

        const options = {
          method: "get",
          responseType: "json",
          headers: {
            "Authorization": localStorage.getItem("token"),
            "Content-Type": "application/json",
            "Accept": "application/json"
          }
        };

        cordova.plugin.http.sendRequest(baseUrl, options, function(response) {

          // console.log("[MiddlewareService] background advanced-http response: ", response, response.status);
          let jsonResponse = response.data;
          jsonResponse.etag = response.headers.etag;
          jsonResponse.status = response.status;
          if (response && !!response.data) {
            result.next(jsonResponse);
          } else {
            result.next({});
          }


        }, function(response) {
          console.log("[MiddlewareService] background advanced-http response: ", response, response.status);
          console.error("[MiddlewareService] background advanced-http error: ", response.error);
          result.error(response.error);
        });

      } catch (error) {
        console.error("[MiddlewareService] background advanced-http Error: ", error);
        result.error(error);
      }

      return result.asObservable().pipe(take(1));
    } else {

      return this.http.get(baseUrl, {
        headers: this.buildHeaders(useAuthHeaders, headers),
        observe: "response",
        params: this.buildParams(data),
        responseType: "json"
      }).pipe(map(response => {
        // this.logger.info("[MiddlewareService][getWithStatusInterception]", response.headers.keys(), response.headers.get("etag"));
        let jsonResponse: any = response.body;
        jsonResponse.etag = response.headers.get("etag");
        jsonResponse.status = response.status;
        return jsonResponse;
      }),
      catchError(err => of("error", err)));
    }

  }

  getWithError<T>(url: string, useAuthHeaders: boolean, data?: any, headers?: HttpHeaders, useBaseUrl: boolean = true, responseType?: string): Observable<T> {
    let options = {
      params: this.buildParams(data),
      headers: this.buildHeaders(useAuthHeaders, headers)
    };
    if (responseType) {
      options["responseType"] = responseType;
    }

    // this.logger.info("[MiddlewareService] GET", url, options);

    let baseUrl = this._baseUrl + url;
    if (!useBaseUrl) {
      baseUrl = url;
    }
    return this.http.get<T>(baseUrl, options);
  }

  post<T>(url: string, useAuthHeaders: boolean, data: any, headers?: HttpHeaders, withCredentials = true): Observable<T> {
    return this.http.post<T>(this._baseUrl + url, data, {
      headers: this.buildHeaders(useAuthHeaders, headers),
      withCredentials: withCredentials
    });
  }

  postTest<T>(url: string, useAuthHeaders: boolean, data: any, headers?: HttpHeaders, withCredentials = true): Observable<T> {
    return this.http.post<T>(this._baseUrl + url, data, {
      headers: this.buildHeaders(useAuthHeaders, headers),
      withCredentials: false
    });
  }

  fetchAvatarUpdateInfo(data: any): Observable<any> {
    const url = this.configService.avatarServiceUrl + "/avatarupload/info";
    return this.http.post(url, data, { withCredentials: false });
  }

  backgroundPost(url: string, useAuthHeaders: boolean, data: any, headers?: HttpHeaders, withCredentials = true): Observable<any> {
    // this.logger.info("[MiddlewareService][backgroundpost]", url, data, this._baseUrl);
    const result = new Subject<any>();
    const serverURL = localStorage.getItem("serverURL");
    const targetUrl = serverURL + url;
    try {
      const options = {
        method: "post",
        data: data,
        responseType: "json",
        serializer: "json",
        headers: {
          "Authorization": localStorage.getItem("token"),
          "Content-Type": "application/json",
          "Accept": "application/json"
        }
      };

      cordova.plugin.http.sendRequest(targetUrl, options, function(response) {

        // this.logger.info("[MiddlewareService][bacgroundPost]", url, data, this._baseUrl, response, response.status);
        if (response && !!response.data) {
          result.next(response.data);
        } else {
          result.next({});
        }

      }, function(response) {
        // this.logger.info("[MiddlewareService][bacgroundPost] advanced-http response: ", response, response.status);
        console.error("backgroundPost advanced-http error: ", response.error, options, targetUrl, withCredentials);
        result.error(response.error);
      });
    } catch (error) {
      result.error(error);
    }


    return result.asObservable().pipe(take(1));

  }


  postWithoutBaseUrl(url: string, useAuthHeaders: boolean, data: any, headers?: HttpHeaders, withCredentials = true): Observable<any> {
    this.logger.info("[MiddlewareService][postWithoutBaseUrl]", url, data, this._baseUrl);
    return this.http.post(url, data, {
      headers: this.buildHeaders(useAuthHeaders, headers),
      withCredentials: withCredentials,
      reportProgress: true,
      observe: "events",
    });
  }

  postWithHeadersInterception(url: string, useAuthHeaders: boolean, data: any, headers?: HttpHeaders): Observable<any> {
    this.logger.info("[MiddlewareService][postWithHeadersInterception]", url, data, this._baseUrl);

    const localTime = new Date().getTime();
    return this.http.post(this._baseUrl + url, data, {
      headers: this.buildHeaders(useAuthHeaders, headers),
      observe: "response",
      withCredentials: true
    }).pipe(map(response => {
        // this.logger.info("[MiddlewareService][postWithHeadersInterception] response", response, response.headers, response.body);
      const serverDate = response.headers.get("Date");
      let jsonResponse: any = response.body;
      if (serverDate) {
        const diff = localTime - new Date(serverDate).getTime();
        jsonResponse.serverTimeDiff = diff;
      }
      return jsonResponse;
    }));
  }

  put<T>(url: string, useAuthHeaders: boolean, data?: any, headers?: HttpHeaders): Observable<T> {
    this.logger.info("[MiddlewareService] PUT", url);
    return this.http.put<T>(this._baseUrl + url, data, {
      headers: this.buildHeaders(useAuthHeaders, headers)
    });
  }

  delete<T>(url: string, useAuthHeaders: boolean, data?: any, headers?: HttpHeaders): Observable<T> {
    this.logger.info("[MiddlewareService] DELETE", url);
    const options = {
      params: this.buildParams(data),
      headers: this.buildHeaders(useAuthHeaders, headers)
    };

    return this.http.delete<T>(this._baseUrl + url, options);
  }

  private buildHeaders(useAuthHeaders: boolean, headers?: HttpHeaders): HttpHeaders {
    // this.logger.info("[MiddlewareService] buildHeaders", useAuthHeaders, headers, localStorage.getItem("token"));

    if (!useAuthHeaders) {
      return headers;
    }

    let mutatedHeaders: HttpHeaders;

    if (!isNullOrUndefined(headers)) {
      mutatedHeaders = headers;
    } else {
      mutatedHeaders = new HttpHeaders();
    }

    const token = localStorage.getItem("token");
    const token2 = localStorage.getItem("jwtTemp");
    if (token && this.isCordovaOrElectron) {
      if (environment.theme === "hin" && this.isCordova) {
        return this.getHinHeaders(mutatedHeaders, token);
      }
      if (mutatedHeaders.has("Content-Type") && mutatedHeaders.get("Content-Type") === "text/plain") {
          return mutatedHeaders.set("Authorization", token).set("Content-Type", "text/plain").set("Accept", "text/plain");
      }
      return mutatedHeaders.set("Authorization", token).set("Content-Type", "application/json").set("Accept", "application/json");
    } else {
      if (mutatedHeaders.has("Content-Type") && mutatedHeaders.get("Content-Type") === "text/plain") {
        return mutatedHeaders.set("Content-Type", "text/plain").set("Accept", "text/plain").set("Authorization", token2);
      }
      return mutatedHeaders.set("Content-Type", "application/json").set("Accept", "application/json").set("Authorization", token2);
    }
  }

  private getHinHeaders(mutatedHeaders: HttpHeaders, token: string) {
    const deviceLabel = CommonUtil.getMobileDeviceLabel();
    if (mutatedHeaders.has("Content-Type") && mutatedHeaders.get("Content-Type") === "text/plain") {
      return mutatedHeaders.set("Authorization", token).set("Content-Type", "text/plain").set("hintalkdevice", deviceLabel).set("Accept", "text/plain");
    }
    return mutatedHeaders.set("Authorization", token).set("Content-Type", "application/json").set("hintalkdevice", deviceLabel).set("Accept", "application/json");
  }

  private buildParams(data: any): HttpParams {
    let params = new HttpParams();

    for (let key in data) {
      if (key === "textMatch") {
        this.logger.info("[ENCODE] raw input", key, data[key], encodeURI(data[key]));
        this.logger.info("[ENCODE] raw input", key, data[key], encodeURIComponent(data[key]));
        this.logger.info("[ENCODE] raw input", key, data[key], encodeURI(data[key]));
        this.logger.info("[ENCODE] raw input", key, data[key], encodeURI(data[key]));
      }
      params = params.append(key, data[key]);
    }
    return params;
  }

  private _handleError(response: Response): Observable<any> {
    const error = response["error"] || {};
    const keys = Object.keys(error);
    const key = keys[0];
    let message = error[key];

    this.logger.error("[MiddlewareService] _handleError", response.status ? response.status : response, error);

    if (response.status === 401) {
      this.broadcaster.broadcast(Events.SESSION_EXPIRED);
      if (!this.isCordovaOrElectron) {
        this.configService.clearStorage();
        this.configService.redirectToLoginScreen();
      }
      return of();
    }

    if (error[key] instanceof Array) {
      message = error[key][0];
    }

    if (key === "isTrusted") {
      message = "Please check your internet connection !";
    } else {
      message = key + " : " + message;
    }

    return throwError({messages: message, error: error});
  }
}
