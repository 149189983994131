import { Component, Inject, OnDestroy, OnInit, Optional, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TrashSettingsComponent } from "./trash-settings/trash-settings.component";
import { PopupPositionService } from "../../channels/popup-position.service";
import { take, takeUntil } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { TalkRootState } from "../reducers";
import { getUserConfig } from "../../reducers";
import { FormControl } from "@angular/forms";
import { TrashWindowService } from "./trash-window.service";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { Subject } from "rxjs";
import { ArchiveTrashWindowMobileComponent } from "../archive-trash-window-mobile/archive-trash-window-mobile.component";
import { ChannelRepository } from "app/channels/repository/channel.repository";

@Component({
  selector: "vp-trash-window",
  templateUrl: "./trash-window.component.html",
  styleUrls: ["./trash-window.component.scss"],
  encapsulation: ViewEncapsulation.None
})

export class TrashWindowComponent implements OnInit, OnDestroy {
  isMobileScreen: boolean = false;

  channelsCount = 0;
  topicsCount = 0;
  selectedTabIndex = 0;
  searchControl = new FormControl("");
  private isAlive$ = new Subject<boolean>();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _dialog: MatDialog,
    private _popup: PopupPositionService,
    private _talkRootStore: Store<TalkRootState>,
    private breakpointObserver: BreakpointObserver,
    public _trashWindowService: TrashWindowService,
    public dialog: MatDialog,
    private channelRepo: ChannelRepository,
    private dialogRef?: MatDialogRef<TrashWindowComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data?: { isMobileScreen: boolean, setCategory?: string },
  ) { }

  ngOnInit(): void {
    this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 767px)");
    this.breakpointObserver
      .observe(["(max-width: 767px)"])
      .pipe(takeUntil(this.isAlive$))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobileScreen = true;
        } else {
          this.isMobileScreen = false;
        }
      });
    this._route.queryParams.subscribe(params => {
      this.selectedTabIndex = params["selectedTabIndex"];
    });
    if (!!this.data?.setCategory) {
      this._trashWindowService.changeTab(this.data?.setCategory);
    }
  }

  ngOnDestroy() {
    this._trashWindowService.changeTab("channels");
  }

  exitTrashWindow() {
    if (this.isMobileScreen) {
      this.dialog.closeAll();
      this.dialog.open(ArchiveTrashWindowMobileComponent, {
        backdropClass: "archive-contacts-backdrop",
        panelClass: "mobile-dialog",
        width: "100vw",
        height: "100vh",
        maxHeight: "100vh",
        maxWidth: "100vw",
        autoFocus: true
      });
    }
    else {
      this.channelRepo.setSelectedChannelId(null);
      this._router.navigateByUrl(`/talk`);
    }
    //if (this.isMobileScreen) {
    // this.dialogRef.close();
    //return;
    //}
    // this._router.navigateByUrl("/talk/channels");
  }

  openTrashSettings(isMobileScreen = false) {
    // let left = 0;
    // let top = 0;
    this._talkRootStore.select(getUserConfig).pipe(take(1)).subscribe(userConfig => {
      const { auto_clean_trash, auto_clean_trash_period } = userConfig;
      // const actionLinkDimensions = document.getElementById("setting-link").getBoundingClientRect();
      // const left = actionLinkDimensions.right + 16;
      // const top = actionLinkDimensions.bottom;
      // this.getTra shSettingsPopup(left, top, {auto_clean_trash, auto_clean_trash_period});
      // if (!this.isMobileScreen) {
      const actionLinkDimensions = document.getElementById("setting-link")?.getBoundingClientRect();
      const left = actionLinkDimensions.right + 16;
      const top = actionLinkDimensions.bottom;
      // }
      this.getTrashSettingsPopup(left, top, { auto_clean_trash, auto_clean_trash_period }, this.isMobileScreen);
    });
  }

  getTrashSettingsPopup(clientX, clientY, popupData = {}, isMobileScreen): any {
    return this._dialog.open(TrashSettingsComponent, {
      width: isMobileScreen ? "100%" : "480px",
      height: isMobileScreen ? "100%" : "fit-content",
      maxWidth: isMobileScreen ? "100%" : null,
      panelClass: isMobileScreen ? "mobile-dialog" : "trash-settings-dialog-container",
      data: { ...popupData, isMobileScreen },
      position: isMobileScreen ? {
        top: "0px",
        left: "0px"
      } : this._popup.getPopupPosition({ width: 480, height: 316 }, clientX, clientY)
    });
    // backdropClass: "transparent-backdrop",
    // data: {...popupData},
    // position: this._popup.getPopupPosition({ width: 480, height: 316 }, clientX, clientY)

  }

}

export const SEARCH_CONTROL_CHANGED = "SEARCH_CONTROL_CHANGED";
