import { ChangeDetectorRef, Component, OnDestroy, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { Channel } from "../../../channels/models/channel.model";
import { ChannelService } from "../../../channels/channel.service";
import { ConstantsUtil } from "../../utils/constants.util";
import { combineLatest, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, map, skip, startWith, switchMap, take, takeUntil } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Broadcaster } from "../../shared/providers";
import {
  REMOVE_CHILD_ARCHIVED_TOPICS,
  REMOVE_PARENT_ARCHIVED_CHANNEL
} from "../archive-window-topic/archive-window-topic.component";
import { DEFAULT_AVATAR } from "../../../common";
import { ChannelRepository } from "app/channels/repository/channel.repository";
import { ChannelSnackbarService } from "app/channels/channel-snackbar.service";
import { TranslateService } from "@ngx-translate/core";
import { SnackbarType } from "app/channels/models/snackbar.model";
import { CommonUtil } from "../../utils/common.util";

@Component({
  selector: "vp-archive-window-channels",
  templateUrl: "./archive-window-channels.component.html",
  styleUrls: ["./archive-window-channels.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class ArchiveWindowChannelsComponent implements OnInit, OnDestroy {

  constructor(private _channelService: ChannelService,
    private _dialog: MatDialog,
    private router: Router,
    private _broadcaster: Broadcaster,
    private channelSnackBarService: ChannelSnackbarService,
    private translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private channelRepo: ChannelRepository) { }

  @Output() totalChannels = new BehaviorSubject<number>(0);
  groupType = ChannelGroupType;
  searchControl = new UntypedFormControl("");
  groupControl = new UntypedFormControl(this.groupType.DATE_ASC);
  channels = new BehaviorSubject<Channel[]>([]);
  allChannels = new BehaviorSubject<Channel[]>([]);
  data = { offset: 0, limit: ConstantsUtil.PAGINATION_LIMIT.ARCHIVED_CHANNELS, q: "", sort: ChannelGroupType.DATE_ASC };
  private isAlive$ = new Subject<boolean>();
  isRightBarExpanded: boolean = false;
  isLeftBarExpanded: boolean = false;
  channelsGroupedByAuthor = {};
  defaultAvatar = DEFAULT_AVATAR;
  authorsGroup = [];
  dismissLoader: boolean = false;

  ngOnInit(): void {
    this._broadcaster.on("SUBCHANNEL_ARCHIVED_IN_PARENT").pipe(takeUntil(this.isAlive$)).subscribe((res: Channel) => {
      this.loadMoreChannels(true);
    });

    this.channelRepo.getRightbarExpanded().pipe(takeUntil(this.isAlive$)).subscribe((res: boolean) => {
      this.isRightBarExpanded = res;
      this.changeDetectorRef.markForCheck();
    });

    this.channelRepo.getIsLeftSideBarExpanded().pipe(takeUntil(this.isAlive$)).subscribe((res: boolean) => {
      this.isLeftBarExpanded = res;
      this.changeDetectorRef.markForCheck();
    });
    this.channelRepo.getArchivedChannelsInfo().pipe(take(1)).subscribe(() => {
      this.channelRepo.loadArchivedChannels(this.data?.sort);
    });
    this.channelRepo.getArchivedChannelsInfo().pipe(takeUntil(this.isAlive$),
      distinctUntilChanged((prev, curr) => prev?.totalCount === curr?.totalCount && prev?.ids?.length === curr?.ids?.length) // Only emit if totalCount changes
    ).subscribe(info => {
      if (!info?.isLoaded) {
        this.channelRepo.loadArchivedChannels(this.data?.sort);
      }
      this.totalChannels.next(info?.totalCount || 0);
    });

    this.channelRepo.selectArchivedChannels()
      .pipe(takeUntil(this.isAlive$)
        , filter(v => !!v)
        , distinctUntilChanged((p: { channels: Channel[], count: number }, q: { channels: Channel[], count: number }) => CommonUtil.compareChannelsArrays(p?.channels, q?.channels)))
      .subscribe((data: { channels: Channel[], count: number }) => {
        this.allChannels.next(data?.channels);
        this.channels.next(data?.channels);
      });
    this.listenSearchChanges();
    this.listenBroadcasterChanges();
    this.channelRepo.getArchiveChannelsSortBy().pipe(takeUntil(this.isAlive$)).subscribe((sortBy: ChannelGroupType) => {
      if (sortBy) {
        this.groupControl?.setValue(sortBy);
      } else {
        this.groupControl?.setValue(this.groupType.DATE_ASC);
      }
    });
    this.groupControl?.valueChanges.subscribe(sortBy => {
      this.channelRepo.loadArchivedChannels(sortBy);
    });
  }

  onUserScroll() {
    this.loadMoreChannels();
  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  showChannelInfo(item, event?) {
    if (event) this.onOpenChannel(item);
    else {
      this.channelRepo.setSelectedChannelId(item?.id);
      this.channelRepo.openSideBar("channel", item?.id);
    }
  }
  loadMoreChannels(subChannelsExist?: boolean) {
    if (subChannelsExist || (this.totalChannels?.value > this.data?.offset)) {
      const loadedChannels = [...(this.channels?.getValue() || [])]?.length;
      this.data.offset = loadedChannels - (subChannelsExist ? 1 : 0);
      this.data.sort = this.groupControl?.value;
      let str = this.data?.q?.replace("~", "");
      let ch = this.allChannels?.value;
      if (str) {
        ch = this.allChannels?.value?.filter((a1: any) => {
          return a1?.name?.includes(str) || a1?.description?.includes(str);
        });
      }
      if (!subChannelsExist && (ch?.length >= this.totalChannels?.value ||
        (ch?.length >= (this.data?.offset + this.data?.limit)))) {
        this.data.offset = ch?.length;
        this.channels?.next(ch);
      } else {
        this.channelRepo.loadMoreArchivedChannels(this.data?.offset, this.data?.limit, this.data?.sort, this.data?.q?.startsWith("~") ? this.data?.q : undefined, subChannelsExist);
      }
    }
  }

  async openChannelParticipantsPopup(channel: Channel) {
    const { ChannelParticipantsComponent } = await import(
      "../../channel-participants/channel-participants.component");
    this._dialog.open(ChannelParticipantsComponent, {
      backdropClass: "vnctalk-form-backdrop",
      panelClass: "vnctalk-form-panel",
      width: "440px",
      height: "640px",
      autoFocus: true,
      data: { channel }
    });
  }

  async unArchiveChannel(channel: Channel) {
    const { UnarchiveChannelComponent } = await import(
      "../unarchive-channel/unarchive-channel.component");
    const unArchiveChannelRef = this._dialog.open(UnarchiveChannelComponent, {
      backdropClass: "vnctalk-form-backdrop",
      panelClass: "vnctalk-form-panel",
      width: "480px",
      height: "336px",
      autoFocus: true,
      data: { channelName: channel.name }
    });
    unArchiveChannelRef.afterClosed()
      .pipe(take(1))
      .subscribe(value => {
        this.unarchiveChannelConfirmed(channel, value?.unArchiveTopics);
      });
  }

  private unarchiveChannelConfirmed(channel: Channel, unArchiveTopics = true, showSnackbar = true) {
    this._channelService.unarchiveChannel(channel.id, unArchiveTopics).subscribe(res => {
      if (res?.channel) {
        if (res?.channel?.sub_channels_count > 0) {
          this.data.offset = 0;
          this.data.limit = 25;
        }
        this.channelRepo.changeChannelCategory(res?.channel, "unarchived");
        this.channelRepo.updateChannelIntoStore(res?.channel);
        this.channelRepo.setChannelCategory(res?.channel);
        if (showSnackbar) {
          this.translate.get("UNARCHIVE_CHANNEL_SUCCESS").subscribe(text => this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK));
        }
        if (unArchiveTopics) {
          this.removeChildArchivedTopics(channel?.id);
        }
      }
    });
  }

  private listenBroadcasterChanges() {
    this._broadcaster.on(REMOVE_PARENT_ARCHIVED_CHANNEL).pipe(takeUntil(this.isAlive$)).subscribe((res: any) => {
      setTimeout(() => {
        this.unarchiveChannelConfirmed(res?.channel, false, false);
      });
    });
  }

  onOpenChannel(item) {
    this.router.navigateByUrl(`/talk/channels/${item.id}`);
  }

  private removeChildArchivedTopics(channelId) {
    this._broadcaster.broadcast(REMOVE_CHILD_ARCHIVED_TOPICS, { channelId: channelId });
  }

  private getArchivedChannels() {
    return this._channelService.getArchivedChannels(this.data).pipe(takeUntil(this.isAlive$), map(val => {
      let value = val?.data;
      let newOffset = (value?.offset || 0) + ConstantsUtil.PAGINATION_LIMIT.ARCHIVED_CHANNELS;
      const total_count = value?.total_count || 0;
      newOffset = newOffset > total_count ? total_count : newOffset;
      this.totalChannels.next(total_count);
      this.dismissLoader = true;
      this.data.offset = newOffset;
      return (value?.channels || []);
    }));
  }

  private listenSearchChanges() {
    const $searchControlOb = this.searchControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged(), startWith(""), skip(1));
    const $groupControlOb = this.groupControl.valueChanges.pipe(startWith(this.groupType.DATE_ASC));
    combineLatest([$searchControlOb, $groupControlOb])
      .pipe(takeUntil(this.isAlive$), switchMap((value: [string, ChannelGroupType]) => {
        this.data.q = value[0] || "";
        this.data.sort = value[1] || ChannelGroupType.DATE_ASC;
        this.data.offset = 0;
        this.totalChannels.next(0);
        this.channels.next([]);
        return this.getArchivedChannels();
      })).subscribe((channels: Channel[]) => {
        this.setArchivedChannels(channels);
      });
  }

  private setArchivedChannels(channels: Channel[]) {
    const filterAndConcat = (oldChannels: Channel[], newChannels: Channel[]) => {
      const resultantChannels = [...oldChannels];
      newChannels?.forEach(newChannel => {
        const index = oldChannels.findIndex(oldChannel => oldChannel?.id === newChannel?.id);
        if (index === -1) {
          resultantChannels.push(newChannel);
        }
      });
      return resultantChannels;
    };
    this.channels.next(filterAndConcat(this.channels.getValue(), channels));
  }


  async permanentlyDeleteChannel(channel: Channel) {
    let options: any = {
      width: "480px",
      height: "280px"
    };
    if (CommonUtil.isMobileSize()) {
      options = {
        maxWidth: "100vw",
        maxHeight: "100vh",
        minHeight: "300px"
      };
    }
    const { ConfirmationChannelComponent } = await import(
      "../../../channels/confirmation-channel/confirmation-channel.component");
    this._dialog.open(ConfirmationChannelComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: ["vnctalk-form-panel", "confirmation-dialog"],
      disableClose: true,
      data: {
        headerText: "DELETE_CHANNEL",
        bodyText: "DELETE_CHANNEL_MSG",
        okLabel: "DELETE"
      },
      autoFocus: true
    }, options)).afterClosed().pipe(take(1)).subscribe((res: any) => {
      if (!!res && res.confirmation) {
        if (res.confirmation === "yes") {
          this.channelRepo.deleteChannel(channel.id, true).subscribe();
        }
      }
    });
  }


}
export enum ChannelGroupType {
  "DATE_ASC" = "created_on:asc",
  "DATE_DESC" = "created_on:desc",
  "AUTHOR_ASC" = "author:asc",
  "AUTHOR_DESC" = "author:desc"
}
