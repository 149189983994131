
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import "./rxjs-imports";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ConfigService } from "./config.service";
import { ErrorHandler, Injectable, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { CanLoad, PreloadAllModules, Route, RouterModule } from "@angular/router";

import { AppComponent } from "./app.component";
import { GlobalAuthService } from "./plone/auth.service";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { catchError, map, Observable, switchMap, tap } from "rxjs";
import { HttpModule } from "@angular/http";
import { environment } from "./environments/environment";
import { ConstantsUtil } from "./talk/utils/constants.util";
import { StoreModule } from "@ngrx/store";
import { clearState, reducers } from "./reducers/index";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { ServiceWorkerModule, SwRegistrationOptions } from "@angular/service-worker";
import { of } from "rxjs";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { CommonUtil } from "./talk/utils/common.util";
import { VncLibraryModule } from "vnc-library";
import { HomeComponent } from "./home/home.component";
import { GlobalSharedModule } from "./global-shared.module";
import { SharedModule } from "./shared/shared.module";
import { LoggerService } from "./shared/services/logger.service";
import {  MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from "@angular/material/core";
import * as Raven from "@sentry/browser";
import * as Sentry from "sentry-cordova";
import { RewriteFrames as RewriteFramesIntegration } from "@sentry/integrations";
import { SentryErrorHandler, ElectronErrorHandler } from "./talk/shared/providers/error-handler.service";
import { ColorChromeModule } from "ngx-color/chrome";
import { RedirectInterceptor } from "./channels/redirect.interceptor";

let EXTRA_PROVIDERS = [];
if (environment.enableSentry && environment.production) {
  if (!environment.isCordova && !environment.isElectron) {
    Raven.init({
        dsn: "https://ccff09193bae47d6afe18ccf6b6df3ad@sentry3.vnc.biz/3",
        release: "web-3.0.0.593",
        sendClientReports: false,
        autoSessionTracking: false
      });
    EXTRA_PROVIDERS = [{ provide: ErrorHandler, useClass: SentryErrorHandler }];
  } else if (environment.isCordova){
    Sentry.init({
      dsn: "https://ccff09193bae47d6afe18ccf6b6df3ad@sentry3.vnc.biz/3",
      release: "web-3.0.0.593"
    });
    EXTRA_PROVIDERS = [{ provide: ErrorHandler, useClass: SentryErrorHandler }];
  } else if (environment.isElectron) {
    Raven.init({
        dsn: "https://ccff09193bae47d6afe18ccf6b6df3ad@sentry3.vnc.biz/3",
        release: "web-3.0.0.593",
        sendClientReports: false,
        autoSessionTracking: false,
        integrations: [new RewriteFramesIntegration({
          iteratee: (frame) => {
            if (!!frame.filename) {
              frame.filename = frame.filename.split("app.asar")[1];
            }
            return frame;
          }
        })]
      });
    EXTRA_PROVIDERS = [{ provide: ErrorHandler, useClass: ElectronErrorHandler }];
  }
}

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 0,
    exitDuration: 0
  }
};
@Injectable()
export class AuthGuard implements CanLoad {
  private isCordovaOrElectron = environment.isCordova || environment.isElectron;

  constructor(private configService: ConfigService,
              private logger: LoggerService,
              private authService: GlobalAuthService) {
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    this.logger.info("[canLoad]", route, this.configService);
    if (this.isCordovaOrElectron || window.location.href.indexOf("/vncmeet/") !== -1) {
      if (window.location.href.indexOf("/vncmeet/") !== -1) {
        this.configService.isAnonymous = true;
        this.logger.info("[AuthGuard] isAnonymous = TRUE");
      }
      return true;
    }

    this.logger.info("[authService][getPrivateDocuments]");
    const documents$ = this.authService.getPrivateDocuments().pipe(tap(res => {
      const array = res["__array"];
      let fontSize;
      let theme;
      // this.logger.info("[getPrivateDocuments] AuthGuard", array);
      if (array && Array.isArray(array)) {
        array.forEach(item => {
          if (item["attr"]["key"] === "selectedFontSize") {
            fontSize = item["__array"][0];
          } else if (item["attr"]["key"] === "selectedTheme") {
            theme = item["__array"][0];
          } else if (item["attr"]["key"] === "lang") {
            const language = item["__array"][0].substr(1).slice(0, -1);
            // this.logger.info("[getPrivateDocuments] AuthGuard language", language);
            localStorage.setItem("portalLanguage", language);
          }
        });
      }

      if (fontSize) {
        localStorage.setItem(ConstantsUtil.FONT_SIZE, fontSize.substr(1).slice(0, -1));
      }

      if (theme) {
        const currentTheme = theme.substr(1).slice(0, -1);
        localStorage.setItem(ConstantsUtil.THEME, currentTheme);
        let themePath = `${localStorage.getItem("themeHash")}.`;
        document.getElementById("vnctalkTheme").remove();
        if (currentTheme === "ekbo") {
          CommonUtil.loadExternalStyle(themePath + "ekbo.css", "vnctalkTheme").then(() => {
            // console.info("loaded ekbo theme from backend");
            if (CommonUtil.isOnAndroid()) {
              StatusBar.backgroundColorByHexString("#6633cc");
              StatusBar.styleLightContent();
            }
          }).catch(() => { });
        } else if (currentTheme === "hin") {
          CommonUtil.loadExternalStyle(themePath + "hin.css", "vnctalkTheme").then(() => {
            // console.info("loaded hin theme from backend");
            if (CommonUtil.isOnAndroid()) {
              StatusBar.backgroundColorByHexString("#f39900");
              StatusBar.styleBlackTranslucent();
            }
          }).catch(() => { });
        } else if (currentTheme === "airbus") {
          CommonUtil.loadExternalStyle(themePath + "airbus.css", "vnctalkTheme").then(() => {
            // console.info("loaded airbus theme from backend");
            if (CommonUtil.isOnAndroid()) {
              StatusBar.backgroundColorByHexString("#00205b");
              StatusBar.styleLightContent();
            }
          }).catch(() => { });
        } else {
          CommonUtil.loadExternalStyle(themePath + "vnctalk.css", "vnctalkTheme").then(() => {
            // console.info("loaded vnctalk theme from backend");
            if (CommonUtil.isOnAndroid()) {
              StatusBar.backgroundColorByHexString("#317bbc");
              StatusBar.styleLightContent();
            }
          }).catch(() => { });
        }
      }
    }), catchError((err) => {
      this.logger.info("getPrivateDocuments err", err);
      return of(true);
    }));

    const profile$ = this.authService.getProfile(true).pipe(tap(profileFromServer => {
      this.logger.info("[AuthGuard][canLoad] getProfile, profileFromServer: ", profileFromServer);
      if (!profileFromServer) {
        this.setRedirectUrl();
        this.resetAndRedirectToLoginScreen();
      }
      if (!!profileFromServer) {
        this.checkAndRedirect();
        this.closeOtherTabs();
      }
    }), catchError(err => {
      this.setRedirectUrl();
      this.resetAndRedirectToLoginScreen();
      return err;
    }));

    return profile$.pipe(switchMap(() => documents$), map((document) => !!document));
  }

  private resetAndRedirectToLoginScreen() {
    this.logger.info("[AppModule][resetAndRedirectToLoginScreen]");
    this.configService.clearStorage();
    // if (environment.isElectron) {
    //   this.electronService.clearStorage();
    // }
    this.configService.redirectToLoginScreen();
  }

  private setRedirectUrl() {
    if (window.location && window.location.href.replace(/\/$/, "") !== window.location.origin) {
      let currentDate = new Date();
      currentDate.setSeconds(currentDate.getSeconds() + 300);
      document.cookie = "redirectUrl=" + encodeURIComponent( window.location.href ) + ";expires=" + currentDate.toUTCString() + ";path=/";
    }
  }

  private checkAndRedirect() {
    let allcookies = document.cookie;
    if (allcookies) {
      let cookiearray = allcookies.split(";");
      let redirectUrl;
      for (let i = 0; i < cookiearray.length; i++) {
        let name = cookiearray[i].split("=")[0];
        if ( name && "redirectUrl" === name.trim()) {
          redirectUrl = decodeURIComponent(cookiearray[i].split("=")[1]);
          break;
        }
      }
      if ( redirectUrl ) {
        let currentDate = new Date();
        currentDate.setSeconds(currentDate.getSeconds() - 300);
        document.cookie = "redirectUrl=;expires=" + currentDate.toUTCString() + ";path=/";
        window.location.href = redirectUrl;
      }
    }
  }

  private closeOtherTabs() {
    if (!this.isCordovaOrElectron) {
      if (window.location.href.includes("/talk/trigger")) {
        localStorage.setItem("closeTab", "true");
        setTimeout(() => {
          localStorage.removeItem("closeTab");
        }, 1000);
      } else {
        localStorage.removeItem("closeTab");
      }

      /* setInterval(() => {
        console.log("closeTabInterval");
        let value = localStorage.getItem("closeTab");
        if (value === "true") {
            localStorage.removeItem("closeTab");
            window.location.href = "about:blank";
        }
      }, 1000); */
    }
  }
}

let cordovaRoutes = [
  {
    path: "",
    canLoad: [AuthGuard],
    component: HomeComponent
  },
  {
    path: "vncmeet",
    data: {appName: "VNCtalk", sidebar: false, fullWidth: true, path: "/vncmeet"},
    loadChildren: () => import("./talk/talk.module").then(m => m.VNCTalkModule)
  },
  {
    path: "conference",
    data: {appName: "VNCtalk", sidebar: false, fullWidth: true, path: "/vncmeet"},
    loadChildren: () => import("./talk/talk.module").then(m => m.VNCTalkModule)
  },
  {
    path: "talk",
    canLoad: [AuthGuard],
    loadChildren: () => import("./root.module").then(m => m.RootModule)
  }
];

let routes = [
  {
    path: "",
    canLoad: [AuthGuard],
    loadChildren: () => import("./root.module").then(m => m.RootModule)
  }
];
window.isOnIpad = CommonUtil.isOnIpad();

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    HttpModule,
    VncLibraryModule,
    RouterModule.forRoot(environment.isCordova && !window.isOnIpad ? cordovaRoutes : routes, { preloadingStrategy: PreloadAllModules }),
    StoreModule.forRoot(reducers, {metaReducers: [clearState], runtimeChecks: {
      strictStateImmutability: false,
      strictActionImmutability: false,
    }}),
    !environment.production ? StoreDevtoolsModule.instrument({maxAge: 50}) : [],
    GlobalSharedModule,
    SharedModule,
    MatDialogModule,
    ServiceWorkerModule.register("ngsw-worker.js"),
    ColorChromeModule
  ],
  declarations: [
    AppComponent,
    HomeComponent
  ],
  providers: [
    LoggerService,
    GlobalAuthService,
    ConfigService,
    AuthGuard,
    {
       provide: MatDialogRef,
       useValue: {}
     },
     {
      provide: SwRegistrationOptions,
      useFactory: () => ({ enabled: false, registrationStrategy: "registerImmediately" }),
      },
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: globalRippleConfig
    },
    { provide: HTTP_INTERCEPTORS, useClass: RedirectInterceptor, multi: true },
    ...EXTRA_PROVIDERS
  ],
  exports: [],
  bootstrap: [AppComponent],
})

export class AppModule {

}
