
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ConversationRepository } from "../repositories/conversation.repository";
import { AvatarRepository } from "app/talk/repositories/avatar.repository";
import { getGlobalMute, getIsWindowFocused, getUserNotifyOption } from "../../reducers/index";
import { EventEmitter, Injectable } from "@angular/core";
import { Subject, take } from "rxjs";
import { Notification, NotificationEvent } from "./notifications.model";
import { Store } from "@ngrx/store";
import { TalkRootState, getActiveConference, getSelectedConversationId, getConversationById } from "../reducers/index";
import { environment } from "../../environments/environment";
import { ElectronService } from "app/shared/providers/electron.service";
import { ConversationUtil } from "../utils/conversation.util";
import { SetActiveTab } from "app/actions/app";
import { ContactRepository } from "../repositories/contact.repository";
import { TranslateService } from "@ngx-translate/core";
import { Broadcaster } from "../shared/providers";
import { NotificationCenterItem } from "app/channels/models/notification.model";
import { LoggerService } from "app/shared/services/logger.service";
import { CommonUtil } from "../utils/common.util";

@Injectable()
export class VNCTalkNotificationsService {
  isWindowFocused = true;
  id: string;
  isGlobalMuteEnabled = false;
  notifyOption: string;

  constructor(
    private store: Store<TalkRootState>,
    private conversationRepo: ConversationRepository,
    private contactRepo: ContactRepository,
    private translate: TranslateService,
    private electronService: ElectronService,
    private avatarRepo: AvatarRepository,
    private logger: LoggerService,
    private broadcaster: Broadcaster) {

    this.store.select(getIsWindowFocused).subscribe((focus) => {
      this.isWindowFocused = focus;
    });

    this.store.select(getGlobalMute).subscribe(globalMute => {
      this.logger.info("[VNCTalkNotificationsService][getGlobalMute]", globalMute);
      this.isGlobalMuteEnabled = globalMute;
    });

    this.store.select(getUserNotifyOption).subscribe(notifyOption => {
      this.logger.info("[VNCTalkNotificationsService][getUserNotifyOption]", notifyOption);
      this.notifyOption = notifyOption;
    });
  }

  emitter: Subject<NotificationEvent> = new Subject<NotificationEvent>();

  set(notification: Notification, to: boolean) {
    notification.id = Math.random().toString(36).substring(3);
    if (notification.override && notification.override.id) {
      notification.id = notification.override.id;
    }
    notification.click = new EventEmitter<{}>();
    notification.bare = notification.bare || notification.override.from;
    this.emitter.next({ command: "set", notification: notification, add: to });
    return notification;
  }

  getChangeEmitter() {
    return this.emitter;
  }

  //// Access methods
  singleChat(title: string, content: string, bare: string, override?: any) {
    this.store.select(getSelectedConversationId).pipe(take(1)).subscribe(id => this.id = id);

    if (!this.isWindowFocused || this.id !== bare) {
      const type = "chat";
      const username = this.contactRepo.getFullName(bare);
      // this.logger.info("desktopNotification: ", username, content);
      this.showChromeNotification(username, content, bare, type);

      this.set({
        title: title,
        content: content,
        bare: bare,
        type: type,
        override: override
      }, true);
    }

  }

  groupChat(title: string, content: string, bare: string, override?: any) {
    this.store.select(getSelectedConversationId).pipe(take(1)).subscribe(id => this.id = id);

    if (!this.isWindowFocused || this.id !== bare) {
      const type = "groupchat";
      this.showChromeNotification(title, content, bare, type);

      this.set({
        title: title,
        content: content,
        bare: bare,
        type: type,
        override: override
      }, true);
    }
  }

  mentionNotification(title: string, content: string, bare: string, messageId: string) {
    this.store.select(getSelectedConversationId).pipe(take(1)).subscribe(id => this.id = id);

    if (!this.isWindowFocused || this.id !== bare) {
      const type = "mention";

      this.showChromeNotification(title, content, bare, type);

      this.set({
        title: title,
        content: content,
        bare: bare,
        type: type,
        override: { messageId }
      }, true);
    }
  }


  joinGroupRequestNotification(title: string, content:string, bare: string, override?: any) {
    const type = "groupchat";
    this.set({
      title: title,
      content: content,
      bare: bare,
      type: type,
      override: override
    }, true);
  }

  // Broadcast:

  scheduleLocalNotification(target: string, msgid: string, username: string, groupName: string, message: string, eventType: string, mute?: boolean): void {
    this.logger.info("[VNCTalkNotificationsService][scheduleLocalNotification]", this.isGlobalMuteEnabled, this.notifyOption);

    if (this.isGlobalMuteEnabled || this.notifyOption === "0") {
      return;
    }

    if (window.FirebasePlugin && window.FirebasePlugin.scheduleLocalNotification) {
      const options = {
        id: 0,
        msgid: msgid,
        target: target,
        username: username,
        groupName: groupName || target.split("@")[0],
        message: this.notifyOption !== "1" ? message : "", // 1 means 'Without chat text'
        eventType: eventType,
        nsound: mute ? "mute" : "nomute",
        sound: "",
        lights: ""
      };

      if (environment.theme !== "hin") {
        window.FirebasePlugin.scheduleLocalNotification(options, () => {
          this.logger.info("[VNCTalkNotificationsService][scheduleLocalNotification] success", options);
        }, error => {
          this.logger.info("[VNCTalkNotificationsService][scheduleLocalNotification] error", error);
        });
      } else {
        if (window.appInBackground) {

          this.translate.get("HIN_NEW_MESSAGE").pipe(take(1)).subscribe(notificationText => {
            if (options.username) {
              options.username = notificationText;
            }
            if (options.groupName) {
              options.groupName = notificationText;
            }
            options.message = notificationText;

            window.FirebasePlugin.scheduleLocalNotification(options, () => {
              this.logger.info("[VNCTalkNotificationsService][scheduleLocalNotification] success", options);
            }, error => {
              this.logger.info("[VNCTalkNotificationsService][scheduleLocalNotification] error", error);
            });
          });
        }
      }
    }
  }

  scheduleCallNotification(msgid: string, eventType: string, target: string,
                          username: string, groupName: string, message: string,
                          receiver: string, vncInitiatorJid: string,
                         jitsiRoom: string, jitsiURL: string) {
    this.logger.info("[VNCTalkNotificationsService][scheduleCallNotification]", this.isGlobalMuteEnabled, this.notifyOption);

    if (this.isGlobalMuteEnabled || this.notifyOption === "0") {
      return;
    }

      if (window.FirebasePlugin && window.FirebasePlugin.scheduleCallNotification) {
        const options = {
          msgid,
          eventType,
          target,
          username,
          groupName,
          message,
          receiver,
          vncInitiatorJid,
          jitsiRoom,
          jitsiURL
        };
        this.logger.info("DEVICEINFO: ", device.platform, device.version, device);
      }

  }

  hideIncomingCallNotification(target: string, cancelCallkitCall = true) {
    this.logger.info("[VNCTalkNotificationsService][hideIncomingCallNotification]", target);

    if (window.FirebasePlugin && window.FirebasePlugin.hideIncomingCallNotification) {
      const options = {
        target
      };
      // if (device && (device.platform === "Android") && ((device.version === "12") || (device.version === "13"))) {
      if (device && (device.platform === "Android")) {
        window.FirebasePlugin.hideIncomingCallNotification(options, () => {
          this.logger.info("[VNCTalkNotificationsService][hideIncomingCallNotification] success");
        }, error => {
          this.logger.error("[VNCTalkNotificationsService][hideIncomingCallNotification] error", error);
        });
      }
    }
  }

  audioCall(title: string, content: string, bare: string, incomingCallSignal?: any) {
    // this.store.select(state => getConversationById(state, bare)).filter(c => !!c).take(1).subscribe(conv => {
      // this.logger.info("[audioCall]", conv, incomingCallSignal);
      const from = (incomingCallSignal && incomingCallSignal.from)
        ? this.contactRepo.getFullName(incomingCallSignal.from)
        : title;

      if (!this.isWindowFocused) {
        this.translate.get("INCOMING_AUDIO_CALL").pipe(take(1)).subscribe(titleName => {
          this.showChromeNotification(titleName, `by ${from}`, bare);
        });
      }
      this.logger.info("[audioCall] set", from, bare);
  }

  whiteBoard(title: string, content: string, bare: string, incomingCallSignal?: any) {
    // this.store.select(state => getConversationById(state, bare)).filter(c => !!c).take(1).subscribe(conv => {
      const from = (incomingCallSignal && incomingCallSignal.from)
        ? this.contactRepo.getFullName(incomingCallSignal.from)
        : title;

      if (!this.isWindowFocused) {
        this.translate.get("WHITEBOARD_INVITATION").pipe(take(1)).subscribe(titleName => {
          this.showChromeNotification(titleName, `by ${from}`, bare);
        });
      }

      this.set({
        title: from,
        content: "WHITEBOARD_INVITATION",
        type: "whiteboard",
        bare: bare,
        override: incomingCallSignal
      }, true);
    // });
  }

  videoCall(title: string, content: string, bare: string, incomingCallSignal?: any) {
    this.logger.info("[NotificationService][videoCall]", title, bare, content, incomingCallSignal);

    // this.store.select(state => getConversationById(state, bare)).filter(c => !!c).take(1).subscribe(conv => {
      const from = (incomingCallSignal && incomingCallSignal.from)
        ? this.contactRepo.getFullName(incomingCallSignal.from)
        : title;

      if (!this.isWindowFocused) {
        this.translate.get("INCOMING_VIDEO_CALL").pipe(take(1)).subscribe(titleName => {
          this.showChromeNotification(titleName, `by ${from}`, bare);
        });
      }
      this.set({ title: from, content: "INCOMING_VIDEO_CALL", type: "videocall", bare: bare, override: incomingCallSignal }, true);
    // });
  }

  meetingNotification(title: string, bare: string) {
    this.set({ title: title, type: "meeting", bare: bare}, true);
  }

  recordingNotification(override: any) {
    const data = {title: override.conv, content: "", bare: override.conv, type: "recording", override: override};
    this.logger.info("[recordingNotification]", data);
    this.set(data, true);
    this.broadcaster.broadcast("toggleHideVideoIOS", true);
  }

  screenShare(title: string, content: string, bare: string, override?: any) {
    this.logger.info("[NotificationService][screenShare]");

    // this.store.select(state => getConversationById(state, bare)).filter(c => !!c).take(1).subscribe(conv => {
      const from = (override && override.from)
        ? this.contactRepo.getFullName(override.from)
        : title;

      if (!this.isWindowFocused) {
        this.translate.get("SCREEN_SHARING").pipe(take(1)).subscribe(titleName => {
          this.showChromeNotification(titleName, `by ${from}`, bare);
        });
      }

      this.set({ title: from, content: "SCREEN_SHARING", type: "screen", bare: bare, override: override }, true);
    // });
  }

  activeCall(title: string, content: string, bare: string, override?: any) {
    return this.set({ title: title, content: content, type: "active", bare: bare, override: override }, true);
  }

  incomingCall(title: string, content: string, bare: string, override?: any) {
    return this.set({ title: title, content: content, type: "incoming", bare: bare, override: override }, true);
  }

  // With type method
  create(title: string, content: string, type: string, bare: string, override?: any) {
    return this.set({ title: title, content: content, type: type, bare: bare, override: override }, true);
  }

  // HTML Notification method
  // example: this.notificationsService.html("Ticket #123456", "Closed", "notify", {bgColor: "#fe5019", timeOut: 500000});
  html(title: string, html: any, type: string, override?: any) {
    return this.set({ title: title, html: html, type: type, override: override }, true);
  }

  // Remove all notifications method
  remove(id?: string, type?: string) {
    // console.trace("[NotificationService][remove]", id, type);

    if (id) {
      this.emitter.next({ command: "clean", id: id });
    } else if (type) {
      this.emitter.next({ command: "clean", type: type });
    } else {
      this.emitter.next({ command: "cleanAll" });
    }
  }

  private showChromeNotification(title: string, content: string, bare: string, type?: string) {
    if (document.hasFocus()) {
      return;
    }

    if (typeof ChromeNotification !== "undefined") {
      if (ChromeNotification.permission !== "granted") {
        ChromeNotification.requestPermission();
      } else {
        if ((this.id === bare) && (this.isWindowFocused)) {
          return;
        } else {
          this.store.select(getUserNotifyOption).pipe(take(1)).subscribe(value => {
            if (value && value !== "0") {
              const sticky = value === "2";
              let roomName = title;
              let silent = false;

              if (type) {
                this.store.select(state => getConversationById(state, bare)).pipe(take(1)).subscribe(conv => {
                  silent = conv?.mute_sound > 1;
                  this.logger.info("showChromeNotification for conv: ", conv);
                  this.logger.info("showChromeNotification silent: ", silent);
                  if (type !== "chat") {
                    roomName = conv ? conv.groupChatTitle : ConversationUtil.getGroupChatTitle(bare);
                  }
                });
              }

              if ( type && type === "rfc") {
                roomName = title;
              }

              if (!roomName && !!title)  {
                roomName = title;
              }

              const avatarUrl = this.avatarRepo.buildAvatarUrl(bare);
              this.logger.info("showChromeNotification avatar: ", avatarUrl);

              const notification = new ChromeNotification(roomName, {
                body: CommonUtil.getPlainTextWithOpts(content, {
                  ignoreHref: true,
                  ignoreImage: true
                }),
                silent: silent,
                icon: avatarUrl,
                requireInteraction: sticky
              });

              notification.onclick = () => {
                if (this.electronService.isElectron) {
                  this.electronService.showApp();
                } else {
                  window.focus();
                }
                this.logger.info("[notification.onclick]", bare, type);
                if (type) {
                  if (type === "groupchat" || type === "chat" ) {
                    this.store.dispatch(new SetActiveTab("chat"));
                  } else if (type === "mention") {
                    // this.conversationRepo.setUnreadIdsForTarget(bare, [messageId.toString()]);
                  }
                  if (type !== "rfc") {
                    this.conversationRepo.navigateToConversation(bare);
                    // this.conversationRepo.isRedirectedToChatViaChromeNotification = true;
                  }
                }
                notification.close();
              };

              if (!sticky) {
                setTimeout(() => notification.close(), 5000);
              }
            }
          });
        }
      }
    }
  }

  rfcNotification(title: string, content: string, bare: string, override?: any) {
    if (!this.isWindowFocused) {
      this.translate.get("RFC_INVITATION").pipe(take(1)).subscribe(titleName => {
        this.showChromeNotification(titleName, content, bare);
      });
    }
    content = content.replace("\n", "<br>");
    this.set({
      title: title,
      content: content,
      type: "rfc",
      bare: bare,
      override: override
    }, true);
  }

  channelNotification(notificationItem: NotificationCenterItem) {

    if (this.isGlobalMuteEnabled || this.notifyOption === "0") {
      return;
    }

    if (!this.isWindowFocused) {
      this.channelChromeNotification(notificationItem);
    }
    this.set({
      title: notificationItem.title,
      content: notificationItem.description,
      type: "channels",
      bare: "",
      override: notificationItem
    }, true);
  }

  channelChromeNotification(notificationItem: NotificationCenterItem) {
    if (document.hasFocus()) {
      return;
    }

    if (typeof ChromeNotification !== "undefined") {
      if (ChromeNotification.permission !== "granted") {
        ChromeNotification.requestPermission();
      } else {
          this.store.select(getUserNotifyOption).pipe(take(1)).subscribe(value => {
            if (value && value !== "0") {
              const sticky = value === "2";
              const notification = new ChromeNotification(notificationItem.title, {
                body: notificationItem.description.replace(/<b>/g, "").replace(/<\/b>/g, ""),
                requireInteraction: sticky
              });

              notification.onclick = () => {
                if (this.electronService.isElectron) {
                  this.electronService.showApp();
                } else {
                  window.focus();
                }
                this.broadcaster.broadcast("CLICK_CHANNEL_NOTIFICATION", notificationItem.object);
                notification.close();
              };

              if (!sticky) {
                setTimeout(() => notification.close(), 5000);
              }
            }
          });
        }
      }
    }
  }
