
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ActionReducerMap, createSelector } from "@ngrx/store";
import {
  _getBaseApiUrl,
  _getFirebaseToken,
  _getIsAppBootstrapped,
  _getIsAppOnline,
  _getIsConnectedXMPP,
  _getIsLoggedIn,
  _getIsLoggedInUserLoaded,
  _getIsLoggedInUserLoading,
  _getJID,
  _getLandingPageUrl,
  _getNotifyOption,
  _getSessionId,
  _getStatus,
  _getUserProfile,
  appReducer,
  AppState,
  _getIsWindowFocused,
  _getAppSettings,
  _getFontSize,
  _getIsDatabaseReady,
  _getLogoutOption,
  _getGlobalMute,
  _getNetworkInformation,
  _getDomain,
  _getFederatedApps,
  _getActiveTab,
  _getBare,
  _getUserConfig,
  _getInvitationConfig,
  _getMCBList,
  _getIsXmppSessionStarted,
  _getXmppOmemoInitState,
  _getCallLayout,
  _getSelectedProfile,
  _getSelectedLayout,
  _getLocalSearchLayout,
  _getActiveFilter,
  _getChatBackgrounds,
  _selectedChatBackgroundPreview,
  _chatBackgroundColorPreview
} from "./app";
import {
  _getContactBaresByGroupName,
  _getFailedPhotoForBareId,
  _getFailedPhotos,
  _getIsLoaded,
  _getIsLoading,
  _getLastPhotoUpdate,
  _getOnlineContactsBareId,
  _getPhotoForBareId,
  _getStatusForBareId,
  contactAdapter,
  contactReducer,
  ContactState,
  _getVCardForBareId,
  _getVCards
} from "./contact";
import { AppActionTypes } from "../actions/app";
import {
  _getAdvanceSearchRequest, _getSearchKeyword, _getSearchOffset, _getSearchResults, _getSearchSelectedMessageId,
  _getSearchTotal, searchReducer,
  SearchState,
  _getSearchResultTimeRange
} from "./search";
import { SearchMessageState, searchMessageReducer } from "../talk/reducers/search-message";
import { GroupState, groupReducer, groupAdapter } from "./group";


export interface RootState {
  app: AppState;
  contact: ContactState;
  group: GroupState;
  search: SearchState;
  searchMessage: SearchMessageState;
}

export const reducers: ActionReducerMap<RootState> = {
  app: appReducer,
  contact: contactReducer,
  group: groupReducer,
  search: searchReducer,
  searchMessage: searchMessageReducer
};

/**
 *  App Related Reducers
 */
export const getAppState = state => state.app;

export const getSessionId = createSelector(getAppState, _getSessionId);
export const getIsConnectedXMPP = createSelector(getAppState, _getIsConnectedXMPP);
export const getIsXmppSessionStarted = createSelector(getAppState, _getIsXmppSessionStarted);
export const getXmppOmemoInitState = createSelector(getAppState, _getXmppOmemoInitState);
export const IsDatabaseReady = createSelector(getAppState, _getIsDatabaseReady);

export const getUserJID = createSelector(getAppState, _getJID);
export const getBare = createSelector(getAppState, _getBare);
export const getUserStatus = createSelector(getAppState, _getStatus);
export const getUserNotifyOption = createSelector(getAppState, _getNotifyOption);

export const getUserProfile = createSelector(getAppState, _getUserProfile);
export const getIsLoggedInUserLoading = createSelector(getAppState, _getIsLoggedInUserLoading);
export const getIsLoggedInUserLoaded = createSelector(getAppState, _getIsLoggedInUserLoaded);
export const getIsLoggedIn = createSelector(getAppState, _getIsLoggedIn);

export const getLandingPageUrl = createSelector(getAppState, _getLandingPageUrl);
export const getBaseApiUrl = createSelector(getAppState, _getBaseApiUrl);
export const getFirebaseToken = createSelector(getAppState, _getFirebaseToken);
export const getIsAppBootstrapped = createSelector(getAppState, _getIsAppBootstrapped);
export const getIsAppOnline = createSelector(getAppState, _getIsAppOnline);
export const getIsWindowFocused = createSelector(getAppState, _getIsWindowFocused);
export const getSelectedProfile = createSelector(getAppState, _getSelectedProfile);

/**
 * Contact Related Reducers
 */
export const getContactState = state => state.contact;
export const {
  selectIds: getContactIds,
  selectEntities: getContactEntities,
  selectAll: getContacts,
  selectTotal: getTotalContact, // returns total count of contact
} = contactAdapter.getSelectors(getContactState);

export const getTotalContacts = getTotalContact;
export const getIsContactLoading = createSelector(getContactState, _getIsLoading);
export const getIsContactLoaded = createSelector(getContactState, _getIsLoaded);

export const getContactFailedPhotos = createSelector(getContactState, _getFailedPhotos);

export const getContactById = (state: RootState, bareId: string) => {
  return getContactEntities(state)[bareId];
};

export const getContactsByIds = (state: RootState, ids: string[]) => {
  return ids.map(id => getContactEntities(state)[id]).filter(v => !!v);
};

export const getContactPhotoById = (state: RootState, bareId: string) => {
  return _getPhotoForBareId(getContactState(state), bareId);
};

export const getContactVCardById = (state: RootState, bareId: string) => {
  return _getVCardForBareId(getContactState(state), bareId);
};

export const getVCards = createSelector(getContactState, _getVCards);

// export const getGroups = createSelector(getContactState, _getGroupList);

export const getMemberBaresByGroupName = createSelector(getContactState, _getContactBaresByGroupName);

export const getContactFailedPhotoById = (state: RootState, bareId: string) => {
  return _getFailedPhotoForBareId(getContactState(state), bareId);
};

export const getLastPhotoUpdate = (state: RootState, bareId: string) => {
  return _getLastPhotoUpdate(getContactState(state), bareId);
};

export const getContactStatusById = (state: RootState, bareId: string) => {
  return _getStatusForBareId(getContactState(state), bareId);
};

export const getOnlineContactsBareId = createSelector(getContactState, _getOnlineContactsBareId);

export const getMembersByGroupId = (state: RootState, groupId: number) => {
  const contacts = getContacts(state);

  return contacts.filter(c => {
    return c.groups && c.groups.filter(g => g.id === groupId).length > 0;
  });
};

export function clearState(reducer) {
  return function (state, action) {

    if (action.type === AppActionTypes.LOGOUT) {
      state = undefined;
    }

    return reducer(state, action);
  };
}


/**
 *  App Related Reducers
 */
export const getSearchState = state => state.search;

export const getAdvanceSearchRequest = createSelector(getSearchState, _getAdvanceSearchRequest);

export const getSearchKeyword = createSelector(getSearchState, _getSearchKeyword);
export const getSearchOffset = createSelector(getSearchState, _getSearchOffset);
export const getSearchTotal = createSelector(getSearchState, _getSearchTotal);

export const getSearchSelectedMessageId = createSelector(getSearchState, _getSearchSelectedMessageId);

export const getSearchResults = createSelector(getSearchState, _getSearchResults);
export const getSearchResultTimeRange = createSelector(getSearchState, _getSearchResultTimeRange);

export const getAppSettings = createSelector(getAppState, _getAppSettings);
export const getAppFontSize = createSelector(getAppState, _getFontSize);
export const getGlobalMute = createSelector(getAppState, _getGlobalMute);
export const getNetworkInformation = createSelector(getAppState, _getNetworkInformation);
export const getDomain = createSelector(getAppState, _getDomain);
export const getFederatedApps = createSelector(getAppState, _getFederatedApps);
export const getActiveTab = createSelector(getAppState, _getActiveTab);
export const getActiveFilter = createSelector(getAppState, _getActiveFilter);
export const getLogoutOption = createSelector(getAppState, _getLogoutOption);
export const getUserConfig = createSelector(getAppState, _getUserConfig);
export const getInvitationConfig = createSelector(getAppState, _getInvitationConfig);
export const getMCBList = createSelector(getAppState, _getMCBList);
export const getCallLayout = createSelector(getAppState, _getCallLayout);
export const getSelectedLayout = createSelector(getAppState, _getSelectedLayout);

export const getPadState = state => state.pad;
export const getPlainTarget = createSelector(getPadState, (state: any) => state.plainTarget);
export const getLocalSearchLayout = createSelector(getAppState, _getLocalSearchLayout);

export const getChatBackgrounds = createSelector(getAppState, _getChatBackgrounds);
export const selectedChatBackgroundPreview = createSelector(getAppState, _selectedChatBackgroundPreview);
export const chatBackgroundColorPreview = createSelector(getAppState, _chatBackgroundColorPreview);
/**
 * Group Related Reducers
 */
export const getGroupState = state => state.group;
export const {
  selectIds: getGroupIds,
  selectEntities: getGroupEntities,
  selectAll: getGroups,
  selectTotal: getTotalGroup, // returns total count of group
} = groupAdapter.getSelectors(getGroupState);

export const getIsGroupLoading = createSelector(getGroupState, _getIsLoading);
export const getIsGroupLoaded = createSelector(getGroupState, _getIsLoaded);

export const getGroupById = (state: RootState, bareId: string) => {
  return getGroupEntities(state)[bareId];
};

export const getGroupsByIds = (state: RootState, ids: string[]) => {
  return ids.map(id => getGroupEntities(state)[id]).filter(v => !!v);
};

export const getGroupsByName = (state: RootState, name: string) => {
  const groups = getGroups(state);
  return groups.filter(g => g.name.toLowerCase() === name.toLowerCase());
};

export const getGroupNameExists = (state: RootState, name: string) => {
  const groups = getGroups(state);
  return groups.filter(g => g.name.toLowerCase() === name.toLowerCase()).length > 0;
};
