import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class RedirectInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.includes("/api/attachments")) {
      const newUrl = request.url.replace("http://localhost:3000", "http://localhost:8000");
      const newRequest = request.clone({
        url: newUrl
      });
      return next.handle(newRequest);
    } else {
      return next.handle(request);
    }
  }
}
