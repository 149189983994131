<div class="archive-topic-container">
  <div class="topic-search-box">
    <vnc-input leadingIconName="mdi-magnify" placeholder="{{ 'SEARCH' | translate }}"
      [form]="searchControl"></vnc-input>
  </div>
  <div class="topics-bulk-option">
    <p>{{(totalTopics | async)}} topics</p>
    <div class="filter-section">
      <mat-select panelClass="select-group-by" id="select-group-by" [formControl]="groupControl">
        <mat-option [value]="groupType.DATE_ASC">
          <div class="main-justify">
            <div>
              <!-- {{ 'SORT_BY_ASC' | translate }} -->
              <span>{{ 'SORT_BY_ASC' | translate }}</span>
            </div>
            <div class="icon-item">
              <mat-icon *ngIf="groupControl.value === groupType.DATE_ASC" class="checked-icon" fontSet="mdi"
                fontIcon="mdi-check"></mat-icon>
            </div>
          </div>
        </mat-option>
        <mat-option [value]="groupType.DATE_DESC">
          <div class="main-justify">
            <div>
              <span>{{ 'SORT_BY_DESC' | translate }}</span>
            </div>
            <div class="icon-item">
              <mat-icon *ngIf="groupControl.value === groupType.DATE_DESC" class="checked-icon" fontSet="mdi"
                fontIcon="mdi-check"></mat-icon>
            </div>
          </div>
        </mat-option>
      </mat-select>
      <button class="grid-btn" mat-icon-button *ngIf="currentView === 'grid'" [matMenuTriggerFor]="menu">
        <vnc-icons-view-tile-grid [size]="1" [colorConfig]="viewTileIconColor"></vnc-icons-view-tile-grid>
      </button>
      <button class="list-btn" mat-icon-button *ngIf="currentView === 'list'" [matMenuTriggerFor]="menu">
        <vnc-icons-view-tile-list [size]="1" [colorConfig]="viewTileIconColor"></vnc-icons-view-tile-list>
      </button>
      <button class="table-btn" mat-icon-button *ngIf="currentView === 'table'" [matMenuTriggerFor]="menu">
        <ng-container *ngTemplateOutlet="tableIcon">
        </ng-container>
      </button>
    </div>
  </div>

  <div [class.is-left-bar-expanded]="isLeftBarExpanded && !isRightBarExpanded"
    [class.is-right-bar-expanded]="isRightBarExpanded && !isLeftBarExpanded" class="vnc-kh-show-loader">
    <vnc-loader-circular *ngIf="(topics | async)?.length===0 && !dismissLoader" [color]="'white'" [size]="'s'">
    </vnc-loader-circular>
  </div>
  <div class="archive-topics-list display-grid vnc-scroll-bar--medium--vertical"
    [ngClass]="{'list-view': currentView === uiTopicView.LIST}" vpInfiniteScroll scrollPercentage="0.8"
    *ngIf="data.sort !== groupType.CHANNEL_ASC && data.sort !== groupType.CHANNEL_DESC && data.sort !== groupType.AUTHOR_ASC && data.sort !== groupType.AUTHOR_DESC && currentView !== uiTopicView.TABLE"
    (onUserScroll)="loadMoreTopics()">
    <div class="topic-box" *ngFor="let topic of (topics | async)">
      <div>
        <div class="hero-image-wrap" *ngIf="topic.heroAttachments && topic.heroAttachments.length > 0">
          <vnc-hero-image [attachments]="topic.heroAttachments" [height]="heroImageHeight"
            [thumbnailSize]="800"></vnc-hero-image>
        </div>
        <img *ngIf="topic?.heroAttachments?.length === 0 && (topic?.default_cover_url)" [style.height]="heroImageHeight"
          class="default-cover" [src]="topic?.default_cover_url" alt="">
        <vnc-topic-item class="archive-topic"
          [hasHeaderImage]="(topic?.heroAttachments?.length > 0 || topic?.default_cover_url)"
          (click)="openTopic(topic, topicCommentScroll.NONE)" [showPlainText]="true" [item]="topic"
          [menuTemplate]="menuTemplate" (commentForm)="openTopic(topic, topicCommentScroll.COMMENT_FORM)"
          (comment)="openTopic(topic, topicCommentScroll.COMMENT_ITEMS)"
          (seeMore)="openTopic(topic, topicCommentScroll.NONE)" (like)="likesTopic($event)">
        </vnc-topic-item>
      </div>
    </div>
  </div>
  <div class="table-view vnc-scroll-bar--medium--vertical" vpInfiniteScroll scrollPercentage="0.8"
    *ngIf="data.sort !== groupType.CHANNEL_ASC && data.sort !== groupType.CHANNEL_DESC && data.sort !== groupType.AUTHOR_ASC && data.sort !== groupType.AUTHOR_DESC && currentView === uiTopicView.TABLE"
    (onUserScroll)="loadMoreTopics()">
    <div class="topics-table">
      <vp-topic-table-header [sortColumn]="false"></vp-topic-table-header>
      <ng-container *ngFor="let topic of (topics | async) let index = index">
        <vp-topic-table-row id="topic-{{topic.id}}" [topic]="topic" [serialNumber]="index + 1"
          [menuTemplate]="menuTemplate" (commentForm)="openTopic(topic, topicCommentScroll.COMMENT_FORM)"
          (comment)="openTopic(topic, topicCommentScroll.COMMENT_ITEMS)" (like)="likesTopic($event)"
          (seeMore)="openTopic(topic, topicCommentScroll.NONE)"
          (click)="openTopic(topic, topicCommentScroll.NONE)"></vp-topic-table-row>
      </ng-container>
    </div>
  </div>

  <div class="archive-topics-list vnc-scroll-bar--medium--vertical" vpInfiniteScroll scrollPercentage="0.8"
    *ngIf="data.sort === groupType.CHANNEL_ASC || data.sort === groupType.CHANNEL_DESC"
    (onUserScroll)="loadMoreTopics()">
    <vp-topic-table-header [sortColumn]="false" *ngIf="currentView === uiTopicView.TABLE"></vp-topic-table-header>
    <div class="channel-with-topics" *ngFor="let channel of channelsGroup">
      <div class="channel-details">
        <div class="img-box">
          <vnc-avatar [defaultAvatar]="defaultChannelAvatar"
            [imgSrc]="topicsGroupedByChannel[channel].channelData?.avatar_url || ''"></vnc-avatar>
          <!--          <img [src]="topicsGroupedByChannel[channel].channelData?.avatar_url" alt="">-->
        </div>
        <div class="channel-and-author-name">
          <span>{{topicsGroupedByChannel[channel].channelData?.author?.name}}</span>
          <span>{{topicsGroupedByChannel[channel].channelData?.name}}</span>
        </div>
      </div>
      <div class="topics-box" [ngClass]="{'list-view': currentView === uiTopicView.LIST}"
        *ngIf="data.sort !== groupType.AUTHOR_ASC && data.sort !== groupType.AUTHOR_DESC && currentView === uiTopicView.LIST || currentView === uiTopicView.GRID">
        <ng-container *ngFor="let topic of topicsGroupedByChannel[channel].topics">
          <div class="topic-box">
            <div class="hero-image-wrap" *ngIf="topic.heroAttachments && topic.heroAttachments.length > 0">
              <vnc-hero-image [attachments]="topic.heroAttachments" [height]="heroImageHeight"
                [thumbnailSize]="800"></vnc-hero-image>
            </div>
            <img *ngIf="topic?.heroAttachments?.length === 0 && (topic?.default_cover_url)"
              [style.height]="heroImageHeight" class="default-cover" [src]="topic?.default_cover_url" alt="">
            <vnc-topic-item class="archive-topic"
              [hasHeaderImage]="(topic?.heroAttachments?.length > 0 || topic?.default_cover_url)"
              (click)="openTopic(topic, topicCommentScroll.NONE)" [showPlainText]="true" [item]="topic"
              [menuTemplate]="menuTemplate" (commentForm)="openTopic(topic, topicCommentScroll.COMMENT_FORM)"
              (comment)="openTopic(topic, topicCommentScroll.COMMENT_ITEMS)"
              (seeMore)="openTopic(topic, topicCommentScroll.COMMENT_ITEMS)" (like)="likesTopic($event)">
            </vnc-topic-item>
          </div>
        </ng-container>
      </div>
      <div class="table-view vnc-scroll-bar--medium--vertical" vpInfiniteScroll scrollPercentage="0.8"
        *ngIf="data.sort !== groupType.AUTHOR_ASC && data.sort !== groupType.AUTHOR_DESC && currentView === uiTopicView.TABLE"
        (onUserScroll)="loadMoreTopics()">
        <div class="topics-table">
          <ng-container *ngFor="let topic of topicsGroupedByChannel[channel].topics let index = index">
            <vp-topic-table-row id="topic-{{topic.id}}" [topic]="topic" [serialNumber]="index + 1"
              [menuTemplate]="menuTemplate" (commentForm)="openTopic(topic, topicCommentScroll.COMMENT_FORM)"
              (comment)="openTopic(topic, topicCommentScroll.COMMENT_ITEMS)" (like)="likesTopic($event)"
              (seeMore)="openTopic(topic, topicCommentScroll.NONE)"
              (click)="openTopic(topic, topicCommentScroll.NONE)"></vp-topic-table-row>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #menuTemplate let-item="item">
  <div class="item-drop-down">
    <vp-dropdown-item [itemType]="'icon-drop-default'" (onClickEvent)="channelDetailService.showTopicInfo(item)"
      [prefixIconTemplate]="infoImage" label="{{'TOPIC_INFO' | translate }}">
    </vp-dropdown-item>
  </div>
  <div class="item-drop-down"
    *ngIf="item?.archived && item.permissions && item.permissions[1] && item.permissions[1].editable">
    <vp-dropdown-item [itemType]="'icon-drop-default'" (onClickEvent)="unArchiveTopic(item)"
      [prefixIconTemplate]="unArchiveIcon" label="{{'UNARCHIVE_TOPIC' | translate }}">
    </vp-dropdown-item>
  </div>
  <div class="item-drop-down" *ngIf="item.permissions && item.permissions[0] && item.permissions[0].deletable">
    <vp-dropdown-item [itemType]="'icon-drop-default'" (onClickEvent)="permanentlyDeleteTopic(item)"
      [prefixIconTemplate]="deleteIcon" label="{{'DELETE_TOPIC_COMPLETELY' | translate }}">
    </vp-dropdown-item>
  </div>
</ng-template>

<mat-menu class="vnc-list-item-menu menu-width-240 view-change-menu" #menu="matMenu">
  <button class="grid-btn" mat-menu-item (click)="changeView(uiTopicView.GRID)"
    [class.active]="currentView === uiTopicView.GRID">
    <vnc-icons-view-tile-grid [size]="1" [colorConfig]="viewTileIconColor"
      [isActive]="currentView === uiTopicView.GRID"></vnc-icons-view-tile-grid>
    <span>{{ 'GRID' | translate }}</span>
    <mat-icon class="check-icon" fontSet="mdi" fontIcon="mdi-check"
      [ngStyle]="{'opacity': currentView === uiTopicView.GRID ? 1 : 0}"></mat-icon>
  </button>
  <button class="list-btn" mat-menu-item (click)="changeView(uiTopicView.LIST)"
    [class.active]="currentView === uiTopicView.LIST">
    <vnc-icons-view-tile-list [size]="1" [colorConfig]="viewTileIconColor"
      [isActive]="currentView === uiTopicView.LIST"></vnc-icons-view-tile-list>
    <span>{{ 'TILE' | translate }}</span>
    <mat-icon class="check-icon" fontSet="mdi" fontIcon="mdi-check"
      [ngStyle]="{'opacity': currentView === uiTopicView.LIST ? 1 : 0}"></mat-icon>
  </button>
  <button class="table-btn" mat-menu-item (click)="changeView(uiTopicView.TABLE)"
    [class.active]="currentView === uiTopicView.TABLE">
    <ng-container *ngTemplateOutlet="tableIcon">
    </ng-container>
    <span>{{ 'TABLE' | translate }}</span>
    <mat-icon class="check-icon" fontSet="mdi" fontIcon="mdi-check"
      [ngStyle]="{'opacity': currentView === uiTopicView.TABLE ? 1 : 0}"></mat-icon>
  </button>
</mat-menu>
<ng-template #unArchiveIcon>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/product/archive-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M18.6666667,3 C19.9344145,3 20.9184865,4.06669955 20.9951839,5.36090909 L21,5.52380952 L21,18.3673469 C21,19.725145 20.0830737,20.9022238 18.8255848,20.9942177 L18.6666667,21 L5.33333333,21 C4.04846493,21 3.08014386,19.8772331 3.00473396,18.536065 L3,18.3673469 L3,5.52380952 C3,4.21209774 3.9333621,3.09292972 5.17638745,3.00549502 L5.33333333,3 L18.6666667,3 Z M19,9.571 L5,9.571 L5,18.3673469 C5,18.669907 5.12036977,18.8845341 5.23708236,18.9649827 L5.28651654,18.9911109 L5.33333333,19 L18.6666667,19 C18.7860931,19 18.9565804,18.8074886 18.992992,18.4914971 L19,18.3673469 L19,9.571 Z M14.25,11 C14.6642136,11 15,11.3459618 15,11.7727273 C15,12.1994928 14.6642136,12.5454545 14.25,12.5454545 L9.75,12.5454545 C9.33578644,12.5454545 9,12.1994928 9,11.7727273 C9,11.3459618 9.33578644,11 9.75,11 L14.25,11 Z M18.6666667,5 L5.33333333,5 C5.19805449,5 5.03989688,5.16357329 5.00642086,5.4224126 L5,5.52380952 L5,7.571 L19,7.571 L19,5.52380952 C19,5.27741957 18.8904187,5.09743045 18.7701099,5.02960269 L18.7181805,5.00753409 L18.6666667,5 Z"
        id="Combined-Shape" fill="#8B96A0"></path>
    </g>
  </svg>
</ng-template>
<ng-template #archiveIcon>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/product/archive-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M18.6666667,3 C19.9344145,3 20.9184865,4.06669955 20.9951839,5.36090909 L21,5.52380952 L21,18.3673469 C21,19.725145 20.0830737,20.9022238 18.8255848,20.9942177 L18.6666667,21 L5.33333333,21 C4.04846493,21 3.08014386,19.8772331 3.00473396,18.536065 L3,18.3673469 L3,5.52380952 C3,4.21209774 3.9333621,3.09292972 5.17638745,3.00549502 L5.33333333,3 L18.6666667,3 Z M19,9.571 L5,9.571 L5,18.3673469 C5,18.669907 5.12036977,18.8845341 5.23708236,18.9649827 L5.28651654,18.9911109 L5.33333333,19 L18.6666667,19 C18.7860931,19 18.9565804,18.8074886 18.992992,18.4914971 L19,18.3673469 L19,9.571 Z M14.25,11 C14.6642136,11 15,11.3459618 15,11.7727273 C15,12.1994928 14.6642136,12.5454545 14.25,12.5454545 L9.75,12.5454545 C9.33578644,12.5454545 9,12.1994928 9,11.7727273 C9,11.3459618 9.33578644,11 9.75,11 L14.25,11 Z M18.6666667,5 L5.33333333,5 C5.19805449,5 5.03989688,5.16357329 5.00642086,5.4224126 L5,5.52380952 L5,7.571 L19,7.571 L19,5.52380952 C19,5.27741957 18.8904187,5.09743045 18.7701099,5.02960269 L18.7181805,5.00753409 L18.6666667,5 Z"
        id="Combined-Shape" fill="#8B96A0"></path>
    </g>
  </svg>
</ng-template>
<ng-template #infoImage>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/product/info-outline" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M11,17 L13,17 L13,11 L11,11 L11,17 L11,17 Z M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 L12,2 Z M12,20 C7.59,20 4,16.41 4,12 C4,7.59 7.59,4 12,4 C16.41,4 20,7.59 20,12 C20,16.41 16.41,20 12,20 L12,20 Z M11,9 L13,9 L13,7 L11,7 L11,9 L11,9 Z"
        id="icon" fill="#8B96A0"></path>
    </g>
  </svg>
</ng-template>
<ng-template #deleteIcon>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/product/delete-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M18,7 L18,18.2857143 C18,19.7746499 16.8086874,21 15.3333333,21 L15.3333333,21 L8.66666667,21 C7.19131256,21 6,19.7746499 6,18.2857143 L6,18.2857143 L6,7 L18,7 Z M16,9 L8,9 L8,18.2857143 C8,18.649428 8.25938319,18.9465317 8.57834889,18.9935231 L8.57834889,18.9935231 L8.66666667,19 L15.3333333,19 C15.6913126,19 16,18.6824929 16,18.2857143 L16,18.2857143 L16,9 Z M13,3 C13.7404864,3 14.3869825,3.40242038 14.7327215,4.00049436 L18,4 C18.5522847,4 19,4.44771525 19,5 C19,5.55228475 18.5522847,6 18,6 L6,6 C5.44771525,6 5,5.55228475 5,5 C5,4.44771525 5.44771525,4 6,4 L9.26760632,3.99992752 C9.61342606,3.40216612 10.2597476,3 11,3 L13,3 Z"
        id="Combined-Shape" fill="#8B96A0"></path>
    </g>
  </svg>
</ng-template>
<ng-template #tableIcon>
  <svg width="19px" height="18px" viewBox="0 0 19 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <filter x="-28.8%" y="-55.6%" width="157.5%" height="211.3%" filterUnits="objectBoundingBox" id="filter-1">
        <feOffset dx="0" dy="8" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
        <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0.031372549   0 0 0 0 0.176470588   0 0 0 0 0.239215686  0 0 0 0.2 0"
          type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
        <feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter2"></feOffset>
        <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter2" result="shadowBlurOuter2"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0.0311856736   0 0 0 0 0.177658465   0 0 0 0 0.240432518  0 0 0 0.1 0"
          type="matrix" in="shadowBlurOuter2" result="shadowMatrixOuter2"></feColorMatrix>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
          <feMergeNode in="shadowMatrixOuter2"></feMergeNode>
          <feMergeNode in="SourceGraphic"></feMergeNode>
        </feMerge>
      </filter>
    </defs>
    <g id="Talk" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="0240.-VNCtalk--Channels--Panel-#23" transform="translate(-1254.000000, -189.000000)" fill="#337CBD">
        <g id="Group-13" transform="translate(1239.500000, 100.000000)" filter="url(#filter-1)">
          <g id="Stacked-Group" transform="translate(0.000000, 8.000000)">
            <g id="Dropdown/item/@m/icon-drop-default" transform="translate(0.000000, 72.000000)">
              <g id="Container/18px" transform="translate(12.000000, 6.000000)">
                <g id="Icon/product/list" transform="translate(3.000000, 3.000000)">
                  <g id="Icon-24px" transform="translate(2.250000, 5.250000)">
                    <path
                      d="M0,4.5 L1.5,4.5 L1.5,3 L0,3 L0,4.5 L0,4.5 Z M0,7.5 L1.5,7.5 L1.5,6 L0,6 L0,7.5 L0,7.5 Z M0,1.5 L1.5,1.5 L1.5,0 L0,0 L0,1.5 L0,1.5 Z M3,4.5 L13.5,4.5 L13.5,3 L3,3 L3,4.5 L3,4.5 Z M3,7.5 L13.5,7.5 L13.5,6 L3,6 L3,7.5 L3,7.5 Z M3,0 L3,1.5 L13.5,1.5 L13.5,0 L3,0 L3,0 Z"
                      id="Shape"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</ng-template>
<ng-template #cloneIcon>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g fill="none" fill-rule="evenodd">
      <g fill="#8B96A0">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M12 2.25c.825 0 1.5.675 1.5 1.5v10.5c0 .825-.675 1.5-1.5 1.5H3.75c-.825 0-1.5-.675-1.5-1.5V3.75c0-.825.675-1.5 1.5-1.5zm0 1.5H3.75v10.5H12V3.75zM8.952 0c.414 0 .75.336.75.75 0 .385-.29.702-.663.745l-.087.005H3.25c-.926 0-1.684.72-1.746 1.63l-.004.12v8.762c0 .415-.336.75-.75.75-.385 0-.702-.29-.745-.662L0 12.012V3.25C0 1.508 1.37.086 3.093.004L3.25 0h5.702z"
                    transform="translate(-1121.000000, -109.000000) translate(335.000000, 100.000000) translate(741.000000, 6.000000) translate(42.000000, 0.000000) translate(3.000000, 3.000000) translate(2.250000, 1.500000)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</ng-template>