
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { JID } from "./jid.model";

export interface Message {
  cannotDecrypt?: boolean;
  id?: string; // Unique identifier
  isForwarded?: boolean; // uncertain for future use.

  timestamp?: number;
  decryptAttempt?: number;
  backupOMEMO?: any;
  delay?: any;
  body: string;
  x_attachment?: any;
  attachment?: {
    fileName: string,
    fileSize: number,
    fileType: string,
    recodingRequired?: boolean,
    recodingProgress?: number,
    url: string,
  };
  group_action?: any;
  notification?: {
    type: string,
    target: string,
    action: string,
    content: string,
    jid?: string
  };
  location?: {
    lat: string,
    lng: number,
    label: string,
    address: string,
  };
  conferenceResponse?: {
    receiver: string,
    reason: string
  };
  replace?: {
    id: string
  };
  x?: any;
  expiry?: any;
  carbonReceived?: any;
  noStore?: boolean;
  muc?: any;
  vncTalkConference?: VNCTalkConference;
  vncTalkConferenceScheduler?: any;
  vncTalkMuc?: VNCTalkMuc;
  originalMessage?: OriginalMessage;
  forwardMessage?: ForwardMessage;
  startFile?: {
    type: string
  };
  room?: string;
  mucInvite?: any;
  carbon?: boolean;
  type?: string; // "chat"/"groupchat"/"CHAT.JOIN"/"CHAT.KICK"/"CHAT.LEAVE"
  from?: JID;
  fromJid?: string;
  to?: any;
  nick?: string; // TODO: remove later
  isDeleted?: boolean;
  isStarred?: boolean;
  file?: any;
  signal?: any;
  fileNameOnDisk?: any;
  uploadFileInProgress?: boolean;
  chatState?: string; // "inactive", "paused", "composing"
  pendingIn?: string; // conversationTarget. appending by frontend to maintain pending messages in store
  tags?: string[];
  // appending by frontend. true indicates that message has been delivered. If null treat status as DELIVERED
  status?: MessageStatus;
  receipt?: string;
  requestReceipt?: boolean;
  checkedVideo?: boolean;
  sendingAt?: number;
  references?: {
    begin?: string,
    end?: string,
    type: string,
    uri: string
  }[];
  vncTalkBroadcast?: VNCTalkBroadcast;
  broadcast?: string;
  sort_id?: number;
  broadcast_id?: string;
  broadcast_title?: string;
  broadcast_owner?: string;
  renderedContent?: any;
  cachedContent?: string;
  htmlBody?: string;
  html?: {
    body: string
  };

  urlPreviewData?: {
    background?: string,
    title?: string,
    description?: string,
  };
  redminePreview?: any,
  versionPreview?: any,

  // omemo
  encrypted?: any;
  encryption?: any;
  omemoHistoryExchangeSignal?: any,

  recording_notify?: {
    file: string,
    conv: string,
    callId: string
  };
  error?: {
    type: string,
    condition: string
  }

  isDifferentSenderThanLastMessage?: boolean;
  isDifferentDayThanLastMessage?: boolean;
  highlighted?: boolean;
  time?: any;
  stamp?: any;
  reactions?: any;
  convTarget?: string;
  conversationTarget?: string;
}

export interface VNCTalkConference {
  id?: string;
  from: string;
  to: string;
  conferenceId: string;
  oldConferenceId?: string;
  jitsiRoom?: string;
  jitsiXmppUrl?: string;
  jitsiXmppPort?: string;
  jitsiURL?: string;
  reason?: string;
  conferenceType: string;
  duration?: string;
  eventType: string;
  timestamp?: string;
  skipAddUserToChatWhenAddToCall?: boolean;
}

export interface VNCTalkBroadcast {
  to: string[];
  roster?: string[];
  title?: string;
  origtarget?: string;
  broadcastAudienceId?: string;
  avatarup?: boolean;
}

export interface VNCTalkMuc {
  id?: string;
  from: string;
  to: string;
  conferenceId: string;
  eventType: string;
}

export interface OriginalMessage {
  id: string;
  from: string;
  body: string;
  htmlBody?: string;
  attachment?: string;
  location?: string;
  replyMessage: string;
  broadcast_title?: string;
  broadcast_owner?: string;
  broadcast_id?: string;
  timestamp?: number;
  reactions?: any;
}

export interface ForwardMessage {
  id?: string;
  from: string;
  timestamp?: number;
}
export enum MessageStatus {
  PENDING,
  SENDING,
  SENT,
  DELIVERED,
  FAILED
}

export enum GroupAction {
  UPDATE_AVATAR = "UPDATE_AVATAR",
  ADD_PARTICIPANTS = "ADD_PARTICIPANTS",
  UPDATE_ENCRYPTION = "UPDATE_ENCRYPTION",
  GROUP_UPDATE = "GROUP_UPDATE",
  LEAVE_GROUP = "LEAVE_GROUP"
}
