import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DefaultCover } from "app/channels/models/default-cover.model";
import { Subject, take, takeUntil } from "rxjs";
import { VNCImageCropperComponent } from "vnc-library";
import { Broadcaster } from "../shared/providers";
import { CommonUtil } from "../utils/common.util";
import { environment } from "app/environments/environment";

@Component({
    selector: "vp-chat-avatar-upload-dialog",
    templateUrl: "./chat-upload-avatar.component.html",
    styleUrls: ["./chat-upload-avatar.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ChatUploadAvatarComponent implements OnInit, OnDestroy {
    @ViewChild(VNCImageCropperComponent) vncImageCropperComponent: VNCImageCropperComponent;
    imageChangedEvent: any = "";
    croppedImage: any = "";
    canvasRotation = 0;
    rotation = 0;
    scale = 0.9;
    containWithinAspectRatio = true;
    showDefaultAvatar: boolean = false;
    oldAvatarUrl: string = "";
    defaultCovers: DefaultCover[] = [];
    private isAlive$ = new Subject<boolean>();
    indexItem: number;
    defaultURL: string = "";
    hideCropper: boolean = false;
    hideRightBar: any;
    isMobileScreen: boolean;
    inputConfig = {
        width: 350,
        height: 350,
    };
    visible: boolean = false;
    externalCover: DefaultCover[] = [];
    externalIndex: number;
    disableButton: boolean = false;
    constructor(
        private matDialogRef: MatDialogRef<ChatUploadAvatarComponent>,
        private broadcaster: Broadcaster,
        private breakpointObserver: BreakpointObserver,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private changeDetection: ChangeDetectorRef,
    ) {
        this.defaultURL = this.data.defaultURL;
        this.hideRightBar = this.data.hideRightBar;
        this.hideCropper = true;
        this.broadcaster.on("hideChatUploadAvatarComponent").pipe(take(1)).subscribe(() => {
            this.matDialogRef.close();
        });
        this.broadcaster.on("hideCropAvatar").pipe(take(1)).subscribe(() => {
            this.hideCropper = true;
            this.changeDetection.markForCheck();
        });
        this.changeDetection.markForCheck();
    }

    @HostListener("document:click", ["$event"])
    public onClick(event: Event): void {
        const target = event.target as HTMLElement;
        if(target.classList.contains("LyImageCropper-defaultContent-f")) this.selectPhoto();

    }

    ngOnInit() {
        for (let i = 1; i < 15; i++) {
            const text = i.toString().padStart(3, "0");
            this.defaultCovers.push({
                name: `default-pattern-sq-${text}.png`,
                url: CommonUtil.getFullUrl(`/assets/avatar/default-pattern-sq-${text}.png`),
            });
        }
        this.changeDetection.markForCheck();
        this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 767px)");
        this.breakpointObserver
        .observe(["(max-width: 767px)"])
        .pipe(takeUntil(this.isAlive$))
        .subscribe((state: BreakpointState) => {
            if (state.matches) {
            this.isMobileScreen = true;
            } else {
                this.isMobileScreen = false;
            }

            setTimeout(() => {
                this.vncImageCropperComponent.cropper.loadImage({ originalDataURL: this.defaultURL });
            }, 50);
            setTimeout(() => {
                this.visible = true;
            }, 1000);
        });
        if(environment.isCordova){
            StatusBar.backgroundColorByHexString("#000000");
            StatusBar.styleBlackTranslucent();
        }
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.complete();
        if (environment.isCordova) {
          StatusBar.backgroundColorByHexString("#317bbc");
        }
    }

    close(): void {
        this.matDialogRef.close();
    }


    selectPhoto() {
        if (!!document.getElementById("custom-input-select-avatar")) {
            (<HTMLElement>document.getElementById("custom-input-select-avatar")).click();
        }
    }

    fileChangeEvent(event: any): void {
        this.hideCropper = false;
        this.imageChangedEvent = event;
    }

    imageCropped(event: any) {
        this.croppedImage = event.dataURL;
        this.updateAvatar();
    }

    selectNewImage($event) {
        const source = $event.srcElement || $event.currentTarget;
        if (!source?.files?.length) return;
        const url: string = URL.createObjectURL(source.files[0]);
        this.externalCover.push({
            name: "external-" + Math.floor(Math.random() * 100).toString(),
            url: url,
        });
        const selectImage: number = this.externalCover.length - 1;
        this.selectIndexItem(selectImage, true);
    }

    removeExternalAvatar(index) {
        this.externalCover.splice(index, 1);
        this.vncImageCropperComponent.cropper.loadImage({ originalDataURL: null });
        this.hideCropper = true;
    }

    removeSelectedImage() {
        this.hideCropper = true;
        this.vncImageCropperComponent.cropper.loadImage({ originalDataURL: "" });
    }

    cropImage() {
        this.vncImageCropperComponent.cropImage();
    }

    loadedImageForCrop($event) {
        this.hideCropper = false;
        this.scale = this.vncImageCropperComponent.cropper.scale;
    }

    updateAvatar() {
        if (this.croppedImage) {
            const b64Data = this.croppedImage;
            const photo: any = {
                type: "image/png",
                data: b64Data
            };
            if (this.matDialogRef && this.matDialogRef.close) {
                this.matDialogRef.close({ photo: photo });
            } else {
                this.matDialogRef.close({ photo: photo });
            }
        } else {
            if (this.matDialogRef && this.matDialogRef.close) {
                this.matDialogRef.close({ photo: {} });
            } else {
                this.matDialogRef.close({ photo: {} });
            }
        }
    }

    selectIndexItem(index: number, external:boolean = false): void {
        this.hideCropper = false;
        this.externalIndex = null;
        this.indexItem = null;
        this.disableButton = true;
        if (external) this.externalIndex = index;
        else this.indexItem = index;
        setTimeout(() => {
            if (external) this.defaultURL = CommonUtil.getAttachmentLocalAPIURL(this.externalCover[index].url);
            else this.defaultURL = CommonUtil.getAttachmentLocalAPIURL(this.defaultCovers[index].url);
            this.vncImageCropperComponent.cropper.loadImage({originalDataURL: this.defaultURL});
            this.disableButton = false;
        }, 1000);
        this.changeDetection.markForCheck();
    }

    randomNumber(min, max): number {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    errorUploadedImage(): void {
        if (!this.isMobileScreen) {
            if (!!this.defaultCovers && this.defaultCovers.length > 0) {
                this.indexItem = this.randomNumber(1, 12);
                this.defaultURL = CommonUtil.getAttachmentLocalAPIURL(this.defaultCovers[this.indexItem].url);
                this.hideCropper = false;
                this.changeDetection.markForCheck();
            } else {
                this.defaultURL = null;
                this.changeDetection.markForCheck();
            }
        } else {
            this.defaultURL = null;
            this.changeDetection.markForCheck();
        }

    }
}
