
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Directive, ElementRef, ComponentFactoryResolver, ViewContainerRef, TemplateRef, HostListener, ChangeDetectorRef } from "@angular/core";
import { Input, EventEmitter, Output, OnChanges, SimpleChanges } from "@angular/core";

import { MentionConfig } from "./mention-config";
import { MentionListComponent } from "./mention-list.component";
import { getValue, insertValue, getCaretPosition, setCaretPosition } from "./mention-utils";
import { environment } from "app/environments/environment";
import { CommonUtil } from "app/talk/utils/common.util";

const KEY_BACKSPACE = 8;
const KEY_TAB = 9;
const KEY_ENTER = 13;
const KEY_SHIFT = 16;
const KEY_ESCAPE = 27;
const KEY_LEFT = 37;
const KEY_UP = 38;
const KEY_RIGHT = 39;
const KEY_DOWN = 40;

@Directive({
  selector: "[vpMention]"
})
export class MentionDirective implements OnChanges {
  // stores the items passed to the mentions directive and used to populate the root items in mentionConfig
  private mentionItems: any[];
  searchUsers$: any;

  @Input("vpMention") set mention(items: any[]) {
    this.mentionItems = items;
  }
  @Input() editor: any;
  // the provided configuration object
  @Input() mentionConfig: MentionConfig = { items: [] };

  private activeConfig: MentionConfig; // = this.DEFAULT_CONFIG;

  private DEFAULT_CONFIG: MentionConfig = {
    items: [],
    triggerChar: "@",
    labelKey: "label",
    maxItems: -1,
    mentionSelect: (item: any) => this.activeConfig.triggerChar + item[this.activeConfig.labelKey]
  };

  // template to use for rendering list items
  @Input() mentionListTemplate: TemplateRef<any>;

  // event emitted whenever the search term changes
  @Output() searchTerm = new EventEmitter();
  @Output() textChange = new EventEmitter();

  // option to diable internal filtering. can be used to show the full list returned
  // from an async operation (or allows a custom filter function to be used - in future)
  private disableSearch: boolean = false;

  private triggerChars: { [key: string]: MentionConfig } = {};

  searchString: string;
  startPos: number;
  startNode;
  searchList: MentionListComponent;
  stopSearch: boolean;
  iframe: any; // optional

  constructor(
    private _element: ElementRef,
    private _componentResolver: ComponentFactoryResolver,
    private _viewContainerRef: ViewContainerRef,
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["mention"] || changes["mentionConfig"]) {
      this.updateConfig();
      if (this.searchList) {
        this.searchList.hidden = true;
      }
    }
  }

  private updateConfig() {
    let config = this.mentionConfig;
    this.triggerChars = {};
    // use items from directive if they have been set
    if (this.mentionItems) {
      config.items = this.mentionItems;
    }
    this.addConfig(config);
    // nested configs
    if (config.mentions) {
      config.mentions.forEach(config => this.addConfig(config));
    }
  }

  // add configuration for a trigger char
  private addConfig(config: MentionConfig) {
    // defaults
    let defaults = Object.assign({}, this.DEFAULT_CONFIG);
    config = Object.assign(defaults, config);
    // add the config
    this.triggerChars[config.triggerChar] = config;

    // for async update while menu/search is active
    if (this.activeConfig && this.activeConfig.triggerChar === config.triggerChar) {
      this.activeConfig = config;
      this.updateSearchList();
    }
  }

  setIframe(iframe: HTMLIFrameElement) {
    this.iframe = iframe;
  }

  stopEvent(event: any) {
    if (!event.wasClick) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
  }

  @HostListener("blur", ["$event"])
  blurHandler(event: any) {
    this.stopEvent(event);
    this.stopSearch = true;
    this.hideMentionList();
  }

  @HostListener("keydown", ["$event"])
  keyHandler(event: any, nativeElement: HTMLInputElement = this._element.nativeElement) {
    const config = this.triggerChars["@"];
    if (!config || (config && config.chatType !== "groupchat")) {
      this.hideMentionList();
      return;
    }
    let charPressed = event.key;
    if (environment.isCordova) {
      if (!document.querySelector("quill-editor")) {
        setTimeout(() => {
          let charPressed = nativeElement.value.slice(nativeElement.selectionStart - 1, nativeElement.selectionStart);
          return this.handleKeyDownEvent(event, nativeElement, charPressed);
        }, 300);
      } else {
        setTimeout(() => {
          if (!!nativeElement.querySelector(".ql-editor")) {
            nativeElement = nativeElement.querySelector(".ql-editor");
          }
          return this.handleKeyDownEvent(event, nativeElement, charPressed);
        }, 500);
      }
    } else {
      return this.handleKeyDownEvent(event, nativeElement, charPressed);
    }
  }

  @HostListener("keyup", ["$event"])
  keyUpHandler() {
  }

  private hideMentionList(): void {
    if (this.searchList) {
      this.searchList.hidden = true;
      if (document.querySelector("vp-mention-list") !== null) {
        document.querySelector("vp-mention-list").classList.add("hide-list");
      }
      this.searchList.className = "hide-list";
      this.changeDetectorRef.markForCheck();
    }
  }

  private handleKeyDownEvent(event, nativeElement, charPressed): boolean {
    if (!!nativeElement.querySelector(".ql-editor")) {
      nativeElement = nativeElement.querySelector(".ql-editor");
    }
    let val: string = getValue(nativeElement);
    let pos = getCaretPosition(nativeElement, this.iframe);
    if (!environment.isCordova) {
      if (!charPressed) {
        let charCode = event.which || event.keyCode;
        if (!event.shiftKey && (charCode >= 65 && charCode <= 90)) {
          charPressed = String.fromCharCode(charCode + 32);
        }
        else {
          charPressed = String.fromCharCode(event.which || event.keyCode);
        }
      }
    } else if (environment.isCordova && !!document.querySelector(".ql-editor")
    && CommonUtil.isOnAndroid() ) {
      const textLength = nativeElement.textContent.length;
      charPressed = nativeElement.textContent.slice(textLength - 1, textLength);
      val = nativeElement.textContent || "";
      if (event.key === "Enter" && this.editor && this.searchList && !this.searchList.hidden) {
        const text = this.activeConfig.mentionSelect(this.searchList.activeItem);
        this.editor.deleteText(this.editor.getSelection(true).index - (this.searchString.length + 2), this.searchString.length + 2);
        this.editor.insertText(this.editor.getSelection(true).index, " " + text + " ");
        if ("createEvent" in document) {
          let evt = document.createEvent("HTMLEvents");
          evt.initEvent("input", false, true);
          nativeElement.dispatchEvent(evt);
          evt = null;
        }
        this.editor.focus();
        this.textChange.emit({ content: this.editor.root.innerHTML, position: this.editor.getSelection(true).index });
      }
    }

    if (event.keyCode === KEY_ENTER && event.wasClick && pos < this.startPos) {
      // put caret back in position prior to contenteditable menu click
      pos = nativeElement.length;
      setCaretPosition(nativeElement, pos, this.iframe);
    }
    let config = this.triggerChars[charPressed];
    if (config) {
      this.activeConfig = config;
      this.startPos = pos;
      this.startNode = (this.iframe ? this.iframe.contentWindow.getSelection() : window.getSelection()).anchorNode;
      this.stopSearch = false;
      this.searchString = null;
      this.showSearchList(nativeElement);
      this.updateSearchList(nativeElement);
    } else if (this.startPos >= 0 && !this.stopSearch) {
      if (pos <= this.startPos && event.keyCode !== KEY_ENTER) {
        this.hideMentionList();
      } else if (event.keyCode !== KEY_SHIFT &&
        !event.metaKey &&
        !event.altKey &&
        !event.ctrlKey &&
        pos >= this.startPos
      ) {
        if (event.keyCode === KEY_BACKSPACE && pos > 0) {
          pos--;
          if (pos === this.startPos) {
            this.stopSearch = true;
          }
          this.searchList.hidden = this.stopSearch;
        } else if (this.searchList && !this.searchList.hidden) {
          if (event.keyCode === KEY_TAB || event.keyCode === KEY_ENTER) {
            this.stopEvent(event);
            this.hideMentionList();
            // value is inserted without a trailing space for consistency
            // between element types (div and iframe do not preserve the space)
            if (this.editor) {
              const text = this.activeConfig.mentionSelect(this.searchList.activeItem);
              this.editor.deleteText(this.editor.getSelection(true).index - (this.searchString.length + 2), this.searchString.length + 2);
              this.editor.insertText(this.editor.getSelection(true).index, " " + text + " ");
              if ("createEvent" in document) {
                let evt = document.createEvent("HTMLEvents");
                evt.initEvent("input", false, true);
                nativeElement.dispatchEvent(evt);
              }
              this.editor.focus();
              this.textChange.emit({ content: this.editor.root.innerHTML, position: this.editor.getSelection(true).index });
            } else {
              insertValue(nativeElement, this.startPos, pos,
                this.activeConfig.mentionSelect(this.searchList.activeItem), this.iframe);
              // fire input event so angular bindings are updated
              if ("createEvent" in document) {
                let evt = document.createEvent("HTMLEvents");
                evt.initEvent("input", false, true);
                nativeElement.dispatchEvent(evt);
                evt = null;
              }
            }

            this.startPos = -1;
            this.changeDetectorRef.markForCheck();
            return false;
          }
          else if (event.keyCode === KEY_ESCAPE) {
            this.stopEvent(event);
            this.searchList.hidden = true;
            this.stopSearch = true;
            return false;
          }
          else if (event.keyCode === KEY_DOWN) {
            this.stopEvent(event);
            this.searchList.activateNextItem();
            return false;
          }
          else if (event.keyCode === KEY_UP) {
            this.stopEvent(event);
            this.searchList.activatePreviousItem();
            return false;
          }
        }

        if (event.keyCode === KEY_LEFT || event.keyCode === KEY_RIGHT) {
          // this.stopEvent(event);
          // return false;
          this.searchTerm.emit(this.searchString);
          this.updateSearchList(nativeElement);
        }
        else {
          let mention = "";
          val = val.trim().replace("close", "");
          if (environment.isCordova) {
             mention = val.trim().substring(this.startPos, pos);
          } else {
            mention = this.checkLastMention(val, pos);
            if (event.keyCode !== KEY_BACKSPACE) {
              mention += charPressed;
            }
          }
          this.searchString = mention;
          this.searchTerm.emit(this.searchString);
          this.updateSearchList(nativeElement);
        }
      } else if (event.keyCode === KEY_ENTER && this.searchString && this.searchList && !this.searchList.hidden) {
        this.stopEvent(event);
        this.hideMentionList();
        if (this.editor) {
          const text = this.activeConfig.mentionSelect(this.searchList.activeItem);
          this.editor.deleteText(this.editor.getSelection(true).index - (this.searchString.length + 2), this.searchString.length + 2);
          this.editor.insertText(this.editor.getSelection(true).index, " " + text);
          this.textChange.emit({ content: this.editor.root.innerHTML, position: this.editor.getSelection(true).index });
          this.editor.focus();
        }
      }
    }
  }

  checkLastMention(text: string, pos: number) {
    if (this.editor) {
      const leaf = this.editor.getLeaf(this.editor.getSelection(true).index)[0];
      if (!leaf) {
        return text;
      }
      const innerHTML = leaf.domNode.parentNode.innerHTML;
      return innerHTML.trim().substring(this.startPos + 1, pos);
    } else {
      return text.trim().substring(this.startPos + 1, pos);
    }
  }

  updateSearchList(nativeElement?: any) {
    let matches: any[] = [];
    if (!!nativeElement && this.searchList) {
      this.searchList.position(nativeElement, this.iframe, this.activeConfig.dropUp);
    }
    if (this.activeConfig && !!this.activeConfig.items && this.activeConfig.items.length > 0) {
      let objects = this.activeConfig.items;
      // disabling the search relies on the async operation to do the filtering
      if (!this.disableSearch && this.searchString) {
        let searchStringLowerCase = this.searchString.toLowerCase();
        objects = objects.filter(e => {
          return e.label && e.label.toLowerCase().indexOf(searchStringLowerCase) !== -1
            || e.value && e.value.toLowerCase().indexOf(searchStringLowerCase) !== -1;
        });
      } else {
        this.hideMentionList();
        this.searchList.items = [];
      }
      matches = objects;
      if (this.activeConfig.maxItems > 0) {
        matches = matches.slice(0, this.activeConfig.maxItems);
      }

      if (this.searchList) {
        if (this.searchString && this.searchString.length < 2 || !this.searchString) {
          this.hideMentionList();
          this.searchList.items = [];
        } else {
          this.searchList.labelKey = this.activeConfig.labelKey;
          this.searchList.items = matches;
          this.searchList.hidden = matches.length === 0;
          if (this.searchList.hidden) {
            this.hideMentionList();
          }
        }
      }
    } else {
      this.hideMentionList();
    }
    this.changeDetectorRef.markForCheck();
  }

  showSearchList(nativeElement: HTMLInputElement) {
    if (!this.searchList) {
      let componentFactory = this._componentResolver.resolveComponentFactory(MentionListComponent);
      let componentRef = this._viewContainerRef.createComponent(componentFactory);
      this.searchList = componentRef.instance;
      this.searchList.position(nativeElement, this.iframe, this.activeConfig.dropUp);
      this.searchList.itemTemplate = this.mentionListTemplate;
      this.searchList.className = "";
      componentRef.instance["itemClick"].subscribe(() => {
        if (!environment.isCordova) {
          let fakeKeydown = { "keyCode": KEY_ENTER, "wasClick": true };
          this.keyHandler(fakeKeydown, nativeElement);
        } else {
          if (this.editor) {
            const text = this.activeConfig.mentionSelect(this.searchList.activeItem);
            this.editor.deleteText(this.editor.getSelection(true).index - (this.searchString.length + 2), this.searchString.length + 2);
            this.editor.insertText(this.editor.getSelection(true).index, " " + text + " ");
            if ("createEvent" in document) {
              let evt = document.createEvent("HTMLEvents");
              evt.initEvent("input", false, true);
              nativeElement.dispatchEvent(evt);
              evt = null;
            }
            this.editor.focus();
            this.textChange.emit({ content: this.editor.root.innerHTML, position: this.editor.getSelection(true).index });
          } else {
            let pos = getCaretPosition(nativeElement, this.iframe);
            setCaretPosition(nativeElement, pos, this.iframe);
            this.searchList.hidden = true;
            // value is inserted without a trailing space for consistency
            // between element types (div and iframe do not preserve the space)
            insertValue(nativeElement, this.startPos - 1, pos,
              this.activeConfig.mentionSelect(this.searchList.activeItem), this.iframe);
            // fire input event so angular bindings are updated
            if ("createEvent" in document) {
              let evt = document.createEvent("HTMLEvents");
              evt.initEvent("input", false, true);
              nativeElement.dispatchEvent(evt);
              evt = null;
            }
            this.startPos = -1;
            this.changeDetectorRef.markForCheck();
          }
          if (this.searchList) {
            this.hideMentionList();
          }
        }
        setTimeout(() => {
          nativeElement.focus();
        }, 500);

      });
    } else if (!!this.searchList) {
      this.searchList.labelKey = this.activeConfig.labelKey;
      this.searchList.activeIndex = 0;
      this.searchList.className = "";
      if (document.querySelector("vp-mention-list") !== null) {
        document.querySelector("vp-mention-list").classList.remove("hide-list");
      }
      this.searchList.position(nativeElement, this.iframe, this.activeConfig.dropUp);
      window.setTimeout(() => this.searchList.resetScroll());
    }
  }
}
