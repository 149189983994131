/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef,
  HostListener,
  OnDestroy,
  OnInit, ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CommonUtil } from "app/talk/utils/common.util";
import { forkJoin, Observable, of, Subject } from "rxjs";
import { Channel } from "../models/channel.model";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from "@angular/router";
import { Topic } from "../models/topic.model";
import { Attachment } from "../models/attachment.model";
import { ChannelRepository } from "../repository/channel.repository";
import { catchError, debounceTime, distinctUntilChanged, filter, map, take, takeUntil } from "rxjs/operators";
import { ConversationRepository } from "../../talk/repositories/conversation.repository";
import { EMPTY_STATE_TYPE, TOPIC_VIEW } from "../../shared/models/user-config.model";
import {getIsConversationListExpanded, getIsRightSideBarExpanded, TalkRootState} from "../../talk/reducers";
import { Store } from "@ngrx/store";
import { UserConfigRepository } from "../../talk/repositories/userconfig.repository";
import { ChannelService } from "../channel.service";
import { Broadcaster } from "../../talk/shared/providers";
import { ChannelSnackbarService } from "../channel-snackbar.service";
import { SnackbarType } from "../models/snackbar.model";
import {TopicBulkAdd, TopicChannelAdd, TopicInfoUpdate} from "../store/actions/topic";
import {TopicState} from "../store/reducers/topic";
import { IconColorConfig } from "vnc-library/lib/components/icons/models";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import {BehaviorSubject} from "rxjs";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { environment } from "app/environments/environment";
declare var Mark: any;
import {ChannelTopicListingService} from "./channel-topic-listing.service";
import {
  FETCH_FILTERED_TOPICS,
  RESET_FILTERS,
  TopicFilterComponentService, ViewFilter
} from "./topic-filter/topic-filter.component.service";
import {TOPIC_FILES_ADDED} from "../topic-detail/topic-detail.component";

import {ConstantsUtil} from "../../talk/utils/constants.util";
import {ExpandConversationList} from "../../talk/actions/layout";
import {DEFAULT_AVATAR, DEFAULT_IMG} from "../../common";
import { UntypedFormControl } from "@angular/forms";
import {ChannelDetailComponentService} from "./channel-detail.component.service";
import {ChannelDetailIconName} from "./channel-detail-icon/channel-detail-icon.component";
import { NotificationService } from "../../talk/services/notification.service";
import { ArchiveWindowComponent } from "app/talk/archive-window/archive-window.component";
import { TrashWindowComponent } from "app/talk/trash-window/trash-window.component";

@Component({
  selector: "vp-channel-detail",
  templateUrl: "./channel-detail.component.html",
  styleUrls: ["./channel-detail.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ChannelDetailComponentService]
})
export class ChannelDetailComponent implements OnInit, OnDestroy, AfterViewInit {
  selectedChannel: Channel;
  channelId: string;
  showFilters: boolean = false;
  topics: Topic[] = [];
  unpublishedTopics: Topic[] = [];
  authorsGroup = [];
  topicsGroupedByAuthor = {};
  attachments: Attachment[] = [];
  currentView = "list";
  heroImageHeight = "244px";
  uiTopicView = TOPIC_VIEW;
  emptyStateTypeNoAccess = EMPTY_STATE_TYPE.BUTTON;
  emptyStateType = EMPTY_STATE_TYPE.SINGLE;
  emptyCards = [];
  fileSizeMBLimit = 100;
  isUploadRunning = false;
  totalFilesUploading = 0;
  showSuccessBanner = false;
  topicCommentScroll = TopicCommentScroll;
  noAccess = false;
  viewTileIconColor: IconColorConfig = {
    colorBase: "#8B96A0",
    colorActive: "#337CBD",
    colorDisabled: "#8B96A0"
  };
  isShowMobileViewSelection: boolean = false;
  isMobileScreen: boolean = false;
  $showGoToParentBanner = new BehaviorSubject<boolean>(false);
  $showGoToParentChannel = new BehaviorSubject<boolean>(false);
  parentChannelId = "";
  parentTopicId = "";
  isTopicsLoading: boolean = false;
  prevTopicId = null;
  isCordovaApp: boolean = environment.isCordova;
  sortBy = "";
  sortOrder =  "";
  groupBy = false;
  currentHighlightedIndex = 1;
  totalHighLights = 0;
  scrollData = {};
  private isMarkedEarlier = false;
  private channelWrapperInstance: any;

  isTopicFiltersLoading: boolean = false;
  filters: any;
  private isAlive$ = new Subject<boolean>();
  private channelChanged$ = new Subject<boolean>();
  private uploadedFiles = [];
  private uploadingTopic: Topic;

  filteredTopics$ = new BehaviorSubject<Topic[]>([]);
  filteredTopicsLoading = false;
  filteredTopicsLoaded = false;
  topicQuery: string;
  wrapperWidth = 0;
  defaultAvatar = DEFAULT_AVATAR;
  defaultChannelAvatar = DEFAULT_IMG;
  isFiltersApplied = false;
  @ViewChild("channelDetailWrapper", {static: false}) channelDetailWrapper: ElementRef;
  isRightBarExpanded: boolean = false;
  isLeftBarExpanded: boolean = false;
  topicSearchControl: UntypedFormControl = new UntypedFormControl("", []);
  searchedTopics: Topic[] = [];
  xDown = null;
  yDown = null;
  isDisplayTopicSearch: boolean = true;
  emptyStateBackground: any = "";
  channelDetailIconName = ChannelDetailIconName;
  themeColor: string = "#317bbc";
  color: string = "#FFFFFF";
  currentTheme = environment.theme || "vnctalk";
  channel: any = {
    archived: false,
    deleted: false,
  };

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private matDialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private conversationRepo: ConversationRepository,
    private _talkRootStore: Store<TalkRootState>,
    private topicsStore: Store<TopicState>,
    private _userConfigRepo: UserConfigRepository,
    private channelService:ChannelService,
    private channelSnackBarService: ChannelSnackbarService,
    private channelRepository: ChannelRepository,
    private broadcaster: Broadcaster,
    private breakpointObserver: BreakpointObserver,
    public _channelTopicListingService: ChannelTopicListingService,
    private _topicFilterComponentService: TopicFilterComponentService,
    public channelDetailService: ChannelDetailComponentService,
    public notificationService: NotificationService,
  ) {
    route.queryParams.pipe(takeUntil(this.isAlive$)).subscribe(q => {
      if (q.parentChannelId && q.parentTopicId) {
        this.$showGoToParentBanner.next(true);
        this.parentChannelId = q.parentChannelId;
        this.parentTopicId = q.parentTopicId;
      }
    });
    this.emptyCards = Array(10).fill(0).map((x,i)=>i);
    this.topicSearchControl.valueChanges.pipe(takeUntil(this.isAlive$), distinctUntilChanged(), debounceTime(500))
    .subscribe((val) => {
      this.searchTopicByKeyword(val);
    });
  }


  ngOnInit(): void {
    this.showUnarchivedOrRestoredBanner = false;
    let cView: any = localStorage.getItem("currentView");
    if(!!cView){
    if(cView.includes("list")) {
      this.changeView(this.uiTopicView.LIST);
    } else if(cView.includes("grid")) {
      this.changeView(this.uiTopicView.GRID);
    } else if(cView.includes("table")) {
      this.changeView(this.uiTopicView.TABLE);
    }
  } else {
    this.changeView(this.uiTopicView?.LIST);
  }
    this.channelRepository.getSelectedChannel().subscribe((channel)=>{
      if(channel?.id !== this.parentChannelId && !this.topics) {
        this.topics = [];
        this.unpublishedTopics = [];
        this.loadChannelTopics(channel?.id);
        this.channelService.getUnpublishedTopics(channel?.id).pipe(takeUntil(this.isAlive$)).subscribe((res: any) => {
          if (res && !res.error) {
            const topics: Topic[] = (res.topics as Topic[]).map((t) => {
              return { ...t, id: t.is_iom ? "iom-" + t.id : t.id };
            });
            this.topicsStore.dispatch(new TopicBulkAdd(topics));
          }
        });
      }
    });
    this.route.queryParams.pipe(takeUntil(this.isAlive$)).subscribe(q => {
      if (q.parentChannelId && q.parentTopicId) {
        this.$showGoToParentBanner.next(true);
        this.parentChannelId = q.parentChannelId;
        this.parentTopicId = q.parentTopicId;
      }
    });
    this.emptyCards = Array(10).fill(0).map((x,i)=>i);
    this.topicSearchControl.valueChanges.pipe(takeUntil(this.isAlive$), distinctUntilChanged(), debounceTime(500))
    .subscribe((val) => {
      this.searchTopicByKeyword(val);
    });
    this.getThemeColor();
    const currentTheme = localStorage.getItem(ConstantsUtil.THEME) || environment.theme;
    if (currentTheme === "hin") {
      this.emptyStateBackground = {"background": "url(" + CommonUtil.getFullUrl("/assets/hin_punkte-01.svg") + ") no-repeat center center"};
    } else {
      this.emptyStateBackground = {"background": "url(" + CommonUtil.getFullUrl("/assets/talk-background-openedchat.jpg") + ")"
      };
    }
    this.listenToBroadcastEvents();
    this.prevTopicId = history?.state?.prevTopicId;

    this.route.params.pipe(distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(params => {
      if (params.channelId) {
         // To hide header on mobile
        this.broadcaster.broadcast("showRightPanel");
        if (document.getElementById("mainLayout") !== null) {
          document.getElementById("mainLayout").classList.add("hide-header-mobile");
        }
        this.markElements();
        if (params.channelId !== this.channelId) {
          this.channelId = params.channelId;
          this.channelRepository.SetSelectedChannelId(this.channelId);
          this.channelChanged$.next(true);
          this._topicFilterComponentService.channelId.next(params?.channelId);
          this.setFilteredTopics(params?.channelId);
          this.getLoadingLoadedStatus(params?.channelId);
        }
        this.topics = [];
        this.channelRepository.getChannelById(this.channelId)
        .pipe(
          filter(v => !!v),
          takeUntil(this.isAlive$)
        )
        .subscribe( channel => {
          if (channel?.id !== this.channelId) return;
          this.selectedChannel = channel;
          this.channelId = channel?.id;
          if (this.selectedChannel?.parent) {
            this.$showGoToParentChannel.next(true);
          }
          else {
            this.$showGoToParentChannel.next(false);
          }
          if (!this.selectedChannel?.archived) {
            this.channelRepository.setUnArchivedChannelId(this.channelId);
          }
          if (this.selectedChannel.topics_count > 0 || this.selectedChannel.channel_type?.includes("iom")) {
            this.channelRepository.getTopicsInfoByChannelId(this.channelId).pipe(take(1)).subscribe( info => {
              if (!info || (info && !info?.isLoaded)) {
                this.loadChannelTopics(this.channelId);
              }
            });
          }
          this.channelRepository.getTopics(this.channelId).pipe(
            map(topics => topics.filter(topic => !topic?.archived && !topic?.is_scheduled && !topic?.is_draft)),
            takeUntil(this.isAlive$)).subscribe(topics => {
              const timeOfUpdate = new Date().getTime().toString();
              this.topics = [...topics].filter(topic => topic && !topic?.deleted).map( topic => {
              let headerAttachments = [];
              topic.attachments = topic.attachments || [];
              if (topic.topic_type ===  "video") {
                if (topic.external_video_url) {
                  const thumbnail = topic.attachments.find(attachment => attachment.is_default_thumbnail === true);
                  if (!!thumbnail) {
                    headerAttachments = [{...thumbnail}];
                  }
                } else {
                  const video = topic.attachments.find(attachment => attachment.is_video === true);
                  let defaultThumbnail = topic.attachments.find(attachment => attachment.is_default_thumbnail === true);
                  if (!!video) {
                    let thumbnail_url = video.thumbnail_url;
                    if (video && defaultThumbnail) {
                      thumbnail_url = defaultThumbnail.thumbnail_url;
                    }
                    headerAttachments = [{...video, thumbnail_url }];
                  }
                }
              } else {
                headerAttachments = topic.attachments.filter(attachment => attachment.is_header === true);
              }
              const heroAttachments = this.channelRepository?.updateAttachmentsURLs(headerAttachments, topic?.is_iom);
              const comments_count = !!topic.comments_count ? +topic.comments_count : 0;
              const unread_comments_count = !!topic.unread_comments_count ? +topic.unread_comments_count : 0;
              const trackById = topic.id + timeOfUpdate;
              return {...topic, heroAttachments, comments_count, unread_comments_count, trackById};
            });
            this.filteredTopics$.next(topics);
          });
            if (this.topics.length > 0) {
              if (!this.filteredTopicsLoaded && this.isFiltersApplied) {
                this._topicFilterComponentService.fetchTopics();
              }
              this.channelChanged$.next(false);
              this.isTopicsLoading = false;
            }
            this.changeDetectorRef.markForCheck();
            if (!!this.prevTopicId) {
              setTimeout(() => {
                if (!this.selectedChannel.deleted && !this.selectedChannel.archived)
                this.scrollToPrevTopic();
              });
            }

          this.channelRepository.getTopics(this.channelId).pipe(
            filter(v => !!v),
            map(topics => {
              return topics.filter(topic => (!!topic?.is_scheduled || !!topic?.is_draft));
            }),
            takeUntil(this.isAlive$)).subscribe( topics => {
              const key = "subject";
              let tempTopic: any = [...new Map(topics.map(item =>
                [item[key], item])).values()];
              this.unpublishedTopics = tempTopic.map(topic => {
                let heroAttachments;
                let headerAttachments;
                if (topic?.topic_type === "video") {
                  headerAttachments = topic?.attachments?.filter(attachment => attachment?.is_header === false);
                  heroAttachments = this.channelRepository?.updateAttachmentsURLs(headerAttachments, topic?.is_iom);
                } else {
                  headerAttachments = topic?.attachments?.filter(attachment => attachment?.is_header === true);
                  heroAttachments = this.channelRepository?.updateAttachmentsURLs(headerAttachments, topic?.is_iom);
                }
                const comments_count = !!topic?.comments_count ? +topic?.comments_count : 0;
                const unread_comments_count = !!topic?.unread_comments_count ? +topic?.unread_comments_count : 0;
              return {...topic, heroAttachments, comments_count, unread_comments_count};
            });
          });
        });

      }
    });

    this.channelRepository.getSelectedChannel().pipe(filter(v => !!v), takeUntil(this.isAlive$))
      .subscribe((channel: Channel) => {
        this.selectedChannel = channel;
        this.setEmptyStateType();
      this.changeDetectorRef.markForCheck();
    });
    const view = localStorage.getItem("currentView");
    if (!!view) {
      this._userConfigRepo.updateUserTopicView(JSON.parse(view)).pipe(takeUntil(this.isAlive$)).subscribe();
    }
    this.channelRepository.getChannelNoAccess().pipe(takeUntil(this.isAlive$)).subscribe(
      res => {
        this.noAccess = res;
        if (this.noAccess) {
          this.isRightBarExpanded = false;
          this.broadcaster.broadcast(ConstantsUtil.CLOSE_SIDEBAR);
        }
        this.changeDetectorRef.markForCheck();
      }
    );


    this.channelRepository.getChannelNotFound().pipe(takeUntil(this.isAlive$)).subscribe(
      res => {
        if (!!res) {
          this.router.navigateByUrl(`/talk/channels`);
        }
      }
    );

    this.broadcaster.on("HIGHLIGHT_TOGGLE").pipe(takeUntil(this.isAlive$))
    .subscribe(val => {
      if (val === false) {
        this.unmark();
        this.changeDetectorRef.markForCheck();
      }
    });

    this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 767px)");
    this.breakpointObserver
      .observe(["(max-width: 767px)"])
      .pipe(takeUntil(this.isAlive$))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobileScreen = true;
        } else {
          this.isMobileScreen = false;
        }
        this.changeDetectorRef.markForCheck();
      });

      if (this.isMobileScreen && this.currentView === "table") {
        this.currentView = "list";
        this.changeDetectorRef.markForCheck();
      }

      this._topicFilterComponentService.filtersChanged$.pipe(distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(
        res => {
          this.isFiltersApplied = res;
          this.changeDetectorRef.markForCheck();
        }
      );
      this.channelRepository.getRightbarExpanded().pipe(takeUntil(this.isAlive$)).subscribe((res: boolean) => {
        this.isRightBarExpanded = res;
        this.changeDetectorRef.markForCheck();
      });

      this.channelRepository.getIsLeftSideBarExpanded().pipe(takeUntil(this.isAlive$)).subscribe((res: boolean) => {
        this.isLeftBarExpanded = res;
        this.changeDetectorRef.markForCheck();
      });
  }
  getDefaultCover() {
    return CommonUtil.getVncDirectoryAttachemntURL(this.selectedChannel?.default_cover);
  }

  getThemeColor(): void {
    const theme = localStorage.getItem("theme");
    if (theme !== null && theme === "hin") {
      this.themeColor = "#f39900";
      if (this.currentTheme === "hin") {
        this.themeColor = "#FFFFFF";
        this.color = "#8f8f8f";
      }
    } else if (theme !== null && theme === "ekbo") {
      this.themeColor = "#6633cc";
    } else if (theme !== null && theme === "airbus") {
      this.themeColor = "#00205b";
    } else {
      this.themeColor = "#317bbc";
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._talkRootStore.select(getIsConversationListExpanded).pipe(takeUntil(this.isAlive$)).subscribe(expanded => {
        if (this.channelDetailWrapper) {
          const wrapperWidth = this.channelDetailWrapper.nativeElement.clientWidth;
          if (!!expanded && wrapperWidth < 704) {
            this.broadcaster.broadcast(ConstantsUtil.CLOSE_SIDEBAR);
          }
        }
      });

      this._talkRootStore.select(getIsRightSideBarExpanded).pipe(takeUntil(this.isAlive$)).subscribe( open => {
        if (this.channelDetailWrapper) {
          const wrapperWidth = this.channelDetailWrapper.nativeElement.clientWidth;
          if (!!open && wrapperWidth < 704) {
            this._talkRootStore.dispatch(new ExpandConversationList(false));
          }
        }
      });
      if (this.channelDetailWrapper) {
        const wrapperWidth = this.channelDetailWrapper.nativeElement.clientWidth;
        if (wrapperWidth !== this.wrapperWidth) {
          this.wrapperWidth = wrapperWidth;
          this.changeDetectorRef.markForCheck();
        }
      }
    });
  }

  loadChannelTopics(channelId: Channel["id"]) {
      this.isTopicsLoading = true;
      if (this.topics.length > 0) {
        this.isTopicsLoading = false;
      }
      this.changeDetectorRef.markForCheck();
      this.channelService.getTopics(channelId).pipe(takeUntil(this.isAlive$)).subscribe((res: any) => {
        this.isTopicsLoading = false;
        if (res && !res.error) {
          const topics: Topic[] = (res.topics as Topic[]).map((t) => {
            return { ...t, id: t.is_iom ? "iom-" + t.id : t.id };
          });
          const info = { totalCount: res.total_count, offset: topics.length, isLoaded: true };
          this.topicsStore.dispatch(new TopicBulkAdd(topics));
          this.topicsStore.dispatch(new TopicInfoUpdate({ channelId, info }));
          this.topicsStore.dispatch(new TopicChannelAdd(channelId));
        }
        else {
          this.isTopicsLoading = false;
        }
        this.changeDetectorRef.markForCheck();
      }, () => {
        this.isTopicsLoading = false;
        this.changeDetectorRef.markForCheck();
      });

      this.channelService.getUnpublishedTopics(channelId).pipe(takeUntil(this.isAlive$)).subscribe((res: any) => {
        if (res && !res.error) {
          const topics: Topic[] = (res.topics as Topic[]).map((t) => {
            return { ...t, id: t.is_iom ? "iom-" + t.id : t.id };
          });
          this.topicsStore.dispatch(new TopicBulkAdd(topics));
        }
      });

    this.channelService.getUnpublishedTopics(channelId).pipe(takeUntil(this.isAlive$)).subscribe((res: any) => {
      if (res && !res.error) {
        const topics: Topic[] = (res.topics as Topic[]).map((t) => {
          return { ...t, id: t.is_iom ? "iom-" + t.id : t.id };
        });
        this.topicsStore.dispatch(new TopicBulkAdd(topics));
      }
    });
  }

  private listenToBroadcastEvents() {
    this.broadcaster.on(FETCH_FILTERED_TOPICS).pipe(takeUntil(this.isAlive$)).subscribe(({query, channelId}) => {
      this.topicQuery = query;
      this._channelTopicListingService.loadFilteredTopics(channelId, query);
    });
    this.broadcaster.on(RESET_FILTERS).pipe(takeUntil(this.isAlive$)).subscribe(({channelId}) => {
      this.isFiltersApplied = false;
      this.changeDetectorRef.markForCheck();
      this._channelTopicListingService.resetFilteredTopics(channelId);
    });
    this.broadcaster.on(ConstantsUtil.APPLY_FILTERS).pipe(takeUntil(this.isAlive$)).subscribe(() => {
      this.isFiltersApplied = true;
      this.changeDetectorRef.markForCheck();
      this._topicFilterComponentService.filters.pipe(take(1)).subscribe(
        filters => {
          if (!!filters) {
            const groupByFilter = [...filters].find(f => f.name === ViewFilter.GROUP_BY);
            this.groupBy        = !!groupByFilter && groupByFilter?.selectedValue?.value === "author";
            const sortByFilter =  [...filters].find(f => f.name === ViewFilter.SORT_BY);
            this.sortBy = sortByFilter?.selectedValue?.value;
            this.changeDetectorRef.markForCheck();
          }
        }
      );
    });
  }

  private setFilteredTopics(channelId) {
    this._channelTopicListingService.selectFilteredTopics(channelId)
      .pipe(takeUntil(this.isAlive$)
      , distinctUntilChanged())
      .subscribe(res => {
        const topics = [...res].map( topic => {
          let headerAttachments = [];
          if (topic.topic_type ===  "video") {
            if (topic.external_video_url) {
              const thumbnail = topic.attachments.find(attachment => attachment.is_default_thumbnail === true);
              if (!!thumbnail) {
                headerAttachments = [{...thumbnail}];
              }
            } else {
              const video = topic.attachments.find(attachment => attachment.is_video === true);
              let defaultThumbnail = topic.attachments.find(attachment => attachment.is_default_thumbnail === true);
              if (!!video) {
                let thumbnail_url = video.thumbnail_url;
                if (video && defaultThumbnail) {
                  thumbnail_url = defaultThumbnail.thumbnail_url;
                }
                headerAttachments = [{...video, thumbnail_url }];
              }
            }
          } else {
            headerAttachments = topic.attachments.filter(attachment => attachment.is_header === true);
          }
          const heroAttachments = this.channelRepository?.updateAttachmentsURLs(headerAttachments, topic?.is_iom);
          const comments_count = !!topic?.comments_count ? +topic?.comments_count : 0;
          const unread_comments_count = !!topic?.unread_comments_count ? +topic?.unread_comments_count : 0;
          return {...topic, heroAttachments, comments_count, unread_comments_count};
        });
        this.filteredTopics$.next(topics);
        this.getTopicsGroupedByAuthor();
      });
  }
  private getLoadingLoadedStatus(channelId) {
    this._channelTopicListingService.getLoadingLoadedStatus(channelId)
      .pipe(takeUntil(this.isAlive$))
      .subscribe(({loaded, loading}) => {
        this.filteredTopicsLoaded = loaded;
        this.filteredTopicsLoading = loading;
        this.changeDetectorRef.markForCheck();
      });
  }

  toggleFilters() {
    this.showFilters = !this.showFilters;
  }

  openTopic(topic, scrollToCommentSection: TopicCommentScroll) {
    this.router.navigateByUrl(`/talk/channels/${topic.channel_id}/topics/${topic.id}?comment=${scrollToCommentSection}`);
  }

  getPermission(permissions: any[] | null = null, toCheck: string) {
    return CommonUtil.getPermission(permissions, toCheck);
  }

  setEmptyStateType() {
    if (this.selectedChannel && !this.selectedChannel.archived && this.selectedChannel?.permissions && this.selectedChannel?.permissions?.length > 0 && this.selectedChannel?.permissions[0] && this.selectedChannel?.permissions[0]?.create_topics) {
      this.emptyStateType = EMPTY_STATE_TYPE.BUTTON;
    } else {
      this.emptyStateType = EMPTY_STATE_TYPE.SINGLE;
    }
    this.changeDetectorRef.markForCheck();
  }


  ngOnDestroy() {
    this.channelChanged$.next(false);
    this.channelChanged$.complete();
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }


  changeView(view: TOPIC_VIEW, bypassApi = false) { //
    this.currentView = view;
    localStorage.setItem("currentView", JSON.stringify(this.currentView));
    this.changeDetectorRef.markForCheck();
    if (!bypassApi) {
      this._userConfigRepo.updateUserTopicView(view).pipe(takeUntil(this.isAlive$)).subscribe();
    }
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize() {
    if (this.channelDetailWrapper) {
      const wrapperWidth = this.channelDetailWrapper.nativeElement.clientWidth;
      if (wrapperWidth !== this.wrapperWidth) {
        this.wrapperWidth = wrapperWidth;
        this.changeDetectorRef.markForCheck();
      }
    }
  }

  async addSubchannel(item) {
    item.createNew = true;
    let options: any = {
      width: "480px",
      height: "730px",
    };
    if (CommonUtil.isMobileSize()) {
      options = {
        maxWidth: "100vw",
        maxHeight: "100vh",
        width: "100vw",
        height: "100vh"
      };
    }
    const { CreateSubChannelComponent } = await import(
      /* webpackPrefetch: true */
      "app/channels/create-subchannel/create-subchannel.component");
    this.matDialog.open(CreateSubChannelComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: "vnctalk-form-panel",
      disableClose: true,
      data: item,
      autoFocus: true
    }, options))
      .afterClosed()
      .pipe(take(1))
      .subscribe(res => {
        if (res && res.channel) {
          if (item.is_public === !res.channel.is_public) {
            this.channelRepository.changeChannelCategory(item,"is_public");
          }
          this.channelRepository.updateChannelIntoStore(res.channel);
          this.router.navigateByUrl(`/talk/channels/${res.channel.id}`);
        }
      });
  }

  addFileToTopic(filesArray: any, topic:Topic) {
    this.uploadingTopic = topic;
    if (!!filesArray?.length) {
      let files: any = [];
      for (let i = 0; i < filesArray.length; i++) {
        const file = filesArray[i];
        if (file) {
          let isFileExceedLimit = this.channelRepository.isFileSizeExceedLimit(file);
          if (isFileExceedLimit) {
            this.channelRepository.showFileExceedNotification(file);
          } else {
            files.push(file);
          }
        }
      }
      if (files.length > 5) {
        this.showNotification("FILE_UPLOAD_LIMIT");
        return;
      }
      if (this.isUploadRunning) {
        this.showNotification("FILE_UPLOAD_ALREADY_RUNNING_MSG");
        return;
      }
      this.totalFilesUploading = files.length;
      this.readFiles(files).pipe(take(1)).subscribe(fileUploads => {
        this.isUploadRunning = true;
        this.changeDetectorRef.markForCheck();
        this.uploadFiles(fileUploads).pipe(take(1)).subscribe(response => {
          this.uploadedFiles = [...response];
          const uploads = {};
          this.uploadedFiles.forEach(function(value, idx) {
            uploads[(idx + 1)] = value;
          });
          this.channelRepository.updateTopic(topic.id, {...topic, uploads}).pipe(take(1)).subscribe((newTopic:Topic) =>{
            this.channelRepository.addFilesToTopicStore(newTopic.id, newTopic.attachments,this.uploadedFiles.length);
            this.broadcaster.broadcast(TOPIC_FILES_ADDED, {files: uploads});
          });
          // this.channelRepository.addFilesToTopicStore(topic.id, {...topic, uploads}).subscribe();
          this.isUploadRunning = false;
          this.showSuccessBanner = true;
          this.changeDetectorRef.markForCheck();
          setTimeout(() => {
            this.dismissSuccessBanner();
          },2500);
        });
      });
    }
  }

  showNotification(key:string, params?:any){
    this.translate.get(key, params).pipe(take(1)).subscribe(text => {
      this.channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE);
    });
  }

  private uploadFiles(fileUploadsArray: any[]): Observable<any> {
    const fileUploads: any = [];
    for (let i = 0; i < fileUploadsArray.length; i++) {
      fileUploads.push(
        this.channelService.uploadFile(fileUploadsArray[i].content, fileUploadsArray[i].filename, fileUploadsArray[i].content_type).pipe(
          map((res: any)  => res.upload),
          catchError(() => of(
            this.showNotification("FILE_UPLOAD_ERROR")
          ))
        )
      );
    }
    return forkJoin(fileUploads);
  }

  private readFiles(files: any[]): Observable<any> {

    const fileReaderObs = (file) => {
      let myReader: FileReader = new FileReader();
      let fileReaderObs = Observable.create((observer: any) => {
        myReader.onloadend = () => {
          let upload = {
            filename: file.name,
            content_type: file.type,
            content: myReader.result
          };
          observer.next(upload);
          observer.complete();
        };
      });
      myReader.readAsArrayBuffer(file);
      return fileReaderObs;
    };

    const observableUploadFilesBatch = [];
    for (let i = 0; i < files.length; i++) {
      observableUploadFilesBatch.push(fileReaderObs(files[i]));
    }
    return forkJoin(observableUploadFilesBatch);
  }


  async editChannel(item) {
    if (!item?.parent) {
      let options: any = {
        width: "480px",
        height: "730px",
      };
      if (CommonUtil.isMobileSize()) {
        options = {
          maxWidth: "100%",
          maxHeight: "100%",
          width: "100vw",
          height: "100vh"
        };
      }
      const { CreateChannelComponent } = await import(
        /* webpackPrefetch: true */
        "app/channels/create-channel/create-channel.component");
      this.matDialog.open(CreateChannelComponent, Object.assign({
        backdropClass: "vnctalk-form-backdrop",
        panelClass: "vnctalk-form-panel",
        disableClose: true,
        data: item,
        autoFocus: true
      }, options)).afterClosed().pipe(take(1)).subscribe();
    }
    else {
      // implement the second casse as it is  a subchannel
      this.editSubchannel(item);
    }
  }


  async editSubchannel(item) {
    item.createNew = false;
    let options: any = {
      width: "480px",
      height: "730px"
    };
    if (CommonUtil.isMobileSize()) {
      options = {
        maxWidth: "100vw",
        maxHeight: "100vh",
        width: "100vw",
        height: "100vh"
      };
    }
    const { CreateSubChannelComponent } = await import(
      /* webpackPrefetch: true */
      "app/channels/create-subchannel/create-subchannel.component"
    );
    this.matDialog
      .open(
        CreateSubChannelComponent,
        Object.assign(
          {
            backdropClass: "vnctalk-form-backdrop",
            panelClass: "vnctalk-form-panel",
            disableClose: true,
            data: item,
            autoFocus: true
          },
          options
        )
      )
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        if (res && res.channel) {
          if (item.is_public === !res.channel.is_public) {
            this.channelRepository.changeChannelCategory(item, "is_public");
          }
          this.channelRepository.updateChannelIntoStore(res.channel);
          this.channelRepository.getChannelById(res.channel.parent_id).pipe(take(1)).subscribe(parentChannel => {
            this.channelRepository.updateChannelIntoStore(parentChannel);
            // this.router.navigateByUrl(`/talk/channels/${parentChannel}`);
          });
          this.changeDetectorRef.markForCheck();
        }
      });
  }

  async deleteChannel(item, moveToTrash = false) {
    if (moveToTrash) {
      this.channelRepository.deleteChannel(item.id, true).subscribe(()=> {
        this.channelButtonPress("back");
      });
      return;
    }
    let options: any = {
      width: "480px",
      height: "280px"
    };
    if (CommonUtil.isMobileSize()) {
      options = {
        maxWidth: "100vw",
        maxHeight: "100vh",
        minHeight: "300px"
      };
    }
    const { ConfirmationChannelComponent } = await import(
      "../confirmation-channel/confirmation-channel.component");
    this.matDialog.open(ConfirmationChannelComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: ["vnctalk-form-panel", "confirmation-dialog"],
      disableClose: true,
      data: {
        headerText: "DELETE_CHANNEL",
        bodyText: "DELETE_CHANNEL_MSG",
        okLabel: "DELETE"
      },
      autoFocus: true
    }, options)).afterClosed().pipe(take(1)).subscribe( (res: any) => {
      if (!!res && res.confirmation) {
          if (res.confirmation === "yes") {
            this.channelRepository.setSelectedChannelId(item.id);
            this.channelRepository.deleteChannel(item.id).subscribe( () => {
              this.channelButtonPress("back");
            });
            this.router.navigateByUrl(`/talk/trash`).then().catch(() => {
            });
          }
      }
    });
  }

  restoreChannelConfirmed(channel) {
    this.channelRepository.restoreChannel(channel.id).subscribe(() => {
      this.translate.get("CHANNEL_RESTORED").pipe(take(1)).subscribe(text => {
        this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
        this.toggleUnarchivedOrRestoredBanner(true, true);
      });
    });
  }

  async restoreChannel(channel: Channel) {
    if (this.isMobileScreen) {
      let options: any = {
        width: "480px",
        height: "280px"
      };
      if (CommonUtil.isMobileSize()) {
        options = {
          maxWidth: "100vw",
          maxHeight: "100vh",
          minHeight: "300px"
        };
      }
      const { ConfirmationChannelComponent } = await import(
        "../../channels/confirmation-channel/confirmation-channel.component");
      this.matDialog.open(ConfirmationChannelComponent, Object.assign({
        backdropClass: "vnctalk-form-backdrop",
        panelClass: ["vnctalk-form-panel", "confirmation-dialog"],
        disableClose: true,
        data: {
          headerText: "RESTORE_CHANNEL",
          bodyText: `Do you want to restore <b>${channel.name}</b> channel?
                    Channel will include all the users, topics and channel settings.`,
          okLabel: "RESTORE",
        },
        autoFocus: true
      }, options)).afterClosed().pipe(take(1)).subscribe((res: any) => {
        if (!!res && res.confirmation) {
          if (res.confirmation === "yes") {
            this.restoreChannelConfirmed(channel);
          }
        }
      });
    }
    else {
      const { RestoreChannelComponent } = await import(
        "../../talk/trash-window/restore-channel/restore-channel.component"
      );
      const unArchiveChannelRef = this.matDialog.open(RestoreChannelComponent, {
        backdropClass: "vnctalk-form-backdrop",
        panelClass: "vnctalk-form-panel",
        width: "480px",
        height: "336px",
        autoFocus: true,
        data: { channelName: channel.name }
      });
      unArchiveChannelRef.afterClosed()
        .pipe(take(1), filter(val => val?.hasOwnProperty("restoreTopics")))
        .subscribe(value => {
          this.restoreChannelConfirmed(channel);
        });
    }
  }

  // not used
  async cloneTopic(topic: Topic) {
    let options: any = {
      width: "480px",
      height: "280px"
    };
    const { ConfirmationChannelComponent } = await import(
      "../confirmation-channel/confirmation-channel.component");
    this.matDialog.open(ConfirmationChannelComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: ["vnctalk-form-panel", "confirmation-dialog"],
      disableClose: true,
      data: {
        headerText: "CLONE_TOPIC_HEADER",
        bodyText: "CLONE_TOPIC_DESCRIPTION",
        okLabel: "YES",
        cancelLabel: "CANCEL"
      },
      autoFocus: true
    }, options)).afterClosed().pipe(take(1)).subscribe( (res: any) => {
      if (!!res && res.confirmation) {
        if (res.confirmation === "yes") {
          this.channelRepository.cloneTopic(topic.id);
        }
      }
    });
  }


  async archiveTopic(item) {
    let options: any = {
      width: "480px",
      height: "280px"
    };
    if (CommonUtil.isMobileSize()) {
      options = {
        maxWidth: "100vw",
        maxHeight: "100vh",
        minHeight: "300px"
      };
    }
    const { ConfirmationChannelComponent } = await import(
      "../confirmation-channel/confirmation-channel.component");
    this.matDialog.open(ConfirmationChannelComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: ["vnctalk-form-panel", "confirmation-dialog"],
      disableClose: true,
      data: {
        headerText: "ARCHIVE_TOPIC",
        bodyText: "ARCHIVE_TOPIC_MSG",
        okLabel: "ARCHIVE"
      },
      autoFocus: true
    }, options))
      .afterClosed()
      .pipe(take(1))
      .subscribe((res: any) => {
        if (!!res && res.confirmation) {
          if (res.confirmation === "yes") {
            this.channelRepository.archiveTopic(item.id, item.channel_id, item.read);
          }
        }
      });
  }

  pinChannel(item) {
    this.channelRepository.pinChannel(item).pipe(take(1)).subscribe(() => {
      this.notificationService.openSnackBarWithTranslation("PINNED_CHANNEL");
      this.selectedChannel.pinned = true;
      this.changeDetectorRef.markForCheck();
    });
  }

  goBack(event, destination?: "archive" | "trash") {
    this.showSuccessBanner = false;
    this.showUnarchivedOrRestoredBanner = false;
    if (event?.target?.className?.localeCompare("text-close-label") === 0 || !event) {
      if (destination) {
        if (!this.isMobileScreen) this.router.navigateByUrl("/talk/trash");
        else {
          if (this.isMobileScreen) {
            this.matDialog.closeAll();
            this.matDialog.open(TrashWindowComponent, {
              backdropClass: ["archive-contacts-backdrop", "trash-window-scroll"],
              panelClass: "mobile-dialog",
              width: "100vw",
              height: "100vh",
              maxHeight: "100vh",
              maxWidth: "100vw",
              autoFocus: true,
              data: { isMobileScreen: true }
            });
          }
        }
        return;
      }
      else {
        if (!this.isMobileScreen) this.router.navigateByUrl("/talk/archive");
        else {
          if (this.isMobileScreen) {
            this.router.navigateByUrl("/talk");
            this.matDialog.closeAll();
            this.matDialog.open(ArchiveWindowComponent, {
              backdropClass: ["archive-contacts-backdrop", "trash-window-scroll"],
              panelClass: "mobile-dialog",
              width: "100vw",
              height: "100vh",
              maxHeight: "100vh",
              maxWidth: "100vw",
              autoFocus: true,
              data: { isMobileScreen: true }
            });
          }
        }
        return;
      }
    }
  }

  unpinChannel(item) {
    this.channelRepository.unpinChannel(item).pipe(take(1)).subscribe(() => {
      this.selectedChannel.pinned = false;
      this.notificationService.openSnackBarWithTranslation("UNPINNED_CHANNEL");
      this.changeDetectorRef.markForCheck();
    });
  }

  addChannelToFavorites(item) {
    this.channelRepository.addChannelToFavorite(item.id).pipe(take(1)).subscribe();
  }

  removeChannelFromFavorites(item) {
    this.channelRepository.removeChannelFromFavorite(item.id).pipe(take(1)).subscribe();
  }

  muteChannel(item) {
    this.channelRepository.muteChannel(item).pipe(take(1)).subscribe();
  }

  unmuteChannel(item) {
    this.channelRepository.unmuteChannel(item).pipe(take(1)).subscribe();
  }

  subscribeChannel(item) {
    if (item.channel_type?.includes("iom")) {
      this.channelRepository.subscribeChannel(item.id, true).pipe(take(1)).subscribe();
    } else {
      this.channelRepository.subscribeChannel(item.id).pipe(take(1)).subscribe();
    }
  }

  async unsubscribeChannel(item) {
    let options: any = {
      width: "480px",
      height: "280px"
    };
    if (CommonUtil.isMobileSize()) {
      options = {
        maxWidth: "100vw",
        maxHeight: "100vh",
        minHeight: "300px"
      };
    }

    const bodyText = this.channelRepository.getTranslatedText("UNSUBSCRIBE_FROM_CHANNEL_MSG", {key: "channelName", value: item?.name});
    const { ConfirmationChannelComponent } = await import(
      "../confirmation-channel/confirmation-channel.component");
    this.matDialog.open(ConfirmationChannelComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: ["vnctalk-form-panel", "confirmation-dialog"],
      disableClose: true,
      data: {
        headerText: "UNSUBSCRIBE_FROM_CHANNEL",
        bodyText,
        okLabel: "UNSUBSCRIBE"
      },
      autoFocus: true
    }, options)).afterClosed().pipe(take(1)).subscribe( (res: any) => {
      if (!!res && res.confirmation) {
        if (res.confirmation === "yes") {
          this.channelRepository.unsubscribeChannel(item.id).pipe(take(1)).subscribe();
        }
      }
    });
  }

  showChannelInfo(item) {
    this.channelRepository.openSideBar("channel", item.id);
  }
  async openHeirarchy(item) {
    let options: any = {
      maxWidth: "90vw",
      width: "90vw",
      height: "90vh"
    };
    const { ChannelHierarchyViewerComponent } = await import(
      "../channel-hierarchy-viewer/channel-hierarchy-viewer.component");
    this.matDialog.open(ChannelHierarchyViewerComponent, Object.assign({
        backdropClass: "vnctalk-form-backdrop",
        panelClass: ["vnctalk-form-panel", "confirmation-dialog"],
        data: { channel: item },
        autoFocus: true
       }, options));
    return;
  }

  copyChannelLink(item: any) {
    this.channelRepository.copyLink("channel", item.id);
  }

  async archiveChannel(item) {
    let options: any = {
      width: "480px",
      height: "280px"
    };
    if (CommonUtil.isMobileSize()) {
      options = {
        maxWidth: "100vw",
        maxHeight: "100vh",
        minHeight: "300px"
      };
    }
    const { ConfirmationChannelComponent } = await import(
      "../confirmation-channel/confirmation-channel.component");
    this.matDialog.open(ConfirmationChannelComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: ["vnctalk-form-panel", "confirmation-dialog"],
      disableClose: true,
      data: {
        headerText: "ARCHIVE_CHANNEL",
        bodyText: "ARCHIVE_CHANNEL_MSG",
        okLabel: "ARCHIVE"
      },
      autoFocus: true
    }, options)).afterClosed().pipe(take(1)).subscribe( (res: any) => {
      if (!!res && res.confirmation) {
        if (res.confirmation === "yes") {
          this.channelRepository.changeChannelCategory(item, "archived");
          this.channelRepository.archiveChannel(item.id, item);
          this.channelButtonPress("back");
        }
      }
    });
  }

  showUnarchivedOrRestoredBanner = false;
  gobackToTrashOrArchive = null;
  restoreBannerLabel: string;

  toggleUnarchivedOrRestoredBanner(value = false, goBackTo = false) {
    this.showUnarchivedOrRestoredBanner = value;
    if (!goBackTo) {
      this.gobackToTrashOrArchive = null;
      this.restoreBannerLabel = "CHANNEL_UNARCHIVED";
    }
    else {
      this.gobackToTrashOrArchive = "trash";
      this.restoreBannerLabel = "CHANNEL_IS_RESTORED";
    }
    this.changeDetectorRef.markForCheck();
  }


  async unarchiveChannel(item) {
    let options: any = {
      width: "480px",
      height: "280px"
    };
    if (CommonUtil.isMobileSize()) {
      options = {
        maxWidth: "100vw",
        maxHeight: "100vh",
        minHeight: "300px"
      };
    }
    const { ConfirmationChannelComponent } = await import(
      "../confirmation-channel/confirmation-channel.component");
    this.matDialog.open(ConfirmationChannelComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: ["vnctalk-form-panel", "confirmation-dialog"],
      disableClose: true,
      data: {
        headerText: "UNARCHIVE_CHANNEL",
        bodyText: "UNARCHIVE_CHANNEL_CONFIRMATION_MSG",
        okLabel: "UNARCHIVE",
        channel_name: item.name
      },
      autoFocus: true
    }, options)).afterClosed().pipe(take(1)).subscribe( (res: any) => {
      if (!!res && res.confirmation) {
        if (res.confirmation === "yes") {
          this.channelRepository.unarchiveChannel(item.id);
          this.toggleUnarchivedOrRestoredBanner(true, false);
        }
      }
    });
  }

  async openSendChannelLinkPopup(item: any) {
    let width = "480px";
    let height = "572px";
    let maxWidth = "80vw";
    const { SendMessageToChatsComponent } = await import(
      "../send-message-to-chats/send-message-to-chats.component");
    const sendChannelLinkRef = this.matDialog.open(SendMessageToChatsComponent, {
      backdropClass: "vnctalk-form-backdrop",
      panelClass: ["vnctalk-form-panel", "send-message-to-chat-component"],
      width: width,
      height: height,
      maxWidth: maxWidth,
      autoFocus: true
    });
    sendChannelLinkRef.afterClosed()
      .pipe(takeUntil(this.isAlive$))
      .subscribe(val => {
        if (val?.selectedUserIds?.length > 0) {
          let comment = `Hey, checkout this channel`;
          this.translate.get("HEY_CHECKOUT_THIS_CHANNEL").pipe(take(1)).subscribe((text: string) => {
            comment = text + " :";
          });
          if (!!val?.comment) {
            comment = val.comment + " :";
          }
          const currentUrl = CommonUtil.getBaseOriginUrl();
          val?.selectedUserIds?.forEach((jId) => {
            const type = jId.includes("@conference") ? "groupchat" : "chat";
            this.conversationRepo.sendMessage({
              body: `${comment} ${currentUrl}/talk/channels/${item?.id}`,
              type: type
            }, jId, type);
          });
          this.translate.get("LINK_SENT").pipe(take(1)).subscribe((text: string) => {
            this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
          });
        }
      });
  }

  loadMoreTopics() {
    if (this.filteredTopicsLoaded) {
      this.loadMoreFilteredTopics();
      return;
    }
    let offset = this.topics.length;
    if (this.selectedChannel) {
      this.channelRepository.loadMoreTopics(this.selectedChannel.id, offset);
    }
  }

  loadMoreFilteredTopics() {
    let offset = this.filteredTopics$.value.length;
    if (this.selectedChannel) {
      this._channelTopicListingService.loadMoreFilteredTopics(this.selectedChannel.id, this.topicQuery, offset);
    }
  }

  getPlainDescription(text) {
    if (text) {
      return CommonUtil.getPlainText(text);
    }
    return text;
  }

  dismissSuccessBanner(click?:MouseEvent) {
    click?.preventDefault();
    click?.stopPropagation();
    this.totalFilesUploading = 0;
    this.uploadingTopic = null;
    this.showSuccessBanner = false;
    this.changeDetectorRef.markForCheck();
  }

  showNewUploadedFiles() {
    this.broadcaster.broadcast("showNewTopicFiles", this.uploadedFiles.map(k=>k.id));
    this.router.navigateByUrl(`/talk/channels/${this.uploadingTopic.channel_id}/topics/${this.uploadingTopic.id}?comment=false&highlight_files=${JSON.stringify(this.uploadedFiles.map(k=>k.id))}`);
  }

  requestAccess() {
    this.channelRepository.accessRequest(this.channelId).pipe(take(1)).subscribe(
      () => {
        this.router.navigateByUrl("/talk");
        setTimeout(() => {
          this.broadcaster.broadcast("showLeftPanel");
          if (document.getElementById("mainLayout") !== null) {
            document.getElementById("mainLayout").classList.remove("hide-header-mobile");
            document.getElementById("mainLayout").classList.remove("hide-header-search-form");
          }
        }, 50);
      }
    );
  }

  channelButtonPress(ev: any): void {
    if (ev === "back") {
      this.router.navigateByUrl("/talk/channels");
      setTimeout(() => {
        this.broadcaster.broadcast("showLeftPanel");
      }, 50);
      if (document.getElementById("mainLayout") !== null) {
        document.getElementById("mainLayout").classList.remove("hide-header-mobile");
        document.getElementById("mainLayout").classList.remove("hide-header-search-form");
      }
    } else if (ev === "subscribe") {
      this.subscribeChannel(this.selectedChannel);
    } else if (ev === "un-subscribe") {
      this.unsubscribeChannel(this.selectedChannel);
    }
  }

  openTileSelection(): void {
    if (this.isShowMobileViewSelection) {
      this.isShowMobileViewSelection = false;
    } else {
      this.isShowMobileViewSelection = true;
    }
    this.changeDetectorRef.markForCheck();
  }

  closeTileSelection(): void {
    this.isShowMobileViewSelection = false;
    this.changeDetectorRef.markForCheck();
  }

  goToParentActionClick(event) {
    if (event.target.className.localeCompare("text-close-label") === 0) {
      this.dismissGotoBanner();
      this.router.navigate([`/talk/channels/${this.parentChannelId}/topics/${this.parentTopicId}`]);
    }
  }

  gotToParentChannel(event) {
    if (event.target.className.localeCompare("text-close-label") === 0) {
      const moveId = this.selectedChannel?.parent?.id;
      this.channelRepository.setSelectedChannelId(moveId);
      this.router.navigateByUrl(`/talk`).then(res => {
        this.router.navigateByUrl(`/talk/channels/${moveId}`);
      });
    }
  }

  dismissGotoBanner() {
    this.$showGoToParentBanner.next(false);

  }

  dismissGotoParentBanner() {
    this.$showGoToParentChannel.next(false);
  }

  scrollToPrevTopic() {
    if (this.isMobileScreen) {
      setTimeout(() => {
        const scrollBody = document.querySelector(".mobile-body-detail");
        const mobileTopic: any = document.getElementById("mobile-topic-" + this.prevTopicId);
        if (scrollBody !== null && mobileTopic !== null) {
          scrollBody.scrollTo({
            top: mobileTopic.offsetTop - 110,
            behavior: "smooth"
          });
        }
      }, 300);
    } else {
      const selector = "topic-" + this.prevTopicId;
      const selectedTopic = document.getElementById(selector);
      if (!!selectedTopic) {
        setTimeout( () => {
          selectedTopic.scrollIntoView({
            behavior: "smooth"
          });
        }, 5000);
      }
    }
  }

  private unmark() {
    this.channelWrapperInstance?.unmark();
    this.currentHighlightedIndex = 1;
    this.totalHighLights = 0;
  }

  private markElements(searchTerm = null) {
    if(!this.isMarkedEarlier){
      this.isMarkedEarlier = true;
      return;
    }
    searchTerm = searchTerm || this.route.snapshot.queryParams["search"];
    this.unmark();
    if(!searchTerm){
      return;
    }
    let markedElementCount = 1;
    try {
      setTimeout(() => {
        this.channelWrapperInstance = new Mark(document.querySelector(".channel-name"));


        this.channelWrapperInstance.mark(searchTerm, {
          className: "topic-search-mark", each: (elem) => {
            this.scrollData[markedElementCount] = elem?.getBoundingClientRect().top;
            elem.classList.add(`mark-vnc-${markedElementCount++}`);
            ++this.totalHighLights;
          }
        });
        this.highlightCurrent();
        this.changeDetectorRef.detectChanges();
      }, 1000);
    } catch (e) {
    }
  }

  private highlightCurrent() {
    document.getElementsByClassName("topic-current-highlight")
      ?.item(0)
      ?.classList
      ?.remove("topic-current-highlight");
    document.getElementsByClassName(`mark-vnc-${this.currentHighlightedIndex}`)
      ?.item(0)
      ?.classList
      ?.add("topic-current-highlight");
    const container = document.getElementsByClassName("topics-list")?.item(0);
      container?.scrollTo({top: this.scrollData[this.currentHighlightedIndex] - 150, behavior: "smooth"});
  }

  getTopicsGroupedByAuthor() {
    this.topicsGroupedByAuthor = {};
    this.authorsGroup = [];
    const currentTopicsResponse = this.filteredTopics$.getValue();
    currentTopicsResponse.forEach((topic: Topic) => {
      const authorEmailId = topic?.author?.jid;
      if (!this.topicsGroupedByAuthor.hasOwnProperty(authorEmailId)) {
        this.authorsGroup.push(authorEmailId);
        this.topicsGroupedByAuthor[authorEmailId] = {
          authorData: {...topic?.author},
          topics: []
        };
      }
      this.topicsGroupedByAuthor?.[authorEmailId].topics.push(topic);
    });
    this.changeDetectorRef.markForCheck();
  }

  searchTopicByKeyword(val): void {
    if (val !== "") {
      this.isTopicsLoading = true;
      this.searchedTopics = [];
      this.changeDetectorRef.markForCheck();
      this.channelService.getTopicByWord(this.selectedChannel.id,val).pipe(takeUntil(this.isAlive$)).subscribe((res: any) => {
        this.isTopicsLoading = false;
        this.changeDetectorRef.markForCheck();
        const topics: any[] = res.topics;
        this.searchedTopics = topics.map( topic => {
          if (topic.attachments && topic.attachments.length > 0) {
            topic.attachments.map(item => {
              item.content_url = topic.is_iom ? item.content_url : CommonUtil.getAttachmentLocalAPIURL(item.content_url);
              item.thumbnail_url = topic.is_iom ? item.thumbnail_url : CommonUtil.getAttachmentLocalAPIURL(item.thumbnail_url);
            });
          }
          let headerAttachments = topic?.attachments?.filter(attachment => attachment?.is_header === true);
          const heroAttachments = this.channelRepository?.updateAttachmentsURLs(headerAttachments, topic?.is_iom);
          const comments_count = !!topic?.comments_count ? +topic?.comments_count : 0;
          const unread_comments_count = !!topic?.unread_comments_count ? +topic?.unread_comments_count : 0;
          if (topic) {
            topic.description = topic?.description_plain;
            topic.matched_comments_count = 0;
          }
          return {...topic, heroAttachments, comments_count, unread_comments_count};
        });
        this.changeDetectorRef.markForCheck();
      }, () => {
        this.isTopicsLoading = false;
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  swipe(ev: any, action: string): void {
    if (action === "start") {
      this.yDown = ev.changedTouches[0].clientY;
    }
    if (action === "move") {
      const yUp = ev.changedTouches[0].clientY;
      if (this.yDown > yUp) {
        setTimeout(() => {
          this.isDisplayTopicSearch = false;
        }, 100);
      } else if (this.yDown < yUp) {
        setTimeout(() => {
          this.isDisplayTopicSearch = true;
        }, 100);
      }
     this.yDown = yUp;
     this.changeDetectorRef.markForCheck();
    }
  }

  channelAvatarFailedToLoad() {
    this.channelRepository.channelAvatarFailedToLoad(this.selectedChannel.id);
  }

  checkPermission(subChannelToCheck) {
    if (subChannelToCheck) {
      return CommonUtil.getPermission(subChannelToCheck?.permissions, "moderate_channel");
    }
    else {
      return true;
    }
  }

  async filterClickMobile(event) {
    let options: any = {
      width: "480px",
      height: "730px",
    };
    if (CommonUtil.isMobileSize()) {
      options = {
        maxWidth: "100vw",
        maxHeight: "100vh",
        width: "100vw",
        height: "100vh"
      };
    }
    const { TopicFilterComponent } = await import(
      /* webpackPrefetch: true */
      "./topic-filter/topic-filter.component");
    this.matDialog.open(TopicFilterComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: "vnctalk-form-panel",
      disableClose: true,
      data: {},
      autoFocus: true
    }, options));
  }
}

export enum TopicCommentScroll {
  NONE = "none",
  COMMENT_FORM = "comment-form",
  COMMENT_ITEMS = "comment-items"
}
